import { ReplacerRestService } from '../../rest/replacer-rest/replacer-rest.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../rest/replacer-rest/replacer-rest.service";
export class ReplacerService {
    constructor(_replacerRest) {
        this._replacerRest = _replacerRest;
        this._selectedChoice = new Subject();
        this._replacerListSelected = new Subject();
        this._initialReplacerList = new BehaviorSubject(undefined);
        this.selectedChoice$ = this._selectedChoice.asObservable();
        this.replacerListSelected$ = this._replacerListSelected.asObservable();
        this.initialReplacerList$ = this._initialReplacerList.asObservable();
    }
    getReplacerByFilter(contextInstanceCodeArray, search, minSearchLength) {
        return this._replacerRest.getReplacerByFilter(contextInstanceCodeArray, search, minSearchLength);
    }
    setReplacerChoiceSelected(choice) {
        this._selectedChoice.next(choice);
    }
    initReplacerListFromEdit(replacerList) {
        this._initialReplacerList.next(replacerList);
    }
    updateReplacerListSelection(replacerList) {
        this._replacerListSelected.next(replacerList);
    }
}
ReplacerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReplacerService_Factory() { return new ReplacerService(i0.ɵɵinject(i1.ReplacerRestService)); }, token: ReplacerService, providedIn: "root" });
