import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class GeographyRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getGeographyByType(type, search) {
        const headers = new HttpHeaders()
            .append('restCache', 'true');
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/geography/catalog-typeahead/${type}`,
            headers: headers,
            params: {
                minSearchLength: 3,
                search: search
            }
        }).pipe(map(result => { return { type: type, items: result }; }));
    }
    getZipcodesCount(codes) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/geography/zipcodes/count`,
            body: codes
        });
    }
    getZipcodes(codes) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/geography/zipcodes`,
            body: codes
        });
    }
}
GeographyRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GeographyRestService_Factory() { return new GeographyRestService(i0.ɵɵinject(i1.RestService)); }, token: GeographyRestService, providedIn: "root" });
