import { Directive, OnDestroy, OnInit, OnChanges, SimpleChanges, Input, Self } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';
import { FieldValueHandlerService, AnyValueEvent } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';

@Directive({
  selector: '[appTextMirror]'
})
export class TextMirrorDirective implements OnInit, OnChanges, OnDestroy {
  @Input()
  public appTextMirror: { from: string, isCloneModal: boolean };

  private _formOperationType: FormOperationType;
  private _subscriptions: Array<Subscription> = [];

  constructor(
    @Self() private _control: NgControl,
    private _detailViewHandler: DetailViewHandlerService,
    private _fieldValueHandler: FieldValueHandlerService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appTextMirror && changes.appTextMirror.firstChange) {
      this._subscriptions.push(
        this._fieldValueHandler.anyValue$
          .subscribe(values => this._handleFieldValueChange(values)));

      this._subscriptions.push(
        this._detailViewHandler.mode$
          .subscribe(viewMode => this._formOperationType = viewMode));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _handleFieldValueChange(values: AnyValueEvent) {
    if (this.appTextMirror && this.appTextMirror.from === values.currentField.name) {

      const allowUpdateField = (this._formOperationType === FormOperationType.Create || this.appTextMirror.isCloneModal) &&
        (!this._control.control.value || this._control.control.pristine);

      if (allowUpdateField) {
        let newValue = values.currentField.value as string;

        if (typeof (newValue) === 'string') {
          newValue = newValue.toUpperCase().replace(/\s+/g, '_');
        } else {
          newValue = newValue && (newValue[Object.keys(newValue)[0]] as string).toUpperCase().replace(/\s+/g, '_');
        }

        this._control.control.setValue(newValue);
        this._control.control.markAsPristine();
        this._control.control.updateValueAndValidity();
      }
    }
  }
}
