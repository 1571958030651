<ng-container *ngFor="let n1 of [1,2]">
  <div class="fields-group pl-fluid-container">

    <div class="row">
      <div class="col-xs-12">
        <div class="title"></div>
      </div>
    </div>

    <div class="row mb-3" *ngFor="let n2 of [1]">
      <div class="col-sm-4 col-md-3 col-lg-3">
        <div class="card ghost-card-default">
          <div class="card-body">
            <div class="dashboard-title"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text text-half"></div>
          </div>
          <div class="card-footer">
            <div>
              <span class="icon-box"></span>
              <span class="icon-box"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-3">
        <div class="card ghost-card-default">
          <div class="card-body">
            <div class="dashboard-title"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text text-half"></div>
          </div>
          <div class="card-footer">
            <div>
              <span class="icon-box"></span>
              <span class="icon-box"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3 col-lg-3">
        <div class="card ghost-card-default">
          <div class="card-body">
            <div class="dashboard-title"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text"></div>
            <div class="dashboard-text text-half"></div>
          </div>
          <div class="card-footer">
            <div>
              <span class="icon-box"></span>
              <span class="icon-box"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</ng-container>