import { Component, OnInit, EventEmitter, Input, OnDestroy } from '@angular/core';
import { GridInput } from 'src/app/components/grid/grid.model';
import { Subscription, Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, filter } from 'rxjs/operators';
import { GridHandlerService } from '../../../../services/handler/grid-handler/grid-handler.service';
import { DetailViewService } from 'src/app/services/behavior/detail-view/detail-view.service';
import { DashboardHandlerService } from '../../../../services/handler/dashboard-handler/dashboard-handler.service';
import { DashboardItem } from '../../../../models/dashboard.models';
import { FormDefinition, FieldDefinition, FieldAttributes } from '../../../../models/form-field-definition.models';
import { GridApi } from 'ag-grid-community';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { deepClone } from 'src/app/functions/deepClone';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';

export interface ChangesGridViewModel {
  title: string;
  loadingGrid: boolean;
  changedFieldsCount: number;
  gridModel: GridInput;
}

@Component({
  selector: 'app-changes-grid',
  templateUrl: './changes-grid.component.html',
  styleUrls: ['./changes-grid.component.sass']
})
export class ChangesGridComponent implements OnInit, OnDestroy {
  @Input() public disabledGrid: boolean;
  @Input() public config: FormDefinition;
  @Input() public requestCode: string;

  public model = {} as ChangesGridViewModel;

  public colState: EventEmitter<any> = new EventEmitter();
  public filterState: EventEmitter<any> = new EventEmitter();

  public externalFilter: { [x: string]: (key: any) => boolean; };
  public export: EventEmitter<any> = new EventEmitter();
  public setExpanded: EventEmitter<boolean> = new EventEmitter();

  public _changeRequests: Array<any>;

  public fixedChangeRequests: Array<any>;
  public dropdownChangeRequests: Array<any>;

  public selectedTab: string;

  private _dashboardItemList: Array<DashboardItem>;
  private _subscriptions: Array<Subscription> = [];
  private _entityReplacer: string = '${entity}';

  constructor(
    private _gridHandler: GridHandlerService,
    private _dashboardHandler: DashboardHandlerService,
    private _detailService: DetailViewService,
  ) { }

  public exportToCsv() {
    this.export.emit(undefined);
  }

  public updateFilter(code: string) {
    if (!this.model.gridModel) {
      return;
    }
    let rows = this.model.gridModel.rows;
    this.selectedTab = code;
    this.externalFilter = undefined;

    if (code) {
      this.externalFilter = {
        'changeRequestCode': (param: string) => {
          return param === code;
        }
      }
      rows = this.model.gridModel.rows.filter(x => x.changeRequestCode === code);
    }
    this._setChangedFieldsCount(rows);
  }

  public setGridApi(gridApi: GridApi) {
    gridApi.setFilterModel({
      changed: {
        filterType: "text",
        type: "contains",
        filter: "Yes"
      }
    });
  }

  ngOnInit() {
    this.model.loadingGrid = true;
    this.model.title = this.config.title;

    this._getGridData();

    this._subscriptions.push(
      this._gridHandler.getGenericGridDataAndDef$
        .pipe(
          filter(x => x.source === this.config.grid.source),
          switchMap(gridDef => {
            return this._dashboardHandler.dashboardItemList$
              .pipe(map(dashboardItems => {
                this._dashboardItemList = dashboardItems;
                return gridDef;
              }))
          }),
          switchMap(gridDef => this._getDefinitions(gridDef)))
        .subscribe(results => {
          // [results] contains:
          // results[0] = grid data source
          // results[1] = field definitions
          const data = results[0];
          const fieldDefinitions = [];

          this._setKeyValuesForGenericDynamicForms(data, results, fieldDefinitions);
          this._setDinamicFields(data, results, fieldDefinitions);
          this._setGridModelAndUpdateFieldNames(data, fieldDefinitions);
        }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _setDinamicFields(data: any, results: any[], fieldDefinitions: any[]) {
    const dynamicFields = data.rows.filter((x: any) => x.dynamicField)
      .map(x => {
        return {
          field: this._camelize(x.field),
          attributes: { label: x.field }
        } as FieldDefinition;
      });
    for (let i = 1; i < results.length; i++) {
      this._checkDynamicGroups(results[i].data, dynamicFields);
      fieldDefinitions.push({
        def: results[i].data,
        libraryCodes: results[i].libraryCodes
      });
    }
  }

  private _setKeyValuesForGenericDynamicForms(data: any, results: Array<any>, fieldDefinitions: Array<any>) {
    data.rows.forEach(element => {
      if (element.field === 'keyValue') {
        const currentKeyValueObj = this._tryParseJSON(element.currentValue);
        const newKeyValueObj = this._tryParseJSON(element.newValue);

        if (currentKeyValueObj || newKeyValueObj) {
          let newFormFieldDefinition: any;
          const fieldDefinitionArray: Array<any> = [];
          newKeyValueObj.forEach((keyValueObj, index) => {
            const newElementData = Object.assign([], element);
            newElementData.field = keyValueObj.key;
            newElementData.currentValue = this._getValue(currentKeyValueObj, index);
            newElementData.newValue = this._getValue(newKeyValueObj, index);

            fieldDefinitionArray.push({
              field: newElementData.field,
              attributes: {
                name: newElementData.field,
                label: newElementData.field
              } as FieldAttributes
            } as FieldDefinition);

            data.rows.push(newElementData);

            let formFieldDefinition = deepClone(results.find(fieldDef => fieldDef.libraryCodes && fieldDef.libraryCodes.includes(element.libraryCode)));
            if (!formFieldDefinition) {
              formFieldDefinition = fieldDefinitions.find(x => x.libraryCodes.includes(element.libraryCode));
              formFieldDefinition.data = formFieldDefinition.def;
            } else {
              const libraryCodeIndex = formFieldDefinition.libraryCodes.findIndex(lc => lc === element.libraryCode);
              formFieldDefinition.libraryCodes.splice(libraryCodeIndex, 1);
            }

            if (!newFormFieldDefinition) {
              newFormFieldDefinition = deepClone(formFieldDefinition);
              newFormFieldDefinition.libraryCodes = [element.libraryCode];
              newFormFieldDefinition.data.push({
                name: 'Dynamic Form',
                description: 'Dynamic Form',
                fields: fieldDefinitionArray,
                id: element.id,
                order: fieldDefinitions.length,
                title: 'Dynamic Form',
                type: "fields"
              });

              fieldDefinitions.push({
                def: newFormFieldDefinition.data,
                libraryCodes: newFormFieldDefinition.libraryCodes
              });
            }
          });
        }
      }
    });
  }

  private _getKey(keyValueObj: any): any {
    return keyValueObj[0].key;
  }

  private _getValue(keyValueObj: any, index: number): any {
    return keyValueObj ? Object.values(keyValueObj[index].value)[0] : null;
  }

  private _tryParseJSON(value: string) {
    let parsedValue: any;
    try {
      parsedValue = JSON.parse(value);
    }
    catch (error) {
      parsedValue = undefined;
    }

    return parsedValue;
  }

  private _getGridData() {
    this._gridHandler.getGenericGridDefAndCustomData(this.config.grid, this.requestCode);
  }

  private _setChangeRequestList() {
    // distinct primitive values
    const changeRequests = Array
      .from(new Set(this.model.gridModel.rows.map((item: any) => item.changeRequestCode)));

    this._changeRequests = changeRequests.map(x => {
      return { code: x, desc: x };
    });

    if (changeRequests.length === 1) {
      this.updateFilter(this._changeRequests[0].code);
    } else if (changeRequests.length > 1) {
      this._changeRequests.unshift({
        code: undefined,
        desc: `${this.requestCode} (all changes)`
      });
      this.updateFilter(undefined);
    }

    this.fixedChangeRequests = Object.assign([], this._changeRequests);

    if (changeRequests.length > 4) {
      this.dropdownChangeRequests = this.fixedChangeRequests.splice(5);
    }
  }

  private _setChangedFieldsCount(rows: Array<any>) {
    this.model.changedFieldsCount = rows.filter(x => x.changed).length;
  }

  private _applyCellStyleRules(columns: Array<any>) {
    columns.forEach(col => {
      if (col.field === 'changed') {
        col.cellClass = (params: any) => {
          if (params && params.data && params.data.changed === true) {
            return 'modified-entity';
          } else {
            return '';
          }
        };
      }
    });
  }

  private _getDefinitions(gridData: GridInput): Observable<Array<any>> {
    const obs: Array<Observable<any>> = [];
    let libraryCodes = gridData.rows
      .filter(x => x.libraryCode)
      .map(x => x.libraryCode);
    libraryCodes = Array.from(new Set(libraryCodes.map(x => x)));

    const sources = this._dashboardItemList
      .filter(items => {
        return libraryCodes.some(x => items.libraryCodes.includes(x) && items.gridSource != AgGridEnum.Book);
      })
      .map(item => { return { source: item.formSource, libraryCode: item.libraryCodes } })
      .filter(items => items.source);

      sources.forEach(item => {
      // There's no definition for DYNAF.FLW.AGRE, then we use DYNAF.FLW
      if (item.source === DynafEnum.AgreFlow) {
        item.source = DynafEnum.Flow;
      }

      if (item.source === DynafEnum.Book) {
        item.source = DynafEnum.OrganizationBook;
      }

      obs.push(this._detailService.getViewDefinitions(item.source)
        .pipe(map(data => { return { data: data, libraryCodes: item.libraryCode } })))

    });

    return forkJoin(of(gridData), ...obs);
  }

  private _updateFieldsWithDefinition(rows: Array<any>, fieldDefinitions: Array<FormDefinition>, libraryCodes: Array<string>) {
    // update fields names
    rows.forEach(row => {
      fieldDefinitions.forEach(groupDef => {
        if (groupDef.fields) {
          this._replaceTitle(groupDef, row['libraryCode']);
          groupDef.fields.forEach(field => {
            if (libraryCodes.includes(row['libraryCode'])) {
              let fieldArray: Array<{ fieldName: string, fieldLabel: string }> = [];

              if (field.fieldGroup) {
                fieldArray.push(
                  {
                    fieldName: field.fieldGroup,
                    fieldLabel: field.attributes.label
                  });

                this._updateFieldAndCategory(row, fieldArray, groupDef.title);
                //Fix for language value (JSON)
                this._setValueForObjectValues(row);
              } else {
                if (field.tabbedRuleCreator) {
                  fieldArray.push(
                    {
                      fieldName: field.tabbedRuleCreator.outcome.field.field,
                      fieldLabel: field.tabbedRuleCreator.outcome.attributes.label
                    }
                  );
                }

                fieldArray.push(
                  {
                    fieldName: field.field,
                    fieldLabel: field.attributes.label
                  });

                this._updateFieldAndCategory(row, fieldArray, groupDef.title);
              }
            }
          });
        }
      });
    });

    rows.forEach(row => {
      if (!row.category) {
        const filteredDefinitions = fieldDefinitions.filter(fieldDef =>
          fieldDef.fields && fieldDef.fields.length === 0
        );

        if (filteredDefinitions.length > 0) {
          row.category = filteredDefinitions[0].title;
        }
      }
    });
  }

  private _replaceTitle(groupDef: FormDefinition, libraryCodes: Array<string>) {
    if (groupDef.title.includes(this._entityReplacer)) {
      var dashboardItem = this._dashboardItemList
        .find(item => item.libraryCodes
          .find(l => libraryCodes.includes(l)));
      groupDef.title = groupDef.title.replace(this._entityReplacer, dashboardItem.name);
    }
  }

  private _setValueForObjectValues(row: any) {
    if (row.newValue instanceof Object) {
      row.newValue = row.newValue[0].Text;
    }
  }

  private _updateFieldAndCategory(row: any, fieldArray: Array<{ fieldName: string, fieldLabel: string }>, categoryName: string) {
    fieldArray.forEach(field => {
      if (field.fieldName === 'childrenGeographies') {
        if (row.field && this._camelize(row.field).includes(field.fieldName)) {
          row.field = field.fieldLabel;
          row.category = categoryName;
          row['fieldNameUpdated'] = true;
        }
      } else {
        if (row.field && (this._camelize(row.field) === field.fieldName || row.field === field.fieldName)) {
          row.field = field.fieldLabel || row.fieldIndex;
          row.category = categoryName;
          row['fieldNameUpdated'] = true;
        }
      }
    });
  }

  private _camelize(str: string) {
    return str.charAt(0).toLocaleLowerCase() + str.substr(1);
  }

  private _setGridModelAndUpdateFieldNames(data: any, fieldDefinitions: Array<{ def: Array<FormDefinition>, libraryCodes: Array<string> }>) {
    this.model.gridModel = data;

    if (data.rows && data.rows.length) {
      fieldDefinitions.forEach(fieldDef => {
        this._updateFieldsWithDefinition(data.rows, fieldDef.def, fieldDef.libraryCodes);
      });

      // remove rows with unset label
      data.rows = data.rows.filter(x => x.fieldNameUpdated);

      this._applyCellStyleRules(data.columns);
      this._setChangedFieldsCount(data.rows);
      this._setChangeRequestList();
      this._setChangesGridRows();

      setTimeout(() => {
        this.setExpanded.emit(true);
      }, 100);
    }

    this.model.loadingGrid = false;
  }

  private _setChangesGridRows() {
    this._gridHandler.setChangesGridRows(this.model.gridModel.rows);
  }

  private _checkDynamicGroups(formDefinition: FormDefinition[], dynamicFields: Array<FieldDefinition>) {
    let dynamicGroupList = [];

    formDefinition.forEach(fd => {
      if (fd.fields) {
        fd.fields.forEach(f => {
          if (f.dynamicGroup != null) {
            dynamicGroupList.push(f.dynamicGroup)
          }
        })
      }
    });

    if (dynamicGroupList.length > 0) {
      dynamicGroupList.forEach(element => {
        const definitionsLength = formDefinition.length;
        formDefinition.push({
          name: element.title,
          description: 'Dynamic Group',
          fields: dynamicFields,
          id: element.id,
          order: definitionsLength,
          title: element.title,
          type: "fields"
        } as FormDefinition);
      });
    }
  }
}
