import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { FileRestService } from '../../rest/file-rest/file-rest.service';
import { BehaviorSubject } from 'rxjs';
import { saveAs } from 'file-saver';
import { WaitingLoaderService } from '../../behavior/waiting-loader/waiting-loader.service';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../dashboard-handler/dashboard-handler.service";
import * as i2 from "../../rest/file-rest/file-rest.service";
import * as i3 from "../../behavior/waiting-loader/waiting-loader.service";
export class FileHandlerService {
    constructor(_dashboardHandler, _fileRest, _waitingLoader) {
        this._dashboardHandler = _dashboardHandler;
        this._fileRest = _fileRest;
        this._waitingLoader = _waitingLoader;
        this._fileUploadedName = new BehaviorSubject(undefined);
        this._subscriptions = [];
        this._subscriptions.push(this._dashboardHandler.currentDashboardItem$
            .subscribe(item => {
            this._currentViewCode = item.viewCode;
        }));
        this.fileUploadedName$ = this._fileUploadedName.asObservable().pipe(filter(x => x != undefined));
    }
    uploadBook(payload) {
        return this._fileRest.uploadBook(this._currentViewCode, payload);
    }
    downloadBookPreview(instanceCode, journeyStepFormInstanceCode) {
        const multiple = [
            'geography-book',
            'campaign-book',
            'flow-book',
            'organization-book',
            'product-type-book',
            'catalog-book',
            'email-template-book'
        ];
        const multipleWithAllowedColumns = ['container-book'];
        if (multiple.includes(this._currentViewCode)) {
            return this._fileRest.downloadBookPreviewMultiple(this._currentViewCode, instanceCode);
        }
        else if (multipleWithAllowedColumns.includes(this._currentViewCode)) {
            return this._fileRest.downloadBookPreviewMultiple(this._currentViewCode, instanceCode, journeyStepFormInstanceCode);
        }
        else {
            return this._fileRest.downloadBookPreview(this._currentViewCode, instanceCode);
        }
    }
    processBook(payload) {
        return this._fileRest.processBook(this._currentViewCode, payload);
    }
    downloadBookTemplateForViewCode(viewCode, instanceCode, journeyStepFormInstanceCode) {
        this._waitingLoader.showFullScreenLoading();
        this._fileRest.downloadBookTemplate(viewCode, instanceCode, journeyStepFormInstanceCode)
            .subscribe((result) => {
            saveAs(result.file, result.fileName);
            this._waitingLoader.hideFullScreenLoading();
        });
    }
    downloadBookTemplate(instanceCode, journeyStepFormInstanceCode) {
        this.downloadBookTemplateForViewCode(this._currentViewCode, instanceCode, journeyStepFormInstanceCode);
    }
    generateAuditForm(instanceCode, payload) {
        this._waitingLoader.showFullScreenLoading();
        return this._fileRest.generateAuditForm(instanceCode, payload);
    }
    downloadAuditForm(instanceCode, jobExecutionUid) {
        this._waitingLoader.showFullScreenLoading();
        this._fileRest.downloadAuditForm(instanceCode, jobExecutionUid)
            .subscribe((result) => {
            saveAs(result.file, result.fileName);
            this._waitingLoader.hideFullScreenLoading();
        });
    }
    generateAgreeFlowAuditForm(flowType, instanceCode, payload) {
        this._waitingLoader.showFullScreenLoading();
        return this._fileRest.generateAgreeFlowAuditForm(flowType, instanceCode, payload);
    }
    downloadAgreeFlowAuditForm(flowType, instanceCode, jobExecutionUid) {
        this._waitingLoader.showFullScreenLoading();
        this._fileRest.downloadAgreeFlowAuditForm(flowType, instanceCode, jobExecutionUid)
            .subscribe((result) => {
            saveAs(result.file, result.fileName);
            this._waitingLoader.hideFullScreenLoading();
        });
    }
    setFileUploadedName(fileUploadedName) {
        this._fileUploadedName.next(fileUploadedName);
    }
}
FileHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileHandlerService_Factory() { return new FileHandlerService(i0.ɵɵinject(i1.DashboardHandlerService), i0.ɵɵinject(i2.FileRestService), i0.ɵɵinject(i3.WaitingLoaderService)); }, token: FileHandlerService, providedIn: "root" });
