import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/store/store.service';
import { Router, NavigationEnd } from '@angular/router';
import { scrollToPosition } from 'src/app/functions/scrollToPosition';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-cb-viewer',
  templateUrl: './cb-viewer.component.html',
  styleUrls: ['./cb-viewer.component.sass']
})
export class CbViewerComponent implements OnInit {

  constructor(
    private _appStore: StoreService,
    private _router: Router
  ) { }

  ngOnInit() {
    // hack?
    setTimeout(() => {
      this._appStore.setTheme('blue-light-theme');
      this._appStore.appendBodyClass('campaign-builder');
    }, 100);

    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url !== event.urlAfterRedirects) {
          scrollToPosition(document.documentElement, 0, 1000);
        }
      });
  }
}
