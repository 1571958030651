import { Injectable } from '@angular/core';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { FileRestService } from '../../rest/file-rest/file-rest.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { AgGridBaseModel } from 'src/app/models/aggrid/aggrid-base.model';
import { WaitingLoaderService } from '../../behavior/waiting-loader/waiting-loader.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileHandlerService {
  public readonly fileUploadedName$: Observable<string>;

  private readonly _fileUploadedName: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private _currentViewCode: string;
  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _dashboardHandler: DashboardHandlerService,
    private _fileRest: FileRestService,
    private _waitingLoader: WaitingLoaderService
  ) {
    this._subscriptions.push(
      this._dashboardHandler.currentDashboardItem$
        .subscribe(item => {
          this._currentViewCode = item.viewCode;
        }));

    this.fileUploadedName$ = this._fileUploadedName.asObservable().pipe(filter(x => x != undefined));
  }

  public uploadBook(payload: any) {
    return this._fileRest.uploadBook(this._currentViewCode, payload);
  }

  public downloadBookPreview(instanceCode: string, journeyStepFormInstanceCode?: string) {
    const multiple = [
      'geography-book',
      'campaign-book',
      'flow-book',
      'organization-book',
      'product-type-book',
      'catalog-book',
      'email-template-book'];

    const multipleWithAllowedColumns = ['container-book'];

    if (multiple.includes(this._currentViewCode)) {
      return this._fileRest.downloadBookPreviewMultiple(this._currentViewCode, instanceCode);
    } else if (multipleWithAllowedColumns.includes(this._currentViewCode)) {
      return this._fileRest.downloadBookPreviewMultiple(this._currentViewCode, instanceCode, journeyStepFormInstanceCode);
    } else {
      return this._fileRest.downloadBookPreview(this._currentViewCode, instanceCode);
    }
  }

  public processBook(payload: any) {
    return this._fileRest.processBook(this._currentViewCode, payload);
  }

  public downloadBookTemplateForViewCode(viewCode: string, instanceCode: string, journeyStepFormInstanceCode: string) {
    this._waitingLoader.showFullScreenLoading();
    this._fileRest.downloadBookTemplate(viewCode, instanceCode, journeyStepFormInstanceCode)
      .subscribe(
        (result: { file: Blob, fileName: string }) => {
          saveAs(result.file, result.fileName);
          this._waitingLoader.hideFullScreenLoading();
        });
  }

  public downloadBookTemplate(instanceCode: string, journeyStepFormInstanceCode: string) {
    this.downloadBookTemplateForViewCode(this._currentViewCode, instanceCode, journeyStepFormInstanceCode);
  }

  public generateAuditForm(instanceCode: string, payload: Array<AgGridBaseModel>) {
    this._waitingLoader.showFullScreenLoading();
    return this._fileRest.generateAuditForm(instanceCode, payload);
  }

  public downloadAuditForm(instanceCode: string, jobExecutionUid: string) {
    this._waitingLoader.showFullScreenLoading();
    this._fileRest.downloadAuditForm(instanceCode, jobExecutionUid)
      .subscribe(
        (result: { file: Blob, fileName: string }) => {
          saveAs(result.file, result.fileName);
          this._waitingLoader.hideFullScreenLoading();
        });
  }

  public generateAgreeFlowAuditForm(flowType: string, instanceCode: string, payload: Array<AgGridBaseModel>) {
    this._waitingLoader.showFullScreenLoading();
    return this._fileRest.generateAgreeFlowAuditForm(flowType, instanceCode, payload);
  }

  public downloadAgreeFlowAuditForm(flowType: string, instanceCode: string, jobExecutionUid: string) {
    this._waitingLoader.showFullScreenLoading();
    this._fileRest.downloadAgreeFlowAuditForm(flowType, instanceCode, jobExecutionUid)
      .subscribe(
        (result: { file: Blob, fileName: string }) => {
          saveAs(result.file, result.fileName);
          this._waitingLoader.hideFullScreenLoading();
        });
  }

  public setFileUploadedName(fileUploadedName: string) {
    this._fileUploadedName.next(fileUploadedName);
  }

}
