<select #input="ngModel" [attr.id]="attrs.id" [attr.name]="attrs.name" [attr.class]="attrs.class" (ngModelChange)='onChangeCallback($event);onModelChange()'
  [(ngModel)]="innerValue" required="{{attrs.required}}" disabled="{{attrs.disabled}}">
  <option [value]="''">- {{attrs.defaultTextItem | translate}} -</option>
  <option *ngFor="let item of attrs.items | async" [value]="attrs.itemCode ? item[attrs.itemCode] : item">{{(attrs.itemText
    ? item[attrs.itemText] : item) | translate }}</option>
</select>

<span *ngIf="attrs.showValidationIcons">
  <i class="fas fa-asterisk text-muted" *ngIf="input.pristine"></i>
  <i class="fas fa-times text-danger" *ngIf="!input.pristine && input.errors"></i>
  <i class="fas fa-check text-success" *ngIf="!input.pristine && !input.errors"></i>
</span>

<div class="input-error" *ngIf="!input.pristine && input.errors?.required" translate>INPUT_VALIDATIONS.REQUIRED</div>