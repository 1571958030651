import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { ElementResponse } from 'ngx-emerios-all';
import { FormDefinition, FieldDefinition, Action } from 'src/app/models/form-field-definition.models';
import { FormGroup, FormControl, ValidatorFn, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { DataModelHelperService } from 'src/app/services/helper/data-model-helper/data-model-helper.service';
import { EntityCatalogItem, EntityCatalog } from 'src/app/models/catalog.models';
import { distinctUntilChanged } from 'rxjs/operators';
import { FormRestService } from 'src/app/services/rest/form-rest/form-rest.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { KeyValueType, KeyValue } from 'src/app/types/keyvalue.type';
import { ReplacerService } from 'src/app/services/behavior/replacer/replacer.service';
import { deepClone } from 'src/app/functions/deepClone';
import { CodeName } from 'src/app/models/common/code-name.model';
import { FieldEnum } from 'src/app/enums/fields';
import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { FileHandlerService } from 'src/app/services/handler/file-handler/file-handler.service';
import { sort } from 'src/app/functions/sortBy';
import { ActivatedRoute, Router } from '@angular/router';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { JourneyHandlerService } from 'src/app/services/handler/journey-handler/journey-handler.service';
import { JourneyActivity, JourneyStep } from 'src/app/models/journey.models';
import { JourneyContextQueryStr } from 'src/app/enums/query-string';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { RestCacheService } from 'src/app/services/rest-cache/rest-cache.service';

export function areListEquals(a: Array<string>, b: Array<string>) {
  let equals = true;

  if (a.length === b.length) {
    a.forEach(x => equals = equals && b.indexOf(x) > -1);
  } else {
    equals = false;
  }

  return equals;
}

@Component({
  selector: 'app-dynamic-form-group',
  templateUrl: './dynamic-form-group.component.html',
  styleUrls: ['./dynamic-form-group.component.sass']
})
export class DynamicFormGroupComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() public forceFormValidation: EventEmitter<any>;
  @Input() public fieldsDistribution: 'page' | 'modal' = 'page';
  @Input() public config: FormDefinition;
  @Input() public data: any;
  @Input() public editMode: boolean;
  @Input() public disabled: boolean;
  @Input() public externalEvent: { name: string, value: any, stopPropagation?: boolean };

  @Output() public isValid: EventEmitter<any> = new EventEmitter();
  @Output() public dirty: EventEmitter<boolean> = new EventEmitter();
  @Output() public change: EventEmitter<any> = new EventEmitter();
  @Output() public bubbledEvent: EventEmitter<any> = new EventEmitter();
  @Output() public formSelected: EventEmitter<any> = new EventEmitter();

  public readonly selectionUpdater$ = new BehaviorSubject<Array<string>>(undefined);

  public form: FormGroup;
  public inputFields: Array<FieldDefinition>;
  public textareaFields: Array<FieldDefinition>;
  public sortableLists: Array<FieldDefinition>;
  public tabbedRuleCreators: Array<FieldDefinition>;
  public fileUploaders: Array<FieldDefinition>;
  public uploadFileProcessings: Array<FieldDefinition>;
  public backgroundJobDetails: Array<FieldDefinition>;
  public buttonActionList: Array<FieldDefinition>;

  public chainedFilterConfig: FieldDefinition;
  public isChainedFilter: boolean;

  public elementConfigurations: KeyValue = {};

  private _triggerableFields: Array<FieldDefinition> = [];
  private _filterableFields: Array<FieldDefinition> = [];
  private _valueChangesForFieldSubscriptions: KeyValueType<Subscription> = {};
  private _subscriptions: Array<Subscription> = [];
  private _initialReplacerList: Array<CodeName>;
  private _hideStaticGroups: boolean = false;
  private _journeyActivity: JourneyActivity = 'Out';
  private _currentJourneyStep: JourneyStep;
  private _formSource: DynafEnum;
  private _fileNameUpdatedInDescription: boolean = false;

  constructor(
    private _elementHelper: ElementHelperService,
    private _dataModelHelper: DataModelHelperService,
    private _formRest: FormRestService,
    private _catalog: CatalogService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _replacer: ReplacerService,
    private _navigationHelper: NavigationHelperService,
    private _fileHandler: FileHandlerService,
    private _router: Router,
    private _journeyHandler: JourneyHandlerService,
    private _activatedRoute: ActivatedRoute) { }

  public isElementValid(event: ElementResponse) {
    this.change.emit({ name: event.name, value: event.value });
  }

  public isType(fieldType: string, typeToCheck: string | Array<string>): boolean {
    const typesToCheck = [];

    Array.isArray(typeToCheck)
      ? typesToCheck.push(...typeToCheck)
      : typesToCheck.push(typeToCheck);

    return typesToCheck.includes(fieldType);
  }

  public setAsDirty(event: any) {
    this.dirty.emit(true);
  }

  public getContainerId(config: FormDefinition) {
    return config.title.toLocaleUpperCase().replace(/\s+/g, '_');
  }

  public onMultiselectChanged(items: any, item: any) {
    this.dirty.emit(true);

    const inputResponse = {} as ElementResponse;

    inputResponse.name = item.attributes.name;
    inputResponse.valid = (item.attributes.required && item.value && item.value.length > 0) || !item.attributes.required;
    inputResponse.value = item.value;

    this.isElementValid(inputResponse);

    this._setOwnerPartyRoleValue(inputResponse);
  }

  public trackByFn(index: number, item: any) {
    return item.field;
  }

  public getColumnDistribution(type: string) {
    const fullColumnDistribution = [
      'grid-catalog-single', 'grid-catalog-multiple',
      'rules-grid', 'relationships-grid', 'entity-selector-grid',
      'navigation-rules', 'grid-viewer', 'button-form-preview',
      'signature-viewer-group'];
    const halfColumnDistribution = [];

    if (fullColumnDistribution.includes(type)) {
      return 'col-xs-12';
    } else if (halfColumnDistribution.includes(type)) {
      return 'col-xs-6';
    } else {
      if (this.fieldsDistribution === 'page') {
        return 'col-xs-12 col-sm-6 col-md-3';
      } else {
        return 'col-xs-12 col-sm-6';
      }
    }
  }

  public onMultiselectItemCheckd(event: any) {
    this._tryToLoadDynamicGroupFromServer(event.item);
  }

  public handleActionButtonTriggered(actionTriggered: Action) {
    const instanceCode = this._fieldValueHandler.getDetailCode();

    switch (actionTriggered) {
      case Action.Upload:
        this._navigationHelper.goToBookFileUploadView(instanceCode);
        break;
      case Action.Download:
        this._fileHandler.downloadBookTemplate(
          instanceCode,
          this._currentJourneyStep && this._currentJourneyStep.code);
        break;
      default:
        break;
    }
  }

  ngOnInit() {
    this.dirty.emit(false);
    this._subscriptions.push(
      this._journeyHandler.journeyActivity$
        .subscribe(journeyAction => this._journeyActivity = journeyAction === 'Start' ? 'In' : 'Out')
    );

    this._subscriptions.push(
      this._journeyHandler.currentJourneyStep$
        .subscribe(currentJourneyStep => this._currentJourneyStep = currentJourneyStep)
    );

    this._subscriptions.push(
      this._activatedRoute.params
        .subscribe(params => this._formSource = params['source'])
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
    Object.keys(this._valueChangesForFieldSubscriptions)
      .forEach(prop => this._valueChangesForFieldSubscriptions[prop].unsubscribe());

    if (this._fileNameUpdatedInDescription) {
      this._fileHandler.setFileUploadedName(undefined);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this._populateTriggerableFields();
      this._populateFilterableFields();
      this._prepareFields();
      this._prepareConfigurations();
      this._updateValueAndValidity();
    }

    if (changes.externalEvent && changes.externalEvent.currentValue) {
      this._setOwnerPartyRoleValue(this.externalEvent as ElementResponse);
      this._checkCascadeCatalogs(changes.externalEvent.currentValue);
      this._validateTriggerable({
        field: this.externalEvent.name,
        value: this.externalEvent.value
      } as FieldDefinition);
    }
  }

  ngAfterViewInit() {
    if (this._initialReplacerList) {
      this._replacer.initReplacerListFromEdit(this._initialReplacerList);
    }
  }

  private _setOwnerPartyRoleValue(event: ElementResponse) {
    if (event.name === FieldEnum.OwnerPartyRoleInstanceCode && event.value) {
      this._fieldValueHandler.setOwnerPartyRoleValue(event.value[0]);
    }
  }

  private _setCurrentStatusCodeValue(field: FieldDefinition) {
    if (field.field === FieldEnum.CurrentStatusCode) {
      this._fieldValueHandler.setCurrentStatusCodeValue(field.value);
    }
  }

  private _setCurrentEnvironmentStatusCodeValue(field: FieldDefinition) {
    if (field.field === FieldEnum.CurrentStatusCode) {
      this._fieldValueHandler.setCurrentStatusCodeValue(field.value);
    }
  }

  private _tryToLoadDynamicGroupFromServer(item: EntityCatalogItem) {
    if (item.associatedDynaf) {
      this._formRest.getFormDefinitionView(item.associatedDynaf)
        .subscribe(dynaf => {
          console.log(dynaf);
        });
    }
  }

  private _updateValueAndValidity() {
    this.form.updateValueAndValidity();
    Object.keys(this.form.controls)
      .forEach(prop => {
        this.form.controls[prop].updateValueAndValidity()
      });
  }

  private _checkCascadeCatalogs(event: { name: string, value: any, stopPropagation?: boolean }) {
    const chainedCatalogs = this._filterableFields.filter(x => x.filterBy == event.name);
    let updated = false;

    chainedCatalogs.forEach(chainedCatalog => {
      const completeItem = this._fieldValueHandler.getAnyValue().changes.get(chainedCatalog.filterBy);
      let eventValue = event.value;

      if (completeItem && chainedCatalog.filterByProperty) {
        eventValue = completeItem[0][chainedCatalog.filterByProperty];
      }

      if (eventValue) {
        const filterValue = deepClone(this._getValueForFilter(eventValue, chainedCatalog.triggerValueProperty));

        this._updateCascadedField(chainedCatalog, filterValue);
        updated = true;
      }
    });

    if (updated) return;

    if (this.inputFields) {
      const triggerField = this.inputFields.find(x => x.field === event.name);

      if (triggerField && triggerField.bubbleEvent && !event.stopPropagation) {
        this.bubbledEvent.next({
          name: event.name,
          value: deepClone(event.value)
        });
      }
    }
  }

  private _updateCascadedField(chainedCatalog: FieldDefinition, filterValue: any) {
    if (filterValue) {
      // maybe show ghost loading for the field
      this.elementConfigurations[chainedCatalog.field].loading = true;
      this._catalog.getSingleWithFilterFromServer(chainedCatalog.catalog, filterValue)
        .subscribe(catalog => {
          if (catalog && catalog.items) {
            this._setNewItemsToCatalog(chainedCatalog, catalog);
          }
          this.elementConfigurations[chainedCatalog.field].loading = false;
        });
    }
  }

  private _setNewItemsToCatalog(chainedCatalog: FieldDefinition, catalog: EntityCatalog) {
    const newItems = this._catalog.getSimpleCatalogDescriptionObject(catalog.items);
    const currentItems = deepClone(chainedCatalog.catalogItems);
    const itemsAreEqual = areListEquals(
      newItems.map(x => x.code),
      currentItems.map((x: any) => x.code)
    );

    // actualizo los items solo si los items a asignar son diferentes
    if (!itemsAreEqual) {
      chainedCatalog.catalogItems = newItems;
      this.elementConfigurations[chainedCatalog.field].items = newItems;

      // hack ? if not, multiselect cascade filter wont work
      this.elementConfigurations[chainedCatalog.field] = Object.assign({}, this.elementConfigurations[chainedCatalog.field]);
    }

    if (this.data && this.form.controls[chainedCatalog.field]) {
      const items = this._getItemFromCatalog(catalog, chainedCatalog);

      setTimeout(() => {
        this.form.controls[chainedCatalog.field].setValue(items && items.length === 0 ? undefined : items);
      });

    }
  }

  private _getItemFromCatalog(catalog: EntityCatalog, chainedCatalog: FieldDefinition) {
    if (chainedCatalog.catalog === 'PRODUCT_BY_PRODUCT_PROVIDER') {
      const selectedCodes = this._getProp(this.data, chainedCatalog.field)
        .map((x: any) => x.productInstanceCode);
      return catalog.items
        .filter(x => selectedCodes.includes(x.code))
        .map(x => x.code);
    }

    const propValue = this._getProp(this.data, chainedCatalog.field);
    let dataCodes = [];

    if (Array.isArray(propValue)) {
      dataCodes = this._getProp(this.data, chainedCatalog.field)
        .map((x: any) => x.code);
    } else {
      dataCodes = [propValue];
    }

    return catalog.items
      .filter(x => dataCodes.includes(x.code))
      .map(x => x.code);
  }

  private _getValueForFilter(value: any, prop: string) {
    if (prop) {
      return typeof (value[prop]) == 'object'
        ? typeof (value[prop][0]) == 'object' ? value[prop][0].code : value[prop][0]
        : value[prop];
    } else {
      return typeof (value) == 'object' ? value[0] : value;
    }
  }

  private _getProp(data: any, propName: string) {
    return this._dataModelHelper.getValueByPropertyFullName(data, propName);
  }

  private _prepareFields() {
    // multiselect hack
    const catalogMultiple = this.config.fields.filter(field => ['catalog-single', 'catalog-multiple'].includes(field.type));
    catalogMultiple.forEach(field => this.onMultiselectChanged(null, field));

    // send textares to the end
    // const textareas = this.config.fields.filter(field => ['textarea', 'textarea-lang', 'textarea-form'].includes(field.type));
    // textareas.forEach(field => field.order = field.order + this.config.fields.length);

    // send sortable lists after textareas
    const sortableLists = this.config.fields.filter(field => field.type === 'sortable-list');
    sortableLists.forEach(field => field.order = field.order + this.config.fields.length);
    // sortableLists.forEach(field => field.order = field.order + this.config.fields.length + textareas.length);

    const tabbedRuleCreators = this.config.fields.filter(field => field.type === 'tabbed-rule-creator');
    const fileUploaders = this.config.fields.filter(field => field.type === 'file-upload');
    const uploadFileProcessings = this.config.fields.filter(field => field.type === 'file-processing');
    const backgroundJobDetails = this.config.fields.filter(field => field.type === 'background-job-detail');
    const buttonActionList = this.config.fields.filter(field => field.type === 'button-action');

    this.config.fields = this.config.fields.sort((a, b) => sort(a.order, b.order));

    this.inputFields = this.config.fields
      .filter(x => !['sortable-list', 'chained-filter'].includes(x.type)
        // .filter(x => !['textarea', 'textarea-lang', 'textarea-form', 'sortable-list', 'chained-filter'].includes(x.type)
        && !x.fieldGroup);
    this.inputFields.forEach(field => {
      if (field.triggerBy) {
        field.attributes.hidden = true;
      }
    });

    // this.textareaFields = textareas.sort((a, b) => sort(a.order, b.order));
    this.sortableLists = sortableLists.sort((a, b) => sort(a.order, b.order));

    this.chainedFilterConfig = this.config.fields.find(x => x.type === 'chained-filter');

    this.tabbedRuleCreators = tabbedRuleCreators;
    this.fileUploaders = fileUploaders;
    this.uploadFileProcessings = uploadFileProcessings;
    this.backgroundJobDetails = backgroundJobDetails;
    this.buttonActionList = buttonActionList;
  }

  private _prepareConfigurations() {
    this.elementConfigurations = this._elementHelper.getElementConfigurations(this.config.fields);

    Object.keys(this.elementConfigurations)
      .forEach(key => {
        const element = this.config.fields.find(field => field.field === key);

        if (this.elementConfigurations[key]) {
          if (this.disabled || !element.attributes.editable) {
            this.elementConfigurations[key].readonly = true;
          }

          // hack... 5 minutes later: hack?
          if (element.catalog && !element.filterBy) {
            if (!element.catalogItems || element.catalogItems.length === 0) {
              this._getCustomCatalog(element);
            }
          }

          if (this.editMode) {
            this.elementConfigurations[key].readonly =
              this.elementConfigurations[key].readonly || element.readonlyOnEdit;
          }
        }

      });

    this._createFormGroupObject();
  }

  private _getCustomCatalog(input: FieldDefinition) {
    const catalog = this._catalog.get(input.catalog);

    if (catalog && catalog.length > 0) {
      this._setCustomCatalogItems(input, catalog)
    } else {
      this._catalog.getFromServer([input.catalog])
        .subscribe(catalog => this._setCustomCatalogItems(input, catalog[0].items));
    }
  }

  private _setCustomCatalogItems(input: FieldDefinition, items: Array<EntityCatalogItem>) {
    input.catalogItems = items;
    this.elementConfigurations[input.field].items = items;
  }

  private _createFormGroupObject() {
    let group: any = {};

    this.config.fields.forEach(field => {
      const isTriggerable = this._triggerableFields.find(x => x.field === field.field);
      if (!isTriggerable) {
        group[field.attributes.name] = this._createFormControl(field);
      }

      this._notifyReplacerCodes(field);
    });

    this.form = new FormGroup(group);

    this._createFormAndFieldSubscriptions();
  }

  private _notifyReplacerCodes(field: FieldDefinition) {
    if (field.replacer) {
      const replacerList = this.config.fields.find(x => x.field === field.replacer.fieldDto).value;

      if (replacerList) {
        this._initialReplacerList = replacerList
          .map(e => {
            return { code: e.code, name: e.description }
          });
      }
    }
  }

  private _createFormAndFieldSubscriptions() {
    Object.keys(this.form.controls)
      .forEach(prop => {
        this._addValueChangeSubcription(prop);
        const fieldDefinition = this.config.fields.find(field => field.field === prop);
        this._addReplacerListSelectionSubscription(fieldDefinition);
        this._addButtonActionSubscription(fieldDefinition);
      });

    this._subscriptions.push(this.form.statusChanges
      .pipe(distinctUntilChanged())
      .subscribe(status => {
        const code = this.config.title.toLocaleLowerCase().replace(/ /g, '_');
        this.isValid.emit({ id: this.config.id, code: code, valid: status === 'VALID' });
      }));
  }

  private _validateTriggerable(field: FieldDefinition) {
    const triggerableArray = this._triggerableFields.filter(x => x.triggerBy.field == field.field);
    const anyValue = this._fieldValueHandler.getAnyValue();

    // if a field to trigger exists
    triggerableArray.forEach(triggerable => {
      let valuesToValidate: Array<string> = field.value || [];

      if (triggerable.triggerBy.fieldToCompare) {
        const triggeredField = anyValue.changes.get(triggerable.triggerBy.field) || [];

        valuesToValidate = triggeredField.map((x: any) => x[triggerable.triggerBy.fieldToCompare]);
      }

      let mustTriggerField = triggerable.triggerBy.codes.some(c => valuesToValidate.includes(c));

      triggerable.attributes.hidden = !mustTriggerField;

      // first simulate change. the removed or added field maybe a trigger of another field
      const elementResponse = {
        name: triggerable.field,
        value: mustTriggerField ? deepClone(triggerable.value) : undefined
      } as ElementResponse;

      this._checkCascadeCatalogs(elementResponse);

      const simulatedField = deepClone(triggerable);
      simulatedField.value = mustTriggerField ? simulatedField.value : undefined;
      this._validateTriggerable(simulatedField);
      ///////////////////////////////////////////////////////////////

      if (mustTriggerField) {
        const existsInFields = this.inputFields.filter(x => x.field === triggerable.field).length > 0;
        const existsInForm = this.form.controls[triggerable.field] !== undefined;

        if (!existsInForm) {
          this.form.addControl(triggerable.field, this._createFormControl(triggerable));
        }

        this._addValueChangeSubcription(triggerable.field);

        if (!existsInFields) {
          this._insertFieldByOrder(triggerable);
          this._addReplacerListSelectionSubscription(triggerable);
          this.onMultiselectChanged(null, triggerable)
        }
      } else {
        this.form.removeControl(triggerable.field);
        this._deleteField(triggerable);
      }
    });
  }

  private _addValueChangeSubcription(fieldName: string) {
    // unsubscribe if exists
    if (this._valueChangesForFieldSubscriptions[fieldName]) {
      this._valueChangesForFieldSubscriptions[fieldName].unsubscribe();
    }

    this._valueChangesForFieldSubscriptions[fieldName] = this.form.controls[fieldName].valueChanges
      .subscribe(value => {
        const found = this.config.fields.find(f => f.field == fieldName);
        found ? found.value = value : console.error(`field ${fieldName} not found`);

        this._setValueForDynamicField(value, found);

        if (found) {
          this._informToValueHandler(found);
          this._setCurrentStatusCodeValue(found);
          this._setCurrentEnvironmentStatusCodeValue(found);

          const elementResponse = {
            name: found.field,
            value: deepClone(found.value)
          } as ElementResponse;

          this._setCampaignType(found);
          this._setFileGroupType(found);
          this._checkCascadeCatalogs(elementResponse);
          this._validateTriggerable(found);
          this._setDynamicFormSection(found);
          this._setTenantCodeTargetForBookPromotion(found);
          this._setIntegrationCommand(found);
        }
      });

    // force valueChanges
    this.form.controls[fieldName].updateValueAndValidity();
  }

  private _setCampaignType(found: FieldDefinition) {
    const triggerableField = this.config.fields.find(x => x.field === 'campaignLibraryTypeCode');

    if (found.field === 'workEffortTypeCode' && found.value && triggerableField) {
      switch (found.value[0]) {
        case 'FLW.TPV':
          triggerableField.value = ["CMP.TPV"];
          break;
        case 'FLW.ORD':
          triggerableField.value = ["CMP.PRD"];
          break;
        case 'FLW.ECST_ORD_CRT':
          triggerableField.value = ["CMP.ECST_ORD_CRT"];
          break;
        case 'FLW.ECST_ORD_CLS':
          triggerableField.value = ["CMP.ECST_ORD_CLS"];
          break;
        case 'FLW.ECST_ORD_INIT':
          triggerableField.value = ["CMP.ECST_ORD_INIT"];
          break;
      }

      if (this.form.controls['campaignLibraryTypeCode']) {
        this.form.controls['campaignLibraryTypeCode'].setValue(triggerableField.value);
      }
    }
  }

  private _setFileGroupType(fieldDefinition: FieldDefinition) {
    const path = this._router.url;
    const libraryCode = this._router.url.substring(this._router.url.toString().lastIndexOf('/') + 1);

    if (fieldDefinition.field === 'fileGroups' && !fieldDefinition.value && libraryCode == DynafEnum.FormView) {
      const value = fieldDefinition.catalogItems.find(item => item.description === 'Generic Form Asset');
      if (value) {
        fieldDefinition.value = [value.code];

        if (this.form.controls['fileGroups']) {
          this.form.controls['fileGroups'].setValue([value.code]);
        }
      }
    }
  }

  private _setValueForDynamicField(value: string, fieldDefinition: FieldDefinition) {
    if (fieldDefinition && value == null && this.config.dynamicSection) {
      const valuePersisted = this._fieldValueHandler.getAnyValue().changes.get(fieldDefinition.field);

      if (valuePersisted) {
        fieldDefinition.value = valuePersisted;

        if (this.form.controls[fieldDefinition.field]) {
          this.form.controls[fieldDefinition.field].setValue(valuePersisted);
        }
      }
    }
  }

  private _setDynamicFormSection(fieldDefinition: FieldDefinition) {
    if (fieldDefinition.field === 'metadata.instanceCode' && fieldDefinition.value) {
      if (fieldDefinition.value.length > 0) {

        setTimeout(() => {
          const genericFormDefinition = fieldDefinition.catalogItems.find(x => x.code == fieldDefinition.value[0])

          if (this._journeyActivity === 'In') {
            this._hideStaticGroups = true;
          }

          this.formSelected.next({ formDefinition: genericFormDefinition.formDefinition.definition, hideStaticGroups: this._hideStaticGroups });
          this._hideStaticGroups = false;

          this._fieldValueHandler.setGenericFormValues('genericFormInstanceName', genericFormDefinition.name);
        });

      } else {
        this.formSelected.next(null);
      }
    }
  }

  private _setTenantCodeTargetForBookPromotion(fieldDefinition: FieldDefinition) {
    if (this._formSource === DynafEnum.TenantSelection && fieldDefinition.field === 'code') {
      this._journeyHandler.updateContext(JourneyContextQueryStr.TenantCodeTarget, fieldDefinition.value);
    }
  }

  private _setIntegrationCommand(fieldDefinition: FieldDefinition) {
    if (this._formSource === DynafEnum.IntegrationCommandSelection) {
      if(fieldDefinition.field === 'operation') {
        let integrationCommand = {operation: fieldDefinition.value[0], batchSizePerMinute: this.form.controls['batchSizePerMinute'].value, parameters: {}};
        sessionStorage.setItem(CacheKeyEnum.IntegrationCommand, JSON.stringify(integrationCommand));
      }
      else if(fieldDefinition.field === 'batchSizePerMinute') {
        let integrationCommand = JSON.parse(sessionStorage.getItem(CacheKeyEnum.IntegrationCommand));
        integrationCommand.batchSizePerMinute = fieldDefinition.value;
        sessionStorage.setItem(CacheKeyEnum.IntegrationCommand, JSON.stringify(integrationCommand));
      }       
      else {
        this._buildIntegrationCommandParameters();
      }
    }
  }

  private _buildIntegrationCommandParameters() {
    let integrationCommand = JSON.parse(sessionStorage.getItem(CacheKeyEnum.IntegrationCommand));

    Object.keys(this.form.controls).forEach(key => {
      integrationCommand.parameters[key] = this.form.controls[key].value;
    });

    sessionStorage.setItem(CacheKeyEnum.IntegrationCommand, JSON.stringify(integrationCommand));
  }

  private _setInitialStatus(field: FieldDefinition, status: Array<string>) {
    if (field.field === 'currentStatus.code' && !this.editMode) {
      field.value = status;
    }
  }

  private _setEffectiveDateAsToday(field: FieldDefinition) {
    if (field.field === 'effectiveDateFrom' && !this.editMode) {
      const date = this._getDate();
      field.value = date;
    }
  }

  private _getDate() {
    const todayDate = new Date();
    const day = todayDate.getDate().toString().length == 1
      ? `0${todayDate.getDate()}` : todayDate.getDate();
    const month = (todayDate.getMonth() + 1).toString().length == 1
      ? `0${todayDate.getMonth() + 1}` : todayDate.getMonth() + 1;
    const year = todayDate.getFullYear();

    return `${month}/${day}/${year}`;
  }

  private _informToValueHandler(field: FieldDefinition) {
    this._fieldValueHandler.setAnyValue(field.field, field);
  }

  private _addReplacerListSelectionSubscription(field: FieldDefinition) {
    if (field && field.replacer) {
      this._subscriptions.push(this._replacer.replacerListSelected$
        .subscribe(replacerList => {
          const codeList = replacerList.map(x => { return { code: x.replacerCode } });
          this.config.fields.find(f => f.field === field.replacer.fieldDto).value = codeList;
        }));
    }
  }

  private _addButtonActionSubscription(fieldDefinition: FieldDefinition) {
    if (fieldDefinition && fieldDefinition.type === 'button-action') {
      this._subscriptions.push(this._fileHandler.fileUploadedName$
        .subscribe((fileName: string) => {
          const dateObj = new Date();
          const dateTime = `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
          this.config.description = `${fileName} file uploaded at ${dateTime}`;
          this._fileNameUpdatedInDescription = true;
        }));
    }
  }

  private _insertFieldByOrder(field: FieldDefinition) {
    if (field.fieldGroup) {
      this.inputFields.push(field);
      this.inputFields.sort((a, b) => a.order - b.order);
    }
  }

  private _deleteField(field: FieldDefinition) {
    if (field.fieldGroup) {
      this.inputFields = this.inputFields.filter(f => f.field !== field.field);
    }

    // simulate a change with undefined value to trigger cascade triggers
    const simulatedField = deepClone<FieldDefinition>(field);

    simulatedField.value = undefined;

    this._validateTriggerable(simulatedField);
  }

  private _populateTriggerableFields() {
    this.config.fields.forEach(field => {
      if (field.triggerBy) {
        this._triggerableFields.push(field);
      }
    });
  }

  private _populateFilterableFields() {
    this.config.fields.forEach(field => {
      if (field.filterBy) {
        this._filterableFields.push(field);
      }
    });
  }

  private _createFormControl(field: FieldDefinition) {
    const validators = this.elementConfigurations[field.field] && this.elementConfigurations[field.field].validators as ValidatorFn;
    const asyncValidators = this.elementConfigurations[field.field] && this.elementConfigurations[field.field].asyncValidators as AsyncValidatorFn;

    this._setInitialStatus(field, ['ACTIVE']);
    this._setEffectiveDateAsToday(field);

    return new FormControl(deepClone(field.value), {
      validators: validators,
      asyncValidators: asyncValidators
    });
  }
}
