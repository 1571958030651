/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ghost-loading.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./_ghost-loading-aggrid/ghost-loading-aggrid.component.ngfactory";
import * as i3 from "./_ghost-loading-aggrid/ghost-loading-aggrid.component";
import * as i4 from "./_ghost-loading-dashboard/ghost-loading-dashboard.component.ngfactory";
import * as i5 from "./_ghost-loading-dashboard/ghost-loading-dashboard.component";
import * as i6 from "./_ghost-loading-dynamic-form/ghost-loading-dynamic-form.component.ngfactory";
import * as i7 from "./_ghost-loading-dynamic-form/ghost-loading-dynamic-form.component";
import * as i8 from "./_ghost-loading-field/ghost-loading-field.component.ngfactory";
import * as i9 from "./_ghost-loading-field/ghost-loading-field.component";
import * as i10 from "@angular/common";
import * as i11 from "./ghost-loading.component";
var styles_GhostLoadingComponent = [i0.styles];
var RenderType_GhostLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GhostLoadingComponent, data: {} });
export { RenderType_GhostLoadingComponent as RenderType_GhostLoadingComponent };
function View_GhostLoadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading-aggrid", [], null, null, null, i2.View_GhostLoadingAggridComponent_0, i2.RenderType_GhostLoadingAggridComponent)), i1.ɵdid(1, 114688, null, 0, i3.GhostLoadingAggridComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_GhostLoadingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading-dashboard", [], null, null, null, i4.View_GhostLoadingDashboardComponent_0, i4.RenderType_GhostLoadingDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i5.GhostLoadingDashboardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_GhostLoadingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading-dynamic-form", [], null, null, null, i6.View_GhostLoadingDynamicFormComponent_0, i6.RenderType_GhostLoadingDynamicFormComponent)), i1.ɵdid(1, 114688, null, 0, i7.GhostLoadingDynamicFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_GhostLoadingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading-field", [], null, null, null, i8.View_GhostLoadingFieldComponent_0, i8.RenderType_GhostLoadingFieldComponent)), i1.ɵdid(1, 114688, null, 0, i9.GhostLoadingFieldComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_GhostLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GhostLoadingComponent_1)), i1.ɵdid(1, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GhostLoadingComponent_2)), i1.ɵdid(3, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GhostLoadingComponent_3)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GhostLoadingComponent_4)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "aggrid"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type === "dashboard"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.type === "dynamic-form"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.type === "catalog"); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_GhostLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading", [], null, null, null, View_GhostLoadingComponent_0, RenderType_GhostLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i11.GhostLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GhostLoadingComponentNgFactory = i1.ɵccf("app-ghost-loading", i11.GhostLoadingComponent, View_GhostLoadingComponent_Host_0, { type: "type" }, {}, []);
export { GhostLoadingComponentNgFactory as GhostLoadingComponentNgFactory };
