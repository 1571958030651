import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class JourneyRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    initFlow(flowInstanceCode) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/journey/init`,
            body: { flowInstanceCode: flowInstanceCode }
        });
    }
    getCurrentStep(workeffortExecutionId) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/journey/next/${workeffortExecutionId}`,
        });
    }
    getApprovalFlowNextStep(workeffortExecutionId) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/next/${workeffortExecutionId}`,
        });
    }
    executeStep(context) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/journey/execute-step`,
            body: {
                flowInstanceCode: context.flowInstanceCode,
                entityInstanceCode: context.entityInstanceCode,
                workEffortExecutionId: context.executionId,
                action: context.ruleAction,
                executorUserName: context.executorUserName
            }
        });
    }
}
JourneyRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JourneyRestService_Factory() { return new JourneyRestService(i0.ɵɵinject(i1.RestService)); }, token: JourneyRestService, providedIn: "root" });
