import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ghost-loading-dashboard',
  templateUrl: './ghost-loading-dashboard.component.html',
  styleUrls: ['./ghost-loading-dashboard.component.sass']
})
export class GhostLoadingDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
