/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./textarea-expander.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./textarea-expander.component";
var styles_TextareaExpanderComponent = [i0.styles];
var RenderType_TextareaExpanderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextareaExpanderComponent, data: {} });
export { RenderType_TextareaExpanderComponent as RenderType_TextareaExpanderComponent };
function View_TextareaExpanderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "textarea-expand"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expandClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-expand-arrows-alt fa-stack-1x fa-inverse"]], null, null, null, null, null))], null, null); }
export function View_TextareaExpanderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextareaExpanderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showExpander(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TextareaExpanderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-textarea-expander", [], null, null, null, View_TextareaExpanderComponent_0, RenderType_TextareaExpanderComponent)), i1.ɵdid(1, 114688, null, 0, i3.TextareaExpanderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextareaExpanderComponentNgFactory = i1.ɵccf("app-textarea-expander", i3.TextareaExpanderComponent, View_TextareaExpanderComponent_Host_0, {}, {}, []);
export { TextareaExpanderComponentNgFactory as TextareaExpanderComponentNgFactory };
