import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { Observable } from 'rxjs';
import { EntityCatalog, Catalog } from 'src/app/models/catalog.models';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { CatalogEnum } from 'src/app/enums/catalogs';

@Injectable({
  providedIn: 'root'
})
export class CatalogRestService {

  private readonly _uncacheableCatalogs = new Set<CatalogEnum>();

  constructor(private _rest: RestService) {
    this._uncacheableCatalogs.add(CatalogEnum.DocumentAssets);
    this._uncacheableCatalogs.add(CatalogEnum.FileAssets);
    this._uncacheableCatalogs.add(CatalogEnum.GeographyGrouping);
    this._uncacheableCatalogs.add(CatalogEnum.GeographyGroupingAndState);
    this._uncacheableCatalogs.add(CatalogEnum.Request);
    this._uncacheableCatalogs.add(CatalogEnum.ProductTypes);
    this._uncacheableCatalogs.add(CatalogEnum.ProductProvider);
    this._uncacheableCatalogs.add(CatalogEnum.PartyInstance);
    this._uncacheableCatalogs.add(CatalogEnum.Journeys);
    this._uncacheableCatalogs.add(CatalogEnum.Product);
    this._uncacheableCatalogs.add(CatalogEnum.InstanceByLibraryCode);
    this._uncacheableCatalogs.add(CatalogEnum.BrandOrganization);
  }

  public getCatalogsForDetail(source: string): Observable<Array<EntityCatalog>> {
    return this._rest.get<Array<EntityCatalog>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/${source}/catalogs/`
    });
  }

  public getCatalogs(catalogs: Array<string>): Observable<Array<EntityCatalog>> {
    const headers = new HttpHeaders()
      .append('restCache', this._cacheableCatalog(catalogs as Array<CatalogEnum>) ? 'true' : 'false');

    return this._rest.get<Array<EntityCatalog>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/catalogs/`,
      headers: headers,
      params: { catalogCodes: catalogs }
    });
  }

  public getCatalog(catalog: string, filterBy: Array<string>): Observable<EntityCatalog> {
    return this._rest.get<EntityCatalog>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/catalog/${catalog}`,
      params: { filterBy: filterBy }
    });
  }

  public getFullCatalogs<T>(catalogs: Array<CatalogEnum>): Observable<Array<Catalog<T>>> {
    const headers = new HttpHeaders()
      .append('restCache', this._cacheableCatalog(catalogs) ? 'true' : 'false');

    return this._rest.get<Catalog<T>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/catalogs/`,
      params: { catalogCodes: catalogs },
      headers: headers
    });
  }

  public getFiltrableCatalog<T>(catalog: string, filterBy: Array<string>): Observable<Catalog<T>> {
    return this._rest.get<Catalog<T>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/catalog/${catalog}`,
      params: { filterBy: filterBy }
    });
  }

  private _cacheableCatalog(catalogs: Array<CatalogEnum>) {
    const cacheable = catalogs.every(x => !this._uncacheableCatalogs.has(x))

    return cacheable;
  }
}
