<ng-container *ngIf="model.config">
    <div class="app-file-upload">
        <ng-container *ngIf="!readonly()">
            <div class="mb-4">
                <ngx-file-drop [accept]="model.config.accept" [multiple]="model.config.multiple" [disabled]="false"
                    (onFileDrop)="dropped($event)">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        <div class="icon-text">
                            <p>
                                <i class="fas fa-cloud-upload-alt"></i> Upload File
                            </p>
                            <p class="messagge">Drag the file/s here or press the upload button</p>
                        </div>
                        <button type="button" class="btn btn-fill btn-sm btn-drop-file pull-right my-2"
                            (click)="openFileSelector()">Browse Files</button>
                    </ng-template>
                </ngx-file-drop>
            </div>
        </ng-container>

        <ng-container [formGroup]="form" *ngIf="showTable()">
            <div>
                <table class="table table-hover">
                    <thead *ngIf="getItemsForTable().length > 0">
                        <tr>

                            <td *ngIf="!isBookUpload">Thumbnail</td>
                            <td>Filename</td>
                            <td>Uploaded by</td>
                            <td *ngIf="languageSettingAvailable()">Language</td>
                            <td *ngIf="isBookUpload">Date</td>
                            <td *ngIf="isBookUpload">Status</td>
                            <td *ngIf="!readonly()">Remove</td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let item of getItemsForTable(); let i=index">
                            <tr>
                                <td class="table-thumbnail" *ngIf="!isBookUpload">
                                    <img class="img-thumbnail" [src]="getImageThumbnail(item)">
                                </td>
                                <td class="file-name"><a href="javascript:void(0)" [title]="item.fileName"
                                        (click)="openFile(item)">
                                        {{ item.fileName }} </a></td>
                                <td>{{ item.userName }}</td>
                                <td class="table-emerios-multiselect" *ngIf="languageSettingAvailable()">
                                    <label class="control-label app-input emerios-multiselect">
                                        <emerios-multiselect [config]="getMultiselectConfig(item.fileName)"
                                            [items]="model.langItems" [formControlName]="item.fileName"
                                            (change)="multiselectChanged();">
                                        </emerios-multiselect>
                                    </label>
                                </td>
                                <td *ngIf="isBookUpload">{{dateTime}}</td>
                                <td *ngIf="isBookUpload">Ok</td>
                                <td *ngIf="!readonly()">
                                    <a href="javascript:void(0)" class="btn btn-simple btn-danger btn-filter"
                                        (click)="removeFile(item, i)">
                                        <i class="fa fa-minus-circle fa-2x pull-right"></i>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <div *ngIf="model.showPreview">
            <ng-container *ngFor="let item of getItemsForTable(); let i = index">
                <img [src]="getImage(item)" class="display: block;margin-left: auto;margin-right: auto;">
            </ng-container>
        </div>
    </div>
</ng-container>

<div class="app-input" *ngIf="control && control.errors && !control.pristine">
    <div class="input-error file-upload-error">{{getControlErrors()}}</div>
</div>