<app-tab-group [currentIndex]="currentTabIndex" (tabClicked)="tabClicked($event)">
    <app-tab-item label="Draw It">
        <div class="text-center">
            <div>
                <span>Draw your signature</span>
            </div>
            <div>&nbsp;</div>
                <canvas 
                #signPad
                [width]="width" 
                [height]="height" 
                (mousedown)="onMousedown($event)"
                (mousemove)="onMousemove($event)"
                (mouseup)="onMouseup($event)"
                (mouseleave)="onMouseleave($event)"
                style="border:2px solid black">
            </canvas>
        </div>
    </app-tab-item>
    <app-tab-item label="Type in signature">
        <div>
            <span>Type in your name:</span>&nbsp;
            <input [(ngModel)]="signText"
                type="text" 
                (keyup)="onTextChange($event.target.value)" 
                [attr.maxlength]="textMaxLength"
                class="form-control">
        </div>
        <div>&nbsp;</div>
        <div class="text-center">
            <canvas 
                #signPadText
                [width]="width" 
                [height]="height" 
                style="border:2px solid black">
            </canvas>
            </div>
    </app-tab-item>
    <app-tab-item label="Use Saved">
        <div>
            <span>Use this signature</span>&nbsp;
        </div>
        <div>&nbsp;</div>
        <div class="text-center">
            <canvas 
                #signPadImage
                [width]="width" 
                [height]="height" 
                style="border:2px solid black">
            </canvas>
        </div>
    </app-tab-item>
</app-tab-group>

<div>
    <button (click)="clear()" class="btn btn-primary">Clear</button>&nbsp;
    <button (click)="saveSignature()" class="btn btn-primary btn-fill">Save Signature</button>
</div>
