import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private _authHandler: AuthorizationHandlerService,
    private _navHelper: NavigationHelperService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this._authHandler.isAuthenticated()) {
      if (this._authHandler.isWorkingOrganizationSetted()) {
        this._navHelper.goToDashboard();
      } else {
        this._navHelper.goToTenantSelector();
      }
      return false;
    }

    return true;
  }

}
