/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-modal.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./message-modal.component";
import * as i4 from "ngx-bootstrap/modal";
var styles_MessageModalComponent = [i0.styles];
var RenderType_MessageModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageModalComponent, data: {} });
export { RenderType_MessageModalComponent as RenderType_MessageModalComponent };
function View_MessageModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "text-center my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-check fa-2x success-color"]], null, null, null, null, null))], null, null); }
function View_MessageModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "text-center my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-info-circle fa-2x info-color"]], null, null, null, null, null))], null, null); }
function View_MessageModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "text-center my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-exclamation-triangle fa-2x warning-color"]], null, null, null, null, null))], null, null); }
function View_MessageModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "text-center my-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-times-circle fa-2x error-color"]], null, null, null, null, null))], null, null); }
function View_MessageModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_MessageModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h3", [["class", "modal-title pull-left"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "modal-body campaign-modal"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageModalComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageModalComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageModalComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageModalComponent_4)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "p", [["class", "text-center"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "ul", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageModalComponent_5)), i1.ɵdid(17, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "modal-footer mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-primary btn-fill"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(20, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isSuccess(); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.isInfo(); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.isWarning(); _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.isError(); _ck(_v, 13, 0, currVal_4); var currVal_6 = _co.items; _ck(_v, 17, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); var currVal_5 = _co.message; _ck(_v, 14, 0, currVal_5); var currVal_7 = ((_co.okButton == null) ? null : _co.okButton.text); _ck(_v, 20, 0, currVal_7); }); }
export function View_MessageModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-modal", [], null, null, null, View_MessageModalComponent_0, RenderType_MessageModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessageModalComponent, [i4.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageModalComponentNgFactory = i1.ɵccf("app-message-modal", i3.MessageModalComponent, View_MessageModalComponent_Host_0, {}, {}, []);
export { MessageModalComponentNgFactory as MessageModalComponentNgFactory };
