import { BaseDataModel } from '../base-data-model.model';
import * as moment from 'moment-mini';

export class InputDateDataModelService implements BaseDataModel {

  constructor() { }

  public setValueToField(payloadValue: any) {
    return payloadValue
      ? moment(payloadValue).format('MM/DD/YYYY')
      : undefined;
  }

  public getValueFromField(fieldValue: any) {
    return fieldValue;
  }

}
