<div class="modal-header">
    <h3 class="modal-title pull-left" [innerHtml]="title"></h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body campaign-modal grid-viewer">
    <ng-container>
        <div>
            <p *ngIf=discardChangesWarning class="my-4">              
                You are going to Discard Changes of the current entity. Associations with this entities are going to be Discared
            </p>
            <p *ngIf=discardChangesBlocker class="my-4">              
                <i class="fas fa-exclamation-triangle"></i>
                the Entity cannot be Discared because has the following association conflicts
            </p>
            <p *ngIf=cancelRequestWarning class="my-4">              
                You are going to Discard Changes of the entities listed below. Associations with these entities are going to be Discared
            </p>
            <div *ngIf=cancelRequestBlocker class="text-center alert-text">
                <span class="text-danger mb-2 d-block">
                    <i class="fas fa-exclamation-triangle"></i>
                </span>
                <p class="mb-4">              
                    the Request cannot be Canceled because has the following association conflicts
                </p>
                <hr class="dashed-line">
            </div>
        </div>
        <div class="grid grid-60 mb-4">
            <app-grid [data]="gridDataModel"></app-grid>
        </div>

        <div class="my-2">
            <a class="btn btn-simple pl-0">
                <i class="fas fa-download"></i>
                Download CSV with list of discard changes
            </a>
        </div>
    </ng-container>
 </div>

<div class="modal-footer">
    <button type="button" class="btn btn-next" (click)="closeModal()">Close</button>
    <button type="button" *ngIf="discardChangesWarning" class="btn btn-primary btn-fill"
        (click)="executeCallback()">Discard Changes</button>
    <button type="button" *ngIf="cancelRequestWarning" class="btn btn-primary btn-fill"
        (click)="executeCallback()">Cancel Request</button>
</div>