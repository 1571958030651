import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, FormGroup, Validators } from '@angular/forms';
import { ElementResponse, InputConfig, MultiselectConfig, TextareaConfig, ValidationMessages } from 'ngx-emerios-all';
import { InputLangConfig } from './input-lang.model';
import { KeyValue } from 'src/app/types/keyvalue.type';
import { Subscription } from 'rxjs';

const noOp = () => { };

export const CUSTOM_INPUTDP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputLangComponent),
  multi: true
};

export interface InputLangModel {
  selectedText: string;
}

@Component({
  selector: 'app-input-lang',
  templateUrl: './input-lang.component.html',
  styleUrls: ['./input-lang.component.sass'],
  providers: [CUSTOM_INPUTDP_CONTROL_VALUE_ACCESSOR]
})
export class InputLangComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public forceValidation: EventEmitter<any>;
  @Input() public config: InputLangConfig;
  @Input() public type: 'input' | 'textarea';
  @Input() public items: Array<any>;

  @Output("is-valid") public isValid: EventEmitter<ElementResponse> = new EventEmitter();
  @Output() public onActionButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() public onItemSelect: EventEmitter<any> = new EventEmitter();

  public control: FormControl;

  public showInputRequiredIcon: boolean;

  public multiselectItems: Array<any>;

  public inputConfig: InputConfig;
  public textareaConfig: TextareaConfig;
  public multiselectConfig: MultiselectConfig;

  public model = {} as InputLangModel;
  public form: FormGroup;

  private _innerModel: KeyValue = {};
  private _preselectedLangCode: any;
  private _subscriptions: Array<Subscription> = [];
  private _onChange: any;

  writeValue(obj: any): void {
    if (obj != this.control.value) {
      this._innerModel = obj;
      this._setControlValue();

      this._preselectedLangCode = Object.keys(this._innerModel)[0];

      if (this._preselectedLangCode) {
        let valueObj = {
          langCode: [this._preselectedLangCode],
          inputText: '',
          textareaText: ''
        };
        valueObj[this._getInputProp()] = this._innerModel[this._preselectedLangCode] || '';
        this.form.setValue(valueObj);
      }

    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  onTouchedCallback: () => void = noOp;

  constructor() { }

  public actionButtonClicked(event: any) {
    if (this.onActionButtonClick) {
      this.onActionButtonClick.emit(event);
    }
  }

  public onMultiselectItemChecked(event: any) {
    if (this.type === 'input') {
      this.inputConfig.readonly = this.config.readonly;
    } else {
      this.textareaConfig.readonly = this.config.readonly;
    }

    if (this.onItemSelect) {
      this.onItemSelect.emit(event);
    }

    this._updateButtonText(event.item);
    this._setControlValue();

    const textValue = this._innerModel[this.form.controls['langCode'].value[0]];
    this.form.controls[this._getInputProp()].setValue(textValue);

  }

  public inputValueChanged(event: string) {
    this._innerModel[this.form.controls['langCode'].value[0]] = this.form.controls[this._getInputProp()].value;
    this._setControlValue();
    this.control.markAsDirty();
  }

  public getInputLangErrors() {
    let errorMessage: string;

    if (this.control.errors) {
      const errors = Object['keys'](this.control.errors)

      errors.forEach(error =>
        errorMessage = this.config.validationMessages && this.config.validationMessages[error] || '');
    }

    return errorMessage;
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this._initializeComponents();
    }

    if (changes.forceValidation && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        }));
    }

    if (changes.items && changes.items.currentValue) {
      this.multiselectItems = this.items;

      let preselectedLangItem: any;
      if (this._preselectedLangCode) {
        preselectedLangItem = this.items.find(x => x.code === this._preselectedLangCode);
      } else {
        preselectedLangItem = this.items[0];
        this._setSelectedLang(this.items[0].code);
      }
      this._updateButtonText(preselectedLangItem);
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _initializeComponents() {
    this._initializeFormControl();
    this._initializeInput();
    this._initializeMultiselect();

    if (this.config.validators) {
      setTimeout(() => {
        this.showInputRequiredIcon = (this.config.validationMessages && this.config.validationMessages.required !== undefined);
        this.control.setValidators(this.config.validators);
      });
    }

    this._createFormGroupObject();
    this._setSelectedLang(this.config.items && this.config.items[0].code);

    if (this.inputConfig) {
      this.inputConfig.readonly = this.config.readonly;
    }
    if (this.textareaConfig) {
      this.textareaConfig.readonly = this.config.readonly;
    }
    this.multiselectConfig.readonly = this.config.readonly;
  }

  private _initializeFormControl() {
    let isRequired = false;

    if (this.config.validators) {
      this.config.validators.forEach(x => {
        isRequired = !isRequired && x.name === 'required';
      });
    }

    if (isRequired) {
      this.control = new FormControl(undefined, Validators.required);
    } else {
      this.control = new FormControl(undefined);
    }
  }

  private _initializeInput() {
    const config = {} as InputConfig;

    config.id = `input_${this.config.id}`;
    config.name = `input_${this.config.name}`;
    config.type = this.config.type;
    config.cssClasses = this.config.cssClasses;
    config.placeholder = this.config.placeholder;
    config.autocomplete = this.config.autocomplete;
    config.disabled = this.config.disabled;
    config.regexOnInput = this.config.regexOnInput;
    config.errorMsgClass = this.config.errorMsgClass;
    config.validators = [];
    // config.validationIcons = this.config.validationIcons;
    // config.validationMessages = this.config.validationMessages;

    if (this.type === 'input') {
      this.inputConfig = config;
      this.inputConfig.mask = this.config.mask;
    } else if (this.type === 'textarea') {
      this.textareaConfig = config;
    }
  }

  private _initializeMultiselect() {
    this.multiselectConfig = {} as MultiselectConfig;

    this.multiselectConfig.id = `multiselect_${this.config.id}`;
    this.multiselectConfig.name = `multiselect_${this.config.name}`;
    this.multiselectConfig.cssClasses = 'form-control';
    this.multiselectConfig.itemCode = this.config.itemCode;
    this.multiselectConfig.itemText = this.config.itemText;
    this.multiselectConfig.singleSelect = true;
    this.multiselectConfig.itemsShowLimit = 0;
    this.multiselectConfig.placeholder = '--';
    this.multiselectConfig.validators = [Validators.required];
    this.multiselectConfig.validationMessages = { required: '' } as ValidationMessages;
    this.multiselectItems = this.config.items;
  }

  private _setSelectedLang(langCode: string) {
    if (langCode) {
      this.form.controls['langCode'].setValue([langCode]);
    }
  }

  private _getInputProp() {
    return this.type === 'input' ? 'inputText' : 'textareaText';
  }

  private _createFormGroupObject() {
    this.form = new FormGroup({
      langCode: new FormControl(undefined, this.config.validators),
      inputText: new FormControl(undefined, this.config.validators),
      textareaText: new FormControl(undefined, this.config.validators)
    });
  }

  private _updateButtonText(event: any) {
    const propToShow = this.config.propToShow ? this.config[this.config.propToShow] : this.config['itemText'];
    this.model.selectedText = event[propToShow];
  }

  private _isValueValid() {
    let someValid = false;

    Object.keys(this._innerModel)
      .forEach(prop => {
        if (this._innerModel[prop] && this._innerModel[prop].length > 0) {
          someValid = true;
        }
      });

    return someValid;
  }

  private _setControlValue() {
    const isValid = this._isValueValid();
    let newValue = this._innerModel;

    if (!isValid) {
      newValue = undefined;
    }
    this.control.setValue(newValue);

    if (this._onChange) {
      this._onChange(newValue);
    }
    this._emitValidity();
  }

  private _emitValidity() {
    const isValid = this.control.status == 'VALID';

    this.isValid.emit({
      name: this.config.name,
      value: this.control.value,
      valid: isValid
    });
  }

}
