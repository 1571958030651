<ng-container *ngFor="let btn of buttons">
	<ng-container *ngIf="btn.visible">

		<ng-container *ngIf="!btn.childrens">
			<span *ngIf="btn.startSeparator" class="btn-separator"></span>

			<a href="javascript:void(0)" class="btn btn-icon btn-simple btn-action d-inline-block {{btn.buttonClass}}"
				[ngStyle]="getStyle(btn)" [ngClass]="{'disabled':!btn.enabled}" (click)="clickAction(btn)">
				<i [ngClass]="btn.iconClass"></i> {{btn.text}}
			</a>
			<span *ngIf="btn.endSeparator" class="btn-separator"></span>
		</ng-container>

		<ng-container *ngIf="getVisibleChildrens(btn)">
			<span dropdown class="btn-dropdown-link">
				<a href dropdownToggle [ngClass]="{'disabled':!btn.enabled}"
					class="btn btn-icon btn-simple btn-action d-inline-block {{btn.buttonClass}}" (click)="false"
					aria-controls="dropdown-basic">
					<i *ngIf="btn.iconClass" [ngClass]="btn.iconClass"></i>
					{{btn.text}}
					<i *ngIf="btn.showDropdownIcon" class="fas fa-caret-down"></i>
				</a>
				<ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
					[ngClass]="{'dropdown-menu-right':alignmentMenuToRight}">
					<ng-container *ngFor="let child of btn.childrens">
						<ng-container *ngIf="child.visible">
							<li *ngIf="child.startSeparator" class="divider dropdown-divider"></li>

							<li role="menuitem">
								<a href="javascript:void(0)" class="dropdown-item" [ngStyle]="getStyle(child)"
									[ngClass]="{'disabled':!child.enabled}" (click)="clickAction(child)">
									<i [ngClass]="child.iconClass" class="px-1"></i>{{child.text}}</a>
							</li>
							<li *ngIf="child.endSeparator" class="divider dropdown-divider"></li>
						</ng-container>
					</ng-container>
				</ul>
			</span>
		</ng-container>
	</ng-container>
</ng-container>