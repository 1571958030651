import { EventEmitter } from '@angular/core';
import { AuthRestService } from '../../rest/auth-rest/auth-rest.service';
import { catchError, filter } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { NavigationHelperService } from '../../helper/navigation-helper/navigation-helper.service';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../../rest/auth-rest/auth-rest.service";
import * as i2 from "../../helper/navigation-helper/navigation-helper.service";
import * as i3 from "../dashboard-handler/dashboard-handler.service";
export class AuthorizationHandlerService {
    constructor(_authRest, _navHelper, _dashboardHandler) {
        this._authRest = _authRest;
        this._navHelper = _navHelper;
        this._dashboardHandler = _dashboardHandler;
        this._isAuthenticated = new BehaviorSubject(undefined);
        this._availableTenants = new EventEmitter(undefined);
        this._workingOrganization = new BehaviorSubject(undefined);
        this._successAuth = new EventEmitter();
        this._failedAuth = new EventEmitter();
        this._availablePlatformProviders = new EventEmitter(undefined);
        this.isAuthenticated$ = this._isAuthenticated.asObservable().pipe(filter(x => x !== undefined));
        this.availableTenants$ = this._availableTenants.asObservable().pipe(filter(x => x !== undefined));
        this.workingOrganization$ = this._workingOrganization.asObservable();
        this.successAuth$ = this._successAuth.asObservable();
        this.failedAuth$ = this._failedAuth.asObservable();
        this.availablePlatformProviders$ = this._availablePlatformProviders.asObservable().pipe(filter(x => x !== undefined));
        this._workingOrganization.next(this.getWorkingOrganization());
    }
    authenticate(username, password, platformProviderCode, singleSignOn, recaptchaToken) {
        this.logout();
        this._authRest.login(username, password, platformProviderCode, singleSignOn, recaptchaToken)
            .pipe(catchError((error) => {
            this._failedAuth.next(error.message);
            this._isAuthenticated.next(false);
            return throwError(error);
        }))
            .subscribe((response) => {
            if (response.access_token) {
                sessionStorage.setItem(CacheKeyEnum.AccessToken, response.access_token);
                sessionStorage.setItem(CacheKeyEnum.Username, username);
                this._authRest.getPermissions(platformProviderCode)
                    .subscribe((response) => {
                    sessionStorage.setItem(CacheKeyEnum.Permissions, JSON.stringify(response));
                    this._dashboardHandler.initDasboardItemList();
                });
                this._successAuth.next();
                this._isAuthenticated.next(true);
            }
            else {
                this._failedAuth.next(response.error);
            }
        });
    }
    logout() {
        sessionStorage.removeItem(CacheKeyEnum.AccessToken);
        sessionStorage.removeItem(CacheKeyEnum.Username);
        sessionStorage.removeItem(CacheKeyEnum.WorkingOrganization);
        sessionStorage.removeItem(CacheKeyEnum.Permissions);
        sessionStorage.removeItem(CacheKeyEnum.UserSignature);
    }
    getToken() {
        return sessionStorage.getItem(CacheKeyEnum.AccessToken);
    }
    getCurrentUsername() {
        const currentUser = sessionStorage.getItem(CacheKeyEnum.Username);
        if (!currentUser) {
            this.logout();
            this._navHelper.goToLogin();
            throw 'Current user is not valid';
        }
        return currentUser;
    }
    isAuthenticated() {
        return this.getToken() != undefined;
    }
    setAuthenticated(value) {
        this._isAuthenticated.next(value);
    }
    isWorkingOrganizationSetted() {
        const value = this._workingOrganization.getValue();
        // call set method to force session refresh of current value of observable
        this.setWorkingOrganization(value);
        return value != undefined;
    }
    getAvailableTenants() {
        const username = this.getCurrentUsername();
        this._authRest.getTenants(username)
            .subscribe(tenants => {
            this._availableTenants.next(tenants);
        });
    }
    getWorkingOrganization() {
        return JSON.parse(sessionStorage.getItem(CacheKeyEnum.WorkingOrganization));
    }
    setWorkingOrganization(organization) {
        sessionStorage.setItem(CacheKeyEnum.WorkingOrganization, JSON.stringify(organization));
        this._workingOrganization.next(organization);
    }
    clearWorkingOrganization() {
        sessionStorage.removeItem(CacheKeyEnum.WorkingOrganization);
        this._workingOrganization.next(undefined);
    }
    getAvailablePlatformProviders() {
        this._authRest.getPlatformProviders()
            .subscribe(providers => {
            this._availablePlatformProviders.next(providers);
        });
    }
}
AuthorizationHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationHandlerService_Factory() { return new AuthorizationHandlerService(i0.ɵɵinject(i1.AuthRestService), i0.ɵɵinject(i2.NavigationHelperService), i0.ɵɵinject(i3.DashboardHandlerService)); }, token: AuthorizationHandlerService, providedIn: "root" });
