import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ConfirmSubmissionPayload } from 'src/app/modals/link-change-modal/link-change-modal.component';
import { ChangeManagementRestService } from 'src/app/services/rest/change-management-rest/change-management-rest.service';
import { EntityRestService } from 'src/app/services/rest/entity-rest/entity-rest.service';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { DashboardItem } from '../../../models/dashboard.models';
import { catchError } from 'rxjs/operators';
import { FormDefinition } from 'src/app/models/form-field-definition.models';
import { Entity } from 'src/app/models/entity.model';
import { ApiHelperService } from '../../helper/api-helper/api-helper.service';

export type EntityAction =
  'saved' | 'linked' | 'unlinked' | 'submitted' |
  'approved' | 'rejected' | 'canceled' | 'deployed' |
  'cloned-tenant' | 'tenant-comparer' |
  'cloned' | 'changes discared' | 'validated discard' |
  'validated discard request' | 'request discared' | 'promote' | 
  'integration command';

  export interface EntityActionEvent {
  action: EntityAction;
  error?: boolean;
  entity: string;
  data?: Entity;
  executorUserName?: string;
}

export interface ErrorMessage {
  message: string;
  items: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class EntityHandlerService implements OnDestroy {
  public readonly actionFired$: Observable<EntityActionEvent>;
  public readonly entityData$: Observable<{ data: Entity, groups: Array<FormDefinition> }>;
  public readonly errorsAfterChecking$: Observable<ErrorMessage>;

  private readonly _actionFired = new EventEmitter<EntityActionEvent>();
  private readonly _entityData = new EventEmitter<{ data: Entity, groups: Array<FormDefinition> }>();
  private readonly _errorsAfterChecking = new BehaviorSubject<ErrorMessage>(undefined);

  private _subscriptions: Array<Subscription> = [];
  private _currentDashboardItem: DashboardItem;

  constructor(
    private _entity: EntityRestService,
    private _dashboardHandler: DashboardHandlerService,
    private _changeManagement: ChangeManagementRestService,
    private _apiHelper: ApiHelperService
  ) {
    this.actionFired$ = this._actionFired.asObservable();
    this.entityData$ = this._entityData.asObservable();
    this.errorsAfterChecking$ = this._errorsAfterChecking.asObservable();

    this._createSubscriptions();
  }

  public getData(detailCode: string, groups: Array<FormDefinition>) {
    let urlSection = this._apiHelper.getUrlSection(detailCode);

    this._entity.getDataEntity(urlSection, detailCode)
      .subscribe(data => {
        this._entityData.emit({ data, groups })
      });
  }

  public getFormDefinitionByFormInstanceCode(detailCode: string) {
    return this._entity.getFormDefinitionByFormInstanceCode(detailCode);
  }

  public getEntityByCodeAndOwner(code: string, owner: string): Observable<Entity> {
    const dashboardItem = this._currentDashboardItem;
    const urlSection = dashboardItem.overrideViewCode || dashboardItem.viewCode;

    return this._entity.getByCodeAndOwnerPartyRoleInstanceCode(urlSection, code, owner);
  }

  public saveEntity(payload: Entity, requestCode?: string) {
    const detailCode = payload.getUniqueCode();
    const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
    let obs: Observable<any>;

    if (urlSection === 'file-upload') { requestCode = undefined; }

    if (requestCode) {
      if (detailCode) {
        obs = this._entity.updateAndLinkEntity(urlSection, requestCode, payload);
      } else {
        obs = this._entity.saveAndLinkEntity(urlSection, requestCode, payload);
      }
    } else {
      if (detailCode) {
        obs = this._entity.updateEntity(urlSection, payload);
      } else {
        obs = this._entity.saveEntity(urlSection, payload);
      }
    }

    obs.pipe(catchError((error) => { this._fireErrorAction('saved'); throw error; }))
      .subscribe((response) => this._fireAction('saved', response));
  }

  public saveEntityWithRelationship(payload: Entity, requestCode?: string) {
    const detailCode = payload.getUniqueCode();
    const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
    let obs: Observable<any>;

    if (requestCode) {
      if (detailCode) {
        obs = this._entity.updateAndLinkEntityWithRelationship(urlSection, requestCode, payload);
      } else {
        obs = this._entity.saveAndLinkEntityWithRelationship(urlSection, requestCode, payload);
      }
    } else {
      if (detailCode) {
        obs = this._entity.updateEntityWithRelationship(urlSection, payload);
      } else {
        obs = this._entity.saveEntityWithRelationship(urlSection, payload);
      }
    }

    obs.pipe(catchError((error) => { this._fireErrorAction('saved'); throw error; }))
      .subscribe((response) => this._fireAction('saved', response));
  }

  public confirmLink(payload: ConfirmSubmissionPayload) {
    this._changeManagement.confirmLink(payload)
      .pipe(catchError((error) => { this._fireErrorAction('linked'); throw error; }))
      .subscribe(() => this._fireAction('linked'));
  }

  public confirmLinkMultiple(payload: Array<ConfirmSubmissionPayload>) {
    this._changeManagement.confirmLinkMultiple(payload)
      .pipe(catchError((error) => { this._fireErrorAction('linked'); throw error; }))
      .subscribe(() => this._fireAction('linked'));
  }

  public confirmUnlink(changeRequestCode: string) {
    this._changeManagement.confirmUnlink(changeRequestCode)
      .pipe(catchError((error) => { this._fireErrorAction('unlinked'); throw error; }))
      .subscribe(() => this._fireAction('unlinked'));
  }

  public submitRequest(code: string, executorUserName: string, signature: string,approvalWorkEffortInstanceCode?: string) {
    this._changeManagement.submitRequest(code, executorUserName, signature, approvalWorkEffortInstanceCode)
      .pipe(catchError((error) => { this._fireErrorAction('submitted'); throw error; }))
      .subscribe(() => this._fireAction('submitted'));
  }

  public cancelRequest(code: string, executorUserName: string) {
    this._changeManagement.cancelRequest(code, executorUserName)
      .pipe(catchError((error) => { this._fireErrorAction('canceled'); throw error; }))
      .subscribe(() => this._fireAction('canceled'));
  }

  public validateDiscardRequest(code: string) {
    this._changeManagement.validateDiscardRequest(code)
      .pipe(catchError((error) => { this._fireErrorAction('validated discard request'); throw error; }))
      .subscribe((data) => this._fireAction('validated discard request', data));
  }

  public discardRequest(code: string) {
    this._changeManagement.discardRequest(code)
      .pipe(catchError((error) => { this._fireErrorAction('request discared'); throw error; }))
      .subscribe(() => this._fireAction('request discared'));
  }

  public approveRequest(code: string, executorUserName: string, signature: string) {
    this._changeManagement.approveRequest(code, executorUserName, signature)
      .pipe(catchError((error) => { this._fireErrorAction('approved'); throw error; }))
      .subscribe((action: EntityAction) => this._fireAction(action));
  }

  public rejectRequest(code: string, executorUserName: string) {
    this._changeManagement.rejectRequest(code, executorUserName)
      .pipe(catchError((error) => { this._fireErrorAction('rejected'); throw error; }))
      .subscribe(() => this._fireAction('rejected'));
  }

  public deploy(code: string, executorUserName: string) {
    this._changeManagement.deploy(code, executorUserName)
      .pipe(catchError((error) => { this._fireErrorAction('deployed'); throw error; }))
      .subscribe(() => this._fireAction('deployed'));
  }

  public cloneEntity(payload: Entity) {
    const detailCode = payload.getUniqueCode();
    const urlSection = this._apiHelper.getUrlSection(detailCode, payload);

    this._entity.cloneEntity(urlSection, payload)
      .pipe(catchError((error) => { this._fireErrorAction('cloned'); throw error; }))
      .subscribe((response) => this._fireAction('cloned', response));
  }

  public cloneFlow(payload: Entity) {
    const detailCode = payload.getUniqueCode();
    const urlSection = this._apiHelper.getUrlSection(detailCode, payload);

    this._entity.cloneFlow(urlSection, payload)
      .pipe(catchError((error) => { this._fireErrorAction('cloned'); throw error; }))
      .subscribe((response) => this._fireAction('cloned', response));
  }

  public deepClone(payload: Entity) {
    const detailCode = payload.getUniqueCode();
    const urlSection = this._apiHelper.getUrlSection(detailCode, payload);

    this._entity.deepClone(urlSection, payload)
      .pipe(catchError((error) => { this._fireErrorAction('cloned-tenant'); throw error; }))
      .subscribe((response) => this._fireAction('cloned-tenant', response));
  }

  public tenantComparer(source: string, target: string): Observable<any> {
    return this._entity.tenantComparer(source, target);
  }

  public canApprovedOrRejectRequest(code: string) {
    return this._changeManagement.canApprovedOrRejectRequest(code);
  }

  public confirmDiscardChanges(payload: Entity) {
    const detailCode = payload.getUniqueCode();
    const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
    this._entity.confirmDiscardChanges(urlSection, detailCode)
      .pipe(catchError((error) => { this._fireErrorAction('changes discared'); throw error; }))
      .subscribe(() => this._fireAction('changes discared'));
  }

  public validateDiscardChanges(payload: Entity) {
    const detailCode = payload.getUniqueCode();
    const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
    return this._entity.validateDiscardChanges(urlSection, detailCode)
      .pipe(catchError((error) => { this._fireErrorAction('validated discard'); throw error; }))
      .subscribe((data) => this._fireAction('validated discard', data));
  }

  public fireActionSaved() {
    this._fireAction('saved');
  }

  public getRequest(code: string) {
    return this._entity.getRequest(code);
  }

  public _setErrorMessagesAfterCheckingEntityList(errorMessage: ErrorMessage) {
    this._errorsAfterChecking.next(errorMessage);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _createSubscriptions() {
    this._subscriptions.push(this._dashboardHandler.currentDashboardItem$
      .subscribe(dashboardItem => this._currentDashboardItem = dashboardItem));
  }

  private _fireAction(action: EntityAction, data?: any, error?: boolean) {
    this._actionFired.next({
      action: action,
      entity: this._currentDashboardItem.name,
      data: data,
      error: error
    });
  }

  private _fireErrorAction(action: EntityAction) {
    this._actionFired.next({
      action: action,
      entity: this._currentDashboardItem.name,
      error: true
    });
  }

}
