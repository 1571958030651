import { BaseDataModel } from '../base-data-model.model';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';
import { ChainedFilterDataModelService } from '../chained-filter-data-model/chained-filter-data-model.service';

export class TabbedRuleCreatorDataModelService implements BaseDataModel {

  private _fieldDefinition: FieldDefinition;

  constructor(fieldDefinition: FieldDefinition) {
    this._fieldDefinition = fieldDefinition;
  }

  public setValueToField(payloadValue: any) {
    const value = [];

    (payloadValue as Array<any> || []).forEach(rule => {
      const fieldDef = {
        field: this._fieldDefinition.field,
        chainedFilterDefinition: this._fieldDefinition.tabbedRuleCreator.chainedFilterDefinition,
        value: {}
      } as FieldDefinition;

      const chainedFilterDataModel = new ChainedFilterDataModelService(fieldDef);
      const chainedFilterValue = chainedFilterDataModel.setValueToField(rule.factors);
      const gridSelection = rule.outcomeDataComponents
        .map((x: any) => {
          return { instanceCode: x.dataComponentInstanceCode, order: x.sequenceId }
        });

      let itemSelection = undefined;

      if (this._fieldDefinition.tabbedRuleCreator.outcome.type === 'catalog-multiple') {
        itemSelection = rule[this._fieldDefinition.tabbedRuleCreator.outcome.field.field]
          .map((x: any) => x[this._fieldDefinition.tabbedRuleCreator.outcome.field.codeProperty]);
      }

      (value as Array<any>).push({
        ruleCode: rule.instanceCode,
        ruleName: rule.instanceName,
        chainedFilter: chainedFilterValue,
        gridSelection: gridSelection,
        itemSelection: itemSelection
      });
    });

    return value;
  }

  public getValueFromField(fieldValue: any) {
    const value = [];

    (fieldValue as Array<any> || []).forEach(tabbedValue => {
      let outcomeItem = undefined;

      if (this._fieldDefinition.tabbedRuleCreator.outcome.type === 'catalog-multiple') {
        outcomeItem = tabbedValue.itemSelection
          .map((x: string) => {
            const obj = {};
            obj[this._fieldDefinition.tabbedRuleCreator.outcome.field.codeProperty] = x;
            return obj;
          });

      } else if (this._fieldDefinition.tabbedRuleCreator.outcome.type === 'grid') {
        outcomeItem = tabbedValue.gridSelection
          .filter((x: any) => x.instanceCode != undefined)
          .map((x: any) => {
            return {
              dataComponentInstanceCode: x.instanceCode,
              sequenceId: x.order
            }
          });
      }

      let chainedFilterPayloadValue = undefined;
      if (tabbedValue.chainedFilter) {
        const fieldDef = {
          field: this._fieldDefinition.field,
          value: tabbedValue.chainedFilter,
          chainedFilterDefinition: this._fieldDefinition.tabbedRuleCreator.chainedFilterDefinition
        } as FieldDefinition;
        const chainedFilterDataModel = new ChainedFilterDataModelService(fieldDef);

        chainedFilterPayloadValue = chainedFilterDataModel.getValueFromField(fieldDef);

        (chainedFilterPayloadValue as Array<any>).forEach(value => {
          value.ruleTypeLibraryCode = tabbedValue.ruleTypeCode;
        });
      }

      const payloadItem = {
        factors: chainedFilterPayloadValue || [],
        instanceCode: tabbedValue.ruleCode,
        instanceName: tabbedValue.ruleName,
        ruleTypeLibraryCode: tabbedValue.ruleTypeCode,
        ownerPartyRoleInstanceCode: tabbedValue.ownerPartyRoleInstanceCode
      };

      payloadItem[this._fieldDefinition.tabbedRuleCreator.outcome.field.field] = outcomeItem;

      value.push(payloadItem);
    });

    return value;
  }

}
