/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-item.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tab-item.component";
var styles_TabItemComponent = [i0.styles];
var RenderType_TabItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabItemComponent, data: {} });
export { RenderType_TabItemComponent as RenderType_TabItemComponent };
export function View_TabItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "panel-body"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "tab-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.active(); _ck(_v, 0, 0, currVal_0); }); }
export function View_TabItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tab-item", [], null, null, null, View_TabItemComponent_0, RenderType_TabItemComponent)), i1.ɵdid(1, 638976, null, 0, i2.TabItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabItemComponentNgFactory = i1.ɵccf("app-tab-item", i2.TabItemComponent, View_TabItemComponent_Host_0, { label: "label" }, {}, ["*"]);
export { TabItemComponentNgFactory as TabItemComponentNgFactory };
