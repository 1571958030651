import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { ConfirmSubmissionPayload } from 'src/app/modals/link-change-modal/link-change-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ChangeManagementRestService {

  constructor(private _rest: RestService) { }

  public getPendingRequestCount() {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/pending-request/count`,
      headers: headers
    });
  }

  public confirmLink(payload: ConfirmSubmissionPayload) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/change-request/confirm-link`,
      body: payload
    });
  }

  public confirmLinkMultiple(payload: Array<ConfirmSubmissionPayload>) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/change-request/confirm-link-multiple`,
      body: payload
    });
  }

  public confirmUnlink(changeRequestCode: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/change-request/confirm-unlink`,
      body: { changeRequestCode: changeRequestCode }
    });
  }

  public submitRequest(requestCode: string, executorUserName: string, signature: string, approvalWorkEffortInstanceCode: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/submit`,
      body: {
        requestCode: requestCode,
        approvalWorkEffortInstanceCode: approvalWorkEffortInstanceCode,
        executorUserName: executorUserName,
        base64Signature: signature
      }
    });
  }

  public approveRequest(requestCode: string, executorUserName: string, signature: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/approve`,
      body: {
        requestCode: requestCode,
        executorUserName: executorUserName,
        base64Signature: signature
      }
    });
  }

  public rejectRequest(requestCode: string, executorUserName: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/reject`,
      body: {
        requestCode: requestCode,
        executorUserName: executorUserName
      }
    });
  }

  public cancelRequest(requestCode: string, executorUserName: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/cancel`,
      body: {
        requestCode: requestCode,
        executorUserName: executorUserName
      }
    });
  }

  public validateDiscardRequest(requestCode: string) {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/validate-discard/${requestCode}`,
    });
  }

  public discardRequest(requestCode: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/discard/${requestCode}`
    });
  }

  public deploy(requestCode: string, executorUserName: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/deploy`,
      body: {
        requestCode: requestCode,
        executorUserName: executorUserName
      }
    });
  }

  public canApprovedOrRejectRequest(requestCode: string) {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/user-approve-permission/${requestCode}`
    });
  }

  public untokenize(token: string) {
    const encodedToken = encodeURIComponent(token);
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/request/untokenize?token=${encodedToken}`
    });
  }

}
