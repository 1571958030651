import { Component, OnInit, ViewChild, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GridInput } from './grid.model';
import { Subscription, BehaviorSubject } from 'rxjs';
import { RowEvent, GridApi, ColumnApi, RowNode, RowDragEvent, BodyScrollEvent, GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { filter } from 'rxjs/operators';

export interface ViewModel {
  gridVisible: boolean;
  rowData: Array<any>;
  defaultColDef: any;
  columnDefs: any;
  autoGroupColumnDef: any;
  groupDefaultExpanded: number;
  treeData: boolean;
  getDataPath: Function;
}

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('agGrid', { static: false })
  public agGrid: AgGridAngular;

  @Input() public data: GridInput;
  @Input() public rowSelection: 'single' | 'multiple' = 'multiple';
  @Input() public suppressRowClickSelection: boolean;
  @Input() public groupHideOpenParents: boolean;
  @Input() public rowClassRules: any;
  @Input() public colState: EventEmitter<any>;
  @Input() public filterState: EventEmitter<any>;
  @Input() public customPanel: any;
  @Input() public quickSearch: string;
  @Input() public externalFilter: { [x: string]: (key: any) => boolean; };
  @Input() public exportAsCsv: EventEmitter<any>;
  @Input() public setExpanded: EventEmitter<any>;
  @Input() public groupSelectsChildren: boolean;

  @Output() public colStateChanged = new EventEmitter();
  @Output() public rowDoubleClicked = new EventEmitter();
  @Output() public openInNewTab = new EventEmitter();
  @Output() public selectionChanged = new EventEmitter();
  @Output() public cellValueChanged = new EventEmitter();
  @Output() public rowFilterFocused = new EventEmitter();
  @Output() public rowDragEnd = new EventEmitter();
  @Output() public rowDragLeave = new EventEmitter();
  @Output() public gridApi = new EventEmitter<GridApi>();
  @Output() public firstDataRendered = new EventEmitter();

  public model = {} as ViewModel;
  public frameworkComponents: any;
  public sideBar: any;

  private _settingColumnState: boolean;
  private _subscriptions: Array<Subscription> = [];
  private _gridApi: GridApi;
  private _gridColumnApi: ColumnApi;

  private readonly _gridData = new BehaviorSubject<GridInput>(undefined);
  private readonly _colState = new BehaviorSubject<Array<any>>(undefined);
  private readonly _filterState = new BehaviorSubject<Array<any>>(undefined);

  constructor() {
    this.model.defaultColDef = {
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      resizable: true,
      filter: true
    };
    this.model.autoGroupColumnDef = {
      sortable: false,
      cellRendererParams: {
        suppressCount: true
      }
    };

    this.sideBar = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel"
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel"
        }
      ],
      defaultToolPanel: "columns"
    };
  }

  public onGridReady(params: any) {
    this._gridApi = params.api;
    this._gridColumnApi = params.columnApi;

    this.agGrid.api.closeToolPanel();
    // this.moveToolPanelToLeft();
    // this.agGrid.gridOptions.groupSelectsChildren = true;
    // this.agGrid.gridOptions.groupUseEntireRow = true;

    this._setCustomPanelWidth();
    this._addEventToGridInputs();

    if (this.gridApi) {
      this.gridApi.emit(this._gridApi);
    }
  }

  public onFirstDataRendered(event: any) {
    this._colState.asObservable()
      .subscribe(columnState => {
        this._setColumnState(columnState)
      });

    this._filterState.asObservable()
      .subscribe(filterState => this._setFilterState(filterState));

    this.firstDataRendered.emit();
  }

  public onRowDataChanged(params: any) {
    this._gridApi = params.api;
    this._gridColumnApi = params.columnApi;

    const visibleColumns = this._gridColumnApi.getAllDisplayedColumns();
    const gridWidth = (this.agGrid as any).viewContainerRef.element.nativeElement.clientWidth

    let totalWidth = 0;

    visibleColumns.forEach(col => {
      totalWidth += col.getActualWidth();
    });

    if (gridWidth > totalWidth) {
      setTimeout(() => {
        this._gridApi.sizeColumnsToFit();
      }, 100);
    }
  }

  public onBodyScroll(event: BodyScrollEvent) {
    this._addEventToGridInputs();
  }

  public somethingChanged(event: any) {
    if (this.colStateChanged && !this._settingColumnState) {
      this.colStateChanged.emit();
    }

    // Avoid emit a col state change when setting state manually
    if (this._settingColumnState) {
      this._settingColumnState = false;
      this._addEventToGridInputs();
    }
  }

  public getCurrentColumnState() {
    return {
      columnState: this.agGrid.columnApi.getColumnState(),
      filterState: this._gridApi.getFilterModel()
    }
  }

  public onRowDoubleClicked(event: RowEvent) {
    this.rowDoubleClicked.next(event);
  }

  public onSelectionChanged(event: RowEvent) {
    this.selectionChanged.next(event);
  }

  public onCellValueChanged(event: RowEvent) {
    this.cellValueChanged.next(event);
  }

  public onRowDragLeave(event: RowDragEvent) {
    this.rowDragLeave.next(event);
  }

  public onRowDragEnd(event: RowDragEvent) {
    this.rowDragEnd.next(event);
  }

  public isExternalFilterPresent() {
    return this.externalFilter != undefined;
  }

  public doesExternalFilterPass(node: RowNode) {
    let filtered: boolean;

    Object.keys(this.externalFilter)
      .forEach(key => {
        filtered = this.externalFilter[key](node.data[key])
      });

    return filtered;
  }

  public getContextMenuItems(params: GetContextMenuItemsParams): Array<MenuItemDef> {
    const newMenuItems = Object.assign(params.defaultItems, []);

    const openInNewTab = {
      name: 'Open in a new tab',
      action: () => { this.openInNewTab.next(params); },
    } as MenuItemDef;

    newMenuItems.unshift(openInNewTab, 'separator');
    return newMenuItems;
  }

  ngOnInit() {
    this._subscriptions.push(this._gridData.asObservable()
      .pipe(filter(x => x !== undefined))
      .subscribe(data => {
        this._bindModelToGrid(data);
        this._gridData.next(undefined);
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this._gridData.next(this.data);
    }

    if (changes.customPanel && changes.customPanel.currentValue) {
      this._configCustomPanel();
    }

    if (changes.colState && changes.colState.currentValue) {
      this._subscriptions.push(this.colState
        .subscribe((def: Array<any>) => this._colState.next(def)));
    }

    if (changes.filterState && changes.filterState.currentValue) {
      this._subscriptions.push(this.filterState
        .subscribe((def: Array<any>) => this._filterState.next(def)));
    }

    if (changes.exportAsCsv && changes.exportAsCsv.currentValue) {
      this._subscriptions.push(
        this.exportAsCsv.subscribe((params: any) => {
          this._gridApi.exportDataAsCsv(params);
        }));
    }

    if (changes.setExpanded && changes.setExpanded.currentValue) {
      this._subscriptions.push(
        this.setExpanded.subscribe((expanded: boolean) => {
          if (this._gridApi) {
            this._gridApi.forEachNode(function (node) {
              if (node.group) {
                node.setExpanded(expanded);
              }
            });
            this._gridApi.onGroupExpandedOrCollapsed();
          }
        }));
    }

    if (this._gridApi) {
      if (changes.quickSearch && changes.quickSearch.currentValue) {
        this._gridApi.setQuickFilter(this.quickSearch);
      } else {
        this._gridApi.setQuickFilter(undefined);
      }

      if (changes.externalFilter && (changes.externalFilter.currentValue || changes.externalFilter.previousValue)) {
        this._gridApi.onFilterChanged();
      }
    }
  }

  private _addEventToGridInputs() {
    setTimeout(() => {
      document.querySelectorAll('input')
        .forEach(htmlEl => {
          htmlEl.onfocus = (ev) => {
            this.rowFilterFocused.next(true);
          };
          htmlEl.onblur = (ev) => {
            this.rowFilterFocused.next(false);
          };
        });
    }, 100);
  }

  private _setColumnState(columnState: Array<any>) {
    this._settingColumnState = true;
    if (columnState) {
      // this.agGrid.suppressSetColumnStateEvents = true;
      this.agGrid.columnApi.setColumnState(columnState);
    } else {
      if (this.agGrid.columnApi.getColumnState().length > 0) {
        this.agGrid.columnApi.resetColumnState();
      }
    }

    setTimeout(() => {
      this._settingColumnState = false;
    }, 100);
  }

  private _setFilterState(filterState: Array<any>) {
    if (filterState && Object['keys'](filterState).length > 0) {
      this.agGrid.api.setFilterModel(filterState);
    } else {
      this.agGrid.api.setFilterModel(null);
    }
  }

  private _setCustomPanelWidth() {
    if (this.customPanel) {
      const sidePanel = document.getElementsByClassName('ag-side-bar')[0];

      for (let i = 0; i < sidePanel.children.length; i++) {
        const child = sidePanel.children[i];

        for (let j = 0; j < child.children.length; j++) {
          const innerChild = child.children[j];
          if (innerChild.tagName.toLocaleLowerCase().startsWith('app-')) {
            child.setAttribute("style", "width: 300px;");
            break;
          }
        }

      }
    }
  }

  private _bindModelToGrid(data: GridInput) {
    this.model.columnDefs = [];
    this.model.rowData = [];

    if (data) {
      if (data.metadata) {
        this.model.treeData = data.metadata.treeData;
        if (this.model.treeData) {
          this.model.getDataPath = function (params) {
            return params[data.metadata.dataPath];
          };
        }
        this.model.groupDefaultExpanded = data.metadata.groupDefaultExpanded;
        this.model.autoGroupColumnDef = data.metadata.autoGroupColumnDefinition;
      }

      this.model.columnDefs = data.columns || data.metadata.columnDefinition;
      this.model.rowData = data.rows;

      this.model.gridVisible = true;
    }
  }

  private _configCustomPanel() {
    const sideBar = Object.assign({}, this.sideBar);

    sideBar.toolPanels
      .push({
        id: "guidedFilterPanel",
        labelDefault: "Guided Filter",
        labelKey: "guidedFilterPanel",
        iconKey: "filter",
        toolPanel: "guidedFilterPanel"
      });

    sideBar.defaultToolPanel = "guidedFilterPanel";

    this.sideBar = sideBar;

    this.frameworkComponents = { guidedFilterPanel: this.customPanel };

  }

  private _moveToolPanelToLeft() {
    const gridBody = document.getElementsByClassName('ag-root-wrapper-body')[0];
    gridBody.appendChild(gridBody.firstElementChild)
  }

}
