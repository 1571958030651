import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RestService } from 'ngx-emerios-all';
import { ProductAvailabilityDto, ProductDto } from 'src/app/models/products.model';
import { Observable } from 'rxjs';
import { AgGridModel } from 'src/app/models/campaign-builder.models';

@Injectable({
  providedIn: 'root'
})
export class ProductRestService {

  constructor(private _rest: RestService) { }

  public createFeatureApplicability(relationships: Array<ProductAvailabilityDto>) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/product-type/feature-applicability/`,
      body: relationships
    });
  }

  public deleteFeatureApplicability(relationships: Array<ProductAvailabilityDto>) {
    return this._rest.delete({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/product-type/feature-applicability/`,
      body: relationships
    });
  }

  public getProductsByOwner(instanceCode: string): Observable<ProductDto> {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/product/owner/${instanceCode}`
    });
  }
}
