<ng-container *ngIf="form">
    <div class="row navigation-rules" [formGroup]="form">
        <ng-container *ngFor="let factor of model.factors">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 icon-line">
                <div class="condition-text">
                    <p>
                        If {{model.currentStepName}} <strong> {{factor.name}}</strong>
                    </p>
                    <i class="fas fa-arrow-alt-circle-right"></i>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 vertical-line">
                <label class="app-input has-label emerios-multiselect">Then Continue With
                    <emerios-multiselect [config]="factor.weConfig" [items]="model.workeffortList"
                        [formControlName]="factor.name" [forceValidation]="forceFormValidation" (change)="setAsDirty()">
                    </emerios-multiselect>
                </label>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 vertical-line">
                <label class="app-input has-label emerios-multiselect">Action Name
                    <emerios-input [config]="factor.actionConfig" [formControlName]="factor.actionName"
                        [forceValidation]="forceFormValidation" (input)="setAsDirty()">
                    </emerios-input>
                </label>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 vertical-line">
                <label class="app-input has-label emerios-multiselect">Trigger Save Form Action
                    <emerios-multiselect [config]="factor.fireSaveConfig" [items]="model.yesNoCatalog"
                        [formControlName]="factor.fireSaveName" [forceValidation]="forceFormValidation"
                        (change)="setAsDirty()">
                    </emerios-multiselect>
                </label>
            </div>

            <div class="clearfix"></div>
            <hr>
        </ng-container>
    </div>

    <div class="app-input" *ngIf="control && control.errors && control.invalid && !control.pristine">
        <div class="input-error">{{getControlErrors()}}</div>
    </div>
</ng-container>