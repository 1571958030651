import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _authHandler: AuthorizationHandlerService,
    private _navHelper: NavigationHelperService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this._authHandler.isAuthenticated()) {
      this._navHelper.goToLogin(state.url);
      return false;
    }

    this._authHandler.setAuthenticated(true);

    return true;
  }

}
