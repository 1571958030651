export enum CacheKeyEnum {
  AccessToken = 'access_token',
  Breadcrumb = 'breadcrumb',
  Username = 'username',
  WorkingOrganization = 'working-organization',
  CurrentDashboardCategory = 'dashboard-category',
  BackAfterSave = 'backAfterSave',
  StepsByJourney = 'stepsByJourney',
  Permissions = 'permissions',
  UserSignature = 'userSignature',
  BookSelected = 'bookSelected',
  IntegrationCommand = 'integrationCommand'
}
