<!-- <ng-container *ngIf="detailCode"> -->
<app-ghost-loading *ngIf="model.loadingGrid" [type]="'aggrid'"></app-ghost-loading>

<ng-container *ngIf="!model.loadingGrid">

    <div class="grid grid-70">
        <app-grid [data]="model.gridModel" (cellValueChanged)="onCellValueChanged($event)"
            (gridApi)="gridApiReady($event)" (firstDataRendered)="onFirstDataRendered($event)"> </app-grid>
    </div>

</ng-container>

<div class="app-input" *ngIf="control && control.errors && !control.pristine">
    <div class="input-error file-upload-error">{{getControlErrors()}}</div>
</div>

<!-- </ng-container> -->