import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-error-detail-modal',
  templateUrl: './error-detail-modal.component.html',
  styleUrls: ['./error-detail-modal.component.sass']
})

export class ErrorDetailModalComponent implements OnInit {

  public title: string;
  public model: any;

  public errorLines: Array<any> = [];
  private _errorMessage = '';

  constructor(
    private _modal: BsModalRef,
    private _clipboardService: ClipboardService
  ) { }

  ngOnInit() {
    this.title = this.model.ChangeRequestId;
    this.model.Errors.forEach((e: string) => this.errorLines.push(e.split('\n')));
    this.model.Errors.forEach((e: string) => this._errorMessage += e + '\n');
  }

  public closeModal() {
    this._modal.hide();
  }

  public copyError() {
    this._clipboardService.copyFromContent(this._errorMessage);
    this.closeModal();
  }
}
