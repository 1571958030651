import { Component, OnInit, OnChanges, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { ElementConfig } from 'ngx-emerios-all';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';
import { ModalService } from 'src/app/services/behavior/modal/modal.service';
import { DynamicFormPreviewComponent } from 'src/app/modals/dynamic-form-preview/dynamic-form-preview.component';
import { Subscription } from 'rxjs';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { OperationHandlerService } from 'src/app/services/handler/operation-handler/operation-handler.service';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { WaitingLoaderService } from 'src/app/services/behavior/waiting-loader/waiting-loader.service';

@Component({
  selector: 'app-button-form-preview',
  templateUrl: './button-form-preview.component.html',
  styleUrls: ['./button-form-preview.component.sass']
})
export class ButtonFormPreviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public config: ElementConfig;
  @Input() public definition: FieldDefinition;

  public buttonText: string;

  private _dynafToPreview: string;
  private _formOperationType: string;
  private _detailCode: string;
  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _modal: ModalService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _operationHandler: OperationHandlerService,
    private _waitingLoader: WaitingLoaderService) { }

  public buttonClicked() {
    this._waitingLoader.showFullScreenLoading();
    this._operationHandler.getAvailableForms(this._dynafToPreview as DynafEnum, false, false);
  }

  ngOnInit() {
    this._subscriptions.push(
      this._fieldValueHandler.anyValue$.subscribe(anyValue => {
        const formType = anyValue.changes.get('formType');
        const formOptype = anyValue.changes.get('formOperationType');

        this._detailCode = anyValue.changes.get('instanceCode');

        if (formType && formOptype) {
          this._dynafToPreview = formType[0].code;
          this._formOperationType = formOptype[0].code;
        }
      }));

    this._subscriptions.push(
      this._operationHandler.availableFormsByOperation$
        .subscribe(formsByOperation => {
          if (this._detailCode) {
            const formOperation = formsByOperation.find(x => x.operation === this._formOperationType);
            const formDefinition = formOperation.definitions.find(x => x.code === this._detailCode);

            this._waitingLoader.hideFullScreenLoading();

            if (formDefinition && formDefinition.definition) {
              const params = {
                definition: formDefinition.definition,
                entityName: 'test'
              };

              this._modal.showCustom(DynamicFormPreviewComponent, { size: 'xl', params: params });
            }
          }
        }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.definition && changes.definition.currentValue) {
      this.buttonText = this.definition.attributes.label;
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }
}
