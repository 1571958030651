import { BaseDataModel } from '../base-data-model.model';

export class DefaultDataModelService implements BaseDataModel {

  constructor() { }

  public setValueToField(payloadValue: any) {
    return payloadValue;
  }

  public getValueFromField(fieldValue: any) {
    return fieldValue;
  }

}
