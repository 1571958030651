/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-upload.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/ngx-file-drop/ngx-file-drop.ngfactory";
import * as i3 from "ngx-file-drop";
import * as i4 from "@angular/common";
import * as i5 from "../../../../node_modules/ngx-emerios-all/ngx-emerios-all.ngfactory";
import * as i6 from "ngx-emerios-all";
import * as i7 from "@angular/forms";
import * as i8 from "./file-upload.component";
import * as i9 from "../../services/behavior/catalog/catalog.service";
import * as i10 from "../../services/helper/element-helper/element-helper.service";
import * as i11 from "../../services/handler/authorization-handler/authorization-handler.service";
import * as i12 from "../../services/rest/file-rest/file-rest.service";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../services/handler/journey-handler/journey-handler.service";
var styles_FileUploadComponent = [i0.styles];
var RenderType_FileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadComponent, data: {} });
export { RenderType_FileUploadComponent as RenderType_FileUploadComponent };
function View_FileUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "icon-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-cloud-upload-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Upload File "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "messagge"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drag the file/s here or press the upload button"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-fill btn-sm btn-drop-file pull-right my-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.context.openFileSelector() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Browse Files"]))], null, null); }
function View_FileUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "ngx-file-drop", [], null, [[null, "onFileDrop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onFileDrop" === en)) {
        var pd_0 = (_co.dropped($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgxFileDropComponent_0, i2.RenderType_NgxFileDropComponent)), i1.ɵdid(3, 180224, null, 1, i3.NgxFileDropComponent, [i1.NgZone, i1.Renderer2], { accept: [0, "accept"], multiple: [1, "multiple"], disabled: [2, "disabled"] }, { onFileDrop: "onFileDrop" }), i1.ɵqud(603979776, 1, { contentTemplate: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 1, null, View_FileUploadComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.ɵa, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.config.accept; var currVal_1 = _co.model.config.multiple; var currVal_2 = false; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_FileUploadComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Thumbnail"]))], null, null); }
function View_FileUploadComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Language"]))], null, null); }
function View_FileUploadComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Date"]))], null, null); }
function View_FileUploadComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"]))], null, null); }
function View_FileUploadComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Remove"]))], null, null); }
function View_FileUploadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_6)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Filename"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Uploaded by"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_7)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_8)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_9)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_10)), i1.ɵdid(15, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isBookUpload; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.languageSettingAvailable(); _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.isBookUpload; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.isBookUpload; _ck(_v, 13, 0, currVal_3); var currVal_4 = !_co.readonly(); _ck(_v, 15, 0, currVal_4); }, null); }
function View_FileUploadComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [["class", "table-thumbnail"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "img-thumbnail"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImageThumbnail(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "td", [["class", "table-emerios-multiselect"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "label", [["class", "control-label app-input emerios-multiselect"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "emerios-multiselect", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.multiselectChanged() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ɵr_0, i5.RenderType_ɵr)), i1.ɵdid(3, 4833280, null, 0, i6.ɵr, [], { config: [0, "config"], items: [1, "items"] }, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.ɵr]), i1.ɵdid(5, 671744, null, 0, i7.FormControlName, [[3, i7.ControlContainer], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR], [2, i7.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i7.NgControl, null, [i7.FormControlName]), i1.ɵdid(7, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.getMultiselectConfig(_v.parent.context.$implicit.fileName); var currVal_8 = _co.model.langItems; _ck(_v, 3, 0, currVal_7, currVal_8); var currVal_9 = _v.parent.context.$implicit.fileName; _ck(_v, 5, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_FileUploadComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dateTime; _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], null, null); }
function View_FileUploadComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "btn btn-simple btn-danger btn-filter"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeFile(_v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-minus-circle fa-2x pull-right"]], null, null, null, null, null))], null, null); }
function View_FileUploadComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_12)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "td", [["class", "file-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_13)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_14)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_15)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_16)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isBookUpload; _ck(_v, 3, 0, currVal_0); var currVal_4 = _co.languageSettingAvailable(); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.isBookUpload; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.isBookUpload; _ck(_v, 14, 0, currVal_6); var currVal_7 = !_co.readonly(); _ck(_v, 16, 0, currVal_7); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.fileName; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.fileName; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.userName; _ck(_v, 8, 0, currVal_3); }); }
function View_FileUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 540672, null, 0, i7.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i7.ControlContainer, null, [i7.FormGroupDirective]), i1.ɵdid(3, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "table", [["class", "table table-hover"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_5)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_11)), i1.ɵdid(10, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.getItemsForTable().length > 0); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.getItemsForTable(); _ck(_v, 10, 0, currVal_2); }, null); }
function View_FileUploadComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "display: block;margin-left: auto;margin-right: auto;"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getImage(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_FileUploadComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_18)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getItemsForTable(); _ck(_v, 2, 0, currVal_0); }, null); }
function View_FileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "app-file-upload"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_17)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.readonly(); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showTable(); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.model.showPreview; _ck(_v, 7, 0, currVal_2); }, null); }
function View_FileUploadComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "app-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "input-error file-upload-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getControlErrors(); _ck(_v, 2, 0, currVal_0); }); }
export function View_FileUploadComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FileUploadComponent_19)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.config; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.control && _co.control.errors) && !_co.control.pristine); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-file-upload", [], null, null, null, View_FileUploadComponent_0, RenderType_FileUploadComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.FileUploadComponent]), i1.ɵdid(2, 770048, null, 0, i8.FileUploadComponent, [i9.CatalogService, i10.ElementHelperService, i11.AuthorizationHandlerService, i12.FileRestService, i13.DomSanitizer, i14.JourneyHandlerService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FileUploadComponentNgFactory = i1.ɵccf("app-file-upload", i8.FileUploadComponent, View_FileUploadComponent_Host_0, { forceValidation: "forceValidation", config: "config", isBookUpload: "isBookUpload" }, { isValid: "is-valid", fileEvent: "file-event" }, []);
export { FileUploadComponentNgFactory as FileUploadComponentNgFactory };
