import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreadcrumbService, BreadcrumbItem, BreadcrumbMode } from 'src/app/services/behavior/breadcrumb/breadcrumb.service';
import { CacheService, MultiselectConfig } from 'ngx-emerios-all';
import { Subscription } from 'rxjs';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { AuthorizationHelperService } from 'src/app/services/helper/authorization-helper/authorization-helper.service';

export interface CbBreadcrumbComponentModel {
  breadcrumbs: Array<BreadcrumbItem>;
  breadcrumbMode: BreadcrumbMode;
  stateClass: string;
  breadcrumbConfig: MultiselectConfig;
  filterSelection: number[];
  showInstanceName: boolean;
  showInstanceCode: boolean;
}

@Component({
  selector: 'app-cb-breadcrumb',
  templateUrl: './cb-breadcrumb.component.html',
  styleUrls: ['./cb-breadcrumb.component.sass']
})
export class CbBreadcrumbComponent implements OnInit, OnDestroy {
  public model = {} as CbBreadcrumbComponentModel;
  public hasAnyPermission: boolean;

  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _breadcrumb: BreadcrumbService,
    private _cache: CacheService,
    private _authHelper: AuthorizationHelperService) {
    this.model.filterSelection = [];
  }

  public go(item: BreadcrumbItem, index: number) {
    this._breadcrumb.go(item, index);
  }

  public goHome() {
    this._breadcrumb.goHome();
  }

  public updateBreadcrumbInfo(selection: Array<any>) {
    if (selection) {
      this.model.showInstanceName = selection.includes(1);
      this.model.showInstanceCode = selection.includes(2);
      this._breadcrumb.updateBreadcrumbSelection(selection);
    }
  }

  public showSequenceId(breadcrumb: BreadcrumbItem) {
    return breadcrumb.breadcrumbContent.sequenceId !== undefined;
  }

  public isSectionShown(data: string, breadcrumb?: BreadcrumbItem) {
    switch (data) {
      case 'InstanceName':
        return this.model.showInstanceName && breadcrumb.breadcrumbContent.instanceName;
      case 'InstanceCode':
        return this.model.showInstanceCode && breadcrumb.breadcrumbContent.instanceCode;
      default:
        console.error('Invalid section');
        break;
    }
  }

  public showSquareBracket(breadcrumb: BreadcrumbItem) {
    return breadcrumb.breadcrumbContent.instanceCode;
  }

  public isJourneyNavigation() {
    return this.model.breadcrumbMode === BreadcrumbMode.Journey;
  }

  ngOnInit() {
    const selection = this._breadcrumb.getBreadcrumbSelection();
    const defaultSelection = [1];
    this.model.filterSelection = selection ? selection : defaultSelection;

    this._createSubscriptions();
    this._setBreadcrumbMultiselectConfig();
    this.hasAnyPermission = this._authHelper.hasAnyPermission();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _createSubscriptions() {
    this._subscriptions.push(
      this._breadcrumb.breadcrumb$
        .subscribe(list => {
          this.setBreadcrumbForUploadBookPage(list);
          this.model.breadcrumbs = list;
        }));

    this._subscriptions.push(this._breadcrumb.stateClass$
      .subscribe(className => this.model.stateClass = className));

    this._subscriptions.push(this._breadcrumb.breadcrumbMode$
      .subscribe(breadcrumbMode => this.model.breadcrumbMode = breadcrumbMode));
  }

  private setBreadcrumbForUploadBookPage(list: BreadcrumbItem[]) {
    const obj = list.find(bc => bc.breadcrumbContent.instanceType === 'Book Upload');
    if (obj) {
      const bookNameSelected = this._cache.get(CacheKeyEnum.BookSelected);
      obj.breadcrumbContent.instanceName = bookNameSelected ? bookNameSelected : undefined;
      obj.breadcrumbContent.viewMode = undefined;
    }
  }

  private _setBreadcrumbMultiselectConfig() {
    if (!this.model.breadcrumbConfig) {
      this.model.breadcrumbConfig = this._breadcrumb.getBreadcrumbMultiSelectConfig();
    }
  }
}
