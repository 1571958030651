import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';


@Component({
  selector: 'app-zipcode-grid-modal',
  templateUrl: './zipcode-grid-modal.component.html',
  styleUrls: ['./zipcode-grid-modal.component.sass']
})
export class ZipcodeGridModalComponent {
  public title: string;
  public buttonsEnabled: boolean;
  public data: Array<any>;
  public headerData = [
    "Country Code" ,
    "Country" ,
    "State" ,
    "State Code" ,
    "County" ,
    "City" ,
    "Zip Code"
  ];
  
  constructor(
    private _modal: BsModalRef) { }

  public closeModal(){
    this._modal.hide();
  }
}
