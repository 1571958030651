<div class="modal-header">
  <h3 class="modal-title pull-left" [innerHtml]="title"></h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body campaign-modal" *ngIf="config">

  <div [formGroup]="form">

    <div class="row">
      <div class="col-sm-10 col-sm-offset-1">
        <label class="app-input has-label emerios-multiselect"> Select Request
          <emerios-multiselect [config]="config.request" [formControlName]="config.request.name"
            (ngModelChange)="requestChanged($event)">
          </emerios-multiselect>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-10 col-sm-offset-1">
        <label class="app-input has-label"> Requestor
          <emerios-input [config]="config.requestor" [formControlName]="config.requestor.name">
          </emerios-input>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-5 col-sm-offset-1">
        <label class="app-input has-label"> Creator
          <emerios-input [config]="config.creator" [formControlName]="config.creator.name">
          </emerios-input>
        </label>
      </div>

      <div class="col-sm-5">
        <label class="app-input has-label"> Submission Date
          <emerios-datepicker [config]="config.submissionDate" [formControlName]="config.submissionDate.name">
          </emerios-datepicker>
        </label>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-sm-5 col-sm-offset-1">
        <label class="app-input has-label"> UAT Date
          <emerios-datepicker [config]="config.uatDate" [formControlName]="config.uatDate.name">
          </emerios-datepicker>
        </label>
      </div>

      <div class="col-sm-5">
        <label class="app-input has-label"> Prod Date
          <emerios-datepicker [config]="config.prodDate" [formControlName]="config.prodDate.name">
          </emerios-datepicker>
        </label>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
        <label class="app-input has-label"> Description
          <emerios-textarea [config]="config.description" [formControlName]="config.description.name">
          </emerios-textarea>
        </label>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-next" (click)="closeModal()">Cancel</button>
  <button type="button" [disabled]="!form.valid" class="btn btn-primary btn-fill" (click)="okModal()">Save</button>
</div>