import { Injectable, EventEmitter } from '@angular/core';
import { Observable, forkJoin, BehaviorSubject, Observer } from 'rxjs';
import { map, finalize, filter } from 'rxjs/operators';
import { PresetViewDto } from 'src/app/models/preset-view.model';
import { AgGridModel, GridColumnDef } from '../../../models/campaign-builder.models';
import { GridDefinition, RelationshipScope } from '../../../models/form-field-definition.models';
import { GridInput } from 'src/app/components/grid/grid.model';
import { GridRestService } from 'src/app/services/rest/grid-data-rest/grid-data-rest.service';
import { ProductRestService } from '../../rest/product/product-rest.service';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { CatalogService } from '../../behavior/catalog/catalog.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { ApiHelperService } from '../../helper/api-helper/api-helper.service';
import { WorkeffortExecutionStageResponse, WorkeffortExecutionAgGridItem, WorkeffortJourneyHierarchicalFlowResponse, WorkeffortFlowJourneyHierarchicalAgGridItem, WorkeffortJourneyHierarchicalStepResponse, WorkeffortJourneyHierarchicalStageResponse, WorkeffortJourneyHierarchicalFormContainerResponse } from 'src/app/models/workeffort.models';
import { AgGridBaseModel } from 'src/app/models/aggrid/aggrid-base.model';
import * as moment from 'moment-mini';
import { PaginationDto } from 'src/app/models/server-side-pagination';
import { UtilService } from '../../behavior/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class GridHandlerService {
  public readonly getGenericGridDataAndDef$: Observable<GridInput>;

  public readonly mainColumnDefinitions$: Observable<any>;
  public readonly mainQuickSearch$: Observable<any>;

  public readonly gridData$: Observable<any>;
  public readonly gridColumnDefinitions$: Observable<any>;

  public readonly restoreMainColumnState$: Observable<any>;
  public readonly mainColDefPristine$: Observable<any>;
  public readonly changesGridRows$: Observable<Array<AgGridBaseModel>>;

  private readonly _getGenericGridDataAndDef = new EventEmitter<GridInput>();

  private readonly _mainColumnDefinitions = new BehaviorSubject<any>(undefined);
  private readonly _mainQuickSearch = new EventEmitter<string>(undefined);

  private readonly _gridData = new EventEmitter<any>(undefined);
  private readonly _gridColumnDefinitions = new EventEmitter<Array<any>>(undefined);

  private readonly _restoreMainColumnState = new EventEmitter<PresetViewDto>(undefined);
  private readonly _mainColDefPristine = new EventEmitter<boolean>(true);
  private readonly _changesGridRows = new EventEmitter<Array<AgGridBaseModel>>();

  constructor(
    private _gridRest: GridRestService,
    private _productRest: ProductRestService,
    private _catalog: CatalogService,
    private _apiHelper: ApiHelperService,
    private _util: UtilService
  ) {
    this.getGenericGridDataAndDef$ = this._getGenericGridDataAndDef.asObservable();

    this.mainColumnDefinitions$ = this._mainColumnDefinitions.asObservable().pipe(filter(x => x !== undefined));
    this.mainQuickSearch$ = this._mainQuickSearch.asObservable();

    this.gridData$ = this._gridData.asObservable();
    this.gridColumnDefinitions$ = this._gridColumnDefinitions.asObservable();

    this.restoreMainColumnState$ = this._restoreMainColumnState.asObservable();
    this.mainColDefPristine$ = this._mainColDefPristine.asObservable();
    this.changesGridRows$ = this._changesGridRows.asObservable();
  }

  public getRelationshipsData(uid: number, scope: RelationshipScope, agGridSource: AgGridEnum, detailCode: string) {
    const viewCode = this._apiHelper.getUrlSection(detailCode);
    let observable: Observable<AgGridModel>;

    switch (scope) {
      case RelationshipScope.Child:
        observable = this._gridRest.getChildrenRelationshipsDataAndDef(agGridSource, viewCode, detailCode);
        // observable = this._gridRest.getChildrenRelationshipsDataAndDefPaginated(agGridSource, viewCode, detailCode);
        break;
      case RelationshipScope.Parent:
        observable = this._gridRest.getParentsRelationshipsDataAndDef(agGridSource, viewCode, detailCode);
        break;
    }

    observable.subscribe(data => {
      this._getGenericGridDataAndDef.next({
        uid: uid,
        source: agGridSource,
        rows: data.rows,
        metadata: data.metadata
      } as GridInput);
    });
  }

  public getPreviewGridData(data: GridInput) {
    this._getGenericGridDataAndDef.next({
      rows: data.rows,
      metadata: data.metadata
    } as GridInput);
  }

  public getGenericGridDefAndCustomData(grid: GridDefinition, detailCode?: string, type?: string) {
    const newModelAgGrid = [
      AgGridEnum.QuestionParentCondition,
      AgGridEnum.HierarchicalWorkeffort,
      AgGridEnum.WorkeffortHierarchyJourneyAndOthers,
      AgGridEnum.ComparativeView,
      AgGridEnum.PromoteView,
      AgGridEnum.CatalogProduct,
      AgGridEnum.CatalogRelationshipType,
      AgGridEnum.CampaignEntries,
      AgGridEnum.QuestionRelationship,
      AgGridEnum.AvailableTpvCampaignProducts,
      AgGridEnum.EventFlow,
      AgGridEnum.EmailTemplate,
      AgGridEnum.ProductCheckAndBalance,
      AgGridEnum.BookItem] as Array<AgGridEnum>;

    let gridDefObs: Observable<any>;

    if (newModelAgGrid.includes(grid.source as AgGridEnum)) {
      gridDefObs = this._gridRest.getGridDefinitionBySourceV2(grid.source);
    } else {
      gridDefObs = this._gridRest.getGridDefinitionBySource(grid.source);
    }

    let gridDataObs: Observable<any>;

    if (grid.code === 'catalog_product') {
      gridDataObs = this._productRest.getProductsByOwner(detailCode);
    } else if (grid.code === 'cmp_rules') {
      gridDataObs = this._gridRest.getCampaignCombinations(detailCode);
    } else if (grid.code === 'question-rules') {
      gridDataObs = this._gridRest.getQuestionsByOwnerAndContainerType(detailCode, type);
    } else if (grid.code === 'qst_rules') {
      gridDataObs = this._gridRest.getQuestionsParentTrigger();
    } else if (grid.code === 'relationship_types') {
      gridDataObs = this._catalog.getFullCatalog(CatalogEnum.RelationshipTypes)
        .pipe(map(catalog => catalog.items));
    } else if (grid.code === 'comparativeView') {
      gridDataObs = this._gridRest.getChangesGridData(detailCode);
    } else if (grid.code === 'promoteView') {
      gridDataObs = this._gridRest.getPromoteGridData(detailCode);
    } else if (grid.code === 'hierarchicalView') {
      gridDataObs = this._gridRest.getHierarchicalView(detailCode)
        .pipe(map(data => this._mapHierarchicalWeData(data)));
    } else if (grid.code === 'flowstepsTpv') {
      this.getGenericGridDataAndDef(grid.source, detailCode);
      return;
    } else if (grid.code === 'flowstepsOthers') {
      gridDataObs = this._gridRest.getFlowJourneyHierarchicalView(type, detailCode)
        .pipe(map(data => this._mapFlowJourneyHierarchicalData(data)));
    } else if (grid.code === 'tpv_products') {
      gridDataObs = this._gridRest.getTpvCampaignProducts(detailCode);
    } else if (grid.code === 'flowEvent') {
      gridDataObs = this._gridRest.getEventFlowView(detailCode, type);
    } else if (grid.code === 'book_items') {
      gridDataObs = this._gridRest.getBookItems(detailCode, type);
    } else {
      throw `There is no definition for grid code: ${grid.code}`;
    }

    forkJoin(gridDefObs, gridDataObs)
      .pipe(map(response => {
        this._attachFunctions({ metadata: response[0] } as AgGridModel);

        return response;
      }))
      .subscribe(responses => {
        this._getGenericGridDataAndDef.next({
          source: grid.source,
          columns: (responses[0] as any).columnDefinition || responses[0],
          rows: responses[1]
        } as GridInput);
      });
  }

  public getGenericGridDataAndDef(source: AgGridEnum, detailCode?: string) {
    this._gridRest.getGenericGridDataAndDetinitions(source, detailCode)
      .pipe(
        map(response => {
          this._attachFunctions(response);

          return response;
        }),
        finalize(() => this.setMainColDefinitionPristine()))
      .subscribe(data => {
        this._getGenericGridDataAndDef.next({
          source: source,
          columns: data.gridColumnDefinition,
          rows: data.rows
        } as GridInput);
      });
  }

  public getQuestionRulesGridDataAndDef(source: AgGridEnum, workEffortTypeLibraryCode: string, ownerPartyRoleInstanceCode: string) {
    this._gridRest.getQuestionRulesGridDataAndDef(source, workEffortTypeLibraryCode, ownerPartyRoleInstanceCode)
      .pipe(
        map(response => {
          this._attachFunctions(response);

          return response;
        }),
        finalize(() => this.setMainColDefinitionPristine()))
      .subscribe(data => {
        this._getGenericGridDataAndDef.next({
          source: source,
          columns: data.gridColumnDefinition,
          rows: data.rows
        } as GridInput);
      });
  }

  public getGenericGridDataAndDefV2(dashboardItem: DashboardItem, sourceSuffix?: string, params?: string[], ownerPartyRoleInstanceCode?: string) {
    const viewCode = dashboardItem.overrideViewCode || dashboardItem.viewCode;
    let source: string = dashboardItem.gridSource;
    let observable: Observable<AgGridModel>;

    if (sourceSuffix != undefined) {
      source = `${source}.${sourceSuffix}`;
    }

    if (source === AgGridEnum.GlossaryTermHierarchy) {
      observable = this._gridRest.getHierarchyGridDataAndDetinitions(source, viewCode)
    } else {
      observable = this._gridRest.getGenericGridDataAndDetinitionsV2(source, viewCode, params, ownerPartyRoleInstanceCode);
    }

    observable.pipe(map(response => {
      this._attachFunctions(response);

      return response;
    }), finalize(() => this.setMainColDefinitionPristine()))
      .subscribe(data => {
        this._getGenericGridDataAndDef.next({
          source: source,
          rows: data.rows,
          metadata: data.metadata
        } as GridInput);
      });
  }

  public async getPaginatedData(dashboardItem: DashboardItem, payload: PaginationDto, instanceCode?: string, relationshipScope?: string) {
    let source: string = relationshipScope ? AgGridEnum.CommonRelationship : dashboardItem.gridSource;
    let observable: Observable<AgGridModel>;
    const viewCode = instanceCode
      ? this._apiHelper.getUrlSection(instanceCode, null, dashboardItem)
      : dashboardItem.overrideViewCode || dashboardItem.viewCode;

    switch (source) {
      case AgGridEnum.GlossaryTermHierarchy:
        observable = this._gridRest.getHierarchyGridDataAndDetinitions(source, viewCode);
        break;
      case AgGridEnum.StepForm:
      case AgGridEnum.StepContainer:
        payload.LibraryCodes = dashboardItem.libraryCodes;
        observable = this._gridRest.getPaginatedData(source, viewCode, payload);
        break;
      case AgGridEnum.CommonRelationship:
        if (relationshipScope == RelationshipScope.Child) {
          observable = this._gridRest.getChildrenRelationshipsDataAndDefPaginated(source, viewCode, instanceCode, payload);
        }
        if (relationshipScope == RelationshipScope.Parent) {
          observable = this._gridRest.getParentRelationshipsDataAndDefPaginated(source, viewCode, instanceCode, payload);
        }
        break;
      default:
        observable = this._gridRest.getPaginatedData(source, viewCode, payload);
        break;
    }

    return await observable.pipe(map(response => {
      this._attachFunctions(response);

      return {
        source: source,
        rows: response.rows,
        metadata: response.metadata
      } as GridInput
    }), finalize(() => this.setMainColDefinitionPristine())).toPromise();
  }

  public getFormGridColumnDefinitions(code: string) {
    this._gridRest.getGridDefinitionBySource(code)
      .pipe(finalize(() => this._gridColumnDefinitions.next(undefined)))
      .subscribe(definitions => this._gridColumnDefinitions.next(definitions));
  }

  public mainQuickSearch(value: string) {
    this._mainQuickSearch.next(value);
  }

  public updateMainColumnDefinitions(columnDef: any) {
    this._mainColumnDefinitions.next(columnDef);
  }

  public setMainColDefinitionDirty() {
    this._mainColDefPristine.next(false);
  }

  public setMainColDefinitionPristine() {
    this._mainColDefPristine.next(true);
  }

  public restoreMainColumnState(presetView: PresetViewDto) {
    this.setMainColDefinitionPristine();
    this._restoreMainColumnState.next(presetView);
  }

  public setChangesGridRows(rows: Array<AgGridBaseModel>) {
    this._changesGridRows.next(rows);
  }

  public async getGridData(currentDashboardItem: DashboardItem, paginationDto: PaginationDto, instanceCode?: string, relationshipScope?: string) {
    return await this.getPaginatedData(currentDashboardItem, paginationDto, instanceCode, relationshipScope);
  }

  private _attachFunctions(response: AgGridModel) {
    const columnDef = response.gridColumnDefinition || response.metadata.columnDefinition;

    columnDef.forEach((col: GridColumnDef) => {
      this._tryToAttachFuncs(col);

      if (col.children) {
        col.children.forEach((c: GridColumnDef) => {
          this._tryToAttachFuncs(c);
        });
      }
    });
  }

  private _tryToAttachFuncs(col: GridColumnDef) {
    // beautiful date native date function
    // return new Date(data.data.creationDatetime)
    // .toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h24' })
    if (col.filterValueGetter) {
      col.filterValueGetter = eval.bind(null, col.filterValueGetter)();
    }
    if (col.valueGetter) {
      this._handleCellFunctions(col, 'valueGetter');
    }
    if (col.cellRenderer) {
      this._handleCellFunctions(col, 'cellRenderer');
    }
  }

  private _handleCellFunctions(col: GridColumnDef, funcProp: 'valueGetter' | 'cellRenderer') {
    if (col[funcProp].toString().startsWith('$fnDateFormat')) {
      const fieldName = col[funcProp].toString().split('=')[1];
      col[funcProp] = (params: any) => {
        if (!params.data) { return ''; }
        const dateValue = params.data[fieldName || 'creationDatetime'];
        return dateValue != null ? moment(dateValue).format('MM/DD/YYYY HH:mm') : '';
      };
    } else if (col[funcProp].toString().startsWith('$fnLocaleDateFormat')) {
      const fieldName = col[funcProp].toString().split('=')[1];
      col[funcProp] = (params: any) => {
        if (!params.data) { return ''; }
        const dateValue = params.data[fieldName || 'creationDatetime'];
        return dateValue != null ? moment(this._util.CreateDateAsUTCFromString((dateValue))).format('MM/DD/YYYY HH:mm') : '';
      };
    } else if (col[funcProp].toString().startsWith('$fnBoolToYesNo')) {
      const fieldName = col[funcProp].toString().split('=')[1];
      col[funcProp] = (params: any) => {
        if (!params.data) { return undefined; }
        const cellValue = params.data[fieldName];
        return cellValue ? 'Yes' : 'No';
      };
    }
    else {
      col[funcProp] = Function("params", col[funcProp] as string);
    }
  }

  private _mapHierarchicalWeData(data: Array<WorkeffortExecutionStageResponse>): Array<WorkeffortExecutionAgGridItem> {
    const items = new Array<WorkeffortExecutionAgGridItem>();

    data.forEach(stage => {
      items.push({
        requestCode: stage.requestCode,
        instanceCode: stage.workEffortInstanceCode,
        requestName: stage.requestName,
        stageName: stage.workEffortInstanceName,
        status: stage.status,
        completionDateTime: stage.completionDateTime,
        plannedDateTime: stage.plannedDateTime
      });

      if (stage.steps) {
        stage.steps.forEach(step => {
          items.push({
            requestCode: step.requestCode,
            instanceCode: step.workEffortInstanceCode,
            requestName: stage.requestName,
            stageName: stage.workEffortInstanceName,
            stepName: step.workEffortInstanceName,
            status: step.status,
            completionDateTime: step.completionDateTime,
            expectedDuration: step.expectedDuration,
            assignedTo: step.assignedTo,
            executedBy: step.executedBy,
            plannedDateTime: step.plannedDateTime,
            approvedScheduleDateTime: step.approvedScheduleDateTime
          });
        });
      }
    });
    return items;
  }

  private _mapFlowJourneyHierarchicalData(data: Array<WorkeffortJourneyHierarchicalFlowResponse>): Array<Partial<WorkeffortFlowJourneyHierarchicalAgGridItem>> {
    const items = new Array<Partial<WorkeffortFlowJourneyHierarchicalAgGridItem>>();

    let flowIndex: number = 0;
    let stageIndex: number = 0;
    let sequenceId = undefined;

    data.forEach(flow => {
      sequenceId = (++flowIndex).toString();

      ({ sequenceId, flowIndex, stageIndex } =
        this._mapFlowJourneyHierarchicalDataFlow(items, sequenceId, flow, flowIndex, stageIndex));
    });

    // por pedido de charly
    const actionsToCheck = ['onSuccessActionOutcome', 'onFailActionOutcome'];

    actionsToCheck.forEach(action => {
      items.forEach(item => {
        if (item[`${action}Code`]) {
          // busco step en la lista de items a mostrar en la grilla para obtener el sequenceId de "a donde va a ir"
          const goToStep = items.find(x => {
            return item[`${action}Code`] == x.stageWorkEffortInstanceName
              || item[action] == x.stepWorkEffortInstanceName;
          });

          if (goToStep) {
            item[action] = `${goToStep.sequenceId} - ${item[action]}`;
          }
        }
      });
    });

    return items;
  }

  private _mapFlowJourneyHierarchicalDataFlow(
    items: Partial<WorkeffortFlowJourneyHierarchicalAgGridItem>[],
    sequenceId: string,
    flow: WorkeffortJourneyHierarchicalFlowResponse,
    flowIndex: number,
    stageIndex: number) {

    this._mapFlowJourneyHierarchicalDataGeneric(items, sequenceId, flow);

    if (flow.children) {
      flow.children.forEach(stageOrFlow => {
        if (stageOrFlow.workEffortTypeLibraryCode.startsWith('FLW.')) {
          sequenceId = (++flowIndex).toString();

          ({ sequenceId, flowIndex, stageIndex } =
            this._mapFlowJourneyHierarchicalDataFlow(items, sequenceId, stageOrFlow, flowIndex, stageIndex));
        }
        else if (stageOrFlow.workEffortTypeLibraryCode.startsWith('STG.')) {
          const stage = stageOrFlow;

          sequenceId = `${flowIndex}.${(++stageIndex).toString()}`;

          this._mapFlowJourneyHierarchicalDataGeneric(items, sequenceId, flow, stage);

          if (stage.steps) {
            stage.steps.forEach((step, index) => {
              sequenceId = `${flowIndex}.${stageIndex}.${(index + 1).toString()}`;

              this._mapFlowJourneyHierarchicalDataGeneric(items, sequenceId, flow, stage, step);
              if (step.form && step.form.containers) {
                step.form.containers.forEach(container => {
                  this._mapFlowJourneyHierarchicalDataGeneric(items, undefined, flow, stage, step, container);
                });
              }
            });
          }
        }
      });
    }

    return { sequenceId, flowIndex, stageIndex };
  }

  private _mapFlowJourneyHierarchicalDataGeneric(
    items: Partial<WorkeffortFlowJourneyHierarchicalAgGridItem>[],
    sequenceId: string,
    flow: WorkeffortJourneyHierarchicalFlowResponse,
    stage?: WorkeffortJourneyHierarchicalStageResponse,
    step?: WorkeffortJourneyHierarchicalStepResponse,
    container?: WorkeffortJourneyHierarchicalFormContainerResponse
  ) {
    const navRules = step && step.navigationRules;
    const onSuccess = navRules && navRules.find(rule => rule.factorResolutionStatus === 'Success');
    const onFail = navRules && navRules.find(rule => rule.factorResolutionStatus === 'Fail');

    const instanceCode = container ? container.instanceCode :
      step && step.form ? step.form.instanceCode :
        step ? step.workEffortInstanceCode :
          stage ? stage.workEffortInstanceCode :
            flow.workEffortInstanceCode;

    const instanceName = container ? container.name :
      step && step.form ? step.form.name :
        step ? step.workEffortInstanceName :
          stage ? stage.workEffortInstanceName :
            flow.workEffortInstanceName;

    const libraryCode = container ? container.rootLibraryCode :
      step && step.form ? step.form.rootLibraryCode :
        step ? step.workEffortTypeLibraryCode :
          stage ? stage.workEffortTypeLibraryCode :
            flow.workEffortTypeLibraryCode;

    items.push({
      sequenceId: sequenceId,
      instanceCode: instanceCode,
      instanceName: instanceName,
      libraryCode: libraryCode,
      flowWorkEffortInstanceName: flow.workEffortInstanceName,
      stageWorkEffortInstanceName: stage && stage.workEffortInstanceName,
      stepWorkEffortTypeName: step && step.workEffortTypeName,
      stepWorkEffortInstanceCode: step && step.workEffortInstanceCode,
      stepWorkEffortInstanceName: step && step.workEffortInstanceName,
      formName: step && step.form && step.form.name,
      containerInstanceCode: container && container.instanceCode,
      containerCode: container && container.code,
      containerName: container && container.name,
      onSuccessActionOutcomeCode: onSuccess && container == undefined ? onSuccess.outcomeWorkEffortInstanceCode : undefined,
      onSuccessActionOutcome: onSuccess && container == undefined ? onSuccess.outcomeWorkEffortInstanceName : '--',
      onFailActionOutcome: onFail && container == undefined ? onFail.outcomeWorkEffortInstanceName : '--',
      onFailActionOutcomeCode: onFail && container == undefined ? onFail.outcomeWorkEffortInstanceCode : undefined
    });

    return items;
  }

}
