import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AvailableTenant } from 'src/app/models/available-tenant.model';
import { HttpHeaders } from '@angular/common/http';
import { Permission } from 'src/app/models/permission.model';
import { AvailablePlatformProvider } from 'src/app/models/available-platform-provider.model';

@Injectable({
  providedIn: 'root'
})
export class AuthRestService {

  constructor(private _rest: RestService) { }

  public login(username: string, password: string, platformProviderCode: string, singleSignOn: boolean, recaptchaToken: string): Observable<any> {
    const headers = new HttpHeaders()
      .append('silentError', 'false');
    const body = { username, password, platformProviderCode, recaptchaToken};

    var tokenEndpoint =`api/v1/login/token`;
    if(singleSignOn)
    {
      tokenEndpoint = `api/v1/login/token-sso`;
    }

    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: tokenEndpoint,
      body: body,
      headers: headers
    });
  }

  public getTenants(username: string): Observable<Array<AvailableTenant>> {
    return this._rest.get<AvailableTenant>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/user-account/${username}/tenants`
    });
  }

  public getPermissions(platformProviderCode: string): Observable<Array<Permission>> {
    return this._rest.get<Permission>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/login/permissions/${platformProviderCode}`
    });
  }

  public getUserDisplayName(): Observable<string> {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/user-account/current/displayname`
    })
  }

  public getUserSignature(): Observable<string> {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/user-account/current/signature`
    })
  }

  public updateCurrentUserSignature(base64Signature: string): Observable<any> {
    const body = { base64Signature:  base64Signature };

    return this._rest.put({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/user-account/current/signature`,
      body: body
    });
  }

  public getPlatformProviders(): Observable<Array<AvailablePlatformProvider>> {
    return this._rest.get<AvailableTenant>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/login/platform-providers`
    });
  }
}
