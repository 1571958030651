import { CacheService } from 'ngx-emerios-all';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class RestCacheService {
    constructor(_cacheService) {
        this._cacheService = _cacheService;
        this._defaultExpirationTime = 60 * 60 * 1000; // 60 minutes
        this._defaultCacheKey = 'rest-cache';
    }
    getFromCache(fullUrl, cacheKey) {
        const found = this._findByUrl(fullUrl, cacheKey);
        if (found) {
            const currentTime = Date.now();
            if (currentTime <= found.expirationTime) {
                return found.body;
            }
        }
        return undefined;
    }
    setOrUpdateResponse(response, expirationTime) {
        const found = this._findByUrl(response.url);
        if (found) {
            this._updateEntry(response, expirationTime);
        }
        else {
            this._createNewEntry(response, expirationTime);
        }
    }
    _createNewEntry(response, expirationTime, cacheKey) {
        const urls = this._cacheService.get(this._getCacheKey(cacheKey)) || [];
        const obj = {
            url: response.url,
            body: response.body,
            creationTime: Date.now(),
            expirationTime: Date.now() + (expirationTime || this._defaultExpirationTime)
        };
        urls.push(obj);
        this._cacheService.set(this._getCacheKey(cacheKey), urls);
    }
    _updateEntry(response, expirationTime, cacheKey) {
        const urls = this._cacheService.get(this._getCacheKey(cacheKey));
        const found = urls ? urls.find(x => x.url === response.url) : undefined;
        found.body = response.body;
        found.creationTime = Date.now();
        found.expirationTime = Date.now() + (expirationTime || this._defaultExpirationTime);
        this._cacheService.set(this._getCacheKey(cacheKey), urls);
    }
    _findByUrl(url, cacheKey) {
        const urls = this._cacheService.get(this._getCacheKey(cacheKey));
        const found = urls ? urls.find(x => x.url && x.url.includes(url)) : undefined;
        return found;
    }
    _getCacheKey(key) {
        return key || this._defaultCacheKey;
    }
}
RestCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestCacheService_Factory() { return new RestCacheService(i0.ɵɵinject(i1.CacheService)); }, token: RestCacheService, providedIn: "root" });
