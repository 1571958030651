/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./replacer-list.component";
import * as i3 from "../../services/behavior/replacer/replacer.service";
import * as i4 from "../../services/handler/field-value-handler/field-value-handler.service";
var styles_ReplacerListComponent = [".dropdown-menu[_ngcontent-%COMP%] {\n        display: block;\n        max-height: 200px;\n        overflow-y: auto;\n      }"];
var RenderType_ReplacerListComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ReplacerListComponent, data: {} });
export { RenderType_ReplacerListComponent as RenderType_ReplacerListComponent };
function View_ReplacerListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [], [[2, "active", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateSelectedChoice() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "replacer-match"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(4, null, ["", " "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "replacer-code"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActiveItem(_v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.matchingText; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.replacerCode; _ck(_v, 6, 0, currVal_3); }); }
function View_ReplacerListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "ul", [["class", "dropdown-menu"]], [[4, "top", "px"], [4, "left", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "replacer-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Suggestions"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReplacerListComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.choices; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.position == null) ? null : _co.position.top); var currVal_1 = ((_co.position == null) ? null : _co.position.left); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ReplacerListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "replacer-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ReplacerListComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.renderList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ReplacerListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "replacer-list-menu", [], null, [["document", "keydown.ArrowDown"], ["document", "keydown.ArrowUp"], ["document", "keydown.Enter"]], function (_v, en, $event) { var ad = true; if (("document:keydown.ArrowDown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onArrowDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keydown.ArrowUp" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onArrowUp($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:keydown.Enter" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onEnter($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_ReplacerListComponent_0, RenderType_ReplacerListComponent)), i0.ɵdid(1, 245760, null, 0, i2.ReplacerListComponent, [i3.ReplacerService, i4.FieldValueHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReplacerListComponentNgFactory = i0.ɵccf("replacer-list-menu", i2.ReplacerListComponent, View_ReplacerListComponent_Host_0, {}, {}, []);
export { ReplacerListComponentNgFactory as ReplacerListComponentNgFactory };
