import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericModalComponent } from '../modals/generic-modal/generic-modal.component';
import { MessageModalComponent } from '../modals/message-modal/message-modal.component';
import { CreatePartyRelationshipComponent } from '../modals/create-party-relationship/create-party-relationship.component';
import { CreateProductFeatureRelationshipComponent } from '../modals/create-product-feature-relationship/create-product-feature-relationship.component';
import { LinkChangeModalComponent } from '../modals/link-change-modal/link-change-modal.component';
import { SaveGridViewComponent } from '../modals/save-grid-view/save-grid-view.component';
import { ZipcodeGridModalComponent } from '../modals/zipcode-grid-modal/zipcode-grid-modal.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomHttpLoaderFactory } from '../services/rest/translate-loader/translate-loader.service';
import { RestService } from 'ngx-emerios-all';
import { EmeriosComponentsModule } from './emerios-components.module';
import { CoreComponentsModule } from './core-components.module';
import { DynamicFormsComponentsModule } from './dynamic-forms-components.module';
import { CloneEntityComponent } from '../modals/clone-entity/clone-entity.component';
import { CreateInstanceRelationshipComponent } from '../modals/create-instance-relationship/create-instance-relationship.component';
import { DynamicFormPreviewComponent } from '../modals/dynamic-form-preview/dynamic-form-preview.component';
import { CreateFormRelationshipComponent } from '../modals/create-form-relationship/create-form-relationship.component';
import { DiscardChangesModalComponent } from '../modals/discard-changes-modal/discard-changes-modal.component';
import { GridModule } from '../components/grid/grid.module';
import { ErrorDetailModalComponent } from '../modals/error-detail-modal/error-detail-modal.component';
import { SignatureModalComponent } from '../modals/signature-modal/signature-modal.component';
import { SignatureComponentsModule } from './signature/signature.module';

const components = [
    GenericModalComponent,
    MessageModalComponent,
    CreatePartyRelationshipComponent,
    CreateProductFeatureRelationshipComponent,
    LinkChangeModalComponent,
    SaveGridViewComponent,
    ZipcodeGridModalComponent,
    CloneEntityComponent,
    CreateInstanceRelationshipComponent,
    CreateFormRelationshipComponent,
    DynamicFormPreviewComponent,
    DiscardChangesModalComponent,
    ErrorDetailModalComponent,
    SignatureModalComponent
];

@NgModule({
    declarations: [components],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreComponentsModule,
        EmeriosComponentsModule,
        DynamicFormsComponentsModule,
        GridModule,
        SignatureComponentsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: CustomHttpLoaderFactory,
                deps: [RestService]
            }
        })
    ],
    exports: [],
    entryComponents: [components]
})
export class ModalComponentsModule { }
