import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class StoreService {
    constructor() {
        this._theme = new BehaviorSubject(undefined);
        this._isMobile = new BehaviorSubject(undefined);
        this._appendBodyClass = new BehaviorSubject(undefined);
        this._contextParams = new BehaviorSubject(undefined);
        this.theme$ = this._theme.asObservable().pipe(filter(x => x !== undefined));
        this.isMobile$ = this._isMobile.asObservable().pipe(filter(x => x !== undefined));
        this.appendBodyClass$ = this._appendBodyClass.asObservable().pipe(filter(x => x !== undefined));
        this.contextParams$ = this._contextParams.asObservable().pipe(filter(x => x !== undefined));
    }
    setTheme(theme) {
        this._theme.next(theme);
        this._appendBodyClass.next(theme);
    }
    appendBodyClass(className) {
        this._appendBodyClass.next(className);
    }
    setContextParams(source, presetView) {
        this._contextParams.next({ source, presetView });
    }
    setIsMobile(value) {
        this._isMobile.next(value);
    }
}
StoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreService_Factory() { return new StoreService(); }, token: StoreService, providedIn: "root" });
