<div class="modal-header mb-2">
  <h3 class="modal-title pull-left" [innerHtml]="title"></h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body campaign-modal">
  <h2 class="text-center my-2" *ngIf="isSuccess()">
    <i class="fas fa-check fa-2x success-color"></i>
  </h2>
  <h2 class="text-center my-2" *ngIf="isInfo()">
    <i class="fas fa-info-circle fa-2x info-color"></i>
  </h2>
  <h2 class="text-center my-2" *ngIf="isWarning()">
    <i class="fas fa-exclamation-triangle fa-2x warning-color"></i>
  </h2>
  <h2 class="text-center my-2" *ngIf="isError()">
    <i class="fas fa-times-circle fa-2x error-color"></i>
  </h2>

  <p class="text-center" [innerHtml]="message"></p>
  <ul class="text-center">
    <li *ngFor="let item of items">{{item}}</li>
  </ul>
</div>

<div class="modal-footer mt-2">
  <button type="button" class="btn btn-primary btn-fill" (click)="closeModal()">{{ okButton?.text }}</button>
</div>