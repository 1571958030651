<div class="container" [id]="formControlName">
    <div class="row" *ngFor="let signature of signatures">
        <app-signature-viewer 
            [user-mame]="signature.userName"
            [step-name]="signature.workEffortInstanceName"
            [step-type]="signature.workEffortTypeCode"
            [signature]="signature.base64Signature"
            [datetime]="signature.creationDateTime">
        </app-signature-viewer>
    </div>
</div>
