import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { MultiselectConfig } from 'ngx-emerios-all';
import { BsModalRef } from 'ngx-bootstrap';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ProductAvailabilityDto } from 'src/app/models/products.model';
import { EntityCatalogItem } from 'src/app/models/catalog.models';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { FieldAttributes } from 'src/app/models/form-field-definition.models';

export interface ModalParams {
  title: string;
  owner: string;
  instanceCode: string;
  fromProductFeature: boolean;
  fromProductType: boolean;
  currentRelationships: any;
  callback: Function;
}

export interface ViewModel {
  classificationPurposeLabel: string;
  relatedLabel: string;
  applicabilityLabel: string;
}

@Component({
  selector: 'app-create-product-feature-relationship',
  templateUrl: './create-product-feature-relationship.component.html',
  styleUrls: ['./create-product-feature-relationship.component.sass']
})
export class CreateProductFeatureRelationshipComponent implements OnInit, OnDestroy {
  public params = {} as ModalParams;
  public model = {} as ViewModel;

  public form: FormGroup;
  public classificationPurposeConfig: MultiselectConfig;
  public relatedMultiselectConfig: MultiselectConfig;
  public applicabilityConfig: MultiselectConfig;

  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _catalog: CatalogService,
    private _elementHelper: ElementHelperService,
    private _modal: BsModalRef) {
  }

  public okModal() {
    const relationships = Array<ProductAvailabilityDto>();
    const selectedItems = this.form.controls[this.relatedMultiselectConfig.name].value;
    const applicabilityTypeInstanceCode = this.form.controls[this.applicabilityConfig.name].value[0];
    const classificationPurposeLibrayCode = this.form.controls[this.classificationPurposeConfig.name].value[0];

    if (this.params.fromProductFeature) {
      relationships.push(...selectedItems.map((item: string) => {
        return {
          productCategoryCode: item,
          featureInstanceCode: this.params.instanceCode,
          applicabilityTypeInstanceCode: applicabilityTypeInstanceCode,
          classificationPurposeLibrayCode: classificationPurposeLibrayCode
        }
      }));
    } else if (this.params.fromProductType) {
      relationships.push(...selectedItems.map((item: string) => {
        return {
          productCategoryCode: this.params.instanceCode,
          featureInstanceCode: item,
          applicabilityTypeInstanceCode: applicabilityTypeInstanceCode,
          classificationPurposeLibrayCode: classificationPurposeLibrayCode
        }
      }));
    }

    if (this.params.callback) {
      this.params.callback(relationships);
    }
    this._modal.hide();
  }

  public closeModal() {
    this._modal.hide();
  }

  ngOnInit() {
    let clasificationPurpose: Array<string>;
    let codesToFilter: Array<string>;
    let catalog: string;

    if (this.params.fromProductFeature) {
      catalog = CatalogEnum.ProductTypes;
      codesToFilter = this._setCodesToFilter('instanceCodeTo');
    } else if (this.params.fromProductType) {
      catalog = CatalogEnum.Features;
      codesToFilter = this._setCodesToFilter('instanceCodeTo');
      clasificationPurpose = ['PRP.PRD'];
    }

    this._configureElements();

    forkJoin(
      this._catalog.getSingleWithFilterFromServer(catalog, [this.params.owner]),
      this._catalog.getFromServer([CatalogEnum.ProductFeatureApplicability, CatalogEnum.ClassificationPurpose])
    ).subscribe(catalogs => {
      this._setElementItems(catalogs, codesToFilter);

      const form = {};
      form[this.classificationPurposeConfig.name] = new FormControl(clasificationPurpose, Validators.required);
      form[this.relatedMultiselectConfig.name] = new FormControl(undefined, Validators.required);
      form[this.applicabilityConfig.name] = new FormControl(undefined, Validators.required);

      this.form = new FormGroup(form);
    });
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _setCodesToFilter(prop: string) {
    const codesToFilter = this.params.currentRelationships
      .map((row: any) => {
        return row[prop];
      });

    return codesToFilter;
  }

  private _filterItemsByCode(items: Array<EntityCatalogItem>, codesToFilter: Array<string>) {
    return items.filter(x => !codesToFilter.includes(x.code));
  }

  private _configureElements() {
    this.classificationPurposeConfig = this._getClassificationPurposeElementConfig();
    this.relatedMultiselectConfig = this._getRelatedElementConfig();
    this.applicabilityConfig = this._getApplicabilityElementConfig();

    let relatedLabel: string;

    if (this.params.fromProductFeature) {
      relatedLabel = 'Product Types';
      this.classificationPurposeConfig.readonly = false;
    } else if (this.params.fromProductType) {
      relatedLabel = 'Product Features';
      this.classificationPurposeConfig.readonly = true;
    }

    this.model.classificationPurposeLabel = 'Clasification Purpose';
    this.model.relatedLabel = relatedLabel;
    this.model.applicabilityLabel = 'Applicability';
  }

  private _setElementItems(catalogs: any, codesToFilter: Array<string>) {
    const filteredItems = this._filterItemsByCode(catalogs[0].items, codesToFilter);

    this.relatedMultiselectConfig.items = this._getItemsWithDefaultLang(filteredItems);
    this.applicabilityConfig.items = catalogs[1][0].items;
    this.classificationPurposeConfig.items = catalogs[1][1].items;
  }

  private _getClassificationPurposeElementConfig() {
    const clasificationPurposeAttrs = {
      name: 'clasificationPurpose',
      required: true
    } as FieldAttributes;

    const config = this._elementHelper.getMultiSelectConfig(clasificationPurposeAttrs);

    config.singleSelect = true;
    config.placeholder = '-- Select --';
    config.validationMessages.required = 'This is required';

    return config;
  }

  private _getRelatedElementConfig() {
    const relatedMultiselectAttrs = {
      name: 'productRelationship',
      required: true,
      editable: true
    } as FieldAttributes;

    const config = this._elementHelper.getMultiSelectConfig(relatedMultiselectAttrs);

    config.placeholder = '-- Select --';
    config.validationMessages.required = 'This is required';

    return config;
  }

  private _getApplicabilityElementConfig() {
    const applicabilityAttrs = {
      name: 'productApplicability',
      required: true,
      editable: true
    } as FieldAttributes;

    const config = this._elementHelper.getMultiSelectConfig(applicabilityAttrs);

    config.singleSelect = true;
    config.placeholder = '-- Select --';
    config.validationMessages.required = 'This is required';

    return config;
  }

  private _getItemsWithDefaultLang(items: Array<EntityCatalogItem>) {
    let defaultItems = items.map(item => {
      if (item.descriptions) {
        return {
          code: item.code,
          description: item.descriptions[0].text
        }
      } else {
        return item;
      }
    });

    return defaultItems;
  }

}
