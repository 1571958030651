import { Component, OnInit, forwardRef, OnChanges, OnDestroy, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, Validators, FormGroup } from '@angular/forms';
import { ElementConfig, MultiselectConfig, InputConfig } from 'ngx-emerios-all';
import { FieldDefinition, FieldAttributes } from 'src/app/models/form-field-definition.models';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { Subscription } from 'rxjs';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { BaseCatalogItem } from 'src/app/models/catalog.models';
import { CatalogEnum, CatalogNativeEnum } from 'src/app/enums/catalogs';
import { QssHandlerService } from 'src/app/services/handler/qss-handler/qss-handler.service';
import { take } from 'rxjs/operators';

const noOp = () => { };

export interface ViewModel {
  factors: Array<ViewModelFactor>;
  workeffortList: Array<BaseCatalogItem>;
  yesNoCatalog: Array<BaseCatalogItem>;
  currentStepName: string;
}

export interface ViewModelFactor {
  name: string,
  actionName: string,
  fireSaveName: string,
  weConfig: MultiselectConfig,
  actionConfig: InputConfig,
  fireSaveConfig: MultiselectConfig
}

export interface InnerModel {
  ruleInstanceCode: string;
  description: string;
  fireSaveAction: boolean;
  factorWorkEffortInstanceCode: string;
  outcomeWorkEffortInstanceCode: string;
  factorResolutionStatus: string;
}

export const NAVIGATION_RULES_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NavigationRulesComponent),
  multi: true
};

@Component({
  selector: 'app-navigation-rules',
  templateUrl: './navigation-rules.component.html',
  styleUrls: ['./navigation-rules.component.sass'],
  providers: [NAVIGATION_RULES_CONTROL_VALUE_ACCESSOR]
})
export class NavigationRulesComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public config: ElementConfig;
  @Input() public definition: FieldDefinition;
  @Input() public forceValidation: EventEmitter<any>;

  @Output() public changed = new EventEmitter();

  public form: FormGroup;
  public control: FormControl;
  public model = {} as ViewModel;
  public internalModel: Array<InnerModel>;

  private _parentInstanceCode: string;
  private _flowInstanceCode: string;
  private _formOperationType: FormOperationType;
  private _subscriptions: Array<Subscription> = [];
  private _onChange: any;
  private _formSubscription: Subscription;
  private _ruleInstanceCode: string;

  writeValue(obj: Array<InnerModel>): void {
    if (obj != this.control.value) {
      if (obj && obj.length > 0) {
        this.internalModel = obj;
        this._ruleInstanceCode = this.internalModel[0].ruleInstanceCode;
        this._configureComponent();
      }
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  onTouchedCallback: () => void = noOp;

  constructor(
    private _detailViewHandler: DetailViewHandlerService,
    private _elementHelper: ElementHelperService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _qssHandlerService: QssHandlerService,
    private _catalog: CatalogService) { }

  public getControlErrors() {
    let errorMessage: string;

    if (this.control.errors && this.control.errors.required) {
      errorMessage = 'This is required';
    }

    return errorMessage;
  }

  public setAsDirty() {
    this.control.markAsDirty();
  }

  ngOnInit() {
    this._subscriptions.push(
      this._fieldValueHandler.anyValue$.subscribe(anyValue => {
        this.model.currentStepName = `${anyValue.changes.get('instanceName')}`;
      }));

    this._subscriptions.push(
      this._fieldValueHandler.rowEntityData$.subscribe(data => {
        this._parentInstanceCode = data.parentInstanceCode;
      }));

    this._subscriptions.push(
      this._qssHandlerService.value$.subscribe(qss => {
        this._flowInstanceCode = qss.flowInstanceCode;
      }));

    this._subscriptions.push(
      this._detailViewHandler.mode$
        .pipe(take(1))
        .subscribe(mode => {
          this._formOperationType = mode;

          this._configureComponent();
          this._updateInnerValue();
        }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this._initializeFormControl();
    }

    if (changes.forceValidation && changes.forceValidation.currentValue
      && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
    this._formSubscription.unsubscribe();
  }

  private _initializeFormControl() {
    if (this.definition.attributes.required) {
      this.control = new FormControl(undefined, Validators.required);
    } else {
      this.control = new FormControl(undefined);
    }
  }

  private _configureComponent() {
    const factors = new Array<ViewModelFactor>();
    const form = {};
    const successFactorName = 'Success';
    const failFactorName = 'Fail';

    factors.push({
      name: successFactorName,
      actionName: `input${successFactorName}`,
      fireSaveName: `fireSave${successFactorName}`,
      weConfig: this._getFactorMultiselectConfig('Success'),
      actionConfig: this._getFactorActionNameConfig('Success'),
      fireSaveConfig: this._getFactorMultiselectConfig('Success', CatalogNativeEnum.YesNo)
    });
    factors.push({
      name: failFactorName,
      actionName: `input${failFactorName}`,
      fireSaveName: `fireSave${failFactorName}`,
      weConfig: this._getFactorMultiselectConfig('Fail'),
      actionConfig: this._getFactorActionNameConfig('Fail'),
      fireSaveConfig: this._getFactorMultiselectConfig('Fail', CatalogNativeEnum.YesNo)
    });

    factors.forEach(item => {
      const factor = this.internalModel && this.internalModel
        .find(x => x.factorResolutionStatus === item.name.toUpperCase());
      const weValue = factor ? [factor.outcomeWorkEffortInstanceCode] : undefined;
      const actionValue = factor ? factor.description : undefined;
      const fireSaveActionValue = factor ? [factor.fireSaveAction.toString()] : undefined;

      form[item.name] = new FormControl(weValue);
      form[item.actionName] = new FormControl(actionValue);
      form[item.fireSaveName] = new FormControl(fireSaveActionValue);
    });

    this.form = new FormGroup(form);
    this.model.factors = factors;

    this._createFormSubscription();
    this._getWorkeffortCatalog();
  }

  private _updateInnerValue() {
    if (this.form.status === 'VALID') {
      this.control.setValue(this.internalModel);
    } else {
      this.control.setValue(undefined);
    }

    if (this._onChange) {
      this._onChange(this.control.value);
    }
  }

  private _getFactorMultiselectConfig(name: string, catalog?: CatalogNativeEnum) {
    const attr = {
      name: name,
      editable: [FormOperationType.Create, FormOperationType.Edit].includes(this._formOperationType)
    } as FieldAttributes;

    const config = this._elementHelper.getMultiSelectConfig(attr);

    config.singleSelect = true;

    if (catalog) {
      config.allowSearchFilter = false;
      this.model.yesNoCatalog = this._catalog.getNativeCatalog(catalog);
    }

    return config;
  }

  private _getFactorActionNameConfig(name: string) {
    const attr = {
      name: name,
      editable: [FormOperationType.Create, FormOperationType.Edit].includes(this._formOperationType),
      maxlength: 10
    } as FieldAttributes;

    const config = this._elementHelper.getInputConfig(attr);

    return config;
  }

  private _getWorkeffortCatalog() {
    if (this._parentInstanceCode) {
      this._catalog
        .getFiltrableCatalog<BaseCatalogItem>(CatalogEnum.StepsAndStagesByFlow, [this._flowInstanceCode, this._parentInstanceCode])
        .subscribe(catalog => {
          this.model.workeffortList = catalog.items;
        });
    }
  }

  private _createFormSubscription() {
    if (this._formSubscription) {
      this._formSubscription.unsubscribe();
    }

    this._formSubscription = this.form.valueChanges
      .subscribe(values => {
        this.internalModel = [];

        this.model.factors.forEach(factor => {
          const config = this.model.factors.find(x => x.name == factor.name);

          if (values[factor.name] && values[factor.name][0]) {
            this.internalModel.push(
              {
                ruleInstanceCode: this._ruleInstanceCode,
                factorWorkEffortInstanceCode: null,
                outcomeWorkEffortInstanceCode: values[factor.name] && values[factor.name][0],
                factorResolutionStatus: factor.name,
                description: values[`input${factor.name}`],
                fireSaveAction: values[`fireSave${factor.name}`] && values[`fireSave${factor.name}`][0],
              });
            const markAsReadonly = ![FormOperationType.Create, FormOperationType.Edit].includes(this._formOperationType);

            config.actionConfig.readonly = markAsReadonly;
            config.fireSaveConfig.disabled = markAsReadonly;

          } else {
            config.actionConfig.readonly = true;
            config.fireSaveConfig.disabled = true;
          }
        });

        this._updateInnerValue();
      });
  }
}
