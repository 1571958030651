import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Entity } from 'src/app/models/entity.model';
import { JobExtendedStatusItem } from 'src/app/models/background-task.model';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BackgroundTaskRestService {

  constructor(private _rest: RestService) { }

  public getStatus(jobExecutionUid: string):
    Observable<[Entity, Array<JobExtendedStatusItem>]> {
    const headers = new HttpHeaders()
      .append('restCache', 'false');

    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      headers: headers,
      endpoint: `api/v1/background-job/${jobExecutionUid}/status`
    }).pipe(map(result => ([result.Job as Entity, result.ExtendedStatus as Array<JobExtendedStatusItem>])));
  }
}
