import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.sass']
})
export class TabItemComponent implements OnInit, OnChanges {
  @Input() public label: string;

  public activeIndex: number = 0;
  public tabIndex: number = 0;

  constructor() { }

  public active() {
    return this.activeIndex === this.tabIndex;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
  }
}
