<div class="panel with-nav-tabs panel-default">
    <ul class="nav nav-tabs tabs-group" *ngIf="showTabs">
        <li *ngFor="let tab of tabs; let i = index" [ngClass]="{ 'active': currentIndex === i }">
            <a href="javascript:void(0)" (click)="handleTabClick(i)">{{tab.label}}</a>
        </li>
        <!-- <li class="dropdown" *ngIf="dropdownChangeRequests && dropdownChangeRequests.length > 0">
            <a href="#" data-toggle="dropdown">More <span class="caret"></span></a>
            <ul class="dropdown-menu" role="menu" style="max-height: 300px; overflow: auto;">
                <li *ngFor="let item of dropdownChangeRequests" [ngClass]="{ 'active': item.code === selectedTab }">
                    <a href="javascript:void(0)" (click)="updateFilter(item.code)">{{item.desc}}</a>
                </li>
            </ul>
        </li> -->
    </ul>

    <ng-content></ng-content>
</div>