/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tab-group.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tab-group.component";
var styles_TabGroupComponent = [i0.styles];
var RenderType_TabGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TabGroupComponent, data: {} });
export { RenderType_TabGroupComponent as RenderType_TabGroupComponent };
function View_TabGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleTabClick(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.currentIndex === _v.context.index)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.label; _ck(_v, 5, 0, currVal_1); }); }
function View_TabGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "nav nav-tabs tabs-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TabGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "panel with-nav-tabs panel-default"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TabGroupComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTabs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TabGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-tab-group", [], null, null, null, View_TabGroupComponent_0, RenderType_TabGroupComponent)), i1.ɵdid(1, 1818624, null, 1, i3.TabGroupComponent, [], null, null), i1.ɵqud(603979776, 1, { tabs: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TabGroupComponentNgFactory = i1.ɵccf("app-tab-group", i3.TabGroupComponent, View_TabGroupComponent_Host_0, { currentIndex: "currentIndex", showTabs: "showTabs" }, { tabClicked: "tabClicked" }, ["*"]);
export { TabGroupComponentNgFactory as TabGroupComponentNgFactory };
