import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavigationUrlEnum } from 'src/app/enums/navigation-url';
import * as moment from 'moment-mini';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class UtilService {
    constructor(_router) {
        this._router = _router;
        this._history = [];
        this._router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((e) => this._history = [...this._history, e.url]);
    }
    generateRndNumber(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
    }
    getUrlWithSource() {
        const slices = location.href.split('/');
        const url = slices.slice(0, slices.length - 1).join('/') + '/';
        return url;
    }
    getUrlStateWithSource() {
        const urlWithSource = this.getUrlWithSource();
        const url = urlWithSource.split('#')[1];
        return url;
    }
    getPreviousUrl() {
        return this._history[this._history.length - 2] || `/${NavigationUrlEnum.CampaignBuilder}`;
    }
    getLibraryCodeFromInstance(instanceCode) {
        return instanceCode.substring(0, instanceCode.lastIndexOf('.'));
    }
    getOperationFromInstance(instanceCode) {
        const splited = instanceCode.split('.');
        return splited[splited.length - 2];
    }
    ObjectKeysToCamelCase(element) {
        return Object.fromEntries(Object.entries(element).map(x => [x[0].charAt(0).toLowerCase() + x[0].slice(1),
            x[1]]));
    }
    ObjectKeysToPascalCase(element) {
        return Object.fromEntries(Object.entries(element).map(x => [x[0].charAt(0).toUpperCase() + x[0].slice(1),
            x[1]]));
    }
    CreateDateAsUTCFromString(dateString) {
        return moment.utc(dateString).toDate();
    }
}
UtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilService_Factory() { return new UtilService(i0.ɵɵinject(i1.Router)); }, token: UtilService, providedIn: "root" });
