import { Component, OnInit, OnDestroy } from '@angular/core';
import { FieldAttributes } from 'src/app/models/form-field-definition.models';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { BsModalRef } from 'ngx-bootstrap';
import { MultiselectConfig } from 'ngx-emerios-all';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { CatalogEnum } from 'src/app/enums/catalogs';

export interface ModalParams {
  title: string;
  detailCode: string;
  dynafFrom: DynafEnum;
  callback: Function;
}

export interface ViewModel {
  title: string;
  formValid: boolean;
  showWarning: boolean;

  toInstanceAttr: FieldAttributes;
  toInstanceConfig: MultiselectConfig;
}

@Component({
  selector: 'app-create-form-relationship',
  templateUrl: './create-form-relationship.component.html',
  styleUrls: ['./create-form-relationship.component.sass']
})
export class CreateFormRelationshipComponent implements OnInit, OnDestroy {
  public params = {} as ModalParams;
  public model = {} as ViewModel;

  public form: FormGroup;

  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _catalog: CatalogService,
    private _elementHelper: ElementHelperService,
    private _modal: BsModalRef) { }

  public okModal() {
    const callbackObject = {} as any;
    const instanceTo = this.form.controls[this.model.toInstanceConfig.name].value[0];

    if (this.params.dynafFrom === DynafEnum.FormView) {
      callbackObject.instanceFromCode = this.params.detailCode;
      callbackObject.instanceToCode = instanceTo;
    } else {
      callbackObject.instanceFromCode = instanceTo;
      callbackObject.instanceToCode = this.params.detailCode;
    }

    this._modal.hide();
    if (this.params.callback) {
      this.params.callback(callbackObject);
    }
  }

  public closeModal() {
    this._modal.hide();
  }

  ngOnInit() {
    this.model.title = this.params.title;

    this._configureElements();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _configureElements() {
    const label = this.params.dynafFrom === DynafEnum.FormView
      ? 'Subform and Grid Instances' : 'Form Instances';
    const catalogs = this.params.dynafFrom === DynafEnum.FormView
      ? [CatalogEnum.Dynas, CatalogEnum.Dynag] : [CatalogEnum.DynafInstance];

    this.model.toInstanceAttr = {
      label: label,
      name: 'instanceTo',
      required: true,
      editable: true
    } as FieldAttributes;

    this._catalog.getFromServer(catalogs)
      .subscribe(catalog => {
        this.model.toInstanceConfig = this._elementHelper
          .getMultiSelectConfig(this.model.toInstanceAttr);

        this.model.toInstanceConfig.singleSelect = true;
        this.model.toInstanceConfig.items = catalog.map(function(a) { return a.items; })
          .reduce(function(a, b) { return a.concat(b); });

        this._configureForm();
      });
  }

  private _configureForm() {
    const form = {};

    form[this.model.toInstanceConfig.name] = new FormControl(undefined, Validators.required);

    this.form = new FormGroup(form);

    this._subscriptions.push(
      this.form.statusChanges.subscribe(status => this.model.formValid = status === 'VALID')
    );
  }

}
