export interface BaseInstance {
    instanceCode: string;
    instanceName: string;
    instanceDescription: string;
    code: string;
    ownerPartyRoleInstanceCode: string;
    currentStatus: EntityStatus;
    changeRequest: EntityChangeRequest;
    request: EntityChangeRequest;
}

export interface Request {
    code: string;
    name: string;
    description: string;
    ownerPartyRoleInstanceCode: string;
    currentStatusCode: string;
    currentStatusName: string;
    requestor: string;
    creationDateTime: string;
    currentWorkEffortName: string;
    journeyWorkEffortExecutionToken?: string;
    workEffortExecutionToken?: string;
    associatedApprovalFlowInstanceCode?: string;
}

export class Entity implements BaseInstance, Request {
    instanceCode: string;
    instanceName: string;
    instanceDescription: string;
    ownerPartyRoleInstanceCode: string;
    //
    code: string;
    name: string;
    description: string;
    currentStatus: EntityStatus;
    changeRequest: EntityChangeRequest;
    request: EntityChangeRequest;
    currentStatusCode: string;
    currentStatusName: string;
    requestor: string;
    creationDateTime: string;
    currentWorkEffortName: string;
    journeyWorkEffortExecutionToken?: string;
    workEffortExecutionToken?: string;
    associatedApprovalFlowInstanceCode?: string;
    isPromoteRequest: boolean;

    [props: string]: any;

    public getUniqueCode() {
        return this._isRequestEntity() ? this.code : this.instanceCode;
    }

    public getUniqueName() {
        if (this._isRequestEntity()) {
            return this.name;
        } else {
            return this.instanceName
                ? this.instanceName
                : (this.nameTranslations && this.nameTranslations[0].text); // || this.name;
        }
    }

    public getChangeRequestStatusCode() {
        return this._isRequestEntity() ? this.currentStatusCode : this.changeRequest && this.changeRequest.currentStatusCode;
    }

    public getRequestStatusCode() {
        return this._isRequestEntity() ? this.currentStatusCode : this.request && this.request.currentStatusCode;
    }

    private _isRequestEntity(): boolean {
        return this.code && (this.code.startsWith('REQUEST.') || this.code.startsWith('CHANGE_REQUEST.'));
    }
}

export interface EntityStatus {
    code: string;
    name: string;
    statusCreationDateTime: string;
    statusDateTimeFrom: string;
    statusDateTimeThru: string;
}

export interface EntityChangeRequest {
    currentStatusCode: string;
    currentStatusName: string;
    requestCode: string;
    requestName: string;
    description: string;
}

export interface EntityPartyRoleRelationship {
    partyRoleFrom: EntityRelationshipPartyRole;
    partyRoleTo: EntityRelationshipPartyRole;
    dateTimeFrom: Date;
    dateTimeThru: Date;
}

export interface EntityRelationshipPartyRole {
    code: string;
    partyInstanceCode: string;
}

export interface EntityInstanceRelationship {
    instanceFromCode: string;
    instanceToCode: string;
}
