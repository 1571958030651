/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature-viewer-group.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../signature-viewer/signature-viewer.component.ngfactory";
import * as i3 from "../signature-viewer/signature-viewer.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./signature-viewer-group.component";
var styles_SignatureViewerGroupComponent = [i0.styles];
var RenderType_SignatureViewerGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureViewerGroupComponent, data: {} });
export { RenderType_SignatureViewerGroupComponent as RenderType_SignatureViewerGroupComponent };
function View_SignatureViewerGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-signature-viewer", [], null, null, null, i2.View_SignatureViewerComponent_0, i2.RenderType_SignatureViewerComponent)), i1.ɵdid(2, 114688, null, 0, i3.SignatureViewerComponent, [], { userName: [0, "userName"], stepName: [1, "stepName"], stepType: [2, "stepType"], signature: [3, "signature"], dateTime: [4, "dateTime"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.userName; var currVal_1 = _v.context.$implicit.workEffortInstanceName; var currVal_2 = _v.context.$implicit.workEffortTypeCode; var currVal_3 = _v.context.$implicit.base64Signature; var currVal_4 = _v.context.$implicit.creationDateTime; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SignatureViewerGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SignatureViewerGroupComponent_1)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.signatures; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formControlName; _ck(_v, 0, 0, currVal_0); }); }
export function View_SignatureViewerGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-signature-viewer-group", [], null, null, null, View_SignatureViewerGroupComponent_0, RenderType_SignatureViewerGroupComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.SignatureViewerGroupComponent]), i1.ɵdid(2, 114688, null, 0, i6.SignatureViewerGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SignatureViewerGroupComponentNgFactory = i1.ɵccf("app-signature-viewer-group", i6.SignatureViewerGroupComponent, View_SignatureViewerGroupComponent_Host_0, { formControlName: "formControlName" }, {}, []);
export { SignatureViewerGroupComponentNgFactory as SignatureViewerGroupComponentNgFactory };
