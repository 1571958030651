import { setTheme } from 'ngx-bootstrap/utils';
import { CustomHttpLoaderFactory } from './services/rest/translate-loader/translate-loader.service';
import 'ag-grid-enterprise';
const ɵ0 = CustomHttpLoaderFactory;
export class AppModule {
    constructor() {
        setTheme('bs3');
    }
}
export { ɵ0 };
