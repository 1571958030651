import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CacheService } from 'ngx-emerios-all';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { DetailViewHandlerService } from '../../handler/detail-view-handler/detail-view-handler.service';
import { ElementHelperService } from '../../helper/element-helper/element-helper.service';
import { EntityRestService } from '../../rest/entity-rest/entity-rest.service';
import { UtilService } from '../util/util.service';
import { PresetViewDto } from 'src/app/models/preset-view.model';
import { FieldAttributes } from 'src/app/models/form-field-definition.models';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { NavigationHelperService } from '../../helper/navigation-helper/navigation-helper.service';
import { NavigationUrlEnum } from 'src/app/enums/navigation-url';
import { FormOperationType } from 'src/app/models/operation.models';
import { JourneyStep, Journey } from 'src/app/models/journey.models';
import { JourneyHandlerService } from '../../handler/journey-handler/journey-handler.service';
import { filter } from 'rxjs/internal/operators/filter';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';

export interface BreadcrumbConfig {
  homeUrl: string;
  items: Array<BreadcrumbItem>
}

export interface BreadcrumbItem {
  url: string;
  dashboardItem: DashboardItem;
  current?: boolean;
  type?: BreadcrumbItemType;
  breadcrumbContent: BreadcrumbContent;
}

export interface BreadcrumbContent {
  instanceName: string;
  instanceCode?: string;
  instanceType?: string;
  sequenceId?: number;
  viewMode?: string;
  isAgreNavigationStep: boolean;
}

export enum BreadcrumbItemType {
  Entity,
  Instance
}

export enum BreadcrumbMode {
  Entity,
  Journey
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService implements OnDestroy {
  public readonly breadcrumb$: Observable<Array<BreadcrumbItem>>;
  public readonly stateClass$: Observable<string>;
  public readonly breadcrumbMode$: Observable<BreadcrumbMode>;

  private readonly _breadcrumb = new BehaviorSubject<Array<BreadcrumbItem>>([]);
  private readonly _stateClass = new BehaviorSubject<string>(undefined);
  private readonly _breadcrumbMode = new BehaviorSubject<BreadcrumbMode>(undefined);

  private _breadcrumbList: Array<BreadcrumbItem> = [];
  private _homeEntityUrl: string;
  private _viewMode: string;
  private _presetViewSelectedName: string;
  private _presetViewSelectedCode: string;
  private _dashboardItemList: Array<DashboardItem>
  private _subscriptions: Array<Subscription> = [];
  private _agreJourneySteps: Array<JourneyStep> = [];

  constructor(
    private _router: Router,
    private _dashboardHandler: DashboardHandlerService,
    private _cache: CacheService,
    private _detailViewHandler: DetailViewHandlerService,
    private _entity: EntityRestService,
    private _utils: UtilService,
    private _navHelper: NavigationHelperService,
    private _elementHelper: ElementHelperService,
    private _journeyHandler: JourneyHandlerService) {

    this.breadcrumb$ = this._breadcrumb.asObservable();
    this.stateClass$ = this._stateClass.asObservable();
    this.breadcrumbMode$ = this._breadcrumbMode.asObservable();
    this._init();
  }

  public clearItems() {
    this._cache.remove(CacheKeyEnum.Breadcrumb);
    this._breadcrumbList.length = 0;
  }

  public go(item: BreadcrumbItem, index: number) {
    this._breadcrumbList.splice(index + 1);
    this._breadcrumbList[this._breadcrumbList.length - 1].current = true;
    this._persist();

    if (item.dashboardItem) {
      this._dashboardHandler.setCurrrentDashboardItemByCode(item.dashboardItem.viewCode);
    }

    this._goTo(item.url);
    this.clearClass();
  }

  public goHome() {
    const lastDashboardCategory = this._cache.get(CacheKeyEnum.CurrentDashboardCategory);

    this._navHelper.goToDashboard(lastDashboardCategory);
  }

  public applyClass(className: string) {
    this._stateClass.next(className);
  }

  public clearClass() {
    this._stateClass.next(undefined);
  }

  public initForJourneyNavigation(data: Journey) {
    const breadcrumbContent = this._setBreadcrumbContent(data.title, data.code, 'Flow', null);
    this._addItem(breadcrumbContent, null, BreadcrumbItemType.Entity, null);
    this._breadcrumbMode.next(BreadcrumbMode.Journey);
  }

  public finishJourneyNavigation() {
    this._breadcrumbMode.next(BreadcrumbMode.Entity);
  }

  public addEntity(dashboardItem: DashboardItem, presetView: PresetViewDto) {
    let indexOf: number;
    const breadcrumbContent = this._setBreadcrumbContent(null, presetView.code, dashboardItem.name, 'Select');

    if ([AgGridEnum.BackgroundJobs, AgGridEnum.PendingRequest].includes(dashboardItem.gridSource)) {
      indexOf = -1;
      this._breadcrumbList.pop();
    }
    else {
      indexOf = this._getObjectIndexByType(BreadcrumbItemType.Entity);
    }

    if (indexOf !== -1) {
      this._updateEntity(breadcrumbContent, dashboardItem, indexOf + 1, presetView);
    } else {
      this._addItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Entity);
    }
  }

  public addInstance(name: string, code: string, dashboardItem: DashboardItem, journeyNavigationActive: boolean, url: string, sequenceId: number, parentInstanceCode: string, currentJourneyStep: JourneyStep) {
    if (dashboardItem.name === 'Flow' && this._viewMode !== 'Create') {
      sequenceId = 1;
    }

    if (journeyNavigationActive) {
      this._addInstanceDuringJourneyNavigation(code, dashboardItem, currentJourneyStep);
    } else {
      const breadcrumbContent = this._setBreadcrumbContent(name, code, dashboardItem.name, this._viewMode, sequenceId);
      if (this._getObjectIndexByType(BreadcrumbItemType.Entity) === -1) {
        this._createBreadcrumbFromRedirect(name, code, dashboardItem, url, sequenceId, parentInstanceCode);
      } else {
        if (this._getObjectIndexByType(BreadcrumbItemType.Instance) !== -1 && name == 'Create') {
          this._updateCurrentItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, url);
        } else {
          this._addItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, url);
        }
      }
    }
  }

  private _addInstanceDuringJourneyNavigation(code: string, dashboardItem: DashboardItem, currentJourneyStep: JourneyStep) {
    const breadcrumbContent = this._setBreadcrumbContent(currentJourneyStep.name, code, dashboardItem.name, null);
    this._breadcrumbList.forEach(b => b.current = false);

    if (currentJourneyStep) {
      if (this._isAgreNavigationStep(currentJourneyStep)) {
        let urlSection = 'workeffort/stage';
        const stageLibraryCode = this._utils.getLibraryCodeFromInstance(currentJourneyStep.parentInstanceCode);
        urlSection = `${urlSection}/${stageLibraryCode}`;

        this._breadcrumbList = this._breadcrumbList.filter(item => item.type === 0);
        this._entity.getDataEntity(urlSection, currentJourneyStep.parentInstanceCode)
          .subscribe(data => {
            const stageBreadcrumbContent = this._setBreadcrumbContent(data.instanceName, data.instanceCode, 'Stage', null);
            this._insertBreadcrumbitem(stageBreadcrumbContent, null, BreadcrumbItemType.Instance, null, false);
            this._setAgreNavigationStep(breadcrumbContent, dashboardItem);
            this._persist();
          });
      } else {
        this._setStageStepItems(currentJourneyStep.parentInstanceCode, breadcrumbContent);
      }
    }
  }

  private _setStageStepItems(parentStageInstanceCode: string, stepBreadcrumbContent: BreadcrumbContent) {
    let urlSection = 'workeffort/stage';
    const stageLibraryCode = this._utils.getLibraryCodeFromInstance(parentStageInstanceCode);
    urlSection = `${urlSection}/${stageLibraryCode}`;

    this._breadcrumbList = this._breadcrumbList.filter(item => item.type === 0);

    this._entity.getDataEntity(urlSection, parentStageInstanceCode)
      .subscribe(data => {
        stepBreadcrumbContent.instanceType = 'Step';
        const stageBreadcrumbContent = this._setBreadcrumbContent(data.instanceName, data.instanceCode, 'Stage', null);
        this._insertBreadcrumbitem(stageBreadcrumbContent, null, BreadcrumbItemType.Instance, null, false);
        this._insertBreadcrumbitem(stepBreadcrumbContent, null, BreadcrumbItemType.Instance, null, true);
        this._persist();
      });
  }

  private _setAgreNavigationStep(breadcrumbContent: BreadcrumbContent, dashboardItem: DashboardItem) {
    breadcrumbContent.isAgreNavigationStep = true;

    if (this._breadcrumbList.find(item => item.breadcrumbContent.isAgreNavigationStep)) {
      this._updateCurrentAgreStepItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, null);
    }
    else {
      this._addItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, null);
    }
  }

  private _isAgreNavigationStep(currentJourneyStep: JourneyStep) {
    return this._agreJourneySteps.find(step => currentJourneyStep && currentJourneyStep.code === step.code);
  }

  public updateInstance(detailName: string, detailCode: string, url: string, viewMode?: string) {
    const dashboardItem = this._breadcrumbList
      .find(bi => bi.type === BreadcrumbItemType.Entity).dashboardItem;

    const breadcrumbContent = this._setBreadcrumbContent(detailName, detailCode, dashboardItem.name, viewMode ? viewMode : this._viewMode);

    this._updateCurrentItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, url);
  }

  public getBreadcrumbMultiSelectConfig() {
    const items = this._getBreadcrumbFiltersItems();
    const attrs = { placeholder: '-- Select --', editable: true, required: true } as FieldAttributes;
    const config = this._elementHelper.getMultiSelectConfig(attrs, items);

    config.singleSelect = true;
    config.allowSearchFilter = false;
    config.validationIcons = undefined;
    config.name = 'breadcrumb-multiselect';

    return config;
  }

  public getBreadcrumbSelection() {
    return this._cache.get('breadcrumbSelection') as Array<number>;
  }

  public updateBreadcrumbSelection(selection: Array<number>) {
    this._cache.set('breadcrumbSelection', selection);
  }

  public updateOperation(viewMode?: string) {
    const hasInstanceToUpdate = this._getObjectIndexByType(BreadcrumbItemType.Instance) > -1;

    if (hasInstanceToUpdate && this._viewMode !== 'Create' && this._breadcrumbMode.value != BreadcrumbMode.Journey) {
      const currentItem = this._breadcrumbList.find(x => x.current);
      currentItem.breadcrumbContent.viewMode = viewMode ? viewMode : this._viewMode;
    }
  }

  public getVariables(viewCode: string, source: string, instanceCode?: string, instanceName?: string) {
    const commonUrl = `${NavigationUrlEnum.BaseFormUrl}/${viewCode}/${source}`;

    let breadcrumbName = viewCode.charAt(0).toUpperCase() + viewCode.slice(1);
    let url = commonUrl;

    if (instanceCode) {
      url = `${commonUrl}/${instanceCode}`;
      breadcrumbName = instanceName ? `${instanceName}` : `${instanceCode}`;
    } else {
      instanceCode = 'NEW'
    }

    return {
      code: instanceCode,
      name: breadcrumbName,
      url: url
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _init() {
    const cached = this._cache.get(CacheKeyEnum.Breadcrumb) as BreadcrumbConfig;

    if (cached && cached.items) {
      this._breadcrumbList = cached.items;
      this._homeEntityUrl = cached.homeUrl;
      this._breadcrumb.next(this._breadcrumbList);
    }

    this._registerSubscriptions();
  }

  private _registerSubscriptions() {
    this._subscriptions.push(this._detailViewHandler.mode$
      .subscribe(mode => {
        this._viewMode = `${mode.charAt(0)}${mode.slice(1).toLocaleLowerCase()}`;
        this.updateOperation();
      }));

    this._subscriptions.push(this._dashboardHandler.dashboardItemList$
      .subscribe(dashboardItemList => {
        this._dashboardItemList = dashboardItemList;
      }));

    this._subscriptions.push(this._journeyHandler.journeyNavigationFormSteps$
      .pipe(filter(steps => steps != undefined))
      .subscribe(steps => {
        this._agreJourneySteps = steps;
      }));
  }

  private _persist() {
    const config = {
      homeUrl: this._homeEntityUrl,
      items: this._breadcrumbList
    } as BreadcrumbConfig;

    this._breadcrumb.next(config.items);
    this._cache.set(CacheKeyEnum.Breadcrumb, config);
  }

  private _addItem(breadcrumbContent: BreadcrumbContent, dashboardItem: DashboardItem, breadcrumbItemType?: BreadcrumbItemType, url?: string) {
    const currentItem = this._breadcrumbList.filter(b => b.current)[0];

    if (!url) {
      url = this._router.url;
    }

    if (!currentItem || currentItem.url !== url) {
      const indexOf = this._breadcrumbList.findIndex(bi => bi.url === url);

      this._breadcrumbList.forEach(b => b.current = false);

      if (indexOf == -1) {
        this._insertBreadcrumbitem(breadcrumbContent, url, breadcrumbItemType, dashboardItem, true);
      } else {
        this._updateBreadcrumbIndex(indexOf);
      }

      this._persist();
    }
  }

  private _updateCurrentItem(breadcrumbContent: BreadcrumbContent, dashboardItem: DashboardItem, breadcrumbItemType: BreadcrumbItemType, url?: string) {
    const currentObject = this._breadcrumbList.find(bi => bi.type == BreadcrumbItemType.Instance);

    if (!url) {
      url = this._router.url;
    }

    if (this._breadcrumbMode.value != BreadcrumbMode.Journey) {
      breadcrumbContent.viewMode = breadcrumbContent.viewMode === FormOperationType.Identify ? 'Identify' : 'View';
    }

    currentObject.dashboardItem = dashboardItem;
    currentObject.type = breadcrumbItemType;
    currentObject.url = url;
    currentObject.breadcrumbContent = breadcrumbContent;

    this._persist();
  }

  private _updateCurrentAgreStepItem(breadcrumbContent: BreadcrumbContent, dashboardItem: DashboardItem, breadcrumbItemType: BreadcrumbItemType, url?: string) {
    const currentAgreStep = this._breadcrumbList.find(bi => bi.breadcrumbContent.isAgreNavigationStep);

    if (!url) {
      url = this._router.url;
    }

    currentAgreStep.dashboardItem = dashboardItem;
    currentAgreStep.type = breadcrumbItemType;
    currentAgreStep.url = url;
    currentAgreStep.breadcrumbContent = breadcrumbContent;

    this._persist();
  }

  private _insertBreadcrumbitem(bcContent: BreadcrumbContent, url: string, bcItemType: BreadcrumbItemType, dashboardItem: DashboardItem, current: boolean) {
    const breadcrumbItemToInsert = {
      dashboardItem: dashboardItem,
      url: url,
      type: bcItemType,
      current: current,
      breadcrumbContent: bcContent
    } as BreadcrumbItem;

    this._breadcrumbList.push(breadcrumbItemToInsert);
  }

  private _setBreadcrumbContent(name: string, code: string, type: string, viewMode: string, sequenceId?: number) {
    return {
      instanceName: name,
      instanceCode: code,
      instanceType: type,
      sequenceId: sequenceId,
      viewMode: viewMode
    } as BreadcrumbContent;
  }

  private _createBreadcrumbFromRedirect(name: string, code: string, dashboardItem: DashboardItem, url: string, sequenceId: number, parentInstanceCode: string) {
    const entityUrl = `${NavigationUrlEnum.BaseGridUrl}/${dashboardItem.viewCode}/${dashboardItem.gridSource}`;
    const defaultSequenceForFlow = 1;
    this.clearItems();

    if (dashboardItem.name === 'Stage' || dashboardItem.name === 'Step') {
      this._setFlowBreadcrumb(dashboardItem, parentInstanceCode, name, code, url, sequenceId);
    } else {
      sequenceId = dashboardItem.name === 'Flow' ? defaultSequenceForFlow : sequenceId;
      const breadcrumbContent1 = this._setBreadcrumbContent(this._presetViewSelectedName, this._presetViewSelectedCode, dashboardItem.name, 'Select');
      const breadcrumbContent2 = this._setBreadcrumbContent(name, code, dashboardItem.name, this._viewMode, sequenceId);

      this._insertBreadcrumbitem(breadcrumbContent1, entityUrl, BreadcrumbItemType.Entity, dashboardItem, false);
      this._insertBreadcrumbitem(breadcrumbContent2, url, BreadcrumbItemType.Instance, dashboardItem, true);
    }

    this._persist();
  }

  private _setFlowBreadcrumb(dashboardItem: DashboardItem, parentInstanceCode: string, name: string, code: string, url: string, sequenceId: number) {
    const flowDashboardItem = this._dashboardItemList.find(di => di.name === 'Flow');
    const stageDashboardItem = this._dashboardItemList.find(di => di.name === 'Stage');
    const flowEntityUrl = `${NavigationUrlEnum.BaseGridUrl}/${flowDashboardItem.viewCode}/${flowDashboardItem.gridSource}`;
    const breadcrumbContent = this._setBreadcrumbContent(this._presetViewSelectedName, this._presetViewSelectedCode, flowDashboardItem.name, 'Select');

    this._insertBreadcrumbitem(breadcrumbContent, flowEntityUrl, BreadcrumbItemType.Entity, flowDashboardItem, false);

    if (dashboardItem.name === 'Step' && parentInstanceCode) {
      let urlSection = stageDashboardItem.overrideViewCode || stageDashboardItem.viewCode;
      let type = this._utils.getLibraryCodeFromInstance(parentInstanceCode);
      urlSection = `${urlSection}/${type}`;

      this._entity.getDataEntity(urlSection, parentInstanceCode)
        .subscribe(data => {
          const breadcrumbContent3 = this._setBreadcrumbContent(data.instanceName, data.instanceCode, stageDashboardItem.name, 'View', data.sequenceId);
          const stageViewUrl = `${NavigationUrlEnum.BaseGridUrl}/${stageDashboardItem.viewCode}/${stageDashboardItem.formSource}/${data.instanceCode}`;

          urlSection = flowDashboardItem.overrideViewCode || flowDashboardItem.viewCode;
          type = this._utils.getLibraryCodeFromInstance(data.parentInstanceCode);
          urlSection = `${urlSection}/${type}`;

          this._entity.getDataEntity(urlSection, data.parentInstanceCode)
            .subscribe(data => {
              const flowViewUrl = `${NavigationUrlEnum.BaseGridUrl}/${flowDashboardItem.viewCode}/${flowDashboardItem.formSource}/${data.instanceCode}`;
              const breadcrumbContent2 = this._setBreadcrumbContent(data.instanceName, data.instanceCode, flowDashboardItem.name, 'View', 1);
              const breadcrumbContent4 = this._setBreadcrumbContent(name, code, dashboardItem.name, 'View', sequenceId);

              this._insertBreadcrumbitem(breadcrumbContent2, flowViewUrl, BreadcrumbItemType.Instance, flowDashboardItem, false);
              this._insertBreadcrumbitem(breadcrumbContent3, stageViewUrl, BreadcrumbItemType.Instance, flowDashboardItem, false);
              this._insertBreadcrumbitem(breadcrumbContent4, url, BreadcrumbItemType.Instance, dashboardItem, true);
            });
        });
    }
  }

  private _updateBreadcrumbIndex(index: number) {
    this._breadcrumbList.splice(index + 1);
    this._breadcrumbList[index].current = true;
  }

  private _updateEntity(breadcrumbContent: BreadcrumbContent, dashboardItem: DashboardItem, index: number, presetView: PresetViewDto) {
    this._breadcrumbList.splice(index);
    const currentEntity = this._breadcrumbList[index - 1];
    const indexOfViewCode = currentEntity.url.lastIndexOf("/") + 1;
    const url = currentEntity.url.replace(currentEntity.url.substring(indexOfViewCode), presetView.viewCode);
    dashboardItem.presetView = presetView.viewCode;

    currentEntity.dashboardItem = dashboardItem;
    currentEntity.url = url;
    currentEntity.current = true;
    currentEntity.breadcrumbContent = breadcrumbContent;
  }

  private _getObjectIndexByType(type: BreadcrumbItemType) {
    return this._breadcrumbList.findIndex(element => element.type === type);
  }

  private _goTo(url: string) {
    this._router.navigateByUrl(url);
  }

  private _getBreadcrumbFiltersItems() {
    return [
      { code: 1, description: 'Instance Name' },
      { code: 2, description: 'Instance ID' }];
  }
}
