<!-- Fixed navbar -->
<nav class="navbar navbar-default navbar-fixed-top" *ngIf="model.authenticated">
	<div>

		<div class="navbar-header header-height">
			<img src="./assets/img/emerios.png" class="logo" (click)="goHome()">

			<a href="javascript:void(0)" class="user-profile collapsed pull-right mobile-hide" data-toggle="collapse"
				data-target="#navbar">
				<span class="fa-stack fa-2x icon-notification" *ngIf="model.pendingRequests > 0">
					<i class="fas fa-circle fa-stack-2x text-danger"></i>
					<i class="fas fa-bell fa-stack-1x fa-inverse bell-mov"></i>
				</span>
				<span [attr.data-letters]="model.initials">
					<span class="user-name"></span>
				</span>
				<span class="fa fa-angle-down pl-2"></span>
			</a>
		</div>

		<!-- Desktop -->
		<div id="navbar" #navBar class="navbar-collapse collapse in menu-header-selector" *ngIf="!model.isMobile">
			<ul class="nav navbar-nav navbar-right">
				<li class="">
					<a href="javascript:void(0)" class="user-profile dropdown-toggle" data-toggle="dropdown">
						<span class="fa-stack fa-2x icon-notification" *ngIf="model.pendingRequests > 0">
							<i class="fas fa-circle fa-stack-2x text-danger"></i>
							<i class="fas fa-bell fa-stack-1x fa-inverse bell-mov"></i>
						</span>
						<span [attr.data-letters]="model.initials">{{model.displayName || 'Menu'}} </span>
						<ng-container *ngIf="model.workingOrganization">
							<span> @ {{model.workingOrganization.instanceName}}</span>
						</ng-container>
						<span class="fa fa-angle-down pl-2"></span>
					</a>
					<ul class="dropdown-menu dropdown-usermenu pull-right">
						<ng-container *ngTemplateOutlet="items"></ng-container>
					</ul>
				</li>
			</ul>
		</div>

		<!-- Mobile -->
		<div id="navbar" #navBar class="navbar-collapse collapse mobile-selector" aria-expanded="false"
			style="height: 1px;" *ngIf="model.isMobile">
			<ul class="navbar-nav nav">
				<li class="dropdown-header">Notifications</li>
				<ng-container *ngTemplateOutlet="items"></ng-container>
			</ul>
		</div>

	</div>
</nav>

<ng-template #items>
	<ng-container *ngIf="!model.showOnlyLogout">
		<ng-template appAuthorize="Pending Request,View">
			<li>
				<a href="javascript:void(0)" (click)="goToPendingRequests()">
					<span class="badge notification pull-right pt-1">{{model.pendingRequests}}</span>
					<span> Pending Request</span>
				</a>
			</li> 
			<li class="nav-divider"></li>
		</ng-template>
		<ng-template appAuthorize="Background Tasks,View">
			<li>
			<a href="javascript:void(0)" (click)="goToBackgroundTasks()">
				<span> Background Tasks</span>
			</a>
			</li>
			<li class="nav-divider"></li>
		</ng-template>
		<ng-template appAuthorize="Switch Working Organization,View">
			<li>
				<a href="javascript:void(0)" (click)="switchWorkingOrganization()">
					<span> Switch Working Organization</span>
				</a>
			</li>
			<li class="nav-divider"></li>
		</ng-template>
	</ng-container>

	<li>
		<a href="javascript:void(0)" (click)="logout()">
			<i class="fas fa-sign-out-alt pull-right pt-1"></i>
			<span> Log Out</span>
		</a>
	</li>
</ng-template>