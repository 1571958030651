import { Component, OnInit, Input} from '@angular/core';
import * as moment from 'moment-mini';

const SIGNATURE_PREFIX = "data:image/png;base64,";

@Component({
  selector: 'app-signature-viewer',
  templateUrl: './signature-viewer.component.html',
  styleUrls: ['./signature-viewer.component.css']
})
export class SignatureViewerComponent implements OnInit {

  @Input("user-mame")
  public userName: string;
  @Input("step-name")
  public stepName: string;
  @Input("step-type")
  public stepType: string;
  @Input()
  public icon: number;
  @Input()
  public signature: string;
  @Input("datetime")
  public dateTime: Date;

  public signatureUrl: string;
  public text: string;
  public date: string;
  public hour: string;
   
  ngOnInit(): void {
    this.signatureUrl = SIGNATURE_PREFIX + this.signature;
    if(this.stepType == 'FLW.REQ') {
      this.text = "Submit Request" + " - " + this.userName;
    } else {
      this.text = this.stepName + " - " + this.userName;
    }
    this.date =  moment(this.dateTime).format('MM/DD/YYYY')
    this.hour =  moment(this.dateTime).format('HH:mm:ss')
  }
}
