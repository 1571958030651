<div class="modal-header">
  <h3 class="modal-title pull-left" [innerHtml]="params.title"></h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body campaign-modal" *ngIf="config">
  <div class="row">
    <div class="col-sm-5 col-sm-offset-1">
      <label class="app-input has-label"> Valid From
        <emerios-datepicker [config]="config.dateFrom" [(ngModel)]="model.dateFrom" (is-valid)="isElementValid($event)">
        </emerios-datepicker>
      </label>
    </div>

    <div class="col-sm-5">
      <label class="app-input has-label"> Valid Until
        <emerios-datepicker [config]="config.dateTo" [(ngModel)]="model.dateTo" (is-valid)="isElementValid($event)">
        </emerios-datepicker> </label>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-10 col-sm-offset-1">
      <label class="app-input has-label"> In the role of
        <emerios-select [config]="config.whoIam" [(ngModel)]="model.whoIam" [forceValidation]="forceValidation"
          (ngModelChange)="onWhoIamChanged($event)" (is-valid)="isElementValid($event)">
        </emerios-select>
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-10 col-sm-offset-1">
      <label class="app-input has-label"> Relationship Type
        <emerios-select [config]="config.relationshipType" [(ngModel)]="model.relationshipType"
          [forceValidation]="forceValidation" (ngModelChange)="onRelationshipTypeChanged($event)"
          (is-valid)="isElementValid($event)"></emerios-select>
      </label>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-10 col-sm-offset-1">
      <label class="app-input has-label"> With
        <emerios-select [config]="config.withWhom" [(ngModel)]="model.withWhom" [forceValidation]="forceValidation"
          (ngModelChange)="onWithWhomChanged($event)" (is-valid)="isElementValid($event)">
        </emerios-select>
      </label>
    </div>
  </div>

  <div class="alert alert-warning" role="alert" *ngIf="showWarning">This relationship already exists.</div>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-next" (click)="closeModal()">Cancel</button>
  <button type="button" class="btn btn-primary btn-fill" (click)="okModal()"
    [disabled]="!isFormValid || showWarning">Create</button>
</div>