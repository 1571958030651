import { Injectable } from '@angular/core';
import { BaseDataModel } from '../base-data-model.model';
import { InputLangDataModelService } from '../input-lang-data-model/input-lang-data-model.service';
import { DataModelFactoryService } from '../data-model-factory.service';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';

@Injectable({
  providedIn: 'root'
})
export class SortableListDataModelService implements BaseDataModel {

  constructor() { }

  public setValueToField(payloadValue: any) {
    return payloadValue;
  }
  public getValueFromField(fieldValue: any) {
    if (fieldValue) {
      fieldValue.forEach(x => {
        // const catalogSingleElements = x.elementList.filter(e => e.type === 'catalog-single').map(y => y.field);
        x.elementList.forEach(e => {
          if (e.type === 'catalog-single') {
            x[e.field] = x[e.field][0];
          } else if (e.type === 'textarea-lang') {
            x[e.field] = this._getValue(x[e.field], e);
          }
        });
        delete x.elementList;
      });

      return fieldValue;
    }
  }

  private _getValue(fieldValue: any, element: any) {
    const value = [];

    if (fieldValue) {
      Object.keys(fieldValue)
        .forEach(langCode => {
          const translation = {};
          translation[element.translationPropCode] = langCode;
          translation[element.translationPropText] = fieldValue[langCode];
          value.push(translation);
        });
    }

    return value;
  }
}
