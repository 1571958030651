import { AgGridBaseModel } from './aggrid/aggrid-base.model';

export enum ChangeRequestStatus {
    DRAFT = 'DRAFT',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    CANCELED = 'CANCELED',
    LINKED_CHANGE_REQUEST = 'LINKED_CHANGE_REQUEST',
    UNLINKED_CHANGE_REQUEST = 'UNLINKED_CHANGE_REQUEST',
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    DELETED = 'DELETED',
    PENDING_DEPLOY_TEST = 'PENDING_DEPLOY_TEST',
    PENDING_DEPLOY_UAT = 'PENDING_DEPLOY_UAT',
    PENDING_DEPLOY_PROD = 'PENDING_DEPLOY_PROD',
    SUCCESFULLY_DEPLOY_TEST = 'SUCCESFULLY_DEPLOY_TEST',
    SUCCESFULLY_DEPLOY_UAT = 'SUCCESFULLY_DEPLOY_UAT',
    SUCCESFULLY_DEPLOY_PROD = 'SUCCESFULLY_DEPLOY_PROD',
    FAILED_DEPLOY_TEST = 'FAILED_DEPLOY_TEST',
    FAILED_DEPLOY_UAT = 'FAILED_DEPLOY_UAT',
    FAILED_DEPLOY_PROD = 'FAILED_DEPLOY_PROD',
    CONFIGURATION_ENVIRONMENT = 'CONFIGURATION_ENVIRONMENT',
    SCHEDULED = 'SCHEDULED',

    // to use with startsWith
    PENDING_DEPLOY = 'PENDING_DEPLOY',
    SUCCESFULLY_DEPLOY = 'SUCCESFULLY_DEPLOY',
    FAILED_DEPLOY = 'FAILED_DEPLOY'
}

export interface AgGridModel {
    gridColumnDefinition?: Array<GridColumnDef>;
    columnDefinition?: Array<GridColumnDef>;
    autoGroupColumnDefinition?: Array<any>;
    dataPath?: string;

    metadata?: AgGridModelMetadata;
    rows: Array<AgGridBaseModel>;
}


export interface AgGridModelMetadata {
    columnDefinition: any;
}

export interface GridColumnDef {
    children?: Array<any>;
    filterValueGetter?: unknown;
    valueGetter?: unknown;
    cellRenderer?: Function | string;
}
