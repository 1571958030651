import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class DetailViewHandlerService {
    constructor() {
        this._mode = new BehaviorSubject(undefined);
        this._formViewSelected = new BehaviorSubject(undefined);
        this.mode$ = this._mode.asObservable().pipe(filter(x => x !== undefined));
        this.formViewSelected$ = this._formViewSelected.asObservable().pipe(filter(x => x !== undefined));
    }
    setMode(mode) {
        this._mode.next(mode);
    }
    setFormView(form) {
        this._formViewSelected.next(form);
    }
}
DetailViewHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DetailViewHandlerService_Factory() { return new DetailViewHandlerService(); }, token: DetailViewHandlerService, providedIn: "root" });
