import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subscription, combineLatest } from 'rxjs';
import { ValidationIconClass, ValidationMessages, SelectConfig, DatepickerConfig } from 'ngx-emerios-all';
import { EntityPartyRoleRelationship } from 'src/app/models/entity.model';
import { distinctBy } from 'src/app/functions/distinctBy';
import { ValidatorFn, Validators } from '@angular/forms';
import { ElementResponse } from 'ngx-emerios-all/lib/components/_models/elements-models';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { EntityCatalog, EntityCatalogItem } from 'src/app/models/catalog.models';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { AgGridBaseModel } from 'src/app/models/aggrid/aggrid-base.model';
import { RelationshipScope } from 'src/app/models/form-field-definition.models';
import * as moment from 'moment-mini';

export interface ModalParams {
  title: string;
  roles: Array<EntityCatalogItem>;
  currentRoles: Array<any>;
  currentPartyInstanceCode: string;
  currentRelationships: Array<AgGridBaseModel>;
  scope: RelationshipScope;
  callback: Function;
}

@Component({
  selector: 'app-create-party-relationship',
  templateUrl: './create-party-relationship.component.html',
  styleUrls: ['./create-party-relationship.component.sass']
})
export class CreatePartyRelationshipComponent implements OnInit, OnDestroy {
  public params = {} as ModalParams;
  public model: any = {};

  public config: any;

  public showWarning: boolean;
  public isFormValid: boolean;
  public forceValidation: EventEmitter<any> = new EventEmitter();

  private _elementsValidity: any = {};
  private _partyRelationshipTypesCatalog: EntityCatalog;
  private _partyInstanceCatalog: EntityCatalog;
  private _subscriptions: Array<Subscription> = [];

  private _baseConfig: SelectConfig;
  private _validationIcons = {} as ValidationIconClass;
  private _errorMsgClass = 'input-error';
  private _formatDate = 'MM-DD-YYYY';

  constructor(
    private _dashboardHandler: DashboardHandlerService,
    private _catalog: CatalogService,
    private _modal: BsModalRef) {
    this._validationIcons.pristine = 'fas fa-asterisk text-muted';
    this._validationIcons.success = 'fas fa-check text-success';
    this._validationIcons.error = 'fas fa-times text-danger';
  }

  public okModal() {
    const relationship = {
      partyRoleFrom: {
        code: this.model.relationshipType.partyRoleFromCode,
        partyInstanceCode: undefined
      },
      partyRoleTo: {
        code: this.model.relationshipType.partyRoleToCode,
        partyInstanceCode: undefined,
      },
      dateTimeFrom: this.model.dateFrom,
      dateTimeThru: this.model.dateTo
    } as EntityPartyRoleRelationship;

    if (this.model.withWhom.roles.includes(this.model.relationshipType.partyRoleFromCode)) {
      relationship.partyRoleFrom.partyInstanceCode = this.model.withWhom.code;
      relationship.partyRoleTo.partyInstanceCode = this.params.currentPartyInstanceCode;
    } else {
      relationship.partyRoleFrom.partyInstanceCode = this.params.currentPartyInstanceCode;
      relationship.partyRoleTo.partyInstanceCode = this.model.withWhom.code;
    }

    this._modal.hide();
    if (this.params.callback) {
      this.params.callback(relationship);
    }
  }

  public closeModal() {
    this._modal.hide();
  }

  public onWhoIamChanged(event: any) {
    // we need to clone the config object so the component can detect the change
    const relationshipTypeConfig = Object.assign({}, this.config.relationshipType);
    const propToFilter = this.params.scope === RelationshipScope.Parent
      ? 'partyRoleToCode' : 'partyRoleFromCode';
    const items = this._partyRelationshipTypesCatalog.items
      .filter(i => i[propToFilter] == event.code);

    relationshipTypeConfig.items = distinctBy(items, 'code');

    this.config.relationshipType = relationshipTypeConfig;
    this.model.relationshipType = null;
    this._elementsValidity['relationshipType'] = false;
  }

  public onRelationshipTypeChanged(event: any) {
    // we need to clone the config object so the component can detect the change
    const withWhomConfig = Object.assign({}, this.config.withWhom);
    const itemsFrom = this._partyInstanceCatalog.items
      .filter(i => i.roles.includes(this.model.relationshipType.partyRoleFromCode));
    const itemsTo = this._partyInstanceCatalog.items
      .filter(i => i.roles.includes(this.model.relationshipType.partyRoleToCode));

    if (this.model.whoIam.code == this.model.relationshipType.partyRoleFromCode) {
      withWhomConfig.items = itemsTo;
    } else {
      withWhomConfig.items = itemsFrom;
    }

    this.config.withWhom = withWhomConfig;
    // this.forceValidation.emit(true);
    this.isElementValid({
      name: 'withWhom',
      value: undefined,
      valid: false
    } as ElementResponse);
    this.model.withWhom = null;

  }

  public onWithWhomChanged(event: any) {
    this.showWarning = false;
    const relations = this.params.currentRelationships.map(r => {
      return {
        partyInstanceCodeFrom: r.partyInstanceCodeFrom,
        partyInstanceCodeTo: r.partyInstanceCodeTo
      }
    });

    if (this.model.withWhom) {
      relations.forEach(r => {
        this.showWarning =
          (this.params.currentPartyInstanceCode == r.partyInstanceCodeFrom &&
            this.model.withWhom.code == r.partyInstanceCodeTo) ||
          (this.params.currentPartyInstanceCode == r.partyInstanceCodeTo &&
            this.model.withWhom.code == r.partyInstanceCodeFrom);
      });
    }
  }

  public isElementValid(event: ElementResponse) {
    const that = this;
    this._elementsValidity[event.name] = event.valid;
    const required = Object['keys'](this.config).filter(x => that.config[x].validators && that.config[x].validators.filter(y => y.code == 'required')).length;
    let validity = Object["keys"](this._elementsValidity).length == required;

    validity = Object['values'](this._elementsValidity).every((valid: boolean) => valid);

    this.isFormValid = validity;
  }

  ngOnInit() {
    this._subscriptions.push(
      combineLatest(
        this._dashboardHandler.currentDashboardItem$,
        this._catalog.getFromServer([CatalogEnum.PartyRelationshipType, CatalogEnum.PartyInstance]))
        .subscribe(results => {
          this._partyRelationshipTypesCatalog = results[1].find(x => x.code == CatalogEnum.PartyRelationshipType);
          this._partyInstanceCatalog = results[1].find(x => x.code == CatalogEnum.PartyInstance);

          this._initializeDefinitions();
        }));

    this.model.dateFrom = moment().format(this._formatDate);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _initializeDefinitions() {
    this._baseConfig = {} as SelectConfig;

    this._baseConfig.defaultTextItem = '-- Select --';
    this._baseConfig.cssClasses = 'form-control';

    this._baseConfig.validationIcons = this._validationIcons;

    this._baseConfig.errorMsgClass = this._errorMsgClass;

    this._baseConfig.validators = new Array<ValidatorFn>();
    this._baseConfig.validationMessages = {} as ValidationMessages;

    this._baseConfig.validators.push(Validators.required);
    this._baseConfig.validationMessages.required = 'This is required';

    this._baseConfig.items = new Array<any>();

    this._baseConfig.itemCode = 'code';
    this._baseConfig.itemText = 'description';

    this.config = {};

    this._configDatepickers();
    this._configWhoIam();
    this._configRelationshipType();
    this._configWithWhom();
  }

  private _configDatepickers() {
    let newConfig = {} as DatepickerConfig;

    newConfig.cssClasses = 'form-control';
    newConfig.validationIcons = this._validationIcons;

    newConfig.errorMsgClass = this._errorMsgClass;

    newConfig.bsConfig = { dateInputFormat: this._formatDate, containerClass: 'theme-dark-blue' }

    const dateFrom = Object.assign({}, newConfig);
    const dateTo = Object.assign({}, newConfig);

    dateFrom.validators = new Array<ValidatorFn>();
    dateFrom.validationMessages = {} as ValidationMessages;

    dateFrom.validators.push(Validators.required);
    dateFrom.validationMessages.required = 'This is required';

    dateFrom.name = 'dateFrom';
    dateTo.name = 'dateTo';

    this.config.dateFrom = dateFrom
    this.config.dateTo = dateTo;
  }

  private _configWhoIam() {
    const config = Object.assign({}, this._baseConfig);

    config.name = 'whoIam';
    config.items = this.params.roles.filter(r => this.params.currentRoles.includes(r.code));

    this.config.whoIam = config;

    if (config.items.length == 1) {
      this.model.whoIam = config.items[0];
    }
  }

  private _configRelationshipType() {
    const config = Object.assign({}, this._baseConfig);

    config.name = 'relationshipType';

    this.config.relationshipType = config;

    if (this.config.whoIam.items.length == 1) {
      this.model.whoIam = this.config.whoIam.items[0];
    }
  }

  private _configWithWhom() {
    const config = Object.assign({}, this._baseConfig);

    config.name = 'withWhom';

    this.config.withWhom = config;
  }

}
