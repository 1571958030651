import { Component, OnInit, Input, forwardRef} from '@angular/core';
import { RequestSignatureDto } from 'src/app/models/signature-models';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const CUSTOM_SIGNATURE_VIEWER_GROUP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SignatureViewerGroupComponent),
  multi: true
};

@Component({
  selector: 'app-signature-viewer-group',
  templateUrl: './signature-viewer-group.component.html',
  styleUrls: ['./signature-viewer-group.component.css'],
  providers: [CUSTOM_SIGNATURE_VIEWER_GROUP_VALUE_ACCESSOR]
})
export class SignatureViewerGroupComponent implements OnInit, ControlValueAccessor {

  @Input()
  public formControlName: string;
  public signatures: Array<RequestSignatureDto>;

  ngOnInit(): void {
  }

  writeValue(obj: any): void {
    this.signatures = obj;
}

  registerOnChange(fn: any): void {
    // Do Nothing because is readonly
  }

  registerOnTouched(fn: any): void {
    // Do Nothing because is readonly
  }

  setDisabledState?(isDisabled: boolean): void {
    // Do Nothing because is readonly
  }
}
