import { ModalService } from '../modal/modal.service';
import { MessageModalComponent } from 'src/app/modals/message-modal/message-modal.component';
import { Router } from '@angular/router';
import { CacheService } from 'ngx-emerios-all';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
import * as i2 from "../modal/modal.service";
import * as i3 from "@angular/router";
export class MessageService {
    constructor(_cache, _modal, _router) {
        this._cache = _cache;
        this._modal = _modal;
        this._router = _router;
    }
    handle(error, avoidPageRedirect) {
        const contactSupport = 'Please contact support if the error persists.';
        let message = error.message && error.message;
        if (!message) {
            message = contactSupport;
        }
        if (typeof (message) == 'object' && message.length > 0) {
            message = message.find(x => x.language == 'en').text;
        }
        else {
            message = contactSupport;
        }
        switch (error.type) {
            case 'BusinessException':
                this.showError(message);
                break;
            case 'DeployException':
                this.showErrorAnActionOnOk(message, () => {
                    if (!document.getElementById('btn_journey_deploy')) {
                        this._refresh();
                    }
                });
                break;
            case 'Unhandled':
            default:
                if (!avoidPageRedirect) {
                    this._showUnhandledExceptionPage(message);
                }
                break;
        }
    }
    showError(message, title, items) {
        const params = {
            title: title || 'An error has occurred',
            message: message,
            items: items,
            type: 'error',
            okButton: {
                text: 'Close'
            }
        };
        this._modal.showCustom(MessageModalComponent, params);
    }
    showErrorAnActionOnOk(message, okCallback, title) {
        const params = {
            title: title || 'An error has occurred',
            message: message,
            type: 'error',
            okButton: {
                text: 'Close',
                callback: okCallback,
            }
        };
        this._modal.showCustom(MessageModalComponent, params);
    }
    showPageErrorMessage(message) {
        this._goToPage({ type: 'error', message: message });
    }
    showPageInfoMessage(title, message) {
        this._goToPage({ type: 'info', title: title, message: message });
    }
    showPageMessage(params) {
        this._goToPage(params);
    }
    _showUnhandledExceptionPage(message) {
        this._goToPage({ type: 'error', message: `An unhandled error has occurred. ${message}` });
    }
    _goToPage(params) {
        if (!params.title) {
            params.title = 'We are sorry :(';
        }
        this._cache.set('message-page-payload', params);
        this._router.navigate([`msg/${params.type}`]);
    }
    _refresh() {
        window.location.reload();
    }
}
MessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageService_Factory() { return new MessageService(i0.ɵɵinject(i1.CacheService), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.Router)); }, token: MessageService, providedIn: "root" });
