/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-form-preview.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/dynamic-form-group-container/dynamic-form-group-container.component.ngfactory";
import * as i3 from "../../components/dynamic-form-group-container/dynamic-form-group-container.component";
import * as i4 from "../../services/behavior/catalog/catalog.service";
import * as i5 from "../../services/helper/data-model-helper/data-model-helper.service";
import * as i6 from "../../services/handler/field-value-handler/field-value-handler.service";
import * as i7 from "../../services/handler/dashboard-handler/dashboard-handler.service";
import * as i8 from "../../services/handler/detail-view-handler/detail-view-handler.service";
import * as i9 from "./dynamic-form-preview.component";
import * as i10 from "ngx-bootstrap/modal";
var styles_DynamicFormPreviewComponent = [i0.styles];
var RenderType_DynamicFormPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicFormPreviewComponent, data: {} });
export { RenderType_DynamicFormPreviewComponent as RenderType_DynamicFormPreviewComponent };
export function View_DynamicFormPreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "h3", [["class", "modal-title pull-left"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body campaign-modal campaign-builder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-dynamic-form-group-container", [], null, null, null, i2.View_DynamicFormGroupContainerComponent_0, i2.RenderType_DynamicFormGroupContainerComponent)), i1.ɵdid(8, 770048, null, 0, i3.DynamicFormGroupContainerComponent, [i4.CatalogService, i5.DataModelHelperService, i6.FieldValueHandlerService, i7.DashboardHandlerService, i8.DetailViewHandlerService], { groups: [0, "groups"], entityName: [1, "entityName"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-next"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.model.groupFields; var currVal_2 = "test 2"; _ck(_v, 8, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.title; _ck(_v, 1, 0, currVal_0); }); }
export function View_DynamicFormPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dynamic-form-preview", [], null, null, null, View_DynamicFormPreviewComponent_0, RenderType_DynamicFormPreviewComponent)), i1.ɵdid(1, 114688, null, 0, i9.DynamicFormPreviewComponent, [i10.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicFormPreviewComponentNgFactory = i1.ɵccf("app-dynamic-form-preview", i9.DynamicFormPreviewComponent, View_DynamicFormPreviewComponent_Host_0, {}, {}, []);
export { DynamicFormPreviewComponentNgFactory as DynamicFormPreviewComponentNgFactory };
