import { Directive, Self, OnInit, ViewContainerRef, ComponentFactoryResolver, ComponentFactory, ComponentRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { TextareaExpanderComponent } from 'src/app/components/textarea-expander/textarea-expander.component';

@Directive({
  selector: '[appExpandLongText]'
})
export class ExpandLongTextDirective implements OnInit {

  constructor(
    @Self() private _control: NgControl,
    private _resolver: ComponentFactoryResolver,
    private _container: ViewContainerRef) { }

  ngOnInit() {
    const factory: ComponentFactory<TextareaExpanderComponent> = this._resolver.resolveComponentFactory(TextareaExpanderComponent);
    const textExpanderComponent: ComponentRef<TextareaExpanderComponent> = this._container.createComponent(factory);

    textExpanderComponent.instance.setExternalControl(this._control.control);
  }
}
