import { Validators } from '@angular/forms';
import { CustomFormValidatorService } from '../../behavior/custom-form-validator/custom-form-validator.service';
import { EntityHandlerService } from '../../handler/entity-handler/entity-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../../handler/entity-handler/entity-handler.service";
export class ElementHelperService {
    constructor(_entityHandler) {
        this._entityHandler = _entityHandler;
    }
    getElementConfigurations(fields) {
        const elementConfigurations = {};
        fields.forEach(element => {
            switch (element.type) {
                case 'input-text':
                case 'input-date':
                case 'datetime-picker':
                    elementConfigurations[element.field] = this.getInputConfig(element.attributes);
                    break;
                case 'textarea':
                case 'textarea-form':
                    elementConfigurations[element.field] = this.getTextareaConfig(element.attributes);
                    break;
                case 'input-lang':
                    elementConfigurations[element.field] = this.getInputLangConfig(element.attributes);
                    elementConfigurations[element.field].items = element.catalogItems;
                    break;
                case 'textarea-lang':
                    elementConfigurations[element.field] = this.getTextareaLangConfig(element.attributes);
                    elementConfigurations[element.field].items = element.catalogItems;
                    break;
                case 'catalog-single':
                    elementConfigurations[element.field] = this._getMultiSelectConfig(element.attributes, element.catalogCodeProperty, element.catalogItems);
                    elementConfigurations[element.field].singleSelect = true;
                    break;
                case 'catalog-multiple':
                    elementConfigurations[element.field] = this._getMultiSelectConfig(element.attributes, element.catalogCodeProperty, element.catalogItems);
                    break;
                default:
                    elementConfigurations[element.field] = this.getBaseConfig(element.attributes);
                    elementConfigurations[element.field].fieldDefinition = element;
                    break;
            }
        });
        return elementConfigurations;
    }
    getDefaultValidationIcons() {
        return {
            pristine: 'fas fa-asterisk text-muted',
            success: 'fas fa-check text-success',
            error: 'fas fa-times text-danger'
        };
    }
    getBaseConfig(attr) {
        const config = {
            cssClasses: `form-control`,
            id: `id_${attr.name}`,
            name: attr.name,
            errorMsgClass: 'input-error',
            validationIcons: this.getDefaultValidationIcons()
        };
        config.validators = this._getValidators(attr);
        config.asyncValidators = this._getAsyncValidators(attr);
        config.validationMessages = this._getValidatorMessages(attr);
        return config;
    }
    getInputConfig(attr) {
        const config = this._getGenericInputConfig(attr);
        config.autocomplete = false;
        if (attr.mask) {
            config.mask = {};
            config.mask.mask = attr.mask;
            config.mask.showMaskTyped = true;
        }
        return config;
    }
    getInputLangConfig(attr) {
        const config = this.getInputConfig(attr);
        config.itemCode = 'code';
        config.itemText = 'description';
        return config;
    }
    getTextareaLangConfig(attr) {
        const config = this.getTextareaConfig(attr);
        config.itemCode = 'code';
        config.itemText = 'description';
        return config;
    }
    getTextareaConfig(attr) {
        const config = this._getGenericInputConfig(attr);
        config.cssClasses = 'form-control form-textarea';
        return config;
    }
    getDatepickerConfig(attr) {
        const config = this._getGenericInputConfig(attr);
        config.bsConfig = { dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue' };
        return config;
    }
    //** DEPRECATED **/
    getSingleSelectConfig(attr, items) {
        const config = this._getGenericSelectConfig(attr, undefined, items);
        config.defaultTextItem = '-- Select --';
        return config;
    }
    getMultiSelectConfig(attr, items = []) {
        return this._getMultiSelectConfig(attr, undefined, items);
    }
    _getMultiSelectConfig(attr, catalogCodeProperty = 'code', items = []) {
        const config = this._getGenericSelectConfig(attr, catalogCodeProperty, items);
        config.placeholder = attr.placeholder || '-- Select --';
        config.fillParentWidth = true;
        config.itemsShowLimit = 1;
        config.allowSearchFilter = true;
        config.clearText = "<i class='fas fa-times'></i>";
        config.listHeight = 5;
        config.singleSelect = false;
        return config;
    }
    _getGenericInputConfig(attr) {
        const config = this.getBaseConfig(attr);
        config.type = attr.type;
        config.readonly = !attr.editable;
        config.placeholder = attr.placeholder;
        return config;
    }
    _getGenericSelectConfig(attr, catalogCodeProperty = 'code', items) {
        const config = this.getBaseConfig(attr);
        config.items = items;
        //    config.itemCode = catalogCodeProperty;
        config.itemCode = 'code';
        config.itemText = 'description';
        config.readonly = !attr.editable;
        return config;
    }
    _getValidators(attr) {
        const validators = new Array();
        if (attr.required) {
            validators.push(Validators.required);
        }
        if (attr.min != undefined) {
            validators.push(Validators.min(attr.min));
        }
        if (attr.max != undefined) {
            validators.push(Validators.max(attr.max));
        }
        if (attr.minlength != undefined) {
            validators.push(Validators.minLength(attr.minlength));
        }
        if (attr.maxlength != undefined) {
            validators.push(Validators.maxLength(attr.maxlength));
        }
        if (attr.pattern) {
            validators.push(Validators.pattern(attr.pattern));
        }
        if (attr.maxRuleQuantity) {
            validators.push(CustomFormValidatorService.ValidateRuleQuantity(attr.maxRuleQuantity));
        }
        if (attr.dateValidation) {
            validators.push(CustomFormValidatorService.ValidateDate(attr.pattern));
        }
        return validators;
    }
    _getAsyncValidators(attr) {
        const validators = new Array();
        if (attr.repeatedCode) {
            validators.push(CustomFormValidatorService.ValidateCodeUnicity(this._entityHandler));
        }
        return validators;
    }
    _getValidatorMessages(attr) {
        const validationMessages = {};
        if (attr.required) {
            validationMessages.required = 'This is required';
        }
        if (attr.min != undefined) {
            validationMessages.min = `Minimum value is ${attr.min}`;
        }
        if (attr.max != undefined) {
            validationMessages.max = `Maximum value is ${attr.max}`;
        }
        if (attr.minlength != undefined) {
            validationMessages.minlength = `Minimum length is ${attr.minlength}`;
        }
        if (attr.maxlength != undefined) {
            validationMessages.maxlength = `Maximum length is ${attr.maxlength}`;
        }
        if (attr.pattern) {
            validationMessages.pattern = 'Invalid value';
        }
        if (attr.maxRuleQuantity) {
            validationMessages.maxRuleQuantity = CustomFormValidatorService.maxRuleQuantityMessage(attr.maxRuleQuantity);
        }
        if (attr.repeatedCode) {
            validationMessages.repeatedCode = CustomFormValidatorService.repeatedCodeMessage();
        }
        if (attr.dateValidation) {
            validationMessages.invalidDate = CustomFormValidatorService.invalidDate();
        }
        return validationMessages;
    }
}
ElementHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ElementHelperService_Factory() { return new ElementHelperService(i0.ɵɵinject(i1.EntityHandlerService)); }, token: ElementHelperService, providedIn: "root" });
