import { EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { JourneyRestService } from '../../rest/journey-rest/journey-rest.service';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { NavigationHelperService } from '../../helper/navigation-helper/navigation-helper.service';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { CatalogService } from '../../behavior/catalog/catalog.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { JourneyType } from 'src/app/models/journey.models';
import { MessageService } from '../../behavior/message/message.service';
import { JourneyContextQueryStr, DetailViewQueryStr } from 'src/app/enums/query-string';
import { UtilService } from '../../behavior/util/util.service';
import { FormOperationType, FormOperationTypeShortCode } from 'src/app/models/operation.models';
import { EntityHandlerService } from '../entity-handler/entity-handler.service';
import { CacheService } from 'ngx-emerios-all';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { deepClone } from 'src/app/functions/deepClone';
import * as moment from 'moment-mini';
import { AuthorizationHandlerService } from '../authorization-handler/authorization-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../dashboard-handler/dashboard-handler.service";
import * as i2 from "../../behavior/catalog/catalog.service";
import * as i3 from "../../rest/journey-rest/journey-rest.service";
import * as i4 from "../../behavior/message/message.service";
import * as i5 from "../../behavior/util/util.service";
import * as i6 from "../entity-handler/entity-handler.service";
import * as i7 from "../../helper/navigation-helper/navigation-helper.service";
import * as i8 from "ngx-emerios-all";
import * as i9 from "../authorization-handler/authorization-handler.service";
export class JourneyHandlerService {
    constructor(_dashboardHandler, _catalog, _journeyRest, _message, _utils, _entityHandler, _navigationHelper, _cache, _authHandler) {
        this._dashboardHandler = _dashboardHandler;
        this._catalog = _catalog;
        this._journeyRest = _journeyRest;
        this._message = _message;
        this._utils = _utils;
        this._entityHandler = _entityHandler;
        this._navigationHelper = _navigationHelper;
        this._cache = _cache;
        this._authHandler = _authHandler;
        this._availableJourneys = new EventEmitter();
        this._journeySteps = new BehaviorSubject([]);
        this._journeyNavigationFormSteps = new BehaviorSubject([]);
        this._currentJourney = new BehaviorSubject(undefined);
        this._currentJourneyStep = new BehaviorSubject(undefined);
        this._actionFired = new EventEmitter();
        this._actionExecuted = new EventEmitter();
        this._journeyActivity = new BehaviorSubject(undefined);
        this._currentEntitySelected = new BehaviorSubject(undefined);
        this._journeyType = new BehaviorSubject(undefined);
        this._bookInstanceData = new BehaviorSubject(undefined);
        this._journeysList = [];
        this._index = -1;
        this.availableJourneys$ = this._availableJourneys.asObservable().pipe(filter(x => x != undefined));
        this.journeySteps$ = this._journeySteps.asObservable().pipe(filter(x => x != undefined));
        this.journeyNavigationFormSteps$ = this._journeyNavigationFormSteps.asObservable().pipe(filter(x => x != undefined));
        this.currentJourney$ = this._currentJourney.asObservable().pipe(filter(x => x != undefined));
        this.currentJourneyStep$ = this._currentJourneyStep.asObservable();
        this.actionFired$ = this._actionFired.asObservable();
        this.actionExecuted$ = this._actionExecuted.asObservable().pipe(filter(x => x != undefined));
        this.journeyActivity$ = this._journeyActivity.asObservable();
        this.currentEntitySelected$ = this._currentEntitySelected.asObservable();
        this.journeyType$ = this._journeyType.asObservable();
        this.bookInstanceData$ = this._bookInstanceData.asObservable()
            .pipe(filter(x => x != undefined), distinctUntilChanged());
        this._dashboardHandler.currentDashboardItem$
            .subscribe(item => this._dashboardItem = item);
        this._dashboardHandler.dashboardItemList$
            .subscribe(list => this._dashboardItemList = list);
    }
    fireDetailViewAction(action) {
        const context = deepClone(this._context);
        context.action = action;
        context.executorUserName = this._authHandler.getCurrentUsername();
        this._actionFired.next(context);
    }
    getAvailableJourneys(flowInstanceCode) {
        this._catalog.getFullCatalog(CatalogEnum.Journeys)
            .subscribe(catalog => {
            this._journeysList = catalog.items.map(x => {
                return {
                    code: x['code'],
                    title: x['name'],
                    description: x['description'],
                    isPromoteFlow: x['isPromoteFlow'],
                };
            });
            this._availableJourneys.next(this._journeysList);
            if (flowInstanceCode != undefined) {
                const journey = this._journeysList
                    .find(x => x.code === flowInstanceCode);
                this._currentJourney.next(journey);
            }
        });
    }
    getJourney(instanceCode) {
        this.getAvailableJourneys(instanceCode);
    }
    getJourneySteps(instanceCode) {
        this._catalog.getFiltrableCatalog(CatalogEnum.JourneySteps, instanceCode)
            .subscribe(steps => {
            this._journeySteps.next(steps.items);
            this._updateCachedSteps(instanceCode, steps.items);
        });
    }
    getJourneyNavigationSteps(flowInstanceCode, instanceCode) {
        this._catalog.getFiltrableCatalog(CatalogEnum.FlowSteps, instanceCode)
            .subscribe(steps => {
            this._journeyNavigationFormSteps.next(steps.items);
            this._updateCachedSteps(flowInstanceCode, steps.items);
        });
    }
    getCurrentStep(executionId) {
        this._journeyRest.getCurrentStep(executionId)
            .subscribe(journeyExecutionStep => this._setCurrentStep(journeyExecutionStep, executionId, false));
    }
    getApprovalFlow(flowInstanceCode) {
        if (!this._currentJourney.value) {
            this._catalog.getFullCatalog(CatalogEnum.ApprovalFlows)
                .subscribe(catalog => {
                const list = catalog.items.map(x => {
                    return {
                        code: x['code'],
                        title: x['name'],
                        description: x['description']
                    };
                });
                const journey = list.find(x => x.code === flowInstanceCode);
                this._currentJourney.next(journey);
            });
        }
        else {
        }
    }
    getApprovalFlowSteps(instanceCode, contextExecutionid) {
        this._catalog.getFiltrableCatalog(CatalogEnum.ApprovalFlowSteps, instanceCode)
            .subscribe(steps => {
            this._journeySteps.next(steps.items);
            this.getApprovalFlowCurrentStep(contextExecutionid);
        });
    }
    getApprovalFlowCurrentStep(executionId) {
        this._journeyRest.getApprovalFlowNextStep(executionId)
            .subscribe(journeyExecutionStep => {
            this._setCurrentStep(journeyExecutionStep, executionId, true);
        });
    }
    _setCurrentStep(journeyExecutionStep, executionId, isApprovalFlow) {
        let journeyStep;
        if (isApprovalFlow) {
            journeyStep = this._journeySteps.value
                .find(x => x.sequenceId === journeyExecutionStep.sequenceId);
        }
        else {
            journeyStep = this._journeySteps.value
                .find(x => x.formOperationInstanceCode === journeyExecutionStep.formOperationInstanceCode);
            // Case is a Agre Step
            if (!journeyStep) {
                journeyStep = this._journeySteps.value
                    .find(x => x.code === journeyExecutionStep.formOperationInstanceCode);
            }
            if (!journeyStep) {
                journeyStep = this._journeyNavigationFormSteps.value
                    .find(x => x.code === journeyExecutionStep.formOperationInstanceCode);
            }
        }
        if (journeyStep) {
            if (!journeyStep.parentInstanceCode && journeyExecutionStep.parentInstanceCode) {
                journeyStep.parentInstanceCode = journeyExecutionStep.parentInstanceCode;
            }
            journeyStep.execution = journeyExecutionStep;
            journeyStep.workEffortExecutionId = executionId;
            this._currentJourneyStep.next(journeyStep);
        }
    }
    init(flowInstanceCode) {
        this._journeyRest.initFlow(flowInstanceCode)
            .subscribe(executionId => {
            this._getNextStep({ flowInstanceCode, executionId });
        });
    }
    actionExecuted(actionResult) {
        this._actionExecuted.emit(actionResult);
    }
    saveOrSubmitActionCallback(context) {
        if ((this._currentContext && this._currentJourneyExecution) && this._bookInstanceData.value && this._index < this._bookInstanceData.value.length) {
            const approvalFlowStep = this._journeySteps.value.find(x => x.isApprovalFlow);
            const kv = {};
            kv[JourneyContextQueryStr.ApprovalWorkEffortInstanceCode] = approvalFlowStep && approvalFlowStep.code;
            kv[JourneyContextQueryStr.Execution] = this._currentJourneyExecution.workEffortExecutionId;
            kv[DetailViewQueryStr.ForceDetailViewChange] = 1;
            this._goToDynaf(context, this._currentJourneyExecution, kv);
        }
        else {
            this._journeyRest.executeStep(context)
                .subscribe(() => {
                this._getNextStep(context);
            });
        }
    }
    setContext(context) {
        this._context = context;
    }
    setJourneyType(journey) {
        const journeyType = journey.isPromoteFlow ? JourneyType.Promote : JourneyType.Deploy;
        this._journeyType.next(journeyType);
    }
    setBookInstanceData(bookInstanceData) {
        this._index = 0;
        this._currentContext = undefined;
        this._bookInstanceData.next(bookInstanceData);
    }
    getContext() {
        return this._context;
    }
    updateContext(key, value) {
        const kv = {};
        kv[key] = value;
        this.updateContextMultiple(kv);
    }
    updateContextMultiple(kvs) {
        this._navigationHelper.updateQueryParams(kvs);
    }
    linkRequestToEntity(requestCode) {
        const currentStep = this._currentJourneyStep.value;
        if (currentStep.dynamicFormLibraryCode === DynafEnum.Request) {
            let payload = [];
            this._context.entityChangeRequestCode
                .forEach(changeRequest => {
                payload.push({
                    requestCode: requestCode,
                    changeRequestCode: changeRequest,
                    isPromoteRequest: this._context.isPromoteRequest
                });
            });
            this._entityHandler.confirmLinkMultiple(payload);
        }
    }
    buildContext(flowInstanceCode, workEffortExecutionId) {
        const kv = {};
        kv[JourneyContextQueryStr.FlowInstanceCode] = flowInstanceCode;
        kv[JourneyContextQueryStr.FlowType] = 'approval';
        kv[JourneyContextQueryStr.Execution] = workEffortExecutionId;
        this._currentJourney.next(undefined);
        this._journeySteps.next(undefined);
        this._currentJourneyStep.next(undefined);
        this._navigationHelper.updateQueryParams(kv);
    }
    clearCurrentStep() {
        this._currentJourneyStep.next(undefined);
    }
    journeyActivity(action) {
        // if (action === 'Stop') {
        //   this._index = -1;
        //   this._currentContext = undefined;
        // }
        this._journeyActivity.next(action);
    }
    setCurrentEntitySelected(data) {
        this._currentEntitySelected.next(data);
    }
    _updateCachedSteps(instanceCode, steps) {
        const journeyStepsCached = this._cache.get(CacheKeyEnum.StepsByJourney) || [];
        if (journeyStepsCached.findIndex(x => x.code === instanceCode) === -1) {
            journeyStepsCached.push({ code: instanceCode, steps: steps });
        }
        else {
            journeyStepsCached.find(x => x.code === instanceCode).steps = steps;
        }
        this._cache.set(CacheKeyEnum.StepsByJourney, journeyStepsCached);
    }
    _goToDynaf(context, nextStep, extraParams) {
        if (!nextStep.dynamicFormLibraryCode) {
            throw 'Step configuration is wrong';
        }
        let nextDashboardItem = this._dashboardItemList
            .find(x => x.formSource === nextStep.dynamicFormLibraryCode);
        let instanceCode = undefined;
        let assignToPayload = {};
        // si el proximo paso coincide con la entidad seleccionada en Identiy Entity
        if (nextDashboardItem.formSource === context.entityDynaf) {
            if (context.entityInstanceCode) {
                instanceCode = context.entityInstanceCode;
            }
            else if (!context.entityInstanceCode && !context.entityInstanceName) {
                instanceCode = this._bookInstanceData.getValue()[this._index].instanceCode;
                const instanceName = this._bookInstanceData.getValue()[this._index].instanceName;
                this._cache.set(CacheKeyEnum.BookSelected, instanceName);
                const libraryCode = this._utils.getLibraryCodeFromInstance(instanceCode);
                nextDashboardItem = this._dashboardItemList
                    .find(x => x.libraryCodes.includes(libraryCode) && x.formSource != DynafEnum.Book);
                this._index++;
            }
        }
        else {
            // si el proximo paso es DYNAF.REQUEST y ya hay un requestCode
            if (nextDashboardItem.formSource === DynafEnum.Request) {
                const isViewMode = !context.requestCode;
                if (isViewMode) {
                    assignToPayload = this._getAutocompletedDataForRequest(context);
                }
                else {
                    instanceCode = context.requestCode;
                }
            }
        }
        const queryParams = this._getQueryParams(nextStep, context, extraParams);
        if (!instanceCode && this._context) {
            assignToPayload['ownerPartyRoleInstanceCode'] = this._context.entityOwnerPartyRoleInstanceCode;
        }
        if (this._context && nextDashboardItem.formSource === DynafEnum.Request &&
            (queryParams[DetailViewQueryStr.FormOperationType] === FormOperationTypeShortCode.Create ||
                queryParams[DetailViewQueryStr.FormOperationType] == undefined)) {
            assignToPayload['journeyWorkEffortExecutionToken'] = this._context.executionId;
        }
        if (!instanceCode) {
            instanceCode = nextStep.metadata && nextStep.metadata.type.startsWith('DYNAF.GEN.') && nextStep.formOperationInstanceCode;
        }
        this._navigationHelper.goToViewDetail({
            entity: nextDashboardItem,
            detailCode: instanceCode,
            assignToPayload: assignToPayload,
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        });
    }
    _getQueryParams(nextStep, context, extraParams) {
        const queryParams = {};
        const operation = this._utils.getOperationFromInstance(nextStep.formOperationInstanceCode);
        queryParams[JourneyContextQueryStr.FlowInstanceCode] = context.flowInstanceCode;
        queryParams[JourneyContextQueryStr.Execution] = nextStep.workEffortExecutionId;
        Object.keys(extraParams).forEach(k => {
            queryParams[k] = extraParams[k];
        });
        if (operation === FormOperationType.Identify) {
            queryParams[DetailViewQueryStr.FormOperationType] = FormOperationTypeShortCode.Identiy;
        }
        else if (operation === FormOperationType.Edit) {
            queryParams[DetailViewQueryStr.FormOperationType] = FormOperationTypeShortCode.Edit;
        }
        else {
            queryParams[DetailViewQueryStr.FormOperationType] = undefined;
        }
        return queryParams;
    }
    _getAutocompletedDataForRequest(context) {
        const dateNow = moment().format('YYMMDD');
        const tenantCodeSource = context.tenantCodeSource;
        const tenantCodeTarget = context.tenantCodeTarget;
        const journeyName = this._currentJourney.value.title;
        const instanceCode = context.entityInstanceCode;
        const instanceName = context.entityInstanceName;
        const integrationCommand = JSON.parse(sessionStorage.getItem(CacheKeyEnum.IntegrationCommand));
        let name;
        if (!instanceName && !instanceCode) {
            if (this._journeyType.value === JourneyType.Deploy) {
                name = `${dateNow} - ${journeyName}`;
            }
            else {
                name = `${dateNow} - ${journeyName} - ${tenantCodeSource} to ${tenantCodeTarget}`;
            }
        }
        else {
            name = `${dateNow} - ${journeyName} - ${instanceName} (${instanceCode})`;
        }
        return {
            name: name,
            description: name,
            ownerCodeTarget: tenantCodeTarget,
            integrationCommand: integrationCommand
        };
    }
    _getNextStep(context) {
        this._journeyRest.getCurrentStep(context.executionId)
            .subscribe(step => {
            if (step) {
                const regExp = new RegExp('DYNAF.[a-zA-Z]+.BK|DYNAF.BK');
                if (regExp.exec(step.dynamicFormLibraryCode)) {
                    this._currentContext = context;
                    this._currentJourneyExecution = step;
                }
                const approvalFlowStep = this._journeySteps.value.find(x => x.isApprovalFlow);
                this._handleNextStepResponse(step, context, approvalFlowStep);
            }
            else {
                this._goToJourneyFinalizationPage(context);
            }
        });
    }
    _handleNextStepResponse(step, context, approvalFlowStep) {
        let journeyStep = null;
        if (step.isApprovalFlow) {
            journeyStep = approvalFlowStep;
        }
        else {
            journeyStep = this._journeySteps.value
                .find(x => x.formOperationInstanceCode === step.formOperationInstanceCode);
        }
        // Case is a Agre Step
        if (!journeyStep) {
            journeyStep = this._journeySteps.value
                .find(x => x.code === step.formOperationInstanceCode);
        }
        if (!journeyStep) {
            journeyStep = this._journeyNavigationFormSteps.value
                .find(x => x.code === step.formOperationInstanceCode);
        }
        if (journeyStep) {
            if (!journeyStep.parentInstanceCode && step.parentInstanceCode) {
                journeyStep.parentInstanceCode = step.parentInstanceCode;
            }
            journeyStep.execution = step;
            journeyStep.workEffortExecutionId = step.workEffortExecutionId;
            this._currentJourneyStep.next(journeyStep);
            if (!step.isApprovalFlow) {
                const kv = {};
                kv[JourneyContextQueryStr.ApprovalWorkEffortInstanceCode] = approvalFlowStep && approvalFlowStep.code;
                kv[JourneyContextQueryStr.Execution] = step.workEffortExecutionId;
                if ((this._dashboardItem && this._dashboardItem.formSource) === journeyStep.dynamicFormLibraryCode) {
                    kv[DetailViewQueryStr.ForceDetailViewChange] = 1;
                }
                this._goToDynaf(context, step, kv);
            }
            this.actionExecuted();
        }
    }
    _goToJourneyFinalizationPage(context) {
        this._message.showPageMessage({
            type: 'info',
            title: 'Your journey is over :)',
            fromJourney: true,
            requestCode: context.requestCode
        });
    }
}
JourneyHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JourneyHandlerService_Factory() { return new JourneyHandlerService(i0.ɵɵinject(i1.DashboardHandlerService), i0.ɵɵinject(i2.CatalogService), i0.ɵɵinject(i3.JourneyRestService), i0.ɵɵinject(i4.MessageService), i0.ɵɵinject(i5.UtilService), i0.ɵɵinject(i6.EntityHandlerService), i0.ɵɵinject(i7.NavigationHelperService), i0.ɵɵinject(i8.CacheService), i0.ɵɵinject(i9.AuthorizationHandlerService)); }, token: JourneyHandlerService, providedIn: "root" });
