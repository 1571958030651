import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.sass']
})
export class GenericModalComponent implements OnInit {
  public title: string;
  public message: string;
  public ok: any = {};
  public cancel: any = {};

  constructor(private _modal: BsModalRef) { }

  ngOnInit() {
  }

  public okModal() {
    this._modal.hide();
    if (this.ok.callback) {
      this.ok.callback();
    }
  }

  public closeModal() {
    this._modal.hide();
    if (this.cancel.callback) {
      this.cancel.callback();
    }
  }

}
