import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class ActionBarService {
    constructor() {
        this._state = new EventEmitter();
        this._stateForAll = new EventEmitter();
        this.state$ = this._state.asObservable();
        this.stateForAll$ = this._stateForAll.asObservable();
    }
    updateState(code, state) {
        this._state.emit({ code, state });
    }
    updateStateForAll(code, state) {
        this._stateForAll.emit({ code, state });
    }
}
ActionBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActionBarService_Factory() { return new ActionBarService(); }, token: ActionBarService, providedIn: "root" });
