import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { CatalogEnum } from 'src/app/enums/catalogs';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class CatalogRestService {
    constructor(_rest) {
        this._rest = _rest;
        this._uncacheableCatalogs = new Set();
        this._uncacheableCatalogs.add(CatalogEnum.DocumentAssets);
        this._uncacheableCatalogs.add(CatalogEnum.FileAssets);
        this._uncacheableCatalogs.add(CatalogEnum.GeographyGrouping);
        this._uncacheableCatalogs.add(CatalogEnum.GeographyGroupingAndState);
        this._uncacheableCatalogs.add(CatalogEnum.Request);
        this._uncacheableCatalogs.add(CatalogEnum.ProductTypes);
        this._uncacheableCatalogs.add(CatalogEnum.ProductProvider);
        this._uncacheableCatalogs.add(CatalogEnum.PartyInstance);
        this._uncacheableCatalogs.add(CatalogEnum.Journeys);
        this._uncacheableCatalogs.add(CatalogEnum.Product);
        this._uncacheableCatalogs.add(CatalogEnum.InstanceByLibraryCode);
        this._uncacheableCatalogs.add(CatalogEnum.BrandOrganization);
    }
    getCatalogsForDetail(source) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/${source}/catalogs/`
        });
    }
    getCatalogs(catalogs) {
        const headers = new HttpHeaders()
            .append('restCache', this._cacheableCatalog(catalogs) ? 'true' : 'false');
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/catalogs/`,
            headers: headers,
            params: { catalogCodes: catalogs }
        });
    }
    getCatalog(catalog, filterBy) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/catalog/${catalog}`,
            params: { filterBy: filterBy }
        });
    }
    getFullCatalogs(catalogs) {
        const headers = new HttpHeaders()
            .append('restCache', this._cacheableCatalog(catalogs) ? 'true' : 'false');
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/catalogs/`,
            params: { catalogCodes: catalogs },
            headers: headers
        });
    }
    getFiltrableCatalog(catalog, filterBy) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/catalog/${catalog}`,
            params: { filterBy: filterBy }
        });
    }
    _cacheableCatalog(catalogs) {
        const cacheable = catalogs.every(x => !this._uncacheableCatalogs.has(x));
        return cacheable;
    }
}
CatalogRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatalogRestService_Factory() { return new CatalogRestService(i0.ɵɵinject(i1.RestService)); }, token: CatalogRestService, providedIn: "root" });
