import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { WaitingLoaderService } from 'src/app/services/behavior/waiting-loader/waiting-loader.service';
import { SignPadComponent } from 'src/app/modules/signature/sign-pad/sign-pad.component';

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
})
export class SignatureModalComponent implements OnInit {
  @ViewChild('signPadComponent', {static: false}) signPad: SignPadComponent; 

  @Input()
  public callback: Function;
  @Input()
  public confirmText: string;
  @Input()
  public title: string;
  @Input()
  public message: string

  constructor(
    private _modal: BsModalRef,
    private _waitingLoader: WaitingLoaderService) {}

  ngOnInit(): void {
  }

  public executeCallback() {
    if(this.callback) {
      this._waitingLoader.showFullScreenLoading();
      this.callback(this.signPad.getBase64Data());
    }
    this.closeModal();
  }

  public closeModal() {
    this._modal.hide();
  }
}
