import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { map, finalize, filter } from 'rxjs/operators';
import { RelationshipScope } from '../../../models/form-field-definition.models';
import { GridRestService } from 'src/app/services/rest/grid-data-rest/grid-data-rest.service';
import { ProductRestService } from '../../rest/product/product-rest.service';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { CatalogService } from '../../behavior/catalog/catalog.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { ApiHelperService } from '../../helper/api-helper/api-helper.service';
import * as moment from 'moment-mini';
import { UtilService } from '../../behavior/util/util.service';
import * as i0 from "@angular/core";
import * as i1 from "../../rest/grid-data-rest/grid-data-rest.service";
import * as i2 from "../../rest/product/product-rest.service";
import * as i3 from "../../behavior/catalog/catalog.service";
import * as i4 from "../../helper/api-helper/api-helper.service";
import * as i5 from "../../behavior/util/util.service";
export class GridHandlerService {
    constructor(_gridRest, _productRest, _catalog, _apiHelper, _util) {
        this._gridRest = _gridRest;
        this._productRest = _productRest;
        this._catalog = _catalog;
        this._apiHelper = _apiHelper;
        this._util = _util;
        this._getGenericGridDataAndDef = new EventEmitter();
        this._mainColumnDefinitions = new BehaviorSubject(undefined);
        this._mainQuickSearch = new EventEmitter(undefined);
        this._gridData = new EventEmitter(undefined);
        this._gridColumnDefinitions = new EventEmitter(undefined);
        this._restoreMainColumnState = new EventEmitter(undefined);
        this._mainColDefPristine = new EventEmitter(true);
        this._changesGridRows = new EventEmitter();
        this.getGenericGridDataAndDef$ = this._getGenericGridDataAndDef.asObservable();
        this.mainColumnDefinitions$ = this._mainColumnDefinitions.asObservable().pipe(filter(x => x !== undefined));
        this.mainQuickSearch$ = this._mainQuickSearch.asObservable();
        this.gridData$ = this._gridData.asObservable();
        this.gridColumnDefinitions$ = this._gridColumnDefinitions.asObservable();
        this.restoreMainColumnState$ = this._restoreMainColumnState.asObservable();
        this.mainColDefPristine$ = this._mainColDefPristine.asObservable();
        this.changesGridRows$ = this._changesGridRows.asObservable();
    }
    getRelationshipsData(uid, scope, agGridSource, detailCode) {
        const viewCode = this._apiHelper.getUrlSection(detailCode);
        let observable;
        switch (scope) {
            case RelationshipScope.Child:
                observable = this._gridRest.getChildrenRelationshipsDataAndDef(agGridSource, viewCode, detailCode);
                // observable = this._gridRest.getChildrenRelationshipsDataAndDefPaginated(agGridSource, viewCode, detailCode);
                break;
            case RelationshipScope.Parent:
                observable = this._gridRest.getParentsRelationshipsDataAndDef(agGridSource, viewCode, detailCode);
                break;
        }
        observable.subscribe(data => {
            this._getGenericGridDataAndDef.next({
                uid: uid,
                source: agGridSource,
                rows: data.rows,
                metadata: data.metadata
            });
        });
    }
    getPreviewGridData(data) {
        this._getGenericGridDataAndDef.next({
            rows: data.rows,
            metadata: data.metadata
        });
    }
    getGenericGridDefAndCustomData(grid, detailCode, type) {
        const newModelAgGrid = [
            AgGridEnum.QuestionParentCondition,
            AgGridEnum.HierarchicalWorkeffort,
            AgGridEnum.WorkeffortHierarchyJourneyAndOthers,
            AgGridEnum.ComparativeView,
            AgGridEnum.PromoteView,
            AgGridEnum.CatalogProduct,
            AgGridEnum.CatalogRelationshipType,
            AgGridEnum.CampaignEntries,
            AgGridEnum.QuestionRelationship,
            AgGridEnum.AvailableTpvCampaignProducts,
            AgGridEnum.EventFlow,
            AgGridEnum.EmailTemplate,
            AgGridEnum.ProductCheckAndBalance,
            AgGridEnum.BookItem
        ];
        let gridDefObs;
        if (newModelAgGrid.includes(grid.source)) {
            gridDefObs = this._gridRest.getGridDefinitionBySourceV2(grid.source);
        }
        else {
            gridDefObs = this._gridRest.getGridDefinitionBySource(grid.source);
        }
        let gridDataObs;
        if (grid.code === 'catalog_product') {
            gridDataObs = this._productRest.getProductsByOwner(detailCode);
        }
        else if (grid.code === 'cmp_rules') {
            gridDataObs = this._gridRest.getCampaignCombinations(detailCode);
        }
        else if (grid.code === 'question-rules') {
            gridDataObs = this._gridRest.getQuestionsByOwnerAndContainerType(detailCode, type);
        }
        else if (grid.code === 'qst_rules') {
            gridDataObs = this._gridRest.getQuestionsParentTrigger();
        }
        else if (grid.code === 'relationship_types') {
            gridDataObs = this._catalog.getFullCatalog(CatalogEnum.RelationshipTypes)
                .pipe(map(catalog => catalog.items));
        }
        else if (grid.code === 'comparativeView') {
            gridDataObs = this._gridRest.getChangesGridData(detailCode);
        }
        else if (grid.code === 'promoteView') {
            gridDataObs = this._gridRest.getPromoteGridData(detailCode);
        }
        else if (grid.code === 'hierarchicalView') {
            gridDataObs = this._gridRest.getHierarchicalView(detailCode)
                .pipe(map(data => this._mapHierarchicalWeData(data)));
        }
        else if (grid.code === 'flowstepsTpv') {
            this.getGenericGridDataAndDef(grid.source, detailCode);
            return;
        }
        else if (grid.code === 'flowstepsOthers') {
            gridDataObs = this._gridRest.getFlowJourneyHierarchicalView(type, detailCode)
                .pipe(map(data => this._mapFlowJourneyHierarchicalData(data)));
        }
        else if (grid.code === 'tpv_products') {
            gridDataObs = this._gridRest.getTpvCampaignProducts(detailCode);
        }
        else if (grid.code === 'flowEvent') {
            gridDataObs = this._gridRest.getEventFlowView(detailCode, type);
        }
        else if (grid.code === 'book_items') {
            gridDataObs = this._gridRest.getBookItems(detailCode, type);
        }
        else {
            throw `There is no definition for grid code: ${grid.code}`;
        }
        forkJoin(gridDefObs, gridDataObs)
            .pipe(map(response => {
            this._attachFunctions({ metadata: response[0] });
            return response;
        }))
            .subscribe(responses => {
            this._getGenericGridDataAndDef.next({
                source: grid.source,
                columns: responses[0].columnDefinition || responses[0],
                rows: responses[1]
            });
        });
    }
    getGenericGridDataAndDef(source, detailCode) {
        this._gridRest.getGenericGridDataAndDetinitions(source, detailCode)
            .pipe(map(response => {
            this._attachFunctions(response);
            return response;
        }), finalize(() => this.setMainColDefinitionPristine()))
            .subscribe(data => {
            this._getGenericGridDataAndDef.next({
                source: source,
                columns: data.gridColumnDefinition,
                rows: data.rows
            });
        });
    }
    getQuestionRulesGridDataAndDef(source, workEffortTypeLibraryCode, ownerPartyRoleInstanceCode) {
        this._gridRest.getQuestionRulesGridDataAndDef(source, workEffortTypeLibraryCode, ownerPartyRoleInstanceCode)
            .pipe(map(response => {
            this._attachFunctions(response);
            return response;
        }), finalize(() => this.setMainColDefinitionPristine()))
            .subscribe(data => {
            this._getGenericGridDataAndDef.next({
                source: source,
                columns: data.gridColumnDefinition,
                rows: data.rows
            });
        });
    }
    getGenericGridDataAndDefV2(dashboardItem, sourceSuffix, params, ownerPartyRoleInstanceCode) {
        const viewCode = dashboardItem.overrideViewCode || dashboardItem.viewCode;
        let source = dashboardItem.gridSource;
        let observable;
        if (sourceSuffix != undefined) {
            source = `${source}.${sourceSuffix}`;
        }
        if (source === AgGridEnum.GlossaryTermHierarchy) {
            observable = this._gridRest.getHierarchyGridDataAndDetinitions(source, viewCode);
        }
        else {
            observable = this._gridRest.getGenericGridDataAndDetinitionsV2(source, viewCode, params, ownerPartyRoleInstanceCode);
        }
        observable.pipe(map(response => {
            this._attachFunctions(response);
            return response;
        }), finalize(() => this.setMainColDefinitionPristine()))
            .subscribe(data => {
            this._getGenericGridDataAndDef.next({
                source: source,
                rows: data.rows,
                metadata: data.metadata
            });
        });
    }
    getPaginatedData(dashboardItem, payload, instanceCode, relationshipScope) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let source = relationshipScope ? AgGridEnum.CommonRelationship : dashboardItem.gridSource;
            let observable;
            const viewCode = instanceCode
                ? this._apiHelper.getUrlSection(instanceCode, null, dashboardItem)
                : dashboardItem.overrideViewCode || dashboardItem.viewCode;
            switch (source) {
                case AgGridEnum.GlossaryTermHierarchy:
                    observable = this._gridRest.getHierarchyGridDataAndDetinitions(source, viewCode);
                    break;
                case AgGridEnum.StepForm:
                case AgGridEnum.StepContainer:
                    payload.LibraryCodes = dashboardItem.libraryCodes;
                    observable = this._gridRest.getPaginatedData(source, viewCode, payload);
                    break;
                case AgGridEnum.CommonRelationship:
                    if (relationshipScope == RelationshipScope.Child) {
                        observable = this._gridRest.getChildrenRelationshipsDataAndDefPaginated(source, viewCode, instanceCode, payload);
                    }
                    if (relationshipScope == RelationshipScope.Parent) {
                        observable = this._gridRest.getParentRelationshipsDataAndDefPaginated(source, viewCode, instanceCode, payload);
                    }
                    break;
                default:
                    observable = this._gridRest.getPaginatedData(source, viewCode, payload);
                    break;
            }
            return yield observable.pipe(map(response => {
                this._attachFunctions(response);
                return {
                    source: source,
                    rows: response.rows,
                    metadata: response.metadata
                };
            }), finalize(() => this.setMainColDefinitionPristine())).toPromise();
        });
    }
    getFormGridColumnDefinitions(code) {
        this._gridRest.getGridDefinitionBySource(code)
            .pipe(finalize(() => this._gridColumnDefinitions.next(undefined)))
            .subscribe(definitions => this._gridColumnDefinitions.next(definitions));
    }
    mainQuickSearch(value) {
        this._mainQuickSearch.next(value);
    }
    updateMainColumnDefinitions(columnDef) {
        this._mainColumnDefinitions.next(columnDef);
    }
    setMainColDefinitionDirty() {
        this._mainColDefPristine.next(false);
    }
    setMainColDefinitionPristine() {
        this._mainColDefPristine.next(true);
    }
    restoreMainColumnState(presetView) {
        this.setMainColDefinitionPristine();
        this._restoreMainColumnState.next(presetView);
    }
    setChangesGridRows(rows) {
        this._changesGridRows.next(rows);
    }
    getGridData(currentDashboardItem, paginationDto, instanceCode, relationshipScope) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.getPaginatedData(currentDashboardItem, paginationDto, instanceCode, relationshipScope);
        });
    }
    _attachFunctions(response) {
        const columnDef = response.gridColumnDefinition || response.metadata.columnDefinition;
        columnDef.forEach((col) => {
            this._tryToAttachFuncs(col);
            if (col.children) {
                col.children.forEach((c) => {
                    this._tryToAttachFuncs(c);
                });
            }
        });
    }
    _tryToAttachFuncs(col) {
        // beautiful date native date function
        // return new Date(data.data.creationDatetime)
        // .toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hourCycle: 'h24' })
        if (col.filterValueGetter) {
            col.filterValueGetter = eval.bind(null, col.filterValueGetter)();
        }
        if (col.valueGetter) {
            this._handleCellFunctions(col, 'valueGetter');
        }
        if (col.cellRenderer) {
            this._handleCellFunctions(col, 'cellRenderer');
        }
    }
    _handleCellFunctions(col, funcProp) {
        if (col[funcProp].toString().startsWith('$fnDateFormat')) {
            const fieldName = col[funcProp].toString().split('=')[1];
            col[funcProp] = (params) => {
                if (!params.data) {
                    return '';
                }
                const dateValue = params.data[fieldName || 'creationDatetime'];
                return dateValue != null ? moment(dateValue).format('MM/DD/YYYY HH:mm') : '';
            };
        }
        else if (col[funcProp].toString().startsWith('$fnLocaleDateFormat')) {
            const fieldName = col[funcProp].toString().split('=')[1];
            col[funcProp] = (params) => {
                if (!params.data) {
                    return '';
                }
                const dateValue = params.data[fieldName || 'creationDatetime'];
                return dateValue != null ? moment(this._util.CreateDateAsUTCFromString((dateValue))).format('MM/DD/YYYY HH:mm') : '';
            };
        }
        else if (col[funcProp].toString().startsWith('$fnBoolToYesNo')) {
            const fieldName = col[funcProp].toString().split('=')[1];
            col[funcProp] = (params) => {
                if (!params.data) {
                    return undefined;
                }
                const cellValue = params.data[fieldName];
                return cellValue ? 'Yes' : 'No';
            };
        }
        else {
            col[funcProp] = Function("params", col[funcProp]);
        }
    }
    _mapHierarchicalWeData(data) {
        const items = new Array();
        data.forEach(stage => {
            items.push({
                requestCode: stage.requestCode,
                instanceCode: stage.workEffortInstanceCode,
                requestName: stage.requestName,
                stageName: stage.workEffortInstanceName,
                status: stage.status,
                completionDateTime: stage.completionDateTime,
                plannedDateTime: stage.plannedDateTime
            });
            if (stage.steps) {
                stage.steps.forEach(step => {
                    items.push({
                        requestCode: step.requestCode,
                        instanceCode: step.workEffortInstanceCode,
                        requestName: stage.requestName,
                        stageName: stage.workEffortInstanceName,
                        stepName: step.workEffortInstanceName,
                        status: step.status,
                        completionDateTime: step.completionDateTime,
                        expectedDuration: step.expectedDuration,
                        assignedTo: step.assignedTo,
                        executedBy: step.executedBy,
                        plannedDateTime: step.plannedDateTime,
                        approvedScheduleDateTime: step.approvedScheduleDateTime
                    });
                });
            }
        });
        return items;
    }
    _mapFlowJourneyHierarchicalData(data) {
        const items = new Array();
        let flowIndex = 0;
        let stageIndex = 0;
        let sequenceId = undefined;
        data.forEach(flow => {
            sequenceId = (++flowIndex).toString();
            ({ sequenceId, flowIndex, stageIndex } =
                this._mapFlowJourneyHierarchicalDataFlow(items, sequenceId, flow, flowIndex, stageIndex));
        });
        // por pedido de charly
        const actionsToCheck = ['onSuccessActionOutcome', 'onFailActionOutcome'];
        actionsToCheck.forEach(action => {
            items.forEach(item => {
                if (item[`${action}Code`]) {
                    // busco step en la lista de items a mostrar en la grilla para obtener el sequenceId de "a donde va a ir"
                    const goToStep = items.find(x => {
                        return item[`${action}Code`] == x.stageWorkEffortInstanceName
                            || item[action] == x.stepWorkEffortInstanceName;
                    });
                    if (goToStep) {
                        item[action] = `${goToStep.sequenceId} - ${item[action]}`;
                    }
                }
            });
        });
        return items;
    }
    _mapFlowJourneyHierarchicalDataFlow(items, sequenceId, flow, flowIndex, stageIndex) {
        this._mapFlowJourneyHierarchicalDataGeneric(items, sequenceId, flow);
        if (flow.children) {
            flow.children.forEach(stageOrFlow => {
                if (stageOrFlow.workEffortTypeLibraryCode.startsWith('FLW.')) {
                    sequenceId = (++flowIndex).toString();
                    ({ sequenceId, flowIndex, stageIndex } =
                        this._mapFlowJourneyHierarchicalDataFlow(items, sequenceId, stageOrFlow, flowIndex, stageIndex));
                }
                else if (stageOrFlow.workEffortTypeLibraryCode.startsWith('STG.')) {
                    const stage = stageOrFlow;
                    sequenceId = `${flowIndex}.${(++stageIndex).toString()}`;
                    this._mapFlowJourneyHierarchicalDataGeneric(items, sequenceId, flow, stage);
                    if (stage.steps) {
                        stage.steps.forEach((step, index) => {
                            sequenceId = `${flowIndex}.${stageIndex}.${(index + 1).toString()}`;
                            this._mapFlowJourneyHierarchicalDataGeneric(items, sequenceId, flow, stage, step);
                            if (step.form && step.form.containers) {
                                step.form.containers.forEach(container => {
                                    this._mapFlowJourneyHierarchicalDataGeneric(items, undefined, flow, stage, step, container);
                                });
                            }
                        });
                    }
                }
            });
        }
        return { sequenceId, flowIndex, stageIndex };
    }
    _mapFlowJourneyHierarchicalDataGeneric(items, sequenceId, flow, stage, step, container) {
        const navRules = step && step.navigationRules;
        const onSuccess = navRules && navRules.find(rule => rule.factorResolutionStatus === 'Success');
        const onFail = navRules && navRules.find(rule => rule.factorResolutionStatus === 'Fail');
        const instanceCode = container ? container.instanceCode :
            step && step.form ? step.form.instanceCode :
                step ? step.workEffortInstanceCode :
                    stage ? stage.workEffortInstanceCode :
                        flow.workEffortInstanceCode;
        const instanceName = container ? container.name :
            step && step.form ? step.form.name :
                step ? step.workEffortInstanceName :
                    stage ? stage.workEffortInstanceName :
                        flow.workEffortInstanceName;
        const libraryCode = container ? container.rootLibraryCode :
            step && step.form ? step.form.rootLibraryCode :
                step ? step.workEffortTypeLibraryCode :
                    stage ? stage.workEffortTypeLibraryCode :
                        flow.workEffortTypeLibraryCode;
        items.push({
            sequenceId: sequenceId,
            instanceCode: instanceCode,
            instanceName: instanceName,
            libraryCode: libraryCode,
            flowWorkEffortInstanceName: flow.workEffortInstanceName,
            stageWorkEffortInstanceName: stage && stage.workEffortInstanceName,
            stepWorkEffortTypeName: step && step.workEffortTypeName,
            stepWorkEffortInstanceCode: step && step.workEffortInstanceCode,
            stepWorkEffortInstanceName: step && step.workEffortInstanceName,
            formName: step && step.form && step.form.name,
            containerInstanceCode: container && container.instanceCode,
            containerCode: container && container.code,
            containerName: container && container.name,
            onSuccessActionOutcomeCode: onSuccess && container == undefined ? onSuccess.outcomeWorkEffortInstanceCode : undefined,
            onSuccessActionOutcome: onSuccess && container == undefined ? onSuccess.outcomeWorkEffortInstanceName : '--',
            onFailActionOutcome: onFail && container == undefined ? onFail.outcomeWorkEffortInstanceName : '--',
            onFailActionOutcomeCode: onFail && container == undefined ? onFail.outcomeWorkEffortInstanceCode : undefined
        });
        return items;
    }
}
GridHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GridHandlerService_Factory() { return new GridHandlerService(i0.ɵɵinject(i1.GridRestService), i0.ɵɵinject(i2.ProductRestService), i0.ɵɵinject(i3.CatalogService), i0.ɵɵinject(i4.ApiHelperService), i0.ɵɵinject(i5.UtilService)); }, token: GridHandlerService, providedIn: "root" });
