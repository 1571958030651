import { Directive, Input, OnInit, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationHelperService } from 'src/app/services/helper/authorization-helper/authorization-helper.service';

@Directive({
  selector: '[appAuthorize]'
})
export class AuthorizeDirective implements OnInit {

  private _authorize: string;

  @Input()
  set appAuthorize(value: string) {
    this._authorize = value;
    this._updateView();
  }

  constructor(
    private _elemRef: ElementRef,
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authHelper: AuthorizationHelperService) {
  }

  ngOnInit(): void {
  }

  private _updateView() {
    if (this._checkPermission()) {
        this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._viewContainer.clear();
    }
  }

  private _checkPermission() {
    if(this._authorize) {
      let permission = this._authorize.split(",");
      let resource = permission[0].trim();
      let operation = permission[1].trim();
      if(permission.length == 2) {
        let isAuthorized = this._authHelper.isAutorized(resource, operation);
        return isAuthorized;
      } else {
        throw new Error("Authorization must be in the format 'resource,operation'");
      }
    } else {
      throw new Error("Authorization is not defined");
    }
  }
}
