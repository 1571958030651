/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-form-group-container.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading/ghost-loading/ghost-loading.component.ngfactory";
import * as i3 from "../loading/ghost-loading/ghost-loading.component";
import * as i4 from "../dynamic-form-group/dynamic-form-group.component.ngfactory";
import * as i5 from "../dynamic-form-group/dynamic-form-group.component";
import * as i6 from "../../services/helper/element-helper/element-helper.service";
import * as i7 from "../../services/helper/data-model-helper/data-model-helper.service";
import * as i8 from "../../services/rest/form-rest/form-rest.service";
import * as i9 from "../../services/behavior/catalog/catalog.service";
import * as i10 from "../../services/handler/field-value-handler/field-value-handler.service";
import * as i11 from "../../services/behavior/replacer/replacer.service";
import * as i12 from "../../services/helper/navigation-helper/navigation-helper.service";
import * as i13 from "../../services/handler/file-handler/file-handler.service";
import * as i14 from "@angular/router";
import * as i15 from "../../services/handler/journey-handler/journey-handler.service";
import * as i16 from "@angular/common";
import * as i17 from "./dynamic-form-group-container.component";
import * as i18 from "../../services/handler/dashboard-handler/dashboard-handler.service";
import * as i19 from "../../services/handler/detail-view-handler/detail-view-handler.service";
var styles_DynamicFormGroupContainerComponent = [i0.styles];
var RenderType_DynamicFormGroupContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicFormGroupContainerComponent, data: {} });
export { RenderType_DynamicFormGroupContainerComponent as RenderType_DynamicFormGroupContainerComponent };
function View_DynamicFormGroupContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading", [], null, null, null, i2.View_GhostLoadingComponent_0, i2.RenderType_GhostLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GhostLoadingComponent, [], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_0 = "dynamic-form"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DynamicFormGroupContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "fields-group pl-fluid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-dynamic-form-group", [], null, [[null, "bubbledEvent"], [null, "isValid"], [null, "dirty"], [null, "change"], [null, "formSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("bubbledEvent" === en)) {
        var pd_0 = (_co.bubbledEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("isValid" === en)) {
        var pd_1 = (_co.isValidGroup($event) !== false);
        ad = (pd_1 && ad);
    } if (("dirty" === en)) {
        var pd_2 = (_co.formDirtinessChanged($event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.onFieldChange($event) !== false);
        ad = (pd_3 && ad);
    } if (("formSelected" === en)) {
        var pd_4 = (_co.formSelected($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i4.View_DynamicFormGroupComponent_0, i4.RenderType_DynamicFormGroupComponent)), i1.ɵdid(3, 4964352, null, 0, i5.DynamicFormGroupComponent, [i6.ElementHelperService, i7.DataModelHelperService, i8.FormRestService, i9.CatalogService, i10.FieldValueHandlerService, i11.ReplacerService, i12.NavigationHelperService, i13.FileHandlerService, i14.Router, i15.JourneyHandlerService, i14.ActivatedRoute], { forceFormValidation: [0, "forceFormValidation"], fieldsDistribution: [1, "fieldsDistribution"], config: [2, "config"], data: [3, "data"], editMode: [4, "editMode"], disabled: [5, "disabled"], externalEvent: [6, "externalEvent"] }, { isValid: "isValid", dirty: "dirty", change: "change", bubbledEvent: "bubbledEvent", formSelected: "formSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.forceFormValidation; var currVal_1 = _co.fieldsDistribution; var currVal_2 = _v.parent.context.$implicit; var currVal_3 = _co.data; var currVal_4 = _co.detailCode; var currVal_5 = _co.disabledFields; var currVal_6 = _co.externalEvent; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_DynamicFormGroupContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormGroupContainerComponent_3)), i1.ɵdid(2, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkGroupVisibility(_v.context.$implicit, _co.data); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DynamicFormGroupContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormGroupContainerComponent_1)), i1.ɵdid(1, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicFormGroupContainerComponent_2)), i1.ɵdid(3, 278528, null, 0, i16.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fieldsLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getGroups(); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_DynamicFormGroupContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dynamic-form-group-container", [], null, null, null, View_DynamicFormGroupContainerComponent_0, RenderType_DynamicFormGroupContainerComponent)), i1.ɵdid(1, 770048, null, 0, i17.DynamicFormGroupContainerComponent, [i9.CatalogService, i7.DataModelHelperService, i10.FieldValueHandlerService, i18.DashboardHandlerService, i19.DetailViewHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicFormGroupContainerComponentNgFactory = i1.ɵccf("app-dynamic-form-group-container", i17.DynamicFormGroupContainerComponent, View_DynamicFormGroupContainerComponent_Host_0, { forceFormValidation: "forceFormValidation", fieldsDistribution: "fieldsDistribution", detailCode: "detailCode", groups: "groups", disabled: "disabled", data: "data", entityName: "entityName" }, { isValid: "isValid", dirty: "dirty", updateDynamicGroups: "updateDynamicGroups" }, []);
export { DynamicFormGroupContainerComponentNgFactory as DynamicFormGroupContainerComponentNgFactory };
