import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { CacheService } from 'ngx-emerios-all';

export interface RestCacheModel {
  url: string;
  body: any;
  creationTime: number;
  expirationTime: number;
}

@Injectable({
  providedIn: 'root'
})
export class RestCacheService {

  private _defaultExpirationTime: number = 60 * 60 * 1000; // 60 minutes
  private _defaultCacheKey = 'rest-cache';

  constructor(private _cacheService: CacheService) { }

  public getFromCache(fullUrl: string, cacheKey?: string) {
    const found = this._findByUrl(fullUrl, cacheKey);

    if (found) {
      const currentTime = Date.now();
      if (currentTime <= found.expirationTime) {
        return found.body;
      }
    }
    return undefined;
  }

  public setOrUpdateResponse(response: HttpResponse<unknown>, expirationTime?: number) {
    const found = this._findByUrl(response.url);

    if (found) {
      this._updateEntry(response, expirationTime);
    } else {
      this._createNewEntry(response, expirationTime);
    }
  }

  private _createNewEntry(response: HttpResponse<unknown>, expirationTime?: number, cacheKey?: string) {
    const urls = this._cacheService.get(this._getCacheKey(cacheKey)) as Array<RestCacheModel> || [];

    const obj = {
      url: response.url,
      body: response.body,
      creationTime: Date.now(),
      expirationTime: Date.now() + (expirationTime || this._defaultExpirationTime)
    } as RestCacheModel;

    urls.push(obj);

    this._cacheService.set(this._getCacheKey(cacheKey), urls);
  }

  private _updateEntry(response: HttpResponse<unknown>, expirationTime?: number, cacheKey?: string) {
    const urls = this._cacheService.get(this._getCacheKey(cacheKey)) as Array<RestCacheModel>;
    const found = urls ? urls.find(x => x.url === response.url) : undefined;

    found.body = response.body;
    found.creationTime = Date.now();
    found.expirationTime = Date.now() + (expirationTime || this._defaultExpirationTime);

    this._cacheService.set(this._getCacheKey(cacheKey), urls);
  }

  private _findByUrl(url: string, cacheKey?: string) {
    const urls = this._cacheService.get(this._getCacheKey(cacheKey)) as Array<RestCacheModel>;
    const found = urls ? urls.find(x => x.url && x.url.includes(url)) : undefined;

    return found;
  }

  private _getCacheKey(key: string) {
    return key || this._defaultCacheKey;
  }
}
