import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ElementResponse } from 'private_modules/ngx-emerios-all/ngx-emerios-all';
import { Subscription } from 'rxjs';
import { PickerType } from 'src/app/models/form-field-definition.models';

export const DATE_TIME_PICKER_CONTROL_VALUE_ACCESOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DateTimePickerComponent),
  multi: true
};
export interface DateTimePickerComponentViewModel {
  showInputRequiredIcon: boolean;
  pickerType: PickerType;
  minDate: Date;
}

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.sass'],
  providers: [DATE_TIME_PICKER_CONTROL_VALUE_ACCESOR]
})
export class DateTimePickerComponent implements OnChanges, ControlValueAccessor {
  @Input() public config: any;
  @Input() public forceValidation: EventEmitter<any>;
  @Input() public pickerType: PickerType;

  @Output("is-valid") public isValid: EventEmitter<ElementResponse> = new EventEmitter();

  public model = { pickerType: 'calendar' } as DateTimePickerComponentViewModel;
  public form: FormGroup;
  public dateTimeControl: FormControl;

  private _onChange: any;
  private _subscriptions: Array<Subscription> = [];

  writeValue(value: any): void {
    if (this.form && this.form.controls['datetime'] && value) {
      const key = Object.keys(value)[0];
      const dateTimeValue = new Date(value[key])
      this.form.controls['datetime'].setValue(dateTimeValue);
      this._updateValueToForm(dateTimeValue);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void { }
  setDisabledState?(isDisabled: boolean): void { }

  constructor() { }

  public getErrorMessage() {
    let errorMessage: string;

    if (this.dateTimeControl.errors) {
      const errors = Object['keys'](this.dateTimeControl.errors)

      errors.forEach(error =>
        errorMessage = this.config.validationMessages && this.config.validationMessages[error] || '');
    }

    return errorMessage;
  }

  public preventTyping(event: KeyboardEvent) {
    event.preventDefault();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      this._initializeForm();
    }

    if (changes.forceValidation && changes.forceValidation.currentValue && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.dateTimeControl.markAsDirty();
          this.dateTimeControl.markAsTouched();
          this.dateTimeControl.updateValueAndValidity();
        }));
    }
  }

  private _initializeForm() {
    const isRequired = this.config.validators.find(x => x.name === 'required');

    if (isRequired) {
      this.dateTimeControl = new FormControl(undefined, Validators.required);
    } else {
      this.dateTimeControl = new FormControl(undefined);
    }

    if (this.config.validators) {
      setTimeout(() => {
        this.dateTimeControl.setValidators(this.config.validators);
        this.model.showInputRequiredIcon = this.config.validationMessages && this.config.validationMessages.required != undefined;
      });
    }

    this._createFormGroup();
    this._setPickerType();
    this._setMinDate();
    this._registerValueChangesSubscription();
  }


  private _createFormGroup() {
    this.form = new FormGroup({
      datetime: new FormControl(undefined, this.config.validators)
    });
  }

  private _setPickerType() {
    if (this.pickerType) {
      this.model.pickerType = this.pickerType;
    }
  }

  private _setMinDate() {
    this.model.minDate = this.model.pickerType === 'both' ? new Date() : new Date(new Date().setHours(0, 0, 0));
  }

  private _registerValueChangesSubscription() {
    this._subscriptions.push(this.form.valueChanges
      .subscribe(value => {
        this.dateTimeControl.setValue(value);
        this._updateValueToForm(value);
      }));
  }

  private _updateValueToForm(value: any) {
    if (this._onChange) {
      this._onChange(value);
    }
  }

}
