import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { FieldAttributes, FieldDefinition } from 'src/app/models/form-field-definition.models';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { Subscription } from 'rxjs';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { Entity } from 'src/app/models/entity.model';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { BaseCatalogItem } from 'src/app/models/catalog.models';
import { WaitingLoaderService, LoadingBarTarget } from 'src/app/services/behavior/waiting-loader/waiting-loader.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';

@Component({
  selector: 'app-clone-entity',
  templateUrl: './clone-entity.component.html',
  styleUrls: ['./clone-entity.component.sass']
})

export class CloneEntityComponent implements OnInit, OnDestroy {
  public config: any = {};
  public form: FormGroup;
  public title: string;
  public callbackRegularClone: Function;
  public callbackDeepClone: Function;
  public callbackFlowClone: Function;
  public instanceCode: string;
  public entityType: string
  public entityRoles: Array<any>
  public textMirrorData: { from: string, isCloneModal: boolean };
  public requestItemList = [
    { id: 1, name: 'Products' },
    { id: 2, name: 'Flows' },
    { id: 3, name: 'Campaign' },
    { id: 4, name: 'Contract Templates' },
    { id: 5, name: 'Product Providers' },
    { id: 6, name: 'Questions' },
    { id: 7, name: 'Geography Grouping' },
    { id: 8, name: 'Assests' },
    { id: 9, name: 'Approval Flow' },
    { id: 10, name: 'Replacers' },
    { id: 11, name: 'Forms' }
  ];

  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _modal: BsModalRef,
    private _elementHelper: ElementHelperService,
    private _catalog: CatalogService,
    private _waitingLoader: WaitingLoaderService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _authHandler: AuthorizationHandlerService) { }

  public isMultiLang() {
    return ['product', 'feature'].includes(this.entityType);
  }

  public isPersonEntity() {
    return this.entityType === 'person';
  }

  public isOrganizationEntity() {
    return this.entityType === 'organization';
  }

  public isTenant() {
    return this.isOrganizationEntity()
      && this.entityRoles && this.entityRoles.find(er => er.code === 'PR.ORG.TNT');
  }

  public isFlow() {
    return this.entityType === 'flow';
  }

  public isRegularEntity() {
    return !this.isTenant() && !this.isFlow();
  }

  public closeModal() {
    this._modal.hide();
  }

  public cloneEntity() {
    const payload = this._getPayload();
    this._waitingLoader.showLoadingBar(LoadingBarTarget.DetailView, true);

    if (this.isTenant() && this.callbackDeepClone) {
      this.callbackDeepClone(payload);
    }

    if (this.isFlow() && this.callbackFlowClone) {
      this.callbackFlowClone(payload);
    }

    if (this.isRegularEntity() && this.callbackRegularClone) {
      this.callbackRegularClone(payload);
    }

    this.closeModal();
  }

  ngOnInit() {
    this._prepareConfigurations();
    this._loadRequestCatalog();
    this._configFormGroup();
    this._addValueChangesSubscription();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _prepareConfigurations() {
    if (this.isPersonEntity()) {
      this._setConfigForPerson();
    } else if (this.isTenant()) {
      this._setConfigForTenant();
    } else {
      this._setConfigDefault();
    }
  }

  private _setConfigForPerson() {
    this.config.firstName = this._getNameElementConfig('firstName');
    this.config.lastName = this._getNameElementConfig('lastName');
    this.config.code = this._getCodeElementConfig();
  }

  private _setConfigForTenant() {
    this.config.instanceName = this._getNameElementConfig('instanceName');
    this.config.code = this._getCodeElementConfig();
    this.config.instanceDescription = this._getDescriptionElementConfig();
    this.config.email = this._getEmailElementConfig();
    this.config.request = this._getRequestElementConfig();
    this.config.requestItems = this._getItemsforRequest();
  }

  private _setConfigDefault() {
    if (this.isMultiLang()) {
      this.config.nameTranslations = this._getInputLangElementConfig('nameTranslations', true);
      this.config.descriptionTranslations = this._getInputLangElementConfig('descriptionTranslations', false);
    } else {
      this.config.instanceName = this._getNameElementConfig('instanceName');
      this.config.instanceDescription = this._getDescriptionElementConfig();
    }

    this.config.code = this._getCodeElementConfig();
  }

  private _configFormGroup() {
    this.form = new FormGroup({});

    Object.keys(this.config).forEach(propName => {
      if (propName === 'requestItems') {
        this._createItemsForRequest(propName);
      } else {
        this.form.addControl(propName, new FormControl(null, this.config[propName].validators));
      }
    });
  }

  private _createItemsForRequest(propName: string) {
    this.form.addControl(propName, new FormArray([]));

    this.config[propName].items.forEach(() => {
      const checkBoxControl = new FormControl();
      (this.form.controls[propName] as FormArray).push(checkBoxControl);
    });
  }

  private _addValueChangesSubscription() {
    Object.keys(this.form.controls).forEach(fieldName => {
      this._subscriptions.push(this.form.controls[fieldName].valueChanges
        .subscribe(value => {
          this._informToValueHandler(fieldName, value);
        }));
    });
  }

  private _informToValueHandler(fieldName: string, value: string) {
    if (fieldName === this.textMirrorData.from) {
      const fieldDefinition = { value: value } as FieldDefinition;
      this._fieldValueHandler.setAnyValue(fieldName, fieldDefinition);
    }
  }

  private _getPropertyValues(prop: any) {
    return Object.keys(prop)
      .map(key => {
        return {
          languageCode: key,
          text: prop[key]
        }
      })
  }

  private _getNameElementConfig(name: string) {
    const attr = {
      name: name,
      editable: true,
      required: true
    } as FieldAttributes;

    return this._elementHelper.getInputConfig(attr);
  }

  private _getCodeElementConfig() {
    const attr = {
      name: 'code',
      editable: true,
      required: true,
    } as FieldAttributes;

    this.textMirrorData = {
      from: this.isMultiLang() ? 'nameTranslations' : 'instanceName',
      isCloneModal: true
    };
    return this._elementHelper.getInputConfig(attr);
  }

  private _getDescriptionElementConfig() {
    const attr = {
      name: 'instanceDescription',
      editable: true
    } as FieldAttributes;

    return this._elementHelper.getInputConfig(attr);
  }

  private _getEmailElementConfig(): any {
    const attr = {
      name: 'email',
      editable: true,
      required: true
    } as FieldAttributes;

    return this._elementHelper.getInputConfig(attr);
  }

  private _getInputLangElementConfig(name: string, required: boolean) {
    const attr = {
      name: name,
      editable: true,
      required: required
    } as FieldAttributes;

    this._getCustomCatalog(name, CatalogEnum.Language);

    return this._elementHelper.getInputLangConfig(attr);
  }

  private _getRequestElementConfig(): any {
    const attr = {
      name: 'request',
      editable: true,
      required: true
    } as FieldAttributes;

    const config = this._elementHelper.getMultiSelectConfig(attr, []);
    config.singleSelect = true;

    return config;
  }

  private _getItemsforRequest(): any {
    const config = {
      name: 'requestItems',
      items: this.requestItemList
    };

    return config;
  }

  private _getCustomCatalog(name: string, catalogCode: string) {
    this._catalog.getFullCatalog<BaseCatalogItem>(catalogCode)
      .subscribe(catalog => {
        this.config[name].items = catalog.items;
      })
  }

  private _loadRequestCatalog() {
    if (this.isTenant()) {
      this._getCustomCatalog('request', 'REQUEST');
    }
  }

  private _getPayload() {
    const formControls = Object.assign({}, this.form.controls);

    for (var control in formControls) {
      formControls[control] = this.form.controls[control].value;
    }

    const payload = Object.assign(new Entity(), formControls, { instanceCode: this.instanceCode });

    if (this.isMultiLang()) {
      payload.nameTranslations = this._getPropertyValues(payload.nameTranslations);
      payload.descriptionTranslations = this._getPropertyValues(payload.descriptionTranslations);
    }

    if (this.isPersonEntity()) {
      payload.instanceName = `${payload.firstName} ${payload.lastName}`;
    }

    if (this.isTenant()) {
      payload.requestCode = payload.request && payload.request[0];
    }

    payload.userName = this._authHandler.getCurrentUsername();
    payload.ownerPartyRoleInstanceCode = this._authHandler.getWorkingOrganization().partyRoleInstanceCode;

    return payload;
  }
}
