import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmeriosInputModule, EmeriosTextareaModule, EmeriosDatepickerModule, EmeriosSelectModule, EmeriosMultiselectModule, NgxEmeriosDirectivesModule } from 'ngx-emerios-all';
import { InvalidFieldFocusDirective } from '../directives/invalid-field-focus/invalid-field-focus.directive';
import { ExpandLongTextDirective } from '../directives/expand-long-text/expand-long-text.directive';
import { TextareaExpanderComponent } from '../components/textarea-expander/textarea-expander.component';
import { ReplacerDirective } from '../directives/replacer/replacer.directive';
import { TextMirrorDirective } from '../directives/text-mirror/text-mirror.directive';
import { AuthorizeDirective } from '../directives/auth/authorize.directive';

const modulesToExport = [
    EmeriosSelectModule,
    EmeriosMultiselectModule,
    EmeriosDatepickerModule,
    EmeriosInputModule,
    EmeriosTextareaModule,
    NgxEmeriosDirectivesModule
];

@NgModule({
    declarations: [
        InvalidFieldFocusDirective,
        ExpandLongTextDirective,
        TextMirrorDirective,
        TextareaExpanderComponent,
        ReplacerDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        modulesToExport
    ],
    exports: [
        modulesToExport,
        InvalidFieldFocusDirective,
        ExpandLongTextDirective,
        TextMirrorDirective,
        TextareaExpanderComponent,
        ReplacerDirective
    ],
    entryComponents: [TextareaExpanderComponent]
})
export class EmeriosComponentsModule { }
