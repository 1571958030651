<div class="modal-header">
    <h3 class="modal-title pull-left" [innerHtml]="title"></h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body campaign-modal" *ngIf="config">
    <div [formGroup]="form">
        <div *ngIf="!isPersonEntity()" class="row">
            <div class="col-sm-10 col-sm-offset-1">
                <label *ngIf="!isMultiLang()" class="app-input has-label"> Name
                    <emerios-input [config]="config.instanceName" [formControlName]="config.instanceName.name">
                    </emerios-input>
                </label>
                <label *ngIf="isMultiLang()" class="app-input has-label app-input-lang"> Name
                    <app-input-lang [config]="config.nameTranslations" [formControlName]="config.nameTranslations.name"
                        [type]="'input'" [items]="config.nameTranslations.items">
                    </app-input-lang>
                </label>
            </div>
        </div>

        <div *ngIf="isPersonEntity()" class="row">
            <div class="col-sm-10 col-sm-offset-1">
                <label class="app-input has-label"> First Name
                    <emerios-input [config]="config.firstName" [formControlName]="config.firstName.name">
                    </emerios-input>
                </label>
                <label class="app-input has-label"> Last Name
                    <emerios-input [config]="config.lastName" [formControlName]="config.lastName.name">
                    </emerios-input>
                </label>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-10 col-sm-offset-1">
                <label class="app-input has-label"> Code
                    <emerios-input [config]="config.code" [formControlName]="config.code.name"
                        [appTextMirror]="textMirrorData">
                    </emerios-input>
                </label>
            </div>
        </div>

        <div *ngIf="!isPersonEntity()" class="row">
            <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
                <label *ngIf="!isMultiLang()" class="app-input has-label"> Description
                    <emerios-textarea [config]="config.instanceDescription"
                        [formControlName]="config.instanceDescription.name">
                    </emerios-textarea>
                </label>
                <label *ngIf="isMultiLang()" class="app-input has-label app-textarea-lang"> Description
                    <app-input-lang [config]="config.descriptionTranslations"
                        [formControlName]="config.descriptionTranslations.name" [type]="'textarea'"
                        [items]="config.descriptionTranslations.items">
                    </app-input-lang>
                </label>
            </div>
        </div>

        <div *ngIf="isTenant()" class="row">
            <div class="col-sm-10 col-sm-offset-1">
                <label class="app-input has-label"> E-Mail
                    <emerios-input [config]="config.email" [formControlName]="config.email.name">
                    </emerios-input>
                </label>
            </div>
        </div>

        <div *ngIf="isTenant()" class="row">
            <div class="col-sm-10 col-sm-offset-1">
                <label class="app-input has-label emerios-multiselect"> Select Request
                    <emerios-multiselect [config]="config.request" [formControlName]="config.request.name"
                        [items]="config.request.items">
                    </emerios-multiselect>
                </label>
            </div>
        </div>

        <!-- Disable for now -->
        <div *ngIf="isTenant() && false" class="row">
            <div class="col-sm-10 col-sm-offset-1">
                <label class="app-input has-label">Keep the configuration below</label>
                <label class="app-input has-label" [formArrayName]="config.requestItems.name"
                    *ngFor="let item of form.controls.requestItems.controls; index as i">
                    <input type="checkbox" [formControlName]="i">
                    {{requestItemList[i].name}}
                </label>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-next" (click)="closeModal()">Cancel</button>
    <button type="button" [disabled]="!form.valid" class="btn btn-primary btn-fill"
        (click)="cloneEntity()">Clone</button>
</div>