import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityCatalogItem } from 'src/app/models/catalog.models';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeographyRestService {

  constructor(private _rest: RestService) { }

  public getGeographyByType(type: string, search: string):
    Observable<{ type: string, items: Array<EntityCatalogItem> }> {
    const headers = new HttpHeaders()
      .append('restCache', 'true');

    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/geography/catalog-typeahead/${type}`,
      headers: headers,
      params: {
        minSearchLength: 3,
        search: search
      }
    }).pipe(map(result => { return { type: type, items: result } }));
  }

  public getZipcodesCount(codes: Array<string>) {
    return this._rest.post<EntityCatalogItem>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/geography/zipcodes/count`,
      body: codes
    });
  }

  public getZipcodes(codes: Array<string>) {
    return this._rest.post<EntityCatalogItem>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/geography/zipcodes`,
      body: codes
    });
  }

}
