import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeyValue } from 'src/app/types/keyvalue.type';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum QssProp {
  FlowInstanceCode = 'flowInstanceCode'
}

export interface QssModel {
  flowInstanceCode?: string;
}

@Injectable({
  providedIn: 'root'
})

/*
* QSS means Query String Storage
*/
export class QssHandlerService {

  public readonly value$: Observable<QssModel>;

  private readonly _queryStringStorageKey = 'qss';
  private readonly _value = new BehaviorSubject<QssModel>(undefined);

  private _qss: QssModel = {};

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    this.value$ = this._value.asObservable().pipe(filter(x => x != undefined));

    this._activatedRoute.queryParams.subscribe(params => {
      const qssRaw = params[this._queryStringStorageKey];
      if (qssRaw) {
        const qssStr = atob(qssRaw);
        const parsed = JSON.parse(qssStr);

        this._qss = parsed;

        this._value.next(this._qss);
      }
    });
  }

  public updateProp(prop: QssProp, value: any) {
    const kv = {};

    kv[prop] = value;

    this.updateProps(kv);
  }

  public updateProps(props: KeyValue) {
    Object.keys(props).forEach(prop => {
      const value = props[prop];

      if (value == undefined) {
        delete this._qss[prop];
      } else {
        this._qss[prop] = value;
      }
    });

    this.updateQss(this._qss);
  }

  public updateQss(obj: QssModel) {
    const str = JSON.stringify(obj);
    const b64 = btoa(str);
    const params = {};

    params[this._queryStringStorageKey] = b64;

    this._router.navigate([], { queryParams: params, queryParamsHandling: 'merge' });
  }
}
