import { BaseDataModel } from '../base-data-model.model';
import { FieldDefinition, InputLangDefinition } from 'src/app/models/form-field-definition.models';

export class InputLangDataModelService implements BaseDataModel {

  private _fieldDefinition: FieldDefinition;

  constructor(fieldDefinition: FieldDefinition) {
    this._fieldDefinition = fieldDefinition;
  }

  public setValueToField(payloadValue: any) {
    if (payloadValue) {
      const value = {};
      payloadValue.forEach((x: any) => value[x.languageCode] = x.text);
      return value;
    }

    return undefined;
  }

  public getValueFromField(fieldValue: any) {
    const inputField = this._fieldDefinition as InputLangDefinition;
    const value = [];

    if (fieldValue) {
      Object.keys(fieldValue)
        .forEach(langCode => {
          const translation = {};
          translation[inputField.translationPropCode] = langCode;
          translation[inputField.translationPropText] = fieldValue[langCode];
          value.push(translation);
        });
    }

    return value;
  }

}
