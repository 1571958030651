<div class="modal-header">
  <h3 class="modal-title pull-left" [innerHtml]="title"></h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body campaign-modal">
  <span [innerHtml]="message"></span>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-next" (click)="closeModal()" *ngIf="cancel">{{ cancel.text | translate}}</button>
  <button type="button" class="btn btn-primary btn-fill" (click)="okModal()">{{ ok.text | translate}}</button>
</div>