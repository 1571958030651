/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ghost-loading-aggrid.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ghost-loading-aggrid.component";
var styles_GhostLoadingAggridComponent = [i0.styles];
var RenderType_GhostLoadingAggridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GhostLoadingAggridComponent, data: {} });
export { RenderType_GhostLoadingAggridComponent as RenderType_GhostLoadingAggridComponent };
function View_GhostLoadingAggridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-xs-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "grid-header-title"]], null, null, null, null, null))], null, null); }
function View_GhostLoadingAggridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-xs-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "grid-subtitle width-75"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "grid-icon width-20"]], null, null, null, null, null))], null, null); }
function View_GhostLoadingAggridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "grid-row"]], null, null, null, null, null))], null, null); }
export function View_GhostLoadingAggridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "ghost-grid-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "ghost-grid-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "row "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_GhostLoadingAggridComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(5, 4), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_GhostLoadingAggridComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(9, 4), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "ghost-grid-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_GhostLoadingAggridComponent_3)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(14, 9)], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, 1, 2, 3, 4); _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 9, 0, 1, 2, 3, 4); _ck(_v, 8, 0, currVal_1); var currVal_2 = _ck(_v, 14, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9); _ck(_v, 13, 0, currVal_2); }, null); }
export function View_GhostLoadingAggridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading-aggrid", [], null, null, null, View_GhostLoadingAggridComponent_0, RenderType_GhostLoadingAggridComponent)), i1.ɵdid(1, 114688, null, 0, i3.GhostLoadingAggridComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GhostLoadingAggridComponentNgFactory = i1.ɵccf("app-ghost-loading-aggrid", i3.GhostLoadingAggridComponent, View_GhostLoadingAggridComponent_Host_0, {}, {}, []);
export { GhostLoadingAggridComponentNgFactory as GhostLoadingAggridComponentNgFactory };
