import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SignPadComponent } from './sign-pad/sign-pad.component';
import { TabGroupModule } from 'src/app/components/tab-group/tab-group.module';
import { FormsModule } from '@angular/forms';
import { SignatureViewerComponent } from './signature-viewer/signature-viewer.component';
import { SignatureViewerGroupComponent } from './signature-viewer-group/signature-viewer-group.component';

@NgModule({
  declarations: [
    SignPadComponent,
    SignatureViewerComponent,
    SignatureViewerGroupComponent 
  ],
  imports: [
    CommonModule,
    FormsModule,
    TabGroupModule
  ],
  exports: [
    SignPadComponent,
    SignatureViewerGroupComponent 
  ]
})
export class SignatureComponentsModule { }
