import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { UtilService } from 'src/app/services/behavior/util/util.service';
import { FieldAttributes } from 'src/app/models/form-field-definition.models';
import { MultiselectConfig } from 'ngx-emerios-all';
import { BaseCatalogItem, Catalog } from 'src/app/models/catalog.models';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';

export interface ModalParams {
  title: string;
  detailCode: string;
  callback: Function;
  childrenRelationship: boolean;
}

export interface ViewModel {
  title: string;
  formValid: boolean;
  showWarning: boolean;

  relationshipTypeAttr: FieldAttributes;
  InstanceAttr: FieldAttributes;

  relationshipTypeConfig: MultiselectConfig;
  instanceRelationshipConfig: MultiselectConfig;
}

@Component({
  selector: 'app-create-instance-relationship',
  templateUrl: './create-instance-relationship.component.html',
  styleUrls: ['./create-instance-relationship.component.sass']
})
export class CreateInstanceRelationshipComponent implements OnInit, OnDestroy {
  public params = {} as ModalParams;
  public model = {} as ViewModel;

  public form: FormGroup;

  private _subscriptions: Array<Subscription> = [];
  private _ownerPartyRole: string;
  private _multipleRelationships: boolean;
  private _libraryCode: string;

  constructor(
    private _catalog: CatalogService,
    private _utils: UtilService,
    private _elementHelper: ElementHelperService,
    private _modal: BsModalRef,
    private _fieldValueHandler: FieldValueHandlerService) { }

  public okModal() {
    const instanceToValues: Array<string> = this.form.controls[this.model.instanceRelationshipConfig.name].value;
    let payload: any;

    if (this._multipleRelationships) {
      payload = this._handleMultipleRelationships(instanceToValues);
    } else {
      payload = this._handleSingleRelationships(instanceToValues[0]);
    }

    this._modal.hide();
    if (this.params.callback) {
      this.params.callback(payload);
    }
  }

  public closeModal() {
    this._modal.hide();
  }

  public onRelationshipTypeItemChecked(event: any) {
    if (event.selected) {
      const relatedCatalog = event.item.relatedCatalog;

      this.model.instanceRelationshipConfig.items = [];
      this.form.controls[this.model.instanceRelationshipConfig.name].setValue(undefined);

      if (relatedCatalog && !this._multipleRelationships) {
        this._catalog.getFromServer([relatedCatalog])
          .subscribe(catalog => {
            this.model.instanceRelationshipConfig.items = catalog[0].items;
          });
      } else {
        let catalog;
        let libraryCodeRel;

        if (this.params.childrenRelationship) {
          catalog = event.item.libraryCodeTo === CatalogEnum.Product
            ? CatalogEnum.ProductInstanceByLibraryCode
            : CatalogEnum.InstanceByLibraryCode;
          libraryCodeRel = event.item.libraryCodeTo;
        } else {
          catalog = CatalogEnum.BinaryInstanceByLibraryCode;
          libraryCodeRel = event.item.libraryCodeFrom;
        }

        this._catalog.getFiltrableCatalog<BaseCatalogItem>(catalog, [libraryCodeRel, this._ownerPartyRole])
          .subscribe(catalog => {
            this.model.instanceRelationshipConfig.items = catalog.items
              .map(item => {
                item.description = item['description2'];
                return item;
              });
          });
      }
    } else {
      this.model.instanceRelationshipConfig.items = [];
    }
  }

  ngOnInit() {
    this._setInitialData();
    this._configureElements();
    this._getRelationshipTypeCatalog();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _setInitialData() {
    this.model.title = this.params.title;
    this._libraryCode = this._utils.getLibraryCodeFromInstance(this.params.detailCode);
  }

  private _configureElements() {
    this.model.relationshipTypeAttr = {
      label: 'Relationship Type',
      name: 'relationshipType',
      required: true,
      editable: true
    } as FieldAttributes;

    this.model.InstanceAttr = {
      label: this.params.childrenRelationship ? 'Instance To' : 'Instance From',
      name: 'instanceTo',
      required: true,
      editable: true
    } as FieldAttributes;

    this._multipleRelationships = this._isMultipleRelationship();

    this.model.relationshipTypeConfig = this._elementHelper
      .getMultiSelectConfig(this.model.relationshipTypeAttr);
    this.model.relationshipTypeConfig.singleSelect = true;

    this.model.instanceRelationshipConfig = this._elementHelper
      .getMultiSelectConfig(this.model.InstanceAttr);
    this.model.instanceRelationshipConfig.singleSelect = !this._multipleRelationships;
  }

  private _isMultipleRelationship(): boolean {
    return this.params.detailCode.includes(".BK.") || !this.params.childrenRelationship;
  }

  private _getRelationshipTypeCatalog() {
    let observable: Observable<Catalog<BaseCatalogItem>>;

    if (this.params.childrenRelationship) {
      observable = this._catalog.getFiltrableCatalog<BaseCatalogItem>(CatalogEnum.RelationshipTypes, [this._libraryCode]);
    } else {
      observable = this._catalog.getFiltrableCatalog<BaseCatalogItem>(CatalogEnum.ParentRelationshipTypes, [this._libraryCode]);
    }

    observable.subscribe(catalog => {
      this.model.relationshipTypeConfig.items = catalog.items;
      this._configureForm();
    });
  }

  private _configureForm() {
    const form = {};

    form[this.model.relationshipTypeConfig.name] = new FormControl(undefined, Validators.required);
    form[this.model.instanceRelationshipConfig.name] = new FormControl(undefined, Validators.required);

    this.form = new FormGroup(form);

    this._subscriptions.push(
      this.form.statusChanges
        .subscribe(status => this.model.formValid = status === 'VALID')
    );

    this._subscriptions.push(
      this._fieldValueHandler.ownerPartyRoleValue$
        .subscribe(owner => this._ownerPartyRole = owner)
    );
  }

  private _handleSingleRelationships(instanceTo: string) {
    return {
      instanceFromCode: this.params.detailCode,
      instanceToCode: instanceTo
    }
  }

  private _handleMultipleRelationships(instanceToValues: Array<string>) {
    return instanceToValues.map(value => {
      if (this.params.childrenRelationship) {
        return {
          instanceFromCode: this.params.detailCode,
          instanceToCode: value
        }
      } else {
        return {
          instanceFromCode: value,
          instanceToCode: this.params.detailCode
        }
      }
    });
  }

}
