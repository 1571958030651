import { OnInit, EventEmitter, OnChanges, SimpleChanges, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, Validators, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { FileRestService } from 'src/app/services/rest/file-rest/file-rest.service';
import { DomSanitizer } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';
import { JourneyHandlerService } from 'src/app/services/handler/journey-handler/journey-handler.service';
const noOp = () => { };
const ɵ0 = noOp;
export const CUSTOM_FILEUPLOAD_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true
};
export var MimeType;
(function (MimeType) {
    MimeType["Application"] = "application/*";
    MimeType["Image"] = "image/*";
    MimeType["Audio"] = "audio/*";
})(MimeType || (MimeType = {}));
export function LangSelectionValidator(fn) {
    return (control) => {
        if (!fn()) {
            return { 'invalidLangSelection': true };
        }
        return null;
    };
}
export class FileUploadComponent {
    constructor(_catalog, _elementHelper, _authHandler, _fileRest, _sanitizer, _journeyHandler) {
        this._catalog = _catalog;
        this._elementHelper = _elementHelper;
        this._authHandler = _authHandler;
        this._fileRest = _fileRest;
        this._sanitizer = _sanitizer;
        this._journeyHandler = _journeyHandler;
        this.isValid = new EventEmitter();
        this.fileEvent = new EventEmitter();
        this.model = {
            availableImageExtensions: ['.bmp', '.gif', '.jpeg', '.jpg', '.png'],
            availableDocumentExtensions: ['.pdf', '.xlsx'],
            availableAudioExtensions: ['.mp3', '.wav', '.aiff']
        };
        this._defaultImageThumbnail = './assets/img/image-thumbnail.jpg';
        this._multiselectConfiguration = {};
        this._subscriptions = [];
        this._journeyActivity = 'Out';
        this.onTouchedCallback = noOp;
    }
    writeValue(obj) {
        if (obj != this.control.value) {
            this.internalModel = obj;
            this._reconfigureFormGroup();
            this._setControlValue();
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    readonly() {
        if (this._journeyActivity === 'In') {
            return false;
        }
        else {
            return this.config && !this.config.attributes.editable;
        }
    }
    showTable() {
        return this.form && this.config && !this.config.fileUploadConfig.hideAttachmentBox;
    }
    dropped(files) {
        const filteredFiles = files
            .filter(x => x.fileEntry.isFile)
            .filter(x => this._validateExtension(x.relativePath));
        this.internalModel = {};
        filteredFiles.forEach(file => {
            let fileName = file.relativePath.replace(/ /g, '_').toLocaleLowerCase();
            this.internalModel[fileName] = {
                fileName: fileName,
                userName: this._authHandler.getCurrentUsername(),
                fileEntry: file.fileEntry
            };
        });
        this._reconfigureFormGroup();
        this._updateInternalModel();
        this._revalidateControl();
    }
    removeFile(file, index) {
        delete this.internalModel[file.fileName];
        this.form.removeControl(file.fileName);
        this._updateInternalModel();
        this._triggerEvent('file-removed', file.fileName);
    }
    getMultiselectConfig(code) {
        if (!this._multiselectConfiguration[code]) {
            const attrs = {
                name: code,
                placeholder: '-- Select Language --',
                required: true,
                editable: true
            };
            this._multiselectConfiguration[code] = this._elementHelper.getMultiSelectConfig(attrs, []);
            this._multiselectConfiguration[code].readonly = !this.config.attributes.editable;
            this._multiselectConfiguration[code].enableCheckAll = true;
            this._multiselectConfiguration[code].allowSearchFilter = false;
        }
        return this._multiselectConfiguration[code];
    }
    getItemsForTable() {
        const items = Object.keys(this.internalModel || [])
            .map(x => this.internalModel[x]);
        return items;
    }
    openFile(file) {
        if (file.fileEntry) {
            file.fileEntry.file(fileEntryFile => {
                this._showFile(fileEntryFile, file.fileName);
            });
        }
        else {
            this._fileRest.downloadFile(file.fileStorageReference)
                .subscribe(blob => this._showFile(blob, file.fileName));
        }
    }
    getImageThumbnail(item) {
        return (item.contentType && !(item.contentType.includes('pdf') || item.contentType.includes('mp3')) && item.base64ForThumbnail)
            || this._defaultImageThumbnail;
    }
    getImage(item) {
        if (item.contentType && item.contentType.startsWith('image')) {
            if (item.base64ForThumbnail) {
                return item.base64ForThumbnail.changingThisBreaksApplicationSecurity;
            }
        }
    }
    getControlErrors() {
        let errorMessage;
        if (this.control.errors) {
            if (this.control.errors.required) {
                errorMessage = 'This is required';
            }
            else if (this.control.errors.invalidLangSelection) {
                errorMessage = `You can only upload as much images as total languages you have. 
        (${this.model.langItems.length}) Or upload only 1 file with all languages selected.`;
            }
        }
        return errorMessage;
    }
    multiselectChanged() {
        this.control.markAsDirty();
    }
    languageSettingAvailable() {
        return this.config.fileUploadConfig.languageEnabled;
    }
    ngOnInit() {
        this._subscriptions.push(this._journeyHandler.journeyActivity$
            .subscribe(journeyAction => this._journeyActivity = journeyAction === 'Start' ? 'In' : 'Out'));
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            this._initializeFormControl();
            this._configureComponent();
        }
        if (changes.forceValidation && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => this._revalidateControl()));
        }
        this.dateTime = new Date().toLocaleString();
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
        if (this._formSubscription) {
            this._formSubscription.unsubscribe();
        }
    }
    _initializeFormControl() {
        if (this.config.attributes.required && this.config.fileUploadConfig.languageEnabled) {
            this.control = new FormControl(undefined, [Validators.required, LangSelectionValidator(() => this._isValidSelection())]);
        }
        else {
            this.control = new FormControl(undefined);
        }
    }
    _configureComponent() {
        this._catalog.getFullCatalog(CatalogEnum.Language)
            .subscribe(catalog => {
            this.model.langItems = catalog.items;
            if (this.form) {
                Object.keys(this.form.controls).forEach(fileControl => {
                    const value = this.form.controls[fileControl].value;
                    if (value == undefined) {
                        const newValue = this.model.langItems.map(x => x.code);
                        this.form.controls[fileControl].setValue(newValue);
                    }
                });
            }
        });
        this.model.showPreview = this.config.fileUploadConfig.showPreview;
        this.model.config = {
            multiple: this.config.fileUploadConfig.multiple,
            accept: this.config.fileUploadConfig.accept.join()
        };
    }
    _revalidateControl() {
        this.control.markAsDirty();
        this.control.markAsTouched();
        this.control.updateValueAndValidity();
    }
    _showFile(blob, fileName) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else {
            var url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    }
    _reconfigureFormGroup() {
        const form = {};
        Object.keys(this.internalModel)
            .forEach(prop => {
            const file = this.internalModel[prop];
            const value = file.languageCode ? [file.languageCode] : undefined;
            form[file.fileName] = new FormControl(value, Validators.required);
        });
        this.form = new FormGroup(form);
        if (this._formSubscription) {
            this._formSubscription.unsubscribe();
        }
        this._formSubscription = this.form.valueChanges
            .subscribe(value => this._updateInternalModel());
        // this._emitValidity();
    }
    _validateExtension(relativePath) {
        const fileExtension = relativePath.substring(relativePath.lastIndexOf('.')).toLocaleLowerCase();
        const availableExtensions = this._getAvailableMimeTypes();
        return availableExtensions.includes(fileExtension);
    }
    _getAvailableMimeTypes() {
        const mimeTypes = [];
        this.config.fileUploadConfig.accept.forEach((type) => {
            switch (type) {
                case MimeType.Image:
                    mimeTypes.push(...this.model.availableImageExtensions);
                    break;
                case MimeType.Audio:
                    mimeTypes.push(...this.model.availableAudioExtensions);
                    break;
                case MimeType.Application:
                    mimeTypes.push(...this.model.availableDocumentExtensions);
                    break;
                default:
                    mimeTypes.push(type);
                    break;
            }
        });
        return mimeTypes;
    }
    _isValidSelection() {
        if (!this.control) {
            return false;
        }
        const files = this.control.value;
        let isValid = true;
        if (files && this.model && this.model.langItems) {
            const langQty = this.model.langItems.length;
            const fileQty = Object.keys(files).length;
            // es valido cuando:
            // hay 1 file cargado con todos los idiomas seleccionados.
            if (fileQty === 1) {
                const fileName = Object.keys(this.internalModel)[0];
                const langSelected = this.form.controls[fileName].value || [];
                if (langSelected.length !== langQty) {
                    isValid = false;
                }
            }
            else {
                // o 1 file por idioma
                let selectedLangs = new Set();
                Object.keys(this.form.controls).forEach(prop => {
                    const value = this.form.controls[prop].value;
                    if (value && value.length === 1) {
                        selectedLangs.add(value[0]);
                    }
                });
                if (selectedLangs.size !== fileQty) {
                    isValid = false;
                }
            }
        }
        return isValid;
    }
    _updateInternalModel() {
        this._setControlValue();
        Object.keys(this.internalModel)
            .forEach(prop => {
            const file = this.internalModel[prop];
            const reader = new FileReader();
            const value = this.form.controls[file.fileName].value || [];
            if (value.length === this.model.langItems.length) {
                this.internalModel[file.fileName].languageCode = undefined;
            }
            else {
                this.internalModel[file.fileName].languageCode = value[0];
            }
            if (file.fileEntry) {
                file.fileEntry.file(fileEntryFile => {
                    reader.readAsDataURL(fileEntryFile);
                    reader.onload = () => {
                        const result = reader.result.toString();
                        const base64 = result.substring(result.indexOf(',') + 1);
                        this.internalModel[file.fileName].contentType = fileEntryFile.type;
                        this.internalModel[file.fileName].base64 = base64;
                        this._emitFileData(base64);
                    };
                });
            }
        });
        this._populateBase64ForItems();
        if (this._onChange) {
            this._onChange(this.control.valid ? this.internalModel : undefined);
        }
    }
    _emitFileData(base64) {
        const fileDataArray = [];
        for (let fileName in this.internalModel) {
            fileDataArray.push({
                fileName: fileName,
                base64: base64
            });
        }
        this._triggerEvent('file-dropped', fileDataArray);
    }
    _triggerEvent(key, value) {
        const keyValueObj = { [key]: value };
        this.fileEvent.emit(keyValueObj);
    }
    _setControlValue() {
        if (!this.internalModel || Object.keys(this.internalModel).length === 0) {
            this.control.setValue(undefined);
        }
        else {
            this.control.setValue(this.internalModel);
        }
    }
    _populateBase64ForItems() {
        Object.keys(this.internalModel).forEach(fileName => {
            const file = this.internalModel[fileName];
            if (file.fileEntry) {
                file.fileEntry.file(fileEntryFile => {
                    this._getBase64FromBlob(file, fileEntryFile);
                });
            }
            else {
                this._fileRest.downloadFile(file.fileStorageReference, true)
                    .pipe(catchError(() => of(undefined)))
                    .subscribe(blob => this._getBase64FromBlob(file, blob));
            }
        });
    }
    _getBase64FromBlob(file, blob) {
        if (blob) {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                file.base64ForThumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
            };
        }
    }
}
export { ɵ0 };
