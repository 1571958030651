import { BrowserModule } from '@angular/platform-browser';
import { NgModule, SystemJsNgModuleLoader, NgModuleFactoryLoader } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from 'src/environments/environment';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { setTheme } from 'ngx-bootstrap/utils';
import { HttpClientModule } from '@angular/common/http';
import { PageFooterComponent } from './components/page-footer/page-footer.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxMaskModule } from 'ngx-mask';
import { RestService } from 'ngx-emerios-all';

import { GlobalInterceptorProvider } from './interceptors/global-interceptor';

import { PageHeaderComponent } from './components/page-header/page-header.component';
import { CustomHttpLoaderFactory } from './services/rest/translate-loader/translate-loader.service';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ToastrModule } from 'ngx-toastr';

import { AuthInterceptorProvider } from './interceptors/auth-interceptor';

import { LoadersModule } from './components/loading/loaders.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { EmeriosComponentsModule } from './modules/emerios-components.module';
import { ModalComponentsModule } from './modules/modal-components.module';

import 'ag-grid-enterprise';
import { AuthorizeDirective } from './directives/auth/authorize.directive';

@NgModule({
  declarations: [
    AppComponent,
    PageHeaderComponent,
    PageFooterComponent,
    AuthorizeDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    EmeriosComponentsModule,
    LoadersModule,
    ModalComponentsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-button-action',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CustomHttpLoaderFactory,
        deps: [RestService]
      }
    })
  ],
  providers: [
    GlobalInterceptorProvider,
    AuthInterceptorProvider,
    environment.devProviders,
    { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    setTheme('bs3');
  }

}
