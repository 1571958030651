/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signature-viewer.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./signature-viewer.component";
var styles_SignatureViewerComponent = [i0.styles];
var RenderType_SignatureViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignatureViewerComponent, data: {} });
export { RenderType_SignatureViewerComponent as RenderType_SignatureViewerComponent };
export function View_SignatureViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-xs-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "col-xs-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["class", "img-responsive"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "col-xs-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.date; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.hour; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.signatureUrl; _ck(_v, 9, 0, currVal_3); }); }
export function View_SignatureViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signature-viewer", [], null, null, null, View_SignatureViewerComponent_0, RenderType_SignatureViewerComponent)), i1.ɵdid(1, 114688, null, 0, i2.SignatureViewerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureViewerComponentNgFactory = i1.ɵccf("app-signature-viewer", i2.SignatureViewerComponent, View_SignatureViewerComponent_Host_0, { userName: "user-mame", stepName: "step-name", stepType: "step-type", icon: "icon", signature: "signature", dateTime: "datetime" }, {}, []);
export { SignatureViewerComponentNgFactory as SignatureViewerComponentNgFactory };
