import { Injectable } from '@angular/core';
import { PageLoaderService } from 'ngx-emerios-all';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum LoadingBarTarget {
  Login, Dashboard, TenantSelector, DetailView, BackgroundTask
}

export interface LoadingBarTargetModel {
  target: LoadingBarTarget;
  show: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class WaitingLoaderService {
  public readonly loadingBar$: Observable<LoadingBarTargetModel | boolean>;

  private readonly _loadingBar = new BehaviorSubject<LoadingBarTargetModel | boolean>(undefined);

  private _loadingBarMaxWaitingTime = 2500;
  private _timeoutHandler: any;

  constructor(private _pageLoader: PageLoaderService) {
    this.loadingBar$ = this._loadingBar.asObservable().pipe(filter(x => x != undefined));
  }

  public showLoadingBar(target: LoadingBarTarget, fallbackToFullscreen?: boolean) {
    this._clearTimeout();

    this._loadingBar.next({ target: target, show: true });

    if (fallbackToFullscreen) {
      this._timeoutHandler = setTimeout(() => {
        this._pageLoader.show();
      }, this._loadingBarMaxWaitingTime);
    }
  }

  public hideLoadingBar() {
    this._clearTimeout();
    this._loadingBar.next(false);
  }

  public showFullScreenLoading() {
    this._pageLoader.show();
  }

  public hideFullScreenLoading() {
    this._clearTimeout();
    this._pageLoader.hide();
  }

  public hide() {
    this._clearTimeout();
    this._loadingBar.next(false);
    this._pageLoader.hide();
  }

  private _clearTimeout() {
    if (this._timeoutHandler) {
      clearTimeout(this._timeoutHandler);
    }
  }
}
