import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, forwardRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ElementResponse, ElementConfig, MultiselectConfig } from 'ngx-emerios-all';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { FieldDefinition, FieldAttributes } from 'src/app/models/form-field-definition.models';
import { KeyValue } from 'src/app/types/keyvalue.type';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { deepClone } from 'src/app/functions/deepClone';
import { EntityCatalogItem, EntityCatalog } from 'src/app/models/catalog.models';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { SortableItemDefinition } from './sortable-list.models';

const noOp = () => { };

export const SORTABLE_LIST_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SortableListComponent),
  multi: true
};

export enum FieldDefinitionTypeEnum {
  InputText = 'input-text',
  InputLang = 'input-lang',
  TextAreaLang = 'textarea-lang',
  CatalogSingle = 'catalog-single',
  Checkbox = 'checkbox',
  Sort = 'sort'
}

@Component({
  selector: 'app-sortable-list',
  templateUrl: './sortable-list.component.html',
  styleUrls: ['./sortable-list.component.css'],
  providers: [SORTABLE_LIST_CONTROL_VALUE_ACCESSOR]
})
export class SortableListComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() public config: FieldDefinition;
  @Output() public items: EventEmitter<Array<any>> = new EventEmitter();

  public internalItems: Array<any> = [];
  public elementConfig: KeyValue = {}
  public questionName: string;
  public partList: Array<string> = [];

  private _elementsValidity: any;
  private _onChange: any;

  writeValue(obj: any): void {
    if (obj != this.internalItems) {
      this.internalItems = obj || [];

      if (this.internalItems.length === 0) {
        this.addElement();
      } else {
        this.internalItems.forEach(item => {
          if (item.audioInstanceCode) {
            this.partList.push(`[${item.audioInstanceCode} reproduction]`);
          }
          if (item.textTranslations && item.textTranslations.length > 0) {
            this.partList.push(item.textTranslations[0].text);
          }

          item.elementList = this._initElementListFromEdit(item);
          item.elementList.forEach(e => {
            if (e.type === 'catalog-single' && item[e.field]) {
              item[e.field] = [item[e.field]];
            }
          });
        });
        this._restructureInternalItems();
      }
    }
  }

  private _initElementListFromEdit(item: any): Array<any> {
    const elementListResult: Array<any> = [];

    this.config.sortableDefinition.forEach(def => {
      if (!def.triggerBy) {
        elementListResult.push(def);
      } else {
        const trigger = item[def.triggerBy.field];
        if (def.triggerBy.codes.includes(trigger)) {
          elementListResult.push(def);
        }
      }
    });

    return elementListResult;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
  onTouchedCallback: () => void = noOp;

  constructor(
    private _elementHelper: ElementHelperService,
    private _catalog: CatalogService,
    private _fieldValueHandler: FieldValueHandlerService) { }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.internalItems, event.previousIndex, event.currentIndex);
    this._checkValidity();
  }

  public isSort(type: string) {
    return type === FieldDefinitionTypeEnum.Sort;
  }

  public isInputText(type: string) {
    return type === FieldDefinitionTypeEnum.InputText;
  }

  public isCatalogSingle(def: SortableItemDefinition) {
    return def.type === FieldDefinitionTypeEnum.CatalogSingle;
  }

  public isInputLang(type: string) {
    return type === FieldDefinitionTypeEnum.InputLang;
  }

  public isTextAreaLang(type: string) {
    return type === FieldDefinitionTypeEnum.TextAreaLang;
  }

  public isCheckbox(type: string) {
    return type === FieldDefinitionTypeEnum.Checkbox;
  }

  public isAudioOptions() {
    return this.config.field === 'audioParts';
  }

  public getColumnDistribution(type: string) {
    switch (type) {
      case FieldDefinitionTypeEnum.Sort:
        return;
      case FieldDefinitionTypeEnum.InputText:
        return 'col-md-2'
      case FieldDefinitionTypeEnum.InputLang:
        return 'col-md-7'
      case FieldDefinitionTypeEnum.TextAreaLang:
        return 'col-md-9';
      default:
        return 'col-md-3';
    }
  }

  public isElementValid(event: ElementResponse, index: number) {
    const str = index.toString(10);

    if (!this._elementsValidity[str]) {
      this._elementsValidity[str] = {};
    }
    this._elementsValidity[str][event.name] = event.valid;

    this._checkValidity();
  }

  public updatePartList(field: string, index: number) {
    const internalItem = this.internalItems[index];
    let value: string;

    if (typeof (internalItem['name']) === 'object') {
      const key = Object.keys(internalItem[field])[0];
      value = internalItem[field][key];
    }

    if (typeof (internalItem['name']) === 'string') {
      value = internalItem[field];
    }

    this.partList.push(value);
  }

  public updateTriggerable(field: string, index: number) {
    const internalItem = this.internalItems[index];
    const value = internalItem[field][0];
    const triggerableFields = this.config.sortableDefinition.filter(x => this._isTriggerableByFieldAndValue(x, field, value));

    if (triggerableFields.length > 0) {
      internalItem.elementList = this._clearTriggeralbeItemsFromElementList();
      triggerableFields.forEach(element => {
        if (!internalItem.elementList.find(x => x.field == element.field)) {
          internalItem.elementList.push(element);
        }
      });
    }
  }

  public getElementConfig(def: SortableItemDefinition) {
    if (!this.elementConfig[def.field]) {
      const attr = {
        name: def.field,
        placeholder: def.text,
        required: true,
        minlength: 2,
        maxlength: 20
      } as FieldAttributes;

      let config: ElementConfig;

      switch (def.type) {
        case FieldDefinitionTypeEnum.InputText:
          config = this._elementHelper.getInputConfig(attr);
          break;
        case FieldDefinitionTypeEnum.InputLang:
          config = this._elementHelper.getInputLangConfig(attr);
          break;
        case FieldDefinitionTypeEnum.TextAreaLang:
          config = this._elementHelper.getTextareaLangConfig(attr);
          break;
        case FieldDefinitionTypeEnum.CatalogSingle:
          config = this._elementHelper.getMultiSelectConfig(attr);
          (config as MultiselectConfig).singleSelect = true;
          break;
        default:
          console.error(`The element type ${def.type} is not available in sortable-list.components.ts`);
          break;
      }

      this.elementConfig[def.field] = config;
      if (def.catalog != null) {
        this._getCustomCatalog({ field: def.field, catalog: def.catalog } as FieldDefinition);
      }
    }
    this.elementConfig[def.field].readonly = !this.config.attributes.editable;

    return this.elementConfig[def.field];
  }

  public addElement() {
    this.internalItems.push({ elementList: this._clearTriggeralbeItemsFromElementList() });
    this._addSort();
  }

  public removeElement(index: number) {
    this.internalItems.splice(index, 1);
    delete this._elementsValidity[index.toString(10)];
    this._updateModelStructure();
  }

  ngOnInit() {
    this._fieldValueHandler.anyValue$.subscribe(x => {
      this.questionName = x.changes.get("instanceName");
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this._elementsValidity = {};
      if (!this.internalItems || this.internalItems.length === 0) {
        this.addElement();
      }
    }
  }

  private _clearTriggeralbeItemsFromElementList() {
    return this.config.sortableDefinition.filter(x => !x.triggerBy);
  }

  private _isTriggerableByFieldAndValue(item: SortableItemDefinition, field: string, value: string) {
    return item.triggerBy && item.triggerBy.field == field && item.triggerBy.codes.includes(value);
  }

  private _getCustomCatalog(input: FieldDefinition) {
    const catalog = this._catalog.get(input.catalog);

    if (catalog && catalog.length > 0) {
      this._setCustomCatalogItems(input, catalog);
    } else {
      const ownerElement = this._fieldValueHandler.getAnyValue().changes.get('ownerPartyRoleInstanceCode');
      if (ownerElement) {
        const ownerPartyRoleInstanceCode = ownerElement[0].code;
        this._catalog.getSingleWithFilterFromServer(input.catalog, [ownerPartyRoleInstanceCode])
          .subscribe(catalog => {
            this._setCustomCatalogItems(input, catalog.items);
            this._replacePartList(catalog);
          });
      }
    }
  }

  private _replacePartList(catalog: EntityCatalog) {
    const newList = [];
    this.partList.forEach(x => {
      const elt = catalog.items.find(i => x.includes(i.code));
      if (elt) {
        newList.push(x.replace(elt.code, elt.name));
      }
      else {
        newList.push(x);
      }
    });
    this.partList = newList;
  }

  private _setCustomCatalogItems(input: FieldDefinition, catalog: Array<EntityCatalogItem>) {
    input.catalogItems = catalog;
    this.elementConfig[input.field].items = catalog;
  }

  private _checkValidity() {
    let accumulator = true;

    Object.keys(this._elementsValidity)
      .forEach(itemKey => {
        this.config.sortableDefinition
          .filter(item => item.type !== FieldDefinitionTypeEnum.Sort && item.required)
          .forEach(item => {
            const itemValue = this._elementsValidity[itemKey][item.field] === undefined
              ? false : this._elementsValidity[itemKey][item.field];

            accumulator = accumulator && itemValue;
          });
      });

    if (accumulator) {
      this._addSort();
      this.items.next(this.internalItems);
    } else {
      this.items.next([]);
    }

    this._updateModelStructure();
  }

  private _addSort() {
    const sortDef = this.config.sortableDefinition.find(x => x.type === FieldDefinitionTypeEnum.Sort);

    this.internalItems.forEach((item, index) => {
      item[sortDef.field] = index;
    });

    this._updateModelStructure();
  }

  private _restructureInternalItems() {
    const inputLangDef = this.config.sortableDefinition
      .filter(x => x.type === FieldDefinitionTypeEnum.InputLang ||
        x.type === FieldDefinitionTypeEnum.TextAreaLang);

    inputLangDef.forEach(def => {
      this.internalItems.forEach(item => {
        if (item[def.field]) {
          const newItem = {};

          item[def.field].forEach((x: any) => newItem[x.languageCode] = x.text);
          item[def.field] = newItem;
        }
      });
    });
  }

  private _updateModelStructure() {
    const inputLangDef = this.config.sortableDefinition.filter(x => x.type === FieldDefinitionTypeEnum.InputLang);
    const items = deepClone<Array<any>>(this.internalItems);

    inputLangDef.forEach(def => {
      items.forEach(item => {
        if (item[def.field]) {
          const translations = [];
          Object.keys(item[def.field])
            .forEach(langCode => {
              const translation = {};
              translation[def.translationPropCode] = langCode;
              translation[def.translationPropText] = item[def.field][langCode];
              translations.push(translation);
            });
          item[def.field] = translations;
        }
      });
    });

    if (this._onChange) {
      this._onChange(items);
    }
  }
}
