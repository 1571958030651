/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/ngx-bootstrap/pagination/ngx-bootstrap-pagination.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "ngx-bootstrap/pagination";
import * as i6 from "./table.component";
var styles_TableComponent = [i0.styles];
var RenderType_TableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableComponent, data: {} });
export { RenderType_TableComponent as RenderType_TableComponent };
function View_TableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_TableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_TableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TableComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "pagination", [], null, [[null, "pageChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChanged" === en)) {
        var pd_0 = (_co.pageChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_PaginationComponent_0, i3.RenderType_PaginationComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.PaginationComponent]), i1.ɵdid(2, 114688, null, 0, i5.PaginationComponent, [i1.ElementRef, i5.PaginationConfig, i1.ChangeDetectorRef], { maxSize: [0, "maxSize"], itemsPerPage: [1, "itemsPerPage"], totalItems: [2, "totalItems"] }, { pageChanged: "pageChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.maxSize; var currVal_1 = _co.model.itemsPerPage; var currVal_2 = _co.model.totalItems; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_TableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.model.paginatedArray; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.model.showPagination; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_TableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table", [], null, null, null, View_TableComponent_0, RenderType_TableComponent)), i1.ɵdid(1, 573440, null, 0, i6.TableComponent, [], null, null)], null, null); }
var TableComponentNgFactory = i1.ɵccf("app-table", i6.TableComponent, View_TableComponent_Host_0, { header: "header", content: "content", itemsPerPage: "itemsPerPage", maxSize: "maxSize" }, {}, []);
export { TableComponentNgFactory as TableComponentNgFactory };
