import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';
import { EntityHandlerService } from 'src/app/services/handler/entity-handler/entity-handler.service';
import { Subscription, interval } from 'rxjs';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { Entity } from 'src/app/models/entity.model';
import { DatePipe } from '@angular/common';
import { flatMap } from 'rxjs/operators';
import { JobStatus, JobName, BackgroundTaskDetailModel, ComparerItem, JobExtendedStatusItem } from 'src/app/models/background-task.model';
import { BackgroundTaskRestService } from 'src/app/services/rest/background-task-rest/background-task-rest.service';
import { ModalService } from 'src/app/services/behavior/modal/modal.service';
import { ErrorDetailModalComponent } from 'src/app/modals/error-detail-modal/error-detail-modal.component';
import { UtilService } from 'src/app/services/behavior/util/util.service';
import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { FileHandlerService } from 'src/app/services/handler/file-handler/file-handler.service';

@Component({
  selector: 'app-background-task-detail',
  templateUrl: './background-task-detail.component.html',
  styleUrls: ['./background-task-detail.component.sass'],
  providers: [DatePipe]
})
export class BackgroundTaskDetailComponent implements OnInit, OnDestroy {
  @Input() public config: FieldDefinition;

  public model = { Items: [], Events: [] } as BackgroundTaskDetailModel;
  private _subscriptions: Array<Subscription> = [];
  private _statusSubscription: Subscription;
  private _comparisonSubscription: Subscription;
  private sourceCode: string;
  private targetCode: string;
  private _bookInstanceCode: string

  constructor(
    private _entityHandler: EntityHandlerService,
    private _fieldValueHanlder: FieldValueHandlerService,
    private _backgroundTaskRest: BackgroundTaskRestService,
    private _modal: ModalService,
    private _date: DatePipe,
    private _utils: UtilService,
    private _navigationHelper: NavigationHelperService,
    private _fileHandler: FileHandlerService,
  ) { }

  public jobInProgress() {
    return this.model.JobStatus === JobStatus.InProgress;
  }

  public jobCompleted() {
    return this.model.JobStatus === JobStatus.Completed;
  }

  public jobFailed() {
    return this.model.JobStatus === JobStatus.Failed;
  }

  public jobCompletedWithErrors() {
    return this.model.JobStatus === JobStatus.CompletedWithErrors;
  }

  public getTitleClass() {
    switch (this.model.JobStatus) {
      case JobStatus.Completed:
        return 'text-success';
      case JobStatus.Failed:
        return 'text-danger';
      case JobStatus.CompletedWithErrors:
        return 'text-danger';
    }
  }

  public showTenantCloneGrid() {
    return this.model.JobName === JobName.CloneTenant;
  }

  public showDeployGrid() {
    return this.model.JobName === JobName.Deploy || this.model.JobName === JobName.Promote;
  }

  public showEventsGrid() {
    return this.model.JobName === JobName.CloneTenant || this.model.JobName === JobName.Deploy;
  }

  public showProcessedData() {
    return this.model.JobName === JobName.RunIntegrationCommand;
  }

  public showCustomErrorModal(item: ComparerItem) {
    const params = { model: item };
    this._modal.showCustom(ErrorDetailModalComponent, params);
  }

  public getRequestUrl() {
    this._navigationHelper.goToRequest(this.model.RequestCode);
  }

  public showRequestData() {
    return this.model.RequestCode && this.model.RequestName;
  }

  public handleDownloadBook() {
    this._fileHandler.downloadBookTemplateForViewCode('application-book', this._bookInstanceCode, null);
  }

  ngOnInit() {
    this._subscriptions.push(
      this._fieldValueHanlder.rowEntityData$.subscribe(data => this._setData(data))
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _configureIntervalForComparer() {
    this._comparisonSubscription = interval(1 * 10 * 1000)
      .pipe(flatMap(() => this._entityHandler.tenantComparer(this.sourceCode, this.targetCode)))
      .subscribe(response => {
        if (!this.jobInProgress()) {
          this._comparisonSubscription.unsubscribe();
        } else {
          this._handleDataComparer(response);
        }
      });
    this._subscriptions.push(this._comparisonSubscription);
  }

  private _setData(data: Entity) {
    this._setProcessStatusData(data);
    this._setTitleData(data);

    switch (data.jobName) {
      case JobName.CloneTenant:
        this._setCloneTenantJobData(data);
        // this._setEventJobData(data);
        this.model.JobName = JobName.CloneTenant;
        break;
      case JobName.Deploy:
        this._setDeployJobData(data);
        this.model.JobName = JobName.Deploy;
        break;
      case JobName.Promote:
        this._setPromoteJobData(data);
        this.model.JobName = JobName.Promote;
        break;
        case JobName.RunIntegrationCommand:
          this._setRunIntegrationCommandJobData(data);
          this.model.JobName = JobName.RunIntegrationCommand;
          break;
        default:
        console.warn(`${data.jobName} does not exist`);
        break;
    }
  }

  private _setCloneTenantJobData(data: Entity) {
    const jobMetadata = data.jobMetadata;
    this._setCloneTableData(data.result, jobMetadata);
    this._configureIntervalForJobStatusCheck(data.jobExecutionUid);
  }

  private _setDeployJobData(data: Entity) {
    const jobExecutionUid = data.jobExecutionUid;
    this._backgroundJobStatusCheck(jobExecutionUid);
    if (this.jobInProgress()) {
      this._configureIntervalForJobStatusCheck(jobExecutionUid);
    }
  }

  private _setPromoteJobData(data: Entity) {
    const jobExecutionUid = data.jobExecutionUid;
    this._backgroundJobStatusCheck(jobExecutionUid);
    if (this.jobInProgress()) {
      this._configureIntervalForJobStatusCheck(jobExecutionUid);
    }
  }

  private _setRunIntegrationCommandJobData(data: Entity) {
    const jobExecutionUid = data.jobExecutionUid;
    this._backgroundJobStatusCheck(jobExecutionUid);
    if (this.jobInProgress()) {
      this._configureIntervalForJobStatusCheck(jobExecutionUid);
    }
  }

  private _backgroundJobStatusCheck(jobExecutionUid: string) {
    this._backgroundTaskRest.getStatus(jobExecutionUid)
      .subscribe((result: [Entity, Array<JobExtendedStatusItem>]) => {
        this._handleJobStatusData(result);
      });
  }

  private _handleJobStatusData(result: [Entity, Array<JobExtendedStatusItem>]) {
    const data = result[0];
    const extendedStatusData = result[1];
    this._setProcessStatusData(this._utils.ObjectKeysToCamelCase(data));

    switch (data.JobName) {
      case JobName.CloneTenant:
        if (data.Result) {
          if (this._comparisonSubscription) {
            this._comparisonSubscription.unsubscribe();
          }
          const cloneResultData = JSON.parse(data.Result);
          this._handleDataComparer(cloneResultData.Comparison);
        }
        break;
      case JobName.Deploy:
        this._handleJobExecutionStatus(data, extendedStatusData, JobName.Deploy);
        break;
      case JobName.Promote:
        this._handleJobExecutionStatus(data, extendedStatusData, JobName.Promote);
        break;
      case JobName.RunIntegrationCommand:
        this._handleJobExecutionStatus(data, extendedStatusData, JobName.RunIntegrationCommand);
        break;
      default:
        console.warn(`${data.JobName} does not exist`);
        break;
    }
  }

  private _handleJobExecutionStatus(jobData: any, extendedStatusData: any[], job: JobName) {
    let metadata: { ItemErrors?: any; };
    let errorMessage: any;
    try {
      metadata = JSON.parse(jobData.ErrorMessage);
    } catch (e) {
      errorMessage = jobData.ErrorMessage;
    }

    this.model.Items = [];
    if(extendedStatusData) {
      extendedStatusData.forEach((item: JobExtendedStatusItem) => {
        const itemKey = [JobName.Deploy].includes(job) ? item.ChangeRequestId : item.InstanceCode;
        this.model.Items.push({
          EntityName: item.EntityName,
          Status: this._calculateDeployItemStatus(item),
          ChangeRequestId: itemKey,
          EntityLibraryId: item.EntityLibraryId,
          Errors: metadata ? metadata.ItemErrors[itemKey] : [errorMessage]
        } as ComparerItem);
      });
    }
    
    this.model.SuccededCount = jobData.RowCountSucceeded;
    this.model.FailedCount = jobData.RowCountFailed;
    this.model.ClonedCount = jobData.RowCountSucceeded + jobData.RowCountFailed;
    this.model.TotalCount = jobData.RowCountExpected;
    if(job == JobName.RunIntegrationCommand) {
      this._bookInstanceCode = jobData.BookInstanceCode ? jobData.BookInstanceCode : null;
    }
    if (this.model.TotalCount) {
      this.model.PercentageProcessed = this._setPercentageProcessed(this.model.ClonedCount, this.model.TotalCount);
    }
  }

  private _configureIntervalForJobStatusCheck(jobExecutionUid: string) {
    this._statusSubscription = interval(1 * 10 * 1000)
      .subscribe(() => {
        if (!this.jobInProgress()) {
          this._statusSubscription.unsubscribe();
        } else {
          this._backgroundJobStatusCheck(jobExecutionUid);
        }
      });
    this._subscriptions.push(this._statusSubscription);
  }

  private _setEventJobData(data: Entity) {
    this.model.Events = [];

    data.events.forEach(event => {
      const dt = new Date(event.creationDateTime);

      this.model.Events.push({
        JobEventTypeCode: event.eventType,
        CreationDatetime: `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`,
        Message: event.message
      });
    });
  }

  private _setProcessStatusData(data: Entity) {
    let errorMessage: string;
    try {
      errorMessage = JSON.parse(data.errorMessage).ErrorMessage;
    } catch (e) {
      errorMessage = data.errorMessage;
    }
    switch (data.status) {
      case JobStatus.InProgress:
        this.model.LoadingTitle = 'The task is running';
        this.model.JobStatus = data.status;
        this.model.ErrorMessage = errorMessage;
        break;
      case JobStatus.Completed:
        this.model.LoadingTitle = 'The task is completed';
        this.model.JobStatus = data.status;
        this.model.ErrorMessage = errorMessage;
        break;
      case JobStatus.Failed:
        this.model.LoadingTitle = 'The task has failed';
        this.model.JobStatus = data.status;
        this.model.ErrorMessage = errorMessage;
        break;
        case JobStatus.CompletedWithErrors:
          this.model.LoadingTitle = 'The task is completed with errors';
          this.model.JobStatus = JobStatus.CompletedWithErrors;
          this.model.ErrorMessage = errorMessage;
          break;
      default:
        console.warn(`Unmapped status ${data.status}`);
        break;
    }
  }

  private _setTitleData(data: Entity) {
    this.model.RequestName = data.requestName;
    this.model.RequestCode = data.requestCode;
    const localDate = this._utils.CreateDateAsUTCFromString(data.startDatetime).toString();
    this.model.StartDate = this._date.transform(localDate, 'medium');
    this.model.StartDateUtc = this._date.transform(new Date(data.startDatetime), 'medium');
    this.model.User = data.userName;
  }

  private _setCloneTableData(jobExecutionComparison: string, jobMetadata: any) {
    if (jobExecutionComparison) {
      const data = JSON.parse(jobExecutionComparison);
      this._handleDataComparer(data.Comparison);
    } else if (jobMetadata) {
      this.sourceCode = JSON.parse(jobMetadata).SourceCode;
      this.targetCode = JSON.parse(jobMetadata).TargetCode;

      this._initialTenantComprarRequest();
      if (this.jobInProgress()) {
        this._configureIntervalForComparer();
      }
    }
  }

  private _initialTenantComprarRequest() {
    this._entityHandler.tenantComparer(this.sourceCode, this.targetCode)
      .subscribe(response => this._handleDataComparer(response));
  }

  private _handleDataComparer(data: any) {
    let clonedCount = 0;
    let totalCount = 0;

    this.model.Items = [];
    const comparisonList = data.error ? data.error : data;

    comparisonList.forEach(element => {
      element = this._utils.ObjectKeysToCamelCase(element);
      this.model.Items.push({
        EntityName: element.libraryName,
        TargetCount: element.targetCount,
        SourceCount: element.sourceCount,
        Status: this._calculateItemStatus(element),
        Percentage: this._setPercentageProcessed(element.targetCount, element.sourceCount)
      } as ComparerItem);

      clonedCount += element.targetCount;
      totalCount += element.sourceCount;
    });

    this.model.ClonedCount = clonedCount;
    this.model.TotalCount = totalCount;
    this.model.PercentageProcessed = this._setPercentageProcessed(this.model.ClonedCount, this.model.TotalCount);
  }

  private _setPercentageProcessed(partial: number, total: number) {
    const percentage = parseFloat(((partial / total) * 100).toString());
    return percentage.toFixed(2);
  }

  private _calculateItemStatus(element: any): string {
    if (element.targetCount === 0) { return 'Not Started'; }
    if (element.targetCount < element.sourceCount && !this.jobInProgress()) { return 'Failed'; }
    if (element.targetCount < element.sourceCount && this.jobInProgress()) { return 'Processing'; }
    if (element.targetCount === element.sourceCount) { return 'Complete'; }
  }

  private _calculateDeployItemStatus(element: any): string {
    switch (element.StatusCode) {
      case 'IN_CONFIGURATION':
      case 'PENDING':
      case 'PENDING_EXECUTION':
        return 'Not Started';
      case 'FAILED':
      case 'FAILED_EXECUTION':
          return 'Failed';
      case 'IN_PROGRESS':
        return 'Processing';
      case 'COMPLETED':
      case 'SUCCESFULLY_EXECUTED':
        return 'Complete';
      case 'SKIPPED':
        return 'Skipped';
    }
  }
}
