export enum NavigationUrlEnum {
    CampaignBuilder = 'cmp',
    Dashboard = 'campaign-builder/dashboard',
    DashboardJourney = 'campaign-builder/dashboard/journey',
    OrganizationSelector = 'tenant-selector',
    List = 'campaign-builder/config/list',
    Detail = 'campaign-builder/config/detail',
    RequestDetail = 'campaign-builder/config/detail/request',
    RequestList = 'campaign-builder/config/list/request',
    PendingRequestList = 'campaign-builder/config/list/pending-request',
    BaseGridUrl = 'campaign-builder/config/list',
    BaseFormUrl = 'campaign-builder/config/detail',
    BackgroundTaskDetail = 'campaign-builder/config/list/background-job',
    ProductsWithBooksCountList = 'campaign-builder/config/list/products-with-books-count',
    ProductsWithContainersList = 'campaign-builder/config/list/products-with-containers'
}