import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionButtonsComponent } from '../components/action-bar/action-bar.component';
import { FloatingInputComponent } from '../components/floating-input/floating-input.component';
import { LoadersModule } from '../components/loading/loaders.module';
import { TableComponent } from '../components/table/table.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TitleBarComponent } from './campaign-builder/title-bar/title-bar.component';
import { ActionBarDropdownComponent } from '../components/action-bar-dropdown/action-bar-dropdown.component';

const toExport = [
    ActionButtonsComponent,
    ActionBarDropdownComponent,
    FloatingInputComponent,
    TableComponent,
    TitleBarComponent
];

@NgModule({
    declarations: [toExport],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadersModule,
        PaginationModule.forRoot(),
        BsDropdownModule.forRoot()
    ],
    exports: [
        toExport,
        LoadersModule
    ]
})
export class CoreComponentsModule { }
