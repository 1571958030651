<div class="modal-header">
	<h3 class="modal-title pull-left" [innerHtml]="title"></h3>
	<button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body campaign-modal">

	<div class="row">
		<div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
		  <app-table [header]= "headerData" [content]="data"></app-table>
	  </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-next" (click)="closeModal()">Close</button>
</div>