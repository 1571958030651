import { isObject } from 'src/app/functions/isObject';
import { Entity } from 'src/app/models/entity.model';
import { DataModelFactoryService } from '../../factory/data-model-factory/data-model-factory.service';
import { deepClone } from 'src/app/functions/deepClone';
import { FieldValueHandlerService } from '../../handler/field-value-handler/field-value-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../../factory/data-model-factory/data-model-factory.service";
import * as i2 from "../../handler/field-value-handler/field-value-handler.service";
export class DataModelHelperService {
    constructor(_dataModelFactory, _fieldValueHandler) {
        this._dataModelFactory = _dataModelFactory;
        this._fieldValueHandler = _fieldValueHandler;
    }
    setValueToFieldFromRules(data, fieldGroups) {
        const fields = [].concat(...fieldGroups.map(g => g.fields)).filter(x => x !== undefined);
        const ruleConfig = fields.filter(x => x.rulesConfiguration !== undefined);
        // if there is a rule configuration...
        if (ruleConfig && ruleConfig.length > 0) {
            this._manageRuleObjectDeconstruction(data, ruleConfig);
        }
    }
    setValueToFieldFromEntity(data, field, fieldGroups) {
        const dataValue = this._getValueFromEntity(data, field, fieldGroups);
        this.setValueFromEntityProp(dataValue, field);
    }
    setValueFromEntityProp(dataValue, field) {
        const dataModel = this._dataModelFactory.createDataModel(field);
        // only get the value when the field has no preset value
        if (!field.value) {
            field.value = dataModel.setValueToField(dataValue);
        }
        field = this._stringifyJsonMetadataIfExists(field);
    }
    getPayloadFromFields(fields, previousData, fieldGroups) {
        let payload = Object.assign(new Entity(), previousData || {});
        let fieldArrays = {};
        // erase fixed fields that the backend wont need
        payload.rules = undefined;
        fields.forEach(field => {
            // si el field no pertenece al objeto de rules 
            // o si pertenece y no es un factor
            if (!field.rulesConfiguration || !field.rulesConfiguration.isFactor) {
                const dataModel = this._dataModelFactory.createDataModel(field);
                const value = dataModel.getValueFromField(field.value);
                if (field.fieldArray && field.metadata) {
                    if (!fieldArrays[field.fieldArray]) {
                        fieldArrays[field.fieldArray] = [];
                    }
                    const fieldName = field.field.indexOf('#') ? field.field.split('#')[1] : field.field;
                    fieldArrays[field.fieldArray].push({
                        featureInstanceCode: fieldName,
                        libraryCodeDataType: field.metadata.libraryCodeDataType,
                        featureCode: field.metadata.externalCode,
                        categoryInstanceCode: field.metadata.productTypeInstanceCode,
                        value: value
                    });
                }
                else {
                    const fieldName = this._getUnifiedFieldNameForTriggerable(fieldGroups, field) || field.field;
                    /**
                     * This fix is for avoiding override ownerPartyRoleInstanceCode when we have this
                     * field duplicated for any reason in the form definition.
                     * It would be better if we filter out not-triggered fields and sections before this method.
                     * */
                    if (fieldName != 'ownerPartyRoleInstanceCode'
                        || (fieldName === 'ownerPartyRoleInstanceCode' && !payload.ownerPartyRoleInstanceCode)) {
                        this._createProp(payload, fieldName, value);
                    }
                }
            }
        });
        Object.keys(fieldArrays)
            .forEach(prop => {
            this._createProp(payload, prop, fieldArrays[prop]);
        });
        this._nullObjectsWithNullProps(payload);
        // if there is a rule configuration...
        this._setRules(fields, payload);
        payload = this._runGetPayloadFromFieldPostProcess(fields, payload);
        payload = this._parseJsonMetadataIfExists(payload);
        this._setValuesForDynamicForms(payload, fieldGroups);
        if (payload.rules == undefined) {
            payload.rules = [];
        }
        return payload;
    }
    getValueByPropertyFullName(obj, fullPropertyName) {
        const propName = fullPropertyName.split('.', 2);
        if (fullPropertyName.includes('.')) {
            const newFullPropName = fullPropertyName.replace(`${propName[0]}.`, '');
            return this.getValueByPropertyFullName(obj && obj[propName[0]], newFullPropName);
        }
        else {
            return obj && obj[fullPropertyName];
        }
    }
    _getValueFromEntity(data, field, fieldGroups) {
        const fieldName = this._getUnifiedFieldNameForTriggerable(fieldGroups, field);
        let dataValue = undefined;
        if (fieldName) {
            dataValue = data[fieldName];
        }
        else {
            dataValue = this.getValueByPropertyFullName(data, field.field);
        }
        return dataValue;
    }
    _setRules(fields, payload) {
        const ruleConfig = fields.filter(field => field.rulesConfiguration !== undefined)
            .filter(field => !field.attributes.hidden);
        if (ruleConfig && ruleConfig.length > 0) {
            // check if there are factors
            const factorsAvailable = ruleConfig.some(field => field.rulesConfiguration.isFactor);
            if (factorsAvailable) {
                this._manageRuleObjectCreation(payload, ruleConfig);
            }
        }
    }
    _manageRuleObjectCreation(data, ruleConfig) {
        // asumo que hay solo un objeto rule
        const rulesPropName = ruleConfig[0].rulesConfiguration.field;
        const ruleTypeLibraryCode = this._getruleTypeLibraryCodeFromField(ruleConfig);
        ;
        let ruleObj;
        if (data[rulesPropName] == undefined) {
            data[rulesPropName] = [];
        }
        ruleObj = {
            instanceName: `${data.instanceName} - Rule`,
            instanceDescription: `${data.instanceDescription} - Rule`,
            ownerPartyRoleInstanceCode: data.ownerPartyRoleInstanceCode,
            ruleTypeLibraryCode: ruleTypeLibraryCode,
            factors: []
        };
        ruleConfig.forEach(field => {
            // ahora los factors
            if (field.rulesConfiguration.isFactor) {
                this._addFactorToRule(field, ruleTypeLibraryCode, ruleObj.factors);
            }
            // ahora el outcome
            if (field.rulesConfiguration.isOutome) {
                this._addOutomeToRule(field, ruleObj);
            }
        });
        data[rulesPropName].push(ruleObj);
    }
    _setSortableListElements(fields, payload) {
        throw new Error("Method not implemented.");
    }
    _manageRuleObjectDeconstruction(data, ruleConfig) {
        const rulesPropName = ruleConfig[0].rulesConfiguration.field;
        const ruleTypeLibraryCode = this._getruleTypeLibraryCodeFromEntity(data, ruleConfig);
        if ((data[rulesPropName] !== undefined && data[rulesPropName] !== null) && ruleTypeLibraryCode !== undefined) {
            const ruleObj = data[rulesPropName].find(x => x.ruleTypeLibraryCode === ruleTypeLibraryCode);
            if (ruleObj == undefined) {
                return;
            }
            ruleConfig.forEach(field => {
                const dataModel = this._dataModelFactory.createDataModel(field);
                if (field.rulesConfiguration.isFactor) {
                    const factors = ruleObj.factors.filter(factor => {
                        return field.rulesConfiguration.libraryCodes
                            .some(code => code === factor.factorTypeLibraryCode);
                    });
                    const factorsValue = factors.map(x => x.valueInstanceCode);
                    // only get the value when the field has no preset value
                    if (!field.value) {
                        if (field.propertyAsCode) {
                            let value = dataModel.setValueToField(factorsValue.map(x => {
                                return { code: x };
                            }));
                            field.value = value;
                        }
                        else {
                            field.value = factorsValue;
                        }
                    }
                }
                else if (field.rulesConfiguration.isOutome) {
                    const outcomeValue = ruleObj[field.rulesConfiguration.outcomeProperty];
                    field.value = dataModel.setValueToField(outcomeValue);
                }
            });
        }
    }
    _getruleTypeLibraryCodeFromEntity(data, ruleConfig) {
        let ruleTypeLibraryCode = undefined;
        ruleConfig.some(field => {
            if (field.rulesConfiguration.ruleTypeLibraryCodeField) {
                const catalogItem = field.catalogItems.find(x => x.code === data[field.field]);
                ruleTypeLibraryCode = catalogItem[field.rulesConfiguration.ruleTypeLibraryCodeField];
                return true;
            }
        });
        return ruleTypeLibraryCode;
    }
    _getruleTypeLibraryCodeFromField(ruleConfig) {
        let libraryCode = undefined;
        ruleConfig.some(field => {
            if (field.rulesConfiguration.ruleTypeLibraryCodeField) {
                const fieldName = field.rulesConfiguration.ruleTypeLibraryCodeField;
                if (field.codeProperty) {
                    const dataModel = this._dataModelFactory.createDataModel(field);
                    let value = dataModel.getValueFromField(field.value);
                    libraryCode = value[fieldName];
                }
                else {
                    const catalogItem = field.catalogItems.find(x => x.code === field.value[0]);
                    libraryCode = catalogItem[fieldName];
                }
                return true;
            }
        });
        return libraryCode;
    }
    _addFactorToRule(field, ruleTypeLibraryCode, factors) {
        const dataModel = this._dataModelFactory.createDataModel(field);
        const valueInstanceCodeProp = 'valueInstanceCode';
        let value = dataModel.getValueFromField(field.value);
        let factor = {
            ruleTypeLibraryCode: ruleTypeLibraryCode,
            valueInstanceCode: undefined,
            factorTypeLibraryCode: undefined
        };
        if (value) {
            value = Array.isArray(value) ? value : [value];
            (value || []).forEach(fieldValue => {
                const newFactor = deepClone(factor);
                newFactor[field.rulesConfiguration.valueProperty || valueInstanceCodeProp] = fieldValue.code || fieldValue;
                if (field.rulesConfiguration.factorTypeLibraryCode) {
                    // este es un valor fijo
                    newFactor.factorTypeLibraryCode = field.rulesConfiguration.factorTypeLibraryCode;
                }
                else {
                    newFactor.factorTypeLibraryCode = fieldValue['libraryCode'] || fieldValue['ruleTypeLibraryCode'];
                }
                factors.push(newFactor);
            });
        }
    }
    _addOutomeToRule(field, ruleObj) {
        if (field.rulesConfiguration.outcomeProperty) {
            const dataModel = this._dataModelFactory.createDataModel(field);
            ruleObj[field.rulesConfiguration.outcomeProperty] = dataModel.getValueFromField(field.value);
        }
    }
    _createProp(obj, fullPropertyName, value) {
        const propName = fullPropertyName.split('.', 2);
        if (!obj.environmentCode) {
            if (propName.length > 1) {
                const newFullPropName = fullPropertyName.replace(`${propName[0]}.`, '');
                if (!obj[propName[0]]) {
                    obj[propName[0]] = {};
                }
                this._createProp(obj[propName[0]], newFullPropName, value);
            }
            else {
                obj[propName[0]] = value;
            }
        }
    }
    _nullObjectsWithNullProps(obj) {
        const props = Object['keys'](obj);
        props.forEach(prop => {
            if (isObject(obj[prop])) {
                if (this._checkFullUndefined(obj[prop])) {
                    obj[prop] = null;
                }
            }
        });
    }
    _checkFullUndefined(obj) {
        if (typeof (obj) == 'object') {
            const props = Object['keys'](obj);
            var allNull = true;
            props.forEach(prop => {
                if (isObject(obj[prop])) {
                    allNull = allNull && this._checkFullUndefined(obj[prop]);
                }
                else {
                    allNull = allNull && (obj[prop] == null || obj[prop] == undefined);
                }
            });
            return allNull;
        }
        return false;
    }
    _getUnifiedFieldNameForTriggerable(groups, field) {
        let fieldName = undefined;
        if (field.triggerBy && field.fieldGroup) {
            const flatFieldArray = this._getFlatFieldDefinitionArray(groups);
            const trigger = this._findTrigger(flatFieldArray, field);
            if (this._triggerEnable(field, trigger)) {
                fieldName = field.fieldGroup;
            }
        }
        return fieldName;
    }
    _getFlatFieldDefinitionArray(groups) {
        let fieldArray = [];
        groups.forEach(group => {
            if (group.fields) {
                fieldArray = fieldArray.concat(group.fields);
            }
        });
        return fieldArray;
    }
    _findTrigger(fieldArray, triggerableField) {
        return fieldArray.find(field => triggerableField.triggerBy.field === field.field);
    }
    _triggerEnable(field, trigger) {
        return field.triggerBy.codes.includes(trigger.value[0]);
    }
    _runGetPayloadFromFieldPostProcess(fields, entity) {
        if (entity.visibility && entity.sharedLibraryClassifications) {
            return this._runSharedLibraryPostMagix(fields, entity);
        }
        return entity;
    }
    _runSharedLibraryPostMagix(fields, entity) {
        if (entity.visibility !== 'by_category') {
            entity.sharedLibraryClassifications = [];
            entity.isPublic = entity.visibility === 'public';
        }
        delete entity.visibility;
        return entity;
    }
    _parseJsonMetadataIfExists(payload) {
        if (payload.descriptiveMetadata) {
            payload.descriptiveMetadata = JSON.parse(payload.descriptiveMetadata);
        }
        return payload;
    }
    _setValuesForDynamicForms(payload, fieldGroups) {
        const subForm = [];
        let keyValueArray = [];
        fieldGroups.forEach(x => {
            if (x.dynamicSection) {
                keyValueArray = [];
                x.fields.forEach(y => {
                    const valueObj = {
                        key: y.field,
                        value: y.value,
                        type: y.type,
                        label: y.attributes.label
                    };
                    keyValueArray.push([y.field, valueObj]);
                });
                subForm.push({
                    instanceCode: x.sectionId,
                    code: x.name,
                    name: x.title,
                    keyValues: Object.fromEntries(keyValueArray)
                });
            }
        });
        if (payload.metadata && subForm.length > 0) {
            payload.metadata.name = this._fieldValueHandler.getAnyValue().changes.get('genericFormInstanceName');
            payload.metadata.subForms = subForm;
        }
    }
    _stringifyJsonMetadataIfExists(field) {
        if (field.attributes.name === 'descriptiveMetadata') {
            field.value = JSON.stringify(field.value);
        }
        return field;
    }
}
DataModelHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataModelHelperService_Factory() { return new DataModelHelperService(i0.ɵɵinject(i1.DataModelFactoryService), i0.ɵɵinject(i2.FieldValueHandlerService)); }, token: DataModelHelperService, providedIn: "root" });
