import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey("Emerios_Enterprise_Services_Inc_Emerios_Platform_single_1_Devs__12_December_2020_[v2]_MTYwNzczMTIwMDAwMA==08b43cc351f50e074bcb9785edfd3f63");

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherwise, log the boot error
}).catch(err => console.error(err));
