/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chained-filter-counter.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./chained-filter-counter.component";
var styles_ChainedFilterCounterComponent = [i0.styles];
var RenderType_ChainedFilterCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChainedFilterCounterComponent, data: {} });
export { RenderType_ChainedFilterCounterComponent as RenderType_ChainedFilterCounterComponent };
export function View_ChainedFilterCounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Universe of resulting ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "row mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col-sm-12 col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "zipcode-figure"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-map-pin"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "zipcode-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " fitered"])), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "col-sm-12 col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "a", [["class", "btn btn-icon btn-simple btn-action d-inline-block"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fireEvent("view") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { "disabled": 0 }), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fas fa-eye"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, [" View ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "btn btn-icon btn-simple btn-action d-inline-block"; var currVal_4 = _ck(_v, 16, 0, !_co.buttonsEnabled); _ck(_v, 15, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.counter; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.label; _ck(_v, 10, 0, currVal_2); var currVal_5 = _co.label; _ck(_v, 18, 0, currVal_5); }); }
export function View_ChainedFilterCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chained-filter-counter", [], null, null, null, View_ChainedFilterCounterComponent_0, RenderType_ChainedFilterCounterComponent)), i1.ɵdid(1, 770048, null, 0, i3.ChainedFilterCounterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChainedFilterCounterComponentNgFactory = i1.ɵccf("app-chained-filter-counter", i3.ChainedFilterCounterComponent, View_ChainedFilterCounterComponent_Host_0, { counter: "counter", label: "label", data: "data", buttonsEnabled: "buttonsEnabled" }, { actionFired: "actionFired" }, []);
export { ChainedFilterCounterComponentNgFactory as ChainedFilterCounterComponentNgFactory };
