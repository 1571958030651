<a href="javascript:void(0)" (click)="goHome()" class="home" *ngIf="hasAnyPermission">
	<i class="fas fa-home"></i>
</a>
<div class="breadcrumb-select emerios-multiselect">
	<emerios-multiselect [config]="model.breadcrumbConfig" [(ngModel)]="model.filterSelection"
		(ngModelChange)="updateBreadcrumbInfo($event)">
	</emerios-multiselect>
</div>
<ol class="breadcrumb" [ngClass]="model.stateClass">
	<ng-container *ngFor="let breadcrumb of model.breadcrumbs; let i = index">
		<li>
			<a *ngIf="isJourneyNavigation() && !breadcrumb.current">
				<span class="badge">
					<span>{{breadcrumb.breadcrumbContent.viewMode}} {{breadcrumb.breadcrumbContent.instanceType}}</span>
					<span *ngIf="showSequenceId(breadcrumb)">{{breadcrumb.breadcrumbContent.sequenceId}}</span>
				</span>
				<span
					*ngIf="isSectionShown('InstanceName', breadcrumb)">{{breadcrumb.breadcrumbContent.instanceName}}</span>
				<span *ngIf="isSectionShown('InstanceCode', breadcrumb) && showSquareBracket(breadcrumb)"
					class="instance-code">[{{breadcrumb.breadcrumbContent.instanceCode}}]</span>
				<span *ngIf="isSectionShown('InstanceCode', breadcrumb) && !showSquareBracket(breadcrumb)"
					class="instance-code">{{breadcrumb.breadcrumbContent.instanceCode}}</span>
			</a>
			<ng-container *ngIf="isJourneyNavigation() && breadcrumb.current">
				<span class="badge badge-current">
					<span>{{breadcrumb.breadcrumbContent.viewMode}} {{breadcrumb.breadcrumbContent.instanceType}}</span>
					<span *ngIf="showSequenceId(breadcrumb)">{{breadcrumb.breadcrumbContent.sequenceId}}</span>
				</span>
				<span
					*ngIf="isSectionShown('InstanceName', breadcrumb)">{{breadcrumb.breadcrumbContent.instanceName}}</span>
				<span *ngIf="isSectionShown('InstanceCode', breadcrumb) && showSquareBracket(breadcrumb)"
					class="instance-code">[{{breadcrumb.breadcrumbContent.instanceCode}}]</span>
				<span *ngIf="isSectionShown('InstanceCode', breadcrumb) && !showSquareBracket(breadcrumb)"
					class="instance-code">{{breadcrumb.breadcrumbContent.instanceCode}}</span>
			</ng-container>
			<a *ngIf="!isJourneyNavigation() && !breadcrumb.current" (click)="go(breadcrumb, i)">
				<span class="badge">
					<span>{{breadcrumb.breadcrumbContent.viewMode}} {{breadcrumb.breadcrumbContent.instanceType}}</span>
					<span *ngIf="showSequenceId(breadcrumb)">{{breadcrumb.breadcrumbContent.sequenceId}}</span>
				</span>
				<span
					*ngIf="isSectionShown('InstanceName', breadcrumb)">{{breadcrumb.breadcrumbContent.instanceName}}</span>
				<span *ngIf="isSectionShown('InstanceCode', breadcrumb) && showSquareBracket(breadcrumb)"
					class="instance-code">[{{breadcrumb.breadcrumbContent.instanceCode}}]</span>
				<span *ngIf="isSectionShown('InstanceCode', breadcrumb) && !showSquareBracket(breadcrumb)"
					class="instance-code">{{breadcrumb.breadcrumbContent.instanceCode}}</span>
			</a>
			<ng-container *ngIf="!isJourneyNavigation() && breadcrumb.current">
				<span class="badge badge-current">
					<span>{{breadcrumb.breadcrumbContent.viewMode}} {{breadcrumb.breadcrumbContent.instanceType}}</span>
					<span *ngIf="showSequenceId(breadcrumb)">{{breadcrumb.breadcrumbContent.sequenceId}}</span>
				</span>
				<span
					*ngIf="isSectionShown('InstanceName', breadcrumb)">{{breadcrumb.breadcrumbContent.instanceName}}</span>
				<span *ngIf="isSectionShown('InstanceCode', breadcrumb) && showSquareBracket(breadcrumb)"
					class="instance-code">[{{breadcrumb.breadcrumbContent.instanceCode}}]</span>
				<span *ngIf="isSectionShown('InstanceCode', breadcrumb) && !showSquareBracket(breadcrumb)"
					class="instance-code">{{breadcrumb.breadcrumbContent.instanceCode}}</span>
			</ng-container>
		</li>
	</ng-container>
</ol>