<div class="btn-group" #dropdown="bs-dropdown" dropdown [insideClick]="true" [id]="config?.id"
  [ngClass]="{'fullwidth':config?.fillParentWidth}" [isDisabled]="config?.disabled" (onShown)="onPopoverShown()"
  (onHidden)="onPopoverHidden()">
  <button dropdownToggle type="button" [ngClass]="getClasses()">
    {{model.selectedText}} <span class="caret"></span>
  </button>
  <ul *dropdownMenu class="dropdown-menu multiselect-container" [ngClass]="getListHeight()">
    <ng-container *ngIf="config?.allowSearchFilter">
      <div class="search">
        <input #searchInput type="text" class="form-control" (input)="onSearch($event)" (keydown)="onKeydown($event)"
          [(ngModel)]="model.searchCriteria" placeholder="{{config?.searchPlaceholderText || ''}}" autocomplete="off"
          autofocus>
        <a class="search-clear" href="javascript:void(0)" (click)="clearSearch()"
          [innerHTML]="config?.clearText || 'clear'"></a>
      </div>
    </ng-container>

    <!-- --------------------/ CHECK ALL & Clear Selection ---------------------- -->

    <ng-container *ngIf="!config?.singleSelect && config?.enableCheckAll">
      <div class="check-all-container">
        <li>
          <a class="check-all" href="javascript:void(0)" (click)="checkAll()">
            <i class="fas fa-check-double"></i>
            {{config?.selectAllText || 'Check All'}}
          </a>
          <a class="check-all" href="javascript:void(0)" (click)="uncheckAll()">
            <i class="fas fa-times"></i>
            {{config?.unselectAllText || 'Clear Selection'}}
          </a>
        </li>
      </div>
    </ng-container>

    <!-- --------------------/ CHECK ALL & Clear Selection ---------------------- -->

    <li *ngIf="getItemsToShow().length === 0" class="empty-list">
      No items to show
    </li>

    <li *ngFor="let item of getItemsToShow()">
      <a href="javascript:void(0)" [ngClass]="{'selected':item.selected}">
        <label class="checkbox">
          <input type="checkbox" [(ngModel)]="item.selected" (ngModelChange)="onClickedItem(item)">
          {{config.itemText ? item.item[config.itemText] : item.item}}
        </label>
      </a>
    </li>
  </ul>
</div>

<div *ngIf="multiselect.errors && multiselect.invalid && multiselect.touched">
  <div [ngClass]="config?.errorMsgClass">{{getSelectErrors()}}</div>
</div>

<span class="icon-validation" *ngIf="config?.validationIcons">
  <i [ngClass]="config?.validationIcons.pristine"
    *ngIf="multiselect.pristine &&  !multiselect.errors && showRequiredIcon"></i>
  <i [ngClass]="config?.validationIcons.error" *ngIf="!multiselect.pristine && multiselect.errors"></i>
  <i [ngClass]="config?.validationIcons.success" *ngIf="!multiselect.pristine && !multiselect.errors"></i>
</span>