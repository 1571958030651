<div class="container container-grid-panel pt-2">
	<div class="row form-begin">
		<div class="col-xs-12">
			<p>Who am I (role)? <i class="fas fa-question-circle fa-xs"></i></p>
			<!-- <p>Entity Manager Instance Entity Manager Instance Entity Manager Instance</p> -->
			<select class="form-control">
				<option default selected>Entity Manager Instance</option>
				<option>TPV Campaign Manager</option>
			</select>
			<hr>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<p>Who owns what I'm working on? <i class="fas fa-question-circle fa-xs"></i></p>
			<select class="form-control mb-1">
				<option default selected>Destination Role Type Name</option>
				<option>PM Tenant</option>
			</select>
	
			<select class="form-control mb-1">
				<option default selected>Entity Dest. Instance</option>
				<option>Just Energy</option>
				<option>Direct Energy</option>
				<option>CC Energy</option>
			</select>
			<hr>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<!-- <h4 class="my-1"><strong> Step 3</strong></h4> -->
			<p>What do we want to change? <i class="fas fa-question-circle fa-xs"></i></p>
			<select class="form-control mb-1">
				<option default selected>Entity Type</option>
				<option>Party</option>
				<option>Platform Provider</option>
				<option>Organization</option>
				<option>Tenant</option>
				<option>Product Marketer Tenant</option>
				<option>MISO</option>
				<option>ISO</option>
				<option>Team</option>
				<option>Agent</option>
				<option>Person</option>
				<option>PM Tenant</option>
				<option>Campaign</option>
				<option>Flow</option>
				<option>Step</option>
				<option>Form</option>
			</select>
			<hr>
		</div>
	</div>

	<div class="row">
		<div class="col-xs-12">
			<p>What filters do you want to apply to your selection? <i class="fas fa-question-circle fa-xs"></i></p>
			<select class="form-control mb-1">
				<option default selected>Product Brand</option>
				<option>Alpha</option>
				<option>Beta</option>
			</select>

			<select class="form-control mb-1">
				<option default selected>Territory</option>
				<option>FL Territory</option>
				<option>CA Territory</option>
				<option>MA Territory</option>
				<option>MI Territory</option>
				<option>NY Territory</option>
				<option>TX Territory</option>
				<option>VA Territory</option>
			</select>

			<select class="form-control mb-1">
				<option default selected>ISO (Organizational Unit)</option>
				<option>Team 2020</option>
				<option>Team Yellow</option>
			</select>

			<select class="form-control mb-1">
				<option default selected>Request Channel</option>
				<option>Quest</option>
				<option>Public Site</option>
				<option>CSR</option>
			</select>

			<select class="form-control mb-1">
				<option default selected>Customer Type</option>
				<option>RES</option>
				<option>LVC</option>
				<option>SVC</option>
			</select>

			<select class="form-control mb-1">
				<option default selected>Sales Channel</option>
				<option>D2D</option>
				<option>RETAIL</option>
			</select>

			<select class="form-control mb-1">
				<option default selected>TPV Channel</option>
				<option>Email</option>
				<option>SMS</option>
				<option>Phone</option>
			</select>

			<select class="form-control mb-1">
				<option default selected>Product Code</option>
				<option>ENERGY_ELE_COM_320</option>
				<option>ENERGY_ELE_COM_322</option>
				<option>ENERGY_ELE_COM_324</option>
			</select>
			<hr>
		</div>
	</div>
</div>