import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private readonly _theme = new BehaviorSubject<string>(undefined);
  private readonly _isMobile = new BehaviorSubject<boolean>(undefined);
  private readonly _appendBodyClass = new BehaviorSubject<string>(undefined);
  private readonly _contextParams = new BehaviorSubject<any>(undefined);

  public readonly theme$: Observable<string>;
  public readonly isMobile$: Observable<boolean>;
  public readonly appendBodyClass$: Observable<string>;
  public readonly authenticated$: Observable<boolean>;
  public readonly contextParams$: Observable<any>;

  constructor() {
    this.theme$ = this._theme.asObservable().pipe(filter(x => x !== undefined));
    this.isMobile$ = this._isMobile.asObservable().pipe(filter(x => x !== undefined));
    this.appendBodyClass$ = this._appendBodyClass.asObservable().pipe(filter(x => x !== undefined));
    this.contextParams$ = this._contextParams.asObservable().pipe(filter(x => x !== undefined));
  }

  public setTheme(theme: string) {
    this._theme.next(theme);
    this._appendBodyClass.next(theme);
  }

  public appendBodyClass(className: string) {
    this._appendBodyClass.next(className);
  }

  public setContextParams(source?: string, presetView?: string) {
    this._contextParams.next({ source, presetView });
  }

  public setIsMobile(value: boolean) {
    this._isMobile.next(value);
  }
}
