<div class="modal-header">
    <h3 class="modal-title pull-left" [innerHtml]="model.title"></h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<ng-container *ngIf="form">
    <div class="modal-body campaign-modal" [formGroup]="form">

        <div class="row">
            <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
                <label class="app-input has-label emerios-multieselect">
                    {{model.toInstanceAttr.label}}
                    <emerios-multiselect [config]="model.toInstanceConfig"
                        [formControlName]="model.toInstanceConfig.name" [items]="model.toInstanceConfig.items">
                    </emerios-multiselect>
                </label>
            </div>
        </div>

        <div class="alert alert-warning" role="alert" *ngIf="model.showWarning">This relationship already exists.</div>
    </div>
</ng-container>

<div class="modal-footer">
    <button type="button" class="btn btn-next" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary btn-fill" (click)="okModal()"
        [disabled]="!model.formValid">Create</button>
</div>