<div class="modal-header">
	<h3 class="modal-title pull-left" [innerHtml]="title"></h3>
	<button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body campaign-modal">

	<div class="row">
		<div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
			<p [innerHtml]="message"></p>
			<input name="input" class="form-control" type="text" placeholder="Preset View Name" autocomplete="off"
				[(ngModel)]="model.name" (change)="viewNameChanged()" autofocus>
		</div>

		<div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10 my-3">
			<textarea name="input" class="form-control" placeholder="Preset View Description"
				[(ngModel)]="model.description" autocomplete="off" style="height: 150px"></textarea>
		</div>

		<div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10 my-3">
			<span class="url-friendly">
				<span>{{getBaseUrl()}}</span>
				<input name="input" class="form-control" type="text" autocomplete="off" [(ngModel)]="model.viewCode">
			</span>

			<div class="checkbox text-check-left my-3">
				<input type="checkbox" id="isPublic" [(ngModel)]="model.public">
				<label for="isPublic">Mark as Public</label>
			</div>
			<div class="clearfix"></div>
			<span id="helpBlock" class="help-block">If you mark the preset view as Public, any user will be able to use
				it but could not edit it.</span>

		</div>

	</div>

</div>

<div class="modal-footer">
	<button type="button" class="btn btn-next" (click)="closeModal()">Cancel</button>
	<button type="button" class="btn btn-primary btn-fill" (click)="okModal()">Save</button>
</div>