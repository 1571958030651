import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges, OnDestroy } from '@angular/core';
import { KeyValue } from 'src/app/types/keyvalue.type';
import { Subscription } from 'rxjs';
import { deepClone } from 'src/app/functions/deepClone';
import { ActionBarService } from 'src/app/services/behavior/action-bar/action-bar.service';

export interface ActionButton {
  code: string;
  startSeparator?: boolean;
  endSeparator?: boolean;
  callback?: Function;

  text?: string;
  enabled?: boolean;
  iconClass?: string;
  buttonClass?: string;
  buttonStyle?: string;
  visible?: boolean;

  toggle?: KeyValue
  toggled?: boolean;

  showDropdownIcon?: boolean;
  childrens?: Array<ActionButton>
}

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.sass']
})
export class ActionButtonsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public config: Array<ActionButton>;
  @Input() public code: string;
  @Input() public alignmentMenuToRight: boolean;

  @Output() public actionFired: EventEmitter<string> = new EventEmitter();

  public buttons: Array<ActionButton>;

  private _subscriptions: Array<Subscription> = [];

  constructor(private _actionButtonsService: ActionBarService) { }

  public clickAction(button: ActionButton) {
    if (button.toggle) {
      if (button.toggled) {
        this._untoggleButton(button);
      } else {
        this._toggleButton(button);
      }
      button.toggled = !button.toggled;
    }
    if (button.callback) {
      button.callback();
    }

    if (this.actionFired) {
      this.actionFired.emit(button.code);
    }
  }

  public getStyle(button: ActionButton) {
    return JSON.parse(button.buttonStyle || '{}');
  }

  public getVisibleChildrens(button: ActionButton) {
    return button.childrens && button.childrens.filter(x => x.visible).length > 0;
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this.buttons = this._deepCloneButtons();

      this.buttons.forEach(button => {
        if (button.childrens) {
          button.childrens.forEach(child => {
            if (child.toggled) {
              this._toggleButton(child);
            }
          });
        }
        if (button.toggled) {
          this._toggleButton(button);
        }
      });
    }

    if (changes.code && changes.code.firstChange) {
      this._registerServiceSubscriptions();
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _registerServiceSubscriptions() {
    this._subscriptions.push(
      this._actionButtonsService.state$
        .subscribe((data: { code: string, state: KeyValue }) => {
          setTimeout(() => {
            if (data.code === this.code) {
              this._updateState(data.state);
            }
          }, 100);
        }));

    this._subscriptions.push(
      this._actionButtonsService.stateForAll$
        .subscribe((data: { code: string, state: KeyValue }) => {
          setTimeout(() => {
            if (data.code === this.code) {
              const state = {};
              this.buttons.forEach(btn => {
                state[btn.code] = data.state;
              });
              this._updateState(state);
            }
          }, 100);
        }));
  }

  private _toggleButton(button: ActionButton) {
    Object.keys(button.toggle).forEach(prop => {
      button[prop] = button.toggle[prop];
    });
  }

  private _untoggleButton(button: ActionButton) {
    const original = this.config.find(x => x.code == button.code);

    Object.keys(original.toggle).forEach(prop => {
      button[prop] = original[prop];
    });
  }

  private _updateState(state: KeyValue) {
    Object.keys(state || {}).forEach(action => {
      if (state[action] !== undefined) {
        const button = this._getButtonByAction(this.buttons, action);

        if (button) {
          if (typeof (state[action]) == 'object') {
            this._updateProperties(button, state[action]);
          } else {
            button.enabled = state[action];
          }
        } else {
          console.warn(`Action button [${action}] is not defined`)
        }
      }
    });
  }

  private _updateProperties(button: ActionButton, props: any) {
    Object.keys(props).forEach(prop => {
      button[prop] = props[prop];
    });
  }

  private _getButtonByAction(buttons: Array<ActionButton>, action: string) {
    let button = buttons.find(x => x.code == action);

    if (!button) {
      buttons.forEach(btn => {
        if (btn.childrens && !button) {
          button = this._getButtonByAction(btn.childrens, action);
        }
      });
    }

    return button;
  }

  private _deepCloneButtons() {
    const buttons = deepClone<Array<ActionButton>>(this.config);

    this._reassignCallback(buttons, this.config);

    return buttons;
  }

  private _reassignCallback(clonedButtons: Array<ActionButton>, originalButtons: Array<ActionButton>) {
    clonedButtons.forEach(cloned => {
      const original = originalButtons.find(x => x.code == cloned.code);

      cloned.callback = original.callback;

      if (original.toggle && original.toggle.callback) {
        cloned.toggle.callback = original.toggle.callback;
      }

      if (cloned.childrens) {
        this._reassignCallback(cloned.childrens, original.childrens);
      }
    });
  }
}
