import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
export class CustomFormValidatorService {
    constructor() { }
    static ValidateRuleQuantity(max) {
        return (control) => {
            const rules = control.value && control.value.rules;
            if (rules && Object.keys(rules).length > max) {
                return { maxRuleQuantity: true };
            }
            return null;
        };
    }
    static ValidateCodeUnicity(entityHandler) {
        return (control) => {
            const entityCode = control.value ? control.value.trim() : undefined;
            if (this.detailCode != undefined) {
                return of(null);
            }
            if (entityCode != undefined && this.ownerPartyRoleInstanceCode != undefined) {
                return entityHandler.getEntityByCodeAndOwner(entityCode, this.ownerPartyRoleInstanceCode)
                    .pipe(map(entity => { return entity.code === entityCode ? { repeatedCode: true } : null; }), catchError(() => of(null)));
            }
            else {
                return of(null);
            }
        };
    }
    static ValidateDate(pattern) {
        return (control) => {
            const regex = new RegExp(pattern);
            if (regex.test(control.value)) {
                const year = parseInt(control.value.toString().slice(6));
                const minYear = 1753;
                const maxYear = 9999;
                if (year < minYear || year > maxYear) {
                    return { invalidDate: true };
                }
            }
            return null;
        };
    }
}
CustomFormValidatorService.maxRuleQuantityMessage = (n) => `Maximun rule quantity is ${n}`;
CustomFormValidatorService.repeatedCodeMessage = () => 'Repeated Code';
CustomFormValidatorService.invalidDate = () => 'Invalid Date';
CustomFormValidatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomFormValidatorService_Factory() { return new CustomFormValidatorService(); }, token: CustomFormValidatorService, providedIn: "root" });
