import { Injectable } from '@angular/core';
import { ModalService, ModalParamButton } from '../modal/modal.service';
import { MessageModalComponent, MessageModalParams } from 'src/app/modals/message-modal/message-modal.component';
import { Router } from '@angular/router';
import { CacheService } from 'ngx-emerios-all';

export interface ErrorResponse {
  type: string;
  message: Array<{ language: string, text: string }>;
}

export interface MessagePageParams {
  type: 'success' | 'info' | 'warning' | 'error';
  title?: string;
  message?: string;
  fromJourney?: boolean;
  requestCode?: string;

}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private _cache: CacheService,
    private _modal: ModalService,
    private _router: Router) { }

  public handle(error: ErrorResponse, avoidPageRedirect: boolean) {
    const contactSupport = 'Please contact support if the error persists.';
    let message: any = error.message && error.message;

    if (!message) {
      message = contactSupport;
    }

    if (typeof (message) == 'object' && message.length > 0) {
      message = message.find(x => x.language == 'en').text;
    } else {
      message = contactSupport;
    }

    switch (error.type) {
      case 'BusinessException':
        this.showError(message);
        break;
      case 'DeployException':
        this.showErrorAnActionOnOk(message, () => {
          if (!document.getElementById('btn_journey_deploy')) {
            this._refresh()
          }
        });
        break;
      case 'Unhandled':
      default:
        if (!avoidPageRedirect) {
          this._showUnhandledExceptionPage(message);
        }
        break;
    }
  }

  public showError(message: string, title?: string, items?: Array<string>) {
    const params = {
      title: title || 'An error has occurred',
      message: message,
      items: items,
      type: 'error',
      okButton: {
        text: 'Close'
      } as ModalParamButton
    } as MessageModalParams;

    this._modal.showCustom(MessageModalComponent, params);
  }

  public showErrorAnActionOnOk(message: string, okCallback: Function, title?: string) {
    const params = {
      title: title || 'An error has occurred',
      message: message,
      type: 'error',
      okButton: {
        text: 'Close',
        callback: okCallback,
      } as ModalParamButton
    } as MessageModalParams;

    this._modal.showCustom(MessageModalComponent, params);
  }


  public showPageErrorMessage(message: string) {
    this._goToPage({ type: 'error', message: message });
  }

  public showPageInfoMessage(title: string, message: string) {
    this._goToPage({ type: 'info', title: title, message: message });
  }

  public showPageMessage(params: MessagePageParams) {
    this._goToPage(params);
  }

  private _showUnhandledExceptionPage(message: string) {
    this._goToPage({ type: 'error', message: `An unhandled error has occurred. ${message}` });
  }

  private _goToPage(params: MessagePageParams) {
    if (!params.title) {
      params.title = 'We are sorry :(';
    }

    this._cache.set('message-page-payload', params);
    this._router.navigate([`msg/${params.type}`]);
  }

  private _refresh() {
    window.location.reload();
  }
}
