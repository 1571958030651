import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class RuleRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getAvailabilitySelectorByType(type, search, minSearchLength = 3) {
        const headers = new HttpHeaders()
            .append('restCache', 'true');
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            headers: headers,
            endpoint: `api/v1/rule/catalog-typeahead/${type}`,
            params: {
                minSearchLength: minSearchLength,
                search: search
            }
        }).pipe(map(result => { return { type: type, items: result }; }));
    }
}
RuleRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RuleRestService_Factory() { return new RuleRestService(i0.ɵɵinject(i1.RestService)); }, token: RuleRestService, providedIn: "root" });
