import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { GhostLoadingModule } from './ghost-loading/ghost-loading.module';

@NgModule({
  declarations: [
    LoadingBarComponent
  ],
  imports: [
    CommonModule,
    GhostLoadingModule
  ],
  exports: [
    GhostLoadingModule,
    LoadingBarComponent
  ]
})
export class LoadersModule { }
