export enum DetailViewQueryStr {
    FormOperationType = 'fot',
    ForceDetailViewChange = 'fdvc'
}

export enum JourneyContextQueryStr {
    FlowInstanceCode = 'jfic',
    FlowType = 'jft',
    Execution = 'jweei',
    Request = 'req',
    TenantCodeSource = 'tcs',
    TenantCodeTarget = 'tct',
    EntityInstanceCode = 'eic',
    EntityInstanceName = 'ein',
    EntityChangeRequestCode = 'ecrc',
    EntityOwnerPartyRoleInstanceCode = 'eopric',
    EntityDynaf = 'ed',
    ApprovalWorkEffortInstanceCode = 'aweic',
    IsPromoteRequest = 'ipr'
}