import { RestService } from 'ngx-emerios-all';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class GridRestService {
    constructor(_rest) {
        this._rest = _rest;
        this._silentErrorHeader = new HttpHeaders()
            .append('silentError', 'true');
    }
    getGenericGridDataAndDetinitions(source, detailCode = '') {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/${source}/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    getQuestionRulesGridDataAndDef(source, workEffortTypeLibraryCode, ownerPartyRoleInstanceCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/${source}/${workEffortTypeLibraryCode}/${ownerPartyRoleInstanceCode}/question-rules`
        });
    }
    getGenericGridDataAndDetinitionsV2(source, entity, codes, ownerPartyRoleInstanceCode) {
        let params = null;
        if (codes) {
            params = { parameters: codes };
        }
        if (ownerPartyRoleInstanceCode) {
            params = { ownerPartyRoleInstanceCode: ownerPartyRoleInstanceCode };
        }
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/list/${source}`,
            params: params
        });
    }
    getPaginatedData(source, entity, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/list/${source}`,
            body: payload,
        });
    }
    getHierarchyGridDataAndDetinitions(source, entity) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/hierarchy/${source}`
        });
    }
    getParentsRelationshipsDataAndDef(agGridSource, entity, detailCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/relationship/parents/list/${agGridSource}/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    getChildrenRelationshipsDataAndDef(agGridSource, entity, detailCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/relationship/children/list/${agGridSource}/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    getParentRelationshipsDataAndDefPaginated(agGridSource, entity, detailCode, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/relationship/parents/list/${agGridSource}/${detailCode}`,
            headers: this._silentErrorHeader,
            body: payload,
        }).pipe(catchError(() => this._catchGridError()));
    }
    getChildrenRelationshipsDataAndDefPaginated(agGridSource, entity, detailCode, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/relationship/children/list/${agGridSource}/${detailCode}`,
            headers: this._silentErrorHeader,
            body: payload,
        }).pipe(catchError(() => this._catchGridError()));
    }
    getGridDefinitionBySource(source) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/${source}/grid-column-definition`
        });
    }
    getGridDefinitionBySourceV2(source) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/${source}/grid-definition`
        });
    }
    getChangesGridData(changeRequest) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/history/${changeRequest}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => of([])));
    }
    getPromoteGridData(changeRequest) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/request/promote/${changeRequest}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => of([])));
    }
    getCampaignCombinations(detailCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/campaign/combinations/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    getQuestionsByOwnerAndContainerType(owner, containerType) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/question/owner-container/${owner}/${containerType}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    getQuestionsParentTrigger() {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/question/parent-trigger`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    getHierarchicalView(detailCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/request/execution/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => []));
    }
    getFlowJourneyHierarchicalView(type, detailCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort/flow/${type}/tree-view/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => []));
    }
    getTpvCampaignProducts(detailCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/campaign/products/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    getEventFlowView(detailCode, type) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort/flow/${type}/workeffort-events/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    getBookItems(detailCode, bookType) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${bookType}/items/${detailCode}`,
            headers: this._silentErrorHeader
        }).pipe(catchError(() => this._catchGridError()));
    }
    _catchGridError() {
        return of({
            metadata: { columnDefinition: [] },
            gridColumnDefinition: [],
            rows: []
        });
    }
}
GridRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GridRestService_Factory() { return new GridRestService(i0.ɵɵinject(i1.RestService)); }, token: GridRestService, providedIn: "root" });
