import { Component, OnInit, EventEmitter, OnChanges, SimpleChanges, forwardRef, OnDestroy, Input, Output } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, Validators, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subscription, of } from 'rxjs';
import { FieldDefinition, FieldAttributes } from 'src/app/models/form-field-definition.models';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { KeyValueType, KeyValue } from 'src/app/types/keyvalue.type';
import { MultiselectConfig, ElementResponse } from 'ngx-emerios-all';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { BaseCatalogItem } from 'src/app/models/catalog.models';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { FileRestService } from 'src/app/services/rest/file-rest/file-rest.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { catchError } from 'rxjs/operators';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';
import { JourneyHandlerService } from 'src/app/services/handler/journey-handler/journey-handler.service';
import { JourneyActivity } from 'src/app/models/journey.models';

const noOp = () => { };

export const CUSTOM_FILEUPLOAD_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileUploadComponent),
  multi: true
};

export interface InternalModel {
  fileName: string;
  userName: string;
  fileEntry?: FileSystemFileEntry;
  contentType?: string;
  base64?: string;
  base64ForThumbnail?: SafeResourceUrl;
  languageCode?: string;
  fileStorageReference?: string;
}

export interface FileData {
  fileName: string;
  base64: string;
}

export interface ViewModel {
  config: NxgFileDropConfig;
  langItems: Array<BaseCatalogItem>;
  showPreview: boolean;
  availableImageExtensions: Array<string>;
  availableDocumentExtensions: Array<string>;
  availableAudioExtensions: Array<string>;
}

export interface NxgFileDropConfig {
  multiple: boolean;
  accept: string;
}

export enum MimeType {
  Application = 'application/*',
  Image = 'image/*',
  Audio = 'audio/*'
}


export function LangSelectionValidator(fn: Function): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    if (!fn()) {
      return { 'invalidLangSelection': true };
    }

    return null;
  };
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.sass'],
  providers: [CUSTOM_FILEUPLOAD_CONTROL_VALUE_ACCESSOR]
})
export class FileUploadComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public forceValidation: EventEmitter<any>;
  @Input() public config: FieldDefinition;
  @Input() public isBookUpload: boolean;

  @Output("is-valid") public isValid: EventEmitter<ElementResponse> = new EventEmitter();
  @Output("file-event") public fileEvent: EventEmitter<KeyValue> = new EventEmitter();

  public control: FormControl;
  public form: FormGroup;
  public model = {
    availableImageExtensions: ['.bmp', '.gif', '.jpeg', '.jpg', '.png'],
    availableDocumentExtensions: ['.pdf', '.xlsx'],
    availableAudioExtensions: ['.mp3', '.wav', '.aiff']
  } as ViewModel;
  public internalModel: KeyValueType<InternalModel>;
  public dateTime: string;

  private _defaultImageThumbnail: string = './assets/img/image-thumbnail.jpg';
  private _multiselectConfiguration: KeyValueType<MultiselectConfig> = {};
  private _formSubscription: Subscription;
  private _subscriptions: Array<Subscription> = [];
  private _onChange: any;
  private _journeyActivity: JourneyActivity = 'Out';

  constructor(
    private _catalog: CatalogService,
    private _elementHelper: ElementHelperService,
    private _authHandler: AuthorizationHandlerService,
    private _fileRest: FileRestService,
    private _sanitizer: DomSanitizer,
    private _journeyHandler: JourneyHandlerService) { }

  writeValue(obj: KeyValueType<InternalModel>) {
    if (obj != this.control.value) {
      this.internalModel = obj;

      this._reconfigureFormGroup();
      this._setControlValue();
    }
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  onTouchedCallback: () => void = noOp;

  public readonly() {
    if (this._journeyActivity === 'In') {
      return false;
    } else {
      return this.config && !this.config.attributes.editable;
    }
  }

  public showTable() {
    return this.form && this.config && !this.config.fileUploadConfig.hideAttachmentBox;
  }

  public dropped(files: NgxFileDropEntry[]) {
    const filteredFiles = files
      .filter(x => x.fileEntry.isFile)
      .filter(x => this._validateExtension(x.relativePath));

    this.internalModel = {};
    filteredFiles.forEach(file => {
      let fileName = file.relativePath.replace(/ /g, '_').toLocaleLowerCase();
      this.internalModel[fileName] = {
        fileName: fileName,
        userName: this._authHandler.getCurrentUsername(),
        fileEntry: file.fileEntry as FileSystemFileEntry
      };
    });

    this._reconfigureFormGroup();
    this._updateInternalModel();
    this._revalidateControl();
  }

  public removeFile(file: InternalModel, index: number) {
    delete this.internalModel[file.fileName];
    this.form.removeControl(file.fileName);

    this._updateInternalModel();
    this._triggerEvent('file-removed', file.fileName);
  }

  public getMultiselectConfig(code: string) {
    if (!this._multiselectConfiguration[code]) {
      const attrs = {
        name: code,
        placeholder: '-- Select Language --',
        required: true,
        editable: true
      } as FieldAttributes;

      this._multiselectConfiguration[code] = this._elementHelper.getMultiSelectConfig(attrs, []);
      this._multiselectConfiguration[code].readonly = !this.config.attributes.editable;
      this._multiselectConfiguration[code].enableCheckAll = true;
      this._multiselectConfiguration[code].allowSearchFilter = false;
    }

    return this._multiselectConfiguration[code];
  }

  public getItemsForTable() {
    const items = Object.keys(this.internalModel || [])
      .map(x => this.internalModel[x]);

    return items;
  }

  public openFile(file: InternalModel) {
    if (file.fileEntry) {
      file.fileEntry.file(fileEntryFile => {
        this._showFile(fileEntryFile, file.fileName)
      });
    } else {
      this._fileRest.downloadFile(file.fileStorageReference)
        .subscribe(blob => this._showFile(blob, file.fileName));
    }
  }

  public getImageThumbnail(item: InternalModel) {
    return (item.contentType && !(item.contentType.includes('pdf') || item.contentType.includes('mp3')) && item.base64ForThumbnail)
      || this._defaultImageThumbnail;
  }

  public getImage(item: InternalModel) {
    if (item.contentType && item.contentType.startsWith('image')) {
      if (item.base64ForThumbnail) {
        return (item.base64ForThumbnail as any).changingThisBreaksApplicationSecurity;
      }
    }
  }

  public getControlErrors() {
    let errorMessage: string;

    if (this.control.errors) {
      if (this.control.errors.required) {
        errorMessage = 'This is required';
      } else if (this.control.errors.invalidLangSelection) {
        errorMessage = `You can only upload as much images as total languages you have. 
        (${this.model.langItems.length}) Or upload only 1 file with all languages selected.`;
      }
    }

    return errorMessage;
  }

  public multiselectChanged() {
    this.control.markAsDirty();
  }

  public languageSettingAvailable() {
    return this.config.fileUploadConfig.languageEnabled;
  }

  ngOnInit() {
    this._subscriptions.push(
      this._journeyHandler.journeyActivity$
        .subscribe(journeyAction => this._journeyActivity = journeyAction === 'Start' ? 'In' : 'Out')
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this._initializeFormControl();
      this._configureComponent();
    }

    if (changes.forceValidation && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => this._revalidateControl()));
    }

    this.dateTime = new Date().toLocaleString();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());

    if (this._formSubscription) {
      this._formSubscription.unsubscribe();
    }
  }

  private _initializeFormControl() {
    if (this.config.attributes.required && this.config.fileUploadConfig.languageEnabled) {
      this.control = new FormControl(undefined, [Validators.required, LangSelectionValidator(() => this._isValidSelection())]);
    } else {
      this.control = new FormControl(undefined);
    }
  }

  private _configureComponent() {
    this._catalog.getFullCatalog<BaseCatalogItem>(CatalogEnum.Language)
      .subscribe(catalog => {
        this.model.langItems = catalog.items;

        if (this.form) {
          Object.keys(this.form.controls).forEach(fileControl => {
            const value = this.form.controls[fileControl].value;

            if (value == undefined) {
              const newValue = this.model.langItems.map(x => x.code);
              this.form.controls[fileControl].setValue(newValue);
            }
          });
        }

      });

    this.model.showPreview = this.config.fileUploadConfig.showPreview;
    this.model.config = {
      multiple: this.config.fileUploadConfig.multiple,
      accept: this.config.fileUploadConfig.accept.join()
    };
  }

  private _revalidateControl() {
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
  }

  private _showFile(blob: Blob, fileName: string) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      var url = URL.createObjectURL(blob);

      window.open(url, '_blank')
    }
  }

  private _reconfigureFormGroup() {
    const form = {};

    Object.keys(this.internalModel)
      .forEach(prop => {
        const file = this.internalModel[prop];
        const value = file.languageCode ? [file.languageCode] : undefined;

        form[file.fileName] = new FormControl(value, Validators.required)
      });

    this.form = new FormGroup(form);

    if (this._formSubscription) {
      this._formSubscription.unsubscribe();
    }

    this._formSubscription = this.form.valueChanges
      .subscribe(value => this._updateInternalModel());

    // this._emitValidity();
  }

  private _validateExtension(relativePath: string) {
    const fileExtension = relativePath.substring(relativePath.lastIndexOf('.')).toLocaleLowerCase();
    const availableExtensions = this._getAvailableMimeTypes();

    return availableExtensions.includes(fileExtension);
  }

  private _getAvailableMimeTypes() {
    const mimeTypes: Array<string> = [];

    this.config.fileUploadConfig.accept.forEach((type: MimeType) => {
      switch (type) {
        case MimeType.Image:
          mimeTypes.push(...this.model.availableImageExtensions);
          break;
        case MimeType.Audio:
          mimeTypes.push(...this.model.availableAudioExtensions);
          break;
        case MimeType.Application:
          mimeTypes.push(...this.model.availableDocumentExtensions);
          break;
        default:
          mimeTypes.push(type);
          break;
      }
    });

    return mimeTypes;
  }

  private _isValidSelection() {
    if (!this.control) {
      return false;
    }

    const files: KeyValueType<InternalModel> = this.control.value;
    let isValid = true;

    if (files && this.model && this.model.langItems) {
      const langQty = this.model.langItems.length;
      const fileQty = Object.keys(files).length;

      // es valido cuando:
      // hay 1 file cargado con todos los idiomas seleccionados.
      if (fileQty === 1) {
        const fileName = Object.keys(this.internalModel)[0]
        const langSelected: Array<string> = this.form.controls[fileName].value || [];

        if (langSelected.length !== langQty) {
          isValid = false;
        }
      } else {
        // o 1 file por idioma
        let selectedLangs: Set<string> = new Set();
        Object.keys(this.form.controls).forEach(prop => {
          const value: Array<string> = this.form.controls[prop].value;
          if (value && value.length === 1) {
            selectedLangs.add(value[0]);
          }
        });

        if (selectedLangs.size !== fileQty) {
          isValid = false;
        }
      }
    }

    return isValid;
  }

  private _updateInternalModel() {
    this._setControlValue();

    Object.keys(this.internalModel)
      .forEach(prop => {
        const file = this.internalModel[prop];
        const reader = new FileReader();
        const value: Array<string> = this.form.controls[file.fileName].value || [];

        if (value.length === this.model.langItems.length) {
          this.internalModel[file.fileName].languageCode = undefined;
        } else {
          this.internalModel[file.fileName].languageCode = value[0];
        }

        if (file.fileEntry) {
          file.fileEntry.file(fileEntryFile => {
            reader.readAsDataURL(fileEntryFile);
            reader.onload = () => {
              const result = reader.result.toString();
              const base64 = result.substring(result.indexOf(',') + 1);

              this.internalModel[file.fileName].contentType = fileEntryFile.type;
              this.internalModel[file.fileName].base64 = base64;
              this._emitFileData(base64);
            };
          });
        }
      });

    this._populateBase64ForItems();

    if (this._onChange) {
      this._onChange(this.control.valid ? this.internalModel : undefined);
    }
  }

  private _emitFileData(base64: string) {
    const fileDataArray: Array<FileData> = [];

    for (let fileName in this.internalModel) {
      fileDataArray.push({
        fileName: fileName,
        base64: base64
      } as FileData);
    }

    this._triggerEvent('file-dropped', fileDataArray);
  }

  private _triggerEvent(key: string, value: any) {
    const keyValueObj: KeyValue = { [key]: value };

    this.fileEvent.emit(keyValueObj);
  }

  private _setControlValue() {
    if (!this.internalModel || Object.keys(this.internalModel).length === 0) {
      this.control.setValue(undefined);
    } else {
      this.control.setValue(this.internalModel);
    }
  }

  private _populateBase64ForItems() {
    Object.keys(this.internalModel).forEach(fileName => {
      const file = this.internalModel[fileName];

      if (file.fileEntry) {
        file.fileEntry.file(fileEntryFile => {
          this._getBase64FromBlob(file, fileEntryFile);
        });
      } else {
        this._fileRest.downloadFile(file.fileStorageReference, true)
          .pipe(catchError(() => of(undefined)))
          .subscribe(blob => this._getBase64FromBlob(file, blob));
      }
    });
  }

  private _getBase64FromBlob(file: InternalModel, blob: File) {
    if (blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        file.base64ForThumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString());
      }
    }
  }

  // private _emitValidity() {
  //   const isValid = this.control.status == 'VALID';

  //   this.isValid.emit({
  //     name: this.config.attributes.name,
  //     value: this.control.value,
  //     valid: isValid
  //   });
  // }
}
