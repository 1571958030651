import { ModalService } from '../../behavior/modal/modal.service';
import { SignatureModalComponent } from 'src/app/modals/signature-modal/signature-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "../../behavior/modal/modal.service";
export class ModalHelperService {
    constructor(_modal) {
        this._modal = _modal;
    }
    confirmSubmitRequest(callback) {
        this._modal.showCustom(SignatureModalComponent, {
            title: 'Submit',
            confirmText: 'Submit',
            message: 'You are going to <strong>submit</strong> the current request. Continue?',
            callback: callback
        });
    }
    confirmCancelRequest(callback) {
        this._showConfirm({
            title: 'Cancel',
            confirmText: 'Yes',
            message: 'You are going to <strong>cancel</strong> the current request. Continue?',
            callback: callback
        });
    }
    confirmApproveRequest(callback) {
        this._modal.showCustom(SignatureModalComponent, {
            title: 'Approve',
            confirmText: 'Approve',
            message: 'You are going to <strong>approve</strong> the current request. Continue?',
            callback: callback
        });
    }
    confirmRejectRequest(callback) {
        this._showConfirm({
            title: 'Reject',
            confirmText: 'Reject',
            message: 'You are going to <strong>reject</strong> the current request. Continue?',
            callback: callback
        });
    }
    confirmDeployRequest(callback) {
        this._showConfirm({
            title: 'Deploy',
            confirmText: 'Deploy',
            message: 'You are going to <strong>deploy</strong> the current request. Continue?',
            callback: callback
        });
    }
    confirmRemoveStageStep(callback) {
        this._showConfirm({
            title: 'Remove Selection',
            confirmText: 'Remove',
            message: 'You are going to remove the selected relationship. This action cannot be undone. Are you sure?',
            callback: callback
        });
    }
    confirmUnlink(callback) {
        this._showConfirm({
            title: 'Unlink',
            confirmText: 'Unlink',
            message: 'You are going to <strong>unlink</strong> the current entity. Continue?',
            callback: callback
        });
    }
    confirmDiscardChanges(callback) {
        this._showConfirm({
            title: 'Discard Changes',
            confirmText: 'Discard',
            message: 'You are going to <strong>Discard Changes</strong> of the current entity. Continue?',
            callback: callback
        });
    }
    confirmDiscardRequest(callback) {
        this._showConfirm({
            title: 'Discard Request',
            confirmText: 'Discard',
            message: 'You are going to <strong>Discard Changes</strong> of the current Request. Continue?',
            callback: callback
        });
    }
    confirmDiscardChangesDeployed(callback) {
        this._showConfirm({
            title: 'Discard Changes',
            confirmText: 'Discard',
            message: 'You are going to <strong>Discard Changes</strong> of the current entity and <strong>Recover</strong> the last deployed state of the Entity. Continue?',
            callback: callback
        });
    }
    showSavedSignatureMessage() {
        this._showMessage({
            title: 'Signature',
            buttonText: 'Close',
            message: 'The Signature has been saved correctly',
            callback: null
        });
    }
    _showConfirm(params) {
        const okButton = {
            text: params.confirmText,
            callback: params.callback
        };
        const cancelButton = {
            text: params.cancelText ? params.cancelText : 'Cancel',
            callback: params.cancelCallback
        };
        const initialState = {
            title: params.title,
            message: params.message,
            okButton: okButton,
            cancelButton: cancelButton
        };
        this._modal.showGeneric(initialState);
    }
    _showMessage(params) {
        const closeButton = {
            text: params.buttonText,
            callback: params.callback
        };
        const initialState = {
            title: params.title,
            message: params.message,
            okButton: closeButton
        };
        this._modal.showGeneric(initialState);
    }
}
ModalHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalHelperService_Factory() { return new ModalHelperService(i0.ɵɵinject(i1.ModalService)); }, token: ModalHelperService, providedIn: "root" });
