import { DefaultDataModelService } from './default-data-model/default-data-model.service';
import { CatalogMultipleDataModelService } from './catalog-multiple-data-model/catalog-multiple-data-model.service';
import { CatalogSingleDataModelService } from './catalog-single-data-model/catalog-single-data-model.service';
import { InputDateDataModelService } from './input-date-data-model/input-date-data-model.service';
import { InputLangDataModelService } from './input-lang-data-model/input-lang-data-model.service';
import { FileUploadDataModelService } from './file-upload-data-model/file-upload-data-model.service';
import { ChainedFilterDataModelService } from './chained-filter-data-model/chained-filter-data-model.service';
import { TabbedRuleCreatorDataModelService } from './tabbed-rule-creator-data-model/tabbed-rule-creator-data-model.service';
import { RulesGridDataModelService } from './rules-grid-data-model/rules-grid-data-model.service';
import { SortableListDataModelService } from './sortable-list-data-model/sortable-list-data-model.service';
import { DateTimePickerDataModelService } from './date-time-picker-data-model/date-time-picker-data-model.service';
import * as i0 from "@angular/core";
export class DataModelFactoryService {
    constructor() { }
    createDataModel(fieldDefinition) {
        switch (fieldDefinition.type) {
            case 'catalog-single':
            case 'grid-catalog-single':
                return new CatalogSingleDataModelService(fieldDefinition);
            case 'catalog-multiple':
            case 'grid-catalog-multiple':
                return new CatalogMultipleDataModelService(fieldDefinition);
            case 'input-date':
                return new InputDateDataModelService();
            case 'input-lang':
            case 'textarea-lang':
                return new InputLangDataModelService(fieldDefinition);
            case 'chained-filter':
                return new ChainedFilterDataModelService(fieldDefinition);
            case 'tabbed-rule-creator':
                return new TabbedRuleCreatorDataModelService(fieldDefinition);
            case 'file-upload':
                return new FileUploadDataModelService();
            case 'sortable-list':
                return new SortableListDataModelService();
            case 'rules-grid':
                return new RulesGridDataModelService(fieldDefinition);
            case 'datetime-picker':
                return new DateTimePickerDataModelService(fieldDefinition);
            default:
                return new DefaultDataModelService();
        }
    }
}
DataModelFactoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataModelFactoryService_Factory() { return new DataModelFactoryService(); }, token: DataModelFactoryService, providedIn: "root" });
