import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { Entity } from 'src/app/models/entity.model';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class EntityRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getEntityList() {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/`
        });
    }
    getDataEntity(urlSection, detailCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/${detailCode}/`
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    getByCodeAndOwnerPartyRoleInstanceCode(urlSection, code, ownerPartyRoleInstanceCode) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/getByCodeAndOwner/${code}/${ownerPartyRoleInstanceCode}`,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    saveAndLinkEntity(urlSection, requestCode, payload) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/${requestCode}`,
            body: payload,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    updateAndLinkEntity(urlSection, requestCode, payload) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.put({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/${requestCode}`,
            body: payload,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    saveEntity(urlSection, payload) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}`,
            body: payload,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    updateEntity(urlSection, payload) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.put({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}`,
            body: payload,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    saveAndLinkEntityWithRelationship(urlSection, requestCode, payload) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/saveWithRelationship/${requestCode}`,
            body: payload,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    updateAndLinkEntityWithRelationship(urlSection, requestCode, payload) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.put({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/updateWithRelationship/${requestCode}`,
            body: payload,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    saveEntityWithRelationship(urlSection, payload) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/saveWithRelationship`,
            body: payload,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    updateEntityWithRelationship(urlSection, payload) {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.put({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/updateWithRelationship`,
            body: payload,
            headers: headers
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    cloneEntity(urlSection, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/clone`,
            body: payload
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    cloneFlow(urlSection, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/flow-clone`,
            body: payload
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    deepClone(urlSection, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/deep-clone`,
            body: payload
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    tenantComparer(source, target) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/organization/compare/${source}/${target}`
        }).pipe(map(response => Object.assign(new Entity(), response))).pipe(catchError((error) => {
            if (error.status === 409) {
                return of(error);
            }
            throw error;
        }));
    }
    createEntityRelationships(entity, paylod) {
        const endpoint = entity.endsWith('-book')
            ? `api/v1/${entity}/relationship/book`
            : `api/v1/${entity}/relationship`;
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: endpoint,
            body: paylod
        });
    }
    deleteEntityRelationships(entity, relations) {
        return this._rest.delete({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/relationship`,
            body: relations
        });
    }
    getRequest(code) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/request/${code}`
        });
    }
    updateSequence(entity, sequences) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${entity}/relationship/update-sequence`,
            body: sequences
        });
    }
    confirmDiscardChanges(urlSection, instanceCode) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/confirm-discard/${instanceCode}`,
        }).pipe(map(response => Object.assign(new Entity(), response)));
    }
    validateDiscardChanges(urlSection, instanceCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${urlSection}/validate-discard/${instanceCode}`,
        });
    }
    getFormDefinitionByFormInstanceCode(detailCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/form-definition/${detailCode}`
        });
    }
}
EntityRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntityRestService_Factory() { return new EntityRestService(i0.ɵɵinject(i1.RestService)); }, token: EntityRestService, providedIn: "root" });
