import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GridComponent } from 'src/app/components/grid/grid.component';
import { AgGridModule } from 'ag-grid-angular';
import { GridCustomPanelComponent } from '../grid-custom-panel/grid-custom-panel.component';
import { GridPaginationComponent } from '../grid-pagination/grid-pagination.component';

const toExport = [
    GridComponent,
    GridPaginationComponent,
    GridCustomPanelComponent
];

@NgModule({
    declarations: [toExport],
    imports: [
        CommonModule,
        FormsModule,
        AgGridModule.withComponents([GridCustomPanelComponent])
    ],
    exports: [toExport]
})
export class GridModule { }
