import { OnInit, OnChanges, SimpleChanges, forwardRef, EventEmitter, OnDestroy } from '@angular/core';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { NG_VALUE_ACCESSOR, FormControl, Validators } from '@angular/forms';
import { ZipcodeGridModalComponent } from 'src/app/modals/zipcode-grid-modal/zipcode-grid-modal.component';
import { ModalService } from 'src/app/services/behavior/modal/modal.service';
import { of } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, switchMap, catchError, finalize, map } from 'rxjs/operators';
import { ChainedFilterService } from 'src/app/services/behavior/chained-filter/chained-filter.service';
import { FieldValueHandlerService, isSameField } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';
import { CatalogEnum } from 'src/app/enums/catalogs';
const noOp = () => { };
const ɵ0 = noOp;
export const CHAINED_FILTER_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChainedFilterComponent),
    multi: true
};
export class ChainedFilterComponent {
    constructor(_catalogs, _chainedFilter, _modal, _fieldValueHandler, _detailViewHandler) {
        this._catalogs = _catalogs;
        this._chainedFilter = _chainedFilter;
        this._modal = _modal;
        this._fieldValueHandler = _fieldValueHandler;
        this._detailViewHandler = _detailViewHandler;
        this.selectedItems = new EventEmitter();
        this.isValid = new EventEmitter();
        this.model = {};
        this.multiselectConfig = {};
        this.multiselectItems = {};
        this.searchInput = new EventEmitter();
        this._subscriptions = [];
        this.onTouchedCallback = noOp;
        this.model.filtersAdded = [];
        this.model.totalItemCount = 0;
    }
    writeValue(obj) {
        if (obj != this.model.selectedItems) {
            this.model.preselectedItems = obj;
        }
        if (!this.config.chainedFilterDefinition.filterTypeBy) {
            this._configFilterType();
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    addFilter() {
        this._addFilterType(this.model.filterTypeSelected[0]);
        this.control.markAsDirty();
    }
    removeFilter(filter) {
        const indexOf = this.model.filtersAdded.indexOf(filter);
        const item = this.model.filtersAdded.splice(indexOf, 1)[0];
        this.model.filterTypes.push(item);
        this.model.filterTypes = Object.assign([], this.model.filterTypes);
        delete this.model.partialItemCount[filter.code];
        delete this.model.selectedItems[filter.code];
        this._validateFilterTypeEnability();
        this._updateTotalCount();
        this.control.markAsDirty();
    }
    getMultiselectConfig(item) {
        if (!this.multiselectConfig[item.code]) {
            let config = {
                name: item.code,
                itemCode: 'code',
                itemText: 'description',
                cssClasses: 'form-control',
                placeholder: `-- Choose a ${item.description} --`,
                enableCheckAll: true,
                allowSearchFilter: true,
                fillParentWidth: true,
                clearText: "<i class='fas fa-times'></i>",
                itemsShowLimit: 1,
                selectAllText: 'Select All',
                unselectAllText: 'Unselect All',
                listHeight: 5
            };
            this.multiselectConfig[item.code] = config;
            this.multiselectConfig[item.code].readonly = !this.config.attributes.editable;
        }
        return this.multiselectConfig[item.code];
    }
    getMultiselectItems(filter) {
        if (!this.multiselectItems[filter.code]) {
            this.multiselectItems[filter.code] = [];
        }
        return this.multiselectItems[filter.code];
    }
    onSearch(type, text) {
        if (this.config.chainedFilterDefinition.catalogsLoadingType &&
            this.config.chainedFilterDefinition.catalogsLoadingType[type] === 'eager') {
            return;
        }
        this.searchInput.next({ type, text });
    }
    onDropdownClosed(filter) {
        const selectedItems = this.model.selectedItems[filter.code];
        if (this.model.partialItemCount[filter.code] === undefined) {
            this.model.partialItemCount[filter.code] = undefined;
        }
        if (selectedItems && selectedItems.length > 0) {
            if (this.config.chainedFilterDefinition.showPartialFilterCounter) {
                this._getItemQuantity(selectedItems, filter);
            }
        }
        this._validateFilterTypeEnability();
    }
    fireAction(event) {
        switch (event) {
            case 'view':
                this._openModal();
                break;
            case 'export':
                console.log(event);
                break;
            default:
                console.log('Invalid Action Selected!');
                break;
        }
    }
    getControlErrors() {
        let errorMessage;
        if (this.control.errors && this.control.errors.required) {
            errorMessage = 'This is required';
        }
        return errorMessage;
    }
    ngOnInit() {
        this._registerSearch();
        this._subscriptions.push(this._fieldValueHandler.anyValue$
            .pipe(distinctUntilChanged(isSameField))
            .subscribe(anyValue => this._handleExternalValueChange(anyValue)));
        this._subscriptions.push(this._detailViewHandler.mode$
            .subscribe(viewMode => this._viewMode = viewMode));
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            this.chainedFilterConfig = this.config.chainedFilterDefinition;
            if (this.config.attributes.required) {
                this.control = new FormControl(undefined, Validators.required);
            }
            else {
                this.control = new FormControl(undefined);
            }
            if (changes.config.firstChange) {
                setTimeout(() => {
                    if (!this._filterTypeConfigured) {
                        const filterTypeBy = this.config.chainedFilterDefinition.filterTypeBy;
                        if (filterTypeBy) {
                            const value = this._anyValueEvent.changes.get(filterTypeBy);
                            this._configFilterType(value[0].code);
                        }
                        else {
                            this._configFilterType();
                        }
                    }
                }, 500);
            }
        }
        if (changes.forceValidation && changes.forceValidation.currentValue && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => {
                this.control.markAsDirty();
                this.control.markAsTouched();
                this.control.updateValueAndValidity();
            }));
        }
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }
    _registerSearch() {
        this.searchInput.pipe(filter((input) => input.text.length > 3), debounceTime(500), distinctUntilChanged(), switchMap((input) => this._chainedFilter.searchItemsByType(this.config.chainedFilterDefinition.code, input.type, input.text)
            .pipe(catchError(() => of({ type: input.type, items: [] }))))).subscribe(result => {
            this._setItemsForMultiselect(result);
        });
    }
    _setItemsForMultiselect(result) {
        const unselectedItems = this._removeExistingItems(result.type, result.items);
        this.multiselectItems[result.type] = unselectedItems;
    }
    _removeExistingItems(type, items) {
        let currentItems = this.model.selectedItems[type];
        if (!currentItems) {
            currentItems = [];
        }
        return items ? items.filter(x => !currentItems.includes(x.code)) : [];
    }
    _getItemQuantity(selectedItems, filter) {
        this.multiselectConfig[filter.code].disabled = true;
        this._chainedFilter.getItemQuantity(this.config.chainedFilterDefinition.code, [...selectedItems])
            .pipe(finalize(() => this.multiselectConfig[filter.code].disabled = this._viewMode === FormOperationType.View))
            .subscribe(count => {
            this.model.partialItemCount[filter.code] = count;
            this._updateTotalCount();
        });
    }
    _updateTotalCount() {
        this.model.totalItemCount = Object.keys(this.model.partialItemCount)
            .map(prop => this.model.partialItemCount[prop])
            .reduce((a, b) => a + b, 0);
    }
    _handleExternalValueChange(anyValue) {
        const filterTypeBy = this.config.chainedFilterDefinition.filterTypeBy;
        const value = anyValue.changes.get(filterTypeBy);
        this._anyValueEvent = anyValue;
        if (!this._filterTypeConfigured && value != undefined) {
            if (anyValue.currentField.name === filterTypeBy) {
                this._filterTypeConfigured = true;
                this._configFilterType(value[0].code);
            }
        }
        if (this.config.chainedFilterDefinition.code === 'tpv_step_filter') {
            if (anyValue.currentField.name === 'ownerPartyRoleInstanceCode') {
                this.ownerPartyRoleInstanceCode = anyValue.currentField.value[0].code;
                this._loadLazyCatalogs(CatalogEnum.BrandOrganization);
            }
        }
    }
    _loadLazyCatalogs(catalog) {
        if (this.ownerPartyRoleInstanceCode) {
            this._catalogs.getSingleWithFilterFromServer(catalog, [this.ownerPartyRoleInstanceCode])
                .subscribe(result => {
                var entityCatalogItem = { type: result.code, items: result.items };
                this._setItemsForMultiselect(entityCatalogItem);
            });
        }
    }
    _configFilterType(filterBy) {
        let obs;
        obs = filterBy
            ? this._catalogs.getSingleWithFilterFromServer(this.config.chainedFilterDefinition.filterTypeCatalog, [filterBy])
            : this._catalogs.getFromServer([this.config.chainedFilterDefinition.filterTypeCatalog]).pipe(map(x => x[0]));
        obs.subscribe(catalog => {
            this.model.filtersAdded = [];
            this.model.selectedItems = {};
            this.model.partialItemCount = {};
            this.model.totalItemCount = 0;
            this.model.filterTypes = catalog.items;
            this._configFilterTypeElement();
            if (this.config.chainedFilterDefinition.catalogsLoadingType) {
                this._fetchEagerCatalogs(this.config.chainedFilterDefinition.catalogsLoadingType);
            }
            if (this.model.preselectedItems) {
                this._initWithData();
            }
            else {
                if (this.config.chainedFilterDefinition.preselectedFilter) {
                    this._setPreselectedFilters();
                }
                this.control.setValue(undefined);
            }
        });
    }
    _setPreselectedFilters() {
        const preselected = this.model.filterTypes
            .filter(filterType => this.config.chainedFilterDefinition.preselectedFilter.includes(filterType.code));
        preselected.forEach(filterType => {
            this._addFilterType(filterType.code);
            this.model.selectedItems[filterType.code] = undefined;
        });
        this._validateFilterTypeEnability();
    }
    _initWithData() {
        Object.keys(this.model.preselectedItems)
            .forEach(filterTypeCode => {
            const filterType = this.model.filterTypes.find(filterType => filterType.code === filterTypeCode);
            const filterTypeIndex = this.model.filterTypes.indexOf(filterType);
            if (filterType) {
                this.model.filterTypes.splice(filterTypeIndex, 1);
                this.model.filtersAdded.push(filterType);
                this.model.partialItemCount[filterTypeCode] = 0;
                this.model.selectedItems[filterTypeCode] = this.model.preselectedItems[filterTypeCode]
                    .map((x) => x.code);
                this.getMultiselectConfig(filterType);
                this._getItemQuantity(this.model.selectedItems[filterTypeCode], filterType);
            }
            else {
                // if filterType is not found, clear preselectedItems to avoid errors
                this.model.preselectedItems = undefined;
            }
        });
        this.multiselectItems = Object.assign({}, this.model.preselectedItems);
        this._validateFilterTypeEnability();
    }
    _fetchEagerCatalogs(catalogsLoadingType) {
        Object.keys(catalogsLoadingType)
            .forEach(prop => {
            if (catalogsLoadingType[prop] === 'eager') {
                this._chainedFilter.getAllItemsByType(this.config.chainedFilterDefinition.code, prop)
                    .subscribe(result => {
                    this._setItemsForMultiselect(result);
                });
            }
            if (catalogsLoadingType[prop] === 'lazy') {
                const ownerElement = this._fieldValueHandler.getAnyValue().changes.get('ownerPartyRoleInstanceCode');
                if (ownerElement) {
                    this.ownerPartyRoleInstanceCode = ownerElement[0].code;
                }
                this._loadLazyCatalogs(prop);
            }
        });
    }
    _addFilterType(filterTypeCode) {
        const filterType = this.model.filterTypes
            .find(item => item.code === filterTypeCode);
        if (filterType) {
            const indexOf = this.model.filterTypes.indexOf(filterType);
            const item = this.model.filterTypes.splice(indexOf, 1)[0];
            this.model.filterTypes = Object.assign([], this.model.filterTypes);
            this.model.filtersAdded.push(item);
            this.model.selectedItems[filterTypeCode] = undefined;
            // clearly a hack related with angular model check
            setTimeout(() => this.model.filterTypeSelected = undefined);
            this._validateFilterTypeEnability();
        }
    }
    _validateFilterTypeEnability() {
        let someEmpty = false;
        Object.keys(this.model.selectedItems)
            .forEach(prop => {
            if (this.model.selectedItems[prop] === undefined || this.model.selectedItems[prop].length === 0) {
                someEmpty = true;
            }
        });
        this.filterTypeConfig.disabled = someEmpty;
        this._setControlValue();
    }
    _configFilterTypeElement() {
        this.filterTypeConfig = {};
        this.filterTypeConfig.id = 'filterType';
        this.filterTypeConfig.name = 'filterType';
        this.filterTypeConfig.cssClasses = 'form-control';
        this.filterTypeConfig.placeholder = '-- Select Filter --';
        this.filterTypeConfig.fillParentWidth = true;
        this.filterTypeConfig.itemCode = 'code';
        this.filterTypeConfig.itemText = 'description';
        this.filterTypeConfig.singleSelect = true;
        this.filterTypeConfig.readonly = !this.config.attributes.editable;
    }
    _openModal() {
        this._chainedFilter.getFilteredItems(this.config.chainedFilterDefinition.code, this.model.selectedItems)
            .subscribe(data => {
            const params = {};
            params.title = `Total filtered ${this.config.chainedFilterDefinition.workingObjectLabel}`;
            params.size = 'lg';
            params.data = this._setDataForTable(data);
            this._modal.showCustom(ZipcodeGridModalComponent, params);
        });
    }
    _setDataForTable(data) {
        const result = [];
        for (let row in data) {
            result.push(Object.keys(data[row])
                .filter(element => element != 'geoCode')
                .map(element => data[row][element]));
        }
        return result;
    }
    _setControlValue() {
        if (this.selectedItems) {
            this.selectedItems.emit(this.model.selectedItems);
        }
        // if some selector is empty or if there are no selecters added
        if (this.filterTypeConfig.disabled || Object.keys(this.model.selectedItems).length === 0) {
            this.control.setValue(undefined);
        }
        else {
            this.control.setValue(this.model.selectedItems);
        }
        this._onChange(this.control.value);
        const isValid = this.control.status == 'VALID';
        this.isValid.emit({
            name: this.config.attributes.name,
            value: this.control.value,
            valid: isValid
        });
    }
}
export { ɵ0 };
