import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationHelperService } from '../services/helper/navigation-helper/navigation-helper.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationHandlerService } from '../services/handler/authorization-handler/authorization-handler.service';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

    private _lastUrl: string;

    constructor(
        private _router: Router,
        private _authHandler: AuthorizationHandlerService,
        private _toastr: ToastrService,
        private _navHelper: NavigationHelperService) {

        this._router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => this._lastUrl = e.url);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this._authHandler.getToken();

        if (token) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
            });
        }

        return next.handle(request)
            .pipe(
                catchError((response: HttpErrorResponse) => {
                    if (response.status === 401) {
                        this.handle401Error(request, next, response);
                    }

                    throw response;
                })
            );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler, error: any) {
        this._authHandler.logout();
        this._navHelper.goToLogin(this._lastUrl);
        this._toastr.error('Session expired', '', { positionClass: 'toast-top-center' })
    }
}

export let AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
};
