<ng-container [formGroup]="form">
    <div class="col-xs-12 col-sm-10 col-md-9 col-lg-7">
        <app-file-upload [config]="config" [formControlName]="uploadControlName" [isBookUpload]="true"
            (is-valid)="isElementValid($event)" (file-event)="fileEvent($event)">
        </app-file-upload>
    </div>
    <div class="clearfix"></div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
        <div *ngFor="let previewGrid of previewGridDataModel; let i = index" class="grid grid-70">
            <hr class="dashed-line">
            <app-grid [data]="previewGrid" (selectionChanged)="selectionChanged($event)"></app-grid>
        </div>
    </div>
    <div class="clearfix"></div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-3" *ngIf="showErrorsPanel">
        <div>
            <p>
                <i class="fas fa-exclamation-triangle"></i>
                The process of incoming changes has produced <strong>errors</strong>, please check the registry errors
                below
            </p>
            <div class="error-log-container">
                <li *ngFor="let error of fileErrorList">
                   <b>Sheet:</b> {{error.SheetName}}, <b>Column:</b> {{error.GroupHeaderName}}, <b>Line:</b> {{error.RowIndex}}, <b>Error:</b> {{error.Messages[0].Text}}
                </li>
                <li *ngIf="errorMessage">
                   <b>Error:</b> {{errorMessage}}
                </li>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-3">
        <hr class="dashed-line">
        <button class="btn btn-default btn-section mr-2" (click)="declineClicked()">Decline</button>
        <button class="btn btn-default btn-section btn-fill mr-2" (click)="confirmClicked()"
            [disabled]="!form.valid">Confirm</button>
    </div>
</ng-container>
