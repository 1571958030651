import { Injectable } from '@angular/core';
import { ValidationIconClass, InputConfig, InputMask, ValidationMessages, SelectConfig, MultiselectConfig, TextareaConfig, DatepickerConfig, ElementConfig } from 'ngx-emerios-all';
import { FieldAttributes, FieldDefinition } from 'src/app/models/form-field-definition.models';
import { InputLangConfig } from 'src/app/components/input-lang/input-lang.model';
import { ValidatorFn, Validators, AsyncValidatorFn } from '@angular/forms';
import { CustomFormValidatorService } from '../../behavior/custom-form-validator/custom-form-validator.service';
import { EntityHandlerService } from '../../handler/entity-handler/entity-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ElementHelperService {

  constructor(private _entityHandler: EntityHandlerService) { }

  public getElementConfigurations(fields: Array<FieldDefinition>) {
    const elementConfigurations = {};

    fields.forEach(element => {
      switch (element.type) {
        case 'input-text':
        case 'input-date':
        case 'datetime-picker':
          elementConfigurations[element.field] = this.getInputConfig(element.attributes);
          break;
        case 'textarea':
        case 'textarea-form':
          elementConfigurations[element.field] = this.getTextareaConfig(element.attributes);
          break;
        case 'input-lang':
          elementConfigurations[element.field] = this.getInputLangConfig(element.attributes);
          elementConfigurations[element.field].items = element.catalogItems;
          break;
        case 'textarea-lang':
          elementConfigurations[element.field] = this.getTextareaLangConfig(element.attributes);
          elementConfigurations[element.field].items = element.catalogItems;
          break;
        case 'catalog-single':
          elementConfigurations[element.field] = this._getMultiSelectConfig(element.attributes, element.catalogCodeProperty, element.catalogItems);
          elementConfigurations[element.field].singleSelect = true;
          break;
        case 'catalog-multiple':
          elementConfigurations[element.field] = this._getMultiSelectConfig(element.attributes, element.catalogCodeProperty, element.catalogItems);
          break;
        default:
          elementConfigurations[element.field] = this.getBaseConfig(element.attributes);
          elementConfigurations[element.field].fieldDefinition = element;
          break;
      }
    });

    return elementConfigurations;
  }

  public getDefaultValidationIcons(): ValidationIconClass {
    return {
      pristine: 'fas fa-asterisk text-muted',
      success: 'fas fa-check text-success',
      error: 'fas fa-times text-danger'
    };
  }

  public getBaseConfig(attr: FieldAttributes) {
    const config = {
      cssClasses: `form-control`,
      id: `id_${attr.name}`,
      name: attr.name,
      errorMsgClass: 'input-error',
      validationIcons: this.getDefaultValidationIcons()
    } as ElementConfig;

    config.validators = this._getValidators(attr);
    config.asyncValidators = this._getAsyncValidators(attr);
    config.validationMessages = this._getValidatorMessages(attr);

    return config;
  }

  public getInputConfig(attr: FieldAttributes) {
    const config = this._getGenericInputConfig(attr) as InputConfig;

    config.autocomplete = false;

    if (attr.mask) {
      config.mask = {} as InputMask;
      config.mask.mask = attr.mask;
      config.mask.showMaskTyped = true;
    }

    return config;
  }

  public getInputLangConfig(attr: FieldAttributes) {
    const config = this.getInputConfig(attr) as InputLangConfig;

    config.itemCode = 'code';
    config.itemText = 'description';

    return config;
  }

  public getTextareaLangConfig(attr: FieldAttributes) {
    const config = this.getTextareaConfig(attr) as InputLangConfig;

    config.itemCode = 'code';
    config.itemText = 'description';

    return config;
  }

  public getTextareaConfig(attr: FieldAttributes) {
    const config = this._getGenericInputConfig(attr) as TextareaConfig;

    config.cssClasses = 'form-control form-textarea';

    return config;
  }

  public getDatepickerConfig(attr: FieldAttributes) {
    const config = this._getGenericInputConfig(attr) as DatepickerConfig;

    config.bsConfig = { dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue' }

    return config;
  }

  //** DEPRECATED **/
  public getSingleSelectConfig(attr: FieldAttributes, items: Array<any>) {
    const config = this._getGenericSelectConfig(attr, undefined, items) as SelectConfig;

    config.defaultTextItem = '-- Select --';

    return config;
  }

  public getMultiSelectConfig(attr: FieldAttributes, items: Array<any> = []) {
    return this._getMultiSelectConfig(attr, undefined, items);
  }

  private _getMultiSelectConfig(attr: FieldAttributes, catalogCodeProperty: string = 'code', items: Array<any> = []) {
    const config = this._getGenericSelectConfig(attr, catalogCodeProperty, items) as MultiselectConfig;

    config.placeholder = attr.placeholder || '-- Select --';
    config.fillParentWidth = true;
    config.itemsShowLimit = 1;
    config.allowSearchFilter = true;
    config.clearText = "<i class='fas fa-times'></i>";
    config.listHeight = 5;
    config.singleSelect = false;

    return config;
  }

  private _getGenericInputConfig(attr: FieldAttributes) {
    const config = this.getBaseConfig(attr) as InputConfig | TextareaConfig | DatepickerConfig;

    config.type = attr.type;
    config.readonly = !attr.editable;
    config.placeholder = attr.placeholder;

    return config;
  }

  private _getGenericSelectConfig(attr: FieldAttributes, catalogCodeProperty: string = 'code', items: Array<any>) {
    const config = this.getBaseConfig(attr) as SelectConfig | MultiselectConfig;

    config.items = items;
//    config.itemCode = catalogCodeProperty;
    config.itemCode = 'code';
    config.itemText = 'description';
    config.readonly = !attr.editable;

    return config;
  }

  private _getValidators(attr: FieldAttributes) {
    const validators = new Array<ValidatorFn>();

    if (attr.required) {
      validators.push(Validators.required);
    }

    if (attr.min != undefined) {
      validators.push(Validators.min(attr.min));
    }

    if (attr.max != undefined) {
      validators.push(Validators.max(attr.max));
    }

    if (attr.minlength != undefined) {
      validators.push(Validators.minLength(attr.minlength));
    }

    if (attr.maxlength != undefined) {
      validators.push(Validators.maxLength(attr.maxlength));
    }

    if (attr.pattern) {
      validators.push(Validators.pattern(attr.pattern));
    }

    if (attr.maxRuleQuantity) {
      validators.push(CustomFormValidatorService.ValidateRuleQuantity(attr.maxRuleQuantity));
    }

    if(attr.dateValidation) {
      validators.push(CustomFormValidatorService.ValidateDate(attr.pattern))
    }

    return validators;
  }

  private _getAsyncValidators(attr: FieldAttributes) {
    const validators = new Array<AsyncValidatorFn>();

    if (attr.repeatedCode) {
      validators.push(CustomFormValidatorService.ValidateCodeUnicity(this._entityHandler));
    }

    return validators;
  }

  private _getValidatorMessages(attr: FieldAttributes) {
    const validationMessages = {} as ValidationMessages;

    if (attr.required) {
      validationMessages.required = 'This is required';
    }

    if (attr.min != undefined) {
      validationMessages.min = `Minimum value is ${attr.min}`;
    }

    if (attr.max != undefined) {
      validationMessages.max = `Maximum value is ${attr.max}`;
    }

    if (attr.minlength != undefined) {
      validationMessages.minlength = `Minimum length is ${attr.minlength}`;
    }

    if (attr.maxlength != undefined) {
      validationMessages.maxlength = `Maximum length is ${attr.maxlength}`
    }

    if (attr.pattern) {
      validationMessages.pattern = 'Invalid value';
    }

    if (attr.maxRuleQuantity) {
      validationMessages.maxRuleQuantity = CustomFormValidatorService.maxRuleQuantityMessage(attr.maxRuleQuantity);
    }

    if (attr.repeatedCode) {
      validationMessages.repeatedCode = CustomFormValidatorService.repeatedCodeMessage();
    }

    if(attr.dateValidation){
      validationMessages.invalidDate = CustomFormValidatorService.invalidDate();
    }

    return validationMessages;
  }

}
