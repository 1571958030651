import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/store/store.service';
import { AuthRestService } from 'src/app/services/rest/auth-rest/auth-rest.service';
import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { ChangeManagementRestService } from 'src/app/services/rest/change-management-rest/change-management-rest.service';
import { interval, Subscription, combineLatest } from 'rxjs';
import { flatMap, takeWhile, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from 'ngx-emerios-all';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';
import { capitalizeInitials } from 'src/app/functions/capitalize';
import { AvailableTenant } from 'src/app/models/available-tenant.model';

export interface PageHeaderModel {
  pendingRequests: number;
  authenticated: boolean;
  workingOrganization: AvailableTenant;
  displayName: string;
  initials: string;
  lang: string;
  isMobile: boolean;
  showOnlyLogout: boolean;
}

export function isSameObject(prevValue: any, nextValue: any) {
  return (
    (prevValue[0] === nextValue[0]) && (prevValue[0] === nextValue[0]) &&
    ((prevValue[1] && prevValue[1].code) === (nextValue[1] && nextValue[1].code))
  );
}

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('navBar', { static: false })
  public navBar: ElementRef;

  public model = {} as PageHeaderModel;
  public langSelected: string;

  private _langDefault = 'en';
  private _subscriptions: Array<Subscription> = [];

  constructor(
    public translate: TranslateService,
    private _appStore: StoreService,
    private _cache: CacheService,
    private _changeManagement: ChangeManagementRestService,
    private _auth: AuthRestService,
    private _authHandler: AuthorizationHandlerService,
    private _navHelper: NavigationHelperService) { }

  public logout() {
    this._authHandler.logout();
    this._navHelper.goToLogin();
  }

  public langChange(lang: string, manualChange: boolean = true) {
    this.translate.use(lang);
    this.model.lang = lang;

    localStorage.setItem('lang', lang);

    this._cache.set('manualChange', manualChange);
    this.navBar.nativeElement.classList.remove('in');
  }

  public goToPendingRequests() {
    this.navBar.nativeElement.classList.remove('in');
    this._navHelper.goToPendingRequestList();
  }

  public goHome() {
    this._navHelper.goToDashboard();
  }

  public switchWorkingOrganization() {
    this._navHelper.goToTenantSelector();
  }

  public goToBackgroundTasks() {
    this._navHelper.goToBackgroundTasks();
  }

  ngOnInit() {
    this._subscriptions.push(
      this._appStore.isMobile$
        .subscribe(value => this.model.isMobile = value));

    this._subscriptions.push(
      combineLatest(
        this._authHandler.isAuthenticated$,
        this._authHandler.workingOrganization$)
        .pipe(distinctUntilChanged(isSameObject))
        .subscribe(result => {
          this.model.authenticated = result[0];
          this.model.workingOrganization = result[1];
          this.model.showOnlyLogout = this.model.workingOrganization === undefined;

          if (this.model.authenticated) {
            if (this.model.workingOrganization) {
              this._setCurrentUserDisplayName();
              this._setInitialPendingRequests();
              // this._configureIntervalForPendingRequests();
            } else {
              this._resetViewModel();
            }
          }

        }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _resetViewModel() {
    this.model.pendingRequests = 0;
    this.model.displayName = undefined;
    this.model.initials = undefined;
  }

  private _setInitialPendingRequests() {
    this._changeManagement.getPendingRequestCount()
      .subscribe(response => this.model.pendingRequests = response);
  }

  private _configureIntervalForPendingRequests() {
    this._subscriptions.push(
      interval(1 * 60 * 1000)
        .pipe(
          takeWhile(() => this.model.authenticated && this.model.workingOrganization != undefined),
          flatMap(() => this._changeManagement.getPendingRequestCount())
        ).subscribe(response => this.model.pendingRequests = response));
  }

  private _setCurrentUserDisplayName() {
    this._auth.getUserDisplayName()
      .subscribe(displayName => {
        this.model.displayName = displayName.trim();
        this.model.initials = capitalizeInitials(this.model.displayName);
      });
  }

}
