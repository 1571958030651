export class RuleModel {
    private _uniqueId: string;

    public instanceCode: string;
    public code: string;
    public instanceName: string;
    public instanceDescription: string;
    public ownerPartyRoleInstanceCode: string;
    public ruleTypeLibraryCode: string;
    public factors: Array<RuleFactorModel>;
    public outcomeWorkEfforts: Array<{ workEffortInstanceCode: string }>;

    public getUniqueId() {
        if (this._uniqueId === undefined) {
            this._uniqueId = this._generateHash();
        }

        return this._uniqueId
    }

    private _generateHash(): string {
        let stringToHash: String = "";

        if (this.factors) {
            this.factors.forEach(factor => {
                if (factor.valueInstanceCode) {
                    stringToHash += factor.valueInstanceCode;
                }
            });

        }
        return this._getHasCode(stringToHash).toString();
    }
    
    private _getHasCode(stringToHash: String) { 

        var h = 0, l = stringToHash.length, i = 0;
        if ( l > 0 ) {
            while (i < l) {
                h = (h << 5) - h + stringToHash.charCodeAt(i++) | 0;
            }
        }
        return h;
    } 
}


export interface RuleFactorModel {
    ruleTypeLibraryCode: string;
    valueInstanceCode: string;
    factorTypeLibraryCode: string;
    value: string;
}