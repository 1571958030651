import { Component, AfterViewInit } from '@angular/core';
import { IToolPanel, IToolPanelParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-custom-panel',
  templateUrl: './grid-custom-panel.component.html',
  styleUrls: ['./grid-custom-panel.component.scss']
})
export class GridCustomPanelComponent implements IToolPanel {
  public params: IToolPanelParams;

  agInit(params: IToolPanelParams): void {
    this.params = params;

    this.params.api.addEventListener('modelUpdated', this.updateTotals.bind(this));
  }

  refresh(): void {
    this.updateTotals();
  }

  updateTotals(): void {
    var numGold = 0, numSilver = 0, numBronze = 0;

    this.params.api.forEachNode(function (rowNode) {
      let data = rowNode.data;

      if (data && data.gold) numGold += data.gold;
      if (data && data.silver) numSilver += data.silver;
      if (data && data.bronze) numBronze += data.bronze;
    });

  }
}
