import { Component, OnInit, HostListener } from '@angular/core';
import { PageLoaderService } from 'ngx-emerios-all';
import { StoreService } from './store/store.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

declare var lazyLoader: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  public showPageLoader: boolean;
  public hideRouter: boolean;
  public gridExpanded: boolean;

  private _contextualClasses: Array<string> = [];

  constructor(
    private _appStore: StoreService,
    private _pageLoader: PageLoaderService,
    private _spinner: NgxSpinnerService
  ) { }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this._appStore.setIsMobile(event.target.innerWidth <= 767);
  }

  public getClassConfiguration(): string {
    const classes: Array<string> = [...this._contextualClasses];

    return classes.join(' ');
  }

  ngOnInit() {
    this._appStore.theme$
      .subscribe(theme => lazyLoader.loadCss(theme));

    this._appStore.appendBodyClass$
      .subscribe(className => this._contextualClasses.push(className));

    this._pageLoader.loaderStandalone
      .pipe(distinctUntilChanged())
      .subscribe(v => v ? this._spinner.show() : this._spinner.hide());

    this._pageLoader.loaderHidingRouter
      .pipe(distinctUntilChanged())
      .subscribe(v => {
        this.hideRouter = v;
        v ? this._spinner.show() : this._spinner.hide();
      });

    this._checkWindowSize();
    this._loadDefaultTheme();
  }

  private _checkWindowSize() {
    this._appStore.setIsMobile(window.innerWidth <= 767);
  }

  private _loadDefaultTheme() {
    setTimeout(() => {
      this._appStore.setTheme('blue-light-theme');
    });
  }
}
