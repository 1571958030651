/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./catalog-grid.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading/ghost-loading/ghost-loading.component.ngfactory";
import * as i3 from "../loading/ghost-loading/ghost-loading.component";
import * as i4 from "../grid/grid.component.ngfactory";
import * as i5 from "../grid/grid.component";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "./catalog-grid.component";
import * as i9 from "../../services/handler/detail-view-handler/detail-view-handler.service";
import * as i10 from "../../services/handler/grid-handler/grid-handler.service";
import * as i11 from "../../services/handler/field-value-handler/field-value-handler.service";
import * as i12 from "../../services/helper/aggrid-column-helper/aggrid-column-helper.service";
var styles_CatalogGridComponent = [i0.styles];
var RenderType_CatalogGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CatalogGridComponent, data: {} });
export { RenderType_CatalogGridComponent as RenderType_CatalogGridComponent };
function View_CatalogGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading", [], null, null, null, i2.View_GhostLoadingComponent_0, i2.RenderType_GhostLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GhostLoadingComponent, [], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_0 = "aggrid"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CatalogGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grid", [], null, [[null, "selectionChanged"], [null, "gridApi"], [null, "firstDataRendered"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.gridSelectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("gridApi" === en)) {
        var pd_1 = (_co.gridApiReady($event) !== false);
        ad = (pd_1 && ad);
    } if (("firstDataRendered" === en)) {
        var pd_2 = (_co.onFirstDataRendered($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_GridComponent_0, i4.RenderType_GridComponent)), i1.ɵdid(1, 770048, null, 0, i5.GridComponent, [], { data: [0, "data"], rowSelection: [1, "rowSelection"], suppressRowClickSelection: [2, "suppressRowClickSelection"] }, { selectionChanged: "selectionChanged", gridApi: "gridApi", firstDataRendered: "firstDataRendered" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.gridModel; var currVal_1 = _co.model.rowSelection; var currVal_2 = _co.model.suppressRowClickSelection; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_CatalogGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "app-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "input-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getControlErrors(); _ck(_v, 2, 0, currVal_0); }); }
export function View_CatalogGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CatalogGridComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CatalogGridComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CatalogGridComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.loadingGrid; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.model.loadingGrid; _ck(_v, 3, 0, currVal_1); var currVal_2 = (((_co.control && _co.control.errors) && _co.control.invalid) && !_co.control.pristine); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_CatalogGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-catalog-grid", [], null, null, null, View_CatalogGridComponent_0, RenderType_CatalogGridComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.CatalogGridComponent]), i1.ɵdid(2, 770048, null, 0, i8.CatalogGridComponent, [i9.DetailViewHandlerService, i10.GridHandlerService, i11.FieldValueHandlerService, i12.AggridColumnHelperService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CatalogGridComponentNgFactory = i1.ɵccf("app-catalog-grid", i8.CatalogGridComponent, View_CatalogGridComponent_Host_0, { config: "config", definition: "definition", forceValidation: "forceValidation", filterByField: "filterByField", readonly: "readonly" }, { selectionChanged: "selectionChanged" }, []);
export { CatalogGridComponentNgFactory as CatalogGridComponentNgFactory };
