import { Injectable } from '@angular/core';
import { ReplacerRestService } from '../../rest/replacer-rest/replacer-rest.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CodeName } from 'src/app/models/common/code-name.model';
import { ReplacerData } from 'src/app/directives/replacer/replacer.directive';
import { ReplacerListOption } from 'src/app/components/replacer-list/replacer-list.component';

@Injectable({
  providedIn: 'root'
})
export class ReplacerService {
  public readonly selectedChoice$: Observable<ReplacerListOption>;
  public readonly replacerListSelected$: Observable<Array<ReplacerData>>;
  public readonly initialReplacerList$: Observable<Array<CodeName>>;

  private readonly _selectedChoice = new Subject<ReplacerListOption>();
  private readonly _replacerListSelected = new Subject<Array<ReplacerData>>();
  private readonly _initialReplacerList = new BehaviorSubject<Array<CodeName>>(undefined);

  constructor(private _replacerRest: ReplacerRestService) {
    this.selectedChoice$ = this._selectedChoice.asObservable();
    this.replacerListSelected$ = this._replacerListSelected.asObservable();
    this.initialReplacerList$ = this._initialReplacerList.asObservable();
  }

  public getReplacerByFilter(contextInstanceCodeArray: Array<string>, search: string, minSearchLength?: number) {
    return this._replacerRest.getReplacerByFilter(contextInstanceCodeArray, search, minSearchLength);
  }

  public setReplacerChoiceSelected(choice: ReplacerListOption) {
    this._selectedChoice.next(choice);
  }

  public initReplacerListFromEdit(replacerList: Array<CodeName>) {
    this._initialReplacerList.next(replacerList);
  }

  public updateReplacerListSelection(replacerList: Array<ReplacerData>) {
    this._replacerListSelected.next(replacerList);
  }
}
