import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export var QssProp;
(function (QssProp) {
    QssProp["FlowInstanceCode"] = "flowInstanceCode";
})(QssProp || (QssProp = {}));
/*
* QSS means Query String Storage
*/
export class QssHandlerService {
    constructor(_activatedRoute, _router) {
        this._activatedRoute = _activatedRoute;
        this._router = _router;
        this._queryStringStorageKey = 'qss';
        this._value = new BehaviorSubject(undefined);
        this._qss = {};
        this.value$ = this._value.asObservable().pipe(filter(x => x != undefined));
        this._activatedRoute.queryParams.subscribe(params => {
            const qssRaw = params[this._queryStringStorageKey];
            if (qssRaw) {
                const qssStr = atob(qssRaw);
                const parsed = JSON.parse(qssStr);
                this._qss = parsed;
                this._value.next(this._qss);
            }
        });
    }
    updateProp(prop, value) {
        const kv = {};
        kv[prop] = value;
        this.updateProps(kv);
    }
    updateProps(props) {
        Object.keys(props).forEach(prop => {
            const value = props[prop];
            if (value == undefined) {
                delete this._qss[prop];
            }
            else {
                this._qss[prop] = value;
            }
        });
        this.updateQss(this._qss);
    }
    updateQss(obj) {
        const str = JSON.stringify(obj);
        const b64 = btoa(str);
        const params = {};
        params[this._queryStringStorageKey] = b64;
        this._router.navigate([], { queryParams: params, queryParamsHandling: 'merge' });
    }
}
QssHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QssHandlerService_Factory() { return new QssHandlerService(i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i1.Router)); }, token: QssHandlerService, providedIn: "root" });
