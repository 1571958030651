import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormDefinition } from 'src/app/models/form-field-definition.models';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { Subscription } from 'rxjs';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';

@Component({
  selector: 'app-dynamic-form-grid-container',
  templateUrl: './dynamic-form-grid-container.component.html',
  styleUrls: ['./dynamic-form-grid-container.component.sass']
})
export class DynamicFormGridContainerComponent implements OnInit, OnChanges {
  @Input() public grids: Array<FormDefinition>;
  @Input() public detailCode: string;
  @Input() public formData: any;

  public iterableGrids: Array<{ standalone: boolean, def: FormDefinition }>;

  private _dashboardItem: DashboardItem;
  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _dashboardHandler: DashboardHandlerService,
    private _fieldValueHandler: FieldValueHandlerService) { }

  public isComparativeGrid(def: FormDefinition) {
    return def && def.grid && def.grid.code === 'comparativeView';
  }

  ngOnInit() {
    this._subscriptions.push(
      this._dashboardHandler.currentDashboardItem$
        .subscribe(item => this._dashboardItem = item));

    this._subscriptions.push(
      this._fieldValueHandler.rowEntityData$
        .subscribe(value => {
          const gridsToAvoid: Array<string> = [];
          this.iterableGrids = [];

          if (value.isPromoteRequest) {
            gridsToAvoid.push('comparativeView');
          } else {
            gridsToAvoid.push('promoteView');
          }

          this.grids.forEach(grid => {
            if (grid.grid.code !== 'relationships') {
              grid.title = grid.title.replace('${entity}', this._dashboardItem.name);
              if (!gridsToAvoid.includes(grid.grid.code)) {
                if (['comparativeView'].includes(grid.grid.code)) {
                  this.iterableGrids.push({ standalone: true, def: grid });
                } else {
                  this.iterableGrids.push({ standalone: false, def: grid });
                }
              }
            }
          });


          // if (value.isPromoteRequest) {
          //   this.iterableGrids = this.iterableGrids.filter(x => x.def.grid.code != 'comparativeView');
          // } else {
          //   this.iterableGrids = this.iterableGrids.filter(x => x.def.grid.code != 'promoteView');
          // }
        })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.grids && changes.grids.currentValue) {
      // this.iterableGrids = [];

      // this.grids.forEach(grid => {
      //   if (grid.grid.code !== 'relationships') {
      //     grid.title = grid.title.replace('${entity}', this._dashboardItem.name);
      //     if (['comparativeView'].includes(grid.grid.code)) {
      //       this.iterableGrids.push({ standalone: true, def: grid });
      //     } else {
      //       this.iterableGrids.push({ standalone: false, def: grid });
      //     }
      //   }
      // });
    }
  }
}
