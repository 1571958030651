import { Injectable } from '@angular/core';
import { Permission } from 'src/app/models/permission.model';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationHelperService {

  constructor() { }

  public isAutorized(resource: string, operation: string) : boolean {
    if(this.isAuthenticated()) {
      return this._getPermissions()
      .find(x => x.resource == resource && x.operation == operation) != null
    }
    else {
      return false;
    }
  } 

  public hasAnyPermission() : boolean {
    return this._getPermissions().length > 0
  }

  public isAuthenticated(): boolean {
    return this._getToken() != null
  }

  private _getPermissions() : Array<Permission>{
    return JSON.parse(sessionStorage.getItem(CacheKeyEnum.Permissions));
  }

  private _getToken() {
    return sessionStorage.getItem(CacheKeyEnum.AccessToken);
  }
}
