/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-form-preview.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./button-form-preview.component";
import * as i3 from "../../services/behavior/modal/modal.service";
import * as i4 from "../../services/handler/field-value-handler/field-value-handler.service";
import * as i5 from "../../services/handler/operation-handler/operation-handler.service";
import * as i6 from "../../services/behavior/waiting-loader/waiting-loader.service";
var styles_ButtonFormPreviewComponent = [i0.styles];
var RenderType_ButtonFormPreviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonFormPreviewComponent, data: {} });
export { RenderType_ButtonFormPreviewComponent as RenderType_ButtonFormPreviewComponent };
export function View_ButtonFormPreviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-default btn-section mr-2 btn-fill my-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonText; _ck(_v, 1, 0, currVal_0); }); }
export function View_ButtonFormPreviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-form-preview", [], null, null, null, View_ButtonFormPreviewComponent_0, RenderType_ButtonFormPreviewComponent)), i1.ɵdid(1, 770048, null, 0, i2.ButtonFormPreviewComponent, [i3.ModalService, i4.FieldValueHandlerService, i5.OperationHandlerService, i6.WaitingLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonFormPreviewComponentNgFactory = i1.ɵccf("app-button-form-preview", i2.ButtonFormPreviewComponent, View_ButtonFormPreviewComponent_Host_0, { config: "config", definition: "definition" }, {}, []);
export { ButtonFormPreviewComponentNgFactory as ButtonFormPreviewComponentNgFactory };
