import { Injectable } from '@angular/core';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { ActionButton } from 'src/app/components/action-bar/action-bar.component';
import { FormOperationType } from 'src/app/models/operation.models';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionBarHelperService {
  public readonly amountOfRows$: Observable<number>;

  private readonly _amountOfRows = new BehaviorSubject<number>(undefined);

  constructor() {
    this.amountOfRows$ = this._amountOfRows.asObservable();
  }

  public getGridViewButtons(item: DashboardItem, operations: Array<FormOperationType>) {
    const buttons = new Array<ActionButton>();

    const avoidCreateButtonGrids = [
        AgGridEnum.Stage, 
        AgGridEnum.Step, 
        AgGridEnum.StepContainer,
        AgGridEnum.StepForm, 
        AgGridEnum.ChangeRequest,
        AgGridEnum.ProductCheckAndBalance
    ];

    if (!avoidCreateButtonGrids.includes(item.gridSource)) {
      if (operations.includes(FormOperationType.Create)) {
        buttons.push(this._gridViewNew(item.name));
      }
    }

    // it is required to update grids
    // const showModifiedFilterButton = [AgGridEnum.FileAsset, AgGridEnum.FileDocument, AgGridEnum.Organization, AgGridEnum.Person,
    // AgGridEnum.Question, AgGridEnum.FormView, AgGridEnum.Product, AgGridEnum.ProductType, AgGridEnum.Feature, AgGridEnum.GeographyGrouping,
    // AgGridEnum.StepForm, AgGridEnum.StepContainer, AgGridEnum.Step, AgGridEnum.Stage, AgGridEnum.Flow, AgGridEnum.Request]
    //   .includes(item.gridSource);

    const showModifiedFilterButton = [AgGridEnum.Organization, AgGridEnum.Person, AgGridEnum.Question, AgGridEnum.Product,
    AgGridEnum.ProductType, AgGridEnum.Feature, AgGridEnum.GeographyGrouping].includes(item.gridSource);

    if (showModifiedFilterButton) {
      buttons.push(this._gridViewModifiedFilter());
    }

    return buttons;
  }

  public getGridSourceButtons(item: DashboardItem) {
    const buttons = new Array<ActionButton>();

    if ([AgGridEnum.GlossaryTerm].includes(item.gridSource)) {
      buttons.push(this._getGridTableView());
      buttons.push(this._getGridHierarchyView());
    }

    return buttons;
  }

  public getDetailViewButtons(item: DashboardItem, journey: boolean) {
    const buttons = new Array<ActionButton>();

    if (!journey) {
      if (item.categoryCode === 'ENTITY') {
        buttons.push(...this._getDetailViewButtonsForEntities(item));
      } else if (item.categoryCode === 'REQUEST') {
        buttons.push(...this._getDetailViewButtonsForChangeManagement(item, journey));
      }
    }

    return buttons;
  }

  public getGridButtonsBySource(source: string) {
    const buttons = new Array<ActionButton>();

    if (source === AgGridEnum.WorkeffortHierarchy) {
      let stageChilds = this._getWeFlowTypeChildren('Stage');
      let stepChilds = this._getWeFlowTypeChildren('Step');
      let formChilds = this._getWeFlowTypeChildren('Form');
      let containerChilds = this._getWeFlowTypeChildren('Container');

      buttons.push({
        code: 'stage',
        text: `Add Stage`,
        showDropdownIcon: true,
        childrens: stageChilds
      });

      buttons.push({
        code: 'step',
        text: `Add Step`,
        showDropdownIcon: true,
        childrens: stepChilds
      });

      buttons.push({
        code: 'form',
        text: `Add Form`,
        showDropdownIcon: true,
        childrens: formChilds
      });

      buttons.push({
        code: 'container',
        text: `Add Container`,
        showDropdownIcon: true,
        endSeparator: true,
        childrens: containerChilds
      });

      buttons.push({
        code: 'remove',
        text: `Remove Selected`,
        iconClass: 'fas fa-trash-alt'
      });
    }

    return buttons;
  }

  public getTabbedRuleComponentButtons() {
    const buttons = new Array<ActionButton>();

    buttons.push(
      {
        code: 'new',
        text: `Add New`,
        iconClass: "fas fa-plus-circle",
        endSeparator: true,
        visible: true
      });

    buttons.push(
      {
        code: 'remove',
        text: `Remove Selected`,
        iconClass: 'fas fa-trash-alt',
        visible: true
      });

    return buttons;
  }

  public setAmountOfRows(amount: number) {
    this._amountOfRows.next(amount);
  }

  private _getDetailViewButtonsForEntities(item: DashboardItem) {
    const buttons = new Array<ActionButton>();
    const menu = this._getEmptyMenu();

    buttons.push(this._getDetailViewSave());
    buttons.push(this._getDetailViewEdit());
    buttons.push(this._getDetailViewRestore());
    buttons.push(this._getDetailViewCancelEdition());
    buttons.push(...this._getDetailViewLink());

    menu.childrens.push(this._getDetailViewDiscardChange());
    menu.childrens.push(this._getDetailViewClone());

    if (item.libraryCodes.includes('FLW.ECST_ORD_CRT') ||
      item.libraryCodes.includes('FLW.ECST_ORD_CLS') ||
      item.libraryCodes.includes('FLW.ECST_ORD_INIT')) {
      menu.childrens.push(this._getDetailViewDownloadAgreeFlowForm())
    }

    buttons.push(menu);

    return buttons;
  }

  private _getDetailViewButtonsForChangeManagement(item: DashboardItem, journey: boolean) {
    const buttons = new Array<ActionButton>();
    const menu = this._getEmptyMenu();

    switch (item.gridSource) {
      case AgGridEnum.Request:
        if (!journey) {
          buttons.push(this._getDetailViewSubmitRequest());
          buttons.push(this._getDetailViewSave());
          buttons.push(this._getDetailViewEdit());
          buttons.push(this._getDetailViewRestore());
          buttons.push(this._getDetailViewCancelEdition());
          buttons.push(this._getDetailViewCancelRequest());
          buttons.push(this._getDetailViewRedeploy());

          menu.childrens.push(this._getDetailViewDiscardRequest());
          menu.childrens.push(this._getDetailViewDownloadAuditForm());
          menu.childrens.push(this._getDetailViewDeleteRequest());
          menu.childrens.push(this._getDetailViewClone());
        }
        break;

      case AgGridEnum.PendingRequest:
        buttons.push(this._getDetailViewApproveRequest());
        buttons.push(this._getDetailViewRejectRequest());
        break;
    }

    if (menu.childrens.length > 0) {
      buttons.push(menu);
    }

    return buttons;
  }

  private _getWeFlowTypeChildren(type: string) {
    const childrens: Array<ActionButton> = [];

    childrens.push({
      code: `${type.toLocaleLowerCase()}_alone`,
      text: `Add ${type}`,
      visible: true,
      enabled: true,
      endSeparator: true
    });

    childrens.push({
      code: `${type.toLocaleLowerCase()}_before`,
      text: `Before Selected`,
      visible: true
    });

    childrens.push({
      code: `${type.toLocaleLowerCase()}_after`,
      text: `After Selected`,
      visible: true
    });

    return childrens;
  }

  private _gridViewNew(entityName: string) {
    const childrens: Array<ActionButton> = [];

    childrens.push({
      code: 'new',
      text: `Create ${entityName}`,
      visible: true,
      enabled: true,
      iconClass: "fas fa-plus-circle",
      endSeparator: true
    });

    childrens.push({
      code: 'import',
      text: `Bulk import ${entityName} `,
      visible: true,
      iconClass: "fas fa-file-import",
      enabled: false
    });

    childrens.push({
      code: 'export',
      text: `Bulk export ${entityName} `,
      visible: true,
      iconClass: "fas fa-file-export",
      enabled: false
    });

    return {
      code: 'menu',
      text: `New`,
      iconClass: 'fas fa-plus-circle',
      showDropdownIcon: true,
      visible: true,
      enabled: true,
      childrens: childrens
    };
  }

  private _gridViewModifiedFilter() {
    return {
      startSeparator: true,
      code: 'modified-filter',
      text: `View Modified`,
      iconClass: 'fas fa-eye',
      enabled: true,
      visible: true,
      toggled: true,
      toggle: {
        text: `View All`,
        iconClass: 'fas fa-eye'
      }
    }
  }

  private _getDetailViewEdit() {
    return {
      code: 'edit',
      text: `Edit`,
      iconClass: 'fas fa-pencil-alt',
      visible: false,
      enabled: true
    };
  }

  private _getDetailViewCancelEdition() {
    return {
      code: 'cancel',
      text: `Close Edition`,
      iconClass: 'fas fa-times',
      enabled: true
    };
  }

  private _getDetailViewClone() {
    return {
      code: 'clone',
      text: `Clone`,
      iconClass: 'far fa-copy',
      enabled: true
    };
  }

  private _getDetailViewSave() {
    return {
      code: 'save',
      text: `Save`,
      iconClass: 'far fa-save',
      visible: false,
      enabled: true
    };
  }

  private _getDetailViewRestore() {
    return {
      code: 'restore',
      text: `Restore`,
      iconClass: 'fas fa-undo'
    };
  }

  private _getDetailViewLink() {
    return [{
      code: 'link',
      text: `Link`,
      iconClass: 'fas fa-link',
      enabled: true
    },
    {
      code: 'unlink',
      text: `Unlink`,
      iconClass: 'fas fa-unlink',
      enabled: true
    }];
  }

  private _getEmptyMenu() {
    return {
      code: 'menu',
      visible: true,
      iconClass: 'fas fa-ellipsis-v',
      childrens: [],
      enabled: true
    }
  }

  private _getDetailViewSubmitRequest() {
    return {
      code: 'submit',
      text: `Submit`,
      iconClass: 'fas fa-paper-plane',
      visible: false
    };
  }

  private _getDetailViewCancelRequest() {
    return {
      code: 'cancel_request',
      text: `Cancel Request`,
      iconClass: 'fas fa-times',
      visible: false
    };
  }

  private _getDetailViewApproveRequest() {
    return {
      code: 'approve',
      text: `Approve`,
      iconClass: 'fas fa-check',
      buttonStyle: '{"color": "green"}',
      visible: true,
      enabled: false
    };
  }

  private _getDetailViewRejectRequest() {
    return {
      code: 'reject',
      text: `Reject`,
      iconClass: 'fas fa-times',
      buttonStyle: '{"color": "red"}',
      visible: true,
      enabled: false
    };
  }

  private _getDetailViewRedeploy() {
    return {
      code: 'deploy',
      text: `Deploy`,
      iconClass: 'fas fa-dumbbell',
      visible: false,
      enabled: true
    };
  }

  private _getDetailViewDeleteRequest() {
    return {
      code: 'delete_request',
      text: `Delete`,
      iconClass: 'fas fa-trash',
      visible: true,
      enabled: true
    };
  }

  private _getDetailViewDiscardChange() {
    return {
      code: 'discard_changes',
      text: `Discard`,
      iconClass: 'fas fa-undo',
      visible: true,
      enabled: true
    };
  }

  private _getDetailViewDiscardRequest() {
    return {
      code: 'discard_request',
      text: `Discard`,
      iconClass: 'fas fa-undo',
      visible: true,
      enabled: true
    };
  }

  private _getGridTableView() {
    return {
      code: 'table-view',
      text: `Table View`,
      iconClass: 'fas fa-list',
      visible: true,
      enabled: true
    };
  }

  private _getGridHierarchyView() {
    return {
      code: 'hierarchy-view',
      text: `Hierarchy View`,
      iconClass: 'fas fa-project-diagram',
      visible: true,
      enabled: true
    };
  }

  private _getDetailViewDownloadAuditForm() {
    return {
      code: 'download_audit_form',
      text: `Export PDF`,
      iconClass: 'fas fa-file-download',
      enabled: true
    };
  }

  private _getDetailViewDownloadAgreeFlowForm() {
    return {
      code: 'download_agree_flow_form',
      text: `Export PDF`,
      iconClass: 'fas fa-file-download',
      enabled: true
    };
  }

}
