import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class AuthRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    login(username, password, platformProviderCode, singleSignOn, recaptchaToken) {
        const headers = new HttpHeaders()
            .append('silentError', 'false');
        const body = { username, password, platformProviderCode, recaptchaToken };
        var tokenEndpoint = `api/v1/login/token`;
        if (singleSignOn) {
            tokenEndpoint = `api/v1/login/token-sso`;
        }
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: tokenEndpoint,
            body: body,
            headers: headers
        });
    }
    getTenants(username) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/user-account/${username}/tenants`
        });
    }
    getPermissions(platformProviderCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/login/permissions/${platformProviderCode}`
        });
    }
    getUserDisplayName() {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/user-account/current/displayname`
        });
    }
    getUserSignature() {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/user-account/current/signature`
        });
    }
    updateCurrentUserSignature(base64Signature) {
        const body = { base64Signature: base64Signature };
        return this._rest.put({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/user-account/current/signature`,
            body: body
        });
    }
    getPlatformProviders() {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/login/platform-providers`
        });
    }
}
AuthRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthRestService_Factory() { return new AuthRestService(i0.ɵɵinject(i1.RestService)); }, token: AuthRestService, providedIn: "root" });
