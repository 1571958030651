import { SortableItemDefinition } from 'src/app/components/sortable-list/sortable-list.models';
import { ChainedFilterDefinition } from 'src/app/components/chained-filter/chained-filter.model';
import { KeyValueType } from '../types/keyvalue.type';
import { EntityCatalogItem } from './catalog.models';
import { AgGridEnum } from '../enums/aggrid-sources';
import { MimeType } from '../components/file-upload/file-upload.component';

export type FormDefinitionType = 'fields' | 'grid';
export type FieldDefinitionType =
    'input-text' | 'input-date' | 'datetime-picker' | 'input-lang' |
    'textarea' | 'textarea-lang' | 'textarea-form' |
    'catalog-single' | 'catalog-multiple' |
    'sortable-list' | 'chained-filter' |
    'tabbed-rule-creator' | 'file-upload' |
    'rules-grid' | 'grid-catalog-single' | 'grid-catalog-multiple' |
    'entity-selector-grid' |
    'relationships-grid' | 'button-form-preview' | 'button-action' |
    'file-processing' | 'navigation-rules' | 'grid-viewer' |
    'background-job-detail' | 'signature-viewer-group';

export type GridDefinitionCode =
    'relationships' | 'comparativeView' | 'hierarchicalView' | 'promoteView' |
    'flowstepsTpv' | 'flowstepsOthers' | 'campaignEntires' | 'catalog_product' |
    'cmp_rules' | 'qst_rules' | 'relationship_types' | 'question-rules' | 'tpv_products' | 'flowEvent' |
    'book_items';

export enum RelationshipScope {
    Parent = 'parent', Child = 'child', Both = 'both'
}

export enum Action {
    Download = 'Download',
    Upload = 'Upload',
    Confirm = 'Confirm',
    Decline = 'Decline',
}

export type PickerType = 'calendar' | 'timer' | 'both';

export interface EntitySelectorGridDef extends FieldDefinition { }

export interface RelationshipsGridDef extends FieldDefinition {
    agGridSource: AgGridEnum;
    relationshipScope: RelationshipScope;
}

export interface CatalogFieldDefinition extends FieldDefinition {
    grid: GridDefinition;
    loadStandalone: boolean;
}

export interface CatalogGridDefinition extends FieldDefinition {
    grid: GridDefinition;
    loadStandalone: boolean;
    multiselect: boolean;
}

export interface RulesGridDefinition extends FieldDefinition {
    grid: GridDefinition;
    catalogColumnName: string;
    ruleTypeLibraryCode: string;
    factorTypeColumnName: string;
    factorColumnNames: Array<string>;
    sendOutcome: boolean;
}

export interface DateTimePickerDefinition extends FieldDefinition {
    dateTimePropCode: string;
    pickerType: PickerType;
}

export interface InputLangDefinition extends FieldDefinition {
    translationPropCode?: string;
    translationPropText?: string;
}

export interface ActionButtonDefinition extends FieldDefinition {
    buttonActions: Array<Action>;
}

export interface GridDefinition {
    code: GridDefinitionCode;
    generic: boolean;
    source: AgGridEnum;
    expand: boolean;
}

export interface FileUploadDefinition {
    multiple: boolean;
    accept: Array<MimeType>;
    uploadAfterDropped: boolean;
    showPreview: boolean;
    hideAttachmentBox: boolean;
    languageEnabled: boolean;
}

export interface TabbedRuleCreatorDefinition {
    tabTemplateName: string;
    allowAddOrRemoveTabs: boolean;
    showTabs: boolean;
    selectorTitle: string;
    outcomeTitle: string;
    chainedFilterDefinition: ChainedFilterDefinition;
    outcome: RuleOutcome;
}

export interface RuleOutcome {
    type: 'catalog-multiple' | 'grid';
    grid?: GridDefinition;
    field?: FieldDefinition;
    attributes: FieldAttributes;
}

export interface FormDefinition {
    id?: string;
    sectionId?: string;
    name: string;
    type: FormDefinitionType;
    order?: number;
    hidden?: boolean;
    triggerBy?: { field: string, codes: Array<string>, notTriggeredOnCreate: boolean };
    triggeredBy?: string;
    title: string;
    description?: string;
    fields?: Array<FieldDefinition>;
    grid?: GridDefinition;
    dynamicSection?: boolean;
    showDuringJourney?: boolean;
    filterByTenant: boolean;
    visibilityField?: string;
}

export interface FieldDefinition {
    field: string;
    rulesConfiguration: RuleDefinition;
    fieldArray?: string;
    type: FieldDefinitionType;
    readonlyOnEdit?: boolean;
    catalog?: string;
    order: number;
    
    // estos fields son fields del formulario que van a 
    // poder ser usados dentro del componente en cuestion
    externalFields: KeyValueType<string>;
    
    catalogCodeProperty: string,
    catalogCodeProperty2: string,
    payloadCodeProperty: string,
    
    codeProperty?: string;
    propertyAsCode?: string;
    extraCatalogProperties?: Array<string>;
    
    textMirror: { from: string };
    attributes: FieldAttributes;
    triggerBy?: { field: string, codes: Array<string>, fieldToCompare?: string };
    dynamicGroup?: DynamicGroup;
    checkEditability: boolean;
    
    filterBy?: string;
    filterByProperty?: string;
    
    triggerValueProperty?: string;
    bubbleEvent?: boolean;
    sortableDefinition?: Array<SortableItemDefinition>;
    chainedFilterDefinition?: ChainedFilterDefinition;
    catalogItems?: Array<EntityCatalogItem>;
    untouchedCatalogItems?: Array<EntityCatalogItem>;
    value?: any;
    metadata?: any;
    tabbedRuleCreator?: TabbedRuleCreatorDefinition;
    fileUploadConfig?: FileUploadDefinition;
    replacer: ReplacerFieldData;
    notifyUpdateSelection: boolean;
    fieldGroup: string;
}

export interface RuleDefinition {
    field: string;
    ruleTypeLibraryCodeField: string;
    factorTypeLibraryCode: string;
    isFactor: boolean;
    isOutome: boolean;
    libraryCodes: Array<string>;
    valueProperty: 'valueInstanceCode' | 'value';
    outcomeProperty: 'outcomeWorkEfforts' | 'outcomeDataComponents' | 'outcomeProducts';
}

export interface ReplacerFieldData {
    fieldDto: string;
    targetElement: string;
    initialMark: string;
    finalMark: string;
}

export interface FieldAttributes {
    type?: 'text' | 'number';
    label: string;
    name: string;
    placeholder?: string;
    required: boolean;
    minlength?: number;
    maxlength?: number;
    min?: number;
    max?: number;
    pattern?: string;
    mask?: string;
    editable: boolean;
    hidden?: boolean;
    maxRuleQuantity?: number;
    dateValidation: boolean;
    repeatedCode: boolean;
    cssClass: string;
}

export interface DynamicGroup {
    id: string;
    title: string;
    order: number;
    sourceCatalog: string;
    fieldArray: string;
    filterByExtraValues: Array<string>;
}
