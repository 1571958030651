import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges, forwardRef, Output, EventEmitter } from '@angular/core';
import { GridInput } from '../grid/grid.model';
import { Subscription } from 'rxjs';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, Validators } from '@angular/forms';
import { ElementConfig } from 'ngx-emerios-all';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { RulesGridDefinition } from 'src/app/models/form-field-definition.models';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { AggridColumnHelperService } from 'src/app/services/helper/aggrid-column-helper/aggrid-column-helper.service';
import { RowEvent, GridApi } from 'ag-grid-community';
import { deepClone } from 'src/app/functions/deepClone';
import { KeyValueType } from 'src/app/types/keyvalue.type';
import { combineLatest } from 'rxjs';
import { RuleModel, RuleFactorModel } from 'src/app/models/rule.model';
import { CustomFormValidatorService } from 'src/app/services/behavior/custom-form-validator/custom-form-validator.service';
import { FormOperationType } from 'src/app/models/operation.models';

const noOp = () => { };
const DEFAULT_SELECTOR_ROW_NAME: string = "selectedFlow"

export interface FormGridModel {
  loadingGrid: boolean;
  gridModel: GridInput;
  suppressRowClickSelection: boolean;
}

export const CUSTOM_RULES_GRID_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RulesGridComponent),
  multi: true
};

@Component({
  selector: 'app-rules-grid',
  templateUrl: './rules-grid.component.html',
  styleUrls: ['./rules-grid.component.sass'],
  providers: [CUSTOM_RULES_GRID_CONTROL_VALUE_ACCESSOR]
})
export class RulesGridComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public config: ElementConfig;
  @Input() public definition: RulesGridDefinition;
  @Input() public forceValidation: EventEmitter<any>;
  @Input() public editMode: boolean;

  @Output() public selectionChanged = new EventEmitter();

  public control: FormControl;
  public model = {} as FormGridModel;
  public internalModel: { [x: string]: any, rules?: KeyValueType<RuleModel> };
  public detailCode: string;

  private _listItemColumnName: string;
  private _selectorRowName: string;
  private _gridUpdatedInEdition: boolean;
  private _ownerPartyRoleInstanceCode: string;
  private _gridApi: GridApi;
  private _itemsByRow: KeyValueType<Array<any>> = {};
  private _viewMode: FormOperationType;
  private _subscriptions: Array<Subscription> = [];
  private _onChange: any;

  writeValue(obj: any): void {
    if (obj != this.control.value) {
      this.internalModel = obj;
      this._updateGridSelection();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  onTouchedCallback: () => void = noOp;

  constructor(
    private _fieldValueHandler: FieldValueHandlerService,
    private _detailViewHandler: DetailViewHandlerService,
    private _aggridColumnHelper: AggridColumnHelperService,
    private _gridHandler: GridHandlerService
  ) { }

  public onCellValueChanged(event: RowEvent) {
    this._updateInnerModel(event.data);

    this.control.markAsDirty();
    this.selectionChanged.emit();
  }

  public getControlErrors() {
    let errorMessage: string;

    if (this.control.errors) {
      if (this.control.errors.required) {
        errorMessage = this.config.validationMessages.required;
      } else if (this.control.errors.maxRuleQuantity) {
        errorMessage = this.config.validationMessages.maxRuleQuantity;
      }
    }

    return errorMessage;
  }

  public gridApiReady(gridApi: GridApi) {
    this._gridApi = gridApi;
  }

  public onFirstDataRendered(event: any) {

  }

  ngOnInit() {
    this._subscriptions.push(
      this._detailViewHandler.mode$
        .subscribe(mode => {
          this._viewMode = mode;
          this.model.suppressRowClickSelection = mode === FormOperationType.View;
        }));

    this._subscriptions.push(
      combineLatest(
        this._fieldValueHandler.detailCodeValue$,
        this._fieldValueHandler.ownerPartyRoleValue$)
        .subscribe(results => {
          this.detailCode = results[0];
          this._ownerPartyRoleInstanceCode = results[1];
          // this._initiateGrid();
        }));

    this._initiateGrid();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue !== undefined) {
      this._initializeFormControl();
      this._configureComponent();
      this._updateInnerValue();
    }

    if (changes.forceValidation && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _initializeFormControl() {
    const validators = [];

    if (this.definition.attributes.required) {
      validators.push(Validators.required);
    }
    if (this.definition.attributes.maxRuleQuantity) {
      validators.push(CustomFormValidatorService.ValidateRuleQuantity(this.definition.attributes.maxRuleQuantity));
    }

    this.control = new FormControl(undefined, validators);
  }

  private _configureComponent() {
    this._listItemColumnName = this.definition.catalogColumnName;
    this._registerGetData();
  }

  private _updateInnerValue() {
    this.control.setValue(deepClone(this.internalModel));

    if (this._onChange) {
      this._onChange(this.control.value);
    }
  }

  private _updateInnerModel(rowData: any) {
    const selectedItem = rowData[this._selectorRowName];

    if (typeof (rowData[this._selectorRowName]) === 'string') {
      return;
    }

    if (this.internalModel == undefined) {
      this.internalModel = { rules: {} };
    }

    const ruleModel = this._createRuleModel(rowData);

    if (selectedItem && (selectedItem === '-- Not Available --' || selectedItem.code === 'NOT_AVAILABLE')) {
      delete this.internalModel.rules[ruleModel.getUniqueId()];
    } else {
      this.internalModel.detailCode = this.detailCode;
      this.internalModel.ownerPartyRoleInstanceCode = this._ownerPartyRoleInstanceCode;

      this.internalModel.rules[ruleModel.getUniqueId()] = ruleModel;
    }

    if (Object.keys(this.internalModel.rules).length === 0) {
      this.internalModel = undefined;
    }

    this._updateInnerValue();
  }

  private _updateGridSelection() {
    if (!this._gridUpdatedInEdition && this._gridApi) {

      this._gridApi.forEachNode(rowNode => {
        const rowModel = this._createRuleModel(rowNode.data);
        const uniqueId = rowModel.getUniqueId();

        if (this.internalModel && this.internalModel.rules) {
          Object.keys(this.internalModel.rules).forEach(prop => {
            if (prop === uniqueId) {
              const listItems = rowNode.data[this._listItemColumnName];
              // ver como mejorar esto de alguna manera. por configuracion o por magia
              const outcomeWorkEffort = this.internalModel.rules[prop].outcomeWorkEfforts[0];

              let selectedItem: any;

              if (outcomeWorkEffort != undefined) {
                const workEffortInstanceCode = this.internalModel.rules[prop].outcomeWorkEfforts[0].workEffortInstanceCode;
                selectedItem = listItems.find((x: any) => x.code === workEffortInstanceCode);
              } else {
                const valueSelected = this.internalModel.rules[prop].factors[0].value;
                selectedItem = listItems.find((x: any) => x.code === valueSelected);
              }

              rowNode.data['rowSelected'] = selectedItem != undefined;

              if (this._selectorRowName === undefined) {
                this._selectorRowName = DEFAULT_SELECTOR_ROW_NAME;
              }

              rowNode.data[this._selectorRowName] = selectedItem;
            }
          });
        }
      });
      this._gridApi.refreshCells();
      this._gridApi.setSortModel([{ colId: 'rowSelected', sort: 'desc' }]);
      this._gridUpdatedInEdition = true;

      this._updateInnerValue();
    }
  }

  private _initiateGrid() {
    this.model.loadingGrid = true;
    this._setRuleTypeLibraryCode();
    this._gridHandler.getGenericGridDefAndCustomData(this.definition.grid, this.detailCode);
  }

  private _setRuleTypeLibraryCode() {
    const campaignLibraryCode = this.detailCode.slice(0, this.detailCode.lastIndexOf('.'));

    switch (campaignLibraryCode) {
      case 'CMP.TPV':
        this.definition.ruleTypeLibraryCode = 'RUL.CMP_TPV.ENTRY'
        break;
      case 'CMP.ECST_ORD_CRT':
        this.definition.ruleTypeLibraryCode = 'RUL.CMP_ECST_ORD_CRT.ENTRY'
        break;
      case 'CMP.ECST_ORD_CLS':
        this.definition.ruleTypeLibraryCode = 'RUL.CMP_ECST_ORD_CLS.ENTRY'
        break;
      case 'CMP.ECST_ORD_INIT':
        this.definition.ruleTypeLibraryCode = 'RUL.CMP_ECST_ORD_INIT.ENTRY'
        break;
      case 'CMP.PRD':
        this.definition.ruleTypeLibraryCode = 'RUL.CMP_ENR.ENTRY'
        break;
      default:
        console.warn(`${campaignLibraryCode} does not match.`);
        break;
    }
  }

  private _registerGetData() {
    this._subscriptions.push(
      this._gridHandler.getGenericGridDataAndDef$
        .subscribe(gridInput => {
          if (this.definition.grid.source === gridInput.source) {

            this.model.gridModel = this._attachValueGetters(gridInput);
            this.model.gridModel = this._createCheckboxColumn(this.model.gridModel);

            this.model.loadingGrid = false;

            setTimeout(() => {
              this._updateGridSelection();
            }, 1000);
          }
        }));
  }

  private _createCheckboxColumn(gridInput: GridInput) {
    const column = this._aggridColumnHelper.getLockedColumn(
      'rowSelected',
      (params: any) => this._createRowCheckbox(params),
      (params: any) => this._simulateCheckboxClick(params));

    column.sortable = true;
    gridInput.columns.splice(0, 0, column);

    return gridInput;
  }

  private _attachValueGetters(gridInput: GridInput) {
    gridInput.columns.forEach(column => {
      column.valueGetter = (params: any) => {
        return this._handleColumnValueGetter(params);
      };
    });

    return gridInput;
  }

  private _handleColumnValueGetter(params: any): string {
    const cellValue = params.data[params.column.colDef.field];
    const notAvailableItem = { code: 'NOT_AVAILABLE', description: '-- Not Available --' };

    if (params.colDef.cellEditor === 'agRichSelectCellEditor') {
      const ruleModel = this._createRuleModel(params.data);
      const ruleUniqueId = ruleModel.getUniqueId();
      const dataListObj = params.data[this._listItemColumnName];

      this._selectorRowName = params.column.colId;

      if (this._itemsByRow[ruleUniqueId] === undefined) {
        this._itemsByRow[ruleUniqueId] = [notAvailableItem, ...deepClone(dataListObj)];
      }

      if (cellValue == undefined) {
        params.data[params.column.colId] = notAvailableItem;
      } else {
        // update checkbox
        if (cellValue && cellValue.code) {
          params.data['rowSelected'] = cellValue.code !== 'NOT_AVAILABLE';
        }
      }

      params.colDef.editable = this._viewMode !== FormOperationType.View;

      params.colDef.cellEditorParams = {
        values: this._itemsByRow[ruleUniqueId],
        cellRenderer: (cellParams: any) => {
          if (cellParams.value !== undefined) {
            return cellParams.value && cellParams.value.description;
          }
        }
      }
    }

    return cellValue && cellValue.description ? cellValue.description : cellValue;
  }

  private _createRuleModel(rowData: any): RuleModel {
    const ruleModel = new RuleModel();
    const factors = [];

    ruleModel.factors = [];

    // este seria el caso "generico". los factores vienen configurados
    if (this.definition.factorColumnNames) {
      Object.keys(rowData).forEach(prop => {
        if (this.definition.factorColumnNames.includes(prop)) {
          factors.push({
            ruleTypeLibraryCode: this.definition.ruleTypeLibraryCode,
            factorTypeLibraryCode: rowData[this.definition.factorTypeColumnName],
            valueInstanceCode: rowData[prop],
            value: rowData[this._selectorRowName] && rowData[this._selectorRowName].code
          } as RuleFactorModel);
        }
      });

    } else {
      // este caso es especifico. los factores son todas las columnas con un objeto y un .code
      // se podria unificar por configuracion
      Object.keys(rowData).forEach(prop => {
        // si no es la columna de flows
        if (this._selectorRowName !== prop && rowData[prop].code !== undefined) {
          factors.push({
            ruleTypeLibraryCode: this.definition.ruleTypeLibraryCode,
            factorTypeLibraryCode: rowData[prop].factorTypeLibraryCode,
            valueInstanceCode: rowData[prop].code
          } as RuleFactorModel);
        }
      });
    }

    ruleModel.factors = factors;

    if (this.definition.sendOutcome) {
      if (this._selectorRowName && rowData[this._selectorRowName]) {
        ruleModel.outcomeWorkEfforts = [{ workEffortInstanceCode: rowData[this._selectorRowName].code }];
      }
    }

    return ruleModel;
  }

  private _simulateCheckboxClick(params: any) {
    params.event.srcElement.children[0].click();
  }

  private _createRowCheckbox(params: any) {
    const input = document.createElement('input');

    input.type = "checkbox";
    input.checked = params.value;
    input.disabled = true;

    return input;
  }
}
