import { Component, ViewChild, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GridInput } from '../grid/grid.model';
import { Subscription, BehaviorSubject, merge } from 'rxjs';
import { RowEvent, GridApi, ColumnApi, RowNode, RowDragEvent, BodyScrollEvent, GetContextMenuItemsParams, MenuItemDef, IServerSideDatasource } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { PaginationDto, PaginationFilterDto, SortDirection } from 'src/app/models/server-side-pagination';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { ActionBarHelperService } from 'src/app/services/helper/action-bar-helper/action-bar-helper.service';
import { filter } from 'rxjs/operators';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { AgGridBaseModel } from 'src/app/models/aggrid/aggrid-base.model';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';
import { RelationshipHandlerService } from 'src/app/services/handler/relationship-handler/relationship-handler.service';

export interface ViewModel {
  gridVisible: boolean;
  rowData: Array<any>;
  defaultColDef: any;
  columnDefs: any;
  autoGroupColumnDef: any;
  groupDefaultExpanded: number;
  treeData: boolean;
  getDataPath: Function;
}

@Component({
  selector: 'app-grid-pagination',
  templateUrl: './grid-pagination.component.html',
  styleUrls: ['./grid-pagination.component.sass']
})
export class GridPaginationComponent implements OnChanges, OnDestroy {
  @ViewChild('agGrid', { static: false })
  public agGrid: AgGridAngular;

  @Input() public rowSelection: 'single' | 'multiple' = 'multiple';
  @Input() public suppressRowClickSelection: boolean;
  @Input() public groupHideOpenParents: boolean;
  @Input() public rowClassRules: any;
  @Input() public colState: EventEmitter<any>;
  @Input() public filterState: EventEmitter<any>;
  @Input() public customPanel: any;
  @Input() public externalFilter: { [x: string]: (key: any) => boolean; };
  @Input() public exportAsCsv: EventEmitter<any>;
  @Input() public setExpanded: EventEmitter<any>;
  @Input() public groupSelectsChildren: boolean;
  @Input() public dashboardItem: DashboardItem;
  @Input() public instanceCode: string;
  @Input() public relationshipScope: string;
  @Input() public deployJourney: boolean;

  @Output() public colStateChanged = new EventEmitter();
  @Output() public rowDoubleClicked = new EventEmitter();
  @Output() public openInNewTab = new EventEmitter();
  @Output() public selectionChanged = new EventEmitter();
  @Output() public cellValueChanged = new EventEmitter();
  @Output() public rowFilterFocused = new EventEmitter();
  @Output() public rowDragEnd = new EventEmitter();
  @Output() public rowDragLeave = new EventEmitter();
  @Output() public gridApi = new EventEmitter<GridApi>();
  @Output() public firstDataRendered = new EventEmitter();
  @Output() public gridDataLoaded = new EventEmitter<GridInput>();

  public rowModelType: string = 'serverSide';
  public dataSource: IServerSideDatasource;
  public pagination: boolean = true;
  public cacheBlockSize: number = 20;
  public paginationPageSize: number = 20;
  public model = {} as ViewModel;
  public frameworkComponents: any;
  public sideBar: any;
  public showGhostLoading: boolean = true;

  private _settingColumnState: boolean;
  private _subscriptions: Array<Subscription> = [];
  private _gridApi: GridApi;
  private _gridColumnApi: ColumnApi;
  private _filterAndSortList: Array<string> = [];
  private _filterAndSortingFieldAvailables: Array<string> = [
    'instanceCode',
    'code',
    'instanceName',
    'instanceDescription',
    'creationDateTime',
    'currentStatusName',
    'effectiveDateFrom',
    'effectiveDateThru',
    'requestCode',
    'requestName',
    'requestType',
    'ownerPartyRoleInstanceName',
    'requestStatus',
    'lastDeployedDateTime'
  ];
  private _filterAndSortingFieldAvailablesBackgrounJob: Array<string> = [
    'jobExecutionUid',
    'jobName',
    'tenantName',
    'status',
    'userName',
    'startDatetime',
    'endDatetime',
    'startDatetimeForLocale',
    'endDatetimeForLocale'
  ];

  private _filterAndSortingFieldAvailablesRequest: Array<string> = [
    'code',
    'name',
    'description',
    'creationDateTime',
    'ownerPartyRoleInstanceName',
    'currentRequestStatusName'
  ];
  private _filterAndSortingFieldAvailablesRelationship: Array<string> = [
    'instanceCode',
    'code',
    'instanceName',
    'creationDateTime',
    'effectiveDateFrom',
    'effectiveDateThru',
    'requestCode',
    'requestType',
    'requestStatus'
  ];

  private _selectedSortField: string;
  private _selectedSortDirection: string;
  private _defaultSortFieldInstance: string = 'instanceName';
  private _defaultSortFieldBackgroundJob: string = 'startDatetime';
  private _defaultSortFieldRequest: string = 'creationDateTime';
  private _defaultSortFieldRelationship: string = 'instanceName';
  private _currentRowParams: any;

  private readonly _colState = new BehaviorSubject<Array<any>>(undefined);
  private readonly _filterState = new BehaviorSubject<Array<any>>(undefined);

  constructor(
    private _gridHandler: GridHandlerService,
    private _actionBarHelper: ActionBarHelperService,
    private _relationshipHandler: RelationshipHandlerService,
    private _authHandler: AuthorizationHandlerService) {
    this.model.defaultColDef = {
      enableRowGroup: true,
      enablePivot: true,
      sortable: true,
      resizable: true,
      filter: false
    };
    this.model.autoGroupColumnDef = {
      sortable: false,
      cellRendererParams: {
        suppressCount: true
      }
    };

    this.sideBar = {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel"
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel"
        }
      ],
      defaultToolPanel: "columns"
    };
  }

  public onGridReady(params: any) {
    this._gridApi = params.api;
    this._gridColumnApi = params.columnApi;

    this.agGrid.api.closeToolPanel();

    this._setCustomPanelWidth();
    this._setCustomPaginationPanel();
    this._addEventToGridInputs();

    if (this.gridApi) {
      this.gridApi.emit(this._gridApi);
    }

    this.setDataSource();
  }

  public _updateRows() {
    this.dataSource.getRows(this._currentRowParams);
  }

  public setDataSource() {
    this.dataSource = this._createServerSideDatasource();
    this._gridApi.setServerSideDatasource(this.dataSource);
    this.model.columnDefs = undefined;
    const gridSource = this.relationshipScope ? AgGridEnum.CommonRelationship : this.dashboardItem.gridSource;

    switch (gridSource) {
      case AgGridEnum.Request:
      case AgGridEnum.SubmittedRequest:
      case AgGridEnum.ProcessedRequest:
      case AgGridEnum.ChangeRequest:
      case AgGridEnum.PendingRequest:
        this._filterAndSortList = this._filterAndSortingFieldAvailablesRequest;
        this._selectedSortField = this._defaultSortFieldRequest;
        this._selectedSortDirection = 'Descending';
        break;
      case AgGridEnum.BackgroundJobs:
        this._filterAndSortList = this._filterAndSortingFieldAvailablesBackgrounJob;
        this._selectedSortField = this._defaultSortFieldBackgroundJob;
        this._selectedSortDirection = 'Ascending';
        break;
      case AgGridEnum.CommonRelationship:
        this._filterAndSortList = this._filterAndSortingFieldAvailablesRelationship;
        this._selectedSortField = this._defaultSortFieldRelationship;
        this._selectedSortDirection = 'Ascending';
        break;
      default:
        this._filterAndSortList = this._filterAndSortingFieldAvailables;
        this._selectedSortField = this._defaultSortFieldInstance;
        this._selectedSortDirection = 'Ascending';
        break;
    }
  }

  public onFirstDataRendered(event: any) {
    this._colState.asObservable()
      .subscribe(columnState => {
        this._setColumnState(columnState)
      });

    this._filterState.asObservable()
      .subscribe(filterState => this._setFilterState(filterState));

    this.firstDataRendered.emit();
  }

  public onRowDataChanged(params: any) {
    this._gridApi = params.api;
    this._gridColumnApi = params.columnApi;

    this._setColumnWidth();
  }

  public onBodyScroll(event: BodyScrollEvent) {
    this._addEventToGridInputs();
  }

  public somethingChanged(event: any) {
    if (this.colStateChanged && !this._settingColumnState) {
      this.colStateChanged.emit();
    }

    // Avoid emit a col state change when setting state manually
    if (this._settingColumnState) {
      this._settingColumnState = false;
      this._addEventToGridInputs();
    }
  }

  public getCurrentColumnState() {
    return {
      columnState: this.agGrid.columnApi.getColumnState(),
      filterState: this._gridApi.getFilterModel()
    }
  }

  public onRowDoubleClicked(event: RowEvent) {
    this.rowDoubleClicked.next(event);
  }

  public onSelectionChanged(event: RowEvent) {
    this.selectionChanged.next(event);
  }

  public onCellValueChanged(event: RowEvent) {
    this.cellValueChanged.next(event);
  }

  public onRowDragLeave(event: RowDragEvent) {
    this.rowDragLeave.next(event);
  }

  public onRowDragEnd(event: RowDragEvent) {
    this.rowDragEnd.next(event);
  }

  public isExternalFilterPresent() {
    return this.externalFilter != undefined;
  }

  public doesExternalFilterPass(node: RowNode) {
    let filtered: boolean;

    Object.keys(this.externalFilter)
      .forEach(key => {
        filtered = this.externalFilter[key](node.data[key])
      });

    return filtered;
  }

  public getContextMenuItems(params: GetContextMenuItemsParams): Array<MenuItemDef> {
    const newMenuItems = Object.assign(params.defaultItems, []);

    const openInNewTab = {
      name: 'Open in a new tab',
      action: () => { this.openInNewTab.next(params); },
    } as MenuItemDef;

    newMenuItems.unshift(openInNewTab, 'separator');
    return newMenuItems;
  }

  ngOnInit() {
    this._subscriptions.push(this._actionBarHelper.amountOfRows$
      .pipe(filter((amount: number) => amount != undefined))
      .subscribe((amount: number) => {
        this._onPageSizeChanged(amount);
      }));

    this._subscriptions.push(
      merge(
        this._relationshipHandler.relationshipCreated$,
        this._relationshipHandler.relationshipDeleted$)
        .subscribe(action => {
          this._updateRows();
        }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customPanel && changes.customPanel.currentValue) {
      this._configCustomPanel();
    }

    if (changes.colState && changes.colState.currentValue) {
      this._subscriptions.push(this.colState
        .subscribe((def: Array<any>) => this._colState.next(def)));
    }

    if (changes.filterState && changes.filterState.currentValue) {
      this._subscriptions.push(this.filterState
        .subscribe((def: Array<any>) => this._filterState.next(def)));
    }

    if (changes.exportAsCsv && changes.exportAsCsv.currentValue) {
      this._subscriptions.push(
        this.exportAsCsv.subscribe((params: any) => {
          this._gridApi.exportDataAsCsv(params);
        }));
    }

    if (changes.setExpanded && changes.setExpanded.currentValue) {
      this._subscriptions.push(
        this.setExpanded.subscribe((expanded: boolean) => {
          if (this._gridApi) {
            this._gridApi.forEachNode(function (node) {
              if (node.group) {
                node.setExpanded(expanded);
              }
            });
            this._gridApi.onGroupExpandedOrCollapsed();
          }
        }));
    }

    if (this._gridApi) {
      if (changes.externalFilter && (changes.externalFilter.currentValue || changes.externalFilter.previousValue)) {
        this._gridApi.onFilterChanged();
      }
    }

    if (changes.dashboardItem
      && changes.dashboardItem.previousValue
      && changes.dashboardItem.currentValue.gridSource != changes.dashboardItem.previousValue.gridSource) {
      this.setDataSource();
    }
  }

  private _onPageSizeChanged(newAmount: number) {
    this.paginationPageSize = newAmount;
    this._gridApi.paginationSetPageSize(newAmount);
  }

  private _createServerSideDatasource() {
    return {
      getRows: (params) => {
        this._currentRowParams = params;
        const paginationDto: PaginationDto = this._createPaginationDto(params);

        this._gridHandler.getGridData(this.dashboardItem, paginationDto, this.instanceCode, this.relationshipScope)
          .then(data => {
            this._setCheckboxSelection(data);
            this._setColumnDefinition(data);
            var lastRowIndex = this._getLastRowIndex(data.rows, params.request);
            params.successCallback(data.rows, lastRowIndex);
            this.gridDataLoaded.next(data);
            this.showGhostLoading = false;

            if (this.relationshipScope) {
              this._setColumnWidth();
            }
          })
          .catch(params.failCallback());
      },
      destroy: () => { }
    }
  }

  private _setCheckboxSelection(data: GridInput) {
    if (this.deployJourney) {
      const group = data.metadata.columnDefinition.find(x => x.headerName === 'Properties');
      const element = group.children.find(x => x.field === 'ownerPartyRoleInstanceName');
      element.checkboxSelection = true;
    }
  }

  private _getLastRowIndex(rows: Array<AgGridBaseModel>, request: any) {
    var currentLastRow = request.startRow + rows.length;
    return currentLastRow < request.endRow ? currentLastRow : undefined;
  }

  private _setColumnWidth() {
    const visibleColumns = this._gridColumnApi.getAllDisplayedColumns();
    const gridWidth = (this.agGrid as any).viewContainerRef.element.nativeElement.clientWidth;

    let totalWidth = 0;

    visibleColumns.forEach(col => {
      totalWidth += col.getActualWidth();
    });

    if (gridWidth > totalWidth) {
      setTimeout(() => {
        this._gridApi.sizeColumnsToFit();
      }, 100);
    }
  }

  private _createPaginationDto(params: any) {
    const pageNumber = this._gridApi.paginationGetCurrentPage() + 1;
    const filters: Array<PaginationFilterDto> = [];

    let sortDirection: SortDirection;
    let sortField: string;
    const sorting = params.request.sortModel.length > 0 ? params.request.sortModel[0] : undefined;

    Object.keys(params.request.filterModel).forEach(key => {
      if (this._filterAndSortList.includes(key)) {
        if (['creationDateTime', 'effectiveDateFrom', 'effectiveDateThru'].includes(key)) {
          if (params.request.filterModel[key].filter.match(/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/i)) {
            filters.push({
              Field: key,
              Value: params.request.filterModel[key].filter,
              Operation: params.request.filterModel[key].type
            });
          }
        } else {
          filters.push({
            Field: key,
            Value: params.request.filterModel[key].filter,
            Operation: params.request.filterModel[key].type
          });
        }
      }
    });

    if (sorting && this._filterAndSortList.includes(sorting.colId)) {
      this._selectedSortDirection = sorting.sort === 'asc' ? 'Ascending' : 'Descending';
      this._selectedSortField = sorting.colId;
    } else {
      switch (this.dashboardItem.gridSource) {
        case AgGridEnum.Request:
        case AgGridEnum.SubmittedRequest:
        case AgGridEnum.ProcessedRequest:
        case AgGridEnum.ChangeRequest:
        case AgGridEnum.PendingRequest:
          this._selectedSortField = this._defaultSortFieldRequest;
          this._selectedSortDirection = 'Descending';
          break;
        case AgGridEnum.BackgroundJobs:
          this._selectedSortField = this._defaultSortFieldBackgroundJob;
          this._selectedSortDirection = 'Ascending';
          break;
        default:
          this._selectedSortField = this._defaultSortFieldInstance;
          this._selectedSortDirection = 'Ascending';
          break;
      }
    }

    return {
      Filters: filters,
      SortField: this._selectedSortField,
      SortDirection: this._selectedSortDirection,
      Page: pageNumber,
      PageSize: this.paginationPageSize,
      UserName: this._authHandler.getCurrentUsername()
    } as PaginationDto;
  }

  private _addEventToGridInputs() {
    setTimeout(() => {
      document.querySelectorAll('input')
        .forEach(htmlEl => {
          htmlEl.onfocus = (ev) => {
            this.rowFilterFocused.next(true);
          };
          htmlEl.onblur = (ev) => {
            this.rowFilterFocused.next(false);
          };
        });
    }, 100);
  }

  private _setColumnState(columnState: Array<any>) {
    this._settingColumnState = true;
    if (columnState) {
      // this.agGrid.suppressSetColumnStateEvents = true;
      this.agGrid.columnApi.setColumnState(columnState);
    } else {
      if (this.agGrid.columnApi.getColumnState().length > 0) {
        this.agGrid.columnApi.resetColumnState();
      }
    }

    setTimeout(() => {
      this._settingColumnState = false;
    }, 100);
  }

  private _setFilterState(filterState: Array<any>) {
    if (filterState && Object['keys'](filterState).length > 0) {
      this.agGrid.api.setFilterModel(filterState);
    } else {
      this.agGrid.api.setFilterModel(null);
    }
  }

  private _setCustomPanelWidth() {
    if (this.customPanel) {
      const sidePanel = document.getElementsByClassName('ag-side-bar')[0];

      for (let i = 0; i < sidePanel.children.length; i++) {
        const child = sidePanel.children[i];

        for (let j = 0; j < child.children.length; j++) {
          const innerChild = child.children[j];
          if (innerChild.tagName.toLocaleLowerCase().startsWith('app-')) {
            child.setAttribute("style", "width: 300px;");
            break;
          }
        }

      }
    }
  }

  private _setCustomPaginationPanel() {
    const firstNodeList = document.getElementsByClassName('ag-paging-row-summary-panel');
    const lastNodeList = document.getElementsByClassName('ag-paging-page-summary-panel');

    for (let index = 0; index < firstNodeList.length; index++) {
      const firstNode = firstNodeList[index];
      const lastNode = lastNodeList[index];
      const parentNode = firstNode.parentElement;
      parentNode.insertBefore(lastNode, firstNode);
      parentNode.setAttribute("style", "justify-content: center");
    }
  }

  private _setColumnDefinition(data: GridInput) {
    if (this.model.columnDefs || !data) { return; }
    this._validateAvailableColumnFilterAndSorting(data);
    this.model.columnDefs = data.columns || data.metadata.columnDefinition;
  }

  private _validateAvailableColumnFilterAndSorting(data: GridInput) {
    data.metadata.columnDefinition.forEach(colDef => {
      colDef.children.forEach(def => {
        if (def.filter === 'agTextColumnFilter' && !this._filterAndSortList.includes(def.field)) {
          delete def.filter;
        }

        if (!this._filterAndSortList.includes(def.field)) {
          def.sortable = false;
        }
      });
    });
  }

  private _configCustomPanel() {
    const sideBar = Object.assign({}, this.sideBar);

    sideBar.toolPanels
      .push({
        id: "guidedFilterPanel",
        labelDefault: "Guided Filter",
        labelKey: "guidedFilterPanel",
        iconKey: "filter",
        toolPanel: "guidedFilterPanel"
      });

    sideBar.defaultToolPanel = "guidedFilterPanel";

    this.sideBar = sideBar;

    this.frameworkComponents = { guidedFilterPanel: this.customPanel };

  }

}
