import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { CustomFormValidatorService } from '../../behavior/custom-form-validator/custom-form-validator.service';
import * as i0 from "@angular/core";
export function isSameField(prevValue, nextValue) {
    return (prevValue.currentField.name === nextValue.currentField.name);
}
export function isSameValue(prevValue, nextValue) {
    return (prevValue.currentField.name === nextValue.currentField.name)
        && (prevValue.currentField.value === nextValue.currentField.value);
}
export class FieldValueHandlerService {
    constructor() {
        this._rowEntityData = new BehaviorSubject(undefined);
        this._anyValue = new BehaviorSubject(undefined);
        this._detailCodeValue = new BehaviorSubject(undefined);
        this._ownerPartyRoleValue = new BehaviorSubject(undefined);
        this._currentStatusCodeValue = new BehaviorSubject(undefined);
        this._currentEnvironmentStatusCodeValue = new BehaviorSubject(undefined);
        this._formDefinitionValue = new BehaviorSubject(undefined);
        this._anyValueWall = new Map();
        this.rowEntityData$ = this._rowEntityData.asObservable()
            .pipe(filter(x => x != undefined));
        this.anyValue$ = this._anyValue.asObservable()
            .pipe(filter(x => x != undefined), distinctUntilChanged());
        this.detailCodeValue$ = this._detailCodeValue.asObservable()
            .pipe(filter(x => x != undefined), distinctUntilChanged());
        this.ownerPartyRoleValue$ = this._ownerPartyRoleValue.asObservable()
            .pipe(filter(x => x != undefined), distinctUntilChanged());
        this.currentStatusCodeValue$ = this._currentStatusCodeValue.asObservable()
            .pipe(distinctUntilChanged());
        this.currentEnvironmentStatusCodeValue$ = this._currentEnvironmentStatusCodeValue.asObservable()
            .pipe(distinctUntilChanged());
        this.formDefinitionValue$ = this._formDefinitionValue.asObservable()
            .pipe(filter(x => x != undefined));
    }
    setRawData(entity) {
        this._rowEntityData.next(entity);
    }
    setAnyValue(name, field) {
        let value = field.value;
        let detailCode = this._detailCodeValue.getValue();
        if (Array.isArray(field.value)) {
            if (field.catalogItems && field.catalogItems.length > 0) {
                value = field.catalogItems
                    .filter(catalogItem => field.value
                    .some(value => value === catalogItem.code));
                if (value.length === 0 && field.catalogCodeProperty) {
                    value = field.catalogItems
                        .filter(catalogItem => field.value
                        .some(value => value === catalogItem[field.catalogCodeProperty]));
                }
            }
        }
        if (this._lastDetailCode !== detailCode || this._anyValueWall == undefined) {
            this._anyValueWall.clear();
        }
        this._anyValueWall.set(name, value);
        this._lastDetailCode = detailCode;
        this._anyValue.next({
            currentField: { name, value },
            changes: this._anyValueWall
        });
    }
    setGenericFormValues(name, value) {
        this._anyValueWall.set(name, value);
        this._anyValue.next({
            currentField: { name, value },
            changes: this._anyValueWall
        });
    }
    setDetailCode(code) {
        this._detailCodeValue.next(code);
        CustomFormValidatorService.detailCode = code;
    }
    clearDetailCode() {
        this._detailCodeValue.next(undefined);
        CustomFormValidatorService.detailCode = undefined;
    }
    setOwnerPartyRoleValue(code) {
        this._ownerPartyRoleValue.next(code);
    }
    setCurrentStatusCodeValue(currentStatusCode) {
        this._currentStatusCodeValue.next(currentStatusCode);
    }
    setCurrentEnvironmentStatusCodeValue(currentEnvironmentStatusCode) {
        this._currentEnvironmentStatusCodeValue.next(currentEnvironmentStatusCode);
    }
    cleanStatusCodeValues() {
        this._currentStatusCodeValue.next(undefined);
        this._currentEnvironmentStatusCodeValue.next(undefined);
    }
    setFormDefinition(form) {
        this._formDefinitionValue.next(form);
    }
    getOwnerPartyRoleValue() {
        return this._ownerPartyRoleValue.getValue();
    }
    getDetailCode() {
        return this._detailCodeValue.getValue();
    }
    getAnyValue() {
        return this._anyValue.getValue();
    }
    getFormDefinitionValue() {
        return this._formDefinitionValue.getValue();
    }
}
FieldValueHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FieldValueHandlerService_Factory() { return new FieldValueHandlerService(); }, token: FieldValueHandlerService, providedIn: "root" });
