<div class="campaign-filters mt-3">

	<div class="row row-filter" *ngFor="let filter of model?.filtersAdded || []; let index = index">
		<div class="col-sm-9 col-md-9 col-lg-7 content-filters">
			<span class="small label-connector" *ngIf="index > 0">
				<i class="fa fa-plus"></i>
			</span>
			<div class="row campaign-filter-content" [ngClass]="{'has-counter': config.showPartialFilterCounter}">
				<label class="control-label col-md-4 col-sm-12 campaign-filter-title">{{filter.description}}</label>
				<div class="col-md-6 col-sm-10 col-xs-10 my-1 app-input emerios-multiselect">
					<emerios-multiselect [config]="getMultiselectConfig(filter)"
						[(ngModel)]="model.selectedItems[filter.code]" [items]="multiselectItems[filter.code]"
						(onSearchInput)="onSearch(filter.code, $event)" (onHidden)="onDropdownClosed(filter)">
					</emerios-multiselect>
				</div>
				<div class="col-md-2 col-sm-2 col-xs-2">
					<a href="javascript:void(0)" class="btn btn-simple btn-danger btn-filter"
						[ngClass]="{'disabled': this.multiselectConfig[filter.code].disabled}"
						(click)="removeFilter(filter)">
						<i class="fa fa-minus-circle fa-2x pull-right"></i>
					</a>
				</div>
			</div>
			<div class="row" *ngIf="config.showPartialFilterCounter">
				<div class="col-md-10 col-sm-10 col-xs-10 mt-1">
					<span class="zipcode-figure-xs pull-right">
						<ng-container *ngIf="!model.selectedItems[filter.code]">--</ng-container>
						<ng-container *ngIf="model.selectedItems[filter.code]">
							<i class="fas fa-circle-notch fa-spin" *ngIf="!model.partialItemCount[filter.code]"></i>
							{{model.partialItemCount[filter.code]}}
						</ng-container>
						{{config.workingObjectLabel}}
					</span>
				</div>
			</div>
		</div>
	</div>

	<div class="row row-filter" *ngIf="model.filterTypes && model.filterTypes.length > 0">
		<div class="col-sm-9 col-md-9 col-lg-7">
			<ng-container *ngIf="model.filtersAdded && model.filtersAdded.length > 0">
				<span class="small label-connector">
					<i class="fa fa-plus"></i>
				</span>
			</ng-container>

			<div class="row campaign-filter-content add-filter mb-3">
				<label class="control-label col-md-4 col-sm-12 campaign-filter-title">Add Filter</label>
				<div class="col-md-6 col-sm-10 col-xs-10 my-1 app-input emerios-multiselect">
					<emerios-multiselect [config]="filterTypeConfig" [items]="model.filterTypes"
						[(ngModel)]="model.filterTypeSelected">
					</emerios-multiselect>
				</div>
				<div class="col-md-2 col-sm-2 col-xs-2">
					<a href="javascript:void(0)" class="btn btn-simple btn-success btn-filter"
						[ngClass]="{'disabled':!model.filterTypeSelected}" href="javascript:void(0)"
						(click)="addFilter()">
						<i class="fa fa-plus-circle fa-2x pull-right"></i>
					</a>
				</div>
			</div>
		</div>
	</div>

	<ng-container *ngIf="chainedFilterConfig.showGeneralFilterCounter">
		<hr />

		<app-chained-filter-counter [counter]="model.totalItemCount" [label]="chainedFilterConfig.workingObjectLabel" [data]="{}"
			(actionFired)="fireAction($event)" [buttonsEnabled]="model.totalItemCount && model.totalItemCount > 0">
		</app-chained-filter-counter>
	</ng-container>
</div>

<!-- <div *ngIf="control && control.errors && control.invalid && !control.pristine">
	<div class="input-error">{{getControlErrors()}}</div>
</div> -->