/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-action.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button-action.component";
var styles_ButtonActionComponent = [i0.styles];
var RenderType_ButtonActionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonActionComponent, data: {} });
export { RenderType_ButtonActionComponent as RenderType_ButtonActionComponent };
export function View_ButtonActionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-default btn-section mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buttonClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-default btn-section mr-2"; var currVal_1 = _co.cssClass; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.text; _ck(_v, 3, 0, currVal_2); }); }
export function View_ButtonActionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-action", [], null, null, null, View_ButtonActionComponent_0, RenderType_ButtonActionComponent)), i1.ɵdid(1, 49152, null, 0, i3.ButtonActionComponent, [], null, null)], null, null); }
var ButtonActionComponentNgFactory = i1.ɵccf("app-button-action", i3.ButtonActionComponent, View_ButtonActionComponent_Host_0, { text: "text", cssClass: "cssClass" }, { triggeredAction: "triggered-action" }, []);
export { ButtonActionComponentNgFactory as ButtonActionComponentNgFactory };
