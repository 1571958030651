import { GeographyRestService } from '../../rest/geography-rest/geography-rest.service';
import { PageLoaderService } from 'ngx-emerios-all';
import { finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { RuleRestService } from '../../rest/rule-rest/rule-rest.service';
import * as i0 from "@angular/core";
import * as i1 from "../../rest/geography-rest/geography-rest.service";
import * as i2 from "../../rest/rule-rest/rule-rest.service";
import * as i3 from "ngx-emerios-all";
export class ChainedFilterService {
    constructor(_geographyRest, _ruleRest, _pageLoader) {
        this._geographyRest = _geographyRest;
        this._ruleRest = _ruleRest;
        this._pageLoader = _pageLoader;
    }
    getAllItemsByType(code, type) {
        if (code === 'geography_grouping') {
            // return this._geographyRest.getGeographyByType(type, criteria);
        }
        else if (['campaign_filter', 'tpv_step_filter'].includes(code)) {
            return this._ruleRest.getAvailabilitySelectorByType(type, '', 0);
        }
        throw new Error(`Chained Filter: code ${code} not implemented`);
    }
    searchItemsByType(code, type, criteria) {
        if (code === 'geography_grouping') {
            return this._geographyRest.getGeographyByType(type, criteria);
        }
        else if (['campaign_filter', 'tpv_step_filter'].includes(code)) {
            return this._ruleRest.getAvailabilitySelectorByType(type, criteria);
        }
        throw new Error(`Chained Filter: code ${code} not implemented`);
    }
    getItemQuantity(code, codes) {
        if (code === 'geography_grouping') {
            return this._geographyRest.getZipcodesCount(codes);
        }
        return of([777]);
    }
    getFilteredItems(code, selectedItems) {
        this._pageLoader.show();
        if (code === 'geography_grouping') {
            let codes = [];
            Object.keys(selectedItems).forEach(prop => {
                if (selectedItems[prop]) {
                    codes.push(...selectedItems[prop]);
                }
            });
            return this._geographyRest.getZipcodes(codes)
                .pipe(finalize(() => this._pageLoader.hide()));
        }
    }
}
ChainedFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChainedFilterService_Factory() { return new ChainedFilterService(i0.ɵɵinject(i1.GeographyRestService), i0.ɵɵinject(i2.RuleRestService), i0.ɵɵinject(i3.PageLoaderService)); }, token: ChainedFilterService, providedIn: "root" });
