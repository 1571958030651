export enum CatalogEnum {
    Request = 'REQUEST',
    PartyRelationshipType = "PARTY_RELATIONSHIP_TYPE",
    PartyInstance = "PARTY_INSTANCE",
    RelationshipTypes = 'RELATIONSHIP_TYPES',
    ParentRelationshipTypes = 'PARENT_RELATIONSHIP_TYPES',
    Language = 'LANG',
    AvailableForms = 'AVAILABLE_FORMS',
    FileAssets = 'BIN.AST',
    DocumentAssets = 'BIN.DOC',
    ProductTypes = 'PRODUCT_TYPE',
    ProductProvider = 'PRODUCT_PROVIDER_INSTANCE',
    Features = 'FTR',
    ClassificationPurpose = 'PRP',
    ProductFeatureApplicability = 'PFAT',
    ProductFeaturesByProductType = 'PRODUCT_FEATURES_BY_PRODUCT_TYPE',
    GeographyGrouping = 'GEO.GRP',
    GeographyGroupingAndState = 'GEO_STATE_AND_GROUP',
    SharedLibraryClassifications = 'CTG.SLIB',
    DynafInstance = 'DYNAF_INSTANCE',
    Dynaf = 'DYNAF',
    Dynas = 'DYNAS',
    Dynag = 'DYNAG',
    Product = 'PRD',
    InstanceByLibraryCode = 'INSTANCE_BY_LIBRARY_CODE',
    ProductInstanceByLibraryCode = 'PRODUCT_INSTANCE_BY_LIBRARY_CODE',
    BinaryInstanceByLibraryCode = 'BIN_INSTANCE_BY_LIBRARY_CODE',
    ApprovalFlows = 'APPROVAL_FLOWS',
    Journeys = 'JOURNEYS',
    JourneySteps = 'JOURNEY_STEPS',
    FlowSteps = 'FLOW_STEPS',
    ApprovalFlowSteps = 'APPROVAL_REQUEST_STEPS',
    StepsByFlow = 'STEPS_BY_FLOW',
    StepsAndStagesByFlow = 'STEPS_AND_STAGES_BY_FLOW',
    BrandOrganization = 'PR.ORG.BRD',
    TenantAndPlatformProiders = 'TENANT_AND_PLAT_PROV_INSTANCE'
}

export enum CatalogNativeEnum {
    YesNo = "native_yesno",
    TrueFalse = "native_truefalse",
    Gender = "native_gender",
    LibraryVisibility = "native_library_visibility",
    FormOperationType = "native_form_operation_type"
}
