import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class ChangeManagementRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getPendingRequestCount() {
        const headers = new HttpHeaders()
            .append('silentError', 'true');
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/pending-request/count`,
            headers: headers
        });
    }
    confirmLink(payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/change-request/confirm-link`,
            body: payload
        });
    }
    confirmLinkMultiple(payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/change-request/confirm-link-multiple`,
            body: payload
        });
    }
    confirmUnlink(changeRequestCode) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/change-request/confirm-unlink`,
            body: { changeRequestCode: changeRequestCode }
        });
    }
    submitRequest(requestCode, executorUserName, signature, approvalWorkEffortInstanceCode) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/submit`,
            body: {
                requestCode: requestCode,
                approvalWorkEffortInstanceCode: approvalWorkEffortInstanceCode,
                executorUserName: executorUserName,
                base64Signature: signature
            }
        });
    }
    approveRequest(requestCode, executorUserName, signature) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/approve`,
            body: {
                requestCode: requestCode,
                executorUserName: executorUserName,
                base64Signature: signature
            }
        });
    }
    rejectRequest(requestCode, executorUserName) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/reject`,
            body: {
                requestCode: requestCode,
                executorUserName: executorUserName
            }
        });
    }
    cancelRequest(requestCode, executorUserName) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/cancel`,
            body: {
                requestCode: requestCode,
                executorUserName: executorUserName
            }
        });
    }
    validateDiscardRequest(requestCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/validate-discard/${requestCode}`,
        });
    }
    discardRequest(requestCode) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/discard/${requestCode}`
        });
    }
    deploy(requestCode, executorUserName) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/deploy`,
            body: {
                requestCode: requestCode,
                executorUserName: executorUserName
            }
        });
    }
    canApprovedOrRejectRequest(requestCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort-execution/request/user-approve-permission/${requestCode}`
        });
    }
    untokenize(token) {
        const encodedToken = encodeURIComponent(token);
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/request/untokenize?token=${encodedToken}`
        });
    }
}
ChangeManagementRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChangeManagementRestService_Factory() { return new ChangeManagementRestService(i0.ɵɵinject(i1.RestService)); }, token: ChangeManagementRestService, providedIn: "root" });
