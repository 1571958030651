import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CbViewerComponent } from './modules/campaign-builder/cb-viewer/cb-viewer.component';
import { LoginGuard } from './guards/login/login.guard';
import { AuthGuard } from './guards/auth/auth.guard';
import { RouteEnum } from './enums/routes';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: RouteEnum.Unavailable },
  { path: RouteEnum.ChangeManagementPlatformOld, redirectTo: RouteEnum.ChangeManagementPlatform },
  {
    path: RouteEnum.Redirect, loadChildren: () => import('./modules/redirect-page/redirect-page.module')
      .then(m => m.RedirectPageModule), canActivate: [AuthGuard]
  },
  {
    path: RouteEnum.Login, loadChildren: () => import('./modules/login-page/login-page.module')
      .then(m => m.LoginPageModule), canActivate: [LoginGuard]
  },
  {
    path: RouteEnum.OrganizationSelector, loadChildren: () => import('./modules/tenant-selector-page/tenant-selector-page.module')
      .then(m => m.TenantSelectorPageModule), canActivate: [AuthGuard]
  },
  {
    path: RouteEnum.Message, loadChildren: () => import('./modules/message-page/message-page.module')
      .then(m => m.MessagePageModule)
  },
  {
    path: RouteEnum.ChangeManagementPlatform, component: CbViewerComponent, canActivate: [AuthGuard],
    children: [
      {
        path: '', loadChildren: () => import('./modules/campaign-builder/campaign-builder.module')
          .then(m => m.CampaignBuilderModule)
      }
    ]
  },
  { path: '**', redirectTo: RouteEnum.Login, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
