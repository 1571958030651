<textarea [id]="config?.id" [name]="config?.name" [attr.class]="config?.cssClasses"
  [placeholder]="config?.placeholder ? config.placeholder : ''" [readonly]="config?.readonly" [formControl]="textarea"
  (input)="applyRegex()" (blur)="trim()"></textarea>

<span class="icon-validation" *ngIf="config?.validationIcons">
  <i [ngClass]="config?.validationIcons.pristine" *ngIf="textarea.pristine && showInputRequiredIcon"></i>
  <i [ngClass]="config?.validationIcons.error" *ngIf="!textarea.pristine && textarea.errors"></i>
  <i [ngClass]="config?.validationIcons.success" *ngIf="!textarea.pristine && !textarea.errors"></i>
</span>

<div *ngIf="textarea.errors && textarea.invalid && textarea.touched">
  <div [ngClass]="config?.errorMsgClass">{{getErrors()}}</div>
</div>