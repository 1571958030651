import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityPartyRoleRelationship, EntityInstanceRelationship } from 'src/app/models/entity.model';
import { ProductRestService } from 'src/app/services/rest/product/product-rest.service';
import { ProductAvailabilityDto } from 'src/app/models/products.model';
import { EntityRestService } from 'src/app/services/rest/entity-rest/entity-rest.service';

@Injectable({
  providedIn: 'root'
})
export class RelationshipHandlerService {
  public readonly relationshipCreated$: Observable<any>;
  public readonly relationshipDeleted$: Observable<any>;

  private readonly _relationshipDeleted = new EventEmitter<string>();
  private readonly _relationshipCreated = new EventEmitter<string>();

  constructor(
    private _entityRest: EntityRestService,
    private _product: ProductRestService
  ) {
    this.relationshipCreated$ = this._relationshipCreated.asObservable();
    this.relationshipDeleted$ = this._relationshipDeleted.asObservable();
  }

  public createRelationship(entity: string, relationship: EntityPartyRoleRelationship) {
    this._entityRest.createEntityRelationships(entity, relationship)
      .subscribe(() => this._relationshipCreated.next('created'));
  }

  public createInstanceRelationship(entity: string, relationship: EntityInstanceRelationship) {
    this._entityRest.createEntityRelationships(entity, relationship)
      .subscribe(() => this._relationshipCreated.next('created'));
  }

  public createFeatureApplicability(relationships: Array<ProductAvailabilityDto>) {
    this._product.createFeatureApplicability(relationships)
      .subscribe(() => { this._relationshipCreated.next('created') });
  }

  public deleteRelationships(entity: string,
    relationships: Array<{ instanceFromCode: string, instanceToCode: string }>) {
    this._entityRest.deleteEntityRelationships(entity, relationships)
      .subscribe(() => this._relationshipDeleted.next('removed'));
  }

  public deleteFeatureApplicability(relationships: Array<ProductAvailabilityDto>) {
    this._product.deleteFeatureApplicability(relationships)
      .subscribe(() => this._relationshipDeleted.next('removed'));
  }

  public updateSequence(entity: string, sequences: Array<any>) {
    this._entityRest.updateSequence(entity, sequences)
      .subscribe(() => this._relationshipDeleted.next('updated'));
  }
}
