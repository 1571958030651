import { OnInit, OnChanges, SimpleChanges, EventEmitter, forwardRef } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { deepClone } from 'src/app/functions/deepClone';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
const noOp = () => { };
const ɵ0 = noOp;
export const SORTABLE_LIST_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SortableListComponent),
    multi: true
};
export var FieldDefinitionTypeEnum;
(function (FieldDefinitionTypeEnum) {
    FieldDefinitionTypeEnum["InputText"] = "input-text";
    FieldDefinitionTypeEnum["InputLang"] = "input-lang";
    FieldDefinitionTypeEnum["TextAreaLang"] = "textarea-lang";
    FieldDefinitionTypeEnum["CatalogSingle"] = "catalog-single";
    FieldDefinitionTypeEnum["Checkbox"] = "checkbox";
    FieldDefinitionTypeEnum["Sort"] = "sort";
})(FieldDefinitionTypeEnum || (FieldDefinitionTypeEnum = {}));
export class SortableListComponent {
    constructor(_elementHelper, _catalog, _fieldValueHandler) {
        this._elementHelper = _elementHelper;
        this._catalog = _catalog;
        this._fieldValueHandler = _fieldValueHandler;
        this.items = new EventEmitter();
        this.internalItems = [];
        this.elementConfig = {};
        this.partList = [];
        this.onTouchedCallback = noOp;
    }
    writeValue(obj) {
        if (obj != this.internalItems) {
            this.internalItems = obj || [];
            if (this.internalItems.length === 0) {
                this.addElement();
            }
            else {
                this.internalItems.forEach(item => {
                    if (item.audioInstanceCode) {
                        this.partList.push(`[${item.audioInstanceCode} reproduction]`);
                    }
                    if (item.textTranslations && item.textTranslations.length > 0) {
                        this.partList.push(item.textTranslations[0].text);
                    }
                    item.elementList = this._initElementListFromEdit(item);
                    item.elementList.forEach(e => {
                        if (e.type === 'catalog-single' && item[e.field]) {
                            item[e.field] = [item[e.field]];
                        }
                    });
                });
                this._restructureInternalItems();
            }
        }
    }
    _initElementListFromEdit(item) {
        const elementListResult = [];
        this.config.sortableDefinition.forEach(def => {
            if (!def.triggerBy) {
                elementListResult.push(def);
            }
            else {
                const trigger = item[def.triggerBy.field];
                if (def.triggerBy.codes.includes(trigger)) {
                    elementListResult.push(def);
                }
            }
        });
        return elementListResult;
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    drop(event) {
        moveItemInArray(this.internalItems, event.previousIndex, event.currentIndex);
        this._checkValidity();
    }
    isSort(type) {
        return type === FieldDefinitionTypeEnum.Sort;
    }
    isInputText(type) {
        return type === FieldDefinitionTypeEnum.InputText;
    }
    isCatalogSingle(def) {
        return def.type === FieldDefinitionTypeEnum.CatalogSingle;
    }
    isInputLang(type) {
        return type === FieldDefinitionTypeEnum.InputLang;
    }
    isTextAreaLang(type) {
        return type === FieldDefinitionTypeEnum.TextAreaLang;
    }
    isCheckbox(type) {
        return type === FieldDefinitionTypeEnum.Checkbox;
    }
    isAudioOptions() {
        return this.config.field === 'audioParts';
    }
    getColumnDistribution(type) {
        switch (type) {
            case FieldDefinitionTypeEnum.Sort:
                return;
            case FieldDefinitionTypeEnum.InputText:
                return 'col-md-2';
            case FieldDefinitionTypeEnum.InputLang:
                return 'col-md-7';
            case FieldDefinitionTypeEnum.TextAreaLang:
                return 'col-md-9';
            default:
                return 'col-md-3';
        }
    }
    isElementValid(event, index) {
        const str = index.toString(10);
        if (!this._elementsValidity[str]) {
            this._elementsValidity[str] = {};
        }
        this._elementsValidity[str][event.name] = event.valid;
        this._checkValidity();
    }
    updatePartList(field, index) {
        const internalItem = this.internalItems[index];
        let value;
        if (typeof (internalItem['name']) === 'object') {
            const key = Object.keys(internalItem[field])[0];
            value = internalItem[field][key];
        }
        if (typeof (internalItem['name']) === 'string') {
            value = internalItem[field];
        }
        this.partList.push(value);
    }
    updateTriggerable(field, index) {
        const internalItem = this.internalItems[index];
        const value = internalItem[field][0];
        const triggerableFields = this.config.sortableDefinition.filter(x => this._isTriggerableByFieldAndValue(x, field, value));
        if (triggerableFields.length > 0) {
            internalItem.elementList = this._clearTriggeralbeItemsFromElementList();
            triggerableFields.forEach(element => {
                if (!internalItem.elementList.find(x => x.field == element.field)) {
                    internalItem.elementList.push(element);
                }
            });
        }
    }
    getElementConfig(def) {
        if (!this.elementConfig[def.field]) {
            const attr = {
                name: def.field,
                placeholder: def.text,
                required: true,
                minlength: 2,
                maxlength: 20
            };
            let config;
            switch (def.type) {
                case FieldDefinitionTypeEnum.InputText:
                    config = this._elementHelper.getInputConfig(attr);
                    break;
                case FieldDefinitionTypeEnum.InputLang:
                    config = this._elementHelper.getInputLangConfig(attr);
                    break;
                case FieldDefinitionTypeEnum.TextAreaLang:
                    config = this._elementHelper.getTextareaLangConfig(attr);
                    break;
                case FieldDefinitionTypeEnum.CatalogSingle:
                    config = this._elementHelper.getMultiSelectConfig(attr);
                    config.singleSelect = true;
                    break;
                default:
                    console.error(`The element type ${def.type} is not available in sortable-list.components.ts`);
                    break;
            }
            this.elementConfig[def.field] = config;
            if (def.catalog != null) {
                this._getCustomCatalog({ field: def.field, catalog: def.catalog });
            }
        }
        this.elementConfig[def.field].readonly = !this.config.attributes.editable;
        return this.elementConfig[def.field];
    }
    addElement() {
        this.internalItems.push({ elementList: this._clearTriggeralbeItemsFromElementList() });
        this._addSort();
    }
    removeElement(index) {
        this.internalItems.splice(index, 1);
        delete this._elementsValidity[index.toString(10)];
        this._updateModelStructure();
    }
    ngOnInit() {
        this._fieldValueHandler.anyValue$.subscribe(x => {
            this.questionName = x.changes.get("instanceName");
        });
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            this._elementsValidity = {};
            if (!this.internalItems || this.internalItems.length === 0) {
                this.addElement();
            }
        }
    }
    _clearTriggeralbeItemsFromElementList() {
        return this.config.sortableDefinition.filter(x => !x.triggerBy);
    }
    _isTriggerableByFieldAndValue(item, field, value) {
        return item.triggerBy && item.triggerBy.field == field && item.triggerBy.codes.includes(value);
    }
    _getCustomCatalog(input) {
        const catalog = this._catalog.get(input.catalog);
        if (catalog && catalog.length > 0) {
            this._setCustomCatalogItems(input, catalog);
        }
        else {
            const ownerElement = this._fieldValueHandler.getAnyValue().changes.get('ownerPartyRoleInstanceCode');
            if (ownerElement) {
                const ownerPartyRoleInstanceCode = ownerElement[0].code;
                this._catalog.getSingleWithFilterFromServer(input.catalog, [ownerPartyRoleInstanceCode])
                    .subscribe(catalog => {
                    this._setCustomCatalogItems(input, catalog.items);
                    this._replacePartList(catalog);
                });
            }
        }
    }
    _replacePartList(catalog) {
        const newList = [];
        this.partList.forEach(x => {
            const elt = catalog.items.find(i => x.includes(i.code));
            if (elt) {
                newList.push(x.replace(elt.code, elt.name));
            }
            else {
                newList.push(x);
            }
        });
        this.partList = newList;
    }
    _setCustomCatalogItems(input, catalog) {
        input.catalogItems = catalog;
        this.elementConfig[input.field].items = catalog;
    }
    _checkValidity() {
        let accumulator = true;
        Object.keys(this._elementsValidity)
            .forEach(itemKey => {
            this.config.sortableDefinition
                .filter(item => item.type !== FieldDefinitionTypeEnum.Sort && item.required)
                .forEach(item => {
                const itemValue = this._elementsValidity[itemKey][item.field] === undefined
                    ? false : this._elementsValidity[itemKey][item.field];
                accumulator = accumulator && itemValue;
            });
        });
        if (accumulator) {
            this._addSort();
            this.items.next(this.internalItems);
        }
        else {
            this.items.next([]);
        }
        this._updateModelStructure();
    }
    _addSort() {
        const sortDef = this.config.sortableDefinition.find(x => x.type === FieldDefinitionTypeEnum.Sort);
        this.internalItems.forEach((item, index) => {
            item[sortDef.field] = index;
        });
        this._updateModelStructure();
    }
    _restructureInternalItems() {
        const inputLangDef = this.config.sortableDefinition
            .filter(x => x.type === FieldDefinitionTypeEnum.InputLang ||
            x.type === FieldDefinitionTypeEnum.TextAreaLang);
        inputLangDef.forEach(def => {
            this.internalItems.forEach(item => {
                if (item[def.field]) {
                    const newItem = {};
                    item[def.field].forEach((x) => newItem[x.languageCode] = x.text);
                    item[def.field] = newItem;
                }
            });
        });
    }
    _updateModelStructure() {
        const inputLangDef = this.config.sortableDefinition.filter(x => x.type === FieldDefinitionTypeEnum.InputLang);
        const items = deepClone(this.internalItems);
        inputLangDef.forEach(def => {
            items.forEach(item => {
                if (item[def.field]) {
                    const translations = [];
                    Object.keys(item[def.field])
                        .forEach(langCode => {
                        const translation = {};
                        translation[def.translationPropCode] = langCode;
                        translation[def.translationPropText] = item[def.field][langCode];
                        translations.push(translation);
                    });
                    item[def.field] = translations;
                }
            });
        });
        if (this._onChange) {
            this._onChange(items);
        }
    }
}
export { ɵ0 };
