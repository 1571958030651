import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AggridColumnHelperService {

  constructor() { }

  public getNativeCheckboxColumn(headerCheckbox: boolean) {
    return {
      headerName: "",
      filter: false,
      width: 40,
      lockPosition: true,
      suppressToolPanel: true,
      headerCheckboxSelection: headerCheckbox,
      checkboxSelection: true,
      resizable: false
    };
  }

  public getLockedColumn(name: string, cbCellRendered?: Function, cbCellClickedCallback?: Function) {
    const baseColumnDef = this._getBaseLockedColumn();

    baseColumnDef.headerName = '';
    baseColumnDef.field = name;
    baseColumnDef.width = 40;
    baseColumnDef.cellRenderer = cbCellRendered;
    baseColumnDef.onCellClicked = cbCellClickedCallback;

    return baseColumnDef;
  }

  public getDraggabpeColumn(rowDragCallback: Function) {
    const draggableColumn = this._getBaseLockedColumn();

    draggableColumn.width = 40;
    draggableColumn.rowDrag = rowDragCallback;

    return draggableColumn;
  }

  public getTabbedRuleCreatorCustomColumns(
    rowDragCallback: Function, cbCellClickedCallback: Function, cbCellRendered: Function) {
    const baseColumnDef = this._getBaseLockedColumn();
    const columns = [];

    const draggableColumn = Object.assign({}, baseColumnDef);
    const checkboxColumn = Object.assign({}, baseColumnDef);
    const orderColumn = Object.assign({}, baseColumnDef);

    draggableColumn.width = 40;
    draggableColumn.rowDrag = rowDragCallback;

    checkboxColumn.field = 'rowSelected';
    checkboxColumn.width = 40;
    checkboxColumn.onCellClicked = cbCellClickedCallback;
    checkboxColumn.cellRenderer = cbCellRendered;

    orderColumn.width = 100;
    orderColumn.field = 'sortId';
    orderColumn.headerName = 'Sequence';

    columns.push(draggableColumn, checkboxColumn, orderColumn);

    return columns;
  }

  private _getBaseLockedColumn(): any {
    return {
      lockPosition: true,
      suppressToolPanel: true,
      filter: false,
      resizable: false,
      sortable: false
    };
  }
}
