<div [formGroup]="form">
  <div class="input-group">
    <div class="input-group-btn">
      <button type="button" class="btn btn-default" [ngClass]="{'disabled':config.readonly}"
        (click)="actionButtonClicked($event)">{{model.selectedText}}</button>
      <emerios-multiselect [config]="multiselectConfig" [items]="multiselectItems" formControlName="langCode"
        (onItemChecked)="onMultiselectItemChecked($event)">
      </emerios-multiselect>
    </div>

    <emerios-input *ngIf="type==='input'" [config]="inputConfig" formControlName="inputText"
      (ngModelChange)="inputValueChanged($event)">
    </emerios-input>

    <emerios-textarea *ngIf="type==='textarea'" [config]="textareaConfig" formControlName="textareaText"
      (ngModelChange)="inputValueChanged($event)">
    </emerios-textarea>

  </div>

  <span *ngIf="config?.validationIcons" class="icon-validation">
    <i [ngClass]="config?.validationIcons.pristine" *ngIf="control.pristine && showInputRequiredIcon"></i>
    <i [ngClass]="config?.validationIcons.error" *ngIf="!control.pristine && control.errors"></i>
    <i [ngClass]="config?.validationIcons.success" *ngIf="!control.pristine && !control.errors"></i>
  </span>

  <div *ngIf="control && control.errors && control.invalid && !control.pristine">
    <div [ngClass]="config?.errorMsgClass">{{getInputLangErrors()}}</div>
  </div>

</div>