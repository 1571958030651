import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class ReplacerRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getReplacerByFilter(contextInstanceCodeArray, search, minSearchLength) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: 'api/v1/replacer/catalog-typeahead',
            params: {
                contextInstanceCodes: contextInstanceCodeArray,
                search: search,
                minSearchLength: minSearchLength
            }
        });
    }
}
ReplacerRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReplacerRestService_Factory() { return new ReplacerRestService(i0.ɵɵinject(i1.RestService)); }, token: ReplacerRestService, providedIn: "root" });
