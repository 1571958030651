import { Injectable } from '@angular/core';
import { RestService, CacheService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { AgGridBaseModel } from 'src/app/models/aggrid/aggrid-base.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileRestService {

  constructor(
    private _rest: RestService,
    private _cache: CacheService) { }

  public downloadFile(fileStorageReference: string, silentError: boolean = false) {
    const headers = new HttpHeaders()
      .append('silentError', silentError ? 'true' : 'false');
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/file/download/${fileStorageReference}`,
      responseType: 'blob',
      headers: headers
    });
  }

  public getPdf(id: string) {
    const token = this._cache.get('flowToken');

    return this._rest.get({
      baseUrl: '',
      endpoint: `api/v1/${token}/file/pdf`,
      body: id,
      responseType: 'arrayBuffer'
    });
  }

  public uploadBook(section: string, payload: any) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${section}/book/upload`,
      body: payload
    });
  }

  public downloadBookPreview(section: string, instanceCode: string) {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${section}/book/upload/preview/${instanceCode}`
    });
  }

  public downloadBookPreviewMultiple(section: string, instanceCode: string, journeyFormStepInstanceCode?: string) {

    var url = journeyFormStepInstanceCode 
      ? `api/v1/${section}/book/upload/preview-multiple/${instanceCode}/${journeyFormStepInstanceCode}`
      : `api/v1/${section}/book/upload/preview-multiple/${instanceCode}`

    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: url
    });
  }

  public processBook(section: string, payload: any) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${section}/book/process`,
      body: payload
    });
  }

  public downloadBookTemplate(section: string, instanceCode: string, journeyStepFormInstanceCode: string) {
    let endpoint = `api/v1/${section}/book/download/${instanceCode}`;
    if (journeyStepFormInstanceCode) {
      endpoint = `api/v1/${section}/book/download/${instanceCode}/${journeyStepFormInstanceCode}`;
    }
    return this._rest.get<HttpResponse<Blob>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: endpoint,
      responseType: 'blob',
      observe: 'response'
    })
    .pipe(map((result: HttpResponse<Blob>) => {
      let fileName = 'download.xlsx';
      const contentDisposition = result.headers.get('content-disposition');
      if (contentDisposition != null) {
        fileName = contentDisposition.split(';')[1].trim().split('filename=')[1].replace(/['"]+/g, '');
      }
      return { file: result.body, fileName: fileName };
    }));
  }

  public generateAuditForm(instanceCode: string, payload: Array<AgGridBaseModel>) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/request/generate-audit-form/${instanceCode}`,
      body: payload
    });
  }

  public downloadAuditForm(instanceCode: string, jobExecutionUid: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/request/download-audit-form/${instanceCode}/${jobExecutionUid}`,
      responseType: 'blob',
      observe: 'response'
    })
    .pipe(map((result: HttpResponse<Blob>) => {
      let fileName = 'download.pdf';
      const contentDisposition = result.headers.get('content-disposition');
      if (contentDisposition != null) {
        fileName = contentDisposition.split(';')[1].trim().split('filename=')[1].replace(/['"]+/g, '');
      }
      return { file: result.body, fileName: fileName };
    }));
  }

  public generateAgreeFlowAuditForm(flowType: string, instanceCode: string, payload: Array<AgGridBaseModel>) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort/flow/${flowType}/generate-audit-form/${instanceCode}`,
      body: payload
    });
  }

  public downloadAgreeFlowAuditForm(flowType: string, instanceCode: string, jobExecutionUid: string) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort/flow/${flowType}/download-audit-form/${instanceCode}/${jobExecutionUid}`,
      responseType: 'blob',
      observe: 'response'
    })
    .pipe(map((result: HttpResponse<Blob>) => {
      let fileName = 'download.pdf';
      const contentDisposition = result.headers.get('content-disposition');
      if (contentDisposition != null) {
        fileName = contentDisposition.split(';')[1].trim().split('filename=')[1].replace(/['"]+/g, '');
      }
      return { file: result.body, fileName: fileName };
    }));
  }

}
