import { Component, OnInit, Input } from '@angular/core';

export type GhostLoadingType = 'dashboard' | 'aggrid' | 'dynamic-form' | 'catalog';

@Component({
  selector: 'app-ghost-loading',
  templateUrl: './ghost-loading.component.html',
  styleUrls: ['./ghost-loading.component.sass']
})
export class GhostLoadingComponent implements OnInit {
  @Input() public type: GhostLoadingType;

  constructor() { }

  ngOnInit() {
  }

}
