import { Component, OnInit, Input } from '@angular/core';
import { GridInput, GridInputMetadata } from 'src/app/components/grid/grid.model';
import { BsModalRef } from 'ngx-bootstrap';
import { WaitingLoaderService } from 'src/app/services/behavior/waiting-loader/waiting-loader.service';

@Component({
  selector: 'app-discard-changes-modal',
  templateUrl: './discard-changes-modal.component.html',
  styleUrls: ['./discard-changes-modal.component.sass']
})
export class DiscardChangesModalComponent implements OnInit {
  private _DISCARD_CHANGES_GRID_METADATA : GridInputMetadata = {
    columnDefinition: [
      {
          headerName: "Associations",
          children: [
              {
                  headerName: "Entity Type",
                  field: "entityLibraryCode",
                  filter: "agTextColumnFilter"
              },
              {
                  headerName: "Entity Name",
                  field: "entityName",
                  filter: "agTextColumnFilter"
              },
              {
                  headerName: "Code",
                  field: "entityCode",
                  filter: "agTextColumnFilter"
              }
          ]
      }
  ],
    groupDefaultExpanded: undefined,
    autoGroupColumnDefinition: undefined,
  };

  private _CANCEL_REQUEST_GRID_METADATA : GridInputMetadata = {
    columnDefinition: [
      {
        headerName: "Entity",
        children: [
            {
                headerName: "Entity Type",
                field: "entityLibraryCode",
                filter: "agTextColumnFilter",
                rowGroup: true
            },
            {
                headerName: "Entity Name",
                field: "entityName",
                filter: "agTextColumnFilter"
            },
            {
                headerName: "Code",
                field: "entityCode",
                filter: "agTextColumnFilter"
            }
        ]
      },
      {
        headerName: "Associations",
        children: [
            {
                headerName: "Entity Type",
                field: "associatedEntityLibraryCode",
                filter: "agTextColumnFilter"
            },
            {
                headerName: "Entity Name",
                field: "associatedEntityName",
                filter: "agTextColumnFilter"
            },
            {
                headerName: "Code",
                field: "associatedEntityCode",
                filter: "agTextColumnFilter"
            }
        ]
      }
  ],
    groupDefaultExpanded: 0,
    autoGroupColumnDefinition: true,
  };

  public data: any;
  public type: 'discardChanges' | 'discardRequest';
  public callback: Function;
  public title: string;

  public discardChangesWarning: boolean;
  public discardChangesBlocker: boolean;
  public cancelRequestWarning: boolean;
  public cancelRequestBlocker: boolean;

  public gridDataModel: GridInput

  constructor(
    private _modal: BsModalRef,
    private _waitingLoader: WaitingLoaderService) {      
  }

  ngOnInit() {   
    this.gridDataModel = {
      uid: 0,
      rows: undefined,
      source: undefined,
      columns: undefined
    };

    if(this.data) {
      switch(this.type) {
        case 'discardChanges':
          this.title = "Confirm Discard Changes";
          this.gridDataModel.metadata = this._DISCARD_CHANGES_GRID_METADATA;

          if(this.data.hasBlockers) {
            this.discardChangesWarning = false;
            this.discardChangesBlocker = true;
            this.cancelRequestWarning = false;
            this.cancelRequestBlocker = false;
            this.gridDataModel.rows = this.data.blockers;
          }
          else if(this.data.hasWarnings){
            this.discardChangesWarning = true;
            this.discardChangesBlocker = false;
            this.cancelRequestWarning = false;
            this.cancelRequestBlocker = false;
            this.gridDataModel.rows = this.data.warnings;
          }
        break;
        case 'discardRequest':
          this.title = "Confirm Cancel Request";
          this.gridDataModel.metadata = this. _CANCEL_REQUEST_GRID_METADATA;

          if(this._hasBlockers(this.data)) {
            this.discardChangesWarning = false;
            this.discardChangesBlocker = false;
            this.cancelRequestWarning = false;
            this.cancelRequestBlocker = true;
            this.gridDataModel.rows = this._getData(this.data, 'hasBlockers', 'blockers')
          } else if(this._hasWarnings(this.data)) {
            this.discardChangesWarning = false;
            this.discardChangesBlocker = false;
            this.cancelRequestWarning = true;
            this.cancelRequestBlocker = false;
            this.gridDataModel.rows = this._getData(this.data, 'hasWarnings', 'warnings')
          }
          break;
      }
    }
  }

  private _hasBlockers(validations: Array<any>) : boolean {
    for(let i = 0; i < validations.length; i++) {
      if(validations[i].hasBlockers) {
        return true;
      }
    }
    return false;
  }

  private _hasWarnings(validations: Array<any>) : boolean {
    for(let i = 0; i < validations.length; i++) {
      if(validations[i].hasWarnings) {
        return true;
      }
    }
    return false;
  }
 
  private _getData(validations: Array<any>, validationfield: string, field: string) : Array<any> {
    var data = new Array<any>();

    for(let i = 0; i < validations.length; i++) {
      if(validations[i][validationfield]) {
        for(let j = 0; j < (validations[i][field]).length; j++) {
          data.push({
              entityCode: validations[i].entityCode,
              entityType: validations[i].entityType,
              entityName: validations[i].entityName,
              entityLibraryCode: validations[i].entityLibraryCode,
              associatedEntityCode: (validations[i][field])[j].entityCode,
              associatedEntityType: (validations[i][field])[j].entityType,
              associatedEntityName: (validations[i][field])[j].entityName,
              associatedEntityLibraryCode: (validations[i][field])[j].entityLibraryCode,
          });
        }
      }
    }
    return data;
  }

  public executeCallback() {
    if(this.callback) {
      this._waitingLoader.showFullScreenLoading();
      this.callback();
    }
    this.closeModal();
  }

  public closeModal() {
    this._modal.hide();
  }
}
