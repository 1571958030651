<input [id]="config?.id" [name]="config?.name" [attr.class]="config?.cssClasses" type="text"
  [placeholder]="config?.placeholder ? config.placeholder : ''" autocomplete="off" [formControl]="input"
  [minDate]="bsConfig?.minDate" [maxDate]="bsConfig?.maxDate" [bsConfig]="bsConfig" triggers="touchstart click"
  bsDatepicker readonly>

<span class="icon-validation" *ngIf="config?.validationIcons">
  <i [ngClass]="config?.validationIcons.pristine" *ngIf="input.pristine && showInputRequiredIcon"></i>
  <i [ngClass]="config?.validationIcons.error" *ngIf="!input.pristine && input.errors"></i>
  <i [ngClass]="config?.validationIcons.success" *ngIf="!input.pristine && !input.errors"></i>
</span>

<div *ngIf="input.errors && input.invalid && input.touched">
  <div [ngClass]="config?.errorMsgClass">{{getInputErrors()}}</div>
</div>