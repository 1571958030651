import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormDefinition, FieldDefinition, InputLangDefinition } from 'src/app/models/form-field-definition.models';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { Subscription, of, Subject, forkJoin } from 'rxjs';
import { DataModelHelperService } from 'src/app/services/helper/data-model-helper/data-model-helper.service';
import { FieldApplicability, CatalogFeature } from 'src/app/models/catalog.models';
import { deepClone } from 'src/app/functions/deepClone';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { groupBy } from 'src/app/functions/groupBy';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { CustomFormValidatorService } from 'src/app/services/behavior/custom-form-validator/custom-form-validator.service';
import { switchMap, catchError } from 'rxjs/operators';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';

export enum FeatureType {
  Boolean = 'FTR.BIT',
  Date = 'FTR.DATE',
  Number = 'FTR.NUM',
  String = 'FTR.STR',
  Multilang = 'FTR.LANG',
  FileAsset = 'FTR.BIN_AST',
  FileDocument = 'FTR.BIN_DOC'
}

@Component({
  selector: 'app-dynamic-form-group-container',
  templateUrl: './dynamic-form-group-container.component.html',
  styleUrls: ['./dynamic-form-group-container.component.sass']
})
export class DynamicFormGroupContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public forceFormValidation: EventEmitter<any>;
  @Input() public fieldsDistribution: 'page' | 'modal' = 'page';
  @Input() public detailCode: string;
  @Input() public groups: Array<FormDefinition>;
  @Input() public disabled: boolean;
  @Input() public data: any;
  @Input() public entityName: string;

  @Output() public isValid: EventEmitter<any> = new EventEmitter();
  @Output() public dirty: EventEmitter<boolean> = new EventEmitter();
  @Output() public updateDynamicGroups: EventEmitter<Array<FormDefinition>> = new EventEmitter();

  public externalEvent: { name: string, value: any, stopPropagation?: boolean };

  public fieldsLoading: boolean = true;
  public allGroups: Array<{ static: boolean, definition: FormDefinition }> = [];

  private _featuresFromCatalog = new Subject<{ dynamicGroupDef: FieldDefinition, filter: Array<string> }>();

  private _subscriptions: Array<Subscription> = [];
  private _dashboardItem: DashboardItem;
  private _elementsValidity: any = {};
  private _dynamicCreateGroups: Array<FieldDefinition> = [];
  private _features: Array<any> = [];
  private _viewMode: string;
  private _defaultFeatureSectionName: string = 'DEFAULT DYNAMIC FEATURES';

  constructor(
    private _catalogService: CatalogService,
    private _dataModelHelper: DataModelHelperService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _dashboardHandler: DashboardHandlerService,
    private _detailViewHandler: DetailViewHandlerService
  ) { }

  public isValidGroup(event: any) {
    this._elementsValidity[event.code] = event.valid;

    this._revalidateFormGroups();
  }

  public formDirtinessChanged(dirty: boolean) {
    this.dirty.emit(dirty);
  }

  public onFieldChange(event: { name: string, value: any }) {
    setTimeout(() => {
      this._checkForGroupsVisibility(event);
      this._checkForDynamicGroupCreation(event);
    });
  }

  public bubbledEvent(event: { name: string, value: any, stopPropagation?: boolean }) {
    setTimeout(() => {
      this.externalEvent = { name: event.name, value: event.value, stopPropagation: true };
    });
  }

  public formSelected(event: { formDefinition: Array<FormDefinition>, hideStaticGroups: boolean }) {
    this._deleteAllDynamicGenGroups();

    if (event) {
      let position = this.allGroups.findIndex(x => x.definition.sectionId.toString().startsWith('DYNAS.STP.GENFRM'));

      event.formDefinition.forEach(def => {
        position++;
        this.allGroups.splice(position, 0, { definition: def, static: true })
      });

      this.updateDynamicGroups.next(this.allGroups.map(x => x.definition));

      if (event.hideStaticGroups) {
        this.allGroups.forEach(g => {
          if (!g.definition.dynamicSection && !g.definition.showDuringJourney) {
            g.definition.hidden = true;
          }
        })
      }
    }
  }

  public getGroups() {
    return this.allGroups.map(x => x.definition);
  }

  ngOnInit() {
    this._subscriptions.push(this._fieldValueHandler.ownerPartyRoleValue$
      .subscribe(owner => {
        CustomFormValidatorService.ownerPartyRoleInstanceCode = owner;
        const groupFilteredByTenant = this.allGroups.find(x => x.definition.filterByTenant);
        if (groupFilteredByTenant) {
          groupFilteredByTenant.definition.hidden = false;
        }
      }));

    this._subscriptions.push(this._dashboardHandler.currentDashboardItem$
      .subscribe(item => this._dashboardItem = item));

    this._subscriptions.push(this._detailViewHandler.mode$
      .subscribe(mode => {
        this._viewMode = mode;
      }));

    this._configureGetFeauresFromCatalog();
  }

  public checkGroupVisibility(group: FormDefinition, data: any) : boolean {
      if(!group.hidden && group.visibilityField) {
          return data[group.visibilityField];
      }
      return !group.hidden;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.groups && changes.groups.currentValue) {
      setTimeout(() => {
        this.fieldsLoading = false;

        this._initializeFields(this.entityName || this._dashboardItem.name);
      }, 100);

    } else {
      this.fieldsLoading = true;
      this.allGroups = [];
      this._elementsValidity = {};
    }

    if (changes.data && changes.data.currentValue) {
      this._features = this.data.features;
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
    this._clearOwnerValue();
  }

  private _clearOwnerValue() {
    CustomFormValidatorService.ownerPartyRoleInstanceCode = undefined;
    this._fieldValueHandler.setOwnerPartyRoleValue(undefined);
  }

  private _initializeFields(entityName: string) {
    this.allGroups = [];
    this._dynamicCreateGroups = [];

    this.groups.forEach(group => {
      if (group.fields) {
        if (group.filterByTenant) { group.hidden = true; }
        if (!group.triggerBy) {
          // if we are creating a new entity, avoid showing not editable fields
          if (!this.detailCode) {
            // show the field if value has value
            // and is not of rules-grid type
            group.fields = group.fields
              .filter(field => (field.field === 'campaignLibraryTypeCode') || (field.attributes.editable || field.value))
              .filter(field => field.type !== 'rules-grid');
          }

          if (this.data && this.data.parentInstanceLibraryCode
            && this.data.parentInstanceLibraryCode === 'STP.DOC') {
            const field = group.fields.find(x => x.field === 'workEffortTypeCode');
            if (field) {
              field.value = 'STP.FRM.DOC';
            }
          }

          if (group.fields.length > 0) {
            this.allGroups.push({ static: true, definition: group });
          }
        } else {
          group.hidden = true;
          this.allGroups.push({ static: false, definition: group });
        }

        const fields = group.fields.filter(x => x.dynamicGroup);
        this._dynamicCreateGroups.push(...fields);
      }

      group.title = group.title.replace('${entity}', entityName);
    });
  }

  private _checkForGroupsVisibility(event: { name: string, value: any }) {
    const dynamicVisibilityGroups = this.groups
      .filter(group => group.triggerBy)

    const isTrigger = dynamicVisibilityGroups.filter(x => x.triggerBy.field == event.name).length > 0;

    if (!isTrigger || !event.value) {
      return;
    }

    let codes: Array<string> = [];

    if (Array.isArray(event.value)) {
      codes.push(...deepClone(event.value));
    } else {
      codes.push(deepClone(event.value)[0]);
    }

    // we work only with groups NOT triggered by the current event field (event.name)
    const groupsToWork = this._getDynamicGroups().filter(x => x.triggeredBy === event.name);
    const groupsToBeVisible = dynamicVisibilityGroups
      .filter(group => {
        return group.triggerBy.field == event.name
          && group.triggerBy.codes.some(c => codes.includes(c))
          && this._checkViewModeForTriggering(group)
      });

    const groupsToRemove = groupsToWork.filter(groupToWork => {
      const mustBeVisible = groupsToBeVisible.filter(groupToBeVisible => {
        if (groupToBeVisible.name && groupToWork.name) {
          return groupToBeVisible.name === groupToWork.name;
        } else {
          return groupToBeVisible.title === groupToWork.title;
        }
      });

      return mustBeVisible.length === 0;
    });

    groupsToRemove.forEach(group => {
      delete this._elementsValidity[group.title.toLocaleLowerCase().replace(/ /g, '_')];
      this._revalidateFormGroups();
    });

    this.allGroups.forEach(group => {
      //if is dynamic
      if (!group.static &&
        (group.definition.triggeredBy == undefined || group.definition.triggeredBy === event.name)) {
        const mustBeVisible = groupsToBeVisible.find(x => {
          if (x.name && group.definition.name) {
            return x.name === group.definition.name;
          } else {
            return x.title === group.definition.title;
          }
        }) != undefined;

        if (mustBeVisible) {
          group.definition.triggeredBy = event.name;
          group.definition.hidden = false;
        } else {
          group.definition.hidden = true;
          // group.definition.hidden = groupsToRemove
          //   .filter(k => k.title === group.definition.title).length > 0;
        }
      }
    });
  }

  private _checkViewModeForTriggering(group: FormDefinition) {
    if (group.triggerBy.notTriggeredOnCreate) {
      return this._viewMode != 'CREATE';
    } else {
      return true;
    }
  }

  private _checkForDynamicGroupCreation(event: { name: string, value: any }) {
    const dynamicGroup = this._dynamicCreateGroups.find(x => x.field == event.name);

    if (dynamicGroup && event.value) {
      setTimeout(() => {
        // the deepClone is to avoid changing value by reference
        const filter: Array<string> = deepClone(Array.isArray(event.value) ? event.value : event.value[0]);

        this._featuresFromCatalog.next({ dynamicGroupDef: dynamicGroup, filter: filter });
      });
    }
  }

  private _configureGetFeauresFromCatalog() {
    this._subscriptions.push(
      this._featuresFromCatalog.pipe(
        switchMap((data: any) => {
          const dynamicGroupDef = data.dynamicGroupDef;
          const sourceCatalog = data.dynamicGroupDef.dynamicGroup.sourceCatalog;
          const filter = data.filter;

          if (dynamicGroupDef.dynamicGroup.filterByExtraValues) {
            filter.splice(0, 0, ...dynamicGroupDef.dynamicGroup.filterByExtraValues);
          }

          return forkJoin(of(dynamicGroupDef), this._catalogService.getFiltrableCatalog<CatalogFeature>(sourceCatalog, filter)
            .pipe(catchError(() => of(undefined))));

        })).subscribe(data => {
          const dynamicGroupDef = data[0];
          const catalog = data[1];
          const currentGroups = this._getDynamicGroups().filter(x => x.id === dynamicGroupDef.dynamicGroup.id);
          const currentGroupFields = [].concat(...currentGroups.map(x => x.fields));
          const featuresByType = groupBy(catalog && catalog.items || [], 'productTypeInstanceCode');

          this._getDynamicGroups().forEach(group => {
            delete this._elementsValidity[group.title.toLocaleLowerCase().replace(/ /g, '_')];
          });

          this.allGroups = this.allGroups
            .filter(x => x.static || x.definition.id !== dynamicGroupDef.dynamicGroup.id);

          if (catalog) {
            Object.keys(featuresByType).forEach(prop => {
              this._createDynamicGroup(dynamicGroupDef, featuresByType[prop], currentGroupFields);
            });
          }
        }));
  }

  private _createDynamicGroup(dynamicGroupDef: FieldDefinition, features: Array<CatalogFeature>, currentGroupFields: Array<FieldDefinition>) {
    const groupedFeaturesObj = this._groupFeaturesByFeatureCategory(features);
    const groupAmount = Object.keys(groupedFeaturesObj).length;
    let defaultFeatureSection: Array<CatalogFeature> = undefined;
    let groupOrder = dynamicGroupDef.dynamicGroup.order;

    if (groupAmount > 0) {
      Object.keys(groupedFeaturesObj).forEach(key => {
        if (key === this._defaultFeatureSectionName) {
          defaultFeatureSection = groupedFeaturesObj[key];
        } else {
          this._addGroup(dynamicGroupDef, groupOrder, key, groupedFeaturesObj[key], currentGroupFields);
          groupOrder++;
        }
      });
      if (defaultFeatureSection) {
        const lastGroupOrder = dynamicGroupDef.dynamicGroup.order + (groupAmount - 1);
        this._addGroup(dynamicGroupDef, lastGroupOrder, this._defaultFeatureSectionName, defaultFeatureSection, currentGroupFields);
      }
    } else {
      const productTypeName = features && features[0].productTypeInstanceName;
      const groupTitle = `${dynamicGroupDef.dynamicGroup.title} for ${productTypeName}`;
      this._addGroup(dynamicGroupDef, groupOrder, groupTitle, features, currentGroupFields);
    }
  }

  private _addGroup(
    dynamicGroupDef: FieldDefinition,
    groupOrder: number,
    title: string,
    featuresGroupedByCategory: CatalogFeature[],
    currentGroupFields: Array<FieldDefinition>) {
    const fields: Array<FieldDefinition> = [];
    const newGroup = {
      id: dynamicGroupDef.dynamicGroup.id,
      name: dynamicGroupDef.dynamicGroup.id,
      order: groupOrder,
      title: title,
      type: 'fields',
      fields: []
    } as FormDefinition;


    featuresGroupedByCategory.forEach(async (item, i) => {
      const field = this._handleDynamicFieldCreation(item, dynamicGroupDef, i);
      fields.push(field);
    });

    this._loadDynamicGroupsData(fields, currentGroupFields);

    newGroup.fields = fields;
    this.allGroups.push({ static: false, definition: newGroup });
  }

  private _groupFeaturesByFeatureCategory(features: CatalogFeature[]) {
    const featureObj: { [key: string]: Array<CatalogFeature> } = {};
    let featureCategoryDescription: string;

    features.forEach(feature => {
      featureCategoryDescription = feature.featureCategory
        ? feature.featureCategory.description
        : this._defaultFeatureSectionName;

      if (Object.keys(featureObj).includes(featureCategoryDescription)) {
        featureObj[featureCategoryDescription].push(feature);
      } else {
        Object.assign(featureObj, { [featureCategoryDescription]: [feature] });
      }
    });

    return featureObj;
  }

  private _loadDynamicGroupsData(fields: Array<FieldDefinition>, currentGroupFields: Array<FieldDefinition>) {
    fields.forEach(field => {
      const fieldName = field.field.indexOf('#') ? field.field.split('#')[1] : field.field;
      const categoryInstanceCode = field.field.indexOf('#') ? field.field.split('#')[0] : undefined;
      const featureIndex = (this._features || [])
        .findIndex(feature => {
          const filterByCategory = categoryInstanceCode != undefined && feature.categoryInstanceCode != undefined;

          return feature.featureInstanceCode === fieldName
            && (filterByCategory ? feature.categoryInstanceCode == categoryInstanceCode : true)
        });

      let existingValue: any;
      if (currentGroupFields != undefined) {
        const existingField = currentGroupFields.find(x => x.field === field.field);

        existingValue = existingField && existingField.value;
      }

      if (existingValue) {
        field.value = existingValue;
      } else {
        if (featureIndex > -1) {
          const value = this._features[featureIndex] && this._features[featureIndex].value;
          this._dataModelHelper.setValueFromEntityProp(value, field);
        }
      }
    });
  }

  private _revalidateFormGroups() {
    const isValid = Object.values(this._elementsValidity).every((v: boolean) => v);
    const fieldGroups = this.allGroups.map(x => x.definition);

    this.isValid.emit({ valid: isValid, value: fieldGroups });
  }

  private _handleDynamicFieldCreation(item: CatalogFeature, fieldDef: FieldDefinition, index: number) {
    const featureType = item.featureLibraryCodeDataType;
    let desc: string = item.description;

    if (item.descriptions) {
      desc = item.descriptions[0].text;
    }

    switch (featureType) {
      case FeatureType.Boolean:
        return this._createBitField(item, fieldDef, desc, index);
      case FeatureType.Date:
        return this._createDateField(item, fieldDef, desc, index);
      case FeatureType.Multilang:
        return this._createTextareaField(item, fieldDef, desc, index, true);
      case FeatureType.Number:
        return this._createNumberField(item, fieldDef, desc, index);
      case FeatureType.String:
        return this._createTextareaField(item, fieldDef, desc, index, false);
      case FeatureType.FileAsset:
        return this._createFileAssetCatalog(item, fieldDef, desc, index);
      case FeatureType.FileDocument:
        return this._createFileDocumentCatalog(item, fieldDef, desc, index);
      default:
        console.error('Invalid FeatureType', featureType);
    }
  }

  private _createField(item: CatalogFeature, fieldDef: FieldDefinition, desc: string, index: number) {
    return {
      field: `${item.productTypeInstanceCode}#${item.code}`,
      fieldArray: fieldDef.dynamicGroup.fieldArray,
      order: index,
      metadata: this._getHardcodedMetadataObj(item),
      attributes: {
        label: desc,
        name: `${item.productTypeInstanceCode}#${item.code}`,
        required: item.applicabilityTypeInscanceName === FieldApplicability.Required,
        editable: fieldDef.attributes.editable
      }
    } as FieldDefinition;;
  }

  private _createNumberField(item: CatalogFeature, fieldDef: FieldDefinition, desc: string, index: number) {
    const newField = this._createField(item, fieldDef, desc, index);

    newField.type = 'input-text';
    newField.attributes.type = 'number';

    return newField;
  }

  private _createDateField(item: CatalogFeature, fieldDef: FieldDefinition, desc: string, index: number) {
    const newField = this._createField(item, fieldDef, desc, index);

    newField.type = 'input-date';
    newField.attributes.minlength = 10;
    newField.attributes.pattern = '^(|(0[1-9])|(1[0-2]))\/((0[1-9])|(1\\d)|(2\\d)|(3[0-1]))\/((\\d{4}))$';
    newField.attributes.mask = '00/00/0000';

    return newField;
  }

  private _createBitField(item: CatalogFeature, fieldDef: FieldDefinition, desc: string, index: number) {
    const newField = this._createField(item, fieldDef, desc, index);

    newField.type = 'catalog-single';
    newField.catalog = 'native_truefalse';

    return newField;
  }

  private _createTextareaField(item: CatalogFeature, fieldDef: FieldDefinition, desc: string, index: number, translate: boolean) {
    const newField = this._createField(item, fieldDef, desc, index) as InputLangDefinition;

    newField.type = translate ? 'textarea-lang' : 'textarea';
    newField.catalog = translate ? CatalogEnum.Language : undefined;
    newField.translationPropCode = 'languageCode';
    newField.translationPropText = 'text';

    return newField;
  }

  private _createFileAssetCatalog(item: CatalogFeature, fieldDef: FieldDefinition, desc: string, index: number) {
    const newField = this._createField(item, fieldDef, desc, index);

    newField.type = 'catalog-single';
    newField.catalog = CatalogEnum.FileAssets;

    return newField;
  }

  private _createFileDocumentCatalog(item: CatalogFeature, fieldDef: FieldDefinition, desc: string, index: number) {
    const newField = this._createField(item, fieldDef, desc, index);

    newField.type = 'catalog-single';
    newField.catalog = CatalogEnum.DocumentAssets;

    return newField;
  }

  private _getDynamicGroups() {
    return this.allGroups.filter(x => !x.static).map(x => x.definition);
  }

  private _getHardcodedMetadataObj(item: CatalogFeature) {
    return {
      libraryCodeDataType: item.featureLibraryCodeDataType,
      externalCode: item.externalCode,
      productTypeInstanceCode: item.productTypeInstanceCode
    };
  }

  private _deleteAllDynamicGenGroups() {
    this.allGroups = this.allGroups.filter(x => !x.definition.dynamicSection);
  }
}
