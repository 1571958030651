import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CodeName } from 'src/app/models/common/code-name.model';
import { FormOperationType } from 'src/app/models/operation.models';

@Injectable({
  providedIn: 'root'
})
export class DetailViewHandlerService {
  public readonly mode$: Observable<FormOperationType>;
  public readonly formViewSelected$: Observable<CodeName>;

  private readonly _mode = new BehaviorSubject<FormOperationType>(undefined);
  private readonly _formViewSelected = new BehaviorSubject<CodeName>(undefined);

  constructor() {
    this.mode$ = this._mode.asObservable().pipe(filter(x => x !== undefined));
    this.formViewSelected$ = this._formViewSelected.asObservable().pipe(filter(x => x !== undefined));
  }

  public setMode(mode: FormOperationType) {
    this._mode.next(mode);
  }

  public setFormView(form: CodeName) {
    this._formViewSelected.next(form);
  }

}
