<ng-container *ngFor="let n1 of [1,2]">
  <div class="fields-group pl-fluid-container">
    <div class="row">
      <div class="col-xs-12">
        <div class="title"></div>
      </div>
    </div>

    <div class="row mb-3" *ngFor="let n2 of [1]">
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <div class="fieldlabel"></div>
        <div class="field"></div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <div class="fieldlabel"></div>
        <div class="field"></div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <div class="fieldlabel"></div>
        <div class="field"></div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
        <div class="fieldlabel"></div>
        <div class="field"></div>
      </div>
    </div>

  </div>
</ng-container>