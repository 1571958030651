import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormGroup, Validators, ControlValueAccessor } from '@angular/forms';
import { RuleTab } from './tabbed-rule-creator.models';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';
import { GridInput } from '../grid/grid.model';
import { Subscription } from 'rxjs';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { GridApi, RowDragEvent } from 'ag-grid-community';
import { map, filter } from 'rxjs/operators';
import { ActionButton } from '../action-bar/action-bar.component';
import { ElementResponse, MultiselectConfig, CacheService } from 'ngx-emerios-all';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { EntityCatalogItem } from 'src/app/models/catalog.models';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { KeyValue } from 'src/app/types/keyvalue.type';
import { FieldValueHandlerService, AnyValueEvent } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { ActionBarHelperService } from 'src/app/services/helper/action-bar-helper/action-bar-helper.service';
import { AggridColumnHelperService } from 'src/app/services/helper/aggrid-column-helper/aggrid-column-helper.service';
import { sortBy } from 'src/app/functions/sortBy';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { FormOperationType } from 'src/app/models/operation.models';

export interface ViewModel {
  gridModel: Array<GridInput>;
  loadingGrid: Array<boolean>;
  gridApi: Array<GridApi>;
  selectorTitle: string;
  outcomeTitle: string;
}

export interface InternalModel {
  ruleCode: string,
  ruleName: string;
  ruleTypeCode: string;
  ownerPartyRoleInstanceCode: string;
  chainedFilter: Array<any>;
  gridSelection: Array<{ instanceCode: string, order?: number | '-' }>;
  itemSelection: Array<EntityCatalogItem>;
}

const noOp = () => { };

export const TABBED_RULE_CREATOR_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TabbedRuleCreatorComponent),
  multi: true
};

@Component({
  selector: 'app-tabbed-rule-creator',
  templateUrl: './tabbed-rule-creator.component.html',
  styleUrls: ['./tabbed-rule-creator.component.sass'],
  providers: [TABBED_RULE_CREATOR_CONTROL_VALUE_ACCESSOR]
})
export class TabbedRuleCreatorComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public forceValidation: EventEmitter<any>;
  @Input() public config: FieldDefinition;
  @Input() public detailCode: string;

  public control: FormControl;

  public model = {} as ViewModel;
  public form: FormGroup;
  public actionBarConfig: Array<ActionButton> = [];
  public tabs: Array<RuleTab> = [];
  public internalModel: Array<InternalModel> = [];
  public tabIndexUpdater = new EventEmitter<number>();
  public currentTabIndex: number;

  public multiselectDef: FieldDefinition;
  public multiselectConfig: Array<MultiselectConfig> = [];
  public multiselectItems: Array<any> = [];

  private _gridSource: AgGridEnum;
  private _ownerPartyRoleInstanceCode: any;
  private _viewMode: FormOperationType;
  private _subscriptions: Array<Subscription> = [];
  private _onChange: any;
  private _workEffortTypeCode: string;

  writeValue(obj: Array<InternalModel>): void {
    if (obj) {
      this._prepareInternalModel(obj);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }
  onTouchedCallback: () => void = noOp;

  constructor(
    private _gridHandler: GridHandlerService,
    private _actionBarHelper: ActionBarHelperService,
    private _detailViewHandler: DetailViewHandlerService,
    private _catalog: CatalogService,
    private _elementHelper: ElementHelperService,
    private _aggridColumnHelper: AggridColumnHelperService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _cache: CacheService) {
    this.model.loadingGrid = [];
    this.model.gridModel = [];
    this.model.gridApi = [];
  }

  public setGridApi(gridApi: GridApi, index: number) {
    this.model.gridApi[index] = gridApi;
    // this._recalculateSortIdColumn();
  }

  public isValid(event: ElementResponse) {
    const internalModel = this.internalModel[this.currentTabIndex];
    internalModel.itemSelection = event.value;

    this._validateInternalModel();
  }

  public chainedFilterChanged(event: KeyValue) {
    if (this.config.tabbedRuleCreator.outcome.type !== 'catalog-multiple') {
      return;
    }

    if (this.config.tabbedRuleCreator.outcome.field && this.config.tabbedRuleCreator.outcome.field.triggerValueProperty) {
      const catalog = this.config.tabbedRuleCreator.outcome.field.catalog;
      const value = event && event[this.config.tabbedRuleCreator.outcome.field.triggerValueProperty];

      if (value) {
        this._catalog.getSingleWithFilterFromServer(catalog, value)
          .subscribe(catalog => {
            if (catalog && catalog.items) {
              this.multiselectItems[this.currentTabIndex] = this._catalog.getSimpleCatalogDescriptionObject(catalog.items);
            }
          });
      } else {
        this.multiselectItems[this.currentTabIndex] = [];
      }
    }
  }

  public showActionBar() {
    return this.config && this.config.tabbedRuleCreator.allowAddOrRemoveTabs;
  }

  public showTabs() {
    return this.config && this.config.tabbedRuleCreator.showTabs;
  }

  public renderOutcome(type: 'catalog-multiple' | 'catalog-single' | 'grid') {
    return this.config && this.config.tabbedRuleCreator.outcome.type === type;
  }

  public actionFired(action: string) {
    // nothing yet
  }

  public tabClicked(tabIndex: number) {
    this.currentTabIndex = tabIndex;

    this._initiateOutcome(tabIndex);
  }

  public onRowDragEnd(event: RowDragEvent) {
    this._recalculateSortIdColumn();
  }

  public getGridModel() {
    return this.model.gridModel[this.currentTabIndex];
  }

  public getControlErrors() {
    let errorMessage: string;

    if (this.control.errors && this.control.errors.required) {
      errorMessage = 'This is required';
    }

    return errorMessage;
  }

  ngOnInit() {
    this._subscriptions.push(
      this._detailViewHandler.mode$
        .subscribe(mode => {
          this._viewMode = mode;
          this._configureButtons();
        }));

    this._subscriptions.push(
      this._fieldValueHandler.ownerPartyRoleValue$
        .subscribe(code => this._ownerPartyRoleInstanceCode = code));

    this._subscriptions.push(
      this._fieldValueHandler.anyValue$
        .subscribe(anyValue => this._handleExternalValues(anyValue))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this._createEmptyForm();
      this._createGridSubscription();

      this.model.selectorTitle = this.config.tabbedRuleCreator.selectorTitle;
      this.model.outcomeTitle = this.config.tabbedRuleCreator.outcomeTitle;

      if (this.config.attributes.required) {
        this.control = new FormControl(undefined, Validators.required);
      } else {
        this.control = new FormControl(undefined);
      }

      if (!this.config.value || this.config.value.length === 0) {
        this._addTab(true);
      }
    }

    if (changes.forceValidation && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _handleExternalValues(anyValue: AnyValueEvent) {
    Object.keys(this.config.externalFields).forEach(prop => {

      if (this.config.externalFields['ruleTypeCode']) {
        const ruleTypeCode = anyValue.changes.get(this.config.externalFields['ruleTypeCode'])[0];
        this._workEffortTypeCode = ruleTypeCode.code;
        this.internalModel.forEach(x => x.ruleTypeCode = ruleTypeCode.ruleTypeLibraryCode);
      }
    });
  }

  private _createGridSubscription() {
    if (this.config.tabbedRuleCreator.outcome.type === 'grid') {
      this._subscriptions.push(
        this._gridHandler.getGenericGridDataAndDef$
          .pipe(filter(x => x.source === this._gridSource))
          .subscribe(gridInput => {
            this._createDragAndCheckboxColumns(gridInput);
            this._initializeRowSelectionAndSorting(gridInput);

            this.model.gridModel[this.currentTabIndex] = gridInput;
            this.model.loadingGrid[this.currentTabIndex] = false;

            setTimeout(() => {
              this._orderCurrentRows();
            }, 50);
          }));
    }
  }

  private _createEmptyForm() {
    if (!this.form) {
      this.form = new FormGroup({});

      this._subscriptions.push(this.form.valueChanges
        .subscribe(values => {
          this._updateValues(values);
          this._validateInternalModel();
        }));
    }
  }

  private _initiateOutcome(index: number, model?: InternalModel) {
    if (this.config.tabbedRuleCreator.outcome.type === 'grid') {
      this._initiateGrid();
    } else if (this.config.tabbedRuleCreator.outcome.type === 'catalog-multiple') {
      this._initiateMultiselect(index, model ? model.itemSelection : undefined);
    }
  }

  private _initiateGrid() {
    if (!this.model.gridModel[this.currentTabIndex]) {
      this._gridSource = this.config.tabbedRuleCreator.outcome.grid.source as AgGridEnum;
      this.model.loadingGrid[this.currentTabIndex] = true;
      if (!this._workEffortTypeCode) {
        var instanceCode = this._fieldValueHandler.getDetailCode();
        var indexOf = instanceCode.lastIndexOf('.');
        this._workEffortTypeCode = instanceCode.substring(0, indexOf);
      }
      this._gridHandler.getQuestionRulesGridDataAndDef(this._gridSource, this._workEffortTypeCode, this._ownerPartyRoleInstanceCode);
    }
  }

  private _initiateMultiselect(index: number, items: Array<EntityCatalogItem>) {
    this.multiselectDef = this.config.tabbedRuleCreator.outcome.field;

    if (!this.multiselectConfig[index]) {
      let multiselectConfig = this._elementHelper.getMultiSelectConfig(this.multiselectDef.attributes, []);
      multiselectConfig.readonly = !this.config.attributes.editable;

      if (this.multiselectDef.type === 'catalog-single') {
        multiselectConfig.singleSelect = true;
      }

      if (!this.multiselectDef.filterBy) {
        this._catalog.getFromServer([this.multiselectDef.catalog])
          .pipe(map(x => x[0]))
          .subscribe(catalog => {
            if (catalog && catalog.items) {
              this.multiselectItems[index] = this._catalog.getSimpleCatalogDescriptionObject(catalog.items);;
            }
          });
      }
      this.multiselectConfig.push(multiselectConfig);
      this._createFormControl(index, 'multiselect', items);
    }
  }

  private _recalculateSortIdColumn() {
    const internalModel = this.internalModel[this.currentTabIndex];
    const gridApi = this.model.gridApi[this.currentTabIndex];
    let orderedRows = [];

    let cnt = 1;
    gridApi.forEachNode(node => {
      node.data.rowSelected
        ? node.data.sortId = cnt++
        : node.data.sortId = '-';

      orderedRows.push(node.data);

      const selection = internalModel.gridSelection.find(x => x.instanceCode === node.data.instanceCode);
      if (selection) {
        selection.order = node.data.sortId;
      }
    });

    orderedRows = sortBy(orderedRows, [
      { prop: 'rowSelected', direction: 'desc' },
      { prop: 'sortId', direction: 'asc' }
    ]);

    gridApi.setRowData(orderedRows);

    this.model.gridModel[this.currentTabIndex].rows = orderedRows;
  }

  private _orderCurrentRows() {
    const gridApi = this.model.gridApi[this.currentTabIndex];
    const gridModel = this.model.gridModel[this.currentTabIndex];

    if (gridModel && gridApi) {
      let orderedRows = sortBy(gridModel.rows, [
        { prop: 'rowSelected', direction: 'desc' },
        { prop: 'sortId', direction: 'asc' }
      ]);

      orderedRows.forEach(x => {
        if (!x.rowSelected) {
          x.sortId = '-';
        }
      });

      gridApi.setRowData(orderedRows);
    }
  }

  private _configureButtons() {
    const buttons = this._actionBarHelper.getTabbedRuleComponentButtons();
    const newBtn = buttons.find(x => x.code === 'new');
    const removeBtn = buttons.find(x => x.code === 'remove');

    newBtn.enabled = this._viewMode !== FormOperationType.View;
    newBtn.callback = () => { this._addTab() };

    removeBtn.enabled = this._viewMode !== FormOperationType.View;
    removeBtn.callback = () => { this._removeCurrentTab() };

    this.actionBarConfig = buttons;
  }

  private _enableRowDrag(instanceCode: string) {
    const internalModel = this.internalModel[this.currentTabIndex];

    if (this._viewMode !== FormOperationType.View) {
      return internalModel && internalModel.gridSelection.find(x => x.instanceCode === instanceCode);
    } else {
      return false;
    }
  }

  private _simulateCheckboxClick(params: any) {
    params.event.srcElement.children[0].click();
  }

  private _createRowCheckbox(params: any) {
    const input = document.createElement('input');

    input.type = "checkbox";
    input.checked = params.value;
    input.disabled = this._viewMode === FormOperationType.View;

    input.addEventListener('click', (event) => {
      const internalModel = this.internalModel[this.currentTabIndex];
      const instanceCode = params.node.data.instanceCode;

      params.value = (event.srcElement as any).checked;
      params.node.data.rowSelected = params.value;

      params.value
        ? this._addGridSelection(internalModel, instanceCode)
        : this._removeGridSelection(internalModel, instanceCode);

      this._recalculateSortIdColumn();
    });

    return input;
  }

  private _createDragAndCheckboxColumns(gridInput: GridInput) {
    const columns = this._aggridColumnHelper
      .getTabbedRuleCreatorCustomColumns(
        (params: any) => this._enableRowDrag(params.node.data.instanceCode),
        (params: any) => this._simulateCheckboxClick(params),
        (params: any) => this._createRowCheckbox(params)
      );

    gridInput.columns.splice(0, 0, ...columns);
  }

  private _initializeRowSelectionAndSorting(gridInput: GridInput) {
    gridInput.rows
      .forEach(row => {
        const internalModel = this.internalModel[this.currentTabIndex];
        const rowSelected = internalModel.gridSelection
          .find(x => x.instanceCode === row.instanceCode);

        row.rowSelected = rowSelected !== undefined;
        row.sortId = (rowSelected && rowSelected.order) || -1;
      });
  }

  private _addTab(onlyIfEmpty: boolean = false) {
    if (onlyIfEmpty && this.tabs.length > 0) {
      return;
    }

    const tab = this._createTab();

    this.internalModel.push({ ruleName: tab.title, gridSelection: [] } as InternalModel);
    this.currentTabIndex = this.tabs.length - 1;

    this._createFormControl(this.currentTabIndex, 'chainedfilter');
    this._initiateOutcome(this.currentTabIndex);
    this._validateInternalModel();
  }

  private _preloadTab(index: number, model: InternalModel) {
    const tab = this._createTab();
    this.internalModel.push(model);

    this._createFormControl(index, 'chainedfilter', model.chainedFilter);

    return tab;
  }

  private _removeCurrentTab() {
    if (this.currentTabIndex >= 0) {
      const tabCode = this.tabs[this.currentTabIndex].code;

      this.form.removeControl(`chainedfilter-${tabCode}`);
      this.form.removeControl(`multiselect-${tabCode}`);

      this.internalModel.splice(this.currentTabIndex, 1);
      this.model.gridModel.splice(this.currentTabIndex, 1);
      this.model.loadingGrid.splice(this.currentTabIndex, 1);
    }

    this.tabs.splice(this.currentTabIndex, 1);

    if (this.currentTabIndex > 0) {
      this.currentTabIndex--;
    }
    this._validateInternalModel();
  }

  private _createTab(title?: string) {
    const tabCount = this.tabs.length;
    const tabTplName = title || `${this.config.tabbedRuleCreator.tabTemplateName} ${tabCount + 1}`;
    const fieldDef = {
      field: this.config.field,
      attributes: { editable: this.config.attributes.editable },
      chainedFilterDefinition: this.config.tabbedRuleCreator.chainedFilterDefinition
    } as FieldDefinition;

    const tab = {
      code: this._createTabCode(),
      title: tabTplName,
      chainerFilterConfig: fieldDef,
      gridConfig: [{ grid: this.config.tabbedRuleCreator.outcome.grid }]
    } as RuleTab;

    this.tabs.push(tab);

    return tab;
  }

  private _createFormControl(index: number, type: string, value?: any) {
    const tab = this.tabs[index];
    const controlName = `${type}-${tab.code}`;
    this.form.addControl(controlName, new FormControl(value, Validators.required));
  }

  private _updateValues(values: any) {
    Object.keys(values).forEach(prop => {
      const type = prop.split('-')[0];
      const tabCode = prop.split(`${type}-`)[1];

      const index = this.tabs.findIndex(x => x.code === tabCode);
      const value = values[prop];

      if (type === 'chainedfilter') {
        this.internalModel[index].chainedFilter = value;
      } else if (type === 'multiselect') {
        this.internalModel[index].itemSelection = value;
      }
    });
  }

  private _addGridSelection(internalModel: InternalModel, instanceCode: string) {
    if (!internalModel.gridSelection.find(x => x.instanceCode === instanceCode)) {
      internalModel.gridSelection.push({ instanceCode });
      this._validateInternalModel();
    }
  }

  private _removeGridSelection(internalModel: InternalModel, instanceCode: string) {
    const indexOf = internalModel.gridSelection.findIndex(x => x.instanceCode === instanceCode);

    if (indexOf > -1) {
      internalModel.gridSelection.splice(indexOf, 1);
      this._validateInternalModel();
    }
  }

  private _validateInternalModel() {
    let isValid = this.internalModel.length > 0;

    if (isValid) {
      this.internalModel.forEach(x => {
        // use configuration for this?
        // isValid = isValid && (x.chainedFilter !== undefined);

        if (this.config.tabbedRuleCreator.outcome.type === 'grid') {
          isValid = isValid && x.gridSelection && x.gridSelection.length > 0;
        } else if (this.config.tabbedRuleCreator.outcome.type === 'catalog-multiple') {
          isValid = isValid && x.itemSelection && x.itemSelection.length > 0;
        }
      });
    }

    this._updateInternalModel(isValid);
  }

  private _updateInternalModel(valid: boolean) {
    if (this._onChange) {
      this.internalModel.forEach(x => x.ownerPartyRoleInstanceCode = this._ownerPartyRoleInstanceCode);
      this.control.setValue(valid ? this.internalModel : undefined);
      this._onChange(this.control.value);
    }
  }

  private _prepareInternalModel(obj: Array<InternalModel>) {
    if (obj) {
      this.currentTabIndex = 0;
      obj.forEach((x, index) => {
        this._preloadTab(index, x);
        this._initiateOutcome(index, x);
      });
    } else {
      this.internalModel = [];
      this._addTab(true);
    }
  }

  private _createTabCode() {
    return `${this.config.attributes.name}-${this.tabs.length}`;
  }
}
