import { Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-textarea-expander',
  templateUrl: './textarea-expander.component.html',
  styleUrls: ['./textarea-expander.component.sass']
})
export class TextareaExpanderComponent implements OnInit {
  private _control: AbstractControl;

  constructor() { }

  public showExpander() {
    let text: string = this._getText();

    return text && text.length > 50;
  }

  public expandClicked() {
    let text: string = this._getText();

    alert(text);
  }

  public setExternalControl(control: AbstractControl) {
    this._control = control;
  }

  ngOnInit() { }

  private _getText() {
    let text: string = '';

    if (this._control.value && typeof (this._control.value) === 'object') {
      Object.keys(this._control.value)
        .forEach(prop => {
          text += this._control.value[prop] + '\n\n';
        });
    } else {
      text = this._control.value;
    }

    return text;
  }

}
