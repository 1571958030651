import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import * as i0 from "@angular/core";
export class AuthorizationHelperService {
    constructor() { }
    isAutorized(resource, operation) {
        if (this.isAuthenticated()) {
            return this._getPermissions()
                .find(x => x.resource == resource && x.operation == operation) != null;
        }
        else {
            return false;
        }
    }
    hasAnyPermission() {
        return this._getPermissions().length > 0;
    }
    isAuthenticated() {
        return this._getToken() != null;
    }
    _getPermissions() {
        return JSON.parse(sessionStorage.getItem(CacheKeyEnum.Permissions));
    }
    _getToken() {
        return sessionStorage.getItem(CacheKeyEnum.AccessToken);
    }
}
AuthorizationHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationHelperService_Factory() { return new AuthorizationHelperService(); }, token: AuthorizationHelperService, providedIn: "root" });
