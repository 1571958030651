import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GhostLoadingComponent } from './ghost-loading.component';
import { GhostLoadingAggridComponent } from './_ghost-loading-aggrid/ghost-loading-aggrid.component';
import { GhostLoadingDashboardComponent } from './_ghost-loading-dashboard/ghost-loading-dashboard.component';
import { GhostLoadingDynamicFormComponent } from './_ghost-loading-dynamic-form/ghost-loading-dynamic-form.component';
import { GhostLoadingFieldComponent } from './_ghost-loading-field/ghost-loading-field.component';

@NgModule({
  declarations: [
    GhostLoadingComponent,
    GhostLoadingAggridComponent,
    GhostLoadingDashboardComponent,
    GhostLoadingDynamicFormComponent,
    GhostLoadingFieldComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GhostLoadingComponent
  ]
})
export class GhostLoadingModule { }
