import { HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { finalize, catchError, tap, delay } from 'rxjs/operators';
import { PageLoaderService } from 'ngx-emerios-all';
import { MessageService } from '../services/behavior/message/message.service';
import { RestCacheService } from '../services/rest-cache/rest-cache.service';
import { WaitingLoaderService } from '../services/behavior/waiting-loader/waiting-loader.service';
import { AuthorizationHandlerService } from '../services/handler/authorization-handler/authorization-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/handler/authorization-handler/authorization-handler.service";
import * as i2 from "ngx-emerios-all";
import * as i3 from "../services/behavior/waiting-loader/waiting-loader.service";
import * as i4 from "../services/rest-cache/rest-cache.service";
import * as i5 from "../services/behavior/message/message.service";
export class GlobalInterceptor {
    constructor(_authHandler, _pageLoader, _waitingLoader, _restCache, _message) {
        this._authHandler = _authHandler;
        this._pageLoader = _pageLoader;
        this._waitingLoader = _waitingLoader;
        this._restCache = _restCache;
        this._message = _message;
    }
    intercept(request, next) {
        const hideRouter = request.headers.get('X-META-DATA-HIDE-ROUTER') === 'true';
        const showLoader = request.headers.get('showFullPageLoading') === 'true';
        const silentError = request.headers.get('silentError') === 'true';
        const useRestCache = request.headers.get('restCache') === 'true';
        const workingOrganization = this._authHandler.getWorkingOrganization();
        if (workingOrganization) {
            request = request.clone({
                setHeaders: {
                    PRIC: workingOrganization.partyRoleInstanceCode,
                    PRID: workingOrganization.partyRoleId,
                    PIC: workingOrganization.partyInstanceCode,
                    PPPRID: workingOrganization.platformProviderPartyRoleId,
                    PPPRIC: workingOrganization.platformProviderPartyRoleInstanceCode
                }
            });
        }
        if (showLoader) {
            hideRouter ? this._pageLoader.showHidingRouter() : this._pageLoader.show();
        }
        if (useRestCache) {
            const body = this._restCache.getFromCache(request.urlWithParams);
            if (body) {
                return of(new HttpResponse({ status: 200, body: body })).pipe(delay(100));
            }
        }
        return next.handle(request)
            .pipe(tap((event) => {
            if (event instanceof HttpResponse) {
                useRestCache ? this._restCache.setOrUpdateResponse(event) : undefined;
            }
        }), catchError((response) => {
            if (response.status === 500 || response.status === 0) {
                this._handle500Error(response, silentError);
            }
            throw response;
        }), finalize(() => {
            if (showLoader) {
                this._waitingLoader.hide();
            }
        }));
    }
    _handle500Error(response, avoidPageRedirect) {
        this._waitingLoader.hide();
        this._message.handle(response.error, avoidPageRedirect);
    }
}
GlobalInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalInterceptor_Factory() { return new GlobalInterceptor(i0.ɵɵinject(i1.AuthorizationHandlerService), i0.ɵɵinject(i2.PageLoaderService), i0.ɵɵinject(i3.WaitingLoaderService), i0.ɵɵinject(i4.RestCacheService), i0.ɵɵinject(i5.MessageService)); }, token: GlobalInterceptor, providedIn: "root" });
export let GlobalInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: GlobalInterceptor,
    multi: true
};
