import { BaseDataModel } from '../base-data-model.model';
import { RuleModel } from 'src/app/models/rule.model';
import { FieldDefinition, RulesGridDefinition } from 'src/app/models/form-field-definition.models';

export class RulesGridDataModelService implements BaseDataModel {

  private readonly _ruleTypeLibraryCode;
  private _fieldDefinition: RulesGridDefinition;

  constructor(fieldDefinition: FieldDefinition) {
    this._fieldDefinition = fieldDefinition as RulesGridDefinition;
    this._ruleTypeLibraryCode = this._fieldDefinition.ruleTypeLibraryCode;
  }

  public setValueToField(payloadValue: Array<any>) {
    const value = {
      rules: {}
    };

    const workingRules = payloadValue.filter(x => x.ruleTypeLibraryCode === this._ruleTypeLibraryCode);

    workingRules.forEach(rule => {
      const ruleModel = new RuleModel();

      ruleModel.factors = [];
      (rule.factors as Array<any>).forEach(factor => {
        ruleModel.factors.push({
          factorTypeLibraryCode: factor.factorTypeLibraryCode,
          ruleTypeLibraryCode: factor.ruleTypeLibraryCode,
          valueInstanceCode: factor.valueInstanceCode,
          value: factor.value
        });
      });

      value.rules[ruleModel.getUniqueId()] = ruleModel;
      ruleModel.outcomeWorkEfforts = rule.outcomeWorkEfforts && rule.outcomeWorkEfforts.map((x: any) => {
        return { workEffortInstanceCode: x.workEffortInstanceCode };
      });
    });

    if (Object.keys(value.rules).length === 0) {
      return undefined;
    }

    return value;
  }

  public getValueFromField(fieldValue: any) {
    const rules: Array<RuleModel> = [];

    Object.keys(fieldValue && fieldValue.rules || []).forEach(prop => {
      const ruleObj = new RuleModel();

      ruleObj.instanceCode = fieldValue.instanceCode;
      ruleObj.instanceName = `${fieldValue.detailCode} - Rule Entry`;
      ruleObj.instanceDescription = `${fieldValue.detailCode} - Rule Entry`;
      ruleObj.ownerPartyRoleInstanceCode = fieldValue.ownerPartyRoleInstanceCode;
      ruleObj.ruleTypeLibraryCode = this._ruleTypeLibraryCode;
      ruleObj.factors = [];
      ruleObj.outcomeWorkEfforts = [];

      (fieldValue.rules[prop].factors as Array<any>).forEach(factor => {
        factor.ruleTypeLibraryCode = this._ruleTypeLibraryCode;

        ruleObj.factors.push(factor);

        ruleObj.outcomeWorkEfforts = fieldValue.rules[prop].outcomeWorkEfforts;
      });

      rules.push(ruleObj);
    });

    return rules;
  }
}
