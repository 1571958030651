import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, OnDestroy } from '@angular/core';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ElementResponse } from 'ngx-emerios-all';
import { GridInput } from '../grid/grid.model';
import { interval, Subscription } from 'rxjs';
import { FileData } from '../file-upload/file-upload.component';
import { ActivatedRoute } from '@angular/router';
import { KeyValue } from 'src/app/types/keyvalue.type';
import { FileHandlerService } from 'src/app/services/handler/file-handler/file-handler.service';
import { MessageModalParams, MessageModalComponent } from 'src/app/modals/message-modal/message-modal.component';
import { ModalParamButton, ModalService } from 'src/app/services/behavior/modal/modal.service';
import { WaitingLoaderService, LoadingBarTarget } from 'src/app/services/behavior/waiting-loader/waiting-loader.service';
import { RowEvent } from 'ag-grid-community';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { finalize, flatMap } from 'rxjs/operators';
import { capitalizeFirstLetter } from 'src/app/functions/capitalize';
import { JobExecutionDto, JobExtendedStatusItem, JobName, JobStatus } from 'src/app/models/background-task.model';
import { BackgroundTaskRestService } from 'src/app/services/rest/background-task-rest/background-task-rest.service';
import { Entity } from 'src/app/models/entity.model';
import { UtilService } from 'src/app/services/behavior/util/util.service';
import { JourneyStep } from '../../models/journey.models';
import { JourneyHandlerService } from '../../services/handler/journey-handler/journey-handler.service';

// all this wasnt me
@Component({
  selector: 'app-upload-file-processing',
  templateUrl: './upload-file-processing.component.html',
  styleUrls: ['./upload-file-processing.component.sass']
})
export class UploadFileProcessingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public config: FieldDefinition;
  @Input() public forceValidation: boolean;

  @Output() public change: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;

  public showErrorsPanel: boolean;
  public fileErrorList: Array<any>;
  public errorMessage: any;
  public uploadControlName: string;
  public previewGridDataModel: GridInput[]
  public externalFilter: { [x: string]: (key: any) => boolean; };
  public fileUploadedName: string;

  private _subscriptions: Array<Subscription> = [];
  private _getStatusSubscription: Subscription;
  private _instanceCode: string;
  private _currentDashboardItem: DashboardItem;
  private _currentJourneyStep: JourneyStep;

  constructor(
    private _fileHandler: FileHandlerService,
    private _route: ActivatedRoute,
    private _modal: ModalService,
    private _waitingLoader: WaitingLoaderService,
    private _dashboardHandler: DashboardHandlerService,
    private _backgroundTaskRest: BackgroundTaskRestService,
    private _utils: UtilService,
    private _journeyHandler: JourneyHandlerService) { }

  public isElementValid(event: ElementResponse) {
    this.change.emit({ name: event.name, value: event.value });
  }

  public fileEvent(data: KeyValue) {
    if (data) {
      const event = Object.keys(data)[0];
      switch (event) {
        case 'file-dropped':
          this._handleFileDroppedEvent(data[event]);
          break;
        case 'file-removed':
          this._handleFileRemovedEvent();
          break;
        default:
          break;
      }
    }
  }

  public confirmClicked() {
    const payload = {
      bookInstanceCode: this._instanceCode,
      journeyStepFormInstanceCode: this._currentJourneyStep && this._currentJourneyStep.code
    };

    this._waitingLoader.showFullScreenLoading();

    this._fileHandler.processBook(payload)
      .subscribe(result => {
        const data: JobExecutionDto = this._utils.ObjectKeysToPascalCase(result);
        this._initialSubscription(data.JobExecutionUid);
        this._configureIntervalForProcessBookStatus(data.JobExecutionUid);
      });
  }

  public declineClicked() {
    window.history.back();
  }

  public selectionChanged(event: RowEvent) {
    const filtrable = ['geography-book', 'container-book', 'campaign-book', 'flow-book', 'organization-book', 'product-type-book'];

    if (!filtrable.includes(this._currentDashboardItem.viewCode)) {
      return;
    }

    const gridApi = event.api;
    const selectedNode = gridApi.getSelectedNodes()[0];

    this._updateFilter(selectedNode.data);
  }

  ngOnInit() {
    this._subscriptions.push(this._route.queryParams
      .subscribe(params => {
        this._instanceCode = params.instanceCode;
      }));

    this._subscriptions.push(
      this._dashboardHandler.currentDashboardItem$
        .subscribe(item => {
          this._currentDashboardItem = item;
        }));

    this._subscriptions.push(
      this._journeyHandler.currentJourneyStep$
        .subscribe(currentJourneyStep => this._currentJourneyStep = currentJourneyStep)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this.uploadControlName = this.config.attributes.name;
      this._initializeFormGroup();
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _initializeFormGroup() {
    this.form = new FormGroup({});
    this.form.addControl(this.uploadControlName, new FormControl(null, Validators.required));
  }

  private _updateFilter(data: any) {
    this.externalFilter = undefined;
    this.externalFilter = {
      'geo_Code': (param: string) => {
        return param === data.geo_Code;
      },
      'rul_Container_Code': (param: string) => {
        return param === data.cnt_Code;
      },
      'rul_flow_code': (param: string) => {
        return param === data.code;
      }
    };
  }

  private _handleFileDroppedEvent(data: Array<FileData>) {
    data.forEach(obj => {
      const payload = {
        bookInstanceCode: this._instanceCode,
        fileName: obj.fileName,
        base64File: obj.base64
      };

      /**
       * Currently we only are able to upload one file
       * Otherwise, this property contains the value of the last uploaded file.
       */
      this.fileUploadedName = obj.fileName;
      this._waitingLoader.showLoadingBar(LoadingBarTarget.DetailView, true);

      this._fileHandler.uploadBook(payload)
        .pipe(finalize(() => this._waitingLoader.hide()))
        .subscribe(() => {
          if (this._instanceCode) {
            this._fileHandler.downloadBookPreview(this._instanceCode, this._currentJourneyStep && this._currentJourneyStep.code)
              .subscribe(result => {
                this.previewGridDataModel = [];
                if (result[0]) {
                  for (let i = 0; i < result.length; i++) {
                    const currentGrid = result[i];
                    this._capitalizeFirstLetterInColumnDefinition(currentGrid);
                    this.previewGridDataModel.push(currentGrid);
                  }
                } else if (result) {
                  this._capitalizeFirstLetterInColumnDefinition(result);
                  this.previewGridDataModel.push(result);
                }
              });
          }
        });
    });
  }

  private _capitalizeFirstLetterInColumnDefinition(result: any) {
    result.metadata.columnDefinition
      .forEach(col => col.children
        .forEach(c => c.field = capitalizeFirstLetter(c.field)));
  }

  private _handleFileRemovedEvent() {
    this.previewGridDataModel = undefined;
    this.showErrorsPanel = false;
  }

  private _initialSubscription(jobExecutionUid: string) {
    this._subscriptions.push(
      this._backgroundTaskRest.getStatus(jobExecutionUid)
        .subscribe((result: [Entity, Array<JobExtendedStatusItem>]) => {
          this._handleGetStatusCallback(result);
        })
    );
  }

  private _configureIntervalForProcessBookStatus(jobExecutionUid: string) {
    this._getStatusSubscription = interval(1 * 10 * 1000)
      .pipe(flatMap(() => this._backgroundTaskRest.getStatus(jobExecutionUid)))
      .subscribe((result: [Entity, Array<JobExtendedStatusItem>]) => {
        this._handleGetStatusCallback(result);
      });

    this._subscriptions.push(this._getStatusSubscription);
  }

  private _handleGetStatusCallback(result: [Entity, JobExtendedStatusItem[]]) {
    const data: Entity = this._utils.ObjectKeysToCamelCase(result[0]);
    if (data.status !== JobStatus.InProgress) {
      this._waitingLoader.hideFullScreenLoading();
      this._getStatusSubscription.unsubscribe();
      this._handleProcessBookResult(result[0]);
    }
  }

  private _handleProcessBookResult(data: any) {
    let messageType: string;
    let messageText: string;
    let messageButton = {} as ModalParamButton;
    this.fileErrorList = null;
    this.errorMessage = null;
    const parsedResult = JSON.parse(data.Result);
    const hasResult = parsedResult && parsedResult.length > 0;

    if (data.Status === JobStatus.Failed || hasResult || data.ErrorMessage) {
      if (parsedResult[0].NoActionPerformed) {
        messageType = 'warning';
        messageText = `<p>${parsedResult[0].Messages[0].Text}</p>`;
        messageButton = {
          text: 'Close'
        };
        this.showErrorsPanel = false;
        this._fileHandler.setFileUploadedName(this.fileUploadedName);
      } else {
        messageType = 'error';
        messageText = '<p>The import process had Errors<p>Please check the errors and upload the file again.</p>';
        messageButton = {
          text: 'Close'
        };
        if (hasResult) {
          this.fileErrorList = parsedResult;
        }
        if (data.ErrorMessage) {
          const parsedErrorMessage = JSON.parse(data.ErrorMessage);
          this.errorMessage = parsedErrorMessage ? parsedErrorMessage.ErrorMessage : data.ErrorMessage;
        }

        this.showErrorsPanel = true;
      }
    } else {
      messageType = 'success';
      messageText = '<p>The import process was</p><p>Successful</p>';
      messageButton = {
        text: `Back to ${this._currentDashboardItem.name}`,
        callback: this.declineClicked
      };
      this.showErrorsPanel = false;
      this._fileHandler.setFileUploadedName(this.fileUploadedName);
    }

    const params = {
      title: `Confirm Upload ${this._currentDashboardItem.name}`,
      type: messageType,
      message: messageText,
      okButton: messageButton
    } as MessageModalParams;

    this._modal.showCustom(MessageModalComponent, params);
  }
}
