import { Injectable } from '@angular/core';
import { ModalService } from '../../behavior/modal/modal.service';
import { DiscardChangesModalComponent } from 'src/app/modals/discard-changes-modal/discard-changes-modal.component';
import { MessageModalParams } from 'src/app/modals/message-modal/message-modal.component';
import { SignatureModalComponent } from 'src/app/modals/signature-modal/signature-modal.component';

export interface ConfirmModel {
  title: string;
  confirmText: string;
  message: string;
  cancelText?: string;
  callback: Function;
  cancelCallback?: Function;
}

export interface MessageModel {
  title: string;
  buttonText: string;
  message: string;
  callback: Function;
}

@Injectable({
  providedIn: 'root'
})
export class ModalHelperService {

  constructor(private _modal: ModalService) { }

  public confirmSubmitRequest(callback: Function) {
    this._modal.showCustom(SignatureModalComponent ,{
      title: 'Submit',
      confirmText: 'Submit',
      message: 'You are going to <strong>submit</strong> the current request. Continue?',
      callback: callback
    });
  }

  public confirmCancelRequest(callback: Function) {
    this._showConfirm({
      title: 'Cancel',
      confirmText: 'Yes',
      message: 'You are going to <strong>cancel</strong> the current request. Continue?',
      callback: callback
    });
  }

  public confirmApproveRequest(callback: Function) {
    this._modal.showCustom(SignatureModalComponent ,{
      title: 'Approve',
      confirmText: 'Approve',
      message: 'You are going to <strong>approve</strong> the current request. Continue?',
      callback: callback
    });
  }

  public confirmRejectRequest(callback: Function) {
    this._showConfirm({
      title: 'Reject',
      confirmText: 'Reject',
      message: 'You are going to <strong>reject</strong> the current request. Continue?',
      callback: callback
    });
  }

  public confirmDeployRequest(callback: Function) {
    this._showConfirm({
      title: 'Deploy',
      confirmText: 'Deploy',
      message: 'You are going to <strong>deploy</strong> the current request. Continue?',
      callback: callback
    });
  }

  public confirmRemoveStageStep(callback: Function) {
    this._showConfirm({
      title: 'Remove Selection',
      confirmText: 'Remove',
      message: 'You are going to remove the selected relationship. This action cannot be undone. Are you sure?',
      callback: callback
    });
  }

  public confirmUnlink(callback: Function) {
    this._showConfirm({
      title: 'Unlink',
      confirmText: 'Unlink',
      message: 'You are going to <strong>unlink</strong> the current entity. Continue?',
      callback: callback
    });
  }

  public confirmDiscardChanges(callback: Function) {
    this._showConfirm({
      title: 'Discard Changes',
      confirmText: 'Discard',
      message: 'You are going to <strong>Discard Changes</strong> of the current entity. Continue?',
      callback: callback
    });
  }

  public confirmDiscardRequest(callback: Function) {
    this._showConfirm({
      title: 'Discard Request',
      confirmText: 'Discard',
      message: 'You are going to <strong>Discard Changes</strong> of the current Request. Continue?',
      callback: callback
    });
  }

  public confirmDiscardChangesDeployed(callback: Function) {
    this._showConfirm({
      title: 'Discard Changes',
      confirmText: 'Discard',
      message: 'You are going to <strong>Discard Changes</strong> of the current entity and <strong>Recover</strong> the last deployed state of the Entity. Continue?',
      callback: callback
    });
  }

  public showSavedSignatureMessage() {
    this._showMessage({
      title: 'Signature',
      buttonText: 'Close',
      message: 'The Signature has been saved correctly',
      callback: null
    });
  }

  private _showConfirm(params: ConfirmModel) {
    const okButton = {
      text: params.confirmText,
      callback: params.callback
    };

    const cancelButton = {
      text: params.cancelText ? params.cancelText : 'Cancel',
      callback: params.cancelCallback
    };

    const initialState = {
      title: params.title,
      message: params.message,
      okButton: okButton,
      cancelButton: cancelButton
    };

    this._modal.showGeneric(initialState);
  }

  private _showMessage(params: MessageModel) {
    const closeButton = {
      text: params.buttonText,
      callback: params.callback
    };

  const initialState = {
      title: params.title,
      message: params.message,
      okButton: closeButton
    };

    this._modal.showGeneric(initialState);
  }

}
