import { CatalogRestService } from '../../rest/catalog-rest/catalog-rest.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../rest/catalog-rest/catalog-rest.service";
export class CatalogService {
    constructor(_catalogRest) {
        this._catalogRest = _catalogRest;
    }
    set(catalogs) {
        this._catalogList = catalogs;
    }
    get(code, untouched) {
        if (code.startsWith('native')) {
            return this.getNativeCatalog(code);
        }
        else {
            const catalog = this._catalogList.find(f => f.code == code);
            if (catalog && catalog.items) {
                const items = untouched ? catalog.items : this._applyCustomFilter(catalog);
                return items;
            }
            return [];
        }
    }
    getNativeCatalog(code) {
        let items = [];
        switch (code) {
            case 'native_yesno':
                items.push({ code: 'true', description: 'Yes' });
                items.push({ code: 'false', description: 'No' });
                break;
            case 'native_truefalse':
                items.push({ code: 'true', description: 'True' });
                items.push({ code: 'false', description: 'False' });
                break;
            case 'native_gender':
                items.push({ code: 'M', description: 'Male' });
                items.push({ code: 'F', description: 'Female' });
                break;
            case 'native_library_visibility':
                items.push({ code: 'public', description: 'Public' });
                items.push({ code: 'emerios_only', description: 'Platform Provider Only' });
                break;
            case 'native_form_operation_type':
                items.push({ code: 'CREATE', description: 'Create' });
                items.push({ code: 'VIEW', description: 'View' });
                items.push({ code: 'EDIT', description: 'Edit' });
                // Approve = 'APPROVE'
                break;
            case 'native_question_part_type':
                items.push({ code: 'AUDIO', description: 'Audio' });
                items.push({ code: 'TEXT', description: 'Text' });
                break;
            case 'native_promote_operation':
                // Commented out because Update option is always the one selected in promote operations (probabliy no longer available)
                // items.push({ code: 'CREATE', description: 'Create' } as EntityCatalogItem);
                items.push({ code: 'UPDATE', description: 'Update' });
                break;
        }
        return items;
    }
    getCatalogsForDetail(sourceCode) {
        return this._catalogRest.getCatalogsForDetail(sourceCode);
    }
    getFromServer(codes) {
        return this._catalogRest.getCatalogs(codes);
    }
    getSingleWithFilterFromServer(code, filterBy) {
        return this._catalogRest.getCatalog(code, filterBy);
    }
    getFiltrableCatalog(catalog, filterBy = ['']) {
        const arr = Array.isArray(filterBy) ? filterBy : [filterBy];
        return this._catalogRest.getFiltrableCatalog(catalog, arr);
    }
    getFullCatalog(code) {
        return this.getFullCatalogs([code])
            .pipe(map(catalogs => catalogs && catalogs[0]));
    }
    getFullCatalogs(codes) {
        return this._catalogRest.getFullCatalogs(codes);
    }
    getSimpleCatalogDescriptionObject(items) {
        let newObjectItems = items.map(item => {
            let desc = item.description;
            if (item.descriptions) {
                desc = item.descriptions[0].text;
            }
            const newItem = Object.assign({
                code: item.code,
                description: desc,
            }, item);
            return newItem;
        });
        return newObjectItems;
    }
    _applyCustomFilter(catalog) {
        return catalog.items;
    }
}
CatalogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatalogService_Factory() { return new CatalogService(i0.ɵɵinject(i1.CatalogRestService)); }, token: CatalogService, providedIn: "root" });
