import { Injectable } from '@angular/core';
import { GeographyRestService } from '../../rest/geography-rest/geography-rest.service';
import { KeyValue } from 'src/app/types/keyvalue.type';
import { PageLoaderService } from 'ngx-emerios-all';
import { finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { RuleRestService } from '../../rest/rule-rest/rule-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ChainedFilterService {

  constructor(
    private _geographyRest: GeographyRestService,
    private _ruleRest: RuleRestService,
    private _pageLoader: PageLoaderService
  ) { }

  public getAllItemsByType(code: string, type: string) {
    if (code === 'geography_grouping') {
      // return this._geographyRest.getGeographyByType(type, criteria);
    } else if (['campaign_filter', 'tpv_step_filter'].includes(code)) {
      return this._ruleRest.getAvailabilitySelectorByType(type, '', 0);
    }

    throw new Error(`Chained Filter: code ${code} not implemented`);
  }

  public searchItemsByType(code: string, type: string, criteria: string) {
    if (code === 'geography_grouping') {
      return this._geographyRest.getGeographyByType(type, criteria);
    } else if (['campaign_filter', 'tpv_step_filter'].includes(code)) {
      return this._ruleRest.getAvailabilitySelectorByType(type, criteria);
    }
    throw new Error(`Chained Filter: code ${code} not implemented`);
  }

  public getItemQuantity(code: string, codes: Array<string>) {
    if (code === 'geography_grouping') {
      return this._geographyRest.getZipcodesCount(codes);
    }

    return of([777]);
  }

  public getFilteredItems(code: string, selectedItems: KeyValue) {
    this._pageLoader.show();
    if (code === 'geography_grouping') {
      let codes = [];
      Object.keys(selectedItems).forEach(prop => {
        if (selectedItems[prop]) {
          codes.push(...selectedItems[prop]);
        }
      });

      return this._geographyRest.getZipcodes(codes)
        .pipe(finalize(() => this._pageLoader.hide()));
    }
  }
}
