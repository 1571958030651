import { PageLoaderService } from 'ngx-emerios-all';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export var LoadingBarTarget;
(function (LoadingBarTarget) {
    LoadingBarTarget[LoadingBarTarget["Login"] = 0] = "Login";
    LoadingBarTarget[LoadingBarTarget["Dashboard"] = 1] = "Dashboard";
    LoadingBarTarget[LoadingBarTarget["TenantSelector"] = 2] = "TenantSelector";
    LoadingBarTarget[LoadingBarTarget["DetailView"] = 3] = "DetailView";
    LoadingBarTarget[LoadingBarTarget["BackgroundTask"] = 4] = "BackgroundTask";
})(LoadingBarTarget || (LoadingBarTarget = {}));
export class WaitingLoaderService {
    constructor(_pageLoader) {
        this._pageLoader = _pageLoader;
        this._loadingBar = new BehaviorSubject(undefined);
        this._loadingBarMaxWaitingTime = 2500;
        this.loadingBar$ = this._loadingBar.asObservable().pipe(filter(x => x != undefined));
    }
    showLoadingBar(target, fallbackToFullscreen) {
        this._clearTimeout();
        this._loadingBar.next({ target: target, show: true });
        if (fallbackToFullscreen) {
            this._timeoutHandler = setTimeout(() => {
                this._pageLoader.show();
            }, this._loadingBarMaxWaitingTime);
        }
    }
    hideLoadingBar() {
        this._clearTimeout();
        this._loadingBar.next(false);
    }
    showFullScreenLoading() {
        this._pageLoader.show();
    }
    hideFullScreenLoading() {
        this._clearTimeout();
        this._pageLoader.hide();
    }
    hide() {
        this._clearTimeout();
        this._loadingBar.next(false);
        this._pageLoader.hide();
    }
    _clearTimeout() {
        if (this._timeoutHandler) {
            clearTimeout(this._timeoutHandler);
        }
    }
}
WaitingLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WaitingLoaderService_Factory() { return new WaitingLoaderService(i0.ɵɵinject(i1.PageLoaderService)); }, token: WaitingLoaderService, providedIn: "root" });
