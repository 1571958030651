import { Router } from '@angular/router';
import { UtilService } from '../../behavior/util/util.service';
import { CacheService } from 'ngx-emerios-all';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { NavigationUrlEnum } from 'src/app/enums/navigation-url';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { DashboardHandlerService } from '../../handler/dashboard-handler/dashboard-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../../behavior/util/util.service";
import * as i2 from "@angular/router";
import * as i3 from "ngx-emerios-all";
import * as i4 from "../../handler/dashboard-handler/dashboard-handler.service";
export class NavigationHelperService {
    constructor(_utilService, _router, _cache, _dashboardhandler) {
        this._utilService = _utilService;
        this._router = _router;
        this._cache = _cache;
        this._dashboardhandler = _dashboardhandler;
        this._dashboardhandler.currentDashboardItem$
            .subscribe(item => this._currentViewCode = item.viewCode);
        this._dashboardhandler.dashboardItemList$
            .subscribe(list => this._dashboardItemList = list);
    }
    goTo(url, nav) {
        if (url) {
            this._router.navigate([url]);
        }
        else {
            this._router.navigate([nav]);
        }
    }
    goToByUrl(url) {
        this._router.navigateByUrl(url);
    }
    goToLogin(redirectUrl) {
        if (redirectUrl) {
            this._router.navigate(['login'], { queryParams: { redirect: redirectUrl } });
        }
        else {
            this._router.navigate(['login']);
        }
    }
    goToDashboard(category) {
        if (category) {
            this._router.navigate([`${NavigationUrlEnum.Dashboard}/${category.code.toLocaleLowerCase()}`]);
        }
        else {
            this._router.navigate([NavigationUrlEnum.Dashboard]);
        }
    }
    goToTenantSelector(redirectUrl) {
        if (redirectUrl) {
            this._router.navigate([NavigationUrlEnum.OrganizationSelector], { queryParams: { redirect: redirectUrl } });
        }
        else {
            this._router.navigate([NavigationUrlEnum.OrganizationSelector]);
        }
    }
    goToViewDetail(params) {
        const viewCode = params.entity.viewCode;
        const source = params.entity.formSource;
        const commonUrl = `${NavigationUrlEnum.BaseFormUrl}/${viewCode}/${source}`;
        let url = commonUrl;
        if (params.detailCode) {
            url = `${commonUrl}/${params.detailCode}`;
        }
        this._cache.set('additionalPayloadProps', params.assignToPayload);
        if (params.backAfterSave) {
            this._cache.set(CacheKeyEnum.BackAfterSave, true);
        }
        if (params.inNewTab) {
            const baseUrl = window.location.href.split('#')[0];
            window.open(`${baseUrl}#/${url}`, '_blank');
        }
        else {
            this._router.navigate([url], {
                queryParams: params.queryParams,
                queryParamsHandling: params.queryParamsHandling
            });
        }
    }
    goToEntityList(source) {
        const dashboardItem = this._dashboardItemList.find(x => x.gridSource === source);
        const url = `${NavigationUrlEnum.BaseGridUrl}/${dashboardItem.viewCode}/${source}`;
        this._router.navigate([url]);
    }
    goToBookFileUploadView(instanceCode) {
        const source = DynafEnum.BookUpload;
        const url = `${NavigationUrlEnum.BaseFormUrl}/${this._currentViewCode}/${source}`;
        this._router.navigate([url], { queryParams: { instanceCode: instanceCode } });
    }
    goToRequest(requestCode) {
        this._router.navigate([`${NavigationUrlEnum.RequestDetail}/${DynafEnum.Request}/${requestCode}`]);
    }
    goToPendingRequestList() {
        const rnd = this._utilService.generateRndNumber(1, 99999);
        this._router.navigate([`${NavigationUrlEnum.PendingRequestList}/${AgGridEnum.PendingRequest}`], { queryParams: { r: rnd } });
    }
    goToPendingRequestDetail(requestToken) {
        this._router.navigate([`${NavigationUrlEnum.RequestDetail}/${AgGridEnum.PendingRequest}/${requestToken}`]);
    }
    goToBackgroundTasks() {
        const rnd = this._utilService.generateRndNumber(1, 99999);
        this._router.navigate([`${NavigationUrlEnum.BackgroundTaskDetail}/${AgGridEnum.BackgroundJobs}`], { queryParams: { r: rnd } });
    }
    updateAfterSave(url) {
        this._router.navigate([url]);
    }
    updateQueryParam(key, value) {
        const params = {};
        params[key] = value;
        this.updateQueryParams(params);
    }
    updateQueryParams(params) {
        this._router.navigate([], { queryParams: params, queryParamsHandling: 'merge' });
    }
}
NavigationHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationHelperService_Factory() { return new NavigationHelperService(i0.ɵɵinject(i1.UtilService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.CacheService), i0.ɵɵinject(i4.DashboardHandlerService)); }, token: NavigationHelperService, providedIn: "root" });
