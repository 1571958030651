import { Component, HostListener, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ReplacerService } from 'src/app/services/behavior/replacer/replacer.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';

export interface PositionComponent {
  top: number;
  left: number;
}

export interface ReplacerListOption {
  instanceCode: string;
  name: string;
  replacerCode: string;
}

@Component({
  selector: 'replacer-list-menu',
  templateUrl: './replacer-list.component.html',
  styles: [
    `.dropdown-menu {
        display: block;
        max-height: 200px;
        overflow-y: auto;
      }`
  ]
})

export class ReplacerListComponent implements OnInit, OnDestroy {
  public choices: Array<ReplacerListOption> = [];
  public selectChoice = new Subject();
  public activeChoice: ReplacerListOption;
  public position: PositionComponent;
  public searchInput: EventEmitter<string> = new EventEmitter();
  public replacerContext: Array<string> = [];
  public matchingText: string;
  public renderList: boolean;

  private _subscriptions: Array<Subscription> = [];

  @HostListener('document:keydown.ArrowDown', ['$event'])
  onArrowDown(event: KeyboardEvent) {
    event.preventDefault();
    const indexOf = this.choices.indexOf(this.activeChoice);
    if (this.choices[indexOf + 1]) {
      this.activeChoice = this.choices[indexOf + 1];
    } else {
      this.activeChoice = this.choices[0];
    }
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  onArrowUp(event: KeyboardEvent) {
    event.preventDefault();
    const indexOf = this.choices.indexOf(this.activeChoice);
    if (indexOf > 0) {
      this.activeChoice = this.choices[indexOf - 1];
    } else {
      this.activeChoice = this.choices[this.choices.length - 1];
    }
  }

  @HostListener('document:keydown.Enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.activeChoice) {
      event.preventDefault();
      this.updateSelectedChoice();
    }
  }

  constructor(
    private _replacer: ReplacerService,
    private _fieldValueHandler: FieldValueHandlerService) { }

  public search(searchTerm: string) {
    if (this.replacerContext && this.replacerContext.length > 0 && searchTerm !== '') {
      this.searchInput.next(searchTerm);
      this.matchingText = searchTerm;
    }
  }

  public updateSelectedChoice() {
    this._replacer.setReplacerChoiceSelected(this.activeChoice);
  }

  public isActiveItem(choice: ReplacerListOption) {
    return this.activeChoice.instanceCode === choice.instanceCode;
  }

  ngOnInit() {
    this.registerSubscription();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private registerSubscription() {
    this._subscriptions.push(
      this.searchInput.pipe(
        // debounceTime(500),
        distinctUntilChanged(),
        switchMap((search) => this._replacer.getReplacerByFilter(this.replacerContext, search))
      ).subscribe(replacerList => {
        this._setReplacersResult(replacerList);
      }));

    this._subscriptions.push(
      this._fieldValueHandler.anyValue$
        .subscribe(anyValue => {
          var contextList = anyValue.changes.get('contextInstanceCodes');
          if (contextList) {
            this.replacerContext = contextList.map(ctx => ctx.code);
          }
        }));
  }

  private _setReplacersResult(replacerList: Array<any>) {
    if (replacerList.length !== 0) {
      this.choices = replacerList.map(x => {
        return {
          name: x.name.substring(this.matchingText.length),
          instanceCode: x.code,
          replacerCode: x.description
        }
      });
      this.activeChoice = this.choices[0];
      this.renderList = true;
    } else {
      this.choices = [];
      this.renderList = false;
    }
  }

}
