import { BaseDataModel } from '../base-data-model.model';

export class FileUploadDataModelService implements BaseDataModel {

  constructor() { }

  public setValueToField(payloadValue: any) {
    const value = {};

    (payloadValue as Array<any> || []).forEach(item => {
      value[item.fileName] = {
        fileName: item.fileName,
        contentType: item.contentType,
        userName: item.userName,
        languageCode: item.languageInstanceCode,
        base64: item.base64File,
        fileStorageReference: item.fileStorageReference
      };
    });

    return value;
  }

  public getValueFromField(fieldValue: any) {
    const value = [];

    if (fieldValue) {
      Object.keys(fieldValue)
        .forEach(prop => {
          const fileObj = fieldValue[prop];

          value.push({
            fileName: fileObj.fileName,
            contentType: fileObj.contentType,
            userName: fileObj.userName,
            languageInstanceCode: fileObj.languageCode || '',
            base64File: fileObj.base64,
            fileStorageReference: fileObj.fileStorageReference
          });
        });
    }

    return value;
  }

}
