import { RestService, CacheService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class FileRestService {
    constructor(_rest, _cache) {
        this._rest = _rest;
        this._cache = _cache;
    }
    downloadFile(fileStorageReference, silentError = false) {
        const headers = new HttpHeaders()
            .append('silentError', silentError ? 'true' : 'false');
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/file/download/${fileStorageReference}`,
            responseType: 'blob',
            headers: headers
        });
    }
    getPdf(id) {
        const token = this._cache.get('flowToken');
        return this._rest.get({
            baseUrl: '',
            endpoint: `api/v1/${token}/file/pdf`,
            body: id,
            responseType: 'arrayBuffer'
        });
    }
    uploadBook(section, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${section}/book/upload`,
            body: payload
        });
    }
    downloadBookPreview(section, instanceCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${section}/book/upload/preview/${instanceCode}`
        });
    }
    downloadBookPreviewMultiple(section, instanceCode, journeyFormStepInstanceCode) {
        var url = journeyFormStepInstanceCode
            ? `api/v1/${section}/book/upload/preview-multiple/${instanceCode}/${journeyFormStepInstanceCode}`
            : `api/v1/${section}/book/upload/preview-multiple/${instanceCode}`;
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: url
        });
    }
    processBook(section, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/${section}/book/process`,
            body: payload
        });
    }
    downloadBookTemplate(section, instanceCode, journeyStepFormInstanceCode) {
        let endpoint = `api/v1/${section}/book/download/${instanceCode}`;
        if (journeyStepFormInstanceCode) {
            endpoint = `api/v1/${section}/book/download/${instanceCode}/${journeyStepFormInstanceCode}`;
        }
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: endpoint,
            responseType: 'blob',
            observe: 'response'
        })
            .pipe(map((result) => {
            let fileName = 'download.xlsx';
            const contentDisposition = result.headers.get('content-disposition');
            if (contentDisposition != null) {
                fileName = contentDisposition.split(';')[1].trim().split('filename=')[1].replace(/['"]+/g, '');
            }
            return { file: result.body, fileName: fileName };
        }));
    }
    generateAuditForm(instanceCode, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/request/generate-audit-form/${instanceCode}`,
            body: payload
        });
    }
    downloadAuditForm(instanceCode, jobExecutionUid) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/request/download-audit-form/${instanceCode}/${jobExecutionUid}`,
            responseType: 'blob',
            observe: 'response'
        })
            .pipe(map((result) => {
            let fileName = 'download.pdf';
            const contentDisposition = result.headers.get('content-disposition');
            if (contentDisposition != null) {
                fileName = contentDisposition.split(';')[1].trim().split('filename=')[1].replace(/['"]+/g, '');
            }
            return { file: result.body, fileName: fileName };
        }));
    }
    generateAgreeFlowAuditForm(flowType, instanceCode, payload) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort/flow/${flowType}/generate-audit-form/${instanceCode}`,
            body: payload
        });
    }
    downloadAgreeFlowAuditForm(flowType, instanceCode, jobExecutionUid) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/workeffort/flow/${flowType}/download-audit-form/${instanceCode}/${jobExecutionUid}`,
            responseType: 'blob',
            observe: 'response'
        })
            .pipe(map((result) => {
            let fileName = 'download.pdf';
            const contentDisposition = result.headers.get('content-disposition');
            if (contentDisposition != null) {
                fileName = contentDisposition.split(';')[1].trim().split('filename=')[1].replace(/['"]+/g, '');
            }
            return { file: result.body, fileName: fileName };
        }));
    }
}
FileRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileRestService_Factory() { return new FileRestService(i0.ɵɵinject(i1.RestService), i0.ɵɵinject(i1.CacheService)); }, token: FileRestService, providedIn: "root" });
