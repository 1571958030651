<div class="row">
  <div class="col-xs-12">
    <h4>Universe of resulting {{label}}</h4>
  </div>
</div>

<div class="row mt-3">
  <div class="col-sm-12 col-md-12">
    <span class="zipcode-figure">
      <i class="fas fa-map-pin"></i> {{counter}}
    </span>
    <span class="zipcode-description">{{label}} fitered</span>
  </div>
</div>
<div class="row mt-2">
  <div class="col-sm-12 col-md-12">
    <a href="javascript:void(0)" class="btn btn-icon btn-simple btn-action d-inline-block" (click)="fireEvent('view')"
      [ngClass]="{'disabled':!buttonsEnabled}">
      <i class="fas fa-eye"></i> View {{label}}
    </a>
    <!-- <a href="javascript:void(0)" class="btn btn-icon btn-simple btn-action d-inline-block" (click)="fireEvent('export')"
      [ngClass]="{'disabled':!buttonsEnabled}">
      <i class="fas fa-share-square"></i> Export {{label}}
    </a> -->
  </div>
</div>