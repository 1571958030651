<app-ghost-loading *ngIf="fieldsLoading" [type]="'dynamic-form'"></app-ghost-loading>

<ng-container *ngFor="let group of getGroups()">
	<ng-container *ngIf="checkGroupVisibility(group, data)">
		<div class="fields-group pl-fluid-container">
			<app-dynamic-form-group [config]="group" [data]="data" [disabled]="disabledFields"
				[externalEvent]="externalEvent" [fieldsDistribution]="fieldsDistribution"
				[forceFormValidation]="forceFormValidation" [editMode]="detailCode"
				(bubbledEvent)="bubbledEvent($event)" (isValid)="isValidGroup($event)"
				(dirty)="formDirtinessChanged($event)" (change)="onFieldChange($event)"
				(formSelected)="formSelected($event)">
			</app-dynamic-form-group>
		</div>
	</ng-container>
</ng-container>