import { OnInit, OnChanges, OnDestroy, SimpleChanges, EventEmitter, forwardRef } from '@angular/core';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { NG_VALUE_ACCESSOR, FormControl, Validators } from '@angular/forms';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { AggridColumnHelperService } from 'src/app/services/helper/aggrid-column-helper/aggrid-column-helper.service';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';
const noOp = () => { };
const ɵ0 = noOp;
export const CUSTOM_CATALOG_GRID_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CatalogGridComponent),
    multi: true
};
export var CatalogGridFilterByField;
(function (CatalogGridFilterByField) {
    CatalogGridFilterByField["ownerPartyRole"] = "ownerPartyRole";
    CatalogGridFilterByField["detailCode"] = "detailCode";
})(CatalogGridFilterByField || (CatalogGridFilterByField = {}));
export class CatalogGridComponent {
    constructor(_detailViewHandler, _gridHandler, _fieldValueHandler, _aggridColumnHelper) {
        this._detailViewHandler = _detailViewHandler;
        this._gridHandler = _gridHandler;
        this._fieldValueHandler = _fieldValueHandler;
        this._aggridColumnHelper = _aggridColumnHelper;
        this.filterByField = CatalogGridFilterByField.ownerPartyRole;
        this.readonly = false;
        this.selectionChanged = new EventEmitter();
        this.model = {};
        this._subscriptions = [];
        this.onTouchedCallback = noOp;
        this.model.gridModel = {
            uid: undefined,
            source: undefined,
            columns: [],
            rows: []
        };
    }
    writeValue(obj) {
        if (obj != this.control.value) {
            this.internalModel = obj;
            this._updateGridSelection();
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    gridSelectionChanged(event) {
        const gridApi = event.api;
        if (!this._updatingGridSelection) {
            const selectedRows = gridApi.getSelectedRows();
            this.internalModel = selectedRows.map(x => x[this.definition.catalogCodeProperty || 'instanceCode']);
            this._updateInnerValue();
            this.selectionChanged.emit();
        }
    }
    getControlErrors() {
        let errorMessage;
        if (this.control.errors && this.control.errors.required) {
            errorMessage = 'This is required';
        }
        return errorMessage;
    }
    gridApiReady(gridApi) {
        this._gridApi = gridApi;
        this._updateGridSelection();
    }
    onFirstDataRendered(event) {
        if (this.internalModel && this._gridApi) {
            this._updateGridSelection();
        }
    }
    ngOnInit() {
        this._subscriptions.push(this._detailViewHandler.mode$
            .subscribe(mode => {
            this._formOperationType = this.readonly ? FormOperationType.View : mode;
            this.model.suppressRowClickSelection = mode === FormOperationType.View || this.readonly;
        }));
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            this._initializeFormControl();
            this._configureComponent();
            this._updateInnerValue();
        }
        if (changes.forceValidation && changes.forceValidation.currentValue && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => {
                this.control.markAsDirty();
                this.control.markAsTouched();
                this.control.updateValueAndValidity();
            }));
        }
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }
    _initializeFormControl() {
        if (this.definition.attributes.required) {
            this.control = new FormControl(undefined, Validators.required);
        }
        else {
            this.control = new FormControl(undefined);
        }
    }
    _configureComponent() {
        switch (this.definition.type) {
            case 'grid-catalog-single':
                this.model.rowSelection = 'single';
                break;
            case 'grid-catalog-multiple':
                this.model.rowSelection = 'multiple';
                break;
            default:
                this.model.rowSelection = 'multiple';
                break;
        }
        if (this.definition.loadStandalone) {
            this._getGridData();
        }
        else {
            if (this.filterByField == CatalogGridFilterByField.detailCode) {
                this._subscriptions.push(this._fieldValueHandler.detailCodeValue$
                    .subscribe(detailCode => this._getGridData(detailCode)));
            }
            else {
                this._subscriptions.push(this._fieldValueHandler.ownerPartyRoleValue$
                    .subscribe(ownerPartyRole => this._getGridData(ownerPartyRole)));
            }
        }
        this._registerGetData();
    }
    _updateInnerValue() {
        this.control.setValue(this.internalModel);
        if (this._onChange) {
            this._onChange(this.control.value);
        }
    }
    _createCheckboxColumn(gridInput) {
        if ([FormOperationType.Create, FormOperationType.Edit].includes(this._formOperationType)) {
            const showHeaderCheckbox = this.definition.multiselect;
            const column = this._aggridColumnHelper.getNativeCheckboxColumn(showHeaderCheckbox);
            gridInput.columns.splice(0, 0, column);
        }
        return gridInput;
    }
    _registerGetData() {
        this._subscriptions.push(this._gridHandler.getGenericGridDataAndDef$
            .subscribe(gridInput => {
            if (this.definition.grid.source === gridInput.source) {
                this.model.gridModel = this._createCheckboxColumn(gridInput);
                this.model.loadingGrid = false;
                // this component is a catalog type, so this prop must be filled
                this.definition.catalogItems = gridInput.rows;
            }
        }));
    }
    _getGridData(instanceCode) {
        this.model.loadingGrid = true;
        this.internalModel = [];
        this._updateInnerValue();
        this._gridHandler.getGenericGridDefAndCustomData(this.definition.grid, instanceCode);
    }
    _updateGridSelection() {
        if (this._gridApi) {
            let lastSelectedRow = -1;
            this._updatingGridSelection = true;
            this._gridApi.forEachNode(node => {
                const codePropValue = node.data[this.definition.catalogCodeProperty || 'instanceCode'];
                if (this.internalModel && this.internalModel.includes(codePropValue)) {
                    node.setSelected(true);
                    lastSelectedRow = node.rowIndex;
                }
            });
            if (lastSelectedRow > -1) {
                this._gridApi.ensureIndexVisible(lastSelectedRow);
            }
        }
        setTimeout(() => {
            this._updatingGridSelection = false;
        });
    }
}
export { ɵ0 };
