import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { TextareaConfig, ElementResponse, ValidationMessages } from 'ngx-emerios-all';
import { Subscription } from 'rxjs';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { ModalService } from 'src/app/services/behavior/modal/modal.service';
import { DynamicFormPreviewComponent } from 'src/app/modals/dynamic-form-preview/dynamic-form-preview.component';

const noOp = () => { };

export interface ViewModel {
  validationMessages: ValidationMessages;
  showInputRequiredIcon: boolean;
}

export const CUSTOM_TEXTAREA_FORM_PREVIEW_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaFormPreviewComponent),
  multi: true
};

@Component({
  selector: 'app-textarea-form',
  templateUrl: './textarea-form-preview.component.html',
  styleUrls: ['./textarea-form-preview.component.sass'],
  providers: [CUSTOM_TEXTAREA_FORM_PREVIEW_CONTROL_VALUE_ACCESSOR]
})
export class TextareaFormPreviewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public config: TextareaConfig;
  @Input() public forceValidation: EventEmitter<any>;

  @Output("is-valid") public isValid: EventEmitter<ElementResponse> = new EventEmitter();

  public control: FormControl;
  public internalValue: string;
  public model = {} as ViewModel;

  private _subscriptions: Array<Subscription> = [];

  private _onChange: any;

  writeValue(obj: any): void {
    if (obj != this.control.value) {
      this.internalValue = obj;
      this._updateInnerValue();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  onTouchedCallback: () => void = noOp;

  constructor(private _modal: ModalService) { }

  public actionButtonClicked(event: any) {
    const params = {
      definition: [JSON.parse(this.control.value)]
    };

    this._modal.showCustom(DynamicFormPreviewComponent, { size: 'xl', params: params });
  }

  public getControlErrors() {
    let errorMessage: string;

    if (this.control.errors && this.control.errors.required) {
      errorMessage = 'This is required';
    }

    return errorMessage;
  }

  public inputValueChanged(event: string) {
    this.control.markAsDirty();
    this._updateInnerValue();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this._initializeFormControl();
      this._configureComponent();
      this._updateInnerValue();
    }

    if (changes.forceValidation && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _initializeFormControl() {
    this.control = new FormControl(undefined);

    if (this.config.validators) {
      const validators = this.config.validators;
      this.model.validationMessages = this.config.validationMessages;

      this.config.validators = undefined;
      this.model.validationMessages = undefined;

      setTimeout(() => {
        this.model.showInputRequiredIcon = (this.model.validationMessages && this.model.validationMessages.required !== undefined);
        this.control.setValidators(validators);
      });
    }

  }

  private _configureComponent() {
  }

  private _updateInnerValue() {
    this.control.setValue(this.internalValue);

    if (this._onChange) {
      this._onChange(this.control.value);
    }

    this._emitValidity();
  }

  private _emitValidity() {
    const isValid = this.control.status == 'VALID';

    this.isValid.emit({
      name: this.config.name,
      value: this.control.value,
      valid: isValid
    });
  }
}
