import { EventEmitter, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChangeManagementRestService } from 'src/app/services/rest/change-management-rest/change-management-rest.service';
import { EntityRestService } from 'src/app/services/rest/entity-rest/entity-rest.service';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { catchError } from 'rxjs/operators';
import { ApiHelperService } from '../../helper/api-helper/api-helper.service';
import * as i0 from "@angular/core";
import * as i1 from "../../rest/entity-rest/entity-rest.service";
import * as i2 from "../dashboard-handler/dashboard-handler.service";
import * as i3 from "../../rest/change-management-rest/change-management-rest.service";
import * as i4 from "../../helper/api-helper/api-helper.service";
export class EntityHandlerService {
    constructor(_entity, _dashboardHandler, _changeManagement, _apiHelper) {
        this._entity = _entity;
        this._dashboardHandler = _dashboardHandler;
        this._changeManagement = _changeManagement;
        this._apiHelper = _apiHelper;
        this._actionFired = new EventEmitter();
        this._entityData = new EventEmitter();
        this._errorsAfterChecking = new BehaviorSubject(undefined);
        this._subscriptions = [];
        this.actionFired$ = this._actionFired.asObservable();
        this.entityData$ = this._entityData.asObservable();
        this.errorsAfterChecking$ = this._errorsAfterChecking.asObservable();
        this._createSubscriptions();
    }
    getData(detailCode, groups) {
        let urlSection = this._apiHelper.getUrlSection(detailCode);
        this._entity.getDataEntity(urlSection, detailCode)
            .subscribe(data => {
            this._entityData.emit({ data, groups });
        });
    }
    getFormDefinitionByFormInstanceCode(detailCode) {
        return this._entity.getFormDefinitionByFormInstanceCode(detailCode);
    }
    getEntityByCodeAndOwner(code, owner) {
        const dashboardItem = this._currentDashboardItem;
        const urlSection = dashboardItem.overrideViewCode || dashboardItem.viewCode;
        return this._entity.getByCodeAndOwnerPartyRoleInstanceCode(urlSection, code, owner);
    }
    saveEntity(payload, requestCode) {
        const detailCode = payload.getUniqueCode();
        const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
        let obs;
        if (urlSection === 'file-upload') {
            requestCode = undefined;
        }
        if (requestCode) {
            if (detailCode) {
                obs = this._entity.updateAndLinkEntity(urlSection, requestCode, payload);
            }
            else {
                obs = this._entity.saveAndLinkEntity(urlSection, requestCode, payload);
            }
        }
        else {
            if (detailCode) {
                obs = this._entity.updateEntity(urlSection, payload);
            }
            else {
                obs = this._entity.saveEntity(urlSection, payload);
            }
        }
        obs.pipe(catchError((error) => { this._fireErrorAction('saved'); throw error; }))
            .subscribe((response) => this._fireAction('saved', response));
    }
    saveEntityWithRelationship(payload, requestCode) {
        const detailCode = payload.getUniqueCode();
        const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
        let obs;
        if (requestCode) {
            if (detailCode) {
                obs = this._entity.updateAndLinkEntityWithRelationship(urlSection, requestCode, payload);
            }
            else {
                obs = this._entity.saveAndLinkEntityWithRelationship(urlSection, requestCode, payload);
            }
        }
        else {
            if (detailCode) {
                obs = this._entity.updateEntityWithRelationship(urlSection, payload);
            }
            else {
                obs = this._entity.saveEntityWithRelationship(urlSection, payload);
            }
        }
        obs.pipe(catchError((error) => { this._fireErrorAction('saved'); throw error; }))
            .subscribe((response) => this._fireAction('saved', response));
    }
    confirmLink(payload) {
        this._changeManagement.confirmLink(payload)
            .pipe(catchError((error) => { this._fireErrorAction('linked'); throw error; }))
            .subscribe(() => this._fireAction('linked'));
    }
    confirmLinkMultiple(payload) {
        this._changeManagement.confirmLinkMultiple(payload)
            .pipe(catchError((error) => { this._fireErrorAction('linked'); throw error; }))
            .subscribe(() => this._fireAction('linked'));
    }
    confirmUnlink(changeRequestCode) {
        this._changeManagement.confirmUnlink(changeRequestCode)
            .pipe(catchError((error) => { this._fireErrorAction('unlinked'); throw error; }))
            .subscribe(() => this._fireAction('unlinked'));
    }
    submitRequest(code, executorUserName, signature, approvalWorkEffortInstanceCode) {
        this._changeManagement.submitRequest(code, executorUserName, signature, approvalWorkEffortInstanceCode)
            .pipe(catchError((error) => { this._fireErrorAction('submitted'); throw error; }))
            .subscribe(() => this._fireAction('submitted'));
    }
    cancelRequest(code, executorUserName) {
        this._changeManagement.cancelRequest(code, executorUserName)
            .pipe(catchError((error) => { this._fireErrorAction('canceled'); throw error; }))
            .subscribe(() => this._fireAction('canceled'));
    }
    validateDiscardRequest(code) {
        this._changeManagement.validateDiscardRequest(code)
            .pipe(catchError((error) => { this._fireErrorAction('validated discard request'); throw error; }))
            .subscribe((data) => this._fireAction('validated discard request', data));
    }
    discardRequest(code) {
        this._changeManagement.discardRequest(code)
            .pipe(catchError((error) => { this._fireErrorAction('request discared'); throw error; }))
            .subscribe(() => this._fireAction('request discared'));
    }
    approveRequest(code, executorUserName, signature) {
        this._changeManagement.approveRequest(code, executorUserName, signature)
            .pipe(catchError((error) => { this._fireErrorAction('approved'); throw error; }))
            .subscribe((action) => this._fireAction(action));
    }
    rejectRequest(code, executorUserName) {
        this._changeManagement.rejectRequest(code, executorUserName)
            .pipe(catchError((error) => { this._fireErrorAction('rejected'); throw error; }))
            .subscribe(() => this._fireAction('rejected'));
    }
    deploy(code, executorUserName) {
        this._changeManagement.deploy(code, executorUserName)
            .pipe(catchError((error) => { this._fireErrorAction('deployed'); throw error; }))
            .subscribe(() => this._fireAction('deployed'));
    }
    cloneEntity(payload) {
        const detailCode = payload.getUniqueCode();
        const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
        this._entity.cloneEntity(urlSection, payload)
            .pipe(catchError((error) => { this._fireErrorAction('cloned'); throw error; }))
            .subscribe((response) => this._fireAction('cloned', response));
    }
    cloneFlow(payload) {
        const detailCode = payload.getUniqueCode();
        const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
        this._entity.cloneFlow(urlSection, payload)
            .pipe(catchError((error) => { this._fireErrorAction('cloned'); throw error; }))
            .subscribe((response) => this._fireAction('cloned', response));
    }
    deepClone(payload) {
        const detailCode = payload.getUniqueCode();
        const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
        this._entity.deepClone(urlSection, payload)
            .pipe(catchError((error) => { this._fireErrorAction('cloned-tenant'); throw error; }))
            .subscribe((response) => this._fireAction('cloned-tenant', response));
    }
    tenantComparer(source, target) {
        return this._entity.tenantComparer(source, target);
    }
    canApprovedOrRejectRequest(code) {
        return this._changeManagement.canApprovedOrRejectRequest(code);
    }
    confirmDiscardChanges(payload) {
        const detailCode = payload.getUniqueCode();
        const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
        this._entity.confirmDiscardChanges(urlSection, detailCode)
            .pipe(catchError((error) => { this._fireErrorAction('changes discared'); throw error; }))
            .subscribe(() => this._fireAction('changes discared'));
    }
    validateDiscardChanges(payload) {
        const detailCode = payload.getUniqueCode();
        const urlSection = this._apiHelper.getUrlSection(detailCode, payload);
        return this._entity.validateDiscardChanges(urlSection, detailCode)
            .pipe(catchError((error) => { this._fireErrorAction('validated discard'); throw error; }))
            .subscribe((data) => this._fireAction('validated discard', data));
    }
    fireActionSaved() {
        this._fireAction('saved');
    }
    getRequest(code) {
        return this._entity.getRequest(code);
    }
    _setErrorMessagesAfterCheckingEntityList(errorMessage) {
        this._errorsAfterChecking.next(errorMessage);
    }
    ngOnDestroy() {
        this._subscriptions.forEach(s => s.unsubscribe());
    }
    _createSubscriptions() {
        this._subscriptions.push(this._dashboardHandler.currentDashboardItem$
            .subscribe(dashboardItem => this._currentDashboardItem = dashboardItem));
    }
    _fireAction(action, data, error) {
        this._actionFired.next({
            action: action,
            entity: this._currentDashboardItem.name,
            data: data,
            error: error
        });
    }
    _fireErrorAction(action) {
        this._actionFired.next({
            action: action,
            entity: this._currentDashboardItem.name,
            error: true
        });
    }
}
EntityHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntityHandlerService_Factory() { return new EntityHandlerService(i0.ɵɵinject(i1.EntityRestService), i0.ɵɵinject(i2.DashboardHandlerService), i0.ɵɵinject(i3.ChangeManagementRestService), i0.ɵɵinject(i4.ApiHelperService)); }, token: EntityHandlerService, providedIn: "root" });
