import { BaseDataModel } from '../base-data-model.model';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';

export class CatalogSingleDataModelService implements BaseDataModel {

  private _fieldDefinition: FieldDefinition;

  constructor(fieldDefinition: FieldDefinition) {
    this._fieldDefinition = fieldDefinition;
  }

  public setValueToField(payloadValue: any) {
    if (payloadValue == undefined) {
      return undefined;
    }

    const catalogCodeProperty = this._fieldDefinition.catalogCodeProperty;
    const catalogCodeProperty2 = this._fieldDefinition.catalogCodeProperty2; // sorry for the naming :)
    const payloadCodeProperty = this._fieldDefinition.payloadCodeProperty;

    if (catalogCodeProperty || payloadCodeProperty) {
      if(typeof payloadValue === "string") {
        if (this._fieldDefinition.catalogItems && this._fieldDefinition.catalogItems.length > 0) {
          const catalogItem = this._fieldDefinition.catalogItems
            .find(x => x[catalogCodeProperty || 'code'] === payloadValue);

          if (catalogCodeProperty2) {
            return catalogItem ? [catalogItem[catalogCodeProperty2]] : undefined;
          } else {
            return catalogItem ? [catalogItem[catalogCodeProperty || 'code']] : undefined;
          }
        } 
      } else {
        const payloadCodeValue = payloadCodeProperty
        ? payloadValue[payloadCodeProperty]
        : payloadValue[catalogCodeProperty];
        if (this._fieldDefinition.catalogItems && this._fieldDefinition.catalogItems.length > 0) {
          const catalogItem = this._fieldDefinition.catalogItems
            .find(x => x[catalogCodeProperty || 'code'] === payloadCodeValue);
  
          if (catalogCodeProperty2) {
            return catalogItem ? [catalogItem[catalogCodeProperty2]] : undefined;
          } else {
            return catalogItem ? [catalogItem[catalogCodeProperty || 'code']] : undefined;
          }
        }
      }
    }
    return [payloadValue.toString()];
  }

  public getValueFromField(fieldValue: any) {
    // the validation of catalogItems is only to avoid an error when hitting
    // a special field hidden used to trigger catalog cascade loading
    if (fieldValue && this._fieldDefinition.catalogItems) {
      let catalogItem = this._fieldDefinition.catalogItems
        .find(x => x.code === fieldValue[0]);

      if (catalogItem == undefined) {
        catalogItem = this._fieldDefinition.catalogItems
          .find(x => x[this._fieldDefinition.catalogCodeProperty] === fieldValue[0]);
      }

      if (this._fieldDefinition.propertyAsCode) {
        return catalogItem && catalogItem[this._fieldDefinition.propertyAsCode];
      } else if (this._fieldDefinition.codeProperty) {
        let objProp = {};

        objProp[this._fieldDefinition.codeProperty] = fieldValue && fieldValue[0];

        if (this._fieldDefinition.extraCatalogProperties) {
          this._fieldDefinition.extraCatalogProperties.forEach(prop => {
            if (prop.includes('=')) {
              objProp[prop.split('=')[0]] = prop.split('=')[1];
            } else {
              if (catalogItem) {
                objProp[prop] = catalogItem[prop];
              } else {
                console.warn('Item not found in catalog', this._fieldDefinition)
              }
            }
          });
        }

        return objProp;

      } else {
        return fieldValue[0];
      }
    }
    return fieldValue;
  }

}
