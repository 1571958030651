import { Directive, ElementRef, HostListener, ComponentFactoryResolver, ViewContainerRef, ComponentRef, Input, OnInit, OnDestroy } from '@angular/core';
import { ReplacerListComponent, ReplacerListOption } from 'src/app/components/replacer-list/replacer-list.component';
import { EventEmitter } from '@angular/core';
import { CodeName } from 'src/app/models/common/code-name.model';
import { ReplacerService } from 'src/app/services/behavior/replacer/replacer.service';
import { ReplacerFieldData } from 'src/app/models/form-field-definition.models';
import { Subscription } from 'rxjs';

export interface ReplacerData {
  replacerCode: string;
  startIndex: number;
  endIndex: number;
}

export interface SelectionData {
  replacersToDelete: Array<ReplacerData>;
  startIndex: number;
  endIndex: number;
}

@Directive({
  selector: '[appReplacer]'
})
export class ReplacerDirective implements OnInit, OnDestroy {
  @Input() appReplacer: ReplacerFieldData;

  @HostListener('document:keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    const position = (event.target as HTMLInputElement).selectionStart;
    this._targetElement = this._findInputElement(this._el.nativeElement);
    
    if (!this._menu && this._replacerEnabled()) {
      if (this._isTriggerKey(event.key)) {
        this._showList();
      } else if (this._isDeleteKey(event.key)) {
        this._handleDeletion(event, position);
      } else if (this._isPrintableKey(event.key)) {
        this._handleRegularKeyInput(position);
        this._clearSelectionData();
      }
    }
  }

  @HostListener('input', ['$event.target'])
  onChange(target: HTMLInputElement) {
    if (this._menu && this._replacerEnabled()) {
      if (!this._isTriggerKey(target.value[this._menu.triggerCharacterPosition])) {
        this._hideList();
      } else {
        const searchTerm = this._getSearchTerm(target.value);
        this._menu.component.instance.search(searchTerm);
        this.caretPosition = target.selectionStart;
      }
    }
  }

  @HostListener('select', ['$event.target'])
  onSelect(target: HTMLInputElement) {
    if (this._replacerEnabled()) {
      const startIndex = target.selectionStart;
      const endIndex = target.selectionEnd;

      if (startIndex !== endIndex) {
        this._handleTextSelection(startIndex, endIndex);
      }
    }
  }

  public caretPosition: number;
  public searchInput: EventEmitter<string> = new EventEmitter();
  public menuComponent = ReplacerListComponent;

  private _subscriptions: Array<Subscription> = [];
  private _targetElement: HTMLInputElement;
  private _replacerList: Array<ReplacerData> = [];
  private _selectionData: SelectionData;
  private _triggerKey: string = '@';
  private _deleteKeys: Array<string> = ['Delete', 'Backspace'];
  private _nonPrintableKey: Array<string> =
    ['ArrowRight', 'ArrowDown', 'ArrowLeft', 'ArrowUp',
      'Control', 'Shift', 'Alt', 'Escape', 'CapsLock',
      'End', 'PageDown', 'Home', 'PageUp',
      'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'];
  private _menu: {
    component: ComponentRef<ReplacerListComponent>,
    triggerCharacterPosition: number
  } = undefined;

  constructor(
    private _el: ElementRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef,
    private _replacer: ReplacerService) { }

  ngOnInit() {
    this._subscriptions.push(
      this._replacer.initialReplacerList$.subscribe(list => {
        this._targetElement = this._findInputElement(this._el.nativeElement);
        if (list && this._targetElement) {
          this._setInitialReplacerList(list);
        }
      }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  private _setInitialReplacerList(replacerList: Array<CodeName>) {
    const initialMark = this.appReplacer.initialMark;
    const finalMark = this.appReplacer.finalMark;
    const textLength = this._targetElement.value.length;
    let startIndex: number = -1;
    let endIndex: number;
    let replacerCode: string;

    for (let index = 0; index < textLength; index++) {
      if (this._targetElement.value.charAt(index) === initialMark) {
        startIndex = index;
      }

      if (this._targetElement.value.charAt(index) === finalMark) {
        endIndex = index;
        if (startIndex > -1) {
          replacerCode = this._targetElement.value.substring(startIndex + 1, endIndex);
          const replacerObj = this._findReplacerInText(replacerList, replacerCode);
          this._addReplacerToList(replacerObj.code, startIndex, endIndex + 1);
          startIndex = -1;
        }
      }
    }
  }

  private _findReplacerInText(replacerList: Array<CodeName>, replacerCode: string) {
    return replacerList.find(x => x.name === replacerCode);
  }

  private _replacerEnabled() {
    return this.appReplacer != undefined;
  }

  private _showList() {
    if (!this._menu) {
      this._createListComponent();
      this._addChoiceSelectionSubscription();
    }
  }

  private _createListComponent() {
    const menuFactory = this._componentFactoryResolver
      .resolveComponentFactory<ReplacerListComponent>(this.menuComponent);
    const lineHeight = +getComputedStyle(this._targetElement).lineHeight!.replace(/px$/, '');

    this._menu = {
      component: this._viewContainerRef.createComponent(menuFactory),
      triggerCharacterPosition: this._targetElement.selectionStart
    };

    const { offsetTop, offsetLeft } = this._getLeftPosition();
    this._menu.component.instance.position = {
      top: offsetTop + lineHeight,
      left: offsetLeft
    };
  }

  private _findInputElement(element: HTMLElement) {
    return this._findElementRecursive(element) as HTMLInputElement;
  }

  private _findElementRecursive(element: HTMLElement) {
    let keepGoing: boolean = true;
    let index: number = 0;
    let targetElement: HTMLElement;

    if (element != undefined && this._replacerEnabled()) {
      if (element.localName === this.appReplacer.targetElement) {
        targetElement = element;
      } else if (element.childNodes) {
        while (index < element.childNodes.length && keepGoing) {
          targetElement = this._findElementRecursive(element.childNodes[index] as HTMLElement);
          if (targetElement) {
            keepGoing = false;
          } else {
            index++;
          }
        }
      }
    }

    return targetElement;
  }

  private _addChoiceSelectionSubscription() {
    this._replacer.selectedChoice$
      .subscribe((choice: ReplacerListOption) => {
        if (this._menu) {
          this._setTextContent(choice);
          this._hideList();
        }
      });
  }

  private _setTextContent(choice: ReplacerListOption) {
    const textAreaValue = this._targetElement.value;
    const startIndex = this._menu.triggerCharacterPosition;
    const start = textAreaValue.slice(0, startIndex);
    const replacerCode = this._wrapReplacer(choice.replacerCode);
    const endIndex = startIndex + replacerCode.length;

    this._addReplacerToList(choice.instanceCode, startIndex, endIndex);
    this._replacer.updateReplacerListSelection(this._replacerList);

    const end = textAreaValue.slice(this.caretPosition);
    this._targetElement.value = `${start}${replacerCode}${end}`;
    this._updateReplacerList(startIndex + replacerCode.length, replacerCode.length, true);

    this._updateInputElementContent();
    this._setCursorPosition((start + replacerCode).length);
  }

  private _addReplacerToList(code: string, startIndex: number, endIndex: number) {
    this._replacerList.push({
      replacerCode: code,
      startIndex: startIndex,
      endIndex: endIndex
    });
  }

  private _wrapReplacer(replacerCode: string) {
    return `${this.appReplacer.initialMark}${replacerCode}${this.appReplacer.finalMark}`;
  }

  private _updateInputElementContent() {
    this._targetElement.dispatchEvent(new Event('input'));
  }

  private _setCursorPosition(position: number) {
    this._targetElement.setSelectionRange(position, position);
    this._targetElement.focus();
  }

  private _getLeftPosition() {
    const div = document.createElement('div');
    const elementStyle = getComputedStyle(this._targetElement);

    this._targetElement.parentNode.insertBefore(div, this._targetElement);
    this._setElementStyle(div, elementStyle);

    const text = this._el.nativeElement.childNodes[0].childNodes[0].childNodes[3].childNodes[1].value
      .substring(0, this._menu.triggerCharacterPosition + 1);
    const divText = document.createTextNode(text);
    const span = document.createElement('span');
    const spanText = document.createTextNode('test');

    div.appendChild(divText);
    span.appendChild(spanText);
    div.appendChild(span);

    const offsetLeft = span.offsetLeft + this._getExtraWidth();
    const offsetTop = span.offsetTop + parseInt(elementStyle.borderTopWidth, 10) + 20;
    this._el.nativeElement.childNodes[0].childNodes[0].childNodes[3].removeChild(div);

    return { offsetTop, offsetLeft };
  }

  private _getExtraWidth() {
    const contentElement = this._targetElement.parentNode;
    const contentElementIndex = parseInt(Object.keys(contentElement.parentNode.childNodes)
      .find(key => contentElement.parentNode.childNodes[key] === contentElement));

    let width: number = 0;
    let extraWidth: number = 0;

    for (let index = 0; index < contentElementIndex; index++) {
      width = (<HTMLElement>contentElement.parentNode.childNodes[index]).offsetWidth;

      if (width) {
        extraWidth += width;
      }
    }

    return extraWidth;
  }

  private _setElementStyle(element: HTMLElement, style: CSSStyleDeclaration) {
    const divStyle = element.style;

    Object.keys(style).forEach(key => {
      if (isNaN(parseInt(key))) {
        divStyle[key] = style[key]
      }
    });

    divStyle.position = 'absolute';
    divStyle.visibility = 'hidden';
    divStyle.whiteSpace = 'pre-wrap';
    divStyle.wordWrap = 'break-word';
  }

  private _hideList() {
    if (this._menu) {
      this._menu.component.destroy();
      this._menu = undefined;
    }
  }

  private _getSearchTerm(text: string) {
    return text.slice(this._menu.triggerCharacterPosition + 1, this.caretPosition + 1);
  }

  private _handleRegularKeyInput(position: number) {
    if (!this._menu) {
      if (this._selectionData) {
        this._deleteSelection();
      }
      this._updateReplacerList(position, 1, true);
    }
  }

  private _handleDeletion(event: KeyboardEvent, position: number) {
    const replacer = this._findReplacerByPositionAndEvent(event, position);

    if (this._selectionData) {
      this._deleteSelection();
    } else if (replacer) {
      this._deleteReplacer(replacer);
    } else {
      this._updateReplacerList(position, 1, false);
    }
  }

  private _handleTextSelection(startIndex: number, endIndex: number) {
    const { offsetLeft, offsetRight, arrayDeletion } = this._getDeletionRangeData(startIndex, endIndex);
    startIndex -= offsetLeft;
    endIndex += offsetRight;

    this._selectionData = {
      replacersToDelete: arrayDeletion,
      startIndex: startIndex,
      endIndex: endIndex
    };
  }

  private _findReplacerByPositionAndEvent(event: KeyboardEvent, position: number) {
    switch (event.key) {
      case 'Backspace':
        return this._replacerList
          .find(x => x.startIndex < position && position <= x.endIndex);
      case 'Delete':
        return this._replacerList
          .find(x => x.startIndex <= position && position < x.endIndex);
      default:
        return undefined;
    }
  }

  private _deleteReplacer(replacer: ReplacerData) {
    event.preventDefault();
    this._deleteReplacerFromList(replacer);
    const deletedLength = this._updateTextValue(replacer.startIndex, replacer.endIndex);

    this._updateReplacerList(replacer.endIndex, deletedLength, false);
    this._setCursorPosition(replacer.startIndex);
  }

  private _updateTextValue(startIndex: number, endIndex: number) {
    let deletedLength: number;

    if (this._hasDoubleSpace(startIndex, endIndex)) {
      this._targetElement.value = this._targetElement.value.substring(0, startIndex - 1) + this._targetElement.value.substring(endIndex);
      deletedLength = endIndex - startIndex + 1;
    }
    else {
      this._targetElement.value = this._targetElement.value.substring(0, startIndex) + this._targetElement.value.substring(endIndex);
      deletedLength = endIndex - startIndex;
    }

    return deletedLength;
  }

  private _isTriggerKey(key: string) {
    return this._triggerKey === key;
  }

  private _isDeleteKey(key: string) {
    return this._deleteKeys.includes(key);
  }

  private _isPrintableKey(key: string) {
    return !this._nonPrintableKey.includes(key);
  }

  private _hasDoubleSpace(positionStart: number, positionEnd: number) {
    const text = this._targetElement.value;
    return text.charAt(positionStart - 1) === ' ' && text.charAt(positionEnd) === ' ';
  }

  private _getDeletionRangeData(startSelectionIndex: number, endSelectionIndex: number) {
    let offsetLeft: number = 0;
    let offsetRight: number = 0;
    const arrayDeletion = [];

    this._replacerList.forEach(x => {
      if (x.startIndex >= startSelectionIndex && x.endIndex <= endSelectionIndex) {
        arrayDeletion.push(x);
      }
      if ((x.startIndex >= startSelectionIndex && x.startIndex < endSelectionIndex) && x.endIndex > endSelectionIndex) {
        arrayDeletion.push(x);
        offsetRight += x.endIndex - endSelectionIndex;
      }
      if (x.startIndex < startSelectionIndex && (x.endIndex <= endSelectionIndex && x.endIndex > startSelectionIndex)) {
        arrayDeletion.push(x);
        offsetLeft += startSelectionIndex - x.startIndex;
      }
      if (x.startIndex < startSelectionIndex && x.endIndex > endSelectionIndex) {
        arrayDeletion.push(x);
        offsetRight += x.endIndex - endSelectionIndex;
        offsetLeft += startSelectionIndex - x.startIndex;
      }
    });

    return { offsetLeft, offsetRight, arrayDeletion };
  }

  private _updateReplacerList(position: number, length: number, addOperation: boolean) {
    this._replacerList.forEach(x => {
      if (x.startIndex >= position) {
        x.startIndex = addOperation ? x.startIndex + length : x.startIndex - length;
        x.endIndex = addOperation ? x.endIndex + length : x.endIndex - length;
      }
    });
  }

  private _deleteSelection() {
    event.preventDefault();
    const deletedLength = this._updateTextValue(this._selectionData.startIndex, this._selectionData.endIndex);
    this._selectionData.replacersToDelete.forEach(x => this._deleteReplacerFromList(x));
    this._updateReplacerList(this._selectionData.endIndex, deletedLength, false);
    this._setCursorPosition(this._selectionData.startIndex);
    this._clearSelectionData();
  }

  private _clearSelectionData() {
    this._selectionData = undefined;
  }

  private _deleteReplacerFromList(found: ReplacerData) {
    const indexOf = this._replacerList.findIndex(x => x === found);
    this._replacerList.splice(indexOf, 1);
  }

}
