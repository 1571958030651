import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from 'ngx-emerios-all';

@Injectable({
  providedIn: 'root'
})
export class TranslateLoaderService implements TranslateLoader {

  constructor(private _rest: RestService) { }

  public getTranslation(lang: string): any {
    return this.getTranslationFiles(lang);
  }

  private getTranslationFiles(lang: string): Observable<any> {
    let languageObservables: Observable<any>;
    const wasManualChange: boolean = sessionStorage.getItem('manualChange') === 'true';

    const restConfig = { loaderMustHideRouter: wasManualChange };

    languageObservables = this._rest.get({
      baseUrl: '',
      endpoint: `assets/i18n/${lang}.json`,
      extraConfig: restConfig
    });

    return languageObservables.pipe(
      map((res) =>
        res instanceof Array ? this.mergeDeep(res[0], res[1]) : res
      ));
  }

  private mergeDeep(target: { [x: string]: any; }, source: { [x: string]: any; }) {
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) { Object.assign(target, { [key]: {} }); }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
    return target;
  }

  private isObject(item: any) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }
}

export function CustomHttpLoaderFactory(rest: RestService) {
  return new TranslateLoaderService(rest);
}
