import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { of, Subject, forkJoin } from 'rxjs';
import { DataModelHelperService } from 'src/app/services/helper/data-model-helper/data-model-helper.service';
import { FieldApplicability } from 'src/app/models/catalog.models';
import { deepClone } from 'src/app/functions/deepClone';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { groupBy } from 'src/app/functions/groupBy';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { CustomFormValidatorService } from 'src/app/services/behavior/custom-form-validator/custom-form-validator.service';
import { switchMap, catchError } from 'rxjs/operators';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
export var FeatureType;
(function (FeatureType) {
    FeatureType["Boolean"] = "FTR.BIT";
    FeatureType["Date"] = "FTR.DATE";
    FeatureType["Number"] = "FTR.NUM";
    FeatureType["String"] = "FTR.STR";
    FeatureType["Multilang"] = "FTR.LANG";
    FeatureType["FileAsset"] = "FTR.BIN_AST";
    FeatureType["FileDocument"] = "FTR.BIN_DOC";
})(FeatureType || (FeatureType = {}));
export class DynamicFormGroupContainerComponent {
    constructor(_catalogService, _dataModelHelper, _fieldValueHandler, _dashboardHandler, _detailViewHandler) {
        this._catalogService = _catalogService;
        this._dataModelHelper = _dataModelHelper;
        this._fieldValueHandler = _fieldValueHandler;
        this._dashboardHandler = _dashboardHandler;
        this._detailViewHandler = _detailViewHandler;
        this.fieldsDistribution = 'page';
        this.isValid = new EventEmitter();
        this.dirty = new EventEmitter();
        this.updateDynamicGroups = new EventEmitter();
        this.fieldsLoading = true;
        this.allGroups = [];
        this._featuresFromCatalog = new Subject();
        this._subscriptions = [];
        this._elementsValidity = {};
        this._dynamicCreateGroups = [];
        this._features = [];
        this._defaultFeatureSectionName = 'DEFAULT DYNAMIC FEATURES';
    }
    isValidGroup(event) {
        this._elementsValidity[event.code] = event.valid;
        this._revalidateFormGroups();
    }
    formDirtinessChanged(dirty) {
        this.dirty.emit(dirty);
    }
    onFieldChange(event) {
        setTimeout(() => {
            this._checkForGroupsVisibility(event);
            this._checkForDynamicGroupCreation(event);
        });
    }
    bubbledEvent(event) {
        setTimeout(() => {
            this.externalEvent = { name: event.name, value: event.value, stopPropagation: true };
        });
    }
    formSelected(event) {
        this._deleteAllDynamicGenGroups();
        if (event) {
            let position = this.allGroups.findIndex(x => x.definition.sectionId.toString().startsWith('DYNAS.STP.GENFRM'));
            event.formDefinition.forEach(def => {
                position++;
                this.allGroups.splice(position, 0, { definition: def, static: true });
            });
            this.updateDynamicGroups.next(this.allGroups.map(x => x.definition));
            if (event.hideStaticGroups) {
                this.allGroups.forEach(g => {
                    if (!g.definition.dynamicSection && !g.definition.showDuringJourney) {
                        g.definition.hidden = true;
                    }
                });
            }
        }
    }
    getGroups() {
        return this.allGroups.map(x => x.definition);
    }
    ngOnInit() {
        this._subscriptions.push(this._fieldValueHandler.ownerPartyRoleValue$
            .subscribe(owner => {
            CustomFormValidatorService.ownerPartyRoleInstanceCode = owner;
            const groupFilteredByTenant = this.allGroups.find(x => x.definition.filterByTenant);
            if (groupFilteredByTenant) {
                groupFilteredByTenant.definition.hidden = false;
            }
        }));
        this._subscriptions.push(this._dashboardHandler.currentDashboardItem$
            .subscribe(item => this._dashboardItem = item));
        this._subscriptions.push(this._detailViewHandler.mode$
            .subscribe(mode => {
            this._viewMode = mode;
        }));
        this._configureGetFeauresFromCatalog();
    }
    checkGroupVisibility(group, data) {
        if (!group.hidden && group.visibilityField) {
            return data[group.visibilityField];
        }
        return !group.hidden;
    }
    ngOnChanges(changes) {
        if (changes.groups && changes.groups.currentValue) {
            setTimeout(() => {
                this.fieldsLoading = false;
                this._initializeFields(this.entityName || this._dashboardItem.name);
            }, 100);
        }
        else {
            this.fieldsLoading = true;
            this.allGroups = [];
            this._elementsValidity = {};
        }
        if (changes.data && changes.data.currentValue) {
            this._features = this.data.features;
        }
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
        this._clearOwnerValue();
    }
    _clearOwnerValue() {
        CustomFormValidatorService.ownerPartyRoleInstanceCode = undefined;
        this._fieldValueHandler.setOwnerPartyRoleValue(undefined);
    }
    _initializeFields(entityName) {
        this.allGroups = [];
        this._dynamicCreateGroups = [];
        this.groups.forEach(group => {
            if (group.fields) {
                if (group.filterByTenant) {
                    group.hidden = true;
                }
                if (!group.triggerBy) {
                    // if we are creating a new entity, avoid showing not editable fields
                    if (!this.detailCode) {
                        // show the field if value has value
                        // and is not of rules-grid type
                        group.fields = group.fields
                            .filter(field => (field.field === 'campaignLibraryTypeCode') || (field.attributes.editable || field.value))
                            .filter(field => field.type !== 'rules-grid');
                    }
                    if (this.data && this.data.parentInstanceLibraryCode
                        && this.data.parentInstanceLibraryCode === 'STP.DOC') {
                        const field = group.fields.find(x => x.field === 'workEffortTypeCode');
                        if (field) {
                            field.value = 'STP.FRM.DOC';
                        }
                    }
                    if (group.fields.length > 0) {
                        this.allGroups.push({ static: true, definition: group });
                    }
                }
                else {
                    group.hidden = true;
                    this.allGroups.push({ static: false, definition: group });
                }
                const fields = group.fields.filter(x => x.dynamicGroup);
                this._dynamicCreateGroups.push(...fields);
            }
            group.title = group.title.replace('${entity}', entityName);
        });
    }
    _checkForGroupsVisibility(event) {
        const dynamicVisibilityGroups = this.groups
            .filter(group => group.triggerBy);
        const isTrigger = dynamicVisibilityGroups.filter(x => x.triggerBy.field == event.name).length > 0;
        if (!isTrigger || !event.value) {
            return;
        }
        let codes = [];
        if (Array.isArray(event.value)) {
            codes.push(...deepClone(event.value));
        }
        else {
            codes.push(deepClone(event.value)[0]);
        }
        // we work only with groups NOT triggered by the current event field (event.name)
        const groupsToWork = this._getDynamicGroups().filter(x => x.triggeredBy === event.name);
        const groupsToBeVisible = dynamicVisibilityGroups
            .filter(group => {
            return group.triggerBy.field == event.name
                && group.triggerBy.codes.some(c => codes.includes(c))
                && this._checkViewModeForTriggering(group);
        });
        const groupsToRemove = groupsToWork.filter(groupToWork => {
            const mustBeVisible = groupsToBeVisible.filter(groupToBeVisible => {
                if (groupToBeVisible.name && groupToWork.name) {
                    return groupToBeVisible.name === groupToWork.name;
                }
                else {
                    return groupToBeVisible.title === groupToWork.title;
                }
            });
            return mustBeVisible.length === 0;
        });
        groupsToRemove.forEach(group => {
            delete this._elementsValidity[group.title.toLocaleLowerCase().replace(/ /g, '_')];
            this._revalidateFormGroups();
        });
        this.allGroups.forEach(group => {
            //if is dynamic
            if (!group.static &&
                (group.definition.triggeredBy == undefined || group.definition.triggeredBy === event.name)) {
                const mustBeVisible = groupsToBeVisible.find(x => {
                    if (x.name && group.definition.name) {
                        return x.name === group.definition.name;
                    }
                    else {
                        return x.title === group.definition.title;
                    }
                }) != undefined;
                if (mustBeVisible) {
                    group.definition.triggeredBy = event.name;
                    group.definition.hidden = false;
                }
                else {
                    group.definition.hidden = true;
                    // group.definition.hidden = groupsToRemove
                    //   .filter(k => k.title === group.definition.title).length > 0;
                }
            }
        });
    }
    _checkViewModeForTriggering(group) {
        if (group.triggerBy.notTriggeredOnCreate) {
            return this._viewMode != 'CREATE';
        }
        else {
            return true;
        }
    }
    _checkForDynamicGroupCreation(event) {
        const dynamicGroup = this._dynamicCreateGroups.find(x => x.field == event.name);
        if (dynamicGroup && event.value) {
            setTimeout(() => {
                // the deepClone is to avoid changing value by reference
                const filter = deepClone(Array.isArray(event.value) ? event.value : event.value[0]);
                this._featuresFromCatalog.next({ dynamicGroupDef: dynamicGroup, filter: filter });
            });
        }
    }
    _configureGetFeauresFromCatalog() {
        this._subscriptions.push(this._featuresFromCatalog.pipe(switchMap((data) => {
            const dynamicGroupDef = data.dynamicGroupDef;
            const sourceCatalog = data.dynamicGroupDef.dynamicGroup.sourceCatalog;
            const filter = data.filter;
            if (dynamicGroupDef.dynamicGroup.filterByExtraValues) {
                filter.splice(0, 0, ...dynamicGroupDef.dynamicGroup.filterByExtraValues);
            }
            return forkJoin(of(dynamicGroupDef), this._catalogService.getFiltrableCatalog(sourceCatalog, filter)
                .pipe(catchError(() => of(undefined))));
        })).subscribe(data => {
            const dynamicGroupDef = data[0];
            const catalog = data[1];
            const currentGroups = this._getDynamicGroups().filter(x => x.id === dynamicGroupDef.dynamicGroup.id);
            const currentGroupFields = [].concat(...currentGroups.map(x => x.fields));
            const featuresByType = groupBy(catalog && catalog.items || [], 'productTypeInstanceCode');
            this._getDynamicGroups().forEach(group => {
                delete this._elementsValidity[group.title.toLocaleLowerCase().replace(/ /g, '_')];
            });
            this.allGroups = this.allGroups
                .filter(x => x.static || x.definition.id !== dynamicGroupDef.dynamicGroup.id);
            if (catalog) {
                Object.keys(featuresByType).forEach(prop => {
                    this._createDynamicGroup(dynamicGroupDef, featuresByType[prop], currentGroupFields);
                });
            }
        }));
    }
    _createDynamicGroup(dynamicGroupDef, features, currentGroupFields) {
        const groupedFeaturesObj = this._groupFeaturesByFeatureCategory(features);
        const groupAmount = Object.keys(groupedFeaturesObj).length;
        let defaultFeatureSection = undefined;
        let groupOrder = dynamicGroupDef.dynamicGroup.order;
        if (groupAmount > 0) {
            Object.keys(groupedFeaturesObj).forEach(key => {
                if (key === this._defaultFeatureSectionName) {
                    defaultFeatureSection = groupedFeaturesObj[key];
                }
                else {
                    this._addGroup(dynamicGroupDef, groupOrder, key, groupedFeaturesObj[key], currentGroupFields);
                    groupOrder++;
                }
            });
            if (defaultFeatureSection) {
                const lastGroupOrder = dynamicGroupDef.dynamicGroup.order + (groupAmount - 1);
                this._addGroup(dynamicGroupDef, lastGroupOrder, this._defaultFeatureSectionName, defaultFeatureSection, currentGroupFields);
            }
        }
        else {
            const productTypeName = features && features[0].productTypeInstanceName;
            const groupTitle = `${dynamicGroupDef.dynamicGroup.title} for ${productTypeName}`;
            this._addGroup(dynamicGroupDef, groupOrder, groupTitle, features, currentGroupFields);
        }
    }
    _addGroup(dynamicGroupDef, groupOrder, title, featuresGroupedByCategory, currentGroupFields) {
        const fields = [];
        const newGroup = {
            id: dynamicGroupDef.dynamicGroup.id,
            name: dynamicGroupDef.dynamicGroup.id,
            order: groupOrder,
            title: title,
            type: 'fields',
            fields: []
        };
        featuresGroupedByCategory.forEach((item, i) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const field = this._handleDynamicFieldCreation(item, dynamicGroupDef, i);
            fields.push(field);
        }));
        this._loadDynamicGroupsData(fields, currentGroupFields);
        newGroup.fields = fields;
        this.allGroups.push({ static: false, definition: newGroup });
    }
    _groupFeaturesByFeatureCategory(features) {
        const featureObj = {};
        let featureCategoryDescription;
        features.forEach(feature => {
            featureCategoryDescription = feature.featureCategory
                ? feature.featureCategory.description
                : this._defaultFeatureSectionName;
            if (Object.keys(featureObj).includes(featureCategoryDescription)) {
                featureObj[featureCategoryDescription].push(feature);
            }
            else {
                Object.assign(featureObj, { [featureCategoryDescription]: [feature] });
            }
        });
        return featureObj;
    }
    _loadDynamicGroupsData(fields, currentGroupFields) {
        fields.forEach(field => {
            const fieldName = field.field.indexOf('#') ? field.field.split('#')[1] : field.field;
            const categoryInstanceCode = field.field.indexOf('#') ? field.field.split('#')[0] : undefined;
            const featureIndex = (this._features || [])
                .findIndex(feature => {
                const filterByCategory = categoryInstanceCode != undefined && feature.categoryInstanceCode != undefined;
                return feature.featureInstanceCode === fieldName
                    && (filterByCategory ? feature.categoryInstanceCode == categoryInstanceCode : true);
            });
            let existingValue;
            if (currentGroupFields != undefined) {
                const existingField = currentGroupFields.find(x => x.field === field.field);
                existingValue = existingField && existingField.value;
            }
            if (existingValue) {
                field.value = existingValue;
            }
            else {
                if (featureIndex > -1) {
                    const value = this._features[featureIndex] && this._features[featureIndex].value;
                    this._dataModelHelper.setValueFromEntityProp(value, field);
                }
            }
        });
    }
    _revalidateFormGroups() {
        const isValid = Object.values(this._elementsValidity).every((v) => v);
        const fieldGroups = this.allGroups.map(x => x.definition);
        this.isValid.emit({ valid: isValid, value: fieldGroups });
    }
    _handleDynamicFieldCreation(item, fieldDef, index) {
        const featureType = item.featureLibraryCodeDataType;
        let desc = item.description;
        if (item.descriptions) {
            desc = item.descriptions[0].text;
        }
        switch (featureType) {
            case FeatureType.Boolean:
                return this._createBitField(item, fieldDef, desc, index);
            case FeatureType.Date:
                return this._createDateField(item, fieldDef, desc, index);
            case FeatureType.Multilang:
                return this._createTextareaField(item, fieldDef, desc, index, true);
            case FeatureType.Number:
                return this._createNumberField(item, fieldDef, desc, index);
            case FeatureType.String:
                return this._createTextareaField(item, fieldDef, desc, index, false);
            case FeatureType.FileAsset:
                return this._createFileAssetCatalog(item, fieldDef, desc, index);
            case FeatureType.FileDocument:
                return this._createFileDocumentCatalog(item, fieldDef, desc, index);
            default:
                console.error('Invalid FeatureType', featureType);
        }
    }
    _createField(item, fieldDef, desc, index) {
        return {
            field: `${item.productTypeInstanceCode}#${item.code}`,
            fieldArray: fieldDef.dynamicGroup.fieldArray,
            order: index,
            metadata: this._getHardcodedMetadataObj(item),
            attributes: {
                label: desc,
                name: `${item.productTypeInstanceCode}#${item.code}`,
                required: item.applicabilityTypeInscanceName === FieldApplicability.Required,
                editable: fieldDef.attributes.editable
            }
        };
        ;
    }
    _createNumberField(item, fieldDef, desc, index) {
        const newField = this._createField(item, fieldDef, desc, index);
        newField.type = 'input-text';
        newField.attributes.type = 'number';
        return newField;
    }
    _createDateField(item, fieldDef, desc, index) {
        const newField = this._createField(item, fieldDef, desc, index);
        newField.type = 'input-date';
        newField.attributes.minlength = 10;
        newField.attributes.pattern = '^(|(0[1-9])|(1[0-2]))\/((0[1-9])|(1\\d)|(2\\d)|(3[0-1]))\/((\\d{4}))$';
        newField.attributes.mask = '00/00/0000';
        return newField;
    }
    _createBitField(item, fieldDef, desc, index) {
        const newField = this._createField(item, fieldDef, desc, index);
        newField.type = 'catalog-single';
        newField.catalog = 'native_truefalse';
        return newField;
    }
    _createTextareaField(item, fieldDef, desc, index, translate) {
        const newField = this._createField(item, fieldDef, desc, index);
        newField.type = translate ? 'textarea-lang' : 'textarea';
        newField.catalog = translate ? CatalogEnum.Language : undefined;
        newField.translationPropCode = 'languageCode';
        newField.translationPropText = 'text';
        return newField;
    }
    _createFileAssetCatalog(item, fieldDef, desc, index) {
        const newField = this._createField(item, fieldDef, desc, index);
        newField.type = 'catalog-single';
        newField.catalog = CatalogEnum.FileAssets;
        return newField;
    }
    _createFileDocumentCatalog(item, fieldDef, desc, index) {
        const newField = this._createField(item, fieldDef, desc, index);
        newField.type = 'catalog-single';
        newField.catalog = CatalogEnum.DocumentAssets;
        return newField;
    }
    _getDynamicGroups() {
        return this.allGroups.filter(x => !x.static).map(x => x.definition);
    }
    _getHardcodedMetadataObj(item) {
        return {
            libraryCodeDataType: item.featureLibraryCodeDataType,
            externalCode: item.externalCode,
            productTypeInstanceCode: item.productTypeInstanceCode
        };
    }
    _deleteAllDynamicGenGroups() {
        this.allGroups = this.allGroups.filter(x => !x.definition.dynamicSection);
    }
}
