import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { FormDefinition } from 'src/app/models/form-field-definition.models';
import { Subscription, merge, combineLatest } from 'rxjs';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { GridInput } from '../grid/grid.model';
import { ActionButton } from '../action-bar/action-bar.component';
import { ActionBarHelperService } from 'src/app/services/helper/action-bar-helper/action-bar-helper.service';
import { GridApi, RowEvent } from 'ag-grid-community';
import { WorkeffortHandlerService } from 'src/app/services/handler/workeffort-handler/workeffort-handler.service';
import { ModalHelperService } from 'src/app/services/helper/modal-helper/modal-helper.service';
import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { BreadcrumbService } from 'src/app/services/behavior/breadcrumb/breadcrumb.service';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';
import { OperationHandlerService } from 'src/app/services/handler/operation-handler/operation-handler.service';
import { ActionBarService } from 'src/app/services/behavior/action-bar/action-bar.service';
import { UtilService } from 'src/app/services/behavior/util/util.service';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';

type PositionType = 'after' | 'before' | 'last';

export interface FormGridModel {
  title: string;
  loadingGrid: boolean;
  gridModel: GridInput;
  groupHideOpenParents: boolean;
}

@Component({
  selector: 'app-dynamic-form-grid',
  templateUrl: './dynamic-form-grid.component.html',
  styleUrls: ['./dynamic-form-grid.component.sass']
})
export class DynamicFormGridComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public config: FormDefinition;
  @Input() public detailCode: string;
  @Input() public formData: any;

  public model = {} as FormGridModel;
  public setExpanded: EventEmitter<boolean> = new EventEmitter();

  public gridActionButtons: Array<ActionButton>;
  public rowClassRules: any;

  private _availableOperations: Array<FormOperationType>;
  private _detailViewMode: FormOperationType;
  private _selectedNodes: Array<any>;
  private _dashboardItemList: Array<DashboardItem>;
  private _flowTree: Array<any> = [];
  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _gridHandler: GridHandlerService,
    private _dashboardHandler: DashboardHandlerService,
    private _actionButtonsHelper: ActionBarHelperService,
    private _modalHelper: ModalHelperService,
    private _workeffortHandler: WorkeffortHandlerService,
    private _navHelper: NavigationHelperService,
    private _breadcrumb: BreadcrumbService,
    private _operationHandler: OperationHandlerService,
    private _detailViewHandler: DetailViewHandlerService,
    private _actionBarService: ActionBarService,
    private _utils: UtilService
  ) { }

  public selectionChanged(event: any) {
    const gridApi = event.api as GridApi;
    const selectedNodes = gridApi.getSelectedNodes();

    this._selectedNodes = selectedNodes;

    // this._handleSelectionChangeBySource();
  }

  public rowDoubleClicked(event: RowEvent) {
    // if (!event.data) {
    //   return;
    // }

    // const instanceCode = event.data['instanceCode'];
    // const sequenceId = event.data['sequenceId'];
    // const parentLibraryCode = event.data['parentLibraryCode'];

    // const types = {
    //   'STG': 'stage',
    //   'STP': '',
    //   'STP.FRM': 'form',
    //   'STP.CNT': 'container'
    // };

    // const types = {
    //   'STG.TPV': 'stage',
    //   'STG.GRP': 'stage',
    //   'STP.TPV': 'step',
    //   'STP.TPV_NTF': 'step',
    //   'STP.TPV_RTY': 'step',
    //   'STP.APPROVAL': 'step',
    //   'STP.DEPLOY': 'step',
    //   'STP.FRM.TPV': 'form',
    //   'STP.CNT.ORD': 'container',
    //   'STP.CNT.PRD': 'container'
    // };

    // const workEffortTypeCode = event.data['workEffortTypeCode'];
    // if (workEffortTypeCode !== 'FLW.TPV') {
    //   const type = instanceCode.includes('QST') ? 'question' : types[workEffortTypeCode];
    //   this._goToDetail(type, { sequenceId }, instanceCode);
    // }
  }

  ngOnInit() {
    this.model.loadingGrid = true;

    this._subscriptions.push(
      this._gridHandler.getGenericGridDataAndDef$
        .subscribe(gridInput => {
          if (gridInput.source === this.config.grid.source) {
            this.model.gridModel = gridInput;
            this.model.loadingGrid = false;
            this._createFlowTree();
            setTimeout(() => {
              this._expandRowsIfNeeded();
            }, 250);
          }
        }));

    this._subscriptions.push(
      this._dashboardHandler.dashboardItemList$
        .subscribe(items => this._dashboardItemList = items)
    );

    this._subscriptions.push(
      combineLatest(
        this._operationHandler.availableOperations$,
        this._detailViewHandler.mode$
      ).subscribe(results => {
        this._availableOperations = results[0];
        this._detailViewMode = results[1];

        // this._configureActionBar();
      }));

    this._registerSubscriptionsBySource();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this.model.title = this.config.title;

      if (this.detailCode !== undefined) {
        this._initiateGrid();
      }
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _initiateGrid() {
    const source = this.config.grid.source as AgGridEnum;

    if (this.config.grid.generic) {
      this.model.loadingGrid = true;
      this._gridHandler.getGenericGridDataAndDef(source, this.detailCode);
    } else {
      this._gridHandler.getGenericGridDefAndCustomData(this.config.grid, this.detailCode);
    }

    this._setCustomConfig();
  }

  private _registerSubscriptionsBySource() {
    if (this.config.grid.source === AgGridEnum.WorkeffortHierarchy) {
      this._subscriptions.push(
        merge(
          this._workeffortHandler.stageRemoved$,
          this._workeffortHandler.stepRemoved$,
          this._workeffortHandler.formRemoved$,
          this._workeffortHandler.containerRemoved$
        ).subscribe(() => this._initiateGrid()));
    }
  }

  private _createFlowTree() {
    this._flowTree = this.model.gridModel.rows.filter(x => x.parentLibraryCode == 'STG');
    this._flowTree.forEach(stg => {
      stg.steps = this.model.gridModel.rows
        .filter(x => x.parentLibraryCode && x.parentLibraryCode.startsWith('STP')
          && x.parentWorkEffortInstanceId == stg.childWorkEffortInstanceId);
    });
  }

  private _setCustomConfig() {
    const hideOpenParents = [
      AgGridEnum.WorkeffortHierarchy,
      AgGridEnum.HierarchicalWorkeffort
    ];

    if (hideOpenParents.includes(this.config.grid.source)) {
      this.model.groupHideOpenParents = true;
      // this.rowClassRules = {
      //   "gray-row": (params: any) => {
      //     return params.data === undefined ||
      //       (params.data && params.data.parentLibraryCode === 'STG');
      //   }
      // };
    }
  }

  private _expandRowsIfNeeded() {
    const autoExpand = [
      AgGridEnum.WorkeffortHierarchy,
      AgGridEnum.HierarchicalWorkeffort
    ];

    if (autoExpand.includes(this.config.grid.source)) {
      setTimeout(() => {
        this.setExpanded.emit(true);
      });
    }
  }

}
