<input #input="ngModel" [attr.id]="attrs?.id" [attr.name]="attrs?.name" [attr.type]="attrs?.type" [attr.class]="attrs?.class"
  [attr.placeholder]="attrs?.placeholder | translate" [attr.autocomplete]="attrs?.autocomplete || autocompleteDefault"
  minlength="{{attrs?.minlength}}" [attr.maxlength]="attrs?.maxlength" [attr.patternError]="msgErrorPattern" [pattern]="attrs?.pattern"
  (ngModelChange)='onChangeCallback($event)' (input)="applyRegex(input)" (blur)="trim(input)" (keydown)="validateInput(input)"
  (keyup)="checkValidity(input)" [(ngModel)]="innerValue" required="{{attrs?.required}}" disabled="{{attrs?.disabled}}"
  mask="{{attrs?.mask}}" [dropSpecialCharacters]="attrs?.dropSpecialCharacters" [showMaskTyped]="attrs?.showMaskTyped"
  prefix="{{attrs?.maskPrefix}}">

<span *ngIf="attrs?.showValidationIcons">
  <i class="fas fa-asterisk text-muted" *ngIf="input.pristine"></i>
  <i class="fas fa-times text-danger" *ngIf="(!input.pristine && input.errors) || !isFnValid"></i>
  <i class="fas fa-check text-success" *ngIf="(!input.pristine && !input.errors) && isFnValid"></i>
</span>

<!-- This need to be fixed using some priority order for errors -->
<div class="input-error" *ngIf="!input.pristine && input.errors?.required" translate>{{msgErrorRequired}}</div>
<div class="input-error" *ngIf="!input.pristine && input.errors?.minlength" translate [translateParams]="{c: attrs?.minlength}">{{msgErrorMinlength}}</div>
<div class="input-error" *ngIf="!(!input.pristine && input.errors?.minlength) && !input.pristine && (input.errors?.pattern)"
  translate>{{msgErrorPattern}}</div>
<div class="input-error" *ngIf="!(!input.pristine && input.errors) && !input.pristine && !isFnValid" translate>{{msgErrorFunction}}</div>