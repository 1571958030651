<!-- <ng-container *ngIf="instanceCode"> -->
<!-- <div class="grid-can-be-disabled" [ngClass]="{ 'grid-disabled': model.gridOverlay }"> -->
<!-- <div class="wrapper"></div> -->

<ng-container *ngIf="model.editMode">
	<div class="btn-grid">
		<app-action-bar [code]="model.actionBarUid" [config]="model.actionButtons">
		</app-action-bar>
	</div>
</ng-container>

<app-ghost-loading *ngIf="model.loadingGrid" [type]="'aggrid'"></app-ghost-loading>
<div class="grid grid-70 mt-2" *ngIf="!model.loadingGrid">
	<app-grid *ngIf="!model.showServerSideGrid" [data]="model.gridModel" (selectionChanged)="selectionChanged($event)" (gridApi)="setGridApi($event)"
		(rowDoubleClicked)="rowDoubleClicked($event)" (rowDragEnd)="onRowDragEnd($event)">
	</app-grid>
	<app-grid-pagination *ngIf="model.showServerSideGrid" (selectionChanged)="selectionChanged($event)" (gridApi)="setGridApi($event)"
		(rowDoubleClicked)="rowDoubleClicked($event)" (rowDragEnd)="onRowDragEnd($event)"
		(gridDataLoaded)="onGridDataLoaded($event)" [instanceCode]="model.detailCode"
		[relationshipScope]="definition.relationshipScope" [dashboardItem]="model.dashboardItem">
	</app-grid-pagination>
</div>
<!-- </div> -->
<!-- </ng-container> -->