import { OnInit, forwardRef, OnChanges, SimpleChanges, OnDestroy, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { map, filter } from 'rxjs/operators';
import { CacheService } from 'ngx-emerios-all';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { ActionBarHelperService } from 'src/app/services/helper/action-bar-helper/action-bar-helper.service';
import { AggridColumnHelperService } from 'src/app/services/helper/aggrid-column-helper/aggrid-column-helper.service';
import { sortBy } from 'src/app/functions/sortBy';
import { FormOperationType } from 'src/app/models/operation.models';
const noOp = () => { };
const ɵ0 = noOp;
export const TABBED_RULE_CREATOR_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TabbedRuleCreatorComponent),
    multi: true
};
export class TabbedRuleCreatorComponent {
    constructor(_gridHandler, _actionBarHelper, _detailViewHandler, _catalog, _elementHelper, _aggridColumnHelper, _fieldValueHandler, _cache) {
        this._gridHandler = _gridHandler;
        this._actionBarHelper = _actionBarHelper;
        this._detailViewHandler = _detailViewHandler;
        this._catalog = _catalog;
        this._elementHelper = _elementHelper;
        this._aggridColumnHelper = _aggridColumnHelper;
        this._fieldValueHandler = _fieldValueHandler;
        this._cache = _cache;
        this.model = {};
        this.actionBarConfig = [];
        this.tabs = [];
        this.internalModel = [];
        this.tabIndexUpdater = new EventEmitter();
        this.multiselectConfig = [];
        this.multiselectItems = [];
        this._subscriptions = [];
        this.onTouchedCallback = noOp;
        this.model.loadingGrid = [];
        this.model.gridModel = [];
        this.model.gridApi = [];
    }
    writeValue(obj) {
        if (obj) {
            this._prepareInternalModel(obj);
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    setGridApi(gridApi, index) {
        this.model.gridApi[index] = gridApi;
        // this._recalculateSortIdColumn();
    }
    isValid(event) {
        const internalModel = this.internalModel[this.currentTabIndex];
        internalModel.itemSelection = event.value;
        this._validateInternalModel();
    }
    chainedFilterChanged(event) {
        if (this.config.tabbedRuleCreator.outcome.type !== 'catalog-multiple') {
            return;
        }
        if (this.config.tabbedRuleCreator.outcome.field && this.config.tabbedRuleCreator.outcome.field.triggerValueProperty) {
            const catalog = this.config.tabbedRuleCreator.outcome.field.catalog;
            const value = event && event[this.config.tabbedRuleCreator.outcome.field.triggerValueProperty];
            if (value) {
                this._catalog.getSingleWithFilterFromServer(catalog, value)
                    .subscribe(catalog => {
                    if (catalog && catalog.items) {
                        this.multiselectItems[this.currentTabIndex] = this._catalog.getSimpleCatalogDescriptionObject(catalog.items);
                    }
                });
            }
            else {
                this.multiselectItems[this.currentTabIndex] = [];
            }
        }
    }
    showActionBar() {
        return this.config && this.config.tabbedRuleCreator.allowAddOrRemoveTabs;
    }
    showTabs() {
        return this.config && this.config.tabbedRuleCreator.showTabs;
    }
    renderOutcome(type) {
        return this.config && this.config.tabbedRuleCreator.outcome.type === type;
    }
    actionFired(action) {
        // nothing yet
    }
    tabClicked(tabIndex) {
        this.currentTabIndex = tabIndex;
        this._initiateOutcome(tabIndex);
    }
    onRowDragEnd(event) {
        this._recalculateSortIdColumn();
    }
    getGridModel() {
        return this.model.gridModel[this.currentTabIndex];
    }
    getControlErrors() {
        let errorMessage;
        if (this.control.errors && this.control.errors.required) {
            errorMessage = 'This is required';
        }
        return errorMessage;
    }
    ngOnInit() {
        this._subscriptions.push(this._detailViewHandler.mode$
            .subscribe(mode => {
            this._viewMode = mode;
            this._configureButtons();
        }));
        this._subscriptions.push(this._fieldValueHandler.ownerPartyRoleValue$
            .subscribe(code => this._ownerPartyRoleInstanceCode = code));
        this._subscriptions.push(this._fieldValueHandler.anyValue$
            .subscribe(anyValue => this._handleExternalValues(anyValue)));
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            this._createEmptyForm();
            this._createGridSubscription();
            this.model.selectorTitle = this.config.tabbedRuleCreator.selectorTitle;
            this.model.outcomeTitle = this.config.tabbedRuleCreator.outcomeTitle;
            if (this.config.attributes.required) {
                this.control = new FormControl(undefined, Validators.required);
            }
            else {
                this.control = new FormControl(undefined);
            }
            if (!this.config.value || this.config.value.length === 0) {
                this._addTab(true);
            }
        }
        if (changes.forceValidation && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => {
                this.control.markAsDirty();
                this.control.markAsTouched();
                this.control.updateValueAndValidity();
            }));
        }
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }
    _handleExternalValues(anyValue) {
        Object.keys(this.config.externalFields).forEach(prop => {
            if (this.config.externalFields['ruleTypeCode']) {
                const ruleTypeCode = anyValue.changes.get(this.config.externalFields['ruleTypeCode'])[0];
                this._workEffortTypeCode = ruleTypeCode.code;
                this.internalModel.forEach(x => x.ruleTypeCode = ruleTypeCode.ruleTypeLibraryCode);
            }
        });
    }
    _createGridSubscription() {
        if (this.config.tabbedRuleCreator.outcome.type === 'grid') {
            this._subscriptions.push(this._gridHandler.getGenericGridDataAndDef$
                .pipe(filter(x => x.source === this._gridSource))
                .subscribe(gridInput => {
                this._createDragAndCheckboxColumns(gridInput);
                this._initializeRowSelectionAndSorting(gridInput);
                this.model.gridModel[this.currentTabIndex] = gridInput;
                this.model.loadingGrid[this.currentTabIndex] = false;
                setTimeout(() => {
                    this._orderCurrentRows();
                }, 50);
            }));
        }
    }
    _createEmptyForm() {
        if (!this.form) {
            this.form = new FormGroup({});
            this._subscriptions.push(this.form.valueChanges
                .subscribe(values => {
                this._updateValues(values);
                this._validateInternalModel();
            }));
        }
    }
    _initiateOutcome(index, model) {
        if (this.config.tabbedRuleCreator.outcome.type === 'grid') {
            this._initiateGrid();
        }
        else if (this.config.tabbedRuleCreator.outcome.type === 'catalog-multiple') {
            this._initiateMultiselect(index, model ? model.itemSelection : undefined);
        }
    }
    _initiateGrid() {
        if (!this.model.gridModel[this.currentTabIndex]) {
            this._gridSource = this.config.tabbedRuleCreator.outcome.grid.source;
            this.model.loadingGrid[this.currentTabIndex] = true;
            if (!this._workEffortTypeCode) {
                var instanceCode = this._fieldValueHandler.getDetailCode();
                var indexOf = instanceCode.lastIndexOf('.');
                this._workEffortTypeCode = instanceCode.substring(0, indexOf);
            }
            this._gridHandler.getQuestionRulesGridDataAndDef(this._gridSource, this._workEffortTypeCode, this._ownerPartyRoleInstanceCode);
        }
    }
    _initiateMultiselect(index, items) {
        this.multiselectDef = this.config.tabbedRuleCreator.outcome.field;
        if (!this.multiselectConfig[index]) {
            let multiselectConfig = this._elementHelper.getMultiSelectConfig(this.multiselectDef.attributes, []);
            multiselectConfig.readonly = !this.config.attributes.editable;
            if (this.multiselectDef.type === 'catalog-single') {
                multiselectConfig.singleSelect = true;
            }
            if (!this.multiselectDef.filterBy) {
                this._catalog.getFromServer([this.multiselectDef.catalog])
                    .pipe(map(x => x[0]))
                    .subscribe(catalog => {
                    if (catalog && catalog.items) {
                        this.multiselectItems[index] = this._catalog.getSimpleCatalogDescriptionObject(catalog.items);
                        ;
                    }
                });
            }
            this.multiselectConfig.push(multiselectConfig);
            this._createFormControl(index, 'multiselect', items);
        }
    }
    _recalculateSortIdColumn() {
        const internalModel = this.internalModel[this.currentTabIndex];
        const gridApi = this.model.gridApi[this.currentTabIndex];
        let orderedRows = [];
        let cnt = 1;
        gridApi.forEachNode(node => {
            node.data.rowSelected
                ? node.data.sortId = cnt++
                : node.data.sortId = '-';
            orderedRows.push(node.data);
            const selection = internalModel.gridSelection.find(x => x.instanceCode === node.data.instanceCode);
            if (selection) {
                selection.order = node.data.sortId;
            }
        });
        orderedRows = sortBy(orderedRows, [
            { prop: 'rowSelected', direction: 'desc' },
            { prop: 'sortId', direction: 'asc' }
        ]);
        gridApi.setRowData(orderedRows);
        this.model.gridModel[this.currentTabIndex].rows = orderedRows;
    }
    _orderCurrentRows() {
        const gridApi = this.model.gridApi[this.currentTabIndex];
        const gridModel = this.model.gridModel[this.currentTabIndex];
        if (gridModel && gridApi) {
            let orderedRows = sortBy(gridModel.rows, [
                { prop: 'rowSelected', direction: 'desc' },
                { prop: 'sortId', direction: 'asc' }
            ]);
            orderedRows.forEach(x => {
                if (!x.rowSelected) {
                    x.sortId = '-';
                }
            });
            gridApi.setRowData(orderedRows);
        }
    }
    _configureButtons() {
        const buttons = this._actionBarHelper.getTabbedRuleComponentButtons();
        const newBtn = buttons.find(x => x.code === 'new');
        const removeBtn = buttons.find(x => x.code === 'remove');
        newBtn.enabled = this._viewMode !== FormOperationType.View;
        newBtn.callback = () => { this._addTab(); };
        removeBtn.enabled = this._viewMode !== FormOperationType.View;
        removeBtn.callback = () => { this._removeCurrentTab(); };
        this.actionBarConfig = buttons;
    }
    _enableRowDrag(instanceCode) {
        const internalModel = this.internalModel[this.currentTabIndex];
        if (this._viewMode !== FormOperationType.View) {
            return internalModel && internalModel.gridSelection.find(x => x.instanceCode === instanceCode);
        }
        else {
            return false;
        }
    }
    _simulateCheckboxClick(params) {
        params.event.srcElement.children[0].click();
    }
    _createRowCheckbox(params) {
        const input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.disabled = this._viewMode === FormOperationType.View;
        input.addEventListener('click', (event) => {
            const internalModel = this.internalModel[this.currentTabIndex];
            const instanceCode = params.node.data.instanceCode;
            params.value = event.srcElement.checked;
            params.node.data.rowSelected = params.value;
            params.value
                ? this._addGridSelection(internalModel, instanceCode)
                : this._removeGridSelection(internalModel, instanceCode);
            this._recalculateSortIdColumn();
        });
        return input;
    }
    _createDragAndCheckboxColumns(gridInput) {
        const columns = this._aggridColumnHelper
            .getTabbedRuleCreatorCustomColumns((params) => this._enableRowDrag(params.node.data.instanceCode), (params) => this._simulateCheckboxClick(params), (params) => this._createRowCheckbox(params));
        gridInput.columns.splice(0, 0, ...columns);
    }
    _initializeRowSelectionAndSorting(gridInput) {
        gridInput.rows
            .forEach(row => {
            const internalModel = this.internalModel[this.currentTabIndex];
            const rowSelected = internalModel.gridSelection
                .find(x => x.instanceCode === row.instanceCode);
            row.rowSelected = rowSelected !== undefined;
            row.sortId = (rowSelected && rowSelected.order) || -1;
        });
    }
    _addTab(onlyIfEmpty = false) {
        if (onlyIfEmpty && this.tabs.length > 0) {
            return;
        }
        const tab = this._createTab();
        this.internalModel.push({ ruleName: tab.title, gridSelection: [] });
        this.currentTabIndex = this.tabs.length - 1;
        this._createFormControl(this.currentTabIndex, 'chainedfilter');
        this._initiateOutcome(this.currentTabIndex);
        this._validateInternalModel();
    }
    _preloadTab(index, model) {
        const tab = this._createTab();
        this.internalModel.push(model);
        this._createFormControl(index, 'chainedfilter', model.chainedFilter);
        return tab;
    }
    _removeCurrentTab() {
        if (this.currentTabIndex >= 0) {
            const tabCode = this.tabs[this.currentTabIndex].code;
            this.form.removeControl(`chainedfilter-${tabCode}`);
            this.form.removeControl(`multiselect-${tabCode}`);
            this.internalModel.splice(this.currentTabIndex, 1);
            this.model.gridModel.splice(this.currentTabIndex, 1);
            this.model.loadingGrid.splice(this.currentTabIndex, 1);
        }
        this.tabs.splice(this.currentTabIndex, 1);
        if (this.currentTabIndex > 0) {
            this.currentTabIndex--;
        }
        this._validateInternalModel();
    }
    _createTab(title) {
        const tabCount = this.tabs.length;
        const tabTplName = title || `${this.config.tabbedRuleCreator.tabTemplateName} ${tabCount + 1}`;
        const fieldDef = {
            field: this.config.field,
            attributes: { editable: this.config.attributes.editable },
            chainedFilterDefinition: this.config.tabbedRuleCreator.chainedFilterDefinition
        };
        const tab = {
            code: this._createTabCode(),
            title: tabTplName,
            chainerFilterConfig: fieldDef,
            gridConfig: [{ grid: this.config.tabbedRuleCreator.outcome.grid }]
        };
        this.tabs.push(tab);
        return tab;
    }
    _createFormControl(index, type, value) {
        const tab = this.tabs[index];
        const controlName = `${type}-${tab.code}`;
        this.form.addControl(controlName, new FormControl(value, Validators.required));
    }
    _updateValues(values) {
        Object.keys(values).forEach(prop => {
            const type = prop.split('-')[0];
            const tabCode = prop.split(`${type}-`)[1];
            const index = this.tabs.findIndex(x => x.code === tabCode);
            const value = values[prop];
            if (type === 'chainedfilter') {
                this.internalModel[index].chainedFilter = value;
            }
            else if (type === 'multiselect') {
                this.internalModel[index].itemSelection = value;
            }
        });
    }
    _addGridSelection(internalModel, instanceCode) {
        if (!internalModel.gridSelection.find(x => x.instanceCode === instanceCode)) {
            internalModel.gridSelection.push({ instanceCode });
            this._validateInternalModel();
        }
    }
    _removeGridSelection(internalModel, instanceCode) {
        const indexOf = internalModel.gridSelection.findIndex(x => x.instanceCode === instanceCode);
        if (indexOf > -1) {
            internalModel.gridSelection.splice(indexOf, 1);
            this._validateInternalModel();
        }
    }
    _validateInternalModel() {
        let isValid = this.internalModel.length > 0;
        if (isValid) {
            this.internalModel.forEach(x => {
                // use configuration for this?
                // isValid = isValid && (x.chainedFilter !== undefined);
                if (this.config.tabbedRuleCreator.outcome.type === 'grid') {
                    isValid = isValid && x.gridSelection && x.gridSelection.length > 0;
                }
                else if (this.config.tabbedRuleCreator.outcome.type === 'catalog-multiple') {
                    isValid = isValid && x.itemSelection && x.itemSelection.length > 0;
                }
            });
        }
        this._updateInternalModel(isValid);
    }
    _updateInternalModel(valid) {
        if (this._onChange) {
            this.internalModel.forEach(x => x.ownerPartyRoleInstanceCode = this._ownerPartyRoleInstanceCode);
            this.control.setValue(valid ? this.internalModel : undefined);
            this._onChange(this.control.value);
        }
    }
    _prepareInternalModel(obj) {
        if (obj) {
            this.currentTabIndex = 0;
            obj.forEach((x, index) => {
                this._preloadTab(index, x);
                this._initiateOutcome(index, x);
            });
        }
        else {
            this.internalModel = [];
            this._addTab(true);
        }
    }
    _createTabCode() {
        return `${this.config.attributes.name}-${this.tabs.length}`;
    }
}
export { ɵ0 };
