import { OnInit, EventEmitter, OnChanges, SimpleChanges, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormGroup, Validators } from '@angular/forms';
const noOp = () => { };
const ɵ0 = noOp;
export const CUSTOM_INPUTDP_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputLangComponent),
    multi: true
};
export class InputLangComponent {
    constructor() {
        this.isValid = new EventEmitter();
        this.onActionButtonClick = new EventEmitter();
        this.onItemSelect = new EventEmitter();
        this.model = {};
        this._innerModel = {};
        this._subscriptions = [];
        this.onTouchedCallback = noOp;
    }
    writeValue(obj) {
        if (obj != this.control.value) {
            this._innerModel = obj;
            this._setControlValue();
            this._preselectedLangCode = Object.keys(this._innerModel)[0];
            if (this._preselectedLangCode) {
                let valueObj = {
                    langCode: [this._preselectedLangCode],
                    inputText: '',
                    textareaText: ''
                };
                valueObj[this._getInputProp()] = this._innerModel[this._preselectedLangCode] || '';
                this.form.setValue(valueObj);
            }
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    actionButtonClicked(event) {
        if (this.onActionButtonClick) {
            this.onActionButtonClick.emit(event);
        }
    }
    onMultiselectItemChecked(event) {
        if (this.type === 'input') {
            this.inputConfig.readonly = this.config.readonly;
        }
        else {
            this.textareaConfig.readonly = this.config.readonly;
        }
        if (this.onItemSelect) {
            this.onItemSelect.emit(event);
        }
        this._updateButtonText(event.item);
        this._setControlValue();
        const textValue = this._innerModel[this.form.controls['langCode'].value[0]];
        this.form.controls[this._getInputProp()].setValue(textValue);
    }
    inputValueChanged(event) {
        this._innerModel[this.form.controls['langCode'].value[0]] = this.form.controls[this._getInputProp()].value;
        this._setControlValue();
        this.control.markAsDirty();
    }
    getInputLangErrors() {
        let errorMessage;
        if (this.control.errors) {
            const errors = Object['keys'](this.control.errors);
            errors.forEach(error => errorMessage = this.config.validationMessages && this.config.validationMessages[error] || '');
        }
        return errorMessage;
    }
    ngOnInit() { }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            this._initializeComponents();
        }
        if (changes.forceValidation && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => {
                this.control.markAsDirty();
                this.control.markAsTouched();
                this.control.updateValueAndValidity();
            }));
        }
        if (changes.items && changes.items.currentValue) {
            this.multiselectItems = this.items;
            let preselectedLangItem;
            if (this._preselectedLangCode) {
                preselectedLangItem = this.items.find(x => x.code === this._preselectedLangCode);
            }
            else {
                preselectedLangItem = this.items[0];
                this._setSelectedLang(this.items[0].code);
            }
            this._updateButtonText(preselectedLangItem);
        }
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }
    _initializeComponents() {
        this._initializeFormControl();
        this._initializeInput();
        this._initializeMultiselect();
        if (this.config.validators) {
            setTimeout(() => {
                this.showInputRequiredIcon = (this.config.validationMessages && this.config.validationMessages.required !== undefined);
                this.control.setValidators(this.config.validators);
            });
        }
        this._createFormGroupObject();
        this._setSelectedLang(this.config.items && this.config.items[0].code);
        if (this.inputConfig) {
            this.inputConfig.readonly = this.config.readonly;
        }
        if (this.textareaConfig) {
            this.textareaConfig.readonly = this.config.readonly;
        }
        this.multiselectConfig.readonly = this.config.readonly;
    }
    _initializeFormControl() {
        let isRequired = false;
        if (this.config.validators) {
            this.config.validators.forEach(x => {
                isRequired = !isRequired && x.name === 'required';
            });
        }
        if (isRequired) {
            this.control = new FormControl(undefined, Validators.required);
        }
        else {
            this.control = new FormControl(undefined);
        }
    }
    _initializeInput() {
        const config = {};
        config.id = `input_${this.config.id}`;
        config.name = `input_${this.config.name}`;
        config.type = this.config.type;
        config.cssClasses = this.config.cssClasses;
        config.placeholder = this.config.placeholder;
        config.autocomplete = this.config.autocomplete;
        config.disabled = this.config.disabled;
        config.regexOnInput = this.config.regexOnInput;
        config.errorMsgClass = this.config.errorMsgClass;
        config.validators = [];
        // config.validationIcons = this.config.validationIcons;
        // config.validationMessages = this.config.validationMessages;
        if (this.type === 'input') {
            this.inputConfig = config;
            this.inputConfig.mask = this.config.mask;
        }
        else if (this.type === 'textarea') {
            this.textareaConfig = config;
        }
    }
    _initializeMultiselect() {
        this.multiselectConfig = {};
        this.multiselectConfig.id = `multiselect_${this.config.id}`;
        this.multiselectConfig.name = `multiselect_${this.config.name}`;
        this.multiselectConfig.cssClasses = 'form-control';
        this.multiselectConfig.itemCode = this.config.itemCode;
        this.multiselectConfig.itemText = this.config.itemText;
        this.multiselectConfig.singleSelect = true;
        this.multiselectConfig.itemsShowLimit = 0;
        this.multiselectConfig.placeholder = '--';
        this.multiselectConfig.validators = [Validators.required];
        this.multiselectConfig.validationMessages = { required: '' };
        this.multiselectItems = this.config.items;
    }
    _setSelectedLang(langCode) {
        if (langCode) {
            this.form.controls['langCode'].setValue([langCode]);
        }
    }
    _getInputProp() {
        return this.type === 'input' ? 'inputText' : 'textareaText';
    }
    _createFormGroupObject() {
        this.form = new FormGroup({
            langCode: new FormControl(undefined, this.config.validators),
            inputText: new FormControl(undefined, this.config.validators),
            textareaText: new FormControl(undefined, this.config.validators)
        });
    }
    _updateButtonText(event) {
        const propToShow = this.config.propToShow ? this.config[this.config.propToShow] : this.config['itemText'];
        this.model.selectedText = event[propToShow];
    }
    _isValueValid() {
        let someValid = false;
        Object.keys(this._innerModel)
            .forEach(prop => {
            if (this._innerModel[prop] && this._innerModel[prop].length > 0) {
                someValid = true;
            }
        });
        return someValid;
    }
    _setControlValue() {
        const isValid = this._isValueValid();
        let newValue = this._innerModel;
        if (!isValid) {
            newValue = undefined;
        }
        this.control.setValue(newValue);
        if (this._onChange) {
            this._onChange(newValue);
        }
        this._emitValidity();
    }
    _emitValidity() {
        const isValid = this.control.status == 'VALID';
        this.isValid.emit({
            name: this.config.name,
            value: this.control.value,
            valid: isValid
        });
    }
}
export { ɵ0 };
