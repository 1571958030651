export interface ComparerItem {
    EntityName: string;
    SourceCount: number;
    TargetCount: number;
    Percentage: string;
    Status: string;
    ChangeRequestId: string;
    EntityLibraryId: string;
    Errors: Array<string>;
}

export interface JobExtendedStatusItem {
    EntityName: string;
    StatusCode: string;
    ChangeRequestId?: string;
    InstanceCode?: string;
    EntityLibraryId: string;
}

export interface EventItem {
    JobEventTypeCode: string;
    CreationDatetime: string;
    Message: string;
}

export interface BackgroundTaskDetailModel {
    Items: Array<ComparerItem>;
    Events: Array<EventItem>;
    StartDate: string;
    StartDateUtc: string;
    User: string;
    LoadingTitle: string;
    PercentageProcessed: string;
    ClonedCount: number;
    TotalCount: number;
    JobStatus: JobStatus;
    JobName: JobName;
    ErrorMessage: string;
    RequestName: string;
    RequestCode: string;
    SuccededCount: number;
    FailedCount: number;
}

export enum JobStatus {
    InProgress = 'In Progress',
    Completed = 'Success',
    Failed = 'Failed',
    CompletedWithErrors = 'Completed with errors'
}

export enum JobName {
    CloneTenant = 'Clone Tenant',
    Deploy = 'Deploy Change Request',
    Promote = 'Promote Request',
    ProcessBook = 'Process Book',
    CreateAuditForm = 'Create Audit Form',
    CreateFlowAuditForm = 'Create Flow Audit Form',
    RunIntegrationCommand = 'Run Integration Command Request',
}

export interface JobExecutionDto {
    JobExecutionUid: string;
    JobDefinition: string;
    JobMetadata: any;
    Result: any;
    ErrorMessage: string;
    Status: string;
    StartDatetime: string;
    EndDatetime: string;
    ElapsedTime: string;
    RowCountSucceeded: number;
    RowCountFailed: number;
    RowCountExpected: number;
    UserName: string;
    Events: Array<any>;
    Percentage: number;
    JobName: string;
    Owner: string;
    Code: string;
}
