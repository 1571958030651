import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';


export interface TableComponentmodel {
  showPagination: boolean;
  tableData: Array<any>;
  totalItems: number;
  itemsPerPage: number;
  itemsPerPageDefault: number;
  maxSize: number;
  maxSizeDefault: number;
  paginatedArray: Array<string>;
}
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass']
})
export class TableComponent implements OnChanges {
  @Input() public header: Array<any>;
  @Input() public content: Array<any>;
  @Input() public itemsPerPage: number;
  @Input() public maxSize: number;

  public model = { tableData: [], paginatedArray: [], maxSizeDefault: 10, itemsPerPageDefault: 10 } as TableComponentmodel;

  constructor() { }

  public pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.model.paginatedArray = this.model.tableData.slice(startItem, endItem);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.header && changes.content) {
      if (changes.header.currentValue && changes.content.currentValue) {
        this._setInitialValues();
      }
    }
  }

  private _setInitialValues() {
    this.model.tableData = this.content;
    this.model.totalItems = this.content.length;
    this.model.itemsPerPage = this.itemsPerPage ? this.itemsPerPage : this.model.itemsPerPageDefault
    this.model.maxSize = this.maxSize ? this.maxSize : this.model.maxSizeDefault;
    this.model.paginatedArray = this.content.slice(0, this.model.itemsPerPage);
    this.model.showPagination = this.model.totalItems > this.model.itemsPerPage;
  }
}
