import * as i0 from "@angular/core";
export class SortableListDataModelService {
    constructor() { }
    setValueToField(payloadValue) {
        return payloadValue;
    }
    getValueFromField(fieldValue) {
        if (fieldValue) {
            fieldValue.forEach(x => {
                // const catalogSingleElements = x.elementList.filter(e => e.type === 'catalog-single').map(y => y.field);
                x.elementList.forEach(e => {
                    if (e.type === 'catalog-single') {
                        x[e.field] = x[e.field][0];
                    }
                    else if (e.type === 'textarea-lang') {
                        x[e.field] = this._getValue(x[e.field], e);
                    }
                });
                delete x.elementList;
            });
            return fieldValue;
        }
    }
    _getValue(fieldValue, element) {
        const value = [];
        if (fieldValue) {
            Object.keys(fieldValue)
                .forEach(langCode => {
                const translation = {};
                translation[element.translationPropCode] = langCode;
                translation[element.translationPropText] = fieldValue[langCode];
                value.push(translation);
            });
        }
        return value;
    }
}
SortableListDataModelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SortableListDataModelService_Factory() { return new SortableListDataModelService(); }, token: SortableListDataModelService, providedIn: "root" });
