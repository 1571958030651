import { OnInit, OnChanges, OnDestroy, SimpleChanges, forwardRef, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { ModalService } from 'src/app/services/behavior/modal/modal.service';
import { DynamicFormPreviewComponent } from 'src/app/modals/dynamic-form-preview/dynamic-form-preview.component';
const noOp = () => { };
const ɵ0 = noOp;
export const CUSTOM_TEXTAREA_FORM_PREVIEW_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextareaFormPreviewComponent),
    multi: true
};
export class TextareaFormPreviewComponent {
    constructor(_modal) {
        this._modal = _modal;
        this.isValid = new EventEmitter();
        this.model = {};
        this._subscriptions = [];
        this.onTouchedCallback = noOp;
    }
    writeValue(obj) {
        if (obj != this.control.value) {
            this.internalValue = obj;
            this._updateInnerValue();
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    actionButtonClicked(event) {
        const params = {
            definition: [JSON.parse(this.control.value)]
        };
        this._modal.showCustom(DynamicFormPreviewComponent, { size: 'xl', params: params });
    }
    getControlErrors() {
        let errorMessage;
        if (this.control.errors && this.control.errors.required) {
            errorMessage = 'This is required';
        }
        return errorMessage;
    }
    inputValueChanged(event) {
        this.control.markAsDirty();
        this._updateInnerValue();
    }
    ngOnInit() {
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            this._initializeFormControl();
            this._configureComponent();
            this._updateInnerValue();
        }
        if (changes.forceValidation && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => {
                this.control.markAsDirty();
                this.control.markAsTouched();
                this.control.updateValueAndValidity();
            }));
        }
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }
    _initializeFormControl() {
        this.control = new FormControl(undefined);
        if (this.config.validators) {
            const validators = this.config.validators;
            this.model.validationMessages = this.config.validationMessages;
            this.config.validators = undefined;
            this.model.validationMessages = undefined;
            setTimeout(() => {
                this.model.showInputRequiredIcon = (this.model.validationMessages && this.model.validationMessages.required !== undefined);
                this.control.setValidators(validators);
            });
        }
    }
    _configureComponent() {
    }
    _updateInnerValue() {
        this.control.setValue(this.internalValue);
        if (this._onChange) {
            this._onChange(this.control.value);
        }
        this._emitValidity();
    }
    _emitValidity() {
        const isValid = this.control.status == 'VALID';
        this.isValid.emit({
            name: this.config.name,
            value: this.control.value,
            valid: isValid
        });
    }
}
export { ɵ0 };
