<div class="image-container set-full-height campaign-builder" [ngClass]="getClassConfiguration()">

	<app-page-header class="cp-page-header"></app-page-header>

	<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate-multiple">
		<p style="font-size: 20px; color: white">Please wait a moment...</p>
	</ngx-spinner>

	<div class="main-content">
		<div [hidden]="!hideRouter"></div>
		<div [hidden]="hideRouter">
			<!-- <div class="action-bar-full-grid">
				<a href="javascript:void(0)" class="btn btn-fill btn-icon ml-1 d-inline-block" (click)="toggleExpand()">
					<i class="fas fa-expand-arrows-alt"></i>
				</a>
			</div> -->
			<router-outlet></router-outlet>
		</div>
	</div>

	<!-- <app-page-footer [options]="siteMetadata?.pageFooter"></app-page-footer> -->

</div>