<div>{{config.attributes.label}}</div>

<div #dragableList cdkDropList class="question-list" (cdkDropListDropped)="drop($event)">
	<ng-container *ngFor="let dataItem of internalItems; let i = index">
		<div class="example-box campaign-builder" cdkDragBoundary=".question-list" cdkDragLockAxis="y" cdkDrag
			[cdkDragDisabled]="!config.attributes.editable">
			<div class="row my-1">
				<div class="question-delete" *ngIf="config.attributes.editable && internalItems.length > 1">
					<a href="javascript:void(0)" (click)="removeElement(i)">
						<i class="fas fa-times-circle fa-2x"></i>
					</a>
				</div>
				<div class="example-handle" cdkDragHandle
					*ngIf="config.attributes.editable && internalItems.length > 1">
					<div>
						<i class="fas fa-bars fa-lg fa-2x"></i>
					</div>
				</div>

				<!-- <div [formGroup]="form"> -->
				<div class="col-md-{{def.width}}" *ngFor="let def of dataItem.elementList">

					<ng-container *ngIf="isInputText(def.type)">
						<label class="app-input">
							<emerios-input [config]="getElementConfig(def)" [(ngModel)]="dataItem[def.field]"
								(is-valid)="isElementValid($event, i)" (change)="updatePartList(def.field, i)">
							</emerios-input>
						</label>
					</ng-container>

					<ng-container *ngIf="isCatalogSingle(def)">
						<label class="app-input emerios-multiselect">
							<emerios-multiselect [config]="getElementConfig(def)" [(ngModel)]="dataItem[def.field]"
								[items]="elementConfig[def.field].items" (change)="updateTriggerable(def.field, i)"
								(is-valid)="isElementValid($event, i)">
							</emerios-multiselect>
						</label>
					</ng-container>

					<ng-container *ngIf="isInputLang(def.type)">
						<label class="app-input app-input-lang">
							<app-input-lang [config]="getElementConfig(def)" [type]="'input'"
								[items]="elementConfig[def.field].items" [(ngModel)]="dataItem[def.field]"
								(is-valid)="isElementValid($event, i)"></app-input-lang>
						</label>
					</ng-container>

					<ng-container *ngIf="isTextAreaLang(def.type)">
						<label class="app-input app-textarea-lang sortable-list">
							<app-input-lang [config]="getElementConfig(def)" [type]="'textarea'"
								[items]="elementConfig[def.field].items" [(ngModel)]="dataItem[def.field]"
								(is-valid)="isElementValid($event, i)" (change)="updatePartList(def.field, i)"
								appExpandLongText>
							</app-input-lang>
						</label>
					</ng-container>

					<ng-container *ngIf="isCheckbox(def.type)">
						<div class="campaign-checkbox">
							<div class="content-check">
								<div class="checkbox text-check-left">
									<input type="checkbox" id="sortable-list{{i}}" [(ngModel)]="dataItem[def.field]"
										(ngModelChange)="isElementValid($event, i)" class="question-check"
										[ngClass]="{'disabled':!config.attributes.editable}"
										[disabled]="!config.attributes.editable">
									<label for="sortable-list{{i}}"> {{def.text}}</label>
								</div>
							</div>
						</div>
					</ng-container>

				</div>
				<!-- </div> -->
			</div>
		</div>
	</ng-container>

</div>

<ng-container *ngIf="config.attributes.editable">
	<div class="example-box campaign-builder mt-3">
		<div class="row my-1">
			<div class="col-md-12">
				<div class="add-question-container">
					<div class="my-2">
						<span>Add Question Option</span>
					</div>
					<div class="question-add">
						<a href="javascript:void(0)" (click)="addElement()"
							class="btn btn-filter btn-simple btn-success">
							<i class="fas fa-plus-circle fa-2x"></i>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>


<div>
	<ng-container *ngIf="isAudioOptions()">
		<hr class="dashed-line my-4">
		<i class="fas fa-exclamation-triangle mr-1"></i>
		<span>The question <strong>*"{{questionName}}"*</strong> would read like this</span>
		<span *ngFor="let part of partList">
			<strong> {{part}}</strong>
		</span>
	</ng-container>
</div>