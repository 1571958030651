import { BaseDataModel } from '../base-data-model.model';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';

export class ChainedFilterDataModelService implements BaseDataModel {

  private _fieldDefinition: FieldDefinition;

  constructor(fieldDefinition: FieldDefinition) {
    this._fieldDefinition = fieldDefinition;
  }

  public setValueToField(payloadValue: any) {
    const value = {};

    if (this._fieldDefinition.chainedFilterDefinition.code === 'geography_grouping') {
      payloadValue
        .map((x: any) => {
          if (!value[x.geographyTypeLibraryCode]) {
            value[x.geographyTypeLibraryCode] = [];
          }
          value[x.geographyTypeLibraryCode].push({
            code: x.instanceToCode, description: x.instanceToDescription
          });
        });
    }

    if (['campaign_filter', 'tpv_step_filter'].includes(this._fieldDefinition.chainedFilterDefinition.code)) {
      payloadValue
        .map((x: any) => {
          if (!value[x.factorTypeLibraryCode]) {
            value[x.factorTypeLibraryCode] = [];
          }
          value[x.factorTypeLibraryCode].push({
            code: x.valueInstanceCode, description: x.valueInstanceName
          });
        });
    }

    return value;
  }

  public getValueFromField(fieldValue: any) {
    let value: any;

    if (this._fieldDefinition.chainedFilterDefinition.code === 'geography_grouping') {
      value = !fieldValue ? [] :
        [].concat(...Object.keys(fieldValue)
          .map(x => fieldValue[x]))
          .map(x => { return { instanceToCode: x }; });
    }

    if (['campaign_filter', 'tpv_step_filter'].includes(this._fieldDefinition.chainedFilterDefinition.code)) {
      const tuplas = [];
      Object.keys(fieldValue.value)
        .forEach(key => (fieldValue.value[key] || [])
          .forEach((value: any) => tuplas.push({ key, value })));

      value = tuplas.map(x => {
        return {
          factorTypeLibraryCode: x.key,
          valueInstanceCode: x.value
        }
      });
    }

    return value;
  }

}
