<div class="input-group">
    <div class="input-group-btn">
        <button type="button" class="btn btn-default" [ngClass]="{'disabled':config.readonly}"
            (click)="actionButtonClicked($event)" [disabled]="config.readonly">Preview</button>
    </div>
    <emerios-textarea *ngIf="config" [config]="config" [(ngModel)]="internalValue"
        (ngModelChange)="inputValueChanged($event)"></emerios-textarea>

</div>

<span *ngIf="config?.validationIcons" class="icon-validation">
    <i [ngClass]="config?.validationIcons.pristine"
        *ngIf="control.pristine && !control.errors && model.showInputRequiredIcon"></i>
    <i [ngClass]="config?.validationIcons.error" *ngIf="!control.pristine && control.errors"></i>
    <i [ngClass]="config?.validationIcons.success" *ngIf="!control.pristine && !control.errors"></i>
</span>

<div *ngIf="control && control.errors && control.invalid && !control.pristine">
    <div [ngClass]="config?.errorMsgClass">{{getControlErrors()}}</div>
</div>