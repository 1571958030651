import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { PresetViewDto } from 'src/app/models/preset-view.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PresetviewRestService {

  constructor(private _rest: RestService) { }

  public getViews(source: string): Observable<Array<PresetViewDto>> {
    return this._rest.get<Array<PresetViewDto>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/views/${source}`
    });
  }

  public saveView(source: string, presetView: PresetViewDto) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/views/${source}`,
      body: presetView
    });
  }

  public updateView(source: string, presetView: PresetViewDto) {
    return this._rest.put({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/views/${source}`,
      body: presetView
    });
  }

  public deleteView(code: string) {
    return this._rest.delete({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/views/${code}`
    });
  }
}
