<div class="row" *ngIf="config?.title">
	<div class="col-xs-12">
		<h4>{{config.title}}
			<small class="pull-right container-code">
				<span><strong>Container Id</strong> [{{getContainerId(config)}}]</span>
				<span>
					<ng-container *ngIf="config.sectionId">
						<strong>Form Id</strong> [{{config.sectionId}}]
					</ng-container>
				</span>
			</small>
			<ng-container *ngIf="config.description">
				<p class="section-description" [safeHtml]="config.description"></p>
			</ng-container>
		</h4>
	</div>
</div>

<div [formGroup]="form">

	<div class="row">
		<ng-container *ngFor="let item of inputFields; trackBy: trackByFn">
			<ng-container *ngIf="!item.attributes.hidden">
				<div [ngClass]="getColumnDistribution(item.type)">

					<ng-container *ngIf="isType(item.type, ['input-text', 'input-date'])">
						<label class="app-input has-label">{{item.attributes.label}}
							<emerios-input [config]="elementConfigurations[item.field]"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)" (keyup)="setAsDirty($event)"
								[appTextMirror]="item.textMirror">
							</emerios-input>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'datetime-picker')">
						<label class="app-input has-label">{{item.attributes.label}}
							<app-date-time-picker [config]="elementConfigurations[item.field]"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								[pickerType]="item.pickerType" (is-valid)="isElemntValid($event)">
							</app-date-time-picker>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'input-lang')">
						<label class="app-input has-label app-input-lang">{{item.attributes.label}}
							<app-input-lang [config]="elementConfigurations[item.field]" [type]="'input'"
								[items]="elementConfigurations[item.field].items"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)" (keyup)="setAsDirty($event)"></app-input-lang>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'catalog-single')">
						<label class="app-input has-label emerios-multiselect">{{item.attributes.label}}
							<app-ghost-loading *ngIf="elementConfigurations[item.field].loading" [type]="'catalog'">
							</app-ghost-loading>
							<emerios-multiselect *ngIf="!elementConfigurations[item.field].loading"
								[config]="elementConfigurations[item.field]"
								[items]="elementConfigurations[item.field].items"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)" (onItemChecked)="onMultiselectItemCheckd($event)"
								(change)="setAsDirty($event);">
							</emerios-multiselect>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'catalog-multiple')">
						<label class="app-input has-label emerios-multiselect">{{item.attributes.label}}
							<emerios-multiselect [config]="elementConfigurations[item.field]"
								[items]="elementConfigurations[item.field].items"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)" (onItemChecked)="onMultiselectItemCheckd($event)"
								(change)="setAsDirty($event);">
							</emerios-multiselect>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'button-form-preview')">
						<app-button-form-preview [config]="elementConfigurations[item.field]"
							[definition]="elementConfigurations[item.field].fieldDefinition">
						</app-button-form-preview>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'button')">
						<button class="btn btn-default">{{item.attributes.label}}</button>
					</ng-container>

					<ng-container *ngIf="isType(item.type, ['grid-catalog-single', 'grid-catalog-multiple'])">
						<app-catalog-grid [config]="elementConfigurations[item.field]"
							[definition]="elementConfigurations[item.field].fieldDefinition"
							[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
							(selectionChanged)="setAsDirty($event);"
							[filterByField]="item.attributes.filterByField || 'ownerPartyRole'"
							[readonly]="item.attributes.readonly" class="grid grid-70">
						</app-catalog-grid>
						<div class="clearfix mb-3"></div>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'rules-grid')">
						<app-rules-grid [config]="elementConfigurations[item.field]"
							[definition]="elementConfigurations[item.field].fieldDefinition"
							[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
							(selectionChanged)="setAsDirty($event);" class="grid grid-70">
						</app-rules-grid>
						<div class="clearfix mb-3"></div>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'relationships-grid')">
						<app-relationships-grid [config]="elementConfigurations[item.field]"
							[definition]="elementConfigurations[item.field].fieldDefinition"></app-relationships-grid>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'entity-selector-grid')">
						<app-entity-lister [config]="elementConfigurations[item.field]"
							[definition]="elementConfigurations[item.field].fieldDefinition"
							[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
							(selectionChanged)="setAsDirty($event);">
						</app-entity-lister>
						<div class="clearfix mb-3"></div>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'navigation-rules')">
						<app-navigation-rules [config]="elementConfigurations[item.field]"
							[definition]="elementConfigurations[item.field].fieldDefinition"
							[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
							(changed)="setAsDirty($event);">
						</app-navigation-rules>
						<div class="clearfix mb-3"></div>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'grid-viewer')">
						<app-grid-viewer-field [config]="elementConfigurations[item.field]"
							[definition]="elementConfigurations[item.field].fieldDefinition" class="grid grid-70">
						</app-grid-viewer-field>
						<div class="clearfix mb-3"></div>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'textarea')">
						<label class="app-input has-label app-textarea">{{item.attributes.label}}
							<emerios-textarea [config]="elementConfigurations[item.field]"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)" (keyup)="setAsDirty($event)" appExpandLongText>
							</emerios-textarea>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'textarea-lang')">
						<label class="app-input has-label app-textarea-lang">{{item.attributes.label}}
							<app-input-lang [config]="elementConfigurations[item.field]" [type]="'textarea'"
								[items]="elementConfigurations[item.field].items"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)" (keyup)="setAsDirty($event)"
								[appReplacer]="item.replacer" appExpandLongText>
							</app-input-lang>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'textarea-form')">
						<label class="app-input has-label app-input-lang">{{item.attributes.label}}
							<app-textarea-form [config]="elementConfigurations[item.field]"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)">
							</app-textarea-form>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'signature-viewer-group')">
						<app-signature-viewer-group [formControlName]="item.attributes.name">
						</app-signature-viewer-group>
					</ng-container>

				</div>
			</ng-container>
		</ng-container>

		<div class="clearfix"></div>
	</div>

	<!-- <ng-container *ngIf="textareaFields && textareaFields.length > 0">
		<div class="row">
			<ng-container *ngFor="let item of textareaFields; let index = index; let odd = odd; trackBy: trackByFn">
				<div class="col-xs-12 col-sm-6">

					<ng-container *ngIf="isType(item.type, 'textarea')">
						<label class="app-input has-label app-textarea">{{item.attributes.label}}
							<emerios-textarea [config]="elementConfigurations[item.field]"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)" (keyup)="setAsDirty($event)" appExpandLongText>
							</emerios-textarea>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'textarea-lang')">
						<label class="app-input has-label app-textarea-lang">{{item.attributes.label}}
							<app-input-lang [config]="elementConfigurations[item.field]" [type]="'textarea'"
								[items]="elementConfigurations[item.field].items"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)" (keyup)="setAsDirty($event)"
								[appReplacer]="item.replacer" appExpandLongText>
							</app-input-lang>
						</label>
					</ng-container>

					<ng-container *ngIf="isType(item.type, 'textarea-form')">
						<label class="app-input has-label app-input-lang">{{item.attributes.label}}
							<app-textarea-form [config]="elementConfigurations[item.field]"
								[formControlName]="item.attributes.name" [forceValidation]="forceFormValidation"
								(is-valid)="isElementValid($event)">
							</app-textarea-form>
						</label>
					</ng-container>

				</div>
				<div class="clearfix" *ngIf="index > 0 && odd"></div>
			</ng-container>
		</div>
	</ng-container> -->

	<ng-container *ngIf="sortableLists && sortableLists.length > 0">
		<div class="row">
			<ng-container *ngFor="let list of sortableLists; trackBy: trackByFn">
				<div class="col-xs-12 col-sm-9">
					<app-sortable-list [config]="list" [formControlName]="list.attributes.name">
					</app-sortable-list>
				</div>
			</ng-container>
		</div>
	</ng-container>

	<ng-container *ngIf="chainedFilterConfig">
		<div class="row">
			<div class="col-xs-12">
				<app-chained-filter [config]="chainedFilterConfig"
					[formControlName]="chainedFilterConfig.attributes.name" [forceValidation]="forceFormValidation"
					(is-valid)="isElementValid($event)">
				</app-chained-filter>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="tabbedRuleCreators && tabbedRuleCreators.length > 0">
		<ng-container *ngFor="let tabbed of tabbedRuleCreators; trackBy: trackByFn">
			<app-tabbed-rule-creator [config]="tabbed" [formControlName]="tabbed.attributes.name"
				[forceValidation]="forceFormValidation" [detailCode]="detailCode">
			</app-tabbed-rule-creator>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="fileUploaders && fileUploaders.length > 0">
		<ng-container *ngFor="let fileUpload of fileUploaders">
			<div class="row">
				<div class="col-xs-12 col-sm-10 col-md-9 col-lg-7">
					<app-file-upload [config]="fileUpload" [formControlName]="fileUpload.attributes.name"
						[forceValidation]="forceFormValidation" (is-valid)="isElementValid($event)">
					</app-file-upload>
				</div>
			</div>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="uploadFileProcessings && uploadFileProcessings.length > 0">
		<ng-container *ngFor="let uploadFileProcessing of uploadFileProcessings">
			<app-upload-file-processing [config]="uploadFileProcessing" [forceValidation]="forceFormValidation"
				(is-valid)="isElementValid($event)" class="upload-file-processing">
			</app-upload-file-processing>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="backgroundJobDetails && backgroundJobDetails.length > 0">
		<ng-container *ngFor="let backgroundJobDetail of backgroundJobDetails">
			<app-background-task-detail [config]="backgroundJobDetail" (is-valid)="isElementValid($event)">
			</app-background-task-detail>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="buttonActionList && buttonActionList.length > 0">
		<ng-container *ngFor="let buttonAction of buttonActionList">
			<app-button-action [text]="buttonAction.attributes.label" [cssClass]="buttonAction.attributes.cssClass"
				(triggered-action)="handleActionButtonTriggered($event)">
			</app-button-action>
		</ng-container>
	</ng-container>
</div>