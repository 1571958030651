export enum DynafEnum {
    Organization = 'DYNAF.ORG',
    Book = 'DYNAF.BK',
    OrganizationBook = 'DYNAF.ORG.BK',
    Tenant = 'DYNAF.ORGTNT',
    Person = 'DYNAF.PER',
    PersonBook = 'DYNAF.PER.BK',
    InstanceRelationship = 'DYNAF.IREL',
    Question = 'DYNAF.QST',
    EmailTemplate = 'DYNAF.EMAIL_TPL',
    EmailTemplateBook = 'DYNAF.EMAIL_TPL.BK',
    GeographyGrouping = 'DYNAF.GEO',
    GeographyGroupingBook = 'DYNAF.GEO.BK',
    Product = 'DYNAF.PRD',
    ProductBook = 'DYNAF.PRD.BK',
    Feature = 'DYNAF.FTR',
    ProductType = 'DYNAF.PDT',
    ProductTypeBook = 'DYNAF.PDT.BK',
    CampaignBook = 'DYNAF.CMP.BK',
    FlowBook = 'DYNAF.FLW.BK',
    StepContainerBook = 'DYNAF.STP.CNT.BK',
    FileBook = 'DYNAF.FILE.BK',
    BookUpload = 'DYNAF.UPLD',
    Campaign = 'DYNAF.CMP',
    Replacer = 'DYNAF.RPL',
    FormView = 'DYNAF.FRM',
    SubformView = 'DYNAF.SFRM',
    GridView = 'DYNAF.GRD',
    CatalogBook = 'DYNAF.CTL.BK',
    Flow = 'DYNAF.FLW',
    Stage = 'DYNAF.STG',
    Step = 'DYNAF.STP',
    StepForm = 'DYNAF.STP.FRM',
    StepContainer = 'DYNAF.STP.CNT',
    FileAsset = 'DYNAF.FILE.AST',
    FileDocument = 'DYNAF.FILE.DOC',
    FileAudio = 'DYNAF.FILE.AUD',
    File = 'DYNAF.FILE_JRN',
    GlossaryTerm = 'DYNAF.TRM',
    IdentifyInstance = 'DYNAF.IDIN',
    IdentifyPromoteBook = 'DYNAF.IDIN_BK',
    Event = 'DYNAF.EVT',
    GenericForm = 'DYNAF.GEN',

    ChangeRequest = 'DYNAF.CHG',
    SubmittedRequest = 'DYNAF.SREQ',
    ProcessedRequest = 'DYNAF.PROREQ',
    Request = 'DYNAF.REQ',
    PendingRequest = 'DYNAF.PREQ',
    AgreFlow = 'DYNAF.FLW.AGRE',
    BackgroundJobs = 'DYNAF.BCK_JOB',
    TenantSelection = 'DYNAF.TNT_SEL',

    ApplicationBook = 'DYNAF.APP.BK',
    Application = 'DYNAF.APP',
    IntegrationCommandSelection = 'DYNAF.INT_CMD'
}
