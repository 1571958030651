import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { FloatingInputService } from 'src/app/services/behavior/floating-input/floating-input.service';

export interface ViewModel {
  visible: boolean;
  inputText: string;
  focused: boolean;
  sticky: boolean;
}

@Component({
  selector: 'app-floating-input',
  templateUrl: './floating-input.component.html',
  styleUrls: ['./floating-input.component.sass']
})
export class FloatingInputComponent implements OnInit {
  @Output() public inputChanged: EventEmitter<string> = new EventEmitter();

  @ViewChild('inputEl', { static: false })
  public inputEl: ElementRef;
  public model = {} as ViewModel;

  private _forceVisible: boolean;
  private _lockVisibility: boolean;
  private _timeout: any;

  private _subscriptions: Array<Subscription> = [];

  constructor(private _floatingInputService: FloatingInputService) { }

  public inputTextChanged(timeout: number = 1500) {
    clearInterval(this._timeout);

    this.inputChanged.emit(this.model.inputText);

    if (this.model.inputText.length === 0 && !this._forceVisible) {
      this._timeout = setTimeout(() => {
        this.model.visible = !this._lockVisibility && false;
        this.model.focused = !this._lockVisibility && false;
      }, timeout);
    }
  }

  public clearInput() {
    this.model.inputText = '';
    this.inputTextChanged(0);
  }

  ngOnInit() {
    this._subscriptions.push(this._floatingInputService.preventShowing$
      .subscribe(lockVisibility => this._lockVisibility = lockVisibility));

    this._subscriptions.push(this._floatingInputService.setStickyPosition$
      .subscribe(sticky => this.model.sticky = sticky));

    this._subscriptions.push(this._floatingInputService.forceVisible$
      .subscribe(force => {
        this._forceVisible = force;
        this.model.visible = force;
        this.model.focused = force;

        if (this.inputEl) {
          setTimeout(() => {
            this.model.inputText = '';
            this.inputEl.nativeElement.focus();
          }, 100);
        }
      }));

    this._subscriptions.push(fromEvent(window, 'keyup')
      .subscribe((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          this.clearInput();
          return;
        }
        if (!this.model.focused) {
          if (e.altKey || e.ctrlKey || e.shiftKey) {
            return;
          }
          if (e.which <= 90 && e.which >= 48) {
            this.model.visible = !this._lockVisibility && true;
            this.model.focused = !this._lockVisibility && true;
            this.model.inputText = e.key;
            setTimeout(() => {
              this.inputEl.nativeElement.focus();
            });
          }
        }
      }));

    // setTimeout(() => {
    //   document.querySelectorAll('input')
    //     .forEach(htmlEl => {
    //       htmlEl.onfocus = (ev) => {
    //         this._lockVisibility = true;
    //       };
    //       htmlEl.onblur = (ev) => {
    //         this._lockVisibility = false;
    //       };
    //     });
    // }, 1000);

  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

}
