<div class="modal-header">
  <h3 class="modal-title pull-left" [innerHtml]="params.title"></h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<ng-container *ngIf="form">
  <div class="modal-body campaign-modal" [formGroup]="form">

    <div class="row">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
        <label class="app-input has-label emerios-multieselect"> {{model.classificationPurposeLabel}}
          <emerios-multiselect [config]="classificationPurposeConfig"
            [formControlName]="classificationPurposeConfig.name">
          </emerios-multiselect>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
        <label class="app-input has-label emerios-multieselect"> {{model.relatedLabel}}
          <emerios-multiselect [config]="relatedMultiselectConfig" [formControlName]="relatedMultiselectConfig.name">
          </emerios-multiselect>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
        <label class="app-input has-label emerios-multieselect"> {{model.applicabilityLabel}}
          <emerios-multiselect [config]="applicabilityConfig" [formControlName]="applicabilityConfig.name">
          </emerios-multiselect>
        </label>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-next" (click)="closeModal()">Cancel</button>
    <button type="button" [disabled]="!form.valid" class="btn btn-primary btn-fill" (click)="okModal()">Create</button>
  </div>

</ng-container>