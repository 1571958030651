/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./relationships-grid.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../action-bar/action-bar.component.ngfactory";
import * as i3 from "../action-bar/action-bar.component";
import * as i4 from "../../services/behavior/action-bar/action-bar.service";
import * as i5 from "../loading/ghost-loading/ghost-loading.component.ngfactory";
import * as i6 from "../loading/ghost-loading/ghost-loading.component";
import * as i7 from "../grid/grid.component.ngfactory";
import * as i8 from "../grid/grid.component";
import * as i9 from "../grid-pagination/grid-pagination.component.ngfactory";
import * as i10 from "../grid-pagination/grid-pagination.component";
import * as i11 from "../../services/handler/grid-handler/grid-handler.service";
import * as i12 from "../../services/helper/action-bar-helper/action-bar-helper.service";
import * as i13 from "../../services/handler/relationship-handler/relationship-handler.service";
import * as i14 from "../../services/handler/authorization-handler/authorization-handler.service";
import * as i15 from "@angular/common";
import * as i16 from "./relationships-grid.component";
import * as i17 from "../../services/behavior/modal/modal.service";
import * as i18 from "ngx-toastr";
import * as i19 from "../../services/handler/dashboard-handler/dashboard-handler.service";
import * as i20 from "../../services/helper/navigation-helper/navigation-helper.service";
import * as i21 from "../../services/handler/operation-handler/operation-handler.service";
import * as i22 from "../../services/handler/detail-view-handler/detail-view-handler.service";
import * as i23 from "../../services/behavior/waiting-loader/waiting-loader.service";
import * as i24 from "../../services/handler/field-value-handler/field-value-handler.service";
import * as i25 from "../../services/behavior/util/util.service";
import * as i26 from "../../services/helper/api-helper/api-helper.service";
import * as i27 from "../../services/helper/aggrid-column-helper/aggrid-column-helper.service";
var styles_RelationshipsGridComponent = [i0.styles];
var RenderType_RelationshipsGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RelationshipsGridComponent, data: {} });
export { RenderType_RelationshipsGridComponent as RenderType_RelationshipsGridComponent };
function View_RelationshipsGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "btn-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-action-bar", [], null, null, null, i2.View_ActionButtonsComponent_0, i2.RenderType_ActionButtonsComponent)), i1.ɵdid(3, 770048, null, 0, i3.ActionButtonsComponent, [i4.ActionBarService], { config: [0, "config"], code: [1, "code"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.actionButtons; var currVal_1 = _co.model.actionBarUid; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_RelationshipsGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading", [], null, null, null, i5.View_GhostLoadingComponent_0, i5.RenderType_GhostLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i6.GhostLoadingComponent, [], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_0 = "aggrid"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RelationshipsGridComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grid", [], null, [[null, "selectionChanged"], [null, "gridApi"], [null, "rowDoubleClicked"], [null, "rowDragEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.selectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("gridApi" === en)) {
        var pd_1 = (_co.setGridApi($event) !== false);
        ad = (pd_1 && ad);
    } if (("rowDoubleClicked" === en)) {
        var pd_2 = (_co.rowDoubleClicked($event) !== false);
        ad = (pd_2 && ad);
    } if (("rowDragEnd" === en)) {
        var pd_3 = (_co.onRowDragEnd($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_GridComponent_0, i7.RenderType_GridComponent)), i1.ɵdid(1, 770048, null, 0, i8.GridComponent, [], { data: [0, "data"] }, { rowDoubleClicked: "rowDoubleClicked", selectionChanged: "selectionChanged", rowDragEnd: "rowDragEnd", gridApi: "gridApi" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.gridModel; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RelationshipsGridComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grid-pagination", [], null, [[null, "selectionChanged"], [null, "gridApi"], [null, "rowDoubleClicked"], [null, "rowDragEnd"], [null, "gridDataLoaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.selectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("gridApi" === en)) {
        var pd_1 = (_co.setGridApi($event) !== false);
        ad = (pd_1 && ad);
    } if (("rowDoubleClicked" === en)) {
        var pd_2 = (_co.rowDoubleClicked($event) !== false);
        ad = (pd_2 && ad);
    } if (("rowDragEnd" === en)) {
        var pd_3 = (_co.onRowDragEnd($event) !== false);
        ad = (pd_3 && ad);
    } if (("gridDataLoaded" === en)) {
        var pd_4 = (_co.onGridDataLoaded($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i9.View_GridPaginationComponent_0, i9.RenderType_GridPaginationComponent)), i1.ɵdid(1, 770048, null, 0, i10.GridPaginationComponent, [i11.GridHandlerService, i12.ActionBarHelperService, i13.RelationshipHandlerService, i14.AuthorizationHandlerService], { dashboardItem: [0, "dashboardItem"], instanceCode: [1, "instanceCode"], relationshipScope: [2, "relationshipScope"] }, { rowDoubleClicked: "rowDoubleClicked", selectionChanged: "selectionChanged", rowDragEnd: "rowDragEnd", gridApi: "gridApi", gridDataLoaded: "gridDataLoaded" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.dashboardItem; var currVal_1 = _co.model.detailCode; var currVal_2 = _co.definition.relationshipScope; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_RelationshipsGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "grid grid-70 mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationshipsGridComponent_4)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationshipsGridComponent_5)), i1.ɵdid(4, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.model.showServerSideGrid; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.model.showServerSideGrid; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RelationshipsGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationshipsGridComponent_1)), i1.ɵdid(1, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationshipsGridComponent_2)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationshipsGridComponent_3)), i1.ɵdid(5, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.editMode; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.model.loadingGrid; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.model.loadingGrid; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_RelationshipsGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-relationships-grid", [], null, null, null, View_RelationshipsGridComponent_0, RenderType_RelationshipsGridComponent)), i1.ɵdid(1, 770048, null, 0, i16.RelationshipsGridComponent, [i17.ModalService, i18.ToastrService, i11.GridHandlerService, i19.DashboardHandlerService, i13.RelationshipHandlerService, i20.NavigationHelperService, i21.OperationHandlerService, i22.DetailViewHandlerService, i4.ActionBarService, i23.WaitingLoaderService, i24.FieldValueHandlerService, i25.UtilService, i26.ApiHelperService, i27.AggridColumnHelperService, i25.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelationshipsGridComponentNgFactory = i1.ɵccf("app-relationships-grid", i16.RelationshipsGridComponent, View_RelationshipsGridComponent_Host_0, { config: "config", definition: "definition" }, {}, []);
export { RelationshipsGridComponentNgFactory as RelationshipsGridComponentNgFactory };
