import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Action, FieldDefinition } from 'src/app/models/form-field-definition.models';

@Component({
  selector: 'app-button-action',
  templateUrl: './button-action.component.html',
  styleUrls: ['./button-action.component.sass']
})

export class ButtonActionComponent {
  @Input() public text: Action;
  @Input() public cssClass: string;

  @Output("triggered-action") public triggeredAction: EventEmitter<Action> = new EventEmitter();

  public buttonActionDefinition: FieldDefinition;

  constructor() { }

  public buttonClicked() {
    this.triggeredAction.emit(this.text);
  }

}
