import { Component, OnInit, Input, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { EntityPartyRoleRelationship, Entity } from 'src/app/models/entity.model';
import { GenericModalParams, ModalService } from 'src/app/services/behavior/modal/modal.service';
import { CreatePartyRelationshipComponent } from 'src/app/modals/create-party-relationship/create-party-relationship.component';
import { CreateProductFeatureRelationshipComponent } from 'src/app/modals/create-product-feature-relationship/create-product-feature-relationship.component';
import { GridApi, RowEvent, RowDragEvent } from 'ag-grid-community';
import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { GridInput } from 'src/app/components/grid/grid.model';
import { Subscription, merge, combineLatest } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActionButton } from 'src/app/components/action-bar/action-bar.component';
import { ProductAvailabilityDto } from 'src/app/models/products.model';
import { OperationHandlerService } from 'src/app/services/handler/operation-handler/operation-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { ActionBarService } from 'src/app/services/behavior/action-bar/action-bar.service';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { CreateInstanceRelationshipComponent } from 'src/app/modals/create-instance-relationship/create-instance-relationship.component';
import { WaitingLoaderService, LoadingBarTarget } from 'src/app/services/behavior/waiting-loader/waiting-loader.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { CreateFormRelationshipComponent } from 'src/app/modals/create-form-relationship/create-form-relationship.component';
import { FieldDefinition, RelationshipsGridDef, RelationshipScope } from 'src/app/models/form-field-definition.models';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { RelationshipHandlerService } from 'src/app/services/handler/relationship-handler/relationship-handler.service';
import { ElementConfig } from 'ngx-emerios-all';
import { UtilService } from 'src/app/services/behavior/util/util.service';
import { ApiHelperService } from 'src/app/services/helper/api-helper/api-helper.service';
import { AggridColumnHelperService } from 'src/app/services/helper/aggrid-column-helper/aggrid-column-helper.service';
import { sortBy } from 'src/app/functions/sortBy';
import { filter } from 'rxjs/operators';
import { CatalogEnum } from 'src/app/enums/catalogs';

export interface ViewModel {
  actionBarUid: string;
  loadingGrid: boolean;
  gridModel: GridInput;
  gridApi: GridApi;
  editMode: boolean;
  actionButtons: Array<ActionButton>;
  dashboardItem: DashboardItem;
  detailCode: string;
  showServerSideGrid: boolean;
  // gridOverlay: boolean;
}

enum RelationshipType {
  PartyRole, Product, Instance, Form, Flow
}

@Component({
  selector: 'app-relationships-grid',
  templateUrl: './relationships-grid.component.html',
  styleUrls: ['./relationships-grid.component.sass']
})
export class RelationshipsGridComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public config: ElementConfig;
  @Input() public definition: RelationshipsGridDef;

  // @Input() public disabledGrid: EventEmitter<boolean>;

  public model = { showServerSideGrid: true } as ViewModel;
  public selectedRows: Array<any> = [];

  private _uid: number;
  private _detailName: string;
  private _agGridSource: AgGridEnum;
  private _dynafSource: DynafEnum;
  private _availableOperations: Array<FormOperationType>;
  private _detailViewMode: FormOperationType;
  private _dashboardItemList: DashboardItem[];
  private _relationshipType: RelationshipType;
  private _subscriptions: Array<Subscription> = [];
  private _clientSideForms: Array<DynafEnum> = [DynafEnum.Flow, DynafEnum.Stage, DynafEnum.Step, DynafEnum.StepForm, DynafEnum.StepContainer, DynafEnum.FormView];

  constructor(
    private _modal: ModalService,
    private _toastr: ToastrService,
    private _gridHandler: GridHandlerService,
    private _dashboardHandler: DashboardHandlerService,
    private _relationshipHandler: RelationshipHandlerService,
    private _navHelper: NavigationHelperService,
    private _operationHandler: OperationHandlerService,
    private _detailViewHandler: DetailViewHandlerService,
    private _actionBarService: ActionBarService,
    private _waitingLoader: WaitingLoaderService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _utilService: UtilService,
    private _apiHelper: ApiHelperService,
    private _aggridColumnHelper: AggridColumnHelperService,
    private _utils: UtilService
  ) { }

  public rowDoubleClicked(event: RowEvent) {
    const rowData = event.node.data;
    const isParentScope = this._isParentScope();
    const detailCode = isParentScope
      ? rowData.instanceCodeFrom
      : rowData.instanceCodeTo;
    const detailName = isParentScope
      ? rowData.instanceNameFrom
      : rowData.instanceNameTo;
    const libraryCode = isParentScope
      ? rowData.libraryCodeFrom
      : rowData.libraryCodeTo;

    const entity = this._getEntityByLibrary(libraryCode);

    if (entity) {
      this._navHelper.goToViewDetail({
        entity: entity,
        detailCode: detailCode,
        detailName: detailName,
        queryParamsHandling: 'merge'
      });
    }
  }

  public selectionChanged(event: any) {
    const gridApi = event.api as GridApi;
    const selectedNodes = gridApi.getSelectedNodes();

    if (this._relationshipType === RelationshipType.Product) {
      this.selectedRows = selectedNodes.map(node => {
        return {
          productCategoryCode: node.data['instanceCodeFrom'],
          featureInstanceCode: node.data['instanceCodeTo']
        } as ProductAvailabilityDto;
      });
    } else if (this._relationshipType === RelationshipType.PartyRole) {
      this.selectedRows = selectedNodes.map(node => {
        return {
          instanceFromCode: node.data['partyRoleInstanceCodeFrom'] || node.data['instanceCodeFrom'],
          instanceToCode: node.data['partyRoleInstanceCodeTo'] || node.data['instanceCodeTo']
        };
      });
    }
    else {
      this.selectedRows = selectedNodes.map(node => {
        return {
          instanceFromCode: node.data['instanceCodeFrom'],
          instanceToCode: node.data['instanceCodeTo']
        };
      });
    }

    this._updateRemoveButtonCount();
  }

  public removeSelectedRelationships() {
    const params = {} as GenericModalParams;

    params.title = 'Remove relationship';
    params.message = 'You are going to remove the selected relationship(s). This action cannot be undone. Are you sure?';
    params.okButton = { text: 'Remove' };

    if (this._relationshipType === RelationshipType.Product) {
      params.okButton.callback = () => this._relationshipHandler
        .deleteFeatureApplicability(this.selectedRows as Array<ProductAvailabilityDto>);
    } else {
      let urlSection = this._getUrlSection();

      if (urlSection === 'file') {
        urlSection = 'file-book';
      }
      params.okButton.callback = () => this._relationshipHandler
        .deleteRelationships(urlSection, this.selectedRows);
    }

    params.cancelButton = { text: 'Cancel' };

    this._modal.showGeneric(params);
  }

  public setGridApi(gridApi: GridApi) {
    this.model.gridApi = gridApi;
  }

  public onRowDragEnd(event: RowDragEvent) {
    this._recalculateSequenceIdColumn();
  }

  public onGridDataLoaded(gridData: GridInput) {
    this.selectedRows.length = 0;
    this.model.gridModel = this._detailViewMode === FormOperationType.View
      ? gridData : this._createAdditionalColumns(gridData);

    this.model.gridModel.rows = sortBy(this.model.gridModel.rows, [{
      prop: 'sequenceId', direction: 'asc'
    }]);

    this.model.loadingGrid = false;

    this._configureActionButtons();
    this._updateRemoveButtonCount();
  }

  ngOnInit() {
    this._subscriptions.push(
      this._dashboardHandler.dashboardItemList$
        .subscribe(list => this._dashboardItemList = list));

    this._subscriptions.push(
      this._dashboardHandler.currentDashboardItem$
        .subscribe(dashboardItem => {
          const fieldChanges = this._fieldValueHandler.getAnyValue().changes;

          this.model.detailCode = fieldChanges.get('instanceCode');
          this._detailName = fieldChanges.get('instanceName');

          this.model.dashboardItem = dashboardItem;
          this.model.showServerSideGrid = !this._clientSideForms.includes(dashboardItem.formSource);
          this._dynafSource = this.model.dashboardItem.formSource;

          this._computeRelationshipType();
        }));

    this._subscriptions.push(
      this._gridHandler.getGenericGridDataAndDef$
        .pipe(filter(data => this._uid != undefined && data.uid === this._uid))
        .subscribe(data => {
          this.selectedRows.length = 0;

          this.model.gridModel = this._detailViewMode === FormOperationType.View
            ? data : this._createAdditionalColumns(data);

          this.model.gridModel.rows = sortBy(this.model.gridModel.rows, [{
            prop: 'sequenceId', direction: 'asc'
          }]);

          this.model.loadingGrid = false;

          this._updateRemoveButtonCount();
        }));

    this._subscriptions.push(
      combineLatest(
        this._operationHandler.availableOperations$,
        this._detailViewHandler.mode$,
      ).subscribe(results => {
        this._availableOperations = results[0];
        this._detailViewMode = results[1];

        this.model.editMode = results[1] === FormOperationType.Edit;

        if (!this.model.showServerSideGrid) {
          this._configureActionButtons();
        }
      }));

    this._subscriptions.push(
      merge(
        this._relationshipHandler.relationshipCreated$,
        this._relationshipHandler.relationshipDeleted$)
        .subscribe((action: string) => {
          this._waitingLoader.hide();
          this._toastr.success(`Relationship was successfully ${action}`);
          this.selectedRows = [];

          this._updateRemoveButtonCount();
          this._setGridDataConfig();
        }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      setTimeout(() => {
        if (this.model.detailCode != undefined) {
          this._setGridDataConfig();
        }
      }, 100);
    }

    // if (changes.disabledGrid && changes.disabledGrid.firstChange) {
    //   this._subscriptions.push(this.disabledGrid
    //     .pipe(distinctUntilChanged())
    //     .subscribe((value: boolean) => setTimeout(() => { this.model.gridOverlay = value })));
    // }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _recalculateSequenceIdColumn() {
    const gridApi = this.model.gridApi;
    const sequences: Array<any> = [];
    let cnt = 1;

    gridApi.forEachNode(node => {
      node.data.sequenceId = cnt++
      sequences.push({
        instanceCode: node.data.instanceCode,
        sequenceId: node.data.sequenceId
      });
    });

    // call rest api to update sequenceId?
    const urlSection = this._getUrlSection();
    this._relationshipHandler.updateSequence(urlSection, sequences);
  }

  private _setGridDataConfig() {
    this._agGridSource = this.definition.agGridSource == undefined
      ? AgGridEnum.CommonRelationship
      : this.definition.agGridSource;

    this._uid = this._utilService.generateRndNumber(0, 999999);
    this.model.actionBarUid = `relationshipGrid.${this._uid}`;

    if (!this.model.showServerSideGrid) {
      this.model.loadingGrid = true;
      this._gridHandler.getRelationshipsData(
        this._uid, this.definition.relationshipScope, this._agGridSource, this.model.detailCode);
    }
  }

  private _computeRelationshipType() {
    switch (this._dynafSource) {
      case DynafEnum.Organization:
      case DynafEnum.Person:
        this._relationshipType = RelationshipType.PartyRole;
        break;
      case DynafEnum.Feature:
      case DynafEnum.ProductType:
        this._relationshipType = RelationshipType.Product;
        break;
      case DynafEnum.GlossaryTerm:
      case DynafEnum.ProductBook:
        this._relationshipType = RelationshipType.Instance;
        break;
      case DynafEnum.FormView:
      case DynafEnum.SubformView:
        this._relationshipType = RelationshipType.Form;
        break;
      case DynafEnum.Flow:
      case DynafEnum.Stage:
      case DynafEnum.Step:
      case DynafEnum.StepContainer:
      case DynafEnum.StepForm:
        this._relationshipType = RelationshipType.Flow;
        break;
    }
  }

  private _newRelationship() {
    switch (this._relationshipType) {
      case RelationshipType.PartyRole:
        this._openPartyRoleRelationship();
        break;
      case RelationshipType.Product:
        this._openProductRelationship();
        break;
      case RelationshipType.Form:
        this._openFormRelationship();
        break;
      default:
        this._openInstanceRelationship();
        break;
    }
  }

  private _openPartyRoleRelationship() {
    const fieldGroups = this._fieldValueHandler.getFormDefinitionValue();
    const flatFields = [].concat(...fieldGroups
      .filter(f => f.fields != undefined)
      .map(g => g.fields)) as FieldDefinition[];
    const params: any = {};

    params.title = `Create Relationship for ${this._detailName}`;
    params.roles = flatFields.find(f => f.field == 'roles').catalogItems;
    params.currentRoles = flatFields.find(f => f.field == 'roles').value;
    params.currentPartyInstanceCode = this.model.detailCode;
    params.currentRelationships = this.model.gridModel.rows;
    params.scope = this.definition.relationshipScope;

    params.callback = (relationship: EntityPartyRoleRelationship) => {
      this._waitingLoader.showLoadingBar(LoadingBarTarget.DetailView, true);
      this._relationshipHandler.createRelationship(this.model.dashboardItem.viewCode, relationship);
    }

    this._modal.showCustom(CreatePartyRelationshipComponent, { params: params });
  }

  private _openProductRelationship() {
    const params: any = {};

    params.title = `Create Relationship for ${this._detailName}`;
    params.owner = this._fieldValueHandler.getOwnerPartyRoleValue();
    params.fromProductFeature = this.model.dashboardItem.gridSource === AgGridEnum.Feature;
    params.fromProductType = this.model.dashboardItem.gridSource === AgGridEnum.ProductType;
    params.instanceCode = this.model.detailCode;
    params.currentRelationships = this.model.gridModel.rows;

    params.callback = (relationships: Array<ProductAvailabilityDto>) => {
      this._waitingLoader.showLoadingBar(LoadingBarTarget.DetailView, true);
      this._relationshipHandler.createFeatureApplicability(relationships);
    }
    params.currentCode = this.model.detailCode;

    this._modal.showCustom(CreateProductFeatureRelationshipComponent, { params: params });
  }

  private _openInstanceRelationship() {
    let viewCode = this._getUrlSection();
    const libraryCode = this._utils.getLibraryCodeFromInstance(this.model.detailCode);
    const childrenRelationship = ![CatalogEnum.FileAssets.toString(), CatalogEnum.DocumentAssets.toString()].includes(libraryCode);

    const params: any = {};

    params.title = `Create Relationship for ${this._detailName}`;
    params.detailCode = this.model.detailCode;
    params.childrenRelationship = childrenRelationship;
    params.callback = (relationship: any) => {
      this._waitingLoader.showLoadingBar(LoadingBarTarget.DetailView, true);
      if (viewCode === 'file') {
        viewCode = 'file-book';
      }
      this._relationshipHandler.createInstanceRelationship(viewCode, relationship);
    }

    this._modal.showCustom(CreateInstanceRelationshipComponent, { params: params });
  }

  private _openFormRelationship() {
    const viewCode = this.model.dashboardItem.overrideViewCode || this.model.dashboardItem.viewCode;
    const params: any = {};

    if (this._dynafSource === DynafEnum.FormView) {
      params.title = `Create container relationship for dynamic form`;
    } else {
      params.title = `Create container relationship for dynamic subform`;
    }

    params.detailCode = this.model.detailCode;
    params.dynafFrom = this._dynafSource;

    params.callback = (relationship: any) => {
      this._waitingLoader.showLoadingBar(LoadingBarTarget.DetailView, true);
      this._relationshipHandler.createInstanceRelationship(viewCode, relationship);
    }

    this._modal.showCustom(CreateFormRelationshipComponent, { params: params });
  }

  private _getEntityByLibrary(libraryCode: string) {
    const source = this._dashboardItemList.find(entity => entity.libraryCodes.includes(libraryCode));

    return source;
  }

  private _configureActionButtons() {
    const gridsWithoutButtons = [AgGridEnum.ReplacerRelationship, AgGridEnum.QuestionRelationship];
    const isParentScope = this._isParentScope();
    const parentRelationshipAvailable = [DynafEnum.Feature, DynafEnum.FileAsset, DynafEnum.FileDocument, DynafEnum.FileAudio];

    if (gridsWithoutButtons.includes(this._agGridSource) || (isParentScope && (!parentRelationshipAvailable.includes(this._dynafSource)))) {
      return;
    }

    this.model.actionButtons = [];

    switch (this._relationshipType) {
      case RelationshipType.Flow:
        this._configureFlowActionButtons();
        break;
      default:
        this._configurePartyRoleActionButtons();
        break;
    }

    this._updateRemoveButtonCount();
  }

  private _configurePartyRoleActionButtons() {
    this._getNewRelationshipActionButton('New Relationship');
    this._getRemoveActionButton();
  }

  private _configureFlowActionButtons() {
    const entityName = this._getRelatedEntityName();
    const formData = this._fieldValueHandler.getAnyValue().changes;
    const workEffortTypeCode = formData.get('workEffortTypeCode')[0];

    if (this.model.dashboardItem.formSource === DynafEnum.StepContainer ||
      (this.model.dashboardItem.formSource === DynafEnum.Step && workEffortTypeCode === 'STP.APPROVAL') ||
      workEffortTypeCode === 'STP.FRM.JRN') {
      return;
    }

    this._getNewRelationshipActionButton(`Add Existing`);
    this._getAddNewForFlowActionButton(entityName);
    this._getRemoveActionButton();
  }

  private _updateRemoveButtonCount() {
    if (this.model.actionButtons
      && this._availableOperations.includes(FormOperationType.Edit)
      && this._detailViewMode === FormOperationType.Edit) {
      this._actionBarService.updateState(this.model.actionBarUid, {
        remove: {
          enabled: this.selectedRows.length > 0,
          text: `Remove Selected (${this.selectedRows.length})`
        }
      });
    }
  }

  private _newFlowThingRelationship() {
    const formData = this._fieldValueHandler.getAnyValue().changes;
    const sequence = this.model.gridModel.rows.length + 1;
    const nextSource = this._getNextSource();
    const dashboardItem = this._dashboardItemList.find(x => x.formSource === nextSource);
    const shouldBackAfterSave = nextSource.startsWith(DynafEnum.Step);

    let parentInstanceLibraryCode: string;
    let ownerPartyRoleInstanceCode: string;

    ownerPartyRoleInstanceCode = formData.get('ownerPartyRoleInstanceCode')[0].code;
    if (formData.get('workEffortTypeCode')[0].code) {
      parentInstanceLibraryCode = formData.get('workEffortTypeCode')[0].code;
    } else {
      parentInstanceLibraryCode = formData.get('workEffortTypeCode');
    }

    this._navHelper.goToViewDetail({
      entity: dashboardItem,
      backAfterSave: shouldBackAfterSave,
      assignToPayload: {
        parentInstanceLibraryCode: parentInstanceLibraryCode,
        ownerPartyRoleInstanceCode: ownerPartyRoleInstanceCode,
        parentInstanceCode: this.model.detailCode,
        sequenceId: sequence
      }
    });
  }

  private _getNextSource(): DynafEnum {
    const isParentScope = this._isParentScope();
    const currentDynaf = this.model.dashboardItem.formSource;

    if (isParentScope) {
      // const sources = {};
      return undefined;

    } else {
      const sources = {};

      sources[DynafEnum.Flow] = DynafEnum.Stage;
      sources[DynafEnum.Stage] = DynafEnum.Step;
      sources[DynafEnum.Step] = DynafEnum.StepForm;
      sources[DynafEnum.StepForm] = DynafEnum.StepContainer;

      return sources[currentDynaf];
    }
  }

  private _getRelatedEntityName() {
    const isParentScope = this._isParentScope();

    if (isParentScope) {
      // switch (this.model.dashboardItem.formSource) {
      //   case DynafEnum.Stage:
      //     return 'Flow';
      //   case DynafEnum.Step:
      //     return 'Stage';
      //   case DynafEnum.StepForm:
      //     return 'Step';
      // }
      return undefined;
    } else {
      switch (this.model.dashboardItem.formSource) {
        case DynafEnum.Flow:
          return 'Stage';
        case DynafEnum.Stage:
          return 'Step';
        case DynafEnum.Step:
          return 'Form';
        case DynafEnum.StepForm:
          return 'Container';
      }
    }
  }

  private _getNewRelationshipActionButton(text: string) {
    this.model.actionButtons.push({
      code: 'add',
      text: text,
      iconClass: 'fas fa-plus-circle',
      visible: true,
      enabled: true,
      callback: () => this._newRelationship()
    });
  }

  private _getAddNewForFlowActionButton(entityName: string) {
    this.model.actionButtons.push({
      code: 'add_new',
      text: `Add New ${entityName}`,
      iconClass: 'fas fa-plus-circle',
      visible: true,
      enabled: true,
      callback: () => this._newFlowThingRelationship()
    });
  }

  private _getRemoveActionButton() {
    this.model.actionButtons.push({
      code: 'remove',
      text: `Remove Selected (0)`,
      iconClass: 'fas fa-trash-alt',
      visible: true,
      enabled: false,
      callback: () => this.removeSelectedRelationships()
    });
  }

  private _getUrlSection() {
    const payload = this._createEntityFromMap();
    const urlSection = this._apiHelper.getUrlSection(undefined, payload);

    return urlSection;
  }

  private _createEntityFromMap(): Entity {
    const entity = Array.from(this._fieldValueHandler.getAnyValue().changes)
      .reduce((obj, [key, value]) => {
        obj[key] = Array.isArray(value) && value.length === 1 && value[0].code != undefined
          ? value[0].code
          : value;
        return obj;
      }, {});

    return Object.assign(new Entity(), entity);
  }

  private _createAdditionalColumns(gridInput: GridInput) {
    const checkboxColumn = this._aggridColumnHelper.getNativeCheckboxColumn(true);

    gridInput.metadata.columnDefinition.splice(0, 0, checkboxColumn);

    if (this._allowRowDragging()) {
      const draggableColumn = this._aggridColumnHelper.getDraggabpeColumn(() => true);

      draggableColumn.valueGetter = (params: any) => {
        return `${params.data['instanceNameFrom']} -> ${params.data['instanceNameTo']}`;
      };

      gridInput.metadata.columnDefinition.splice(1, 0, draggableColumn);
    }

    return gridInput;
  }

  private _isParentScope() {
    return this.definition.relationshipScope === RelationshipScope.Parent;
  }

  private _allowRowDragging() {
    const allowed = [
      DynafEnum.Flow, DynafEnum.Stage,
      DynafEnum.Step, DynafEnum.FormView,
      DynafEnum.StepContainer];

    return allowed.includes(this._dynafSource) && !this._isParentScope();
  }

}
