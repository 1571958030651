import * as i0 from "@angular/core";
export class AggridColumnHelperService {
    constructor() { }
    getNativeCheckboxColumn(headerCheckbox) {
        return {
            headerName: "",
            filter: false,
            width: 40,
            lockPosition: true,
            suppressToolPanel: true,
            headerCheckboxSelection: headerCheckbox,
            checkboxSelection: true,
            resizable: false
        };
    }
    getLockedColumn(name, cbCellRendered, cbCellClickedCallback) {
        const baseColumnDef = this._getBaseLockedColumn();
        baseColumnDef.headerName = '';
        baseColumnDef.field = name;
        baseColumnDef.width = 40;
        baseColumnDef.cellRenderer = cbCellRendered;
        baseColumnDef.onCellClicked = cbCellClickedCallback;
        return baseColumnDef;
    }
    getDraggabpeColumn(rowDragCallback) {
        const draggableColumn = this._getBaseLockedColumn();
        draggableColumn.width = 40;
        draggableColumn.rowDrag = rowDragCallback;
        return draggableColumn;
    }
    getTabbedRuleCreatorCustomColumns(rowDragCallback, cbCellClickedCallback, cbCellRendered) {
        const baseColumnDef = this._getBaseLockedColumn();
        const columns = [];
        const draggableColumn = Object.assign({}, baseColumnDef);
        const checkboxColumn = Object.assign({}, baseColumnDef);
        const orderColumn = Object.assign({}, baseColumnDef);
        draggableColumn.width = 40;
        draggableColumn.rowDrag = rowDragCallback;
        checkboxColumn.field = 'rowSelected';
        checkboxColumn.width = 40;
        checkboxColumn.onCellClicked = cbCellClickedCallback;
        checkboxColumn.cellRenderer = cbCellRendered;
        orderColumn.width = 100;
        orderColumn.field = 'sortId';
        orderColumn.headerName = 'Sequence';
        columns.push(draggableColumn, checkboxColumn, orderColumn);
        return columns;
    }
    _getBaseLockedColumn() {
        return {
            lockPosition: true,
            suppressToolPanel: true,
            filter: false,
            resizable: false,
            sortable: false
        };
    }
}
AggridColumnHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AggridColumnHelperService_Factory() { return new AggridColumnHelperService(); }, token: AggridColumnHelperService, providedIn: "root" });
