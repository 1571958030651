import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FieldAttributes } from 'src/app/models/form-field-definition.models';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { EntityRestService } from 'src/app/services/rest/entity-rest/entity-rest.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { BaseCatalogItem } from 'src/app/models/catalog.models';
import * as moment from 'moment-mini';

export interface ConfirmSubmissionPayload {
  requestCode: string;
  changeRequestCode: string;
  description: string;
  isPromoteRequest?: boolean;
}

@Component({
  selector: 'app-link-change-modal',
  templateUrl: './link-change-modal.component.html',
  styleUrls: ['./link-change-modal.component.sass']
})
export class LinkChangeModalComponent implements OnInit, OnDestroy {
  public config: any;
  public model: any = {};

  public title: string;
  public changeRequestCode: string;
  public changeRequestDescription: string;
  public requestCode: string;

  public callback: Function;

  public form: FormGroup;

  private _formatDate = 'MM-DD-YYYY';

  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _catalog: CatalogService,
    private _modal: BsModalRef,
    private _elementHelper: ElementHelperService,
    private _entity: EntityRestService) { }

  public okModal() {
    const change: ConfirmSubmissionPayload = {
      requestCode: this.model.requestCode,
      changeRequestCode: this.changeRequestCode,
      description: this.model.description
    };

    if (this.callback) {
      this.callback(change);
    }
    this._modal.hide()
  }

  public closeModal() {
    this._modal.hide();
  }

  public requestChanged(event: any) {
    if (event) {
      this._getRequest(event);
    }
  }

  ngOnInit() {
    this.config = {};

    this._prepareConfigurations();
    this._configFormGroup();

    this.form.controls.description.setValue(this.changeRequestDescription);
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _configFormGroup() {
    this.form = new FormGroup({
      request: new FormControl(undefined, this.config.request.validators),
      creator: new FormControl('', this.config.creator.validators),
      requestor: new FormControl('', this.config.requestor.validators),
      description: new FormControl('', this.config.description.validators),
      submissionDate: new FormControl(moment().format(this._formatDate), this.config.submissionDate.validators),
      // uatDate: new FormControl(moment().format(this._formatDate), this.config.uatDate.validators),
      // prodDate: new FormControl(moment().format(this._formatDate), this.config.prodDate.validators),
    });

    this._subscriptions.push(this.form.valueChanges
      .subscribe(changes => {
        this.model.requestCode = changes.request ? changes.request[0] : changes.request;
        this.model.description = changes.description;
      }));
  }

  private _prepareConfigurations() {
    this.config.creator = this._getCreatorElementConfig();
    this.config.requestor = this._getRequestorElementConfig();
    this.config.description = this._getDescriptionElementConfig();
    this.config.request = this._getRequestElementConfig();
    this.config.submissionDate = this._getSubmissionElementConfig();

    // load request items
    this._catalog.getFullCatalog<BaseCatalogItem>(CatalogEnum.Request)
      .subscribe(catalog => {
        const selectedRequest = catalog.items.find(x => x.code == this.requestCode);

        this.config.request.items = catalog.items;

        this.config.request = Object.assign({}, this.config.request);

        if (selectedRequest) {
          setTimeout(() => {
            this.form.controls.request.setValue([selectedRequest.code]);
          });
        }
        this.form.updateValueAndValidity();
      });
  }

  private _getCreatorElementConfig() {
    const attr = {} as FieldAttributes;

    attr.name = 'creator';
    attr.editable = false;

    return this._elementHelper.getInputConfig(attr);
  }

  private _getRequestorElementConfig(): any {
    const attr = {} as FieldAttributes;

    attr.name = 'requestor';
    attr.editable = false;

    return this._elementHelper.getInputConfig(attr);
  }

  private _getDescriptionElementConfig(): any {
    const attr = {
      name: 'description',
      editable: true,
      required: true
    } as FieldAttributes;

    return this._elementHelper.getTextareaConfig(attr);
  }

  private _getRequestElementConfig(): any {
    const attr = {
      name: 'request',
      editable: true,
      required: true
    } as FieldAttributes;
    const config = this._elementHelper.getMultiSelectConfig(attr, []);

    config.singleSelect = true;

    return config;
  }

  private _getSubmissionElementConfig(): any {
    const attr = { name: 'submissionDate' } as FieldAttributes;

    return this._elementHelper.getDatepickerConfig(attr);
  }

  private _getRequest(requestCode: string) {
    this._entity.getDataEntity('request', requestCode)
      .subscribe(request => {
        if (request) {
          this.form.controls.creator.setValue(request.creator);
          this.form.controls.requestor.setValue(request.requestor);
        }
      });
  }
}
