export enum AgGridEnum {
    CommonRelationship = 'FGRID.REL.CMN',
    Tenant = 'FGRID.ORGTNT',
    Organization = 'FGRID.ORG',
    Person = 'FGRID.PER',
    Question = 'FGRID.QST',
    Replacer = 'FGRID.RPL',
    EmailTemplate = 'FGRID.EMAIL_TPL',
    EmailTemplateBook = 'FGRID.EMAIL_TPL.BK',
    Product = 'FGRID.PRD',
    Book = 'FGRID.BK',
    OrganizationBook = 'FGRID.ORGBK',
    PersonBook = 'FGRID.PERBK',
    ProductBook = 'FGRID.PRDBK',
    GeographyGroupingBook = 'FGRID.GEOBK',
    ProductTypeBook = 'FGRID.PDTBK',
    CampaignBook = 'FGRID.CMPBK',
    FlowBook = 'FGRID.FLWBK',
    StepContainerBook = 'FGRID.STPCNTBK',
    FileBook = 'FGRID.FILEBK',
    InstanceRelationship = 'FGRID.IREL',
    GeographyGrouping = 'FGRID.GEO',
    WorkeffortHierarchy = 'FGRID.WE.HIER',
    WorkeffortHierarchyJourneyAndOthers = 'FGRID.WE.HIER_OTHER',
    Feature = 'FGRID.FTR',
    ProductType = 'FGRID.PDT',
    Campaign = 'FGRID.CMP',
    CampaignEntries = 'FGRID.CMPE',
    FormView = 'FGRID.FRM',
    SubformView = 'FGRID.SFRM',
    GridView = 'FGRID.GRD',
    Flow = 'FGRID.FLW',
    Stage = 'FGRID.WE.STG',
    Step = 'FGRID.WE.STP',
    StepForm = 'FGRID.WE.FRM',
    StepContainer = 'FGRID.WE.CNT',
    FileAsset = 'FGRID.FILE.AST',
    FileDocument = 'FGRID.FILE.DOC',
    FileAudio = 'FGRID.FILE.AUD',
    GlossaryTerm = 'FGRID.TRM',
    GlossaryTermHierarchy = 'FGRID.TRM.HIER',
    Event = 'FGRID.EVT',
    QuestionParentCondition = 'FGRID.QST.CND',
    HierarchicalWorkeffort = 'FGRID.CM.HIER',
    ComparativeView = 'FGRID.CM.REQ',
    PromoteView = 'FGRID.CM.PROM',

    QuestionRelationship = 'FGRID.REL.QST',
    ReplacerRelationship = 'FGRID.REL.RPL',
    ProductBookRelationship = 'FGRID.REL.PDB',
    FormViewRelationship = 'FGRID.REL.FRM',
    SubformViewRelationship = 'FGRID.REL.SFRM',
    GlossaryTermRelationship = 'FGRID.REL.TRM',

    ChangeRequest = 'FGRID.CHG',
    NewRequest = 'FGRID.NREQ',
    SubmittedRequest = 'FGRID.SREQ',
    ProcessedRequest = 'FGRID.PROREQ',
    Request = 'FGRID.REQ',
    PendingRequest = 'FGRID.PREQ',

    CatalogRelationshipType = 'FGRID.CAT.RELTYPE',
    CatalogProduct = 'FGRID.CAT.PROD',

    ContainerQuestions = 'FGRID.WE.QST',
    AvailableTpvCampaignProducts = 'FGRID.PRD.CMP_TPV',
    EventFlow = 'FGRID.WE.EVENT',
    BackgroundJobs = 'FGRID.BCK_JOB',
    CatalogBook = 'FGRID.CTLBK',
    EmailTemplateAttachments = 'FGRID.EMAIL_TPL_ATCH',

    ProductCheckAndBalance = 'FGRID.PRD.CHK_BLC',

    ApplicationBook = 'FGRID.APP.BK',
    Application = 'FGRID.APP',
    BookItem = 'FGRID.BITM',
}
