import { Component, OnInit } from '@angular/core';
import { ActionBarHelperService } from 'src/app/services/helper/action-bar-helper/action-bar-helper.service';

@Component({
  selector: 'app-action-bar-dropdown',
  templateUrl: './action-bar-dropdown.component.html',
  styleUrls: ['./action-bar-dropdown.component.sass']
})
export class ActionBarDropdownComponent implements OnInit {

  constructor(private _actionBarHelper: ActionBarHelperService) { }

  public onAmountOfRowsChange(event: any) {
    const newAmount = Number(event.target.value);
    this._actionBarHelper.setAmountOfRows(newAmount);
  }

  ngOnInit() {
  }

}
