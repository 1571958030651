import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, forwardRef, Input, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ElementConfig } from 'ngx-emerios-all';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { CatalogFieldDefinition } from 'src/app/models/form-field-definition.models';
import { GridInput } from '../grid/grid.model';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { first, distinctUntilChanged } from 'rxjs/operators';
import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { RowEvent } from 'ag-grid-community';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';

export interface ViewModel {
  gridModel: GridInput;
  loadingGrid: boolean;
  suppressRowClickSelection: boolean;
}

@Component({
  selector: 'app-grid-viewer-field',
  templateUrl: './grid-viewer-field.component.html',
  styleUrls: ['./grid-viewer-field.component.sass']
})
export class GridViewerFieldComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public config: ElementConfig;
  @Input() public definition: CatalogFieldDefinition;

  public model = {} as ViewModel;
  public control: FormControl;
  public setExpanded: EventEmitter<boolean> = new EventEmitter();

  private _dashboardItemList: Array<DashboardItem>;
  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _detailViewHandler: DetailViewHandlerService,
    private _gridHandler: GridHandlerService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _navHelper: NavigationHelperService,
    private _dashboardHandler: DashboardHandlerService
  ) { }

  public onFirstDataRendered(event: any) {
    // if (this.internalModel && this._gridApi) {
    //   this._updateGridSelection();
    // }
  }

  public rowDoubleClicked(event: RowEvent) {
    const rowData = event.node.data;
    const detailCode = rowData.instanceCode;
    const detailName = rowData.instanceName;
    const entity = this._getEntityByLibrary(rowData.libraryCode);

    if (entity) {
      this._navHelper.goToViewDetail({
        entity: entity,
        detailCode: detailCode,
        detailName: detailName,
        queryParamsHandling: 'merge'
      });
    }
  }

  ngOnInit() {
    this._subscriptions.push(
      this._dashboardHandler.dashboardItemList$
        .subscribe(list => this._dashboardItemList = list));

    this._subscriptions.push(
      this._detailViewHandler.mode$
        .subscribe(mode => {
          this.model.suppressRowClickSelection = mode === FormOperationType.View;
        }));

    this._subscriptions.push(
      this._fieldValueHandler.anyValue$
        .pipe(distinctUntilChanged())
        .subscribe(values => {
          const detailCode = values.changes.get('instanceCode');
          const instanceCode: string = values.changes.get('instanceCode');
          const isItemBook = instanceCode.startsWith('APP.BK');
          const type = values.changes.get('workEffortTypeCode') 
            ? values.changes.get('workEffortTypeCode')[0].code 
            : isItemBook ? 'application-book' : null;

          if (detailCode && (type || isItemBook) && !this.model.loadingGrid) {
            this._getGridData(detailCode, type);
          }
        }));

    this._registerGetData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config.currentValue && changes.definition.currentValue) {

    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _getEntityByLibrary(libraryCode: string) {
    const source = this._dashboardItemList.find(entity => entity.libraryCodes.includes(libraryCode));

    return source;
  }

  private _registerGetData() {
    this._subscriptions.push(
      this._gridHandler.getGenericGridDataAndDef$
        .subscribe(gridInput => {
          if (this.definition.grid.source === gridInput.source) {
            this.model.gridModel = gridInput;
            this.model.loadingGrid = false;

            if (this.definition.grid.expand) {
              setTimeout(() => {
                this.setExpanded.emit(true);
              }, 100);
            }
          }
        }));
  }

  private _getGridData(instanceCode: string, flowType: string) {
    this.model.loadingGrid = true;
    this._gridHandler.getGenericGridDefAndCustomData(this.definition.grid, instanceCode, flowType);
  }

}

