import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { GridApi } from 'ag-grid-community';
import { CacheService, ElementConfig } from 'ngx-emerios-all';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { JourneyContextQueryStr } from 'src/app/enums/query-string';
import { ChangeRequestStatus } from 'src/app/models/campaign-builder.models';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { EntitySelectorGridDef } from 'src/app/models/form-field-definition.models';
import { JourneyType } from 'src/app/models/journey.models';
import { FormOperationType } from 'src/app/models/operation.models';
import { MessageService } from 'src/app/services/behavior/message/message.service';
import { UtilService } from 'src/app/services/behavior/util/util.service';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { EntityHandlerService, ErrorMessage } from 'src/app/services/handler/entity-handler/entity-handler.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { JourneyHandlerService } from 'src/app/services/handler/journey-handler/journey-handler.service';
import { GridInput } from '../grid/grid.model';

const noOp = () => { };

export const CUSTOM_ENTITY_LISTER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EntityListerComponent),
  multi: true
};

export class InstanceCodeName {
  instanceCode: string;
  instanceName: string;
  order: number;
}

@Component({
  selector: 'app-entity-lister',
  templateUrl: './entity-lister.component.html',
  styleUrls: ['./entity-lister.component.sass'],
  providers: [CUSTOM_ENTITY_LISTER_CONTROL_VALUE_ACCESSOR]
})
export class EntityListerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public config: ElementConfig;
  @Input() public definition: EntitySelectorGridDef;
  @Input() public forceValidation: EventEmitter<any>;

  public availableOperations: Array<FormOperationType>;
  public gridModel: GridInput;
  public control: FormControl;
  public groupSelectsChildren: boolean = true;
  public rowClassRules: any;
  public gridDashboardItem: DashboardItem;
  public showGhostLoading: boolean;
  public showPaginatedGrid: boolean;

  private _subscriptions: Array<Subscription> = [];
  private _journeyType: JourneyType;
  private _isChangeRequestDeploy: boolean;
  private _bookInstanceCodeNames: Array<InstanceCodeName> = [];

  private _onChange: any;

  writeValue(obj: any): void {
    if (obj != this.control.value) {
      this._updateInnerValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  onTouchedCallback: () => void = noOp;

  constructor(
    private _dashboardHandler: DashboardHandlerService,
    private _gridHandler: GridHandlerService,
    private _journeyHandler: JourneyHandlerService,
    private _authHandler: AuthorizationHandlerService,
    private _message: MessageService,
    private _entityHandler: EntityHandlerService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _cache: CacheService,
    private _utils: UtilService
  ) { }

  public selectionChanged(event: any) {
    const gridApi = event.api as GridApi;
    const selectedNodes = gridApi.getSelectedRows();

    if (selectedNodes) {
      this._entityHandler._setErrorMessagesAfterCheckingEntityList(null);

      // this._handleMultipleSelection(selectedNodes);
      if (this.gridModel.source === AgGridEnum.Book || this._isChangeRequestDeploy) {
        this._handleMultipleSelection(selectedNodes);
      } else {
        this._handleSingleSelection(selectedNodes);
      }
    }
  }

  public onGridDataLoaded(event: GridInput) {
    this.showGhostLoading = false;

    if (event.source != AgGridEnum.Flow) {
      // This is to validate that is not an agree flow journey. Promotes journeys are not using paginated so far
      // If this change, then fix this brittle validation.
      this._isChangeRequestDeploy = true;
    }

    this.gridModel = event;
  }

  ngOnInit() {
    this._updateContextVariables(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      if (changes.config.firstChange) {
        this._createRegistrations();
      }

      this._initializeFormControl();
    }

    if (changes.forceValidation && changes.forceValidation.currentValue && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        }));
    }
  }

  ngOnDestroy() {
    if (this._bookInstanceCodeNames.length > 0) {
      this._bookInstanceCodeNames.forEach(x => this._setExecutionOrder(x));
      this._bookInstanceCodeNames.sort((a, b) => a.order - b.order);
      this._journeyHandler.setBookInstanceData(this._bookInstanceCodeNames);
    }
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _setExecutionOrder(x: InstanceCodeName): any {
    const libraryCode = this._utils.getLibraryCodeFromInstance(x.instanceCode);

    switch (libraryCode) {
      case 'P.ORG.BK':
        x.order = 1;
        break;
      case 'BIN.BK':
        x.order = 2;
        break;
      case 'P.PER.BK':
        x.order = 3;
        break;
      case 'CTG.PDT.BK':
        x.order = 4;
        break;
      case 'PRD.BK':
        x.order = 5;
        break;
      case 'CTG.GEO.BK':
        x.order = 6;
        break;
      case 'GEO.GRP.BK':
        x.order = 7;
        break;
      case 'STP.CNT.BK':
        x.order = 8;
        break;
      case 'CTL.BK':
        x.order = 9;
        break;
      case 'DC.EMAIL_TPL.BK':
        x.order = 10;
        break;
      case 'CTG.CL.BK':
        x.order = 11;
        break;
      case 'FLW.BK':
        x.order = 12;
        break;
      case 'FLW.ORD.BK':
        x.order = 13;
        break;
      case 'CMP.BK':
        x.order = 14;
        break;
      default:
        x.order = 0;
        break;
    }
  }

  private _initializeFormControl() {
    if (this.definition.attributes.required) {
      this.control = new FormControl(undefined, Validators.required);
    } else {
      this.control = new FormControl(undefined);
    }
  }

  private _updateContextVariables(entityOwnerPartyRoleInstanceCode: string, entityInstanceCode: string,
    entityInstanceName: string, entityChangeRequestCode: Array<string>, entityDynaf: string, code: string, 
    isPromoteRequest: boolean) {
    
    const kvs = {};

    kvs[JourneyContextQueryStr.EntityOwnerPartyRoleInstanceCode] = entityOwnerPartyRoleInstanceCode;
    kvs[JourneyContextQueryStr.EntityInstanceCode] = entityInstanceCode;
    kvs[JourneyContextQueryStr.EntityInstanceName] = entityInstanceName;
    kvs[JourneyContextQueryStr.EntityChangeRequestCode] = entityChangeRequestCode;
    kvs[JourneyContextQueryStr.EntityDynaf] = entityDynaf;
    kvs[JourneyContextQueryStr.TenantCodeSource] = code;
    kvs[JourneyContextQueryStr.TenantCodeTarget] = code;
    kvs[JourneyContextQueryStr.IsPromoteRequest] = isPromoteRequest;

    this._journeyHandler.updateContextMultiple(kvs);

  }

  private _createRegistrations() {
    this._subscriptions.push(this._gridHandler.getGenericGridDataAndDef$
      .pipe(filter(data => data.source === this.gridDashboardItem.gridSource))
      .subscribe(data => {
        this.gridModel = data;
        this.showGhostLoading = false;
        this.rowClassRules = {
          'pending-approval-warning': (params: any) => {
            const isChangeRequestPendingApproval = params.data
              && params.data.changeRequestStatusCode == ChangeRequestStatus.PENDING_APPROVAL;
            const isParentOfPendingApproval = params.node.allLeafChildren
              && params.node.allLeafChildren.find(x => x.data && x.data.changeRequestStatusCode === ChangeRequestStatus.PENDING_APPROVAL);

            return isChangeRequestPendingApproval || isParentOfPendingApproval;
          }
        }
      }));

    this._subscriptions.push(combineLatest(
      this._dashboardHandler.dashboardItemList$,
      this._journeyHandler.currentJourneyStep$.pipe(filter(x => x != undefined))
    ).subscribe(result => {
      if (!this.gridDashboardItem) {
        this.gridDashboardItem = result[0]
          .find(x => x.formSource === result[1].relatedDynamicFormLibraryCode);
        if (!this.gridDashboardItem) {
          this.gridDashboardItem = result[0]
            .find(x => x.formSourceAlternative === result[1].relatedDynamicFormLibraryCode);
        }
      }
    }));

    this._subscriptions.push(this._journeyHandler.journeyType$
      .pipe(switchMap(journeyType => {
        this._journeyType = journeyType;
        this.showPaginatedGrid = this.gridDashboardItem.gridSource != AgGridEnum.Book; //this._journeyType === JourneyType.Deploy ||

        return this._fieldValueHandler.ownerPartyRoleValue$
          .pipe(map(owner => ({ journeyType, owner })));
      }))
      .subscribe(({ journeyType, owner }) => {
        // if (journeyType === JourneyType.Promote && owner) {
        if (this.gridDashboardItem.gridSource === AgGridEnum.Book && owner) {
          this._getGridData(owner);
          this.showGhostLoading = true;

          if (this._journeyType === JourneyType.Deploy) {
            this._isChangeRequestDeploy = true;
          }
        }
      }));
  }

  private _getGridData(ownerPartyRoleInstanceCode: string) {
    if (this.gridDashboardItem) {
      if ([AgGridEnum.StepForm, AgGridEnum.StepContainer].includes(this.gridDashboardItem.gridSource)) {
        this._gridHandler.getGenericGridDataAndDefV2(this.gridDashboardItem, undefined, this.gridDashboardItem.libraryCodes);
      } else {
        this._gridHandler.getGenericGridDataAndDefV2(this.gridDashboardItem, undefined, undefined, ownerPartyRoleInstanceCode);
      }
    }
  }

  private _getGridDataMultipleBooks() {
    this._gridHandler.getGenericGridDataAndDefV2(this.gridDashboardItem);
  }

  private _updateInnerValue(value: Array<string>) {
    this.control.setValue(value);

    if (this._onChange) {
      this._onChange(value);
    }
  }

  private _handleMultipleSelection(selectedNodes: any[]) {
    const instanceCodes = selectedNodes.map(node => node.instanceCode);

    if (selectedNodes.length > 0) {
      const pendingChangeRequestsStatus = selectedNodes.filter(node => node.changeRequestStatusCode === ChangeRequestStatus.PENDING_APPROVAL);
      if (pendingChangeRequestsStatus.length > 0) {
        const paragraphElement = '<p>One or more of the selected books are currently in an Approval Flow, please approve or reject the approval flow before continuing or update the book selection</p>';
        const items = pendingChangeRequestsStatus.map(changeRequest => `${changeRequest.instanceName} - ${changeRequest.instanceCode}`);
        const errorMessage = { message: paragraphElement, items: items } as ErrorMessage;
        this._entityHandler._setErrorMessagesAfterCheckingEntityList(errorMessage);
        this._updateInnerValue(instanceCodes);
      } else {
        const changeRequestCodes = selectedNodes.map(node => node.changeRequestCode);

        const firstOwnerPartyRoleInstanceCode = selectedNodes[0].ownerPartyRoleInstanceCode;
        const samePartyRoleInstanceCodeCount = selectedNodes.filter(x => x.ownerPartyRoleInstanceCode == firstOwnerPartyRoleInstanceCode).length;
        let ownerPartyRoleInstanceCode = null;
        if(samePartyRoleInstanceCodeCount === selectedNodes.length) {
          ownerPartyRoleInstanceCode = firstOwnerPartyRoleInstanceCode;
        } else {
          ownerPartyRoleInstanceCode = this._authHandler.getWorkingOrganization().partyRoleInstanceCode;
        }

        const tenantCode = selectedNodes[0].ownerPartyRoleCode;

        this._updateInnerValue(instanceCodes);

        if (this._isChangeRequestDeploy) {
          const bookDataObj = selectedNodes.map(node => {
            return {
              instanceCode: node.instanceCode,
              instanceName: node.instanceName
            } as InstanceCodeName;
          });
          this._bookInstanceCodeNames = bookDataObj;
        }

        this._updateContextVariables(ownerPartyRoleInstanceCode, null, null,
          changeRequestCodes, this.gridDashboardItem.formSource, tenantCode, 
          !this._isChangeRequestDeploy);
      }
    }
  }

  private _handleSingleSelection(selectedNodes: any[]) {
    const instanceCode = selectedNodes[0].instanceCode;

    const isEntitySelectedPendingForApprove = selectedNodes[0].changeRequestStatusCode === ChangeRequestStatus.PENDING_APPROVAL;

    if (isEntitySelectedPendingForApprove) {
      const paragraphElement = '<p>One or more of the selected books are currently in an Approval Flow, please approve or reject the approval flow before continuing or update the book selection</p>';
      const errorMessage = { message: paragraphElement } as ErrorMessage;
      this._entityHandler._setErrorMessagesAfterCheckingEntityList(errorMessage);
      this._updateInnerValue([instanceCode]);
    } else {
      const instanceName = selectedNodes[0].instanceName;
      const changeRequestCode = selectedNodes[0].changeRequestCode;
      const ownerPartyRoleInstanceCode = selectedNodes[0].ownerPartyRoleInstanceCode;

      this._cache.set(CacheKeyEnum.BookSelected, instanceName);
      this._updateInnerValue([instanceCode]);
      this._updateContextVariables(ownerPartyRoleInstanceCode, instanceCode, instanceName,
        [changeRequestCode], this.gridDashboardItem.formSource, null, undefined);
      this._journeyHandler.setCurrentEntitySelected(selectedNodes[0]);
    }
  }

}
