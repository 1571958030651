import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NavigationUrlEnum } from 'src/app/enums/navigation-url';
import { FormOperationType } from 'src/app/models/operation.models';
import * as moment from 'moment-mini';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private _history: Array<string>;

  constructor(private _router: Router) {
    this._history = [];

    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => this._history = [...this._history, e.url]);
  }

  public generateRndNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
  }

  public getUrlWithSource() {
    const slices = location.href.split('/');
    const url = slices.slice(0, slices.length - 1).join('/') + '/';

    return url;
  }

  public getUrlStateWithSource() {
    const urlWithSource = this.getUrlWithSource();
    const url = urlWithSource.split('#')[1];

    return url;
  }

  public getPreviousUrl() {
    return this._history[this._history.length - 2] || `/${NavigationUrlEnum.CampaignBuilder}`;
  }

  public getLibraryCodeFromInstance(instanceCode: string) {
    return instanceCode.substring(0, instanceCode.lastIndexOf('.'));
  }

  public getOperationFromInstance(instanceCode: string): FormOperationType {
    const splited = instanceCode.split('.');
    return splited[splited.length - 2] as FormOperationType
  }

  public ObjectKeysToCamelCase(element: any): any {
    return Object.fromEntries(
      Object.entries(element).map(x => [x[0].charAt(0).toLowerCase() + x[0].slice(1),
      x[1]]));
  }

  public ObjectKeysToPascalCase(element: any): any {
    return Object.fromEntries(
      Object.entries(element).map(x => [x[0].charAt(0).toUpperCase() + x[0].slice(1),
      x[1]]));
  }

  public CreateDateAsUTCFromString(dateString: string) : Date {
    return moment.utc(dateString).toDate();
  }
}
