/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cb-viewer.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../cb-breadcrumb/cb-breadcrumb.component.ngfactory";
import * as i3 from "../cb-breadcrumb/cb-breadcrumb.component";
import * as i4 from "../../../services/behavior/breadcrumb/breadcrumb.service";
import * as i5 from "ngx-emerios-all";
import * as i6 from "../../../services/helper/authorization-helper/authorization-helper.service";
import * as i7 from "@angular/router";
import * as i8 from "./cb-viewer.component";
import * as i9 from "../../../store/store.service";
var styles_CbViewerComponent = [i0.styles];
var RenderType_CbViewerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CbViewerComponent, data: {} });
export { RenderType_CbViewerComponent as RenderType_CbViewerComponent };
export function View_CbViewerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cb-breadcrumb", [["class", "cb-breadcrumb"]], null, null, null, i2.View_CbBreadcrumbComponent_0, i2.RenderType_CbBreadcrumbComponent)), i1.ɵdid(1, 245760, null, 0, i3.CbBreadcrumbComponent, [i4.BreadcrumbService, i5.CacheService, i6.AuthorizationHelperService], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); }, null); }
export function View_CbViewerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cb-viewer", [], null, null, null, View_CbViewerComponent_0, RenderType_CbViewerComponent)), i1.ɵdid(1, 114688, null, 0, i8.CbViewerComponent, [i9.StoreService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CbViewerComponentNgFactory = i1.ɵccf("app-cb-viewer", i8.CbViewerComponent, View_CbViewerComponent_Host_0, {}, {}, []);
export { CbViewerComponentNgFactory as CbViewerComponentNgFactory };
