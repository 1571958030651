// tenant code must be the database code from cfg.Tenant in lowercase
const externalServicesKeys: any = {
  'es4': {
    reCaptcha: '6LesoxUUAAAAABjXpmJwrL_UjD_oMb3ztoANsB3y'
  },
  'tracfone': {
    reCaptcha: '6Le5MnkUAAAAAEIeYV-hcaHxa7W1W9vg_wO1MEc3'
  }
};

export const environment = {
  production: true,
  mocked: false,
  questUrl: 'https://api-quest.dev.emerios.com',
  vaultUrl: 'https://api-vault.dev.emerios.com',
  vaultAppUrl: 'https://vault.dev.emerios.com',
  campaignBuilderUrl: 'https://api-cmp.dev.emerios.com',
  externalServicesKeys: externalServicesKeys,
  devProviders: []
};
