import { Component, OnInit, Input, OnChanges, SimpleChanges, forwardRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MultiselectConfig, ElementResponse } from 'ngx-emerios-all';
import { ChainedFilterDefinition } from './chained-filter.model';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { KeyValue } from 'src/app/types/keyvalue.type';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, Validators } from '@angular/forms';
import { ZipcodeGridModalComponent } from 'src/app/modals/zipcode-grid-modal/zipcode-grid-modal.component';
import { ModalService } from 'src/app/services/behavior/modal/modal.service';
import { ZipcodeData } from './chained-filter-counter/chained-filter-counter.model';
import { Subscription, of, Observable } from 'rxjs';
import { filter, debounceTime, distinctUntilChanged, switchMap, catchError, finalize, map } from 'rxjs/operators';
import { ChainedFilterService } from 'src/app/services/behavior/chained-filter/chained-filter.service';
import { EntityCatalogItem, EntityCatalog } from 'src/app/models/catalog.models';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';
import { FieldValueHandlerService, isSameField, AnyValueEvent } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';
import { CatalogEnum } from 'src/app/enums/catalogs';

export interface ChainedFilterModel {
  filterTypes: Array<EntityCatalogItem>;
  filterTypeSelected: Array<string>;

  filtersAdded: Array<EntityCatalogItem>;

  preselectedItems: any;
  selectedItems: KeyValue;
  partialItemCount: KeyValue;
  totalItemCount: number;
}

const noOp = () => { };

export const CHAINED_FILTER_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ChainedFilterComponent),
  multi: true
};

@Component({
  selector: 'app-chained-filter',
  templateUrl: './chained-filter.component.html',
  styleUrls: ['./chained-filter.component.sass'],
  providers: [CHAINED_FILTER_CONTROL_VALUE_ACCESSOR]
})
export class ChainedFilterComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public forceValidation: EventEmitter<any>;
  @Input() public config: FieldDefinition;

  @Output() public selectedItems = new EventEmitter();
  @Output("is-valid") public isValid: EventEmitter<ElementResponse> = new EventEmitter();

  public control: FormControl;

  public model = {} as ChainedFilterModel;

  public ownerPartyRoleInstanceCode: string;
  public chainedFilterConfig: ChainedFilterDefinition;
  public filterTypeConfig: MultiselectConfig;
  public multiselectConfig: KeyValue = {}
  public multiselectItems: KeyValue = {}

  public searchInput: EventEmitter<{ type: string, text: string }> = new EventEmitter();

  private _subscriptions: Array<Subscription> = [];
  private _onChange: any;
  private _filterTypeConfigured: boolean;
  private _viewMode: FormOperationType;
  private _anyValueEvent: AnyValueEvent;

  writeValue(obj: any): void {
    if (obj != this.model.selectedItems) {
      this.model.preselectedItems = obj;
    }

    if (!this.config.chainedFilterDefinition.filterTypeBy) {
      this._configFilterType();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  onTouchedCallback: () => void = noOp;

  constructor(
    private _catalogs: CatalogService,
    private _chainedFilter: ChainedFilterService,
    private _modal: ModalService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _detailViewHandler: DetailViewHandlerService
  ) {
    this.model.filtersAdded = [];
    this.model.totalItemCount = 0;
  }

  public addFilter() {
    this._addFilterType(this.model.filterTypeSelected[0]);
    this.control.markAsDirty();
  }

  public removeFilter(filter: EntityCatalogItem) {
    const indexOf = this.model.filtersAdded.indexOf(filter);
    const item = this.model.filtersAdded.splice(indexOf, 1)[0];

    this.model.filterTypes.push(item);
    this.model.filterTypes = Object.assign([], this.model.filterTypes);

    delete this.model.partialItemCount[filter.code];
    delete this.model.selectedItems[filter.code];

    this._validateFilterTypeEnability();
    this._updateTotalCount();
    this.control.markAsDirty();
  }

  public getMultiselectConfig(item: EntityCatalogItem) {
    if (!this.multiselectConfig[item.code]) {
      let config = {
        name: item.code,
        itemCode: 'code',
        itemText: 'description',
        cssClasses: 'form-control',
        placeholder: `-- Choose a ${item.description} --`,
        enableCheckAll: true,
        allowSearchFilter: true,
        fillParentWidth: true,
        clearText: "<i class='fas fa-times'></i>",
        itemsShowLimit: 1,
        selectAllText: 'Select All',
        unselectAllText: 'Unselect All',
        listHeight: 5
      } as MultiselectConfig;

      this.multiselectConfig[item.code] = config;
      this.multiselectConfig[item.code].readonly = !this.config.attributes.editable;
    }

    return this.multiselectConfig[item.code];
  }

  public getMultiselectItems(filter: EntityCatalogItem) {
    if (!this.multiselectItems[filter.code]) {
      this.multiselectItems[filter.code] = [];
    }

    return this.multiselectItems[filter.code];
  }

  public onSearch(type: string, text: string) {
    if (this.config.chainedFilterDefinition.catalogsLoadingType &&
      this.config.chainedFilterDefinition.catalogsLoadingType[type] === 'eager') {
      return;
    }
    this.searchInput.next({ type, text });
  }

  public onDropdownClosed(filter: EntityCatalogItem) {
    const selectedItems = this.model.selectedItems[filter.code];

    if (this.model.partialItemCount[filter.code] === undefined) {
      this.model.partialItemCount[filter.code] = undefined;
    }

    if (selectedItems && selectedItems.length > 0) {
      if (this.config.chainedFilterDefinition.showPartialFilterCounter) {
        this._getItemQuantity(selectedItems, filter);
      }
    }

    this._validateFilterTypeEnability();
  }

  public fireAction(event: any) {
    switch (event) {
      case 'view':
        this._openModal();
        break;
      case 'export':
        console.log(event);
        break;
      default:
        console.log('Invalid Action Selected!');
        break;
    }
  }

  public getControlErrors() {
    let errorMessage: string;

    if (this.control.errors && this.control.errors.required) {
      errorMessage = 'This is required';
    }

    return errorMessage;
  }

  ngOnInit() {
    this._registerSearch();

    this._subscriptions.push(this._fieldValueHandler.anyValue$
      .pipe(distinctUntilChanged(isSameField))
      .subscribe(anyValue => this._handleExternalValueChange(anyValue)));

    this._subscriptions.push(this._detailViewHandler.mode$
      .subscribe(viewMode => this._viewMode = viewMode));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this.chainedFilterConfig = this.config.chainedFilterDefinition;

      if (this.config.attributes.required) {
        this.control = new FormControl(undefined, Validators.required);
      } else {
        this.control = new FormControl(undefined);
      }

      if (changes.config.firstChange) {
        setTimeout(() => {
          if (!this._filterTypeConfigured) {
            const filterTypeBy = this.config.chainedFilterDefinition.filterTypeBy;

            if (filterTypeBy) {
              const value = this._anyValueEvent.changes.get(filterTypeBy);
              this._configFilterType(value[0].code);
            } else {
              this._configFilterType();
            }
          }
        }, 500);
      }
    }

    if (changes.forceValidation && changes.forceValidation.currentValue && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _registerSearch() {
    this.searchInput.pipe(
      filter((input) => input.text.length > 3),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((input) => this._chainedFilter.searchItemsByType(this.config.chainedFilterDefinition.code, input.type, input.text)
        .pipe(catchError(() => of({ type: input.type, items: [] }))))
    ).subscribe(result => {
      this._setItemsForMultiselect(result);
    });
  }

  private _setItemsForMultiselect(result: { type: string; items: EntityCatalogItem[]; } | { type: string; items: any[]; }) {
    const unselectedItems = this._removeExistingItems(result.type, result.items);
    this.multiselectItems[result.type] = unselectedItems;
  }

  private _removeExistingItems(type: string, items: Array<EntityCatalogItem>) {
    let currentItems = this.model.selectedItems[type] as Array<string>;

    if (!currentItems) {
      currentItems = [];
    }

    return items ? items.filter(x => !currentItems.includes(x.code)) : [];
  }

  private _getItemQuantity(selectedItems: any, filter: EntityCatalogItem) {
    this.multiselectConfig[filter.code].disabled = true;

    this._chainedFilter.getItemQuantity(this.config.chainedFilterDefinition.code, [...selectedItems])
      .pipe(finalize(() =>
        this.multiselectConfig[filter.code].disabled = this._viewMode === FormOperationType.View))
      .subscribe(count => {
        this.model.partialItemCount[filter.code] = count;
        this._updateTotalCount();
      });
  }

  private _updateTotalCount() {
    this.model.totalItemCount = Object.keys(this.model.partialItemCount)
      .map(prop => this.model.partialItemCount[prop])
      .reduce((a, b) => a + b, 0);
  }

  private _handleExternalValueChange(anyValue: AnyValueEvent) {
    const filterTypeBy = this.config.chainedFilterDefinition.filterTypeBy;
    const value = anyValue.changes.get(filterTypeBy);

    this._anyValueEvent = anyValue;

    if (!this._filterTypeConfigured && value != undefined) {
      if (anyValue.currentField.name === filterTypeBy) {
        this._filterTypeConfigured = true
        this._configFilterType(value[0].code);
      }
    }

    if (this.config.chainedFilterDefinition.code === 'tpv_step_filter') {
      if (anyValue.currentField.name === 'ownerPartyRoleInstanceCode') {
        this.ownerPartyRoleInstanceCode = anyValue.currentField.value[0].code;
        this._loadLazyCatalogs(CatalogEnum.BrandOrganization);
      }
    }
  }

  private _loadLazyCatalogs(catalog: string) {
    if (this.ownerPartyRoleInstanceCode) {
      this._catalogs.getSingleWithFilterFromServer(catalog, [this.ownerPartyRoleInstanceCode])
        .subscribe(result => {
          var entityCatalogItem = { type: result.code, items: result.items };
          this._setItemsForMultiselect(entityCatalogItem);
        });
    }
  }

  private _configFilterType(filterBy?: string) {
    let obs: Observable<EntityCatalog>;

    obs = filterBy
      ? this._catalogs.getSingleWithFilterFromServer(this.config.chainedFilterDefinition.filterTypeCatalog, [filterBy])
      : this._catalogs.getFromServer([this.config.chainedFilterDefinition.filterTypeCatalog]).pipe(map(x => x[0]));

    obs.subscribe(catalog => {
      this.model.filtersAdded = [];
      this.model.selectedItems = {};
      this.model.partialItemCount = {};
      this.model.totalItemCount = 0;
      this.model.filterTypes = catalog.items;

      this._configFilterTypeElement();

      if (this.config.chainedFilterDefinition.catalogsLoadingType) {
        this._fetchEagerCatalogs(this.config.chainedFilterDefinition.catalogsLoadingType);
      }

      if (this.model.preselectedItems) {
        this._initWithData();
      } else {
        if (this.config.chainedFilterDefinition.preselectedFilter) {
          this._setPreselectedFilters();
        }
        this.control.setValue(undefined);
      }
    });
  }

  private _setPreselectedFilters() {
    const preselected = this.model.filterTypes
      .filter(filterType => this.config.chainedFilterDefinition.preselectedFilter.includes(filterType.code));
    preselected.forEach(filterType => {
      this._addFilterType(filterType.code);
      this.model.selectedItems[filterType.code] = undefined;
    });
    this._validateFilterTypeEnability();
  }

  private _initWithData() {
    Object.keys(this.model.preselectedItems)
      .forEach(filterTypeCode => {
        const filterType = this.model.filterTypes.find(filterType => filterType.code === filterTypeCode);
        const filterTypeIndex = this.model.filterTypes.indexOf(filterType);

        if (filterType) {
          this.model.filterTypes.splice(filterTypeIndex, 1);
          this.model.filtersAdded.push(filterType);
          this.model.partialItemCount[filterTypeCode] = 0;
          this.model.selectedItems[filterTypeCode] = this.model.preselectedItems[filterTypeCode]
            .map((x: any) => x.code);
          this.getMultiselectConfig(filterType);
          this._getItemQuantity(this.model.selectedItems[filterTypeCode], filterType);
        } else {
          // if filterType is not found, clear preselectedItems to avoid errors
          this.model.preselectedItems = undefined;
        }
      });

    this.multiselectItems = Object.assign({}, this.model.preselectedItems);
    this._validateFilterTypeEnability();
  }

  private _fetchEagerCatalogs(catalogsLoadingType: KeyValue) {
    Object.keys(catalogsLoadingType)
      .forEach(prop => {
        if (catalogsLoadingType[prop] === 'eager') {
          this._chainedFilter.getAllItemsByType(this.config.chainedFilterDefinition.code, prop)
            .subscribe(result => {
              this._setItemsForMultiselect(result);
            })
        }
        if (catalogsLoadingType[prop] === 'lazy') {
          const ownerElement = this._fieldValueHandler.getAnyValue().changes.get('ownerPartyRoleInstanceCode');
          if (ownerElement) {
            this.ownerPartyRoleInstanceCode = ownerElement[0].code;
          }
          this._loadLazyCatalogs(prop);
        }
      });
  }

  private _addFilterType(filterTypeCode: string) {
    const filterType = this.model.filterTypes
      .find(item => item.code === filterTypeCode);

    if (filterType) {
      const indexOf = this.model.filterTypes.indexOf(filterType);
      const item = this.model.filterTypes.splice(indexOf, 1)[0];

      this.model.filterTypes = Object.assign([], this.model.filterTypes);
      this.model.filtersAdded.push(item);
      this.model.selectedItems[filterTypeCode] = undefined;

      // clearly a hack related with angular model check
      setTimeout(() => this.model.filterTypeSelected = undefined);

      this._validateFilterTypeEnability();
    }
  }

  private _validateFilterTypeEnability() {
    let someEmpty = false;
    Object.keys(this.model.selectedItems)
      .forEach(prop => {
        if (this.model.selectedItems[prop] === undefined || this.model.selectedItems[prop].length === 0) {
          someEmpty = true;
        }
      });

    this.filterTypeConfig.disabled = someEmpty;

    this._setControlValue();
  }

  private _configFilterTypeElement() {
    this.filterTypeConfig = {} as MultiselectConfig;

    this.filterTypeConfig.id = 'filterType';
    this.filterTypeConfig.name = 'filterType';
    this.filterTypeConfig.cssClasses = 'form-control';
    this.filterTypeConfig.placeholder = '-- Select Filter --';
    this.filterTypeConfig.fillParentWidth = true;

    this.filterTypeConfig.itemCode = 'code';
    this.filterTypeConfig.itemText = 'description';
    this.filterTypeConfig.singleSelect = true;
    this.filterTypeConfig.readonly = !this.config.attributes.editable;
  }

  private _openModal() {
    this._chainedFilter.getFilteredItems(this.config.chainedFilterDefinition.code, this.model.selectedItems)
      .subscribe(data => {
        const params: any = {};
        params.title = `Total filtered ${this.config.chainedFilterDefinition.workingObjectLabel}`;
        params.size = 'lg';
        params.data = this._setDataForTable(data);
        this._modal.showCustom(ZipcodeGridModalComponent, params);
      });

  }

  private _setDataForTable(data: Array<ZipcodeData>) {
    const result: Array<any> = [];

    for (let row in data) {
      result.push(Object.keys(data[row])
        .filter(element => element != 'geoCode')
        .map(element => data[row][element]));
    }

    return result;
  }

  private _setControlValue() {
    if (this.selectedItems) {
      this.selectedItems.emit(this.model.selectedItems);
    }
    // if some selector is empty or if there are no selecters added
    if (this.filterTypeConfig.disabled || Object.keys(this.model.selectedItems).length === 0) {
      this.control.setValue(undefined);
    } else {
      this.control.setValue(this.model.selectedItems);
    }

    this._onChange(this.control.value);

    const isValid = this.control.status == 'VALID';

    this.isValid.emit({
      name: this.config.attributes.name,
      value: this.control.value,
      valid: isValid
    });
  }
}
