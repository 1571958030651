/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ghost-loading-dynamic-form.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ghost-loading-dynamic-form.component";
var styles_GhostLoadingDynamicFormComponent = [i0.styles];
var RenderType_GhostLoadingDynamicFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GhostLoadingDynamicFormComponent, data: {} });
export { RenderType_GhostLoadingDynamicFormComponent as RenderType_GhostLoadingDynamicFormComponent };
function View_GhostLoadingDynamicFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "row mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-6 col-md-3 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "fieldlabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-6 col-md-3 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "fieldlabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-6 col-md-3 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "fieldlabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-6 col-md-3 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "fieldlabel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "field"]], null, null, null, null, null))], null, null); }
function View_GhostLoadingDynamicFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "fields-group pl-fluid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_GhostLoadingDynamicFormComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(7, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 7, 0, 1); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_GhostLoadingDynamicFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_GhostLoadingDynamicFormComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(2, 2)], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, 1, 2); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GhostLoadingDynamicFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading-dynamic-form", [], null, null, null, View_GhostLoadingDynamicFormComponent_0, RenderType_GhostLoadingDynamicFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.GhostLoadingDynamicFormComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GhostLoadingDynamicFormComponentNgFactory = i1.ɵccf("app-ghost-loading-dynamic-form", i3.GhostLoadingDynamicFormComponent, View_GhostLoadingDynamicFormComponent_Host_0, {}, {}, []);
export { GhostLoadingDynamicFormComponentNgFactory as GhostLoadingDynamicFormComponentNgFactory };
