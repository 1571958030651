import { GenericModalComponent } from '../modals/generic-modal/generic-modal.component';
import { MessageModalComponent } from '../modals/message-modal/message-modal.component';
import { CreatePartyRelationshipComponent } from '../modals/create-party-relationship/create-party-relationship.component';
import { CreateProductFeatureRelationshipComponent } from '../modals/create-product-feature-relationship/create-product-feature-relationship.component';
import { LinkChangeModalComponent } from '../modals/link-change-modal/link-change-modal.component';
import { SaveGridViewComponent } from '../modals/save-grid-view/save-grid-view.component';
import { ZipcodeGridModalComponent } from '../modals/zipcode-grid-modal/zipcode-grid-modal.component';
import { CustomHttpLoaderFactory } from '../services/rest/translate-loader/translate-loader.service';
import { CloneEntityComponent } from '../modals/clone-entity/clone-entity.component';
import { CreateInstanceRelationshipComponent } from '../modals/create-instance-relationship/create-instance-relationship.component';
import { DynamicFormPreviewComponent } from '../modals/dynamic-form-preview/dynamic-form-preview.component';
import { CreateFormRelationshipComponent } from '../modals/create-form-relationship/create-form-relationship.component';
import { DiscardChangesModalComponent } from '../modals/discard-changes-modal/discard-changes-modal.component';
import { ErrorDetailModalComponent } from '../modals/error-detail-modal/error-detail-modal.component';
import { SignatureModalComponent } from '../modals/signature-modal/signature-modal.component';
const components = [
    GenericModalComponent,
    MessageModalComponent,
    CreatePartyRelationshipComponent,
    CreateProductFeatureRelationshipComponent,
    LinkChangeModalComponent,
    SaveGridViewComponent,
    ZipcodeGridModalComponent,
    CloneEntityComponent,
    CreateInstanceRelationshipComponent,
    CreateFormRelationshipComponent,
    DynamicFormPreviewComponent,
    DiscardChangesModalComponent,
    ErrorDetailModalComponent,
    SignatureModalComponent
];
const ɵ0 = CustomHttpLoaderFactory;
export class ModalComponentsModule {
}
export { ɵ0 };
