import { FormDefinition } from './form-field-definition.models';

export enum FieldApplicability {
    Default = 'Default',
    Optional = 'Optional',
    Required = 'Required',
    Selectable = 'Selectable'
}

////////////////
export interface Catalog<T> {
    code: string;
    items: Array<T>;
}

export interface BaseCatalogItem {
    code: string;
    description: string;
}

export interface OperationForm {
    operation: string;
    formInstances: Array<CatalogFormInstance>
}

export interface CatalogFormInstance {
    code: string;
    name: string;
    definition: { default: boolean, definition: Array<FormDefinition> };
}

export interface CatalogFeature {
    applicabilityTypeInscanceName: string;
    code: string;
    description: string;
    descriptions: Array<{ languageName: string, languageCode: string, text: string }>;
    externalCode: string;
    featureLibraryCodeDataType: string;
    productTypeInstanceCode: string;
    productTypeInstanceName: string;
    sequenceId: number;
    featureCategory: BaseCatalogItem;
}

////////////////

export interface EntityCatalog {
    code: string,
    items: Array<EntityCatalogItem>
}

export interface EntityCatalogItem {
    code: string;
    description: string;
    descriptions: Array<{ languageCode: string, text: string }>;
    featureLibraryCodeDataType: string;
    externalCode?: string,
    partyType: string;
    partyRoleFromCode?: string;
    partyRoleToCode?: string;
    roles?: Array<string>;
    productTypeCode?: string
    [x: string]: any;
}