import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValue } from 'src/app/types/keyvalue.type';

@Injectable({
  providedIn: 'root'
})
export class ActionBarService {

  public state$: Observable<{ code: string, state: KeyValue }>;
  public stateForAll$: Observable<{ code: string, state: KeyValue }>;

  private readonly _state = new EventEmitter<any>();
  private readonly _stateForAll = new EventEmitter<any>();

  constructor() {
    this.state$ = this._state.asObservable();
    this.stateForAll$ = this._stateForAll.asObservable();
  }

  public updateState(code: string, state: KeyValue) {
    this._state.emit({ code, state });
  }

  public updateStateForAll(code: string, state: KeyValue) {
    this._stateForAll.emit({ code, state });
  }
}
