import { DynafEnum } from '../enums/dynaf-sources';
import { DetailViewAction } from '../enums/detail-view-actions';

export interface Journey {
  code: string;
  title: string;
  description: string;
  isPromoteFlow: boolean;
}

export enum JourneyType {
  'Deploy',
  'Promote',
  'RunIntegrationCommand',
};

export interface JourneyStep {
  code: string;
  name: string;
  description: string;
  sequenceId: number;
  dynamicFormLibraryCode: DynafEnum;
  relatedDynamicFormLibraryCode: DynafEnum;
  workEffortExecutionId?: string;
  formOperationInstanceCode?: string;
  dynamicGridInstanceCode?: string;
  parentInstanceCode?: string;
  isApprovalFlow?: boolean;
  execution?: JourneyExecution;
  executorUserName: string;
  isJourneyNavigationStep: boolean;
  isMultipleBookNavigationStep: boolean;
  isAgreFormStep: boolean;
  subSteps: Array<JourneyStep>;
  isActiveSubStep: boolean;
  subIndex: number;
}

export interface JourneyExecution {
  metadata?: any;
  workEffortExecutionId: string;
  dynamicFormLibraryCode: string;
  formOperationInstanceCode: string;
  parentInstanceCode?: string;
  sequenceId: number;
  isApprovalFlow: boolean;
  navigationRules: Array<JourneyNavigationRule>;
}

export interface JourneyNavigationRule {
  ruleId: string;
  description: string;
  factorWorkEffortInstanceCode: string;
  outcomeWorkEffortInstanceCode: string;
  factorResolutionStatus: string;
  fireSaveAction: boolean;
}

export interface JourneyContext {
  action?: DetailViewAction;
  flowInstanceCode: string;
  executionId: string;
  isApprovalFlow?: boolean;
  entityInstanceCode?: string;
  entityInstanceName?: string;
  entityChangeRequestCode?: Array<string>;
  entityDynaf?: DynafEnum;
  requestCode?: string;
  entityOwnerPartyRoleInstanceCode?: string;
  approvalWorkEffortInstanceCode?: string;
  ruleAction?: string;
  executorUserName?: string;
  tenantCodeSource?: string;
  tenantCodeTarget?: string;
  isPromoteRequest?: boolean;
  integrationCommand?: any;
}

export interface JourneyStepsCached {
  code: string;
  steps: Array<JourneyStep>;
}

export declare type JourneyActivity = 'In' | 'Out';

export enum ActionResult {
  ValidForm = 'ValidForm',
  InvalidForm = 'InvalidForm'
}
