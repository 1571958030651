import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize, catchError, tap, delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PageLoaderService } from 'ngx-emerios-all';
import { MessageService } from '../services/behavior/message/message.service';
import { RestCacheService } from '../services/rest-cache/rest-cache.service';
import { WaitingLoaderService } from '../services/behavior/waiting-loader/waiting-loader.service';
import { AuthorizationHandlerService } from '../services/handler/authorization-handler/authorization-handler.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalInterceptor implements HttpInterceptor {

    constructor(
        private _authHandler: AuthorizationHandlerService,
        private _pageLoader: PageLoaderService,
        private _waitingLoader: WaitingLoaderService,
        private _restCache: RestCacheService,
        private _message: MessageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const hideRouter = request.headers.get('X-META-DATA-HIDE-ROUTER') === 'true';
        const showLoader = request.headers.get('showFullPageLoading') === 'true';
        const silentError = request.headers.get('silentError') === 'true';
        const useRestCache = request.headers.get('restCache') === 'true';
        const workingOrganization = this._authHandler.getWorkingOrganization();

        if (workingOrganization) {
            request = request.clone({
                setHeaders: {
                    PRIC: workingOrganization.partyRoleInstanceCode,
                    PRID: workingOrganization.partyRoleId,
                    PIC: workingOrganization.partyInstanceCode, 
                    PPPRID: workingOrganization.platformProviderPartyRoleId,
                    PPPRIC: workingOrganization.platformProviderPartyRoleInstanceCode
                }
            });
        }

        if (showLoader) {
            hideRouter ? this._pageLoader.showHidingRouter() : this._pageLoader.show();
        }

        if (useRestCache) {
            const body = this._restCache.getFromCache(request.urlWithParams);
            if (body) {
                return of(new HttpResponse(
                    { status: 200, body: body }
                )).pipe(delay(100));
            }
        }

        return next.handle(request)
            .pipe(
                tap((event) => {
                    if (event instanceof HttpResponse) {
                        useRestCache ? this._restCache.setOrUpdateResponse(event) : undefined;
                    }
                }),
                catchError((response: HttpErrorResponse) => {
                    if (response.status === 500 || response.status === 0) {
                        this._handle500Error(response, silentError);
                    }
                    throw response;
                }),
                finalize(() => {
                    if (showLoader) {
                        this._waitingLoader.hide()
                    }
                })
            );
    }

    private _handle500Error(response: HttpErrorResponse, avoidPageRedirect: boolean) {
        this._waitingLoader.hide();

        this._message.handle(response.error, avoidPageRedirect)
    }
}

export let GlobalInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: GlobalInterceptor,
    multi: true
};
