import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ZipcodeData } from './chained-filter-counter.model';

@Component({
  selector: 'app-chained-filter-counter',
  templateUrl: './chained-filter-counter.component.html',
  styleUrls: ['./chained-filter-counter.component.sass']
})

export class ChainedFilterCounterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public counter: string;
  @Input() public label: string;
  @Input() public data: Array<ZipcodeData>;
  @Input() public buttonsEnabled: boolean;

  @Output() public actionFired: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  ngOnChanges(): void { }

  ngOnDestroy(): void { }

  public fireEvent(action: string) {
    this.actionFired.emit(action);
  }
}
