/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rules-grid.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading/ghost-loading/ghost-loading.component.ngfactory";
import * as i3 from "../loading/ghost-loading/ghost-loading.component";
import * as i4 from "../grid/grid.component.ngfactory";
import * as i5 from "../grid/grid.component";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "./rules-grid.component";
import * as i9 from "../../services/handler/field-value-handler/field-value-handler.service";
import * as i10 from "../../services/handler/detail-view-handler/detail-view-handler.service";
import * as i11 from "../../services/helper/aggrid-column-helper/aggrid-column-helper.service";
import * as i12 from "../../services/handler/grid-handler/grid-handler.service";
var styles_RulesGridComponent = [i0.styles];
var RenderType_RulesGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RulesGridComponent, data: {} });
export { RenderType_RulesGridComponent as RenderType_RulesGridComponent };
function View_RulesGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading", [], null, null, null, i2.View_GhostLoadingComponent_0, i2.RenderType_GhostLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GhostLoadingComponent, [], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_0 = "aggrid"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RulesGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "grid grid-70"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-grid", [], null, [[null, "cellValueChanged"], [null, "gridApi"], [null, "firstDataRendered"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cellValueChanged" === en)) {
        var pd_0 = (_co.onCellValueChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("gridApi" === en)) {
        var pd_1 = (_co.gridApiReady($event) !== false);
        ad = (pd_1 && ad);
    } if (("firstDataRendered" === en)) {
        var pd_2 = (_co.onFirstDataRendered($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_GridComponent_0, i4.RenderType_GridComponent)), i1.ɵdid(3, 770048, null, 0, i5.GridComponent, [], { data: [0, "data"] }, { cellValueChanged: "cellValueChanged", gridApi: "gridApi", firstDataRendered: "firstDataRendered" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.gridModel; _ck(_v, 3, 0, currVal_0); }, null); }
function View_RulesGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "app-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "input-error file-upload-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getControlErrors(); _ck(_v, 2, 0, currVal_0); }); }
export function View_RulesGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RulesGridComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RulesGridComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RulesGridComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.loadingGrid; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.model.loadingGrid; _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.control && _co.control.errors) && !_co.control.pristine); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_RulesGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-rules-grid", [], null, null, null, View_RulesGridComponent_0, RenderType_RulesGridComponent)), i1.ɵprd(5120, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.RulesGridComponent]), i1.ɵdid(2, 770048, null, 0, i8.RulesGridComponent, [i9.FieldValueHandlerService, i10.DetailViewHandlerService, i11.AggridColumnHelperService, i12.GridHandlerService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var RulesGridComponentNgFactory = i1.ɵccf("app-rules-grid", i8.RulesGridComponent, View_RulesGridComponent_Host_0, { config: "config", definition: "definition", forceValidation: "forceValidation", editMode: "editMode" }, { selectionChanged: "selectionChanged" }, []);
export { RulesGridComponentNgFactory as RulesGridComponentNgFactory };
