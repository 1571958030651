export enum RouteEnum {
    Login = 'login',
    Redirect = 'redirect',
    OrganizationSelector = 'tenant-selector',
    Message = 'msg',
    ChangeManagementPlatformOld = 'campaign-builder',
    ChangeManagementPlatform = 'cmp',
    Unavailable = 'unavailable',
    Dashboard = 'dashboard',
    Config = 'config',
    ConfigList = 'list',
    ConfigDetail = 'detail',
    BackgroundTaskDetail = 'background-job'
}