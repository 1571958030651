import { FormDefinition } from './form-field-definition.models';
import { DynafEnum } from '../enums/dynaf-sources';

export enum FormOperationType {
    Identify = 'IDENTIFY',
    Create = 'CREATE',
    Edit = 'EDIT',
    View = 'VIEW',
    Approve = 'APPROVE'
}

export enum FormOperationTypeShortCode {
    Identiy = 'I',
    Create = 'C',
    Edit = 'E',
    View = 'V',
    Approve = 'A'
}

export interface FormOperation {
    code: FormOperationType;
    source: string;
}

export interface FormOperationDefinition {
    source: DynafEnum;
    operation: FormOperationType;
    formInstance: string;
    definitions: Array<FormInstance>
}

export interface FormInstance {
    code: string;
    name: string;
    default: boolean;
    definition: Array<FormDefinition>;
}