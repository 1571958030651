import { Routes } from '@angular/router';
import { CbViewerComponent } from './modules/campaign-builder/cb-viewer/cb-viewer.component';
import { LoginGuard } from './guards/login/login.guard';
import { AuthGuard } from './guards/auth/auth.guard';
import { RouteEnum } from './enums/routes';
const ɵ0 = () => import("./modules/redirect-page/redirect-page.module.ngfactory")
    .then(m => m.RedirectPageModuleNgFactory), ɵ1 = () => import("./modules/login-page/login-page.module.ngfactory")
    .then(m => m.LoginPageModuleNgFactory), ɵ2 = () => import("./modules/tenant-selector-page/tenant-selector-page.module.ngfactory")
    .then(m => m.TenantSelectorPageModuleNgFactory), ɵ3 = () => import("./modules/message-page/message-page.module.ngfactory")
    .then(m => m.MessagePageModuleNgFactory), ɵ4 = () => import("./modules/campaign-builder/campaign-builder.module.ngfactory")
    .then(m => m.CampaignBuilderModuleNgFactory);
const routes = [
    { path: '', pathMatch: 'full', redirectTo: RouteEnum.Unavailable },
    { path: RouteEnum.ChangeManagementPlatformOld, redirectTo: RouteEnum.ChangeManagementPlatform },
    {
        path: RouteEnum.Redirect, loadChildren: ɵ0, canActivate: [AuthGuard]
    },
    {
        path: RouteEnum.Login, loadChildren: ɵ1, canActivate: [LoginGuard]
    },
    {
        path: RouteEnum.OrganizationSelector, loadChildren: ɵ2, canActivate: [AuthGuard]
    },
    {
        path: RouteEnum.Message, loadChildren: ɵ3
    },
    {
        path: RouteEnum.ChangeManagementPlatform, component: CbViewerComponent, canActivate: [AuthGuard],
        children: [
            {
                path: '', loadChildren: ɵ4
            }
        ]
    },
    { path: '**', redirectTo: RouteEnum.Login, pathMatch: 'full' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
