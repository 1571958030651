import { Injectable, EventEmitter } from '@angular/core';
import { AuthRestService } from '../../rest/auth-rest/auth-rest.service';
import { catchError, filter } from 'rxjs/operators';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { AvailableTenant } from 'src/app/models/available-tenant.model';
import { NavigationHelperService } from '../../helper/navigation-helper/navigation-helper.service';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { AvailablePlatformProvider } from 'src/app/models/available-platform-provider.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationHandlerService {
  public readonly isAuthenticated$: Observable<boolean>;
  public readonly availableTenants$: Observable<Array<AvailableTenant>>;
  public readonly workingOrganization$: Observable<AvailableTenant>;
  public readonly successAuth$: Observable<any>;
  public readonly failedAuth$: Observable<string>;
  public readonly availablePlatformProviders$: Observable<Array<AvailablePlatformProvider>>;

  private readonly _isAuthenticated = new BehaviorSubject<boolean>(undefined);
  private readonly _availableTenants = new EventEmitter<Array<AvailableTenant>>(undefined);
  private readonly _workingOrganization = new BehaviorSubject<AvailableTenant>(undefined);
  private readonly _successAuth = new EventEmitter();
  private readonly _failedAuth = new EventEmitter<string>();
  private readonly _availablePlatformProviders = new EventEmitter<Array<AvailablePlatformProvider>>(undefined);

  constructor(
    private _authRest: AuthRestService,
    private _navHelper: NavigationHelperService,
    private _dashboardHandler: DashboardHandlerService) {
    this.isAuthenticated$ = this._isAuthenticated.asObservable().pipe(filter(x => x !== undefined));
    this.availableTenants$ = this._availableTenants.asObservable().pipe(filter(x => x !== undefined));
    this.workingOrganization$ = this._workingOrganization.asObservable();
    this.successAuth$ = this._successAuth.asObservable();
    this.failedAuth$ = this._failedAuth.asObservable();
    this.availablePlatformProviders$ = this._availablePlatformProviders.asObservable().pipe(filter(x => x !== undefined));

    this._workingOrganization.next(this.getWorkingOrganization());
  }

  public authenticate(username: string, password: string, platformProviderCode, singleSignOn: boolean, recaptchaToken: string) {
    this.logout();

    this._authRest.login(username, password, platformProviderCode, singleSignOn, recaptchaToken)
      .pipe(catchError((error) => {
        this._failedAuth.next(error.message);
        this._isAuthenticated.next(false);
        return throwError(error)
      }))
      .subscribe((response) => {
        if (response.access_token) {
          sessionStorage.setItem(CacheKeyEnum.AccessToken, response.access_token);
          sessionStorage.setItem(CacheKeyEnum.Username, username);
          
          this._authRest.getPermissions(platformProviderCode)
            .subscribe((response) => {
              sessionStorage.setItem(CacheKeyEnum.Permissions, JSON.stringify(response));
              this._dashboardHandler.initDasboardItemList();
            });

            this._successAuth.next();
            this._isAuthenticated.next(true);
  
          } else {
          this._failedAuth.next(response.error);
        }
      });
  }

  public logout() {
    sessionStorage.removeItem(CacheKeyEnum.AccessToken);
    sessionStorage.removeItem(CacheKeyEnum.Username);
    sessionStorage.removeItem(CacheKeyEnum.WorkingOrganization);
    sessionStorage.removeItem(CacheKeyEnum.Permissions);
    sessionStorage.removeItem(CacheKeyEnum.UserSignature);
  }

  public getToken() {
    return sessionStorage.getItem(CacheKeyEnum.AccessToken);
  }

  public getCurrentUsername() {
    const currentUser = sessionStorage.getItem(CacheKeyEnum.Username);

    if (!currentUser) {
      this.logout();
      this._navHelper.goToLogin();
      throw 'Current user is not valid';
    }

    return currentUser;
  }

  public isAuthenticated() {
    return this.getToken() != undefined;
  }

  public setAuthenticated(value: boolean) {
    this._isAuthenticated.next(value);
  }

  public isWorkingOrganizationSetted() {
    const value = this._workingOrganization.getValue();
    // call set method to force session refresh of current value of observable
    this.setWorkingOrganization(value);

    return value != undefined;
  }

  public getAvailableTenants() {
    const username = this.getCurrentUsername();

    this._authRest.getTenants(username)
      .subscribe(tenants => {
        this._availableTenants.next(tenants);
      });
  }

  public getWorkingOrganization(): AvailableTenant {
    return JSON.parse(sessionStorage.getItem(CacheKeyEnum.WorkingOrganization));
  }

  public setWorkingOrganization(organization: AvailableTenant) {
    sessionStorage.setItem(CacheKeyEnum.WorkingOrganization, JSON.stringify(organization));
    this._workingOrganization.next(organization);
  }

  public clearWorkingOrganization() {
    sessionStorage.removeItem(CacheKeyEnum.WorkingOrganization);
    this._workingOrganization.next(undefined);
  }

  public getAvailablePlatformProviders() {
    this._authRest.getPlatformProviders()
      .subscribe(providers => {
        this._availablePlatformProviders.next(providers);
      });
  }
}
