import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { Observable, of } from 'rxjs';
import { AgGridModel } from 'src/app/models/campaign-builder.models';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { WorkeffortExecutionStageResponse, WorkeffortJourneyHierarchicalFlowResponse } from 'src/app/models/workeffort.models';
import { PaginationDto } from 'src/app/models/server-side-pagination';

@Injectable({
  providedIn: 'root'
})
export class GridRestService {

  private readonly _silentErrorHeader: HttpHeaders;

  constructor(private _rest: RestService) {
    this._silentErrorHeader = new HttpHeaders()
      .append('silentError', 'true');
  }

  public getGenericGridDataAndDetinitions(source: string, detailCode: string = ''): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/${source}/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getQuestionRulesGridDataAndDef(source: string, workEffortTypeLibraryCode: string, ownerPartyRoleInstanceCode: string): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/${source}/${workEffortTypeLibraryCode}/${ownerPartyRoleInstanceCode}/question-rules`
    });
  }

  public getGenericGridDataAndDetinitionsV2(source: string, entity: string, codes?: string[], ownerPartyRoleInstanceCode?: string): Observable<AgGridModel> {
    let params = null;

    if (codes) {
      params = { parameters: codes };
    }

    if (ownerPartyRoleInstanceCode) {
      params = { ownerPartyRoleInstanceCode: ownerPartyRoleInstanceCode };
    }

    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/list/${source}`,
      params: params
    });
  }

  public getPaginatedData(source: string, entity: string, payload: PaginationDto): Observable<AgGridModel> {
    return this._rest.post<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/list/${source}`,
      body: payload,
    });
  }

  public getHierarchyGridDataAndDetinitions(source: string, entity: string): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/hierarchy/${source}`
    });
  }

  public getParentsRelationshipsDataAndDef(agGridSource: string, entity: string, detailCode: string): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/relationship/parents/list/${agGridSource}/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getChildrenRelationshipsDataAndDef(agGridSource: string, entity: string, detailCode: string): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/relationship/children/list/${agGridSource}/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getParentRelationshipsDataAndDefPaginated(agGridSource: string, entity: string, detailCode: string, payload: PaginationDto): Observable<AgGridModel> {
    return this._rest.post<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/relationship/parents/list/${agGridSource}/${detailCode}`,
      headers: this._silentErrorHeader,
      body: payload,
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getChildrenRelationshipsDataAndDefPaginated(agGridSource: string, entity: string, detailCode: string, payload: PaginationDto): Observable<AgGridModel> {
    return this._rest.post<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/relationship/children/list/${agGridSource}/${detailCode}`,
      headers: this._silentErrorHeader,
      body: payload,
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getGridDefinitionBySource(source: string): Observable<Array<any>> {
    return this._rest.get<Array<any>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/${source}/grid-column-definition`
    });
  }

  public getGridDefinitionBySourceV2(source: string): Observable<Array<any> | any> {
    return this._rest.get<Array<any>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/${source}/grid-definition`
    });
  }

  public getChangesGridData(changeRequest: string): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/history/${changeRequest}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => of([])));
  }

  public getPromoteGridData(changeRequest: string): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/request/promote/${changeRequest}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => of([])));
  }

  public getCampaignCombinations(detailCode: string): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/campaign/combinations/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getQuestionsByOwnerAndContainerType(owner: string, containerType: string): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/question/owner-container/${owner}/${containerType}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getQuestionsParentTrigger(): Observable<AgGridModel> {
    return this._rest.get<AgGridModel>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/question/parent-trigger`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getHierarchicalView(detailCode: string): Observable<Array<WorkeffortExecutionStageResponse>> {
    return this._rest.get<Array<WorkeffortExecutionStageResponse>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/request/execution/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => []));
  }

  public getFlowJourneyHierarchicalView(type: string, detailCode: string): Observable<Array<WorkeffortJourneyHierarchicalFlowResponse>> {
    return this._rest.get<Array<WorkeffortExecutionStageResponse>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort/flow/${type}/tree-view/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => []));
  }

  public getTpvCampaignProducts(detailCode: string): Observable<AgGridModel> {
    return this._rest.get<Array<any>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/campaign/products/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getEventFlowView(detailCode: string, type: string): Observable<AgGridModel> {
    return this._rest.get<Array<any>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort/flow/${type}/workeffort-events/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

  public getBookItems(detailCode: string, bookType: string): Observable<AgGridModel> {
    return this._rest.get<Array<any>>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${bookType}/items/${detailCode}`,
      headers: this._silentErrorHeader
    }).pipe(catchError(() => this._catchGridError()));
  }

    private _catchGridError() {
    return of({
      metadata: { columnDefinition: [] },
      gridColumnDefinition: [], // this will be removed when all aggrid were migrated
      rows: []
    } as AgGridModel);
  }
}
