import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CacheService } from 'ngx-emerios-all';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { DetailViewHandlerService } from '../../handler/detail-view-handler/detail-view-handler.service';
import { ElementHelperService } from '../../helper/element-helper/element-helper.service';
import { EntityRestService } from '../../rest/entity-rest/entity-rest.service';
import { UtilService } from '../util/util.service';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { NavigationHelperService } from '../../helper/navigation-helper/navigation-helper.service';
import { NavigationUrlEnum } from 'src/app/enums/navigation-url';
import { FormOperationType } from 'src/app/models/operation.models';
import { JourneyHandlerService } from '../../handler/journey-handler/journey-handler.service';
import { filter } from 'rxjs/internal/operators/filter';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../handler/dashboard-handler/dashboard-handler.service";
import * as i3 from "ngx-emerios-all";
import * as i4 from "../../handler/detail-view-handler/detail-view-handler.service";
import * as i5 from "../../rest/entity-rest/entity-rest.service";
import * as i6 from "../util/util.service";
import * as i7 from "../../helper/navigation-helper/navigation-helper.service";
import * as i8 from "../../helper/element-helper/element-helper.service";
import * as i9 from "../../handler/journey-handler/journey-handler.service";
export var BreadcrumbItemType;
(function (BreadcrumbItemType) {
    BreadcrumbItemType[BreadcrumbItemType["Entity"] = 0] = "Entity";
    BreadcrumbItemType[BreadcrumbItemType["Instance"] = 1] = "Instance";
})(BreadcrumbItemType || (BreadcrumbItemType = {}));
export var BreadcrumbMode;
(function (BreadcrumbMode) {
    BreadcrumbMode[BreadcrumbMode["Entity"] = 0] = "Entity";
    BreadcrumbMode[BreadcrumbMode["Journey"] = 1] = "Journey";
})(BreadcrumbMode || (BreadcrumbMode = {}));
export class BreadcrumbService {
    constructor(_router, _dashboardHandler, _cache, _detailViewHandler, _entity, _utils, _navHelper, _elementHelper, _journeyHandler) {
        this._router = _router;
        this._dashboardHandler = _dashboardHandler;
        this._cache = _cache;
        this._detailViewHandler = _detailViewHandler;
        this._entity = _entity;
        this._utils = _utils;
        this._navHelper = _navHelper;
        this._elementHelper = _elementHelper;
        this._journeyHandler = _journeyHandler;
        this._breadcrumb = new BehaviorSubject([]);
        this._stateClass = new BehaviorSubject(undefined);
        this._breadcrumbMode = new BehaviorSubject(undefined);
        this._breadcrumbList = [];
        this._subscriptions = [];
        this._agreJourneySteps = [];
        this.breadcrumb$ = this._breadcrumb.asObservable();
        this.stateClass$ = this._stateClass.asObservable();
        this.breadcrumbMode$ = this._breadcrumbMode.asObservable();
        this._init();
    }
    clearItems() {
        this._cache.remove(CacheKeyEnum.Breadcrumb);
        this._breadcrumbList.length = 0;
    }
    go(item, index) {
        this._breadcrumbList.splice(index + 1);
        this._breadcrumbList[this._breadcrumbList.length - 1].current = true;
        this._persist();
        if (item.dashboardItem) {
            this._dashboardHandler.setCurrrentDashboardItemByCode(item.dashboardItem.viewCode);
        }
        this._goTo(item.url);
        this.clearClass();
    }
    goHome() {
        const lastDashboardCategory = this._cache.get(CacheKeyEnum.CurrentDashboardCategory);
        this._navHelper.goToDashboard(lastDashboardCategory);
    }
    applyClass(className) {
        this._stateClass.next(className);
    }
    clearClass() {
        this._stateClass.next(undefined);
    }
    initForJourneyNavigation(data) {
        const breadcrumbContent = this._setBreadcrumbContent(data.title, data.code, 'Flow', null);
        this._addItem(breadcrumbContent, null, BreadcrumbItemType.Entity, null);
        this._breadcrumbMode.next(BreadcrumbMode.Journey);
    }
    finishJourneyNavigation() {
        this._breadcrumbMode.next(BreadcrumbMode.Entity);
    }
    addEntity(dashboardItem, presetView) {
        let indexOf;
        const breadcrumbContent = this._setBreadcrumbContent(null, presetView.code, dashboardItem.name, 'Select');
        if ([AgGridEnum.BackgroundJobs, AgGridEnum.PendingRequest].includes(dashboardItem.gridSource)) {
            indexOf = -1;
            this._breadcrumbList.pop();
        }
        else {
            indexOf = this._getObjectIndexByType(BreadcrumbItemType.Entity);
        }
        if (indexOf !== -1) {
            this._updateEntity(breadcrumbContent, dashboardItem, indexOf + 1, presetView);
        }
        else {
            this._addItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Entity);
        }
    }
    addInstance(name, code, dashboardItem, journeyNavigationActive, url, sequenceId, parentInstanceCode, currentJourneyStep) {
        if (dashboardItem.name === 'Flow' && this._viewMode !== 'Create') {
            sequenceId = 1;
        }
        if (journeyNavigationActive) {
            this._addInstanceDuringJourneyNavigation(code, dashboardItem, currentJourneyStep);
        }
        else {
            const breadcrumbContent = this._setBreadcrumbContent(name, code, dashboardItem.name, this._viewMode, sequenceId);
            if (this._getObjectIndexByType(BreadcrumbItemType.Entity) === -1) {
                this._createBreadcrumbFromRedirect(name, code, dashboardItem, url, sequenceId, parentInstanceCode);
            }
            else {
                if (this._getObjectIndexByType(BreadcrumbItemType.Instance) !== -1 && name == 'Create') {
                    this._updateCurrentItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, url);
                }
                else {
                    this._addItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, url);
                }
            }
        }
    }
    _addInstanceDuringJourneyNavigation(code, dashboardItem, currentJourneyStep) {
        const breadcrumbContent = this._setBreadcrumbContent(currentJourneyStep.name, code, dashboardItem.name, null);
        this._breadcrumbList.forEach(b => b.current = false);
        if (currentJourneyStep) {
            if (this._isAgreNavigationStep(currentJourneyStep)) {
                let urlSection = 'workeffort/stage';
                const stageLibraryCode = this._utils.getLibraryCodeFromInstance(currentJourneyStep.parentInstanceCode);
                urlSection = `${urlSection}/${stageLibraryCode}`;
                this._breadcrumbList = this._breadcrumbList.filter(item => item.type === 0);
                this._entity.getDataEntity(urlSection, currentJourneyStep.parentInstanceCode)
                    .subscribe(data => {
                    const stageBreadcrumbContent = this._setBreadcrumbContent(data.instanceName, data.instanceCode, 'Stage', null);
                    this._insertBreadcrumbitem(stageBreadcrumbContent, null, BreadcrumbItemType.Instance, null, false);
                    this._setAgreNavigationStep(breadcrumbContent, dashboardItem);
                    this._persist();
                });
            }
            else {
                this._setStageStepItems(currentJourneyStep.parentInstanceCode, breadcrumbContent);
            }
        }
    }
    _setStageStepItems(parentStageInstanceCode, stepBreadcrumbContent) {
        let urlSection = 'workeffort/stage';
        const stageLibraryCode = this._utils.getLibraryCodeFromInstance(parentStageInstanceCode);
        urlSection = `${urlSection}/${stageLibraryCode}`;
        this._breadcrumbList = this._breadcrumbList.filter(item => item.type === 0);
        this._entity.getDataEntity(urlSection, parentStageInstanceCode)
            .subscribe(data => {
            stepBreadcrumbContent.instanceType = 'Step';
            const stageBreadcrumbContent = this._setBreadcrumbContent(data.instanceName, data.instanceCode, 'Stage', null);
            this._insertBreadcrumbitem(stageBreadcrumbContent, null, BreadcrumbItemType.Instance, null, false);
            this._insertBreadcrumbitem(stepBreadcrumbContent, null, BreadcrumbItemType.Instance, null, true);
            this._persist();
        });
    }
    _setAgreNavigationStep(breadcrumbContent, dashboardItem) {
        breadcrumbContent.isAgreNavigationStep = true;
        if (this._breadcrumbList.find(item => item.breadcrumbContent.isAgreNavigationStep)) {
            this._updateCurrentAgreStepItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, null);
        }
        else {
            this._addItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, null);
        }
    }
    _isAgreNavigationStep(currentJourneyStep) {
        return this._agreJourneySteps.find(step => currentJourneyStep && currentJourneyStep.code === step.code);
    }
    updateInstance(detailName, detailCode, url, viewMode) {
        const dashboardItem = this._breadcrumbList
            .find(bi => bi.type === BreadcrumbItemType.Entity).dashboardItem;
        const breadcrumbContent = this._setBreadcrumbContent(detailName, detailCode, dashboardItem.name, viewMode ? viewMode : this._viewMode);
        this._updateCurrentItem(breadcrumbContent, dashboardItem, BreadcrumbItemType.Instance, url);
    }
    getBreadcrumbMultiSelectConfig() {
        const items = this._getBreadcrumbFiltersItems();
        const attrs = { placeholder: '-- Select --', editable: true, required: true };
        const config = this._elementHelper.getMultiSelectConfig(attrs, items);
        config.singleSelect = true;
        config.allowSearchFilter = false;
        config.validationIcons = undefined;
        config.name = 'breadcrumb-multiselect';
        return config;
    }
    getBreadcrumbSelection() {
        return this._cache.get('breadcrumbSelection');
    }
    updateBreadcrumbSelection(selection) {
        this._cache.set('breadcrumbSelection', selection);
    }
    updateOperation(viewMode) {
        const hasInstanceToUpdate = this._getObjectIndexByType(BreadcrumbItemType.Instance) > -1;
        if (hasInstanceToUpdate && this._viewMode !== 'Create' && this._breadcrumbMode.value != BreadcrumbMode.Journey) {
            const currentItem = this._breadcrumbList.find(x => x.current);
            currentItem.breadcrumbContent.viewMode = viewMode ? viewMode : this._viewMode;
        }
    }
    getVariables(viewCode, source, instanceCode, instanceName) {
        const commonUrl = `${NavigationUrlEnum.BaseFormUrl}/${viewCode}/${source}`;
        let breadcrumbName = viewCode.charAt(0).toUpperCase() + viewCode.slice(1);
        let url = commonUrl;
        if (instanceCode) {
            url = `${commonUrl}/${instanceCode}`;
            breadcrumbName = instanceName ? `${instanceName}` : `${instanceCode}`;
        }
        else {
            instanceCode = 'NEW';
        }
        return {
            code: instanceCode,
            name: breadcrumbName,
            url: url
        };
    }
    ngOnDestroy() {
        this._subscriptions.forEach(s => s.unsubscribe());
    }
    _init() {
        const cached = this._cache.get(CacheKeyEnum.Breadcrumb);
        if (cached && cached.items) {
            this._breadcrumbList = cached.items;
            this._homeEntityUrl = cached.homeUrl;
            this._breadcrumb.next(this._breadcrumbList);
        }
        this._registerSubscriptions();
    }
    _registerSubscriptions() {
        this._subscriptions.push(this._detailViewHandler.mode$
            .subscribe(mode => {
            this._viewMode = `${mode.charAt(0)}${mode.slice(1).toLocaleLowerCase()}`;
            this.updateOperation();
        }));
        this._subscriptions.push(this._dashboardHandler.dashboardItemList$
            .subscribe(dashboardItemList => {
            this._dashboardItemList = dashboardItemList;
        }));
        this._subscriptions.push(this._journeyHandler.journeyNavigationFormSteps$
            .pipe(filter(steps => steps != undefined))
            .subscribe(steps => {
            this._agreJourneySteps = steps;
        }));
    }
    _persist() {
        const config = {
            homeUrl: this._homeEntityUrl,
            items: this._breadcrumbList
        };
        this._breadcrumb.next(config.items);
        this._cache.set(CacheKeyEnum.Breadcrumb, config);
    }
    _addItem(breadcrumbContent, dashboardItem, breadcrumbItemType, url) {
        const currentItem = this._breadcrumbList.filter(b => b.current)[0];
        if (!url) {
            url = this._router.url;
        }
        if (!currentItem || currentItem.url !== url) {
            const indexOf = this._breadcrumbList.findIndex(bi => bi.url === url);
            this._breadcrumbList.forEach(b => b.current = false);
            if (indexOf == -1) {
                this._insertBreadcrumbitem(breadcrumbContent, url, breadcrumbItemType, dashboardItem, true);
            }
            else {
                this._updateBreadcrumbIndex(indexOf);
            }
            this._persist();
        }
    }
    _updateCurrentItem(breadcrumbContent, dashboardItem, breadcrumbItemType, url) {
        const currentObject = this._breadcrumbList.find(bi => bi.type == BreadcrumbItemType.Instance);
        if (!url) {
            url = this._router.url;
        }
        if (this._breadcrumbMode.value != BreadcrumbMode.Journey) {
            breadcrumbContent.viewMode = breadcrumbContent.viewMode === FormOperationType.Identify ? 'Identify' : 'View';
        }
        currentObject.dashboardItem = dashboardItem;
        currentObject.type = breadcrumbItemType;
        currentObject.url = url;
        currentObject.breadcrumbContent = breadcrumbContent;
        this._persist();
    }
    _updateCurrentAgreStepItem(breadcrumbContent, dashboardItem, breadcrumbItemType, url) {
        const currentAgreStep = this._breadcrumbList.find(bi => bi.breadcrumbContent.isAgreNavigationStep);
        if (!url) {
            url = this._router.url;
        }
        currentAgreStep.dashboardItem = dashboardItem;
        currentAgreStep.type = breadcrumbItemType;
        currentAgreStep.url = url;
        currentAgreStep.breadcrumbContent = breadcrumbContent;
        this._persist();
    }
    _insertBreadcrumbitem(bcContent, url, bcItemType, dashboardItem, current) {
        const breadcrumbItemToInsert = {
            dashboardItem: dashboardItem,
            url: url,
            type: bcItemType,
            current: current,
            breadcrumbContent: bcContent
        };
        this._breadcrumbList.push(breadcrumbItemToInsert);
    }
    _setBreadcrumbContent(name, code, type, viewMode, sequenceId) {
        return {
            instanceName: name,
            instanceCode: code,
            instanceType: type,
            sequenceId: sequenceId,
            viewMode: viewMode
        };
    }
    _createBreadcrumbFromRedirect(name, code, dashboardItem, url, sequenceId, parentInstanceCode) {
        const entityUrl = `${NavigationUrlEnum.BaseGridUrl}/${dashboardItem.viewCode}/${dashboardItem.gridSource}`;
        const defaultSequenceForFlow = 1;
        this.clearItems();
        if (dashboardItem.name === 'Stage' || dashboardItem.name === 'Step') {
            this._setFlowBreadcrumb(dashboardItem, parentInstanceCode, name, code, url, sequenceId);
        }
        else {
            sequenceId = dashboardItem.name === 'Flow' ? defaultSequenceForFlow : sequenceId;
            const breadcrumbContent1 = this._setBreadcrumbContent(this._presetViewSelectedName, this._presetViewSelectedCode, dashboardItem.name, 'Select');
            const breadcrumbContent2 = this._setBreadcrumbContent(name, code, dashboardItem.name, this._viewMode, sequenceId);
            this._insertBreadcrumbitem(breadcrumbContent1, entityUrl, BreadcrumbItemType.Entity, dashboardItem, false);
            this._insertBreadcrumbitem(breadcrumbContent2, url, BreadcrumbItemType.Instance, dashboardItem, true);
        }
        this._persist();
    }
    _setFlowBreadcrumb(dashboardItem, parentInstanceCode, name, code, url, sequenceId) {
        const flowDashboardItem = this._dashboardItemList.find(di => di.name === 'Flow');
        const stageDashboardItem = this._dashboardItemList.find(di => di.name === 'Stage');
        const flowEntityUrl = `${NavigationUrlEnum.BaseGridUrl}/${flowDashboardItem.viewCode}/${flowDashboardItem.gridSource}`;
        const breadcrumbContent = this._setBreadcrumbContent(this._presetViewSelectedName, this._presetViewSelectedCode, flowDashboardItem.name, 'Select');
        this._insertBreadcrumbitem(breadcrumbContent, flowEntityUrl, BreadcrumbItemType.Entity, flowDashboardItem, false);
        if (dashboardItem.name === 'Step' && parentInstanceCode) {
            let urlSection = stageDashboardItem.overrideViewCode || stageDashboardItem.viewCode;
            let type = this._utils.getLibraryCodeFromInstance(parentInstanceCode);
            urlSection = `${urlSection}/${type}`;
            this._entity.getDataEntity(urlSection, parentInstanceCode)
                .subscribe(data => {
                const breadcrumbContent3 = this._setBreadcrumbContent(data.instanceName, data.instanceCode, stageDashboardItem.name, 'View', data.sequenceId);
                const stageViewUrl = `${NavigationUrlEnum.BaseGridUrl}/${stageDashboardItem.viewCode}/${stageDashboardItem.formSource}/${data.instanceCode}`;
                urlSection = flowDashboardItem.overrideViewCode || flowDashboardItem.viewCode;
                type = this._utils.getLibraryCodeFromInstance(data.parentInstanceCode);
                urlSection = `${urlSection}/${type}`;
                this._entity.getDataEntity(urlSection, data.parentInstanceCode)
                    .subscribe(data => {
                    const flowViewUrl = `${NavigationUrlEnum.BaseGridUrl}/${flowDashboardItem.viewCode}/${flowDashboardItem.formSource}/${data.instanceCode}`;
                    const breadcrumbContent2 = this._setBreadcrumbContent(data.instanceName, data.instanceCode, flowDashboardItem.name, 'View', 1);
                    const breadcrumbContent4 = this._setBreadcrumbContent(name, code, dashboardItem.name, 'View', sequenceId);
                    this._insertBreadcrumbitem(breadcrumbContent2, flowViewUrl, BreadcrumbItemType.Instance, flowDashboardItem, false);
                    this._insertBreadcrumbitem(breadcrumbContent3, stageViewUrl, BreadcrumbItemType.Instance, flowDashboardItem, false);
                    this._insertBreadcrumbitem(breadcrumbContent4, url, BreadcrumbItemType.Instance, dashboardItem, true);
                });
            });
        }
    }
    _updateBreadcrumbIndex(index) {
        this._breadcrumbList.splice(index + 1);
        this._breadcrumbList[index].current = true;
    }
    _updateEntity(breadcrumbContent, dashboardItem, index, presetView) {
        this._breadcrumbList.splice(index);
        const currentEntity = this._breadcrumbList[index - 1];
        const indexOfViewCode = currentEntity.url.lastIndexOf("/") + 1;
        const url = currentEntity.url.replace(currentEntity.url.substring(indexOfViewCode), presetView.viewCode);
        dashboardItem.presetView = presetView.viewCode;
        currentEntity.dashboardItem = dashboardItem;
        currentEntity.url = url;
        currentEntity.current = true;
        currentEntity.breadcrumbContent = breadcrumbContent;
    }
    _getObjectIndexByType(type) {
        return this._breadcrumbList.findIndex(element => element.type === type);
    }
    _goTo(url) {
        this._router.navigateByUrl(url);
    }
    _getBreadcrumbFiltersItems() {
        return [
            { code: 1, description: 'Instance Name' },
            { code: 2, description: 'Instance ID' }
        ];
    }
}
BreadcrumbService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreadcrumbService_Factory() { return new BreadcrumbService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.DashboardHandlerService), i0.ɵɵinject(i3.CacheService), i0.ɵɵinject(i4.DetailViewHandlerService), i0.ɵɵinject(i5.EntityRestService), i0.ɵɵinject(i6.UtilService), i0.ɵɵinject(i7.NavigationHelperService), i0.ɵɵinject(i8.ElementHelperService), i0.ɵɵinject(i9.JourneyHandlerService)); }, token: BreadcrumbService, providedIn: "root" });
