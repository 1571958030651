import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { FormDefinition } from 'src/app/models/form-field-definition.models';

export interface ViewModel {
  title: string;
  groupFields: Array<FormDefinition>;
}

export interface ModalParams {
  definition: Array<FormDefinition>;
}

@Component({
  selector: 'app-dynamic-form-preview',
  templateUrl: './dynamic-form-preview.component.html',
  styleUrls: ['./dynamic-form-preview.component.sass']
})
export class DynamicFormPreviewComponent implements OnInit {
  public model = {} as ViewModel;
  public params = {} as ModalParams;

  constructor(private _modal: BsModalRef) { }

  public closeModal() {
    this._modal.hide();
  }

  ngOnInit() {
    this.model.title = 'Form Preview';
    this.model.groupFields = this.params.definition;
  }

}
