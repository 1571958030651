<div class="modal-header">
    <h3 class="modal-title pull-left" [innerHtml]="title"></h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body campaign-modal error-detail-modal">
    <div class="row">
        <div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
          <div>
            <p class="error-title">
                Errors
            </p>
          </div>
          <ul class="error-list">
              <li *ngFor="let lines of errorLines">
                <p *ngFor="let line of lines" class="reference">
                  {{line}}
                </p>
              </li>
          </ul>
        </div>

        <!-- Error type 1 -->
        <div *ngIf="false" class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
            <!--Error title-->
            <div>
                <p class="error-title">
                    System.Exception: Deployment item CHANGE_REQUEST.70437 failed
                </p>
            </div>

            <!--Error list-->
            <ul class="error-list">
                <li>
                    <p class="description">---> System.Exception: "ExceptionMessage":"An error has occurred while executing this operation. Please try again."</p>
                    <p class="reference">at Emerios.CMP.Utils.HttpClientUtils.d__2`1.MoveNext() in C:\\Jenkins\\workspace\\DEMO_Deploy\\Sources\\Emerios.Integration\\Jobs\\Emerios.CMP\\Utils\\HttpClientUtils.cs:line 39</p>
                </li>

                <li>
                    <p class="description">--- End of stack trace from previous location where exception was thrown ---</p>
                    <p class="reference">at System.Runtime.CompilerServices.TaskAwaiter.ThrowForNonSuccess(Task task)</p>
                    <p class="reference">at System.Runtime.CompilerServices.TaskAwaiter.HandleNonSuccessAndDebuggerNotification(Task task)</p>
                    <p class="reference">at System.Runtime.CompilerServices.TaskAwaiter`1.GetResult()</p>
                    <p class="reference">at Emerios.CMP.Executors.Base.BaseChangeRequestExecutor`1.d__5`1.MoveNext() in C:\\Jenkins\\workspace\\DEMO_Deploy\\Sources\\Emerios.Integration\\Jobs\\Emerios.CMP\\Executors\\Base\\BaseChangeRequestExecutor.cs:line 32</p>
                </li>

                <li>
                    <p class="description">--- End of stack trace from previous location where exception was thrown ---</p>
                    <p class="reference">at System.Runtime.CompilerServices.TaskAwaiter.ThrowForNonSuccess(Task task)</p>
                    <p class="reference">at System.Runtime.CompilerServices.TaskAwaiter.HandleNonSuccessAndDebuggerNotification(Task task)</p>
                    <p class="reference">at System.Runtime.CompilerServices.TaskAwaiter`1.GetResult()</p>
                    <p class="reference">at Emerios.CMP.Executors.Base.BaseChangeRequestExecutor`1.d__5`1.MoveNext() in C:\\Jenkins\\workspace\\DEMO_Deploy\\Sources\\Emerios.Integration\\Jobs\\Emerios.CMP\\Executors\\Base\\BaseChangeRequestExecutor.cs:line 32</p>
                </li>
            </ul>
        </div>

        <!-- Error type 2 -->
        <div *ngIf="false" class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-10 col-lg-10">
            <!--Error title-->
            <div>
                <p class="error-title">
                    Error Title
                </p>
            </div>

            <!--Error list-->
            <ul class="error-list">
                <li>
                    <p class="description">Validation error when deploying CHANGE_REQUEST.48563</p>
                </li>
                <li>
                    <p class="description">Validation error when deploying CHANGE_REQUEST.48563</p>
                </li>
                <li>
                    <p class="description">Validation error when deploying CHANGE_REQUEST.48563</p>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-next" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary btn-fill"
        (click)="copyError()">Copy</button>
</div>
