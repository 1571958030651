import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmeriosComponentsModule } from './emerios-components.module';
import { DynamicFormGridContainerComponent } from '../components/dynamic-form-grid-container/dynamic-form-grid-container.component';
import { DynamicFormGroupContainerComponent } from '../components/dynamic-form-group-container/dynamic-form-group-container.component';
import { DynamicFormGroupComponent } from '../components/dynamic-form-group/dynamic-form-group.component';
import { DynamicFormGridComponent } from '../components/dynamic-form-grid/dynamic-form-grid.component';
import { InputLangComponent } from '../components/input-lang/input-lang.component';
import { SortableListComponent } from '../components/sortable-list/sortable-list.component';
import { LoadersModule } from '../components/loading/loaders.module';
import { ChangesGridComponent } from './campaign-builder/detail-view/changes-grid/changes-grid.component';
import { ChainedFilterComponent } from '../components/chained-filter/chained-filter.component';
import { ChainedFilterCounterComponent } from '../components/chained-filter/chained-filter-counter/chained-filter-counter.component';
import { GridModule } from '../components/grid/grid.module';
import { CoreComponentsModule } from './core-components.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TabGroupModule } from '../components/tab-group/tab-group.module';
import { TabbedRuleCreatorComponent } from '../components/tabbed-rule-creator/tabbed-rule-creator.component';
import { ReplacerListComponent } from '../components/replacer-list/replacer-list.component';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CatalogGridComponent } from '../components/catalog-grid/catalog-grid.component';
import { RulesGridComponent } from '../components/rules-grid/rules-grid.component';
import { TextareaFormPreviewComponent } from '../components/textarea-form-preview/textarea-form-preview.component';
import { ButtonFormPreviewComponent } from '../components/button-form-preview/button-form-preview.component';
import { RelationshipsGridComponent } from '../components/relationships-grid/relationships-grid.component';
import { ButtonActionComponent } from '../components/button-action/button-action.component';
import { UploadFileProcessingComponent } from '../components/upload-file-processing/upload-file-processing.component';
import { EntityListerComponent } from '../components/entity-lister/entity-lister.component';
import { NavigationRulesComponent } from '../components/navigation-rules/navigation-rules.component';
import { GridViewerFieldComponent } from '../components/grid-viewer-field/grid-viewer-field.component';
import { BackgroundTaskDetailComponent } from './background-task/background-task-detail.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { SignatureComponentsModule } from './signature/signature.module';
import { DateTimePickerComponent } from '../components/date-time-picker/date-time-picker.component';

const toExport = [
    DynamicFormGroupContainerComponent,
    DynamicFormGridContainerComponent,
    DynamicFormGroupComponent,
    DynamicFormGridComponent,
    InputLangComponent,
    SortableListComponent,
    ChangesGridComponent,
    ChainedFilterComponent,
    ChainedFilterCounterComponent,
    TabbedRuleCreatorComponent,
    ReplacerListComponent,
    FileUploadComponent,
    CatalogGridComponent,
    RulesGridComponent,
    TextareaFormPreviewComponent,
    ButtonFormPreviewComponent,
    RelationshipsGridComponent,
    ButtonActionComponent,
    UploadFileProcessingComponent,
    EntityListerComponent,
    NavigationRulesComponent,
    GridViewerFieldComponent,
    BackgroundTaskDetailComponent,
    DateTimePickerComponent
];

@NgModule({
    declarations: [toExport],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        LoadersModule,
        CoreComponentsModule,
        EmeriosComponentsModule,
        TabGroupModule,
        DragDropModule,
        NgxFileDropModule,
        SignatureComponentsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule
    ],
    entryComponents: [ReplacerListComponent],
    exports: [
        EmeriosComponentsModule,
        TabGroupModule,
        toExport
    ]
})
export class DynamicFormsComponentsModule { }
