export function sortBy(list: Array<any>, criteria: Array<{ prop: string, direction: 'asc' | 'desc' }>) {
    const directions = { 'asc': 1, 'desc': -1 };

    return list.sort(function (a, b) {
        let i = 0, result = 0;
        while (i < criteria.length && result === 0) {
            const aValue = (a[criteria[i].prop] || '').toString();
            const bValue = (b[criteria[i].prop] || '').toString();

            result = directions[criteria[i].direction] * (aValue < bValue
                ? -1 : (aValue > bValue ? 1 : 0));
            i++;
        }
        return result;
    })
};

export function sort(a: number, b: number) {
    return (a > b) ? 1 : -1;
}