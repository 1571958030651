import { Component, OnInit, ViewChild, Input, ElementRef, AfterViewInit } from '@angular/core';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { AuthRestService } from 'src/app/services/rest/auth-rest/auth-rest.service';
import { ModalHelperService } from 'src/app/services/helper/modal-helper/modal-helper.service';

@Component({
  selector: 'app-sign-pad',
  templateUrl: './sign-pad.component.html',
  styleUrls: ['./sign-pad.component.css']
})
export class SignPadComponent implements OnInit, AfterViewInit {
  @ViewChild('signPad', {static: false}) canvasElement: ElementRef<HTMLCanvasElement>; 
  @ViewChild('signPadText', {static: false}) canvasElementText: ElementRef<HTMLCanvasElement>; 
  @ViewChild('signPadImage', {static: false}) canvasElementImage: ElementRef<HTMLCanvasElement>; 

  @Input()
  public width: number;
  @Input()
  public height: number;
  @Input()
  public color: string;
  @Input()
  public weight: number;
  @Input("font-size")
  public fontSize: string;
  @Input("font-type")
  public fontType: string;
  @Input("text-max-length")
  public textMaxLength: number;

  public signText: string;
  public currentTabIndex: number;

  private _mousePressed: boolean;
  private _lastX: number;
  private _lastY: number;
  private _context: CanvasRenderingContext2D;
  private _contextText: CanvasRenderingContext2D;
  private _contextImage: CanvasRenderingContext2D;
  private _currentContext: CanvasRenderingContext2D;
  private _currentCanvasElement: ElementRef<HTMLCanvasElement>; 

  constructor(
    private _authRest: AuthRestService,
    private _modalHelper: ModalHelperService) { }

  ngAfterViewInit(): void {
    this._context = this.canvasElement.nativeElement.getContext('2d');
    this._contextText = this.canvasElementText.nativeElement.getContext('2d');
    this._contextImage = this.canvasElementImage.nativeElement.getContext('2d');
    this.tabClicked(0);
    this.clear();
    if(this._isSignatureSaved()) {
      if(this._setSavedSignature()) {
        this.currentTabIndex = 2;
        this.tabClicked(this.currentTabIndex);
      }
    } else {
      this._authRest.getUserSignature()
      .subscribe((response) => { 
        sessionStorage.setItem(CacheKeyEnum.UserSignature, response);
        if(this._setSavedSignature()) {
          this.currentTabIndex = 2;
          this.tabClicked(this.currentTabIndex);
        }
      });
    }
   }

  ngOnInit(): void {
    this._mousePressed = false;
    this.currentTabIndex = 0;
  }

  public clear() {
    this._clear(this._context);
    this._clear(this._contextText);
    this._clear(this._contextImage);
    this.signText = "";
  }

  public saveSignature() { 
    const base64Data = this.getBase64Data();
    this._authRest.updateCurrentUserSignature(base64Data)
      .subscribe(() => {
        sessionStorage.setItem(CacheKeyEnum.UserSignature, base64Data)
        this._modalHelper.showSavedSignatureMessage();
        this._setSavedSignature();
      });
  }

  private _clear(context: CanvasRenderingContext2D) {
    context.setTransform(1, 0, 0, 1, 0, 0);
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  }

  public onMousedown(event: MouseEvent) {
    this._mousePressed = true;
    let bounds = this.canvasElement.nativeElement.getBoundingClientRect();
    this._draw(event.pageX - bounds.left, event.pageY - bounds.top, false);
  }

  public onMousemove(event: MouseEvent) {
    if(this._mousePressed) {
      let bounds = this.canvasElement.nativeElement.getBoundingClientRect();
      this._draw(event.pageX - bounds.left, event.pageY - bounds.top, true);
    }
  }

  public onMouseup(event: MouseEvent) {
    this._mousePressed = false;
  }

  public onMouseleave(event: MouseEvent) {
    this._mousePressed = false;
  }

  private _draw(x: number, y: number, isDown: boolean) {
    if (isDown) {
        this._context.beginPath();
        this._context.strokeStyle = this.color;
        this._context.lineWidth = this.weight;
        this._context.lineJoin = "round";
        this._context.moveTo(this._lastX, this._lastY);
        this._context.lineTo(x, y);
        this._context.closePath();
        this._context.stroke();
    }
    this._lastX = x; 
    this._lastY = y;
  }

  public isEmpty() {
    // Verifies dataUrl againts a blank canvas element
    const blank = document.createElement('canvas');
   
    blank.width = this._currentCanvasElement.nativeElement.width;
    blank.height = this._currentCanvasElement.nativeElement.height;

    const result = this._currentCanvasElement.nativeElement.toDataURL() === blank.toDataURL();

    return result;
  }

  public onTextChange(text: string) {
    this._clear(this._contextText);
    this._contextText.font = this.fontSize.toString() + "px " + this.fontType;
    this._contextText.fillText(text, 10, parseInt(this.fontSize) + 15);
  }

  public getData() : string {
      return this._currentCanvasElement.nativeElement.toDataURL();
  }	

  public getBase64Data() : string {
    if(!this.isEmpty()) {
      var data = this._currentCanvasElement.nativeElement.toDataURL();
      var index = data.indexOf(",");
      return data.substring(index + 1);
    } else {
      return "";
    } 
  }	

  public tabClicked(index: number) {
    switch(index) {
      case 0:
        this._currentContext = this._context;
        this._currentCanvasElement = this.canvasElement;
        break;
      case 1:
        this._currentContext = this._contextText
        this._currentCanvasElement = this.canvasElementText;
        break;
      case 2:
        this._currentContext = this._contextImage
        this._currentCanvasElement = this.canvasElementImage;
        break;
    } 
  }
  
  private _setSavedSignature() {
    this._clear(this._contextImage);
    const base64Signature = sessionStorage.getItem(CacheKeyEnum.UserSignature);
    if(base64Signature) {
      let image = new Image();      
      var context = this._contextImage;
      var canvas = this.canvasElementImage;

      image.onload = function() {
        context.drawImage(image, 0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
      };

      image.src = 'data:image/png;base64,' + base64Signature;
      return true;
    }
    else {
      return false;
    }
  }  
  private _isSignatureSaved(): boolean {
    const base64Signature = sessionStorage.getItem(CacheKeyEnum.UserSignature);
    if(base64Signature) {
      return true;
    } else {
      return false;
    }
  }
}
