<div class="replacer-list">
    <ul *ngIf="renderList" class="dropdown-menu" [style.top.px]="position?.top" [style.left.px]="position?.left">
        <li>
            <span class="replacer-title">Suggestions</span>
        </li>
        <li *ngFor="let choice of choices" [class.active]="isActiveItem(choice)">
            <a href="javascript:void(0)" (click)="updateSelectedChoice()">
                <span class="replacer-match">{{matchingText}}</span>{{choice.name}}
                <span class="replacer-code"> ({{choice.replacerCode}})</span></a>
        </li>
    </ul>
</div>