import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { Entity } from 'src/app/models/entity.model';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EntityRestService {

  constructor(private _rest: RestService) { }

  public getEntityList(): Observable<any> {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/`
    });
  }

  public getDataEntity(urlSection: string, detailCode: string): Observable<Entity> {
    return this._rest.get<Entity>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/${detailCode}/`
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public getByCodeAndOwnerPartyRoleInstanceCode(
    urlSection: string, code: string, ownerPartyRoleInstanceCode: string): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');

    return this._rest.get<Entity>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/getByCodeAndOwner/${code}/${ownerPartyRoleInstanceCode}`,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public saveAndLinkEntity(urlSection: string, requestCode: string, payload: any): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/${requestCode}`,
      body: payload,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public updateAndLinkEntity(urlSection: string, requestCode: string, payload: any): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.put({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/${requestCode}`,
      body: payload,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public saveEntity(urlSection: string, payload: any): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}`,
      body: payload,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public updateEntity(urlSection: string, payload: any): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.put({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}`,
      body: payload,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public saveAndLinkEntityWithRelationship(urlSection: string, requestCode: string, payload: any): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/saveWithRelationship/${requestCode}`,
      body: payload,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public updateAndLinkEntityWithRelationship(urlSection: string, requestCode: string, payload: any): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.put({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/updateWithRelationship/${requestCode}`,
      body: payload,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public saveEntityWithRelationship(urlSection: string, payload: any): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/saveWithRelationship`,
      body: payload,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public updateEntityWithRelationship(urlSection: string, payload: any): Observable<Entity> {
    const headers = new HttpHeaders()
      .append('silentError', 'true');
    return this._rest.put({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/updateWithRelationship`,
      body: payload,
      headers: headers
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public cloneEntity(urlSection: string, payload: Entity): Observable<Entity> {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/clone`,
      body: payload
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public cloneFlow(urlSection: string, payload: Entity): Observable<Entity> {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/flow-clone`,
      body: payload
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public deepClone(urlSection: string, payload: Entity): Observable<Entity> {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/deep-clone`,
      body: payload
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public tenantComparer(source: string, target: string): Observable<any> {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/organization/compare/${source}/${target}`
    }).pipe(map(response => Object.assign(new Entity(), response))).pipe(catchError((error) => {
      if (error.status === 409) { return of(error); }
      throw error;
    }));
  }

  public createEntityRelationships(entity: string, paylod: any) {
    const endpoint = entity.endsWith('-book')
      ? `api/v1/${entity}/relationship/book`
      : `api/v1/${entity}/relationship`;

    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: endpoint,
      body: paylod
    });
  }

  public deleteEntityRelationships(entity: string, relations: Array<any>) {
    return this._rest.delete({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/relationship`,
      body: relations
    });
  }

  public getRequest(code: string) {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/request/${code}`
    });
  }

  public updateSequence(entity: string, sequences: Array<any>) {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${entity}/relationship/update-sequence`,
      body: sequences
    });
  }

  public confirmDiscardChanges(urlSection: string, instanceCode: string): Observable<Entity> {
    return this._rest.post({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/confirm-discard/${instanceCode}`,
    }).pipe(map(response => Object.assign(new Entity(), response)));
  }

  public validateDiscardChanges(urlSection: string, instanceCode: string) {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/${urlSection}/validate-discard/${instanceCode}`,
    });
  }

  public getFormDefinitionByFormInstanceCode(detailCode: string): Observable<any> {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/form-definition/${detailCode}`
    });
  }
}
