<div class="container-fluid campaign-edit background-task">

    <!-- Breadcrumb Buttons -->
    <div class="pull-right my-1 details-tools">

    </div>

    <!-- <hr class="mt-0"> -->
    <div class="pl-fluid-container">
        <div class="row">
            <div class="col-xs-12">
                <p *ngIf="showRequestData()" class="task-detail">Request: {{model.RequestName}} (<a href="javascript:void(0)"
                        (click)="getRequestUrl()">{{model.RequestCode}}</a>)</p>
                <p class="task-detail">Submitted on {{model.StartDate}} ({{model.StartDateUtc}} UTC) by {{model.User}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 mb-3 text-center mt-2">
                <div class="loading-title" [ngClass]="getTitleClass()">

                    <div class="in-process" *ngIf="jobInProgress()">
                        <i class="fas fa-cog fa-spin one fa-3x"></i>
                        <i class="fas fa-cog fa-spin two fa-2x"></i>
                        <i class="fas fa-cog fa-spin three"></i>
                    </div>
                    <!-- Completed -->
                    <i *ngIf="jobCompleted()" class="fas fa-check fa-2x"></i>
                    <!-- Failed -->
                    <i *ngIf="jobFailed() || jobCompletedWithErrors()" class="fas fa-times fa-2x"></i>
                    <p class="title-task">
                        {{model.LoadingTitle}}
                    </p>
                </div>
                <ng-container *ngIf="jobInProgress()">
                    <p class="percentage-task">Complete process {{model.PercentageProcessed}}%
                        <span>({{model.ClonedCount}} of {{model.TotalCount}} instances processed)</span>
                    </p>
                </ng-container>
                <ng-container *ngIf="showProcessedData()">
                    <p class="percentage-task"><span>Succeded: {{model.SuccededCount}} / Failed: {{model.FailedCount}}</span></p>

                    <app-button-action 
                        *ngIf="jobFailed() || jobCompletedWithErrors()" 
                        text="Download Book" 
                        cssClass="btn btn-default btn-section" 
                        (triggered-action)="handleDownloadBook()">
                    </app-button-action>
                </ng-container>
                <ng-container *ngIf="jobInProgress()">
                    <div class="text-waiting">
                        <p>
                            <span class="description">
                                Please wait ... You will receive a notification when the process ends
                            </span>
                        </p>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="row" *ngIf="showTenantCloneGrid()">
            <div class="col-xs-12 col-md-10 col-md-offset-1 mt-3 table-responsive task-table">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Entity Name</th>
                            <th scope="col">Cloned Entities</th>
                            <th scope="col">Total Entities</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of model.Items" [ngClass]="{'task-failed': item.Status === 'Failed'}">
                            <td>{{item.EntityName}}</td>
                            <td>{{item.TargetCount}}</td>
                            <td>{{item.SourceCount}}</td>
                            <td>
                                <i *ngIf="item.Status === 'Processing'" class="fas fa-spinner fa-pulse"></i>
                                <i *ngIf="item.Status === 'Complete'" class="fas fa-check-circle text-success"></i>
                                {{item.Status}} <span *ngIf="item.Status === 'Processing'">({{item.Percentage}})%</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="showDeployGrid()">
            <div class="col-xs-12 col-md-10 col-md-offset-1 mt-3 table-responsive task-table">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Change Request Id</th>
                            <th scope="col">Entity Name</th>
                            <th scope="col">Entity Library Id</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of model.Items">
                            <td>{{item.ChangeRequestId}}</td>
                            <td>{{item.EntityName}}</td>
                            <td>{{item.EntityLibraryId}}</td>
                            <td>
                                <i *ngIf="item.Status === 'Processing'" class="fas fa-spinner fa-pulse"></i>
                                <i *ngIf="item.Status === 'Complete' || item.Status === 'Skipped'"
                                    class="fas fa-check-circle text-success"></i>
                                {{item.Status}}
                                <!-- <span *ngIf="item.Status === 'Processing'">({{item.Percentage}})%</span> -->
                                <a class="link-errors" *ngIf="item.Status === 'Failed' && item.Errors"
                                    href="javascript:void(0)" (click)="showCustomErrorModal(item)">(view erros)</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-xs-12 mb-3 text-center mt-2">
                <p class="title-task">Events</p>
            </div>
        </div>

        <div class="row" *ngIf="showEventsGrid()">
            <div class="col-xs-12 col-md-10 col-md-offset-1 mt-3 table-responsive task-table">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Event Type</th>
                            <th scope="col">Date</th>
                            <th scope="col">Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let event of model.Events">
                            <td>{{event.JobEventTypeCode}}</td>
                            <td>{{event.CreationDatetime}}</td>
                            <td>{{event.Message}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
    </div>
</div>