import { Injectable } from '@angular/core';
import { OperationForm, CatalogFormInstance, Catalog } from 'src/app/models/catalog.models';
import { Observable, forkJoin, BehaviorSubject, of } from 'rxjs';
import { KeyValueType } from 'src/app/types/keyvalue.type';
import { FormOperationType, FormOperationDefinition, FormInstance } from 'src/app/models/operation.models';
import { UtilService } from '../../behavior/util/util.service';
import { deepClone } from 'src/app/functions/deepClone';
import { filter } from 'rxjs/operators';
import { CatalogService } from '../../behavior/catalog/catalog.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { AuthorizationHandlerService } from '../authorization-handler/authorization-handler.service';

@Injectable({
  providedIn: 'root'
})
export class OperationHandlerService {
  public readonly availableOperations$: Observable<Array<FormOperationType>>;
  public readonly availableFormsByOperation$: Observable<Array<FormOperationDefinition>>;

  private readonly _availableOperations = new BehaviorSubject<Array<FormOperationType>>(undefined);
  private readonly _availableFormsByOperation = new BehaviorSubject<Array<FormOperationDefinition>>(undefined);

  private _opFrmCatalogs: KeyValueType<OperationForm[]> = {};

  constructor(
    private _catalogService: CatalogService,
    private _utilService: UtilService,
    private _authHandler: AuthorizationHandlerService
  ) {
    this.availableOperations$ = this._availableOperations.asObservable().pipe(filter(x => x !== undefined));
    this.availableFormsByOperation$ = this._availableFormsByOperation.asObservable().pipe(filter(x => x !== undefined));
  }

  public cleanMemoryCache() {
    this._opFrmCatalogs = {};
  }

  public clearAll() {
    this.cleanMemoryCache();
    this._availableOperations.next(undefined);
    this._availableFormsByOperation.next(undefined);
  }

  public getAvailableOperations(code: DynafEnum) {
    if (this._opFrmCatalogs[code]) {
      this._emitOperations(this._opFrmCatalogs[code]);
    } else {
      this._getOperationsFormsCatalog(code);
    }
  }

  public getAvailableForms(code: DynafEnum, withCatalogs: boolean = true, useCache: boolean = true) {
    if (useCache && this._opFrmCatalogs[code]) {
      this._emitFormDefinitions(code, this._opFrmCatalogs[code]);
    } else {
      this._getOperationsFormsCatalog(code, withCatalogs);
    }
  }

  private _getOperationsFormsCatalog(code: DynafEnum, withCatalogs: boolean = true) {
    const catalogsObs = withCatalogs
      ? this._catalogService.getCatalogsForDetail(code)
      : of(undefined);

    forkJoin(
      this._catalogService.getFiltrableCatalog<OperationForm>(CatalogEnum.AvailableForms, [code]),
      catalogsObs
    ).subscribe(responses => {
      const operationForm = responses[0];

      if (withCatalogs) {
        const catalogs = responses[1];

        this._catalogService.set(catalogs);
      }

      this._updateFieldWithOwner(operationForm, code);
      this._updateItemsWithCatalogsAndEmit(code, operationForm);
    });
  }

  private _updateFieldWithOwner(operationForm: Catalog<OperationForm>, code: DynafEnum) {
    const workingOrg = this._authHandler.getWorkingOrganization();

    if (workingOrg.code !== 'EMERIOS') {
      operationForm.items.forEach(opForm => {
        const formOperation = opForm.operation.split('.').pop() as FormOperationType;

        opForm.formInstances.forEach(form => {
          form.definition.definition.forEach(def => {
            const ownerField = def.fields && def.fields.find(x => x.field === 'ownerPartyRoleInstanceCode');
            const isWorkEffortChild = this._isWorkEffortChild(code);

            if (ownerField) {
              if (formOperation === FormOperationType.Create && !isWorkEffortChild) {
                ownerField.value = [workingOrg.partyRoleInstanceCode];
              }
              ownerField.attributes.editable = false;
            }
          });
        });

      });
    }
  }

  private _isWorkEffortChild(code: DynafEnum) {
    const workEffortChildDynafLibraryCodes = [DynafEnum.Stage, DynafEnum.Step, DynafEnum.StepForm, DynafEnum.StepContainer];
    return workEffortChildDynafLibraryCodes.includes(code);
  }

  private _updateItemsWithCatalogsAndEmit(code: DynafEnum, operationForm: Catalog<OperationForm>) {
    operationForm.items.forEach(opForm => {
      opForm.formInstances.forEach(form => {
        if (form.definition) {
          form.definition.definition.forEach(group => {
            group.id = this._utilService.generateRndNumber(10, 9999).toString();

            if (group.type === 'fields') {
              group.fields.forEach(field => {
                if (field.filterBy) {
                  field.catalogItems = [];
                }
                if (field.catalog) {
                  field.untouchedCatalogItems = this._catalogService.get(field.catalog, true);
                  field.catalogItems = this._catalogService.get(field.catalog);
                }
              });
            }
          });
        }
      });
    });

    this._opFrmCatalogs[code] = operationForm.items;

    this._emitOperations(operationForm.items);
    this._emitFormDefinitions(code, operationForm.items);
  }

  private _emitOperations(items: OperationForm[]) {
    const operations = items.map(x => x.operation.split('.').pop() as FormOperationType);
    this._availableOperations.next(deepClone(operations));
  }

  private _emitFormDefinitions(source: DynafEnum, items: OperationForm[]) {
    const formDefByOperation = items.map(x => {
      return {
        source: source,
        operation: x.operation.split('.').pop() as FormOperationType,
        formInstance: x.operation,
        definitions: this._getDefinitions(x.formInstances)
      } as FormOperationDefinition;
    });

    this._availableFormsByOperation.next(deepClone(formDefByOperation));
  }

  private _getDefinitions(formInstances: Array<CatalogFormInstance>): Array<FormInstance> {
    const instances = formInstances
      .filter(x => x.definition != undefined)
      .map(x => {
        return {
          code: x.code,
          name: x.name,
          default: x.definition.default,
          definition: x.definition.definition
        } as FormInstance;
      });

    return instances;
  }
}
