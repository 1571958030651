<app-ghost-loading
  *ngIf="showGhostLoading"
  [type]="'aggrid'"
></app-ghost-loading>

<div class="grid grid-70">
  <app-grid
    *ngIf="!showPaginatedGrid"
    [data]="gridModel"
    [groupSelectsChildren]="groupSelectsChildren"
    [rowClassRules]="rowClassRules"
    (selectionChanged)="selectionChanged($event)"
  ></app-grid>

  <app-grid-pagination
    *ngIf="showPaginatedGrid"
    [ngClass]="{ hide: showGhostLoading }"
    [deployJourney]="showPaginatedGrid"
    [groupSelectsChildren]="groupSelectsChildren"
    [dashboardItem]="gridDashboardItem"
    [rowClassRules]="rowClassRules"
    (gridDataLoaded)="onGridDataLoaded($event)"
    (selectionChanged)="selectionChanged($event)"
  ></app-grid-pagination>
</div>

<div
  class="app-input"
  *ngIf="control && control.errors?.required && !control.pristine"
>
  <div class="input-error">This is required</div>
</div>
