import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { NavigationHelperService } from '../services/helper/navigation-helper/navigation-helper.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthorizationHandlerService } from '../services/handler/authorization-handler/authorization-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/handler/authorization-handler/authorization-handler.service";
import * as i3 from "ngx-toastr";
import * as i4 from "../services/helper/navigation-helper/navigation-helper.service";
export class AuthInterceptor {
    constructor(_router, _authHandler, _toastr, _navHelper) {
        this._router = _router;
        this._authHandler = _authHandler;
        this._toastr = _toastr;
        this._navHelper = _navHelper;
        this._router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((e) => this._lastUrl = e.url);
    }
    intercept(request, next) {
        const token = this._authHandler.getToken();
        if (token) {
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
            });
        }
        return next.handle(request)
            .pipe(catchError((response) => {
            if (response.status === 401) {
                this.handle401Error(request, next, response);
            }
            throw response;
        }));
    }
    handle401Error(request, next, error) {
        this._authHandler.logout();
        this._navHelper.goToLogin(this._lastUrl);
        this._toastr.error('Session expired', '', { positionClass: 'toast-top-center' });
    }
}
AuthInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthInterceptor_Factory() { return new AuthInterceptor(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthorizationHandlerService), i0.ɵɵinject(i3.ToastrService), i0.ɵɵinject(i4.NavigationHelperService)); }, token: AuthInterceptor, providedIn: "root" });
export let AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
};
