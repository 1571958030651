import { Injectable } from '@angular/core';
import { UtilService } from '../../behavior/util/util.service';
import { Entity } from 'src/app/models/entity.model';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { DashboardHandlerService } from '../../handler/dashboard-handler/dashboard-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ApiHelperService {

  private _currentDashboardItem: DashboardItem;

  constructor(
    private _utilService: UtilService,
    private _dashboardHandler: DashboardHandlerService
  ) {
    this._createSubscriptions();
  }

  public getUrlSection(detailCode?: string, payload?: Entity, overrideDashboardItem?: DashboardItem) {
    const dashboardItem = overrideDashboardItem || this._currentDashboardItem;
    let urlSection = dashboardItem.overrideViewCode || dashboardItem.viewCode;
    let type: string;

    if (dashboardItem.addTypeToUrl) {
      type = this._getTypeForUrl({ detailCode, payload });

      if (!type) {
        console.warn('This may be a misconfiguration.');
        type = this._utilService.getLibraryCodeFromInstance(detailCode);
      }

      urlSection = urlSection.concat(`/${type}`);
    }

    return urlSection;
  }

  private _createSubscriptions() {
    this._dashboardHandler.currentDashboardItem$
      .subscribe(dashboardItem => this._currentDashboardItem = dashboardItem)
  }

  private _getTypeForUrl(data: { detailCode?: string, payload?: Entity }) {
    let type: string;

    if (this._currentDashboardItem.typeField) {
      if (data.payload) {
        type = data.payload[this._currentDashboardItem.typeField];
      } else {
        type = this._utilService.getLibraryCodeFromInstance(data.detailCode);
      }
    }

    return type;
  }
}
