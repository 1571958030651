<div class="modal-header">
    <h3 class="modal-title pull-left" [innerHtml]="model.title"></h3>
    <button type="button" class="close pull-right" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<ng-container>
    <div class="modal-body campaign-modal campaign-builder">
        <app-dynamic-form-group-container [groups]="model.groupFields" [entityName]="'test 2'">
        </app-dynamic-form-group-container>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-next" (click)="closeModal()">Ok</button>
    </div>
</ng-container>