<div [formGroup]="form">
    <input class="form-control" formControlName="datetime" [owlDateTime]="dt" [owlDateTimeTrigger]="dt" [min]="model.minDate"
        [ngClass]="{'disabled':config.readonly}" (keydown)="preventTyping($event)">
    <owl-date-time #dt [pickerType]="model.pickerType" [disabled]="config.readonly"></owl-date-time>

    <span *ngIf="config?.validationIcons" class="icon-validation">
        <i [ngClass]="config?.validationIcons.pristine"
            *ngIf="dateTimeControl.pristine && model.showInputRequiredIcon"></i>
        <i [ngClass]="config?.validationIcons.error" *ngIf="!dateTimeControl.pristine && dateTimeControl.errors"></i>
        <i [ngClass]="config?.validationIcons.success" *ngIf="!dateTimeControl.pristine && !dateTimeControl.errors"></i>
    </span>

    <div *ngIf="dateTimeControl && dateTimeControl.errors && dateTimeControl.invalid && !dateTimeControl.pristine">
        <div [ngClass]="config?.errorMsgClass">{{getErrorMessage()}}</div>
    </div>
</div>
