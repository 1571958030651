import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { Observable } from 'rxjs';
import { FormDefinition } from 'src/app/models/form-field-definition.models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormRestService {

  constructor(private _rest: RestService) { }

  public getFormDefinitionView(source: string): Observable<Array<FormDefinition>> {
    return this._rest.get<FormDefinition>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/config/data/${source}/form-definition-view`
    }).pipe(map((response: any) => response.definition));
  }
}
