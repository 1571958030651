import { forkJoin, BehaviorSubject, of } from 'rxjs';
import { FormOperationType } from 'src/app/models/operation.models';
import { UtilService } from '../../behavior/util/util.service';
import { deepClone } from 'src/app/functions/deepClone';
import { filter } from 'rxjs/operators';
import { CatalogService } from '../../behavior/catalog/catalog.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { AuthorizationHandlerService } from '../authorization-handler/authorization-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../../behavior/catalog/catalog.service";
import * as i2 from "../../behavior/util/util.service";
import * as i3 from "../authorization-handler/authorization-handler.service";
export class OperationHandlerService {
    constructor(_catalogService, _utilService, _authHandler) {
        this._catalogService = _catalogService;
        this._utilService = _utilService;
        this._authHandler = _authHandler;
        this._availableOperations = new BehaviorSubject(undefined);
        this._availableFormsByOperation = new BehaviorSubject(undefined);
        this._opFrmCatalogs = {};
        this.availableOperations$ = this._availableOperations.asObservable().pipe(filter(x => x !== undefined));
        this.availableFormsByOperation$ = this._availableFormsByOperation.asObservable().pipe(filter(x => x !== undefined));
    }
    cleanMemoryCache() {
        this._opFrmCatalogs = {};
    }
    clearAll() {
        this.cleanMemoryCache();
        this._availableOperations.next(undefined);
        this._availableFormsByOperation.next(undefined);
    }
    getAvailableOperations(code) {
        if (this._opFrmCatalogs[code]) {
            this._emitOperations(this._opFrmCatalogs[code]);
        }
        else {
            this._getOperationsFormsCatalog(code);
        }
    }
    getAvailableForms(code, withCatalogs = true, useCache = true) {
        if (useCache && this._opFrmCatalogs[code]) {
            this._emitFormDefinitions(code, this._opFrmCatalogs[code]);
        }
        else {
            this._getOperationsFormsCatalog(code, withCatalogs);
        }
    }
    _getOperationsFormsCatalog(code, withCatalogs = true) {
        const catalogsObs = withCatalogs
            ? this._catalogService.getCatalogsForDetail(code)
            : of(undefined);
        forkJoin(this._catalogService.getFiltrableCatalog(CatalogEnum.AvailableForms, [code]), catalogsObs).subscribe(responses => {
            const operationForm = responses[0];
            if (withCatalogs) {
                const catalogs = responses[1];
                this._catalogService.set(catalogs);
            }
            this._updateFieldWithOwner(operationForm, code);
            this._updateItemsWithCatalogsAndEmit(code, operationForm);
        });
    }
    _updateFieldWithOwner(operationForm, code) {
        const workingOrg = this._authHandler.getWorkingOrganization();
        if (workingOrg.code !== 'EMERIOS') {
            operationForm.items.forEach(opForm => {
                const formOperation = opForm.operation.split('.').pop();
                opForm.formInstances.forEach(form => {
                    form.definition.definition.forEach(def => {
                        const ownerField = def.fields && def.fields.find(x => x.field === 'ownerPartyRoleInstanceCode');
                        const isWorkEffortChild = this._isWorkEffortChild(code);
                        if (ownerField) {
                            if (formOperation === FormOperationType.Create && !isWorkEffortChild) {
                                ownerField.value = [workingOrg.partyRoleInstanceCode];
                            }
                            ownerField.attributes.editable = false;
                        }
                    });
                });
            });
        }
    }
    _isWorkEffortChild(code) {
        const workEffortChildDynafLibraryCodes = [DynafEnum.Stage, DynafEnum.Step, DynafEnum.StepForm, DynafEnum.StepContainer];
        return workEffortChildDynafLibraryCodes.includes(code);
    }
    _updateItemsWithCatalogsAndEmit(code, operationForm) {
        operationForm.items.forEach(opForm => {
            opForm.formInstances.forEach(form => {
                if (form.definition) {
                    form.definition.definition.forEach(group => {
                        group.id = this._utilService.generateRndNumber(10, 9999).toString();
                        if (group.type === 'fields') {
                            group.fields.forEach(field => {
                                if (field.filterBy) {
                                    field.catalogItems = [];
                                }
                                if (field.catalog) {
                                    field.untouchedCatalogItems = this._catalogService.get(field.catalog, true);
                                    field.catalogItems = this._catalogService.get(field.catalog);
                                }
                            });
                        }
                    });
                }
            });
        });
        this._opFrmCatalogs[code] = operationForm.items;
        this._emitOperations(operationForm.items);
        this._emitFormDefinitions(code, operationForm.items);
    }
    _emitOperations(items) {
        const operations = items.map(x => x.operation.split('.').pop());
        this._availableOperations.next(deepClone(operations));
    }
    _emitFormDefinitions(source, items) {
        const formDefByOperation = items.map(x => {
            return {
                source: source,
                operation: x.operation.split('.').pop(),
                formInstance: x.operation,
                definitions: this._getDefinitions(x.formInstances)
            };
        });
        this._availableFormsByOperation.next(deepClone(formDefByOperation));
    }
    _getDefinitions(formInstances) {
        const instances = formInstances
            .filter(x => x.definition != undefined)
            .map(x => {
            return {
                code: x.code,
                name: x.name,
                default: x.definition.default,
                definition: x.definition.definition
            };
        });
        return instances;
    }
}
OperationHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperationHandlerService_Factory() { return new OperationHandlerService(i0.ɵɵinject(i1.CatalogService), i0.ɵɵinject(i2.UtilService), i0.ɵɵinject(i3.AuthorizationHandlerService)); }, token: OperationHandlerService, providedIn: "root" });
