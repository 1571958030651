import { BaseDataModel } from '../base-data-model.model';
import { FieldDefinition } from 'src/app/models/form-field-definition.models';

export class CatalogMultipleDataModelService implements BaseDataModel {

  private _fieldDefinition: FieldDefinition;

  constructor(fieldDefinition: FieldDefinition) {
    this._fieldDefinition = fieldDefinition;
  }

  public setValueToField(payloadValue: any) {
    let items: Array<string>;

    if (!payloadValue) {
      return undefined;
    }

    if (this._fieldDefinition.codeProperty) {
      items = (payloadValue as Array<any>)
        .map(item => {
          const propToUse = this._fieldDefinition.codeProperty;
          if (this._fieldDefinition.catalogItems) {
            const catalogItem = this._fieldDefinition.catalogItems
              .find(x => x[propToUse] === item[propToUse]);

            return catalogItem && catalogItem.code;
          } else {
            return item[propToUse];
          }
        });
    } else if (this._fieldDefinition.propertyAsCode) {
      items = (payloadValue as Array<any>)
        .map(item => {
          const catalogItem = this._fieldDefinition.catalogItems
            .find(x => x[this._fieldDefinition.propertyAsCode] === item.code);

          return catalogItem.code;
        });
    } else {
      items = payloadValue.map((x: any) => x.code);
    }

    return items;
  }

  public getValueFromField(fieldValue: any) {
    const value = fieldValue && (fieldValue as Array<string>)
      .map(item => {
        const catalogItem = this._fieldDefinition.catalogItems
          && this._fieldDefinition.catalogItems.find(x => x.code === item);
        let objProp = {};

        objProp[this._fieldDefinition.codeProperty || 'code'] =
          (catalogItem && this._fieldDefinition.propertyAsCode) ? catalogItem[this._fieldDefinition.propertyAsCode] : item;

        if (this._fieldDefinition.extraCatalogProperties) {
          this._fieldDefinition.extraCatalogProperties.forEach(prop => {
            if (prop.includes('=')) {
              objProp[prop.split('=')[0]] = prop.split('=')[1];
            } else {

              if (catalogItem) {
                objProp[prop] = catalogItem[prop];
              }
            }
          });
        }
        return objProp;
      });

    return value;
  }
}
