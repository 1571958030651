<div class="ghost-grid-loading">
	<div class="ghost-grid-header">
		<div class="row ">
			<ng-container *ngFor="let n1 of [1,2,3,4]">
				<div class="col-xs-3">
					<div class="grid-header-title"></div>
				</div>
			</ng-container>
		</div>
		<div class="row">
			<ng-container *ngFor="let n1 of [1,2,3,4]">
				<div class="col-xs-3">
					<div class="grid-subtitle width-75"></div>
					<div class="grid-icon width-20"></div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="ghost-grid-body">
		<div class="row">
			<ng-container *ngFor="let n1 of [1,2,3,4,5,6,7,8,9]">
				<div class="col-xs-12">
					<div class="grid-row"></div>
				</div>
			</ng-container>
		</div>
	</div>
	<!-- <div class="row ghost-table-row"></div>
	<div class="row ghost-table-row"></div>
	<div class="row ghost-table-row"></div>
	<div class="row ghost-table-row"></div> -->
</div>