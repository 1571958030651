import { map } from 'rxjs/operators';
import { RestService } from 'ngx-emerios-all';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class TranslateLoaderService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getTranslation(lang) {
        return this.getTranslationFiles(lang);
    }
    getTranslationFiles(lang) {
        let languageObservables;
        const wasManualChange = sessionStorage.getItem('manualChange') === 'true';
        const restConfig = { loaderMustHideRouter: wasManualChange };
        languageObservables = this._rest.get({
            baseUrl: '',
            endpoint: `assets/i18n/${lang}.json`,
            extraConfig: restConfig
        });
        return languageObservables.pipe(map((res) => res instanceof Array ? this.mergeDeep(res[0], res[1]) : res));
    }
    mergeDeep(target, source) {
        if (this.isObject(target) && this.isObject(source)) {
            for (const key in source) {
                if (this.isObject(source[key])) {
                    if (!target[key]) {
                        Object.assign(target, { [key]: {} });
                    }
                    this.mergeDeep(target[key], source[key]);
                }
                else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }
        return target;
    }
    isObject(item) {
        return (item && typeof item === 'object' && !Array.isArray(item));
    }
}
TranslateLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslateLoaderService_Factory() { return new TranslateLoaderService(i0.ɵɵinject(i1.RestService)); }, token: TranslateLoaderService, providedIn: "root" });
export function CustomHttpLoaderFactory(rest) {
    return new TranslateLoaderService(rest);
}
