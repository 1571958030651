import { Injectable } from '@angular/core';
import { Router, QueryParamsHandling } from '@angular/router';
import { DashboardItem, DashboardCategory } from 'src/app/models/dashboard.models';
import { UtilService } from '../../behavior/util/util.service';
import { CacheService } from 'ngx-emerios-all';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { NavigationUrlEnum } from 'src/app/enums/navigation-url';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { DashboardHandlerService } from '../../handler/dashboard-handler/dashboard-handler.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationHelperService {
  private _currentViewCode: string;
  private _dashboardItemList: Array<DashboardItem>;

  constructor(
    private _utilService: UtilService,
    private _router: Router,
    private _cache: CacheService,
    private _dashboardhandler: DashboardHandlerService) {

    this._dashboardhandler.currentDashboardItem$
      .subscribe(item => this._currentViewCode = item.viewCode);

    this._dashboardhandler.dashboardItemList$
      .subscribe(list => this._dashboardItemList = list);
  }

  public goTo(url: string);
  public goTo(nav: NavigationUrlEnum);

  public goTo(url: string, nav?: NavigationUrlEnum) {
    if (url) {
      this._router.navigate([url]);
    } else {
      this._router.navigate([nav]);
    }
  }

  public goToByUrl(url: string) {
    this._router.navigateByUrl(url);
  }

  public goToLogin(redirectUrl?: string) {
    if (redirectUrl) {
      this._router.navigate(['login'], { queryParams: { redirect: redirectUrl } });
    } else {
      this._router.navigate(['login']);
    }
  }

  public goToDashboard(category?: DashboardCategory) {
    if (category) {
      this._router.navigate([`${NavigationUrlEnum.Dashboard}/${category.code.toLocaleLowerCase()}`]);
    } else {
      this._router.navigate([NavigationUrlEnum.Dashboard]);
    }
  }

  public goToTenantSelector(redirectUrl?: string) {
    if (redirectUrl) {
      this._router.navigate([NavigationUrlEnum.OrganizationSelector], { queryParams: { redirect: redirectUrl } });
    } else {
      this._router.navigate([NavigationUrlEnum.OrganizationSelector]);
    }
  }

  public goToViewDetail(params: {
    entity: DashboardItem, detailCode?: string, detailName?: string,
    assignToPayload?: any, backAfterSave?: boolean, queryParams?: any,
    queryParamsHandling?: QueryParamsHandling, inNewTab?: boolean
  }) {
    const viewCode = params.entity.viewCode;
    const source = params.entity.formSource;
    const commonUrl = `${NavigationUrlEnum.BaseFormUrl}/${viewCode}/${source}`;
    let url = commonUrl;

    if (params.detailCode) {
      url = `${commonUrl}/${params.detailCode}`;
    }

    this._cache.set('additionalPayloadProps', params.assignToPayload);

    if (params.backAfterSave) {
      this._cache.set(CacheKeyEnum.BackAfterSave, true);
    }

    if (params.inNewTab) {
      const baseUrl = window.location.href.split('#')[0];
      window.open(`${baseUrl}#/${url}`, '_blank');
    } else {
      this._router.navigate([url], {
        queryParams: params.queryParams,
        queryParamsHandling: params.queryParamsHandling
      });
    }
  }

  public goToEntityList(source: AgGridEnum) {
    const dashboardItem = this._dashboardItemList.find(x => x.gridSource === source);
    const url = `${NavigationUrlEnum.BaseGridUrl}/${dashboardItem.viewCode}/${source}`;

    this._router.navigate([url]);
  }

  public goToBookFileUploadView(instanceCode: string) {
    const source = DynafEnum.BookUpload;
    const url = `${NavigationUrlEnum.BaseFormUrl}/${this._currentViewCode}/${source}`;

    this._router.navigate([url], { queryParams: { instanceCode: instanceCode } });
  }

  public goToRequest(requestCode: string) {
    this._router.navigate([`${NavigationUrlEnum.RequestDetail}/${DynafEnum.Request}/${requestCode}`]);
  }

  public goToPendingRequestList() {
    const rnd = this._utilService.generateRndNumber(1, 99999);
    this._router.navigate([`${NavigationUrlEnum.PendingRequestList}/${AgGridEnum.PendingRequest}`], { queryParams: { r: rnd } });
  }

  public goToPendingRequestDetail(requestToken: string) {
    this._router.navigate([`${NavigationUrlEnum.RequestDetail}/${AgGridEnum.PendingRequest}/${requestToken}`]);
  }

  public goToBackgroundTasks() {
    const rnd = this._utilService.generateRndNumber(1, 99999);
    this._router.navigate([`${NavigationUrlEnum.BackgroundTaskDetail}/${AgGridEnum.BackgroundJobs}`], { queryParams: { r: rnd } });
  }

  public updateAfterSave(url: string) {
    this._router.navigate([url]);
  }

  public updateQueryParam(key: string, value: string) {
    const params = {};

    params[key] = value;

    this.updateQueryParams(params);
  }

  public updateQueryParams(params: any) {
    this._router.navigate([], { queryParams: params, queryParamsHandling: 'merge' });
  }

}
