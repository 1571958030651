import { Component, OnInit } from '@angular/core';
import { GenericModalParams, ModalParamButton } from 'src/app/services/behavior/modal/modal.service';
import { BsModalRef } from 'ngx-bootstrap';

export type MessageModalType = 'success' | 'info' | 'warning' | 'error';

export interface MessageModalParams extends GenericModalParams {
  type: MessageModalType;
}

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.sass']
})
export class MessageModalComponent implements OnInit {
  public title: string;
  public message: string;
  public items: Array<string>;
  public type: MessageModalType;
  public okButton: ModalParamButton;

  constructor(private _modal: BsModalRef) { }

  public closeModal() {
    this._modal.hide();
    if (this.okButton.callback) {
      this.okButton.callback();
    }
  }

  public isSuccess() {
    return this.type === 'success';
  }

  public isWarning() {
    return this.type === 'warning';
  }

  public isInfo() {
    return this.type === 'info';
  }

  public isError() {
    return this.type === 'error';
  }

  ngOnInit() { }

}
