<app-action-bar *ngIf="showActionBar()" class="btn-container" [code]="'tabbedRuleCreator'" [config]="actionBarConfig"
    (actionFired)="actionFired($event)">
</app-action-bar>

<div [formGroup]="form">
    <app-tab-group [showTabs]="showTabs()" [currentIndex]="currentTabIndex" (tabClicked)="tabClicked($event)">
        <app-tab-item *ngFor="let tab of tabs; let i = index" [label]="tab.title">

            <ng-container *ngIf="model.outcomeTitle">
                <h5>{{model.outcomeTitle}}</h5>
            </ng-container>

            <!-- MULTISELECT -->
            <ng-container *ngIf="renderOutcome('catalog-multiple')">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3">
                        <label class="app-input has-label emerios-multiselect">{{multiselectDef.attributes.label}}
                            <emerios-multiselect [config]="multiselectConfig[currentTabIndex]"
                                [items]="multiselectItems[currentTabIndex]" formControlName="multiselect-{{tab.code}}"
                                [forceValidation]="forceFormValidation" (is-valid)="isValid($event)">
                            </emerios-multiselect>
                        </label>
                    </div>
                </div>
            </ng-container>

            <!-- GRID -->
            <ng-container *ngIf="renderOutcome('grid')">
                <app-ghost-loading *ngIf="model.loadingGrid[i]" [type]="'aggrid'"></app-ghost-loading>

                <ng-container *ngIf="!model.loadingGrid[i]">
                    <div class="grid grid-70">
                        <app-grid [data]="model.gridModel[i]" (gridApi)="setGridApi($event, i)"
                            (rowDragEnd)="onRowDragEnd($event)">
                        </app-grid>
                    </div>
                </ng-container>
            </ng-container>

            <hr class="dashed-line my-4">

            <ng-container *ngIf="model.selectorTitle">
                <h5 class="mt-3">{{model.selectorTitle}}</h5>
            </ng-container>

            <app-chained-filter [config]="tab.chainerFilterConfig" formControlName="chainedfilter-{{tab.code}}"
                (selectedItems)="chainedFilterChanged($event)">
            </app-chained-filter>

        </app-tab-item>

        <app-tab-item [label]="'Add New...'" *ngIf="tabs.length === 0">
            <p>Add a new tab to start adding rules</p>
        </app-tab-item>
    </app-tab-group>
</div>

<div class="app-input" *ngIf="control && control.errors && control.invalid && !control.pristine">
    <div class="input-error">{{getControlErrors()}}</div>
</div>

<!-- ------------- -->