import { DateTimePickerDefinition, FieldDefinition } from 'src/app/models/form-field-definition.models';
import { BaseDataModel } from '../base-data-model.model';

export class DateTimePickerDataModelService implements BaseDataModel {
  private _fieldDefinition: FieldDefinition;

  constructor(fieldDefinition: FieldDefinition) {
    this._fieldDefinition = fieldDefinition;
  }

  public setValueToField(payloadValue: any) {
    const inputField = this._fieldDefinition as DateTimePickerDefinition;
    const key = inputField.dateTimePropCode;

    return payloadValue
      ? Object.fromEntries([[key, new Date(payloadValue)]])
      : undefined;
  }

  public getValueFromField(fieldValue: any) {
    const inputField = this._fieldDefinition as DateTimePickerDefinition;

    if (fieldValue) {
      const rawValue = fieldValue[inputField.dateTimePropCode];
      if (typeof (rawValue) === 'object') {
        return `${rawValue.toLocaleDateString()} ${rawValue.toLocaleTimeString()}`
      } else {
        return rawValue;
      }
    } else {
      return undefined;
    }
  }

}
