import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { GenericModalComponent } from 'src/app/modals/generic-modal/generic-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modal: BsModalRef;

  constructor(private _modalService: BsModalService) { }

  public showGeneric(params: GenericModalParams): void {
    const okButton = {
      text: (params && params.okButton && params.okButton.text) ? params.okButton.text : 'NAVIGATION.BTN.BTN_OK',
      callback: (params && params.okButton && params.okButton.callback) ? params.okButton.callback : undefined
    };

    const cancelButton = params.cancelButton ? {
      text: (params && params.cancelButton && params.cancelButton.text) ? params.cancelButton.text : 'NAVIGATION.BTN.BTN_CLOSE',
      callback: (params && params.cancelButton && params.cancelButton.callback) ? params.cancelButton.callback : undefined
    } : undefined;

    const initialState = {
      title: params.title,
      message: params.message,
      size: params.size || 'md',
      ok: okButton,
      cancel: cancelButton
    };

    this._showModal(GenericModalComponent, initialState);
  }

  /**
  *
  * @param modalComponent Is component that will be place inside the modal.
  * @param params Is the modal configuration, `title` and `content` correspond to the
  * public properties of the `GenericModalComponent` component.
  */
  public showCustom(modelComponent: any, params?: any): void {
    this._showModal(modelComponent, params);
  }

  public close(): void {
    for (let i = 1; i <= this._modalService.getModalsCount(); i++) {
      this._modalService.hide(i);
    }
  }

  /**
   *
   * @param modalComponent Is the component that will be place inside the modal.
   * @param params Is the modal configuration, `title` and `content` correspond to the
   * public properties of the `GenericModalComponent` component.
   * @param limit Is the max number of modal that can be opened.
   */
  private _showModal(modalComponent: any, params: any, limit: number = 1): void {
    if (this._modalService.getModalsCount() <= limit) {
      this.modal = this._modalService.show(modalComponent, {
        initialState: params,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: `modal-${params && params.size ? params.size : 'md'}`
      });
    }
  }
}

export interface GenericModalParams {
  title: string;
  message: string;
  size?: 'sm' | 'md' | 'lg';

  okButton?: ModalParamButton;
  cancelButton?: ModalParamButton;
}

export interface ModalParamButton {
  text?: string;
  callback?: Function;
}
