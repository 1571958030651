import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { FieldDefinition, FormDefinition } from 'src/app/models/form-field-definition.models';
import { CustomFormValidatorService } from '../../behavior/custom-form-validator/custom-form-validator.service';
import { Entity } from 'src/app/models/entity.model';

export function isSameField(prevValue: AnyValueEvent, nextValue: AnyValueEvent) {
  return (prevValue.currentField.name === nextValue.currentField.name);
}

export function isSameValue(prevValue: AnyValueEvent, nextValue: AnyValueEvent) {
  return (prevValue.currentField.name === nextValue.currentField.name)
    && (prevValue.currentField.value === nextValue.currentField.value);
}

export interface ValueEvent {
  name: string;
  value: any;
}

export interface AnyValueEvent {
  currentField: ValueEvent;
  changes: Map<string, any>;
}

@Injectable({
  providedIn: 'root'
})
export class FieldValueHandlerService {
  public readonly rowEntityData$: Observable<Entity>;
  public readonly anyValue$: Observable<AnyValueEvent>;

  public readonly detailCodeValue$: Observable<string>;
  public readonly ownerPartyRoleValue$: Observable<string>;
  public readonly currentStatusCodeValue$: Observable<string>;
  public readonly currentEnvironmentStatusCodeValue$: Observable<string>;
  public readonly formDefinitionValue$: Observable<Array<FormDefinition>>;

  private readonly _rowEntityData = new BehaviorSubject<Entity>(undefined);
  private readonly _anyValue = new BehaviorSubject<AnyValueEvent>(undefined);

  private readonly _detailCodeValue = new BehaviorSubject<string>(undefined);
  private readonly _ownerPartyRoleValue = new BehaviorSubject<string>(undefined);
  private readonly _currentStatusCodeValue = new BehaviorSubject<string>(undefined);
  private readonly _currentEnvironmentStatusCodeValue = new BehaviorSubject<string>(undefined);
  private readonly _formDefinitionValue = new BehaviorSubject<Array<FormDefinition>>(undefined);

  private _anyValueWall: Map<string, any> = new Map();
  private _lastDetailCode: string;

  constructor() {
    this.rowEntityData$ = this._rowEntityData.asObservable()
      .pipe(filter(x => x != undefined));

    this.anyValue$ = this._anyValue.asObservable()
      .pipe(filter(x => x != undefined), distinctUntilChanged());

    this.detailCodeValue$ = this._detailCodeValue.asObservable()
      .pipe(filter(x => x != undefined), distinctUntilChanged());

    this.ownerPartyRoleValue$ = this._ownerPartyRoleValue.asObservable()
      .pipe(filter(x => x != undefined), distinctUntilChanged());

    this.currentStatusCodeValue$ = this._currentStatusCodeValue.asObservable()
      .pipe(distinctUntilChanged());

    this.currentEnvironmentStatusCodeValue$ = this._currentEnvironmentStatusCodeValue.asObservable()
      .pipe(distinctUntilChanged());

    this.formDefinitionValue$ = this._formDefinitionValue.asObservable()
      .pipe(filter(x => x != undefined));
  }

  public setRawData(entity: Entity) {
    this._rowEntityData.next(entity);
  }

  public setAnyValue(name: string, field: FieldDefinition) {
    let value: any = field.value;
    let detailCode = this._detailCodeValue.getValue();

    if (Array.isArray(field.value)) {
      if (field.catalogItems && field.catalogItems.length > 0) {
        value = field.catalogItems
          .filter(catalogItem => (field.value as Array<any>)
            .some(value => value === catalogItem.code));

        if (value.length === 0 && field.catalogCodeProperty) {
          value = field.catalogItems
            .filter(catalogItem => (field.value as Array<any>)
              .some(value => value === catalogItem[field.catalogCodeProperty]));
        }
      }
    }

    if (this._lastDetailCode !== detailCode || this._anyValueWall == undefined) {
      this._anyValueWall.clear();
    }
    this._anyValueWall.set(name, value);

    this._lastDetailCode = detailCode;

    this._anyValue.next({
      currentField: { name, value },
      changes: this._anyValueWall
    });
  }

  public setGenericFormValues(name: string, value: string) {
    this._anyValueWall.set(name, value);

    this._anyValue.next({
      currentField: { name, value },
      changes: this._anyValueWall
    });
  }

  public setDetailCode(code: string) {
    this._detailCodeValue.next(code);
    CustomFormValidatorService.detailCode = code;
  }

  public clearDetailCode() {
    this._detailCodeValue.next(undefined);
    CustomFormValidatorService.detailCode = undefined;
  }

  public setOwnerPartyRoleValue(code: string) {
    this._ownerPartyRoleValue.next(code);
  }

  public setCurrentStatusCodeValue(currentStatusCode: string) {
    this._currentStatusCodeValue.next(currentStatusCode);
  }

  public setCurrentEnvironmentStatusCodeValue(currentEnvironmentStatusCode: string) {
    this._currentEnvironmentStatusCodeValue.next(currentEnvironmentStatusCode);
  }

  public cleanStatusCodeValues() {
    this._currentStatusCodeValue.next(undefined);
    this._currentEnvironmentStatusCodeValue.next(undefined);
  }

  public setFormDefinition(form: Array<FormDefinition>) {
    this._formDefinitionValue.next(form);
  }

  public getOwnerPartyRoleValue() {
    return this._ownerPartyRoleValue.getValue();
  }

  public getDetailCode() {
    return this._detailCodeValue.getValue();
  }

  public getAnyValue() {
    return this._anyValue.getValue();
  }

  public getFormDefinitionValue() {
    return this._formDefinitionValue.getValue();
  }
}
