import { AbstractControl } from '@angular/forms';
import { RuleModel } from 'src/app/models/rule.model';
import { Injectable } from '@angular/core';
import { EntityHandlerService } from '../../handler/entity-handler/entity-handler.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomFormValidatorService {
  public static ownerPartyRoleInstanceCode: string;
  public static detailCode: string;

  public static maxRuleQuantityMessage = (n: number) => `Maximun rule quantity is ${n}`;
  public static repeatedCodeMessage = () => 'Repeated Code';
  public static invalidDate = () => 'Invalid Date';

  constructor() { }

  public static ValidateRuleQuantity(max: number) {
    return (control: AbstractControl) => {
      const rules = control.value && control.value.rules as Array<RuleModel>;

      if (rules && Object.keys(rules).length > max) {
        return { maxRuleQuantity: true };
      }
      return null;
    };
  }

  public static ValidateCodeUnicity(entityHandler: EntityHandlerService) {
    return (control: AbstractControl) => {
      const entityCode = control.value ? (control.value as string).trim() : undefined;

      if (this.detailCode != undefined) {
        return of(null);
      }

      if (entityCode != undefined && this.ownerPartyRoleInstanceCode != undefined) {
        return entityHandler.getEntityByCodeAndOwner(entityCode, this.ownerPartyRoleInstanceCode)
          .pipe(
            map(entity => { return entity.code === entityCode ? { repeatedCode: true } : null; }),
            catchError(() => of(null)));
      } else {
        return of(null);
      }
    };
  }

  public static ValidateDate(pattern: string) {
    return (control: AbstractControl) => {
      const regex = new RegExp(pattern);

      if (regex.test(control.value)) {
        const year = parseInt(control.value.toString().slice(6));
        const minYear = 1753;
        const maxYear = 9999;

        if (year < minYear || year > maxYear) {
          return { invalidDate: true };
        }
      }

      return null;
    };
  }
}
