/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./grid-viewer-field.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading/ghost-loading/ghost-loading.component.ngfactory";
import * as i3 from "../loading/ghost-loading/ghost-loading.component";
import * as i4 from "../grid/grid.component.ngfactory";
import * as i5 from "../grid/grid.component";
import * as i6 from "@angular/common";
import * as i7 from "./grid-viewer-field.component";
import * as i8 from "../../services/handler/detail-view-handler/detail-view-handler.service";
import * as i9 from "../../services/handler/grid-handler/grid-handler.service";
import * as i10 from "../../services/handler/field-value-handler/field-value-handler.service";
import * as i11 from "../../services/helper/navigation-helper/navigation-helper.service";
import * as i12 from "../../services/handler/dashboard-handler/dashboard-handler.service";
var styles_GridViewerFieldComponent = [i0.styles];
var RenderType_GridViewerFieldComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GridViewerFieldComponent, data: {} });
export { RenderType_GridViewerFieldComponent as RenderType_GridViewerFieldComponent };
function View_GridViewerFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ghost-loading", [], null, null, null, i2.View_GhostLoadingComponent_0, i2.RenderType_GhostLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.GhostLoadingComponent, [], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_0 = "aggrid"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GridViewerFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grid", [], null, [[null, "rowDoubleClicked"], [null, "firstDataRendered"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rowDoubleClicked" === en)) {
        var pd_0 = (_co.rowDoubleClicked($event) !== false);
        ad = (pd_0 && ad);
    } if (("firstDataRendered" === en)) {
        var pd_1 = (_co.onFirstDataRendered($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_GridComponent_0, i4.RenderType_GridComponent)), i1.ɵdid(1, 770048, null, 0, i5.GridComponent, [], { data: [0, "data"], suppressRowClickSelection: [1, "suppressRowClickSelection"], groupHideOpenParents: [2, "groupHideOpenParents"], setExpanded: [3, "setExpanded"] }, { rowDoubleClicked: "rowDoubleClicked", firstDataRendered: "firstDataRendered" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.gridModel; var currVal_1 = _co.model.suppressRowClickSelection; var currVal_2 = true; var currVal_3 = _co.setExpanded; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_GridViewerFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridViewerFieldComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GridViewerFieldComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.loadingGrid; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.model.loadingGrid; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_GridViewerFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-grid-viewer-field", [], null, null, null, View_GridViewerFieldComponent_0, RenderType_GridViewerFieldComponent)), i1.ɵdid(1, 770048, null, 0, i7.GridViewerFieldComponent, [i8.DetailViewHandlerService, i9.GridHandlerService, i10.FieldValueHandlerService, i11.NavigationHelperService, i12.DashboardHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GridViewerFieldComponentNgFactory = i1.ɵccf("app-grid-viewer-field", i7.GridViewerFieldComponent, View_GridViewerFieldComponent_Host_0, { config: "config", definition: "definition" }, {}, []);
export { GridViewerFieldComponentNgFactory as GridViewerFieldComponentNgFactory };
