import { UtilService } from '../../behavior/util/util.service';
import { DashboardHandlerService } from '../../handler/dashboard-handler/dashboard-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../../behavior/util/util.service";
import * as i2 from "../../handler/dashboard-handler/dashboard-handler.service";
export class ApiHelperService {
    constructor(_utilService, _dashboardHandler) {
        this._utilService = _utilService;
        this._dashboardHandler = _dashboardHandler;
        this._createSubscriptions();
    }
    getUrlSection(detailCode, payload, overrideDashboardItem) {
        const dashboardItem = overrideDashboardItem || this._currentDashboardItem;
        let urlSection = dashboardItem.overrideViewCode || dashboardItem.viewCode;
        let type;
        if (dashboardItem.addTypeToUrl) {
            type = this._getTypeForUrl({ detailCode, payload });
            if (!type) {
                console.warn('This may be a misconfiguration.');
                type = this._utilService.getLibraryCodeFromInstance(detailCode);
            }
            urlSection = urlSection.concat(`/${type}`);
        }
        return urlSection;
    }
    _createSubscriptions() {
        this._dashboardHandler.currentDashboardItem$
            .subscribe(dashboardItem => this._currentDashboardItem = dashboardItem);
    }
    _getTypeForUrl(data) {
        let type;
        if (this._currentDashboardItem.typeField) {
            if (data.payload) {
                type = data.payload[this._currentDashboardItem.typeField];
            }
            else {
                type = this._utilService.getLibraryCodeFromInstance(data.detailCode);
            }
        }
        return type;
    }
}
ApiHelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiHelperService_Factory() { return new ApiHelperService(i0.ɵɵinject(i1.UtilService), i0.ɵɵinject(i2.DashboardHandlerService)); }, token: ApiHelperService, providedIn: "root" });
