<ng-container *ngIf="inputType=='text'">
  <input [id]="config?.id" [name]="config?.name" [attr.class]="config?.cssClasses" [type]="inputType"
    [placeholder]="config?.placeholder ? config.placeholder : ''" [autocomplete]="inputAutocomplete"
    [readonly]="config?.readonly" [mask]="config?.mask?.mask"
    [dropSpecialCharacters]="config?.mask?.dropSpecialCharacters" [showMaskTyped]="config?.mask?.showMaskTyped"
    [prefix]="config?.mask?.maskPrefix" [formControl]="input" (input)="applyRegex()" (blur)="trim()">
</ng-container>

<ng-container *ngIf="inputType!='text'">
  <input [id]="config?.id" [name]="config?.name" [attr.class]="config?.cssClasses" [type]="inputType"
    [placeholder]="config?.placeholder ? config.placeholder : ''" [autocomplete]="inputAutocomplete"
    [readonly]="config?.readonly" [formControl]="input" (input)="applyRegex()" (blur)="trim()">
</ng-container>

<ng-content></ng-content>

<span class="icon-validation" *ngIf="config?.validationIcons">
  <i [ngClass]="config?.validationIcons.pristine" *ngIf="input.pristine && !input.errors && showInputRequiredIcon"></i>
  <i [ngClass]="config?.validationIcons.error" *ngIf="!input.pristine && input.errors"></i>
  <i [ngClass]="config?.validationIcons.success" *ngIf="!input.pristine && !input.errors"></i>
</span>

<div *ngIf="input.errors && input.invalid && input.touched">
  <div [ngClass]="config?.errorMsgClass">{{getInputErrors()}}</div>
</div>