<select [id]="config?.id" [name]="config?.name" [attr.class]="config?.cssClasses" [attr.readonly]="config?.readonly"
  [formControl]="select">
  <option *ngIf="config && config.defaultTextItem" [ngValue]="null" disabled>{{config.defaultTextItem}}</option>
  <option *ngFor="let item of config?.items; trackBy: trackByFn.bind(this, config?.itemCode)" [ngValue]="item">
    {{config.itemText ? item[config.itemText] : item}}</option>
</select>

<span class="icon-validation" *ngIf="config?.validationIcons">
  <i [ngClass]="config?.validationIcons.pristine" *ngIf="select.pristine"></i>
  <i [ngClass]="config?.validationIcons.error" *ngIf="!select.pristine && select.errors"></i>
  <i [ngClass]="config?.validationIcons.success" *ngIf="!select.pristine && !select.errors"></i>
</span>

<div *ngIf="select.errors && select.invalid && select.touched">
  <div [ngClass]="config?.errorMsgClass">{{getSelectErrors()}}</div>
</div>