import { Component, OnInit, OnChanges, OnDestroy, SimpleChanges, Input, EventEmitter, forwardRef, Output } from '@angular/core';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, Validators } from '@angular/forms';
import { CatalogGridDefinition } from 'src/app/models/form-field-definition.models';
import { Subscription } from 'rxjs';
import { GridInput } from '../grid/grid.model';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { GridApi, RowEvent } from 'ag-grid-community';
import { AggridColumnHelperService } from 'src/app/services/helper/aggrid-column-helper/aggrid-column-helper.service';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { ElementConfig } from 'ngx-emerios-all';
import { FormOperationType } from 'src/app/models/operation.models';

const noOp = () => { };

export interface ViewModel {
  gridModel: GridInput;
  loadingGrid: boolean;
  rowSelection: string;
  suppressRowClickSelection: boolean;
}

export const CUSTOM_CATALOG_GRID_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CatalogGridComponent),
  multi: true
};

export enum CatalogGridFilterByField {
  ownerPartyRole = "ownerPartyRole",
  detailCode = "detailCode"
}

@Component({
  selector: 'app-catalog-grid',
  templateUrl: './catalog-grid.component.html',
  styleUrls: ['./catalog-grid.component.sass'],
  providers: [CUSTOM_CATALOG_GRID_CONTROL_VALUE_ACCESSOR]
})
export class CatalogGridComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() public config: ElementConfig;
  @Input() public definition: CatalogGridDefinition;
  @Input() public forceValidation: EventEmitter<any>;
  @Input() public filterByField: CatalogGridFilterByField = CatalogGridFilterByField.ownerPartyRole;
  @Input() public readonly: boolean = false;

  @Output() public selectionChanged = new EventEmitter();

  public control: FormControl;
  public model = {} as ViewModel;
  public internalModel: Array<string>;

  private _formOperationType: FormOperationType;
  private _gridApi: GridApi;
  private _subscriptions: Array<Subscription> = [];
  private _updatingGridSelection: boolean;
  private _onChange: any;

  writeValue(obj: any): void {
    if (obj != this.control.value) {
      this.internalModel = obj;
      this._updateGridSelection();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }

  onTouchedCallback: () => void = noOp;

  constructor(
    private _detailViewHandler: DetailViewHandlerService,
    private _gridHandler: GridHandlerService,
    private _fieldValueHandler: FieldValueHandlerService,
    private _aggridColumnHelper: AggridColumnHelperService) {
    this.model.gridModel = {
      uid: undefined,
      source: undefined,
      columns: [],
      rows: []
    };
  }

  public gridSelectionChanged(event: RowEvent) {
    const gridApi = event.api as GridApi;

    if (!this._updatingGridSelection) {
      const selectedRows = gridApi.getSelectedRows();

      this.internalModel = selectedRows.map(x => x[this.definition.catalogCodeProperty || 'instanceCode']);

      this._updateInnerValue();
      this.selectionChanged.emit();
    }
  }

  public getControlErrors() {
    let errorMessage: string;

    if (this.control.errors && this.control.errors.required) {
      errorMessage = 'This is required';
    }

    return errorMessage;
  }

  public gridApiReady(gridApi: GridApi) {
    this._gridApi = gridApi;
    this._updateGridSelection();
  }

  public onFirstDataRendered(event: any) {
    if (this.internalModel && this._gridApi) {
      this._updateGridSelection();
    }
  }

  ngOnInit() {
    this._subscriptions.push(
      this._detailViewHandler.mode$
        .subscribe(mode => {
          this._formOperationType = this.readonly ? FormOperationType.View : mode;
          this.model.suppressRowClickSelection = mode === FormOperationType.View || this.readonly;
        }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && changes.config.currentValue) {
      this._initializeFormControl();
      this._configureComponent();
      this._updateInnerValue();
    }

    if (changes.forceValidation && changes.forceValidation.currentValue && changes.forceValidation.firstChange) {
      this._subscriptions.push(this.forceValidation
        .subscribe(() => {
          this.control.markAsDirty();
          this.control.markAsTouched();
          this.control.updateValueAndValidity();
        }));
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _initializeFormControl() {
    if (this.definition.attributes.required) {
      this.control = new FormControl(undefined, Validators.required);
    } else {
      this.control = new FormControl(undefined);
    }
  }

  private _configureComponent() {
    switch(this.definition.type)
    {
      case 'grid-catalog-single':
        this.model.rowSelection = 'single';
        break;
      case 'grid-catalog-multiple':
        this.model.rowSelection = 'multiple';
        break;
      default:
        this.model.rowSelection = 'multiple';
        break;
      }

    if (this.definition.loadStandalone) {
      this._getGridData();
    } else {
      
      if(this.filterByField == CatalogGridFilterByField.detailCode)
      {
        this._subscriptions.push(
          this._fieldValueHandler.detailCodeValue$
            .subscribe(detailCode => this._getGridData(detailCode)));
      }
      else
      {

        this._subscriptions.push(
          this._fieldValueHandler.ownerPartyRoleValue$
            .subscribe(ownerPartyRole => this._getGridData(ownerPartyRole)));
      }
    }

    this._registerGetData();
  }

  private _updateInnerValue() {
    this.control.setValue(this.internalModel);

    if (this._onChange) {
      this._onChange(this.control.value);
    }
  }

  private _createCheckboxColumn(gridInput: GridInput) {
    if ([FormOperationType.Create, FormOperationType.Edit].includes(this._formOperationType)) {
      const showHeaderCheckbox = this.definition.multiselect;
      const column = this._aggridColumnHelper.getNativeCheckboxColumn(showHeaderCheckbox);

      gridInput.columns.splice(0, 0, column);
    }

    return gridInput;
  }

  private _registerGetData() {
    this._subscriptions.push(
      this._gridHandler.getGenericGridDataAndDef$
        .subscribe(gridInput => {
          if (this.definition.grid.source === gridInput.source) {
            this.model.gridModel = this._createCheckboxColumn(gridInput);
            this.model.loadingGrid = false;

            // this component is a catalog type, so this prop must be filled
            this.definition.catalogItems = gridInput.rows as any;
          }
        }));
  }

  private _getGridData(instanceCode?: string) {
    this.model.loadingGrid = true;
    this.internalModel = [];

    this._updateInnerValue();

    this._gridHandler.getGenericGridDefAndCustomData(this.definition.grid, instanceCode);
  }

  private _updateGridSelection() {
    if (this._gridApi) {
      let lastSelectedRow = -1;

      this._updatingGridSelection = true;
      this._gridApi.forEachNode(node => {
        const codePropValue = node.data[this.definition.catalogCodeProperty || 'instanceCode'];

        if (this.internalModel && this.internalModel.includes(codePropValue)) {
          node.setSelected(true);
          lastSelectedRow = node.rowIndex;
        }
      });
      if (lastSelectedRow > -1) {
        this._gridApi.ensureIndexVisible(lastSelectedRow);
      }
    }
    setTimeout(() => {
      this._updatingGridSelection = false;
    });
  }

}
