import { environment } from 'src/environments/environment';
import { RestService } from 'ngx-emerios-all';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class ProductRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    createFeatureApplicability(relationships) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/product-type/feature-applicability/`,
            body: relationships
        });
    }
    deleteFeatureApplicability(relationships) {
        return this._rest.delete({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/product-type/feature-applicability/`,
            body: relationships
        });
    }
    getProductsByOwner(instanceCode) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/product/owner/${instanceCode}`
        });
    }
}
ProductRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductRestService_Factory() { return new ProductRestService(i0.ɵɵinject(i1.RestService)); }, token: ProductRestService, providedIn: "root" });
