import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class FormRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getFormDefinitionView(source) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/data/${source}/form-definition-view`
        }).pipe(map((response) => response.definition));
    }
}
FormRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormRestService_Factory() { return new FormRestService(i0.ɵɵinject(i1.RestService)); }, token: FormRestService, providedIn: "root" });
