import { EventEmitter } from '@angular/core';
import { ProductRestService } from 'src/app/services/rest/product/product-rest.service';
import { EntityRestService } from 'src/app/services/rest/entity-rest/entity-rest.service';
import * as i0 from "@angular/core";
import * as i1 from "../../rest/entity-rest/entity-rest.service";
import * as i2 from "../../rest/product/product-rest.service";
export class RelationshipHandlerService {
    constructor(_entityRest, _product) {
        this._entityRest = _entityRest;
        this._product = _product;
        this._relationshipDeleted = new EventEmitter();
        this._relationshipCreated = new EventEmitter();
        this.relationshipCreated$ = this._relationshipCreated.asObservable();
        this.relationshipDeleted$ = this._relationshipDeleted.asObservable();
    }
    createRelationship(entity, relationship) {
        this._entityRest.createEntityRelationships(entity, relationship)
            .subscribe(() => this._relationshipCreated.next('created'));
    }
    createInstanceRelationship(entity, relationship) {
        this._entityRest.createEntityRelationships(entity, relationship)
            .subscribe(() => this._relationshipCreated.next('created'));
    }
    createFeatureApplicability(relationships) {
        this._product.createFeatureApplicability(relationships)
            .subscribe(() => { this._relationshipCreated.next('created'); });
    }
    deleteRelationships(entity, relationships) {
        this._entityRest.deleteEntityRelationships(entity, relationships)
            .subscribe(() => this._relationshipDeleted.next('removed'));
    }
    deleteFeatureApplicability(relationships) {
        this._product.deleteFeatureApplicability(relationships)
            .subscribe(() => this._relationshipDeleted.next('removed'));
    }
    updateSequence(entity, sequences) {
        this._entityRest.updateSequence(entity, sequences)
            .subscribe(() => this._relationshipDeleted.next('updated'));
    }
}
RelationshipHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RelationshipHandlerService_Factory() { return new RelationshipHandlerService(i0.ɵɵinject(i1.EntityRestService), i0.ɵɵinject(i2.ProductRestService)); }, token: RelationshipHandlerService, providedIn: "root" });
