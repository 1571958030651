import { EventEmitter, forwardRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { CacheService } from 'ngx-emerios-all';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { JourneyContextQueryStr } from 'src/app/enums/query-string';
import { ChangeRequestStatus } from 'src/app/models/campaign-builder.models';
import { JourneyType } from 'src/app/models/journey.models';
import { MessageService } from 'src/app/services/behavior/message/message.service';
import { UtilService } from 'src/app/services/behavior/util/util.service';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';
import { DashboardHandlerService } from 'src/app/services/handler/dashboard-handler/dashboard-handler.service';
import { EntityHandlerService } from 'src/app/services/handler/entity-handler/entity-handler.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { JourneyHandlerService } from 'src/app/services/handler/journey-handler/journey-handler.service';
const noOp = () => { };
const ɵ0 = noOp;
export const CUSTOM_ENTITY_LISTER_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EntityListerComponent),
    multi: true
};
export class InstanceCodeName {
}
export class EntityListerComponent {
    constructor(_dashboardHandler, _gridHandler, _journeyHandler, _authHandler, _message, _entityHandler, _fieldValueHandler, _cache, _utils) {
        this._dashboardHandler = _dashboardHandler;
        this._gridHandler = _gridHandler;
        this._journeyHandler = _journeyHandler;
        this._authHandler = _authHandler;
        this._message = _message;
        this._entityHandler = _entityHandler;
        this._fieldValueHandler = _fieldValueHandler;
        this._cache = _cache;
        this._utils = _utils;
        this.groupSelectsChildren = true;
        this._subscriptions = [];
        this._bookInstanceCodeNames = [];
        this.onTouchedCallback = noOp;
    }
    writeValue(obj) {
        if (obj != this.control.value) {
            this._updateInnerValue(obj);
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    selectionChanged(event) {
        const gridApi = event.api;
        const selectedNodes = gridApi.getSelectedRows();
        if (selectedNodes) {
            this._entityHandler._setErrorMessagesAfterCheckingEntityList(null);
            // this._handleMultipleSelection(selectedNodes);
            if (this.gridModel.source === AgGridEnum.Book || this._isChangeRequestDeploy) {
                this._handleMultipleSelection(selectedNodes);
            }
            else {
                this._handleSingleSelection(selectedNodes);
            }
        }
    }
    onGridDataLoaded(event) {
        this.showGhostLoading = false;
        if (event.source != AgGridEnum.Flow) {
            // This is to validate that is not an agree flow journey. Promotes journeys are not using paginated so far
            // If this change, then fix this brittle validation.
            this._isChangeRequestDeploy = true;
        }
        this.gridModel = event;
    }
    ngOnInit() {
        this._updateContextVariables(undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            if (changes.config.firstChange) {
                this._createRegistrations();
            }
            this._initializeFormControl();
        }
        if (changes.forceValidation && changes.forceValidation.currentValue && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => {
                this.control.markAsDirty();
                this.control.markAsTouched();
                this.control.updateValueAndValidity();
            }));
        }
    }
    ngOnDestroy() {
        if (this._bookInstanceCodeNames.length > 0) {
            this._bookInstanceCodeNames.forEach(x => this._setExecutionOrder(x));
            this._bookInstanceCodeNames.sort((a, b) => a.order - b.order);
            this._journeyHandler.setBookInstanceData(this._bookInstanceCodeNames);
        }
        this._subscriptions.forEach(x => x.unsubscribe());
    }
    _setExecutionOrder(x) {
        const libraryCode = this._utils.getLibraryCodeFromInstance(x.instanceCode);
        switch (libraryCode) {
            case 'P.ORG.BK':
                x.order = 1;
                break;
            case 'BIN.BK':
                x.order = 2;
                break;
            case 'P.PER.BK':
                x.order = 3;
                break;
            case 'CTG.PDT.BK':
                x.order = 4;
                break;
            case 'PRD.BK':
                x.order = 5;
                break;
            case 'CTG.GEO.BK':
                x.order = 6;
                break;
            case 'GEO.GRP.BK':
                x.order = 7;
                break;
            case 'STP.CNT.BK':
                x.order = 8;
                break;
            case 'CTL.BK':
                x.order = 9;
                break;
            case 'DC.EMAIL_TPL.BK':
                x.order = 10;
                break;
            case 'CTG.CL.BK':
                x.order = 11;
                break;
            case 'FLW.BK':
                x.order = 12;
                break;
            case 'FLW.ORD.BK':
                x.order = 13;
                break;
            case 'CMP.BK':
                x.order = 14;
                break;
            default:
                x.order = 0;
                break;
        }
    }
    _initializeFormControl() {
        if (this.definition.attributes.required) {
            this.control = new FormControl(undefined, Validators.required);
        }
        else {
            this.control = new FormControl(undefined);
        }
    }
    _updateContextVariables(entityOwnerPartyRoleInstanceCode, entityInstanceCode, entityInstanceName, entityChangeRequestCode, entityDynaf, code, isPromoteRequest) {
        const kvs = {};
        kvs[JourneyContextQueryStr.EntityOwnerPartyRoleInstanceCode] = entityOwnerPartyRoleInstanceCode;
        kvs[JourneyContextQueryStr.EntityInstanceCode] = entityInstanceCode;
        kvs[JourneyContextQueryStr.EntityInstanceName] = entityInstanceName;
        kvs[JourneyContextQueryStr.EntityChangeRequestCode] = entityChangeRequestCode;
        kvs[JourneyContextQueryStr.EntityDynaf] = entityDynaf;
        kvs[JourneyContextQueryStr.TenantCodeSource] = code;
        kvs[JourneyContextQueryStr.TenantCodeTarget] = code;
        kvs[JourneyContextQueryStr.IsPromoteRequest] = isPromoteRequest;
        this._journeyHandler.updateContextMultiple(kvs);
    }
    _createRegistrations() {
        this._subscriptions.push(this._gridHandler.getGenericGridDataAndDef$
            .pipe(filter(data => data.source === this.gridDashboardItem.gridSource))
            .subscribe(data => {
            this.gridModel = data;
            this.showGhostLoading = false;
            this.rowClassRules = {
                'pending-approval-warning': (params) => {
                    const isChangeRequestPendingApproval = params.data
                        && params.data.changeRequestStatusCode == ChangeRequestStatus.PENDING_APPROVAL;
                    const isParentOfPendingApproval = params.node.allLeafChildren
                        && params.node.allLeafChildren.find(x => x.data && x.data.changeRequestStatusCode === ChangeRequestStatus.PENDING_APPROVAL);
                    return isChangeRequestPendingApproval || isParentOfPendingApproval;
                }
            };
        }));
        this._subscriptions.push(combineLatest(this._dashboardHandler.dashboardItemList$, this._journeyHandler.currentJourneyStep$.pipe(filter(x => x != undefined))).subscribe(result => {
            if (!this.gridDashboardItem) {
                this.gridDashboardItem = result[0]
                    .find(x => x.formSource === result[1].relatedDynamicFormLibraryCode);
                if (!this.gridDashboardItem) {
                    this.gridDashboardItem = result[0]
                        .find(x => x.formSourceAlternative === result[1].relatedDynamicFormLibraryCode);
                }
            }
        }));
        this._subscriptions.push(this._journeyHandler.journeyType$
            .pipe(switchMap(journeyType => {
            this._journeyType = journeyType;
            this.showPaginatedGrid = this.gridDashboardItem.gridSource != AgGridEnum.Book; //this._journeyType === JourneyType.Deploy ||
            return this._fieldValueHandler.ownerPartyRoleValue$
                .pipe(map(owner => ({ journeyType, owner })));
        }))
            .subscribe(({ journeyType, owner }) => {
            // if (journeyType === JourneyType.Promote && owner) {
            if (this.gridDashboardItem.gridSource === AgGridEnum.Book && owner) {
                this._getGridData(owner);
                this.showGhostLoading = true;
                if (this._journeyType === JourneyType.Deploy) {
                    this._isChangeRequestDeploy = true;
                }
            }
        }));
    }
    _getGridData(ownerPartyRoleInstanceCode) {
        if (this.gridDashboardItem) {
            if ([AgGridEnum.StepForm, AgGridEnum.StepContainer].includes(this.gridDashboardItem.gridSource)) {
                this._gridHandler.getGenericGridDataAndDefV2(this.gridDashboardItem, undefined, this.gridDashboardItem.libraryCodes);
            }
            else {
                this._gridHandler.getGenericGridDataAndDefV2(this.gridDashboardItem, undefined, undefined, ownerPartyRoleInstanceCode);
            }
        }
    }
    _getGridDataMultipleBooks() {
        this._gridHandler.getGenericGridDataAndDefV2(this.gridDashboardItem);
    }
    _updateInnerValue(value) {
        this.control.setValue(value);
        if (this._onChange) {
            this._onChange(value);
        }
    }
    _handleMultipleSelection(selectedNodes) {
        const instanceCodes = selectedNodes.map(node => node.instanceCode);
        if (selectedNodes.length > 0) {
            const pendingChangeRequestsStatus = selectedNodes.filter(node => node.changeRequestStatusCode === ChangeRequestStatus.PENDING_APPROVAL);
            if (pendingChangeRequestsStatus.length > 0) {
                const paragraphElement = '<p>One or more of the selected books are currently in an Approval Flow, please approve or reject the approval flow before continuing or update the book selection</p>';
                const items = pendingChangeRequestsStatus.map(changeRequest => `${changeRequest.instanceName} - ${changeRequest.instanceCode}`);
                const errorMessage = { message: paragraphElement, items: items };
                this._entityHandler._setErrorMessagesAfterCheckingEntityList(errorMessage);
                this._updateInnerValue(instanceCodes);
            }
            else {
                const changeRequestCodes = selectedNodes.map(node => node.changeRequestCode);
                const firstOwnerPartyRoleInstanceCode = selectedNodes[0].ownerPartyRoleInstanceCode;
                const samePartyRoleInstanceCodeCount = selectedNodes.filter(x => x.ownerPartyRoleInstanceCode == firstOwnerPartyRoleInstanceCode).length;
                let ownerPartyRoleInstanceCode = null;
                if (samePartyRoleInstanceCodeCount === selectedNodes.length) {
                    ownerPartyRoleInstanceCode = firstOwnerPartyRoleInstanceCode;
                }
                else {
                    ownerPartyRoleInstanceCode = this._authHandler.getWorkingOrganization().partyRoleInstanceCode;
                }
                const tenantCode = selectedNodes[0].ownerPartyRoleCode;
                this._updateInnerValue(instanceCodes);
                if (this._isChangeRequestDeploy) {
                    const bookDataObj = selectedNodes.map(node => {
                        return {
                            instanceCode: node.instanceCode,
                            instanceName: node.instanceName
                        };
                    });
                    this._bookInstanceCodeNames = bookDataObj;
                }
                this._updateContextVariables(ownerPartyRoleInstanceCode, null, null, changeRequestCodes, this.gridDashboardItem.formSource, tenantCode, !this._isChangeRequestDeploy);
            }
        }
    }
    _handleSingleSelection(selectedNodes) {
        const instanceCode = selectedNodes[0].instanceCode;
        const isEntitySelectedPendingForApprove = selectedNodes[0].changeRequestStatusCode === ChangeRequestStatus.PENDING_APPROVAL;
        if (isEntitySelectedPendingForApprove) {
            const paragraphElement = '<p>One or more of the selected books are currently in an Approval Flow, please approve or reject the approval flow before continuing or update the book selection</p>';
            const errorMessage = { message: paragraphElement };
            this._entityHandler._setErrorMessagesAfterCheckingEntityList(errorMessage);
            this._updateInnerValue([instanceCode]);
        }
        else {
            const instanceName = selectedNodes[0].instanceName;
            const changeRequestCode = selectedNodes[0].changeRequestCode;
            const ownerPartyRoleInstanceCode = selectedNodes[0].ownerPartyRoleInstanceCode;
            this._cache.set(CacheKeyEnum.BookSelected, instanceName);
            this._updateInnerValue([instanceCode]);
            this._updateContextVariables(ownerPartyRoleInstanceCode, instanceCode, instanceName, [changeRequestCode], this.gridDashboardItem.formSource, null, undefined);
            this._journeyHandler.setCurrentEntitySelected(selectedNodes[0]);
        }
    }
}
export { ɵ0 };
