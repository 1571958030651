import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class PresetviewRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getViews(source) {
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/views/${source}`
        });
    }
    saveView(source, presetView) {
        return this._rest.post({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/views/${source}`,
            body: presetView
        });
    }
    updateView(source, presetView) {
        return this._rest.put({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/views/${source}`,
            body: presetView
        });
    }
    deleteView(code) {
        return this._rest.delete({
            baseUrl: environment.campaignBuilderUrl,
            endpoint: `api/v1/config/views/${code}`
        });
    }
}
PresetviewRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PresetviewRestService_Factory() { return new PresetviewRestService(i0.ɵɵinject(i1.RestService)); }, token: PresetviewRestService, providedIn: "root" });
