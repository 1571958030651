import { Component, OnInit, ContentChildren, QueryList, AfterContentInit, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TabItemComponent } from './tab-item/tab-item.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.sass']
})
export class TabGroupComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
  @ContentChildren(TabItemComponent)
  public tabs: QueryList<TabItemComponent>;

  @Input()
  public currentIndex: number = 0;

  @Input()
  public showTabs: boolean = true;

  @Output()
  public tabClicked = new EventEmitter<number>();

  private _subscriptions: Array<Subscription> = [];

  constructor() { }

  public handleTabClick(index: number, emmitChange: boolean = true) {
    if (this.tabs) {
      this.currentIndex = index;
      this._setActiveIndex();

      if (emmitChange) {
        this.tabClicked.next(index);
      }
    }
  }

  ngOnInit() { }

  ngAfterContentInit() {
    if (this.tabs) {
      this._updateTabIndexes();
      this.handleTabClick(0);

      this.tabs.changes.subscribe(changes => {
        this._updateTabIndexes();
        this._setActiveIndex();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentIndex && changes.currentIndex.currentValue !== undefined) {
      this._setActiveIndex();
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  private _setActiveIndex() {
    if (this.tabs) {
      setTimeout(() => {
        this.tabs.forEach(x => x.activeIndex = this.currentIndex);
      }, 100);
    }
  }

  private _updateTabIndexes() {
    if (this.tabs) {
      let index = 0;
      this.tabs.forEach(x => x.tabIndex = index++);
    }
  }
}
