import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PresetViewDto } from 'src/app/models/preset-view.model';
import { filter, finalize } from 'rxjs/operators';
import { GridHandlerService } from '../grid-handler/grid-handler.service';
import { DashboardItem } from '../../../models/dashboard.models';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { PresetviewRestService } from 'src/app/services/rest/presetview-rest/presetview-rest.service';

@Injectable({
  providedIn: 'root'
})
export class PresetViewHandlerService {

  private readonly _presetViewSelected = new BehaviorSubject<PresetViewDto>(undefined);
  private readonly _presetViewList = new BehaviorSubject<PresetViewDto[]>([]);

  private readonly _presetViewSaved = new EventEmitter<string>();
  private readonly _presetViewDeleted = new EventEmitter<string>();

  private readonly _presetViewModalClosed = new EventEmitter();

  public readonly presetViewList$ = this._presetViewList.asObservable();
  public readonly presetViewSelected$ = this._presetViewSelected.asObservable().pipe(filter(x => x !== undefined));

  public readonly presetViewSaved$ = this._presetViewSaved.asObservable();
  public readonly presetViewDeleted$ = this._presetViewDeleted.asObservable();

  public readonly presetViewModalClosed$ = this._presetViewModalClosed.asObservable();

  private _dashboardItem: DashboardItem;

  constructor(
    private _presetViewRest: PresetviewRestService,
    private _dashboardHandler: DashboardHandlerService,
    private _gridHandler: GridHandlerService
  ) {

    this._dashboardHandler.currentDashboardItem$
      .subscribe(item => {
        this._dashboardItem = item;
      });
  }

  public getPresetViews(source: string, viewCodeSelected?: string) {
    this._presetViewRest.getViews(source)
      .subscribe(presetViews => {

        if (presetViews) {
          presetViews.unshift({
            name: 'Default',
            description: 'You can select the columns you want and save it as a view',
            viewCode: 'default'
          });

          this._presetViewList.next(presetViews);
        }

        if (viewCodeSelected) {
          const found = presetViews.find(presetView => presetView.viewCode === viewCodeSelected);

          if (found) {
            this._presetViewSelected.next(found);
          } else {
            this._presetViewSelected.next(presetViews[0]);
          }
        }
      });
  }

  public setPresetView(val: PresetViewDto) {
    this._gridHandler.setMainColDefinitionPristine();
    this._presetViewSelected.next(val);
  }

  public savePresetView(val: PresetViewDto) {
    this._presetViewRest.saveView(this._dashboardItem.gridSource, val)
      .pipe(finalize(() => {
        this.getPresetViews(this._dashboardItem.gridSource);
      }))
      .subscribe(saved => {
        this._presetViewSaved.next('saved');
        this.setPresetView(saved);
      });
  }

  public editPresetView(val: PresetViewDto) {
    this._presetViewRest.updateView(this._dashboardItem.gridSource, val)
      .pipe(finalize(() => {
        this.getPresetViews(this._dashboardItem.gridSource);
        this._presetViewSelected.next(val);
      })).subscribe(() => {
        this._presetViewSaved.next('saved');
      });
  }

  public updatePresetView(val: PresetViewDto) {
    this._presetViewRest.updateView(this._dashboardItem.gridSource, val)
      .pipe(finalize(() => {
        this.getPresetViews(this._dashboardItem.gridSource);
        this._presetViewSelected.next(val);
      })).subscribe(() => this._presetViewSaved.next('updated'));
  }

  public deletePresetView(val: PresetViewDto) {
    this._presetViewRest.deleteView(val.code)
      .pipe(finalize(() => {
        const presetViews = this._presetViewList.getValue().filter(v => v);
        const toDelete = presetViews.findIndex(v => v.code == val.code);
        const defaultView = presetViews[0];

        presetViews.splice(toDelete, 1);

        this._presetViewSelected.next(defaultView);
        this._presetViewList.next(presetViews);
      })).subscribe(() => this._presetViewDeleted.next('removed'));
  }

  public modalClosed() {
    this._presetViewModalClosed.next();
  }
}
