export function scrollToPosition(element: HTMLElement, to: number, duration: number, callback?: Function) {
    let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    let animateScroll = () => {
        currentTime += increment;
        let val = _easeInOutQuad(currentTime, start, change, duration);

        element.scrollTop = val;
        if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
            // setTimeout(animateScroll, increment);
        } else {
            if (callback) {
                callback();
            }
        }
    };
    animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
const _easeInOutQuad = (t: number, b: number, c: number, d: number) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};