<ng-container>
  <ag-grid-angular #agGrid class="ag-theme-balham grid-container" [rowClassRules]="rowClassRules"
    [rowData]="model.rowData" [columnDefs]="model.columnDefs" [autoGroupColumnDef]="model.autoGroupColumnDef"
    [defaultColDef]="model.defaultColDef" [sideBar]="sideBar" [floatingFilter]="true"
    [frameworkComponents]="frameworkComponents" [rowSelection]="rowSelection"
    [suppressRowClickSelection]="suppressRowClickSelection"
    [isExternalFilterPresent]="isExternalFilterPresent.bind(this)"
    [doesExternalFilterPass]="doesExternalFilterPass.bind(this)" [groupHideOpenParents]="groupHideOpenParents"
    [animateRows]="true" [rowDragManaged]="true" [treeData]="model.treeData" [getDataPath]="model.getDataPath"
    [groupDefaultExpanded]="model.groupDefaultExpanded" [rowModelType]="rowModelType" [pagination]="pagination"
    [cacheBlockSize]="cacheBlockSize" [paginationPageSize]="paginationPageSize"
    [getContextMenuItems]="getContextMenuItems.bind(this)" (gridReady)="onGridReady($event)"
    (firstDataRendered)="onFirstDataRendered($event)" (rowDataChanged)="onRowDataChanged($event)"
    (columnVisible)="somethingChanged($event)" (columnPinned)="somethingChanged($event)"
    (columnResized)="somethingChanged($event)" (columnMoved)="somethingChanged($event)"
    (filterChanged)="somethingChanged($event)" (rowDoubleClicked)="onRowDoubleClicked($event)"
    (selectionChanged)="onSelectionChanged($event)" (cellValueChanged)="onCellValueChanged($event)"
    (rowDragLeave)="onRowDragLeave($event)" (rowDragEnd)="onRowDragEnd($event)" (bodyScroll)="onBodyScroll($event)">
  </ag-grid-angular>
</ng-container>