import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { GridHandlerService } from '../grid-handler/grid-handler.service';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { PresetviewRestService } from 'src/app/services/rest/presetview-rest/presetview-rest.service';
import * as i0 from "@angular/core";
import * as i1 from "../../rest/presetview-rest/presetview-rest.service";
import * as i2 from "../dashboard-handler/dashboard-handler.service";
import * as i3 from "../grid-handler/grid-handler.service";
export class PresetViewHandlerService {
    constructor(_presetViewRest, _dashboardHandler, _gridHandler) {
        this._presetViewRest = _presetViewRest;
        this._dashboardHandler = _dashboardHandler;
        this._gridHandler = _gridHandler;
        this._presetViewSelected = new BehaviorSubject(undefined);
        this._presetViewList = new BehaviorSubject([]);
        this._presetViewSaved = new EventEmitter();
        this._presetViewDeleted = new EventEmitter();
        this._presetViewModalClosed = new EventEmitter();
        this.presetViewList$ = this._presetViewList.asObservable();
        this.presetViewSelected$ = this._presetViewSelected.asObservable().pipe(filter(x => x !== undefined));
        this.presetViewSaved$ = this._presetViewSaved.asObservable();
        this.presetViewDeleted$ = this._presetViewDeleted.asObservable();
        this.presetViewModalClosed$ = this._presetViewModalClosed.asObservable();
        this._dashboardHandler.currentDashboardItem$
            .subscribe(item => {
            this._dashboardItem = item;
        });
    }
    getPresetViews(source, viewCodeSelected) {
        this._presetViewRest.getViews(source)
            .subscribe(presetViews => {
            if (presetViews) {
                presetViews.unshift({
                    name: 'Default',
                    description: 'You can select the columns you want and save it as a view',
                    viewCode: 'default'
                });
                this._presetViewList.next(presetViews);
            }
            if (viewCodeSelected) {
                const found = presetViews.find(presetView => presetView.viewCode === viewCodeSelected);
                if (found) {
                    this._presetViewSelected.next(found);
                }
                else {
                    this._presetViewSelected.next(presetViews[0]);
                }
            }
        });
    }
    setPresetView(val) {
        this._gridHandler.setMainColDefinitionPristine();
        this._presetViewSelected.next(val);
    }
    savePresetView(val) {
        this._presetViewRest.saveView(this._dashboardItem.gridSource, val)
            .pipe(finalize(() => {
            this.getPresetViews(this._dashboardItem.gridSource);
        }))
            .subscribe(saved => {
            this._presetViewSaved.next('saved');
            this.setPresetView(saved);
        });
    }
    editPresetView(val) {
        this._presetViewRest.updateView(this._dashboardItem.gridSource, val)
            .pipe(finalize(() => {
            this.getPresetViews(this._dashboardItem.gridSource);
            this._presetViewSelected.next(val);
        })).subscribe(() => {
            this._presetViewSaved.next('saved');
        });
    }
    updatePresetView(val) {
        this._presetViewRest.updateView(this._dashboardItem.gridSource, val)
            .pipe(finalize(() => {
            this.getPresetViews(this._dashboardItem.gridSource);
            this._presetViewSelected.next(val);
        })).subscribe(() => this._presetViewSaved.next('updated'));
    }
    deletePresetView(val) {
        this._presetViewRest.deleteView(val.code)
            .pipe(finalize(() => {
            const presetViews = this._presetViewList.getValue().filter(v => v);
            const toDelete = presetViews.findIndex(v => v.code == val.code);
            const defaultView = presetViews[0];
            presetViews.splice(toDelete, 1);
            this._presetViewSelected.next(defaultView);
            this._presetViewList.next(presetViews);
        })).subscribe(() => this._presetViewDeleted.next('removed'));
    }
    modalClosed() {
        this._presetViewModalClosed.next();
    }
}
PresetViewHandlerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PresetViewHandlerService_Factory() { return new PresetViewHandlerService(i0.ɵɵinject(i1.PresetviewRestService), i0.ɵɵinject(i2.DashboardHandlerService), i0.ɵɵinject(i3.GridHandlerService)); }, token: PresetViewHandlerService, providedIn: "root" });
