/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../modules/signature/sign-pad/sign-pad.component.ngfactory";
import * as i2 from "../../modules/signature/sign-pad/sign-pad.component";
import * as i3 from "../../services/rest/auth-rest/auth-rest.service";
import * as i4 from "../../services/helper/modal-helper/modal-helper.service";
import * as i5 from "./signature-modal.component";
import * as i6 from "ngx-bootstrap/modal";
import * as i7 from "../../services/behavior/waiting-loader/waiting-loader.service";
var styles_SignatureModalComponent = [];
var RenderType_SignatureModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignatureModalComponent, data: {} });
export { RenderType_SignatureModalComponent as RenderType_SignatureModalComponent };
export function View_SignatureModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { signPad: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "h3", [["class", "modal-title pull-left"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-body campaign-modal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-sign-pad", [["color", "black"], ["font-size", "45"], ["font-type", "Lucida Handwriting"], ["height", "150"], ["text-max-length", "16"], ["weight", "3"], ["width", "500"]], null, null, null, i1.View_SignPadComponent_0, i1.RenderType_SignPadComponent)), i0.ɵdid(9, 4308992, [[1, 4], ["signPadComponent", 4]], 0, i2.SignPadComponent, [i3.AuthRestService, i4.ModalHelperService], { width: [0, "width"], height: [1, "height"], color: [2, "color"], weight: [3, "weight"], fontSize: [4, "fontSize"], fontType: [5, "fontType"], textMaxLength: [6, "textMaxLength"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-next"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary btn-fill"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.executeCallback() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, ["", ""]))], function (_ck, _v) { var currVal_2 = "500"; var currVal_3 = "150"; var currVal_4 = "black"; var currVal_5 = "3"; var currVal_6 = "45"; var currVal_7 = "Lucida Handwriting"; var currVal_8 = "16"; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 6, 0, currVal_1); var currVal_9 = _co.confirmText; _ck(_v, 14, 0, currVal_9); }); }
export function View_SignatureModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-signature-modal", [], null, null, null, View_SignatureModalComponent_0, RenderType_SignatureModalComponent)), i0.ɵdid(1, 114688, null, 0, i5.SignatureModalComponent, [i6.BsModalRef, i7.WaitingLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignatureModalComponentNgFactory = i0.ɵccf("app-signature-modal", i5.SignatureModalComponent, View_SignatureModalComponent_Host_0, { callback: "callback", confirmText: "confirmText", title: "title", message: "message" }, {}, []);
export { SignatureModalComponentNgFactory as SignatureModalComponentNgFactory };
