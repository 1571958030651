import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReplacerRestService {

  constructor(private _rest: RestService) { }

  public getReplacerByFilter(contextInstanceCodeArray: Array<string>, search: string, minSearchLength?: number):
    Observable<any> {
    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: 'api/v1/replacer/catalog-typeahead',
      params: {
        contextInstanceCodes: contextInstanceCodeArray,
        search: search,
        minSearchLength: minSearchLength
      }
    });
  }
}
