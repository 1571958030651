import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { UtilService } from 'src/app/services/behavior/util/util.service';
import { PresetViewDto } from 'src/app/models/preset-view.model';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { Subscription } from 'rxjs';
import { PresetViewHandlerService } from 'src/app/services/handler/preset-view-handler/preset-view-handler.service';

@Component({
  selector: 'app-save-grid-view',
  templateUrl: './save-grid-view.component.html',
  styleUrls: ['./save-grid-view.component.sass']
})
export class SaveGridViewComponent implements OnInit, OnDestroy {

  public model: PresetViewDto = {} as PresetViewDto;
  public title: string;
  public message: string;
  public okButton: any = {};
  public cancelButton: any = {};

  private _subscriptions: Array<Subscription> = [];

  constructor(
    private _gridHandler: GridHandlerService,
    private _presetViewHandler: PresetViewHandlerService,
    private _utils: UtilService,
    private _modal: BsModalRef) { }

  ngOnInit() {
    this._subscriptions.push(this._gridHandler.mainColumnDefinitions$
      .subscribe((definitions) => this.model.viewDefinition = definitions));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  public viewNameChanged() {
    this.model.viewCode = this.model.name
      .replace(/([a-z])([A-Z])/g, "$1-$2")
      .replace(/\s+/g, '-')
      .toLowerCase();
  }

  public okModal() {

    const output: PresetViewDto = {
      code: this.model.code,
      name: this.model.name,
      description: this.model.description,
      viewCode: this.model.viewCode,
      public: this.model.public,
      viewDefinition: this.model.viewDefinition
    };

    this._modal.hide();

    if (this.okButton.callback) {
      this.okButton.callback(output);
    }
  }

  public closeModal() {
    this._modal.hide();
    this._presetViewHandler.modalClosed();
    if (this.cancelButton.callback) {
      this.cancelButton.callback();
    }
  }

  public getBaseUrl() {
    return this._utils.getUrlWithSource();
  }
}
