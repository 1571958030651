import { Injectable } from '@angular/core';
import { CatalogRestService } from '../../rest/catalog-rest/catalog-rest.service';
import { EntityCatalog, Catalog, EntityCatalogItem } from 'src/app/models/catalog.models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogEnum } from 'src/app/enums/catalogs';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  private _catalogList: Array<EntityCatalog>;

  constructor(private _catalogRest: CatalogRestService) { }

  public set(catalogs: Array<EntityCatalog>) {
    this._catalogList = catalogs;
  }

  public get(code: string, untouched?: boolean) {
    if (code.startsWith('native')) {
      return this.getNativeCatalog(code);
    } else {
      const catalog = this._catalogList.find(f => f.code == code);

      if (catalog && catalog.items) {
        const items = untouched ? catalog.items : this._applyCustomFilter(catalog);
        return items;
      }
      return [];
    }
  }

  public getNativeCatalog(code: string) {
    let items: Array<EntityCatalogItem> = [];

    switch (code) {
      case 'native_yesno':
        items.push({ code: 'true', description: 'Yes' } as EntityCatalogItem);
        items.push({ code: 'false', description: 'No' } as EntityCatalogItem);
        break;
      case 'native_truefalse':
        items.push({ code: 'true', description: 'True' } as EntityCatalogItem);
        items.push({ code: 'false', description: 'False' } as EntityCatalogItem);
        break;
      case 'native_gender':
        items.push({ code: 'M', description: 'Male' } as EntityCatalogItem);
        items.push({ code: 'F', description: 'Female' } as EntityCatalogItem);
        break;
      case 'native_library_visibility':
        items.push({ code: 'public', description: 'Public' } as EntityCatalogItem);
        items.push({ code: 'emerios_only', description: 'Platform Provider Only' } as EntityCatalogItem);
        break;
      case 'native_form_operation_type':
        items.push({ code: 'CREATE', description: 'Create' } as EntityCatalogItem);
        items.push({ code: 'VIEW', description: 'View' } as EntityCatalogItem);
        items.push({ code: 'EDIT', description: 'Edit' } as EntityCatalogItem);
        // Approve = 'APPROVE'
        break;
      case 'native_question_part_type':
        items.push({ code: 'AUDIO', description: 'Audio' } as EntityCatalogItem);
        items.push({ code: 'TEXT', description: 'Text' } as EntityCatalogItem);
        break;
      case 'native_promote_operation':
        // Commented out because Update option is always the one selected in promote operations (probabliy no longer available)
        // items.push({ code: 'CREATE', description: 'Create' } as EntityCatalogItem);
        items.push({ code: 'UPDATE', description: 'Update' } as EntityCatalogItem);
        break;
      }

    return items;
  }

  public getCatalogsForDetail(sourceCode: string): Observable<Array<EntityCatalog>> {
    return this._catalogRest.getCatalogsForDetail(sourceCode);
  }

  public getFromServer(codes: Array<string>) {
    return this._catalogRest.getCatalogs(codes);
  }

  public getSingleWithFilterFromServer(code: string, filterBy: Array<string>) {
    return this._catalogRest.getCatalog(code, filterBy);
  }

  public getFiltrableCatalog<T>(catalog: string): Observable<Catalog<T>>;
  public getFiltrableCatalog<T>(catalog: string, filterBy: string): Observable<Catalog<T>>;
  public getFiltrableCatalog<T>(catalog: string, filterBy: Array<string>): Observable<Catalog<T>>;
  public getFiltrableCatalog<T>(catalog: string, filterBy: string | Array<string> = ['']): Observable<Catalog<T>> {
    const arr = Array.isArray(filterBy) ? filterBy : [filterBy];
    return this._catalogRest.getFiltrableCatalog<T>(catalog, arr);
  }

  public getFullCatalog<T>(code: string): Observable<Catalog<T>> {
    return this.getFullCatalogs<T>([code as CatalogEnum])
      .pipe(map(catalogs => catalogs && catalogs[0]));
  }

  public getFullCatalogs<T>(codes: Array<CatalogEnum>): Observable<Array<Catalog<T>>> {
    return this._catalogRest.getFullCatalogs<T>(codes);
  }

  public getSimpleCatalogDescriptionObject(items: Array<EntityCatalogItem>) {
    let newObjectItems = items.map(item => {
      let desc = item.description;

      if (item.descriptions) {
        desc = item.descriptions[0].text;
      }

      const newItem = Object.assign({
        code: item.code,
        description: desc,
      } as Partial<EntityCatalogItem>, item);

      return newItem;
    });

    return newObjectItems;
  }

  private _applyCustomFilter(catalog: EntityCatalog): Array<EntityCatalogItem> {
    return catalog.items;
  }
}
