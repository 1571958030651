import { OnInit, forwardRef, OnChanges, OnDestroy, SimpleChanges, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, Validators, FormGroup } from '@angular/forms';
import { ElementHelperService } from 'src/app/services/helper/element-helper/element-helper.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FormOperationType } from 'src/app/models/operation.models';
import { CatalogService } from 'src/app/services/behavior/catalog/catalog.service';
import { CatalogEnum, CatalogNativeEnum } from 'src/app/enums/catalogs';
import { QssHandlerService } from 'src/app/services/handler/qss-handler/qss-handler.service';
import { take } from 'rxjs/operators';
const noOp = () => { };
const ɵ0 = noOp;
export const NAVIGATION_RULES_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NavigationRulesComponent),
    multi: true
};
export class NavigationRulesComponent {
    constructor(_detailViewHandler, _elementHelper, _fieldValueHandler, _qssHandlerService, _catalog) {
        this._detailViewHandler = _detailViewHandler;
        this._elementHelper = _elementHelper;
        this._fieldValueHandler = _fieldValueHandler;
        this._qssHandlerService = _qssHandlerService;
        this._catalog = _catalog;
        this.changed = new EventEmitter();
        this.model = {};
        this._subscriptions = [];
        this.onTouchedCallback = noOp;
    }
    writeValue(obj) {
        if (obj != this.control.value) {
            if (obj && obj.length > 0) {
                this.internalModel = obj;
                this._ruleInstanceCode = this.internalModel[0].ruleInstanceCode;
                this._configureComponent();
            }
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    getControlErrors() {
        let errorMessage;
        if (this.control.errors && this.control.errors.required) {
            errorMessage = 'This is required';
        }
        return errorMessage;
    }
    setAsDirty() {
        this.control.markAsDirty();
    }
    ngOnInit() {
        this._subscriptions.push(this._fieldValueHandler.anyValue$.subscribe(anyValue => {
            this.model.currentStepName = `${anyValue.changes.get('instanceName')}`;
        }));
        this._subscriptions.push(this._fieldValueHandler.rowEntityData$.subscribe(data => {
            this._parentInstanceCode = data.parentInstanceCode;
        }));
        this._subscriptions.push(this._qssHandlerService.value$.subscribe(qss => {
            this._flowInstanceCode = qss.flowInstanceCode;
        }));
        this._subscriptions.push(this._detailViewHandler.mode$
            .pipe(take(1))
            .subscribe(mode => {
            this._formOperationType = mode;
            this._configureComponent();
            this._updateInnerValue();
        }));
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue) {
            this._initializeFormControl();
        }
        if (changes.forceValidation && changes.forceValidation.currentValue
            && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => {
                this.control.markAsDirty();
                this.control.markAsTouched();
                this.control.updateValueAndValidity();
            }));
        }
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
        this._formSubscription.unsubscribe();
    }
    _initializeFormControl() {
        if (this.definition.attributes.required) {
            this.control = new FormControl(undefined, Validators.required);
        }
        else {
            this.control = new FormControl(undefined);
        }
    }
    _configureComponent() {
        const factors = new Array();
        const form = {};
        const successFactorName = 'Success';
        const failFactorName = 'Fail';
        factors.push({
            name: successFactorName,
            actionName: `input${successFactorName}`,
            fireSaveName: `fireSave${successFactorName}`,
            weConfig: this._getFactorMultiselectConfig('Success'),
            actionConfig: this._getFactorActionNameConfig('Success'),
            fireSaveConfig: this._getFactorMultiselectConfig('Success', CatalogNativeEnum.YesNo)
        });
        factors.push({
            name: failFactorName,
            actionName: `input${failFactorName}`,
            fireSaveName: `fireSave${failFactorName}`,
            weConfig: this._getFactorMultiselectConfig('Fail'),
            actionConfig: this._getFactorActionNameConfig('Fail'),
            fireSaveConfig: this._getFactorMultiselectConfig('Fail', CatalogNativeEnum.YesNo)
        });
        factors.forEach(item => {
            const factor = this.internalModel && this.internalModel
                .find(x => x.factorResolutionStatus === item.name.toUpperCase());
            const weValue = factor ? [factor.outcomeWorkEffortInstanceCode] : undefined;
            const actionValue = factor ? factor.description : undefined;
            const fireSaveActionValue = factor ? [factor.fireSaveAction.toString()] : undefined;
            form[item.name] = new FormControl(weValue);
            form[item.actionName] = new FormControl(actionValue);
            form[item.fireSaveName] = new FormControl(fireSaveActionValue);
        });
        this.form = new FormGroup(form);
        this.model.factors = factors;
        this._createFormSubscription();
        this._getWorkeffortCatalog();
    }
    _updateInnerValue() {
        if (this.form.status === 'VALID') {
            this.control.setValue(this.internalModel);
        }
        else {
            this.control.setValue(undefined);
        }
        if (this._onChange) {
            this._onChange(this.control.value);
        }
    }
    _getFactorMultiselectConfig(name, catalog) {
        const attr = {
            name: name,
            editable: [FormOperationType.Create, FormOperationType.Edit].includes(this._formOperationType)
        };
        const config = this._elementHelper.getMultiSelectConfig(attr);
        config.singleSelect = true;
        if (catalog) {
            config.allowSearchFilter = false;
            this.model.yesNoCatalog = this._catalog.getNativeCatalog(catalog);
        }
        return config;
    }
    _getFactorActionNameConfig(name) {
        const attr = {
            name: name,
            editable: [FormOperationType.Create, FormOperationType.Edit].includes(this._formOperationType),
            maxlength: 10
        };
        const config = this._elementHelper.getInputConfig(attr);
        return config;
    }
    _getWorkeffortCatalog() {
        if (this._parentInstanceCode) {
            this._catalog
                .getFiltrableCatalog(CatalogEnum.StepsAndStagesByFlow, [this._flowInstanceCode, this._parentInstanceCode])
                .subscribe(catalog => {
                this.model.workeffortList = catalog.items;
            });
        }
    }
    _createFormSubscription() {
        if (this._formSubscription) {
            this._formSubscription.unsubscribe();
        }
        this._formSubscription = this.form.valueChanges
            .subscribe(values => {
            this.internalModel = [];
            this.model.factors.forEach(factor => {
                const config = this.model.factors.find(x => x.name == factor.name);
                if (values[factor.name] && values[factor.name][0]) {
                    this.internalModel.push({
                        ruleInstanceCode: this._ruleInstanceCode,
                        factorWorkEffortInstanceCode: null,
                        outcomeWorkEffortInstanceCode: values[factor.name] && values[factor.name][0],
                        factorResolutionStatus: factor.name,
                        description: values[`input${factor.name}`],
                        fireSaveAction: values[`fireSave${factor.name}`] && values[`fireSave${factor.name}`][0],
                    });
                    const markAsReadonly = ![FormOperationType.Create, FormOperationType.Edit].includes(this._formOperationType);
                    config.actionConfig.readonly = markAsReadonly;
                    config.fireSaveConfig.disabled = markAsReadonly;
                }
                else {
                    config.actionConfig.readonly = true;
                    config.fireSaveConfig.disabled = true;
                }
            });
            this._updateInnerValue();
        });
    }
}
export { ɵ0 };
