import { OnInit, OnChanges, OnDestroy, SimpleChanges, forwardRef, EventEmitter } from '@angular/core';
import { GridHandlerService } from 'src/app/services/handler/grid-handler/grid-handler.service';
import { NG_VALUE_ACCESSOR, FormControl, Validators } from '@angular/forms';
import { DetailViewHandlerService } from 'src/app/services/handler/detail-view-handler/detail-view-handler.service';
import { FieldValueHandlerService } from 'src/app/services/handler/field-value-handler/field-value-handler.service';
import { AggridColumnHelperService } from 'src/app/services/helper/aggrid-column-helper/aggrid-column-helper.service';
import { deepClone } from 'src/app/functions/deepClone';
import { combineLatest } from 'rxjs';
import { RuleModel } from 'src/app/models/rule.model';
import { CustomFormValidatorService } from 'src/app/services/behavior/custom-form-validator/custom-form-validator.service';
import { FormOperationType } from 'src/app/models/operation.models';
const noOp = () => { };
const ɵ0 = noOp;
const DEFAULT_SELECTOR_ROW_NAME = "selectedFlow";
export const CUSTOM_RULES_GRID_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RulesGridComponent),
    multi: true
};
export class RulesGridComponent {
    constructor(_fieldValueHandler, _detailViewHandler, _aggridColumnHelper, _gridHandler) {
        this._fieldValueHandler = _fieldValueHandler;
        this._detailViewHandler = _detailViewHandler;
        this._aggridColumnHelper = _aggridColumnHelper;
        this._gridHandler = _gridHandler;
        this.selectionChanged = new EventEmitter();
        this.model = {};
        this._itemsByRow = {};
        this._subscriptions = [];
        this.onTouchedCallback = noOp;
    }
    writeValue(obj) {
        if (obj != this.control.value) {
            this.internalModel = obj;
            this._updateGridSelection();
        }
    }
    registerOnChange(fn) {
        this._onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouchedCallback = fn;
    }
    setDisabledState(isDisabled) { }
    onCellValueChanged(event) {
        this._updateInnerModel(event.data);
        this.control.markAsDirty();
        this.selectionChanged.emit();
    }
    getControlErrors() {
        let errorMessage;
        if (this.control.errors) {
            if (this.control.errors.required) {
                errorMessage = this.config.validationMessages.required;
            }
            else if (this.control.errors.maxRuleQuantity) {
                errorMessage = this.config.validationMessages.maxRuleQuantity;
            }
        }
        return errorMessage;
    }
    gridApiReady(gridApi) {
        this._gridApi = gridApi;
    }
    onFirstDataRendered(event) {
    }
    ngOnInit() {
        this._subscriptions.push(this._detailViewHandler.mode$
            .subscribe(mode => {
            this._viewMode = mode;
            this.model.suppressRowClickSelection = mode === FormOperationType.View;
        }));
        this._subscriptions.push(combineLatest(this._fieldValueHandler.detailCodeValue$, this._fieldValueHandler.ownerPartyRoleValue$)
            .subscribe(results => {
            this.detailCode = results[0];
            this._ownerPartyRoleInstanceCode = results[1];
            // this._initiateGrid();
        }));
        this._initiateGrid();
    }
    ngOnChanges(changes) {
        if (changes.config && changes.config.currentValue !== undefined) {
            this._initializeFormControl();
            this._configureComponent();
            this._updateInnerValue();
        }
        if (changes.forceValidation && changes.forceValidation.firstChange) {
            this._subscriptions.push(this.forceValidation
                .subscribe(() => {
                this.control.markAsDirty();
                this.control.markAsTouched();
                this.control.updateValueAndValidity();
            }));
        }
    }
    ngOnDestroy() {
        this._subscriptions.forEach(x => x.unsubscribe());
    }
    _initializeFormControl() {
        const validators = [];
        if (this.definition.attributes.required) {
            validators.push(Validators.required);
        }
        if (this.definition.attributes.maxRuleQuantity) {
            validators.push(CustomFormValidatorService.ValidateRuleQuantity(this.definition.attributes.maxRuleQuantity));
        }
        this.control = new FormControl(undefined, validators);
    }
    _configureComponent() {
        this._listItemColumnName = this.definition.catalogColumnName;
        this._registerGetData();
    }
    _updateInnerValue() {
        this.control.setValue(deepClone(this.internalModel));
        if (this._onChange) {
            this._onChange(this.control.value);
        }
    }
    _updateInnerModel(rowData) {
        const selectedItem = rowData[this._selectorRowName];
        if (typeof (rowData[this._selectorRowName]) === 'string') {
            return;
        }
        if (this.internalModel == undefined) {
            this.internalModel = { rules: {} };
        }
        const ruleModel = this._createRuleModel(rowData);
        if (selectedItem && (selectedItem === '-- Not Available --' || selectedItem.code === 'NOT_AVAILABLE')) {
            delete this.internalModel.rules[ruleModel.getUniqueId()];
        }
        else {
            this.internalModel.detailCode = this.detailCode;
            this.internalModel.ownerPartyRoleInstanceCode = this._ownerPartyRoleInstanceCode;
            this.internalModel.rules[ruleModel.getUniqueId()] = ruleModel;
        }
        if (Object.keys(this.internalModel.rules).length === 0) {
            this.internalModel = undefined;
        }
        this._updateInnerValue();
    }
    _updateGridSelection() {
        if (!this._gridUpdatedInEdition && this._gridApi) {
            this._gridApi.forEachNode(rowNode => {
                const rowModel = this._createRuleModel(rowNode.data);
                const uniqueId = rowModel.getUniqueId();
                if (this.internalModel && this.internalModel.rules) {
                    Object.keys(this.internalModel.rules).forEach(prop => {
                        if (prop === uniqueId) {
                            const listItems = rowNode.data[this._listItemColumnName];
                            // ver como mejorar esto de alguna manera. por configuracion o por magia
                            const outcomeWorkEffort = this.internalModel.rules[prop].outcomeWorkEfforts[0];
                            let selectedItem;
                            if (outcomeWorkEffort != undefined) {
                                const workEffortInstanceCode = this.internalModel.rules[prop].outcomeWorkEfforts[0].workEffortInstanceCode;
                                selectedItem = listItems.find((x) => x.code === workEffortInstanceCode);
                            }
                            else {
                                const valueSelected = this.internalModel.rules[prop].factors[0].value;
                                selectedItem = listItems.find((x) => x.code === valueSelected);
                            }
                            rowNode.data['rowSelected'] = selectedItem != undefined;
                            if (this._selectorRowName === undefined) {
                                this._selectorRowName = DEFAULT_SELECTOR_ROW_NAME;
                            }
                            rowNode.data[this._selectorRowName] = selectedItem;
                        }
                    });
                }
            });
            this._gridApi.refreshCells();
            this._gridApi.setSortModel([{ colId: 'rowSelected', sort: 'desc' }]);
            this._gridUpdatedInEdition = true;
            this._updateInnerValue();
        }
    }
    _initiateGrid() {
        this.model.loadingGrid = true;
        this._setRuleTypeLibraryCode();
        this._gridHandler.getGenericGridDefAndCustomData(this.definition.grid, this.detailCode);
    }
    _setRuleTypeLibraryCode() {
        const campaignLibraryCode = this.detailCode.slice(0, this.detailCode.lastIndexOf('.'));
        switch (campaignLibraryCode) {
            case 'CMP.TPV':
                this.definition.ruleTypeLibraryCode = 'RUL.CMP_TPV.ENTRY';
                break;
            case 'CMP.ECST_ORD_CRT':
                this.definition.ruleTypeLibraryCode = 'RUL.CMP_ECST_ORD_CRT.ENTRY';
                break;
            case 'CMP.ECST_ORD_CLS':
                this.definition.ruleTypeLibraryCode = 'RUL.CMP_ECST_ORD_CLS.ENTRY';
                break;
            case 'CMP.ECST_ORD_INIT':
                this.definition.ruleTypeLibraryCode = 'RUL.CMP_ECST_ORD_INIT.ENTRY';
                break;
            case 'CMP.PRD':
                this.definition.ruleTypeLibraryCode = 'RUL.CMP_ENR.ENTRY';
                break;
            default:
                console.warn(`${campaignLibraryCode} does not match.`);
                break;
        }
    }
    _registerGetData() {
        this._subscriptions.push(this._gridHandler.getGenericGridDataAndDef$
            .subscribe(gridInput => {
            if (this.definition.grid.source === gridInput.source) {
                this.model.gridModel = this._attachValueGetters(gridInput);
                this.model.gridModel = this._createCheckboxColumn(this.model.gridModel);
                this.model.loadingGrid = false;
                setTimeout(() => {
                    this._updateGridSelection();
                }, 1000);
            }
        }));
    }
    _createCheckboxColumn(gridInput) {
        const column = this._aggridColumnHelper.getLockedColumn('rowSelected', (params) => this._createRowCheckbox(params), (params) => this._simulateCheckboxClick(params));
        column.sortable = true;
        gridInput.columns.splice(0, 0, column);
        return gridInput;
    }
    _attachValueGetters(gridInput) {
        gridInput.columns.forEach(column => {
            column.valueGetter = (params) => {
                return this._handleColumnValueGetter(params);
            };
        });
        return gridInput;
    }
    _handleColumnValueGetter(params) {
        const cellValue = params.data[params.column.colDef.field];
        const notAvailableItem = { code: 'NOT_AVAILABLE', description: '-- Not Available --' };
        if (params.colDef.cellEditor === 'agRichSelectCellEditor') {
            const ruleModel = this._createRuleModel(params.data);
            const ruleUniqueId = ruleModel.getUniqueId();
            const dataListObj = params.data[this._listItemColumnName];
            this._selectorRowName = params.column.colId;
            if (this._itemsByRow[ruleUniqueId] === undefined) {
                this._itemsByRow[ruleUniqueId] = [notAvailableItem, ...deepClone(dataListObj)];
            }
            if (cellValue == undefined) {
                params.data[params.column.colId] = notAvailableItem;
            }
            else {
                // update checkbox
                if (cellValue && cellValue.code) {
                    params.data['rowSelected'] = cellValue.code !== 'NOT_AVAILABLE';
                }
            }
            params.colDef.editable = this._viewMode !== FormOperationType.View;
            params.colDef.cellEditorParams = {
                values: this._itemsByRow[ruleUniqueId],
                cellRenderer: (cellParams) => {
                    if (cellParams.value !== undefined) {
                        return cellParams.value && cellParams.value.description;
                    }
                }
            };
        }
        return cellValue && cellValue.description ? cellValue.description : cellValue;
    }
    _createRuleModel(rowData) {
        const ruleModel = new RuleModel();
        const factors = [];
        ruleModel.factors = [];
        // este seria el caso "generico". los factores vienen configurados
        if (this.definition.factorColumnNames) {
            Object.keys(rowData).forEach(prop => {
                if (this.definition.factorColumnNames.includes(prop)) {
                    factors.push({
                        ruleTypeLibraryCode: this.definition.ruleTypeLibraryCode,
                        factorTypeLibraryCode: rowData[this.definition.factorTypeColumnName],
                        valueInstanceCode: rowData[prop],
                        value: rowData[this._selectorRowName] && rowData[this._selectorRowName].code
                    });
                }
            });
        }
        else {
            // este caso es especifico. los factores son todas las columnas con un objeto y un .code
            // se podria unificar por configuracion
            Object.keys(rowData).forEach(prop => {
                // si no es la columna de flows
                if (this._selectorRowName !== prop && rowData[prop].code !== undefined) {
                    factors.push({
                        ruleTypeLibraryCode: this.definition.ruleTypeLibraryCode,
                        factorTypeLibraryCode: rowData[prop].factorTypeLibraryCode,
                        valueInstanceCode: rowData[prop].code
                    });
                }
            });
        }
        ruleModel.factors = factors;
        if (this.definition.sendOutcome) {
            if (this._selectorRowName && rowData[this._selectorRowName]) {
                ruleModel.outcomeWorkEfforts = [{ workEffortInstanceCode: rowData[this._selectorRowName].code }];
            }
        }
        return ruleModel;
    }
    _simulateCheckboxClick(params) {
        params.event.srcElement.children[0].click();
    }
    _createRowCheckbox(params) {
        const input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.disabled = true;
        return input;
    }
}
export { ɵ0 };
