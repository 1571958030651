<div class="modal-header">
    <h3 class="modal-title pull-left" [innerHtml]="title"></h3>
</div>

<div class="modal-body campaign-modal">
    <ng-container class="text-center">
        <div class="text-center">
            <span [innerHtml]="message"></span>
        </div>
        <div>
            <app-sign-pad 
                #signPadComponent    
                width="500" 
                height="150"
                weight="3" 
                color="black" 
                font-size="45" 
                font-type="Lucida Handwriting"
                text-max-length="16"></app-sign-pad>
        </div>
    </ng-container>
 </div>

<div class="modal-footer">
    <button type="button" class="btn btn-next" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary btn-fill" (click)="executeCallback()">{{confirmText}}</button>
</div>
