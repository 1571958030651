import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { JourneyRestService } from '../../rest/journey-rest/journey-rest.service';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { DashboardItem } from 'src/app/models/dashboard.models';
import { NavigationHelperService } from '../../helper/navigation-helper/navigation-helper.service';
import { DashboardHandlerService } from '../dashboard-handler/dashboard-handler.service';
import { CatalogService } from '../../behavior/catalog/catalog.service';
import { CatalogEnum } from 'src/app/enums/catalogs';
import { JourneyContext, JourneyExecution, JourneyStepsCached, Journey, JourneyStep, JourneyType, ActionResult } from 'src/app/models/journey.models';
import { KeyValueType, KeyValue } from 'src/app/types/keyvalue.type';
import { MessageService } from '../../behavior/message/message.service';
import { JourneyContextQueryStr, DetailViewQueryStr } from 'src/app/enums/query-string';
import { UtilService } from '../../behavior/util/util.service';
import { FormOperationType, FormOperationTypeShortCode } from 'src/app/models/operation.models';
import { ConfirmSubmissionPayload } from 'src/app/modals/link-change-modal/link-change-modal.component';
import { EntityHandlerService } from '../entity-handler/entity-handler.service';
import { CacheService } from 'ngx-emerios-all';
import { CacheKeyEnum } from 'src/app/enums/cachekeys';
import { deepClone } from 'src/app/functions/deepClone';
import { DetailViewAction } from 'src/app/enums/detail-view-actions';
import * as moment from 'moment-mini';
import { AuthorizationHandlerService } from '../authorization-handler/authorization-handler.service';
import { Entity } from 'src/app/models/entity.model';
import { InstanceCodeName } from 'src/app/components/entity-lister/entity-lister.component';

@Injectable({
  providedIn: 'root'
})
export class JourneyHandlerService {
  public readonly availableJourneys$: Observable<Array<Journey>>;
  public readonly journeySteps$: Observable<Array<JourneyStep>>;
  public readonly journeyNavigationFormSteps$: Observable<Array<JourneyStep>>;
  public readonly currentJourney$: Observable<Journey>;
  public readonly currentJourneyStep$: Observable<JourneyStep>;
  public readonly actionFired$: Observable<JourneyContext>;
  public readonly actionExecuted$: Observable<ActionResult>;
  public readonly journeyActivity$: Observable<string>;
  public readonly currentEntitySelected$: Observable<Entity>;
  public readonly journeyType$: Observable<JourneyType>;
  public readonly bookInstanceData$: Observable<Array<InstanceCodeName>>;

  private readonly _availableJourneys = new EventEmitter<Array<Journey>>();
  private readonly _journeySteps = new BehaviorSubject<Array<JourneyStep>>([]);
  private readonly _journeyNavigationFormSteps = new BehaviorSubject<Array<JourneyStep>>([]);
  private readonly _currentJourney = new BehaviorSubject<Journey>(undefined);
  private readonly _currentJourneyStep = new BehaviorSubject<JourneyStep>(undefined);
  private readonly _actionFired = new EventEmitter<JourneyContext>();
  private readonly _actionExecuted = new EventEmitter<ActionResult>();
  private readonly _journeyActivity = new BehaviorSubject<string>(undefined);
  private readonly _currentEntitySelected = new BehaviorSubject<Entity>(undefined);
  private readonly _journeyType = new BehaviorSubject<JourneyType>(undefined);
  private readonly _bookInstanceData = new BehaviorSubject<Array<InstanceCodeName>>(undefined);

  private _context: JourneyContext;
  private _dashboardItem: DashboardItem;
  private _dashboardItemList: Array<DashboardItem>;
  private _journeysList: Array<Journey> = [];
  private _index: number = -1;
  private _currentContext: JourneyContext;
  private _currentJourneyExecution: JourneyExecution;

  constructor(
    private _dashboardHandler: DashboardHandlerService,
    private _catalog: CatalogService,
    private _journeyRest: JourneyRestService,
    private _message: MessageService,
    private _utils: UtilService,
    private _entityHandler: EntityHandlerService,
    private _navigationHelper: NavigationHelperService,
    private _cache: CacheService,
    private _authHandler: AuthorizationHandlerService) {
    this.availableJourneys$ = this._availableJourneys.asObservable().pipe(filter(x => x != undefined));
    this.journeySteps$ = this._journeySteps.asObservable().pipe(filter(x => x != undefined));
    this.journeyNavigationFormSteps$ = this._journeyNavigationFormSteps.asObservable().pipe(filter(x => x != undefined));
    this.currentJourney$ = this._currentJourney.asObservable().pipe(filter(x => x != undefined));
    this.currentJourneyStep$ = this._currentJourneyStep.asObservable();
    this.actionFired$ = this._actionFired.asObservable();
    this.actionExecuted$ = this._actionExecuted.asObservable().pipe(filter(x => x != undefined));
    this.journeyActivity$ = this._journeyActivity.asObservable();
    this.currentEntitySelected$ = this._currentEntitySelected.asObservable();
    this.journeyType$ = this._journeyType.asObservable();
    this.bookInstanceData$ = this._bookInstanceData.asObservable()
      .pipe(filter(x => x != undefined), distinctUntilChanged());

    this._dashboardHandler.currentDashboardItem$
      .subscribe(item => this._dashboardItem = item);

    this._dashboardHandler.dashboardItemList$
      .subscribe(list => this._dashboardItemList = list);
  }

  public fireDetailViewAction(action: DetailViewAction) {
    const context: JourneyContext = deepClone<JourneyContext>(this._context);

    context.action = action;
    context.executorUserName = this._authHandler.getCurrentUsername();

    this._actionFired.next(context);
  }

  public getAvailableJourneys(flowInstanceCode?: string) {
    this._catalog.getFullCatalog(CatalogEnum.Journeys)
      .subscribe(catalog => {
        this._journeysList = catalog.items.map(x => {
          return {
            code: x['code'],
            title: x['name'],
            description: x['description'],
            isPromoteFlow: x['isPromoteFlow'],
          } as Journey
        });

        this._availableJourneys.next(this._journeysList);

        if (flowInstanceCode != undefined) {
          const journey = this._journeysList
            .find(x => x.code === flowInstanceCode);
          this._currentJourney.next(journey);
        }
      });
  }

  public getJourney(instanceCode: string) {
    this.getAvailableJourneys(instanceCode);
  }

  public getJourneySteps(instanceCode: string) {
    this._catalog.getFiltrableCatalog<JourneyStep>(CatalogEnum.JourneySteps, instanceCode)
      .subscribe(steps => {
        this._journeySteps.next(steps.items);
        this._updateCachedSteps(instanceCode, steps.items);
      });
  }

  public getJourneyNavigationSteps(flowInstanceCode: string, instanceCode: string) {
    this._catalog.getFiltrableCatalog<JourneyStep>(CatalogEnum.FlowSteps, instanceCode)
      .subscribe(steps => {
        this._journeyNavigationFormSteps.next(steps.items);
        this._updateCachedSteps(flowInstanceCode, steps.items);
      });
  }

  public getCurrentStep(executionId: string) {
    this._journeyRest.getCurrentStep(executionId)
      .subscribe(journeyExecutionStep => this._setCurrentStep(journeyExecutionStep, executionId, false));
  }

  public getApprovalFlow(flowInstanceCode: string) {
    if (!this._currentJourney.value) {
      this._catalog.getFullCatalog(CatalogEnum.ApprovalFlows)
        .subscribe(catalog => {
          const list = catalog.items.map(x => {
            return {
              code: x['code'],
              title: x['name'],
              description: x['description']
            } as Journey
          });

          const journey = list.find(x => x.code === flowInstanceCode);
          this._currentJourney.next(journey);
        });
    } else {

    }
  }

  public getApprovalFlowSteps(instanceCode: string, contextExecutionid: string) {
    this._catalog.getFiltrableCatalog<JourneyStep>(CatalogEnum.ApprovalFlowSteps, instanceCode)
      .subscribe(steps => {
        this._journeySteps.next(steps.items);
        this.getApprovalFlowCurrentStep(contextExecutionid);
      });
  }

  public getApprovalFlowCurrentStep(executionId: string) {
    this._journeyRest.getApprovalFlowNextStep(executionId)
      .subscribe(journeyExecutionStep => {
        this._setCurrentStep(journeyExecutionStep, executionId, true);
      });
  }

  private _setCurrentStep(journeyExecutionStep: JourneyExecution, executionId: string, isApprovalFlow: boolean) {
    let journeyStep: JourneyStep;

    if (isApprovalFlow) {
      journeyStep = this._journeySteps.value
        .find(x => x.sequenceId === journeyExecutionStep.sequenceId);
    } else {
      journeyStep = this._journeySteps.value
        .find(x => x.formOperationInstanceCode === journeyExecutionStep.formOperationInstanceCode);

      // Case is a Agre Step
      if (!journeyStep) {
        journeyStep = this._journeySteps.value
          .find(x => x.code === journeyExecutionStep.formOperationInstanceCode);
      }

      if (!journeyStep) {
        journeyStep = this._journeyNavigationFormSteps.value
          .find(x => x.code === journeyExecutionStep.formOperationInstanceCode);
      }
    }

    if (journeyStep) {
      if (!journeyStep.parentInstanceCode && journeyExecutionStep.parentInstanceCode) {
        journeyStep.parentInstanceCode = journeyExecutionStep.parentInstanceCode;
      }

      journeyStep.execution = journeyExecutionStep;
      journeyStep.workEffortExecutionId = executionId;
      this._currentJourneyStep.next(journeyStep);
    }
  }

  public init(flowInstanceCode: string) {
    this._journeyRest.initFlow(flowInstanceCode)
      .subscribe(executionId => {
        this._getNextStep({ flowInstanceCode, executionId })
      });
  }

  public actionExecuted(actionResult?: ActionResult) {
    this._actionExecuted.emit(actionResult);
  }

  public saveOrSubmitActionCallback(context: JourneyContext) {
    if ((this._currentContext && this._currentJourneyExecution) && this._bookInstanceData.value && this._index < this._bookInstanceData.value.length) {
      const approvalFlowStep = this._journeySteps.value.find(x => x.isApprovalFlow);
      const kv = {};

      kv[JourneyContextQueryStr.ApprovalWorkEffortInstanceCode] = approvalFlowStep && approvalFlowStep.code;
      kv[JourneyContextQueryStr.Execution] = this._currentJourneyExecution.workEffortExecutionId;
      kv[DetailViewQueryStr.ForceDetailViewChange] = 1;

      this._goToDynaf(context, this._currentJourneyExecution, kv);
    } else {
      this._journeyRest.executeStep(context)
        .subscribe(() => {
          this._getNextStep(context)
        });
    }
  }

  public setContext(context: JourneyContext) {
    this._context = context;
  }

  public setJourneyType(journey: Journey) {
    const journeyType = journey.isPromoteFlow ? JourneyType.Promote : JourneyType.Deploy;
    this._journeyType.next(journeyType);
  }

  public setBookInstanceData(bookInstanceData: Array<InstanceCodeName>) {
    this._index = 0;
    this._currentContext = undefined;
    this._bookInstanceData.next(bookInstanceData);
  }

  public getContext() {
    return this._context
  }

  public updateContext(key: JourneyContextQueryStr, value: string) {
    const kv = {};

    kv[key] = value;

    this.updateContextMultiple(kv);
  }

  public updateContextMultiple(kvs: KeyValueType<JourneyContextQueryStr>) {
    this._navigationHelper.updateQueryParams(kvs);
  }

  public linkRequestToEntity(requestCode: string) {
    const currentStep = this._currentJourneyStep.value;

    if (currentStep.dynamicFormLibraryCode === DynafEnum.Request) {
      let payload: Array<ConfirmSubmissionPayload> = [];

      this._context.entityChangeRequestCode
        .forEach(changeRequest => {
          payload.push({
            requestCode: requestCode,
            changeRequestCode: changeRequest,
            isPromoteRequest: this._context.isPromoteRequest
          } as ConfirmSubmissionPayload);
        });


      this._entityHandler.confirmLinkMultiple(payload);
    }
  }

  public buildContext(flowInstanceCode: string, workEffortExecutionId: string) {
    const kv = {};

    kv[JourneyContextQueryStr.FlowInstanceCode] = flowInstanceCode;
    kv[JourneyContextQueryStr.FlowType] = 'approval';
    kv[JourneyContextQueryStr.Execution] = workEffortExecutionId;

    this._currentJourney.next(undefined);
    this._journeySteps.next(undefined);
    this._currentJourneyStep.next(undefined);

    this._navigationHelper.updateQueryParams(kv);
  }

  public clearCurrentStep() {
    this._currentJourneyStep.next(undefined);
  }

  public journeyActivity(action: string) {
    // if (action === 'Stop') {
    //   this._index = -1;
    //   this._currentContext = undefined;
    // }

    this._journeyActivity.next(action);
  }

  public setCurrentEntitySelected(data: Entity) {
    this._currentEntitySelected.next(data);
  }

  private _updateCachedSteps(instanceCode: string, steps: Array<JourneyStep>) {
    const journeyStepsCached = this._cache.get(CacheKeyEnum.StepsByJourney) as Array<JourneyStepsCached> || [];

    if (journeyStepsCached.findIndex(x => x.code === instanceCode) === -1) {
      journeyStepsCached.push({ code: instanceCode, steps: steps });
    } else {
      journeyStepsCached.find(x => x.code === instanceCode).steps = steps;
    }

    this._cache.set(CacheKeyEnum.StepsByJourney, journeyStepsCached);
  }

  private _goToDynaf(context: JourneyContext, nextStep: JourneyExecution, extraParams: KeyValue) {
    if (!nextStep.dynamicFormLibraryCode) {
      throw 'Step configuration is wrong';
    }

    let nextDashboardItem = this._dashboardItemList
      .find(x => x.formSource === nextStep.dynamicFormLibraryCode);

    let instanceCode: string = undefined;
    let assignToPayload = {};

    // si el proximo paso coincide con la entidad seleccionada en Identiy Entity
    if (nextDashboardItem.formSource === context.entityDynaf) {
      if (context.entityInstanceCode) {
        instanceCode = context.entityInstanceCode;
      } else if (!context.entityInstanceCode && !context.entityInstanceName) {
        instanceCode = this._bookInstanceData.getValue()[this._index].instanceCode;
        const instanceName = this._bookInstanceData.getValue()[this._index].instanceName;
        this._cache.set(CacheKeyEnum.BookSelected, instanceName);
        const libraryCode = this._utils.getLibraryCodeFromInstance(instanceCode);
        nextDashboardItem = this._dashboardItemList
          .find(x => x.libraryCodes.includes(libraryCode) && x.formSource != DynafEnum.Book);
        this._index++;
      }
    } else {
      // si el proximo paso es DYNAF.REQUEST y ya hay un requestCode
      if (nextDashboardItem.formSource === DynafEnum.Request) {
        const isViewMode = !context.requestCode;

        if (isViewMode) {
          assignToPayload = this._getAutocompletedDataForRequest(context);
        } else {
          instanceCode = context.requestCode;
        }
      }
    }

    const queryParams = this._getQueryParams(nextStep, context, extraParams);

    if (!instanceCode && this._context) {
      assignToPayload['ownerPartyRoleInstanceCode'] = this._context.entityOwnerPartyRoleInstanceCode;
    }

    if (this._context && nextDashboardItem.formSource === DynafEnum.Request &&
      (queryParams[DetailViewQueryStr.FormOperationType] === FormOperationTypeShortCode.Create ||
        queryParams[DetailViewQueryStr.FormOperationType] == undefined)) {
      assignToPayload['journeyWorkEffortExecutionToken'] = this._context.executionId;
    }

    if (!instanceCode) {
      instanceCode = nextStep.metadata && nextStep.metadata.type.startsWith('DYNAF.GEN.') && nextStep.formOperationInstanceCode
    }

    this._navigationHelper.goToViewDetail({
      entity: nextDashboardItem,
      detailCode: instanceCode,
      assignToPayload: assignToPayload,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }

  private _getQueryParams(nextStep: JourneyExecution, context: JourneyContext, extraParams: KeyValue) {
    const queryParams = {};
    const operation = this._utils.getOperationFromInstance(nextStep.formOperationInstanceCode);

    queryParams[JourneyContextQueryStr.FlowInstanceCode] = context.flowInstanceCode;
    queryParams[JourneyContextQueryStr.Execution] = nextStep.workEffortExecutionId;

    Object.keys(extraParams).forEach(k => {
      queryParams[k] = extraParams[k];
    });

    if (operation === FormOperationType.Identify) {
      queryParams[DetailViewQueryStr.FormOperationType] = FormOperationTypeShortCode.Identiy;
    } else if (operation === FormOperationType.Edit) {
      queryParams[DetailViewQueryStr.FormOperationType] = FormOperationTypeShortCode.Edit;
    } else {
      queryParams[DetailViewQueryStr.FormOperationType] = undefined;
    }

    return queryParams;
  }

  private _getAutocompletedDataForRequest(context: JourneyContext) {
    const dateNow = moment().format('YYMMDD');
    const tenantCodeSource = context.tenantCodeSource;
    const tenantCodeTarget = context.tenantCodeTarget;
    const journeyName = this._currentJourney.value.title;
    const instanceCode = context.entityInstanceCode;
    const instanceName = context.entityInstanceName;
    const integrationCommand = JSON.parse(sessionStorage.getItem(CacheKeyEnum.IntegrationCommand));

    let name: string;

    if (!instanceName && !instanceCode) {
      if (this._journeyType.value === JourneyType.Deploy) {
        name = `${dateNow} - ${journeyName}`;
      } else {
        name = `${dateNow} - ${journeyName} - ${tenantCodeSource} to ${tenantCodeTarget}`;
      }
    } else {
      name = `${dateNow} - ${journeyName} - ${instanceName} (${instanceCode})`;
    }

    return {
      name: name,
      description: name,
      ownerCodeTarget: tenantCodeTarget,
      integrationCommand: integrationCommand
    };
  }

  private _getNextStep(context: JourneyContext) {
    this._journeyRest.getCurrentStep(context.executionId)
      .subscribe(step => {
        if (step) {
          const regExp = new RegExp('DYNAF.[a-zA-Z]+.BK|DYNAF.BK');

          if (regExp.exec(step.dynamicFormLibraryCode)) {
            this._currentContext = context;
            this._currentJourneyExecution = step;
          }

          const approvalFlowStep = this._journeySteps.value.find(x => x.isApprovalFlow);
          this._handleNextStepResponse(step, context, approvalFlowStep);
        } else {
          this._goToJourneyFinalizationPage(context);
        }
      });
  }

  private _handleNextStepResponse(step: JourneyExecution, context: JourneyContext, approvalFlowStep: JourneyStep) {
    let journeyStep = null;

    if (step.isApprovalFlow) {
      journeyStep = approvalFlowStep;
    } else {
      journeyStep = this._journeySteps.value
        .find(x => x.formOperationInstanceCode === step.formOperationInstanceCode);
    }

    // Case is a Agre Step
    if (!journeyStep) {
      journeyStep = this._journeySteps.value
        .find(x => x.code === step.formOperationInstanceCode);
    }

    if (!journeyStep) {
      journeyStep = this._journeyNavigationFormSteps.value
        .find(x => x.code === step.formOperationInstanceCode);
    }

    if (journeyStep) {
      if (!journeyStep.parentInstanceCode && step.parentInstanceCode) {
        journeyStep.parentInstanceCode = step.parentInstanceCode;
      }

      journeyStep.execution = step;
      journeyStep.workEffortExecutionId = step.workEffortExecutionId;

      this._currentJourneyStep.next(journeyStep);

      if (!step.isApprovalFlow) {
        const kv = {};

        kv[JourneyContextQueryStr.ApprovalWorkEffortInstanceCode] = approvalFlowStep && approvalFlowStep.code;
        kv[JourneyContextQueryStr.Execution] = step.workEffortExecutionId;

        if ((this._dashboardItem && this._dashboardItem.formSource) === journeyStep.dynamicFormLibraryCode) {
          kv[DetailViewQueryStr.ForceDetailViewChange] = 1;
        }
        
        this._goToDynaf(context, step, kv);
      }

      this.actionExecuted();
    }
  }

  private _goToJourneyFinalizationPage(context: JourneyContext) {
    this._message.showPageMessage({
      type: 'info',
      title: 'Your journey is over :)',
      fromJourney: true,
      requestCode: context.requestCode
    });
  }

}
