import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { DashboardCategory, DashboardCategoryType, DashboardItem, DashboardItemGroupType } from '../../../models/dashboard.models';
import { delay, filter } from 'rxjs/operators';
import { deepClone } from 'src/app/functions/deepClone';
import { AgGridEnum } from 'src/app/enums/aggrid-sources';
import { DynafEnum } from 'src/app/enums/dynaf-sources';
import { AuthorizationHelperService } from '../../helper/authorization-helper/authorization-helper.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardHandlerService {
  public readonly dashboardCategoryList$: Observable<Array<DashboardCategory>>;
  public readonly dashboardItemList$: Observable<Array<DashboardItem>>;
  public readonly currentDashboardItem$: Observable<DashboardItem>;
  public readonly favoriteItemUpdated$: Observable<DashboardItem>;

  private readonly _dashboardCategoryList = new BehaviorSubject<Array<DashboardCategory>>([]);
  private readonly _dashboardItemList = new BehaviorSubject<Array<DashboardItem>>([]);
  private readonly _currentDashboardItem = new BehaviorSubject<DashboardItem>(undefined);
  private readonly _favoriteItemUpdated = new EventEmitter<DashboardItem>();

  private _flatDashboardItems: Array<DashboardItem>;
  private _dashboardCategoriesListMock: Array<DashboardCategory> = [];

  constructor(private _authHelper: AuthorizationHelperService) {
    this.dashboardCategoryList$ = this._dashboardCategoryList.asObservable();
    this.dashboardItemList$ = this._dashboardItemList.asObservable();
    this.currentDashboardItem$ = this._currentDashboardItem.asObservable().pipe(filter(x => x !== undefined));
    this.favoriteItemUpdated$ = this._favoriteItemUpdated.asObservable();
    this.initDasboardItemList();
  }

  public initDasboardItemList() {
      this._dashboardCategoriesListMock.length = 0;
      this._setJourneyItems();
      this._setEntityItems();
      this._setChangeManagementItems();
      this._setCheckAndBalanceItems();
      this._setFavoritesItems();
  }

  public getDashboardItemList() {
    this._getDashboardItemListAsObservable()
      .subscribe((items => {
        this._setDashboardItemList(items);
      }));
  }

  public setCurrrentDashboardItemByCode(code: string) {
    const dashboardItems = this._flatDashboardItems;

    if (!dashboardItems) {
      this._getDashboardItemListAsObservable()
        .subscribe((items => {
          this._setDashboardItemList(items);
          this._deliverDashboardItemByCode(code);
        }));
    } else {
      this._deliverDashboardItemByCode(code);
    }

  }

  public updateFavorite(item: DashboardItem) {
    this._favoriteItemUpdated.emit(item);
  }

  public toggleFavoriteCardDefault(items: DashboardCategory[]) {
    const clonedItems = deepClone<Array<DashboardCategory>>(items);

    // let item = clonedItems
    //   .find((x: DashboardCategory) => x.code === 'FAVORITE').items[0];

    // item.favorite = !item.favorite;

    this._dashboardCategoryList.next(clonedItems);
  }

  public getCardIconClass(viewCode: string) {
    return {
      'campaign': 'fas fa-briefcase',
      'organization': 'fas fa-building',
      'person': 'fas fa-user-alt',
      'tenant': 'fas fa-building',
      'question': 'fas fa-question',
      'product': 'fas fa-shopping-cart',
      'product-type': 'fas fa-bookmark',
      'feature': 'fas fa-tasks',
      'geography': 'fas fa-map-marked-alt',
      'flow': 'fas fa-project-diagram',
      'flow-stage': 'fas fa-layer-group',
      'flow-step': 'fas fa-shoe-prints',
      'change-request': 'fas fa-exchange-alt',
      'new-request': 'fas fa-plus',
      'submitted-request': 'fas fa-list-alt',
      'processed-request': 'fad fa-tasks',
      'request': 'fas fa-file-signature',
      'pending-request': 'fas fa-bell',
      'form-designer': 'fas fa-file-alt',
      'subform-designer': 'fas fa-file-alt',
      'form': 'fas fa-file-alt',
      'container': 'fas fa-file-invoice',
      'replacer': 'fas fa-spell-check',
      'contract': 'fas fa-file-contract',
      'asset': 'fas fa-images',
      'audio': 'fas fa-file-audio',
      'glossary': 'fas fa-text',
      'event': 'fas fa-calendar-week',
      'product-book': 'fas fa-book',
      'geography-book': 'fas fa-book',
      'relationship': 'fas fa-network-wired',
      'person-book': 'fas fa-book',
      'campaign-book': 'fas fa-book',
      'organization-book': 'fas fa-book',
      'product-type-book': 'fas fa-book',
      'flow-book': 'fas fa-book',
      'container-book': 'fas fa-book',
      'email-template-book': 'fas fa-book',
    }[viewCode] || '';
  }

  public getCardIconColor(groupType: DashboardItemGroupType, viewCode: string) {
    if (viewCode === 'new-request') { return 'new-request'; }
    return {
      'PARTY': 'party-color',
      'PRODUCT': 'product-color',
      'CAMPAIGN': 'campaign-color',
      'CHANNEL': 'channel-color',
      'TERRITORY': 'territory-color',
      'DC': 'data-component-color',
      'FAVORITE': 'favorite-color'
    }[groupType] || 'change-management-color';
  }

  private _setJourneyItems() {
    this._dashboardCategoriesListMock.push(
      {
        name: 'Journeys',
        code: 'JOURNEY',
        order: 0,
        visible: this._authHelper.isAutorized("Journeys", "View")
      }
    );
  }

  private _setEntityItems() {
    const parties: Array<DashboardItem> = [];
    const relationship: Array<DashboardItem> = [];
    const campaign: Array<DashboardItem> = [];
    const territory: Array<DashboardItem> = [];
    const products: Array<DashboardItem> = [];
    const channel: Array<DashboardItem> = [];
    const dataComponents: Array<DashboardItem> = [];
    const others: Array<DashboardItem> = [];
    const platform: Array<DashboardItem> = [];

    if(this._authHelper.isAutorized("Parties", "View"))
      this._setEntitiesParties(parties);
    if(this._authHelper.isAutorized("Relationships", "View"))
      this._setEntitiesRelationships(relationship);
    if(this._authHelper.isAutorized("Campaign", "View"))
      this._setEntitiesCampaign(campaign);
    if(this._authHelper.isAutorized("Territory", "View"))
      this._setEntitiesTerritories(territory);
    if(this._authHelper.isAutorized("Products", "View"))
      this._setEntitiesProducts(products);
    if(this._authHelper.isAutorized("Channel", "View"))
      this._setEntitiesChannel(channel);
    if(this._authHelper.isAutorized("Data Components", "View"))
      this._setEntitiesDataComponents(dataComponents);
    if(this._authHelper.isAutorized("Others", "View"))
      this._setEntitiesOthers(others);
    if(this._authHelper.isAutorized("Platform", "View"))
      this._setEntitiesPlatform(platform)

    this._dashboardCategoriesListMock.push(
      {
        name: 'Entities',
        code: 'ENTITY',
        order: 1,
        visible: this._authHelper.isAutorized("Entities", "View"),
        items: [
          ...parties, ...relationship, ...campaign, ...territory,
          ...products, ...channel, ...dataComponents, ...others,
          ...platform
        ]
      }
    );
  }

  private _setEntitiesOthers(others: DashboardItem[]) {
    others.push({
      gridSource: undefined,
      formSource: DynafEnum.IdentifyInstance,
      viewCode: 'identify',
      name: 'Identify Instance',
      desc: '',
      libraryCodes: [],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'OTHER' as DashboardItemGroupType,
      visible: false
    });
    others.push({
      gridSource: undefined,
      formSource: DynafEnum.IdentifyPromoteBook,
      viewCode: 'identify',
      name: 'Identify Promote Book',
      desc: '',
      libraryCodes: [],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'OTHER' as DashboardItemGroupType,
      visible: false
    });
    others.push({
      gridSource: AgGridEnum.Flow,
      formSource: DynafEnum.AgreFlow,
      viewCode: 'flow-agre',
      name: 'AGRE Flow',
      desc: '',
      libraryCodes: ['FLW.ECST_ORD_CRT', 'FLW.ECST_ORD_CLS', 'FLW.ECST_ORD_INIT'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'OTHER' as DashboardItemGroupType,
      visible: false
    });
    others.push({
      gridSource: AgGridEnum.BackgroundJobs,
      formSource: DynafEnum.BackgroundJobs,
      viewCode: 'background-job',
      name: 'Background Job',
      desc: '',
      libraryCodes: [],
      categoryCode: 'BACKGROUND' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'OTHER' as DashboardItemGroupType,
      visible: false
    });
    others.push({
      gridSource: undefined,
      formSource: DynafEnum.TenantSelection,
      viewCode: 'tenant-selection',
      name: 'Tenant Selection',
      desc: '',
      libraryCodes: [],
      categoryCode: 'TENANT_SELECTION' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'OTHER' as DashboardItemGroupType,
      visible: false
    });
    others.push({
      gridSource: undefined,
      formSource: DynafEnum.IntegrationCommandSelection,
      viewCode: 'integration-command',
      name: 'Select Integration Command',
      desc: '',
      libraryCodes: [],
      categoryCode: 'INTEGRATION_COMMAND_SELECTION' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'OTHER' as DashboardItemGroupType,
      visible: false
    });
  }

  private _setEntitiesTerritories(territory: DashboardItem[]) {
    territory.push({
      gridSource: AgGridEnum.GeographyGroupingBook,
      formSource: DynafEnum.GeographyGroupingBook,
      viewCode: 'geography-book',
      name: 'Geography Grouping Book',
      desc: 'A grouping of GEOGRAPHY GROUPINGs used to facilitate their management and updates.',
      libraryCodes: ['GEO.GRP.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'TERRITORY' as DashboardItemGroupType,
      visible: true
    });
    territory.push({
      gridSource: AgGridEnum.GeographyGrouping,
      formSource: DynafEnum.GeographyGrouping,
      viewCode: 'geography',
      name: 'Geography Grouping',
      desc: 'Any type of encompassing area such as a city, state, country, sales territory, or service territory.',
      libraryCodes: ['GEO.GRP'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'TERRITORY' as DashboardItemGroupType,
      visible: true
    });
  }

  private _setEntitiesRelationships(relationship: DashboardItem[]) {
    relationship.push({
      gridSource: AgGridEnum.InstanceRelationship,
      formSource: DynafEnum.InstanceRelationship,
      viewCode: 'relationship',
      name: 'Relationship',
      desc: 'A connection between two instances of the same or different entities.',
      libraryCodes: ['P.ORG'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'RELATIONSHIP' as DashboardItemGroupType,
      visible: true
    });
  }

  private _setEntitiesCampaign(campaign: DashboardItem[]) {
    campaign.push({
      gridSource: AgGridEnum.CampaignBook,
      formSource: DynafEnum.CampaignBook,
      viewCode: 'campaign-book',
      name: 'Campaign Book',
      desc: 'A grouping of CAMPAIGNs used to facilitate their management and updates.',
      libraryCodes: ['CMP.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CAMPAIGN' as DashboardItemGroupType,
      visible: true
    });
    campaign.push({
      gridSource: AgGridEnum.Campaign,
      formSource: DynafEnum.Campaign,
      viewCode: 'campaign',
      name: 'Campaign',
      desc: 'Represents a Product offering within a given timeframe, Geography, Channel, and other factors that indicate its availability.',
      libraryCodes: ['CMP.PRD', 'CMP.TPV', 'CMP.ECST_ORD_CRT', 'CMP.ECST_ORD_CLS', 'CMP.ECST_ORD_INIT'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CAMPAIGN' as DashboardItemGroupType,
      visible: true
    });
  }

  private _setEntitiesDataComponents(dataComponents: DashboardItem[]) {
    dataComponents.push({
      gridSource: AgGridEnum.FormView,
      formSource: DynafEnum.FormView,
      viewCode: 'form-designer',
      name: 'Form View',
      desc: 'Is a visual representation of a collection of Data Components.',
      libraryCodes: ['DYNAF'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.SubformView,
      formSource: DynafEnum.SubformView,
      viewCode: 'subform-designer',
      name: 'Subform View',
      desc: 'A reusable section of a form that contains data components for data capture or visualization.',
      libraryCodes: ['DYNAS'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.GridView,
      formSource: DynafEnum.GridView,
      viewCode: 'grid-designer',
      name: 'Grid View',
      desc: 'Is a visual representation of a collection of grid columns.',
      libraryCodes: ['DYNAG'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.Question,
      formSource: DynafEnum.Question,
      viewCode: 'question',
      name: 'Question',
      desc: 'Data capture field which is visualized according to its data type format.',
      libraryCodes: ['QST', 'QST.YES_NO', 'QST.NO_YES', 'QST.TXT', 'QST.MC_PM', 'QST.MC_TM', 'QST.SC', 'QST.DOB', 'QST.RANGE'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.Replacer,
      formSource: DynafEnum.Replacer,
      viewCode: 'replacer',
      name: 'Replacer',
      desc: 'A placeholder that is used within a dynamic question or dynamic form and is dynamically replaced by the metadata included in the context of the order.',
      libraryCodes: ['RPL.LANG', 'RPL.NUM', 'RPL.STR', 'RPL.DATE'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.FileAsset,
      formSource: DynafEnum.FileAsset,
      viewCode: 'asset',
      overrideViewCode: 'file',
      name: 'Asset',
      desc: 'An image, logo, material, or any other type of file which is required as part of the configuration of an Instance.',
      libraryCodes: ['BIN.AST'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.FileDocument,
      formSource: DynafEnum.FileDocument,
      viewCode: 'contract',
      overrideViewCode: 'file',
      name: 'Contract Template',
      desc: 'A PDF form used as a pre-formatted engagement document that contains dynamic contract replacers completed by the platform with contextual information at the moment of its presentation.',
      libraryCodes: ['BIN.DOC'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.FileAudio,
      formSource: DynafEnum.FileAudio,
      viewCode: 'audio',
      overrideViewCode: 'file',
      name: 'Audio',
      desc: 'An Audio File which is required as part of the configuration of a question for Automated Agent channel.',
      libraryCodes: ['BIN.AUD'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: undefined,
      formSource: DynafEnum.File,
      viewCode: 'file-upload',
      name: 'File',
      desc: '',
      libraryCodes: [],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: false
    });
    dataComponents.push({
      gridSource: AgGridEnum.GlossaryTerm,
      formSource: DynafEnum.GlossaryTerm,
      relatedGridSource: [AgGridEnum.GlossaryTermHierarchy],
      viewCode: 'glossary',
      overrideViewCode: 'term',
      name: 'Glossary Term',
      desc: 'A definition used in a particular domain of knowledge in order to standardize the communication and facilitate access to comprehensively categorized bodies of information.',
      libraryCodes: [],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.Event,
      formSource: DynafEnum.Event,
      relatedGridSource: [AgGridEnum.GlossaryTermHierarchy],
      viewCode: 'event',
      name: 'Event',
      desc: 'An EVENT is a definable occurrence of a significant happening in a scenario which must be registered and/or act upon.',
      libraryCodes: ['EVT'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.CatalogBook,
      formSource: DynafEnum.CatalogBook,
      viewCode: 'catalog-book',
      name: 'Catalog Book',
      desc: 'A grouping of Catalogs used to facilitate their management and updates.',
      libraryCodes: ['CTL.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.FileBook,
      formSource: DynafEnum.FileBook,
      viewCode: 'file-book',
      name: 'File Book',
      desc: 'A grouping of Files used to facilitate their management and updates.',
      libraryCodes: ['BIN.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.EmailTemplateBook,
      formSource: DynafEnum.EmailTemplateBook,
      viewCode: 'email-template-book',
      name: 'Email Template Book',
      desc: 'A grouping of EMAIL TEMPLATEs used to facilitate their management and updates.',
      libraryCodes: ['DC.EMAIL_TPL.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
    dataComponents.push({
      gridSource: AgGridEnum.EmailTemplate,
      formSource: DynafEnum.EmailTemplate,
      viewCode: 'emailTemplate',
      name: 'Email Template',
      desc: 'An Email Template used in Notifications.',
      libraryCodes: ['DC.EMAIL_TPL'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'DC' as DashboardItemGroupType,
      visible: true
    });
  }

  private _setEntitiesChannel(channel: DashboardItem[]) {
    channel.push({
      gridSource: AgGridEnum.FlowBook,
      formSource: DynafEnum.FlowBook,
      viewCode: 'flow-book',
      name: 'Flow Book',
      desc: 'A grouping of Flows used to facilitate their management and updates.',
      libraryCodes: ['FLW.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CHANNEL' as DashboardItemGroupType,
      visible: true
    });

    channel.push({
      gridSource: AgGridEnum.StepContainerBook,
      formSource: DynafEnum.StepContainerBook,
      viewCode: 'container-book',
      name: 'Step Container Book',
      desc: 'A grouping of Containers used to facilitate their management and updates.',
      libraryCodes: ['STP.CNT.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CHANNEL' as DashboardItemGroupType,
      visible: true
    });

    channel.push({
      gridSource: AgGridEnum.Flow,
      formSource: DynafEnum.Flow,
      viewCode: 'flow',
      overrideViewCode: 'workeffort/flow',
      addTypeToUrl: true,
      typeField: 'workEffortTypeCode',
      name: 'Flow',
      desc: 'A Flow is a journey represented as a sequence of Stages and Steps to be executed as part of a Campaign.',
      libraryCodes: ['FLW', 'FLW.REQ', 'FLW.TPV', 'FLW.JRN', 'FLW.ORD', 'FLW.ECST_ORD_CRT', 'FLW.ECST_ORD_CLS', 'FLW.ECST_ORD_INIT'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CHANNEL' as DashboardItemGroupType,
      visible: true
    });
    channel.push({
      gridSource: AgGridEnum.Stage,
      formSource: DynafEnum.Stage,
      viewCode: 'flow-stage',
      overrideViewCode: 'workeffort/stage',
      addTypeToUrl: true,
      typeField: 'workEffortTypeCode',
      name: 'Stage',
      desc: 'A sequence of Steps that guides a Person or Organization through a process and facilitate reporting and tracking of events.',
      libraryCodes: [
        'STG',
        'STG.GRP',
        'STG.APPROVAL',
        'STG.TPV',
        'STG.JRN',
        'STG.TPVINI',
        'STG.TPVCON',
        'STG.CSTINT',
        'STG.SIGPRF',
        'STG.ORDADV',
        'STG.ORDBIL',
        'STG.ORD_INIT',
        'STG.ORD_PREQ',
        'STG.ORD_PRD-SEL',
        'STG.ORD_DTL',
        'STG.ORD_CTR-SEL',
        'STG.ORD_CNF',
        'STG.ORD_CLS_AGT-DEV',
        'STG.ORD_AUTH',
        'STG.ORD_AGRE',
        'STG.ORD_PRF-CPT',
        'STG.ORD_STS'
      ],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CHANNEL' as DashboardItemGroupType,
      visible: true
    });
    channel.push({
      gridSource: AgGridEnum.Step,
      formSource: DynafEnum.Step,
      viewCode: 'flow-step',
      overrideViewCode: 'workeffort/step',
      addTypeToUrl: true,
      typeField: 'workEffortTypeCode',
      name: 'Step',
      desc: 'A configurable and traceable task that requires an action from a Party or System.',
      libraryCodes: [
        'STP',
        'STP.APPROVAL',
        'STP.DEPLOY',
        'STP.GEN_AGR_DOC',
        'STP.TPV',
        'STP.TPV_NTF',
        'STP.TPV_RTY',
        'STP.FRM.JRN',
        'STP.NAV.JRN',
        'STP.REQVAL',
        'STP.SELCMP',
        'STP.CHDUP',
        'STP.INICAL',
        'STP.RIGORD',
        'STP.SNDREQ',
        'STP.CSTID',
        'STP.CSTCNT',
        'STP.RECSIG',
        'STP.AUDPRF',
        'STP.FINTPV',
        'STP.AGTFDB',
        'STP.DATPKG',
        'STP.WLCKIT',
        'STP.ORDBIL',
        'STP.AUTH_USR',
        'STP.LKP_USR',
        'STP.SEL_SC',
        'STP.SEL_MENU',
        'STP.VAL_ZC',
        'STP.VAL_SRV-ADD',
        'STP.SEL_DISP',
        'STP.PST_PTC',
        'STP.SEL_UTL',
        'STP.SEL_PLAN',
        'STP.VAL_CST-ACC',
        'STP.VAL_CST-PI',
        'STP.SEL_CTC-SIG',
        'STP.SEL_VER-MTD',
        'STP.VER_ORD-INF',
        'STP.SIGN_ORD',
        'STP.SBT_ORD',
        'STP.STR_ORD',
        'STP.PST_PHT-RQM',
        'STP.REQ_PHT-RQM',
        'STP.CPT_PHT-RQM',
        'STP.RVW_PHT-RQM',
        'STP.REQ_ADD-PHT',
        'STP.CNF_PHT-RQM',
        'STP.SBT_PHT-RQM',
        'STP.PST_SBT-CMP',
        'STP.PST_CTR',
        'STP.PST_ORD-STS',
        'STP.REQ_SRY',
        'STP.NTF_SMS',
        'STP.NTF_EML',
        'STP.AUTH_CST',
        'STP.STR_SIGN',
        'STP.CNF_CTR',
        'STP.PST_CLS-NO-ETPV',
        'STP.PST_CLS-ETPV',
        'STP.PROM',
        'STP.SCHEDULER'
      ],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CHANNEL' as DashboardItemGroupType,
      visible: true
    });
    channel.push({
      gridSource: AgGridEnum.StepForm,
      formSource: DynafEnum.StepForm,
      viewCode: 'form',
      overrideViewCode: 'workeffort/step',
      addTypeToUrl: true,
      typeField: 'workEffortTypeCode',
      name: 'Form',
      desc: 'A two dimensional visual representation of information.',
      libraryCodes: ['STP.FRM.TPV', 'STP.FRM.DOC'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CHANNEL' as DashboardItemGroupType,
      visible: true
    });
    channel.push({
      gridSource: AgGridEnum.StepContainer,
      formSource: DynafEnum.StepContainer,
      viewCode: 'container',
      overrideViewCode: 'workeffort/step',
      addTypeToUrl: true,
      typeField: 'workEffortTypeCode',
      name: 'Container',
      desc: 'A reusable section of a form that contains Data Components for data capture and visualization.',
      libraryCodes: ['STP.CNT.ORD', 'STP.CNT.PRD', 'STP.CNT.DOC', 'STP.CNT.SL_SPC', 'STP.CNT.SL_STM'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'CHANNEL' as DashboardItemGroupType,
      visible: true
    });
  }

  private _setEntitiesProducts(products: DashboardItem[]) {
    products.push({
      gridSource: AgGridEnum.ProductBook,
      formSource: DynafEnum.ProductBook,
      viewCode: 'product-book',
      name: 'Product Book',
      desc: 'A grouping of PRODUCTs used to facilitate their management and updates.',
      libraryCodes: ['PRD.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PRODUCT' as DashboardItemGroupType,
      visible: true
    });
    products.push({
      gridSource: AgGridEnum.ProductTypeBook,
      formSource: DynafEnum.ProductTypeBook,
      viewCode: 'product-type-book',
      name: 'Product Type Book',
      desc: 'A grouping of Product Types used to facilitate their management and updates.',
      libraryCodes: ['CTG.PDT.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PRODUCT' as DashboardItemGroupType,
      visible: true
    });

    products.push({
      gridSource: AgGridEnum.Product,
      formSource: DynafEnum.Product,
      viewCode: 'product',
      name: 'Product',
      desc: 'Is a service or good commercialized by a Tenant.',
      libraryCodes: ['PRD'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PRODUCT' as DashboardItemGroupType,
      visible: true
    });
    products.push({
      gridSource: AgGridEnum.ProductType,
      formSource: DynafEnum.ProductType,
      viewCode: 'product-type',
      name: 'Product Type',
      desc: 'Is a specification of a class of Products which has a particular set of Features defined.',
      libraryCodes: ['CTG.PDT'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PRODUCT' as DashboardItemGroupType,
      visible: true
    });
    products.push({
      gridSource: AgGridEnum.Feature,
      formSource: DynafEnum.Feature,
      viewCode: 'feature',
      name: 'Product Feature',
      desc: 'Field that is specific for a Product Type and extends the properties of the Product.',
      libraryCodes: ['FTR.NUM', 'FTR.BIT', 'FTR.LANG', 'FTR.STR', 'FTR.DATE', 'FTR.BIN_AST', 'FTR.BIN_DOC'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PRODUCT' as DashboardItemGroupType,
      visible: true
    });
    products.push({
      gridSource: AgGridEnum.ProductBook,
      formSource: DynafEnum.BookUpload,
      viewCode: '',
      name: 'Book Upload',
      desc: '',
      libraryCodes: ['UPLD.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PRODUCT' as DashboardItemGroupType,
      visible: false
    });
  }

  private _setEntitiesParties(parties: DashboardItem[]) {
    parties.push({
      gridSource: AgGridEnum.Book,
      formSource: DynafEnum.Book,
      viewCode: 'book',
      name: 'Book',
      desc: '',
      libraryCodes: ['GEO.BK', 'CMP.BK', 'FLW.TPV.BK', 'STP.CNT.BK', 'PRD.BK', 'CTG.PDT.BK', 'P.ORG.BK', 'P.PER.BK', 'CTL.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'OTHER' as DashboardItemGroupType,
      visible: false
    });
    parties.push({
      gridSource: AgGridEnum.OrganizationBook,
      formSource: DynafEnum.OrganizationBook,
      viewCode: 'organization-book',
      name: 'Organization Book',
      desc: 'A grouping of ORGANIZATIONs used to facilitate their management and updates.',
      libraryCodes: ['P.ORG.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PARTY' as DashboardItemGroupType,
      visible: true
    });
    parties.push({
      gridSource: AgGridEnum.PersonBook,
      formSource: DynafEnum.PersonBook,
      viewCode: 'person-book',
      name: 'Person Book',
      desc: 'A grouping of PERSONs used to facilitate their management and updates.',
      libraryCodes: ['P.PER.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PARTY' as DashboardItemGroupType,
      visible: true
    });
    parties.push({
      gridSource: AgGridEnum.Organization,
      formSource: DynafEnum.Organization,
      viewCode: 'organization',
      name: 'Organization',
      desc: 'Is a Party that represents a group or structure that can participate as an actor in the system.',
      libraryCodes: ['P.ORG'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PARTY' as DashboardItemGroupType,
      visible: true
    });
    parties.push({
      gridSource: AgGridEnum.Person,
      formSource: DynafEnum.Person,
      viewCode: 'person',
      name: 'Person',
      desc: 'Is a Party that represents a human that can participate as an actor in the system, such as: User, Manager, Agent or Customer.',
      libraryCodes: ['P.PER'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PARTY' as DashboardItemGroupType,
      visible: true
    });
    parties.push({
      gridSource: AgGridEnum.Tenant,
      formSource: DynafEnum.Organization,
      formSourceAlternative: DynafEnum.Tenant,
      viewCode: 'tenant',
      name: 'Tenant',
      desc: 'An ORGANIZATION ROLE for which the PLATFORM PROVIDER assists with SERVICES and/or PRODUCTs in order to fulfill the client\'s journeys.',
      libraryCodes: ['P.ORG'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PARTY' as DashboardItemGroupType,
      visible: true
    });
  }

  private _setEntitiesPlatform(platform: DashboardItem[]) {
    platform.push({
      gridSource: AgGridEnum.ApplicationBook,
      formSource: DynafEnum.ApplicationBook,
      viewCode: 'application-book',
      name: 'Application Book',
      desc: 'A grouping of APPLICATIONs used to facilitate their management and updates.',
      libraryCodes: ['APP.BK'],
      categoryCode: 'ENTITY' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PLATFORM' as DashboardItemGroupType,
      visible: true
    });
  }

  private _setChangeManagementItems() {
    this._dashboardCategoriesListMock.push({
      name: 'Change Management',
      code: 'REQUEST',
      order: 2,
      visible: this._authHelper.isAutorized("Change Management", "View"),
      items: [
        // {
        //   gridSource: AgGridEnum.ChangeRequest,
        //   formSource: DynafEnum.ChangeRequest,
        //   viewCode: 'change-request',
        //   name: 'Changes',
        //   desc: 'Represents a Change in an instance (creation, update or deletion).',
        //   libraryCodes: ['CHG'],
        //   categoryCode: 'REQUEST' as DashboardCategoryType,
        //   presetView: 'default',
        //   visible: true
        // },
        {
          gridSource: undefined,
          formSource: undefined,
          viewCode: 'new-request',
          name: 'New Request',
          desc: 'Start a change request and approval process.',
          libraryCodes: ['NREQ'],
          categoryCode: 'REQUEST' as DashboardCategoryType,
          presetView: 'default',
          visible: true
        },
        {
          gridSource: AgGridEnum.SubmittedRequest,
          formSource: DynafEnum.SubmittedRequest,
          viewCode: 'submitted-request',
          name: 'My Submitted Requests',
          desc: 'Review requests you created.',
          libraryCodes: ['SREQ'],
          categoryCode: 'REQUEST' as DashboardCategoryType,
          presetView: 'default',
          visible: true
        },
        {
          gridSource: AgGridEnum.ProcessedRequest,
          formSource: DynafEnum.ProcessedRequest,
          viewCode: 'processed-request',
          name: 'My Approved/Rejected Requests',
          desc: 'Review requests you have already approved or rejected.',
          libraryCodes: ['PROREQ'],
          categoryCode: 'REQUEST' as DashboardCategoryType,
          presetView: 'default',
          visible: true
        },
        {
          gridSource: AgGridEnum.PendingRequest,
          formSource: DynafEnum.PendingRequest,
          viewCode: 'pending-request',
          name: 'My Pending Approval Requests',
          desc: 'Review requests that are pending for your approval.',
          libraryCodes: ['PREQ'],
          categoryCode: 'REQUEST' as DashboardCategoryType,
          presetView: 'default',
          visible: true
        },
        {
          gridSource: AgGridEnum.Request,
          formSource: DynafEnum.Request,
          viewCode: 'request',
          name: 'All Requests',
          desc: 'Review all submitted requests.',
          libraryCodes: ['REQ'],
          categoryCode: 'REQUEST' as DashboardCategoryType,
          presetView: 'default',
          visible: true
        }
      ]
    });
  }

  private _setCheckAndBalanceProducts(products: DashboardItem[]) {
    products.push({
      gridSource: AgGridEnum.ProductCheckAndBalance,
      viewCode: 'product-without-books',
      name: 'Related Product Books',
      desc: 'Review all products not related with books',
      libraryCodes: ['PRD'],
      categoryCode: 'CHECK_AND_BALANCE' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PRODUCT' as DashboardItemGroupType,
      visible: true
    });
    products.push({
      gridSource: AgGridEnum.ProductCheckAndBalance,
      viewCode: 'product-without-containers',
      name: 'Related Product Containers',
      desc: 'Review all products not related with containers',
      libraryCodes: ['PRD'],
      categoryCode: 'CHECK_AND_BALANCE' as DashboardCategoryType,
      presetView: 'default',
      groupType: 'PRODUCT' as DashboardItemGroupType,
      visible: true
    });
  }

  private _setCheckAndBalanceItems() {
    const products: Array<DashboardItem> = [];

    this._setCheckAndBalanceProducts(products);

    this._dashboardCategoriesListMock.push(
      {
        name: 'Check And Balance',
        code: 'CHECK_AND_BALANCE',
        order: 3,
        visible: this._authHelper.isAutorized("Check and Balance", "View"),
        items: [...products]
      }
    );
  }

  private _setFavoritesItems() {
    this._dashboardCategoriesListMock.push({
      name: 'Favorites',
      code: 'FAVORITE',
      order: 3,
      visible: this._authHelper.isAutorized("Favorites", "View"),
      items: [{
        gridSource: undefined,
        formSource: undefined,
        viewCode: 'add-favorite',
        name: 'Add Favorites!',
        desc: 'Click on your favorite card\'s pin to show them here',
        libraryCodes: [],
        categoryCode: 'FAVORITE' as DashboardCategoryType,
        presetView: '',
        visible: true,
        favorite: true,
        groupType: 'FAVORITE' as DashboardItemGroupType,
        cannotMarkAsFavorite: true
      }]
    });
  }

  private _deliverDashboardItemByCode(code: string) {
    const dashboardItem = this._flatDashboardItems.find(e => e.viewCode == code);

    if (dashboardItem) {
      this._currentDashboardItem.next(Object.assign({}, dashboardItem));
    }
  }

  private _getDashboardItemListAsObservable() {
    return of(this._dashboardCategoriesListMock)
      .pipe(delay(1500));
  }

  private _setDashboardItemList(items: DashboardCategory[]) {
    this._flatDashboardItems = [].concat(...items
      .filter(x => x.items != undefined)
      .map(x => x.items));

    this.toggleFavoriteCardDefault(items);
    this._dashboardItemList.next(this._flatDashboardItems);
  }

}
