import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EntityCatalogItem } from 'src/app/models/catalog.models';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RuleRestService {

  constructor(private _rest: RestService) { }

  public getAvailabilitySelectorByType(type: string, search: string, minSearchLength: number = 3):
    Observable<{ type: string, items: Array<EntityCatalogItem> }> {
    const headers = new HttpHeaders()
      .append('restCache', 'true');

    return this._rest.get({
      baseUrl: environment.campaignBuilderUrl,
      headers: headers,
      endpoint: `api/v1/rule/catalog-typeahead/${type}`,
      params: {
        minSearchLength: minSearchLength,
        search: search
      }
    }).pipe(map(result => { return { type: type, items: result } }));
  }
}
