import { Injectable } from '@angular/core';
import { RestService } from 'ngx-emerios-all';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JourneyContext, JourneyExecution } from 'src/app/models/journey.models';

@Injectable({
  providedIn: 'root'
})
export class JourneyRestService {

  constructor(private _rest: RestService) { }

  public initFlow(flowInstanceCode: string): Observable<string> {
    return this._rest.post<string>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/journey/init`,
      body: { flowInstanceCode: flowInstanceCode }
    });
  }

  public getCurrentStep(workeffortExecutionId: string): Observable<JourneyExecution> {
    return this._rest.get<JourneyExecution>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/journey/next/${workeffortExecutionId}`,
    });
  }

  public getApprovalFlowNextStep(workeffortExecutionId: string): Observable<JourneyExecution> {
    return this._rest.get<JourneyExecution>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/request/next/${workeffortExecutionId}`,
    });
  }

  public executeStep(context: JourneyContext): Observable<JourneyExecution> {
    return this._rest.post<JourneyExecution>({
      baseUrl: environment.campaignBuilderUrl,
      endpoint: `api/v1/workeffort-execution/journey/execute-step`,
      body: {
        flowInstanceCode: context.flowInstanceCode,
        entityInstanceCode: context.entityInstanceCode,
        workEffortExecutionId: context.executionId,
        action: context.ruleAction,
        executorUserName: context.executorUserName
      }
    });
  }
}
