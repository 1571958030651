import { BsModalService } from 'ngx-bootstrap';
import { GenericModalComponent } from 'src/app/modals/generic-modal/generic-modal.component';
import * as i0 from "@angular/core";
import * as i1 from "ngx-bootstrap/modal";
export class ModalService {
    constructor(_modalService) {
        this._modalService = _modalService;
    }
    showGeneric(params) {
        const okButton = {
            text: (params && params.okButton && params.okButton.text) ? params.okButton.text : 'NAVIGATION.BTN.BTN_OK',
            callback: (params && params.okButton && params.okButton.callback) ? params.okButton.callback : undefined
        };
        const cancelButton = params.cancelButton ? {
            text: (params && params.cancelButton && params.cancelButton.text) ? params.cancelButton.text : 'NAVIGATION.BTN.BTN_CLOSE',
            callback: (params && params.cancelButton && params.cancelButton.callback) ? params.cancelButton.callback : undefined
        } : undefined;
        const initialState = {
            title: params.title,
            message: params.message,
            size: params.size || 'md',
            ok: okButton,
            cancel: cancelButton
        };
        this._showModal(GenericModalComponent, initialState);
    }
    /**
    *
    * @param modalComponent Is component that will be place inside the modal.
    * @param params Is the modal configuration, `title` and `content` correspond to the
    * public properties of the `GenericModalComponent` component.
    */
    showCustom(modelComponent, params) {
        this._showModal(modelComponent, params);
    }
    close() {
        for (let i = 1; i <= this._modalService.getModalsCount(); i++) {
            this._modalService.hide(i);
        }
    }
    /**
     *
     * @param modalComponent Is the component that will be place inside the modal.
     * @param params Is the modal configuration, `title` and `content` correspond to the
     * public properties of the `GenericModalComponent` component.
     * @param limit Is the max number of modal that can be opened.
     */
    _showModal(modalComponent, params, limit = 1) {
        if (this._modalService.getModalsCount() <= limit) {
            this.modal = this._modalService.show(modalComponent, {
                initialState: params,
                backdrop: true,
                ignoreBackdropClick: true,
                keyboard: false,
                class: `modal-${params && params.size ? params.size : 'md'}`
            });
        }
    }
}
ModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.ɵɵinject(i1.BsModalService)); }, token: ModalService, providedIn: "root" });
