import { RestService } from 'ngx-emerios-all';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "ngx-emerios-all";
export class BackgroundTaskRestService {
    constructor(_rest) {
        this._rest = _rest;
    }
    getStatus(jobExecutionUid) {
        const headers = new HttpHeaders()
            .append('restCache', 'false');
        return this._rest.get({
            baseUrl: environment.campaignBuilderUrl,
            headers: headers,
            endpoint: `api/v1/background-job/${jobExecutionUid}/status`
        }).pipe(map(result => ([result.Job, result.ExtendedStatus])));
    }
}
BackgroundTaskRestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackgroundTaskRestService_Factory() { return new BackgroundTaskRestService(i0.ɵɵinject(i1.RestService)); }, token: BackgroundTaskRestService, providedIn: "root" });
