import { NavigationHelperService } from 'src/app/services/helper/navigation-helper/navigation-helper.service';
import { AuthorizationHandlerService } from 'src/app/services/handler/authorization-handler/authorization-handler.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/handler/authorization-handler/authorization-handler.service";
import * as i2 from "../../services/helper/navigation-helper/navigation-helper.service";
export class LoginGuard {
    constructor(_authHandler, _navHelper) {
        this._authHandler = _authHandler;
        this._navHelper = _navHelper;
    }
    canActivate(next, state) {
        if (this._authHandler.isAuthenticated()) {
            if (this._authHandler.isWorkingOrganizationSetted()) {
                this._navHelper.goToDashboard();
            }
            else {
                this._navHelper.goToTenantSelector();
            }
            return false;
        }
        return true;
    }
}
LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.AuthorizationHandlerService), i0.ɵɵinject(i2.NavigationHelperService)); }, token: LoginGuard, providedIn: "root" });
