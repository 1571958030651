<table class="table">
  <thead>
    <tr>
      <th scope="col" *ngFor="let element of header">{{element}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of model.paginatedArray">

      <td *ngFor="let element of row">{{element}}</td>
    </tr>
  </tbody>
</table>
<pagination *ngIf="model.showPagination" [totalItems]="model.totalItems" [itemsPerPage]="model.itemsPerPage"
  [maxSize]="model.maxSize" (pageChanged)="pageChanged($event)"></pagination>